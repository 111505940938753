import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import service from "Api/service";
import converter from "json-style-converter/es5";
import { FormattedDate } from "react-intl";
import { capitalizeFirstLetter } from "HelperFunctions/general";
import Checkbox from "Utils/Checkbox";
import { saveError } from "Api";
import CurrencyLabel from "Utils/CurrencyLabel";

class DetailedAmountPaid extends React.Component {

  state = {
    shouldSyncToQb: false,
    loading: false
  }

  componentDidMount() {
    const { payment } = this.props;
    this.setState({
      shouldSyncToQb: payment.shouldSyncToQb
    })
  }

  qbConnection() {
    const { quickbooksConnections } = this.props.payment;
    if (quickbooksConnections && quickbooksConnections.constructor === Array) {
      return quickbooksConnections[quickbooksConnections.length - 1];
    }
    return null;
  }

  handleSubmit = event => {
    const { rental, setErrors, onRefresh, payment } = this.props;
    const { loading } = this.state;
    const { checked } = event.target;

    if (loading) {
      return;
    }

    if (!rental.shouldSyncToQb) {
      setErrors([
        "Transactions can't be synced to Quickbooks if the order is not synced."
      ]);
    } else {
      this.setState({
        shouldSyncToQb: !this.state.shouldSyncToQb,
        loading: true
      })
      service
        .patch(
          `${process.env.REACT_APP_API_DOMAIN}/api/rentals/${rental.token}/transactions/${payment.id}`,
          converter.camelToSnakeCase({
            transaction: {
              should_sync_to_qb: checked
            }
          })
        )
        .then(response => {
          onRefresh();
          this.setState({
            loading: false
          })
        })
        .catch(error => {
          this.setState({
            shouldSyncToQb: !this.state.shouldSyncToQb,
            loading: false
          })
          saveError(error);
        });
    }
  };

  render() {
    const {
      payment,
      ownerView,
      displayQuickbooksToggle
    } = this.props;
    const { shouldSyncToQb } = this.state;
    const feeValue = Number(payment.processingFee);
    const qbConnection = this.qbConnection();

    return (
      <div>
        <label>
          Payment{" "}
          {payment.status == "Approved" &&
            payment.parentTransactionId &&
            `(Authorization Hold Charged)`
          }
          {payment.mode !== "success" &&
            `(${capitalizeFirstLetter(payment.mode)})`}
        </label>
        {payment.mode === "failed" ? (
          <span>Failed</span>
        ) : (
          <CurrencyLabel value={0 - payment.amount} />
        )}

        <table className="grid">
          <thead>
            <tr>
              <th className="sm">
                <FormattedDate
                  value={payment.paymentDate}
                  day="2-digit"
                  month="2-digit"
                  year="numeric"
                />
              </th>
              <th>
                {capitalizeFirstLetter(payment.paymentData.type)}
                {payment.paymentData.number &&
                  " " + payment.paymentData.number}
              </th>
              <th className="sm right">
                {ownerView && feeValue > 0 && payment.mode !== "failed" && (
                  <span>
                    <CurrencyLabel value={feeValue} />
                    {" Fee"}
                  </span>
                )}
              </th>
            </tr>
          </thead>
        </table>
        {displayQuickbooksToggle && (
          <div>
            <Checkbox
              checked={shouldSyncToQb}
              onCheck={this.handleSubmit}
              label="Include with QuickBooks Sync"
            />

            <div>QB Sync Status:&nbsp;
              {qbConnection ? (
                <span>
                  Synced on&nbsp;
                  <FormattedDate
                    value={qbConnection.createdAt}
                    year='numeric'
                    month='short'
                    day='numeric'
                  />
                </span>
              ) : (
                <span>
                  {payment.qbPeriodClosedError ? (
                    <span>&nbsp;-&nbsp;<strong>Warning:</strong> QB Reported Period Closed</span>
                  ) : 'In queue'}
                </span>
              )}
            </div>
          </div>
        )}
      </div>
    );
  }
};

const mapStateToProps = state => {
  const { location } = state.location;
  const { business } = state.business;
  const { user } = state.auth;
  return { location, user, business };
};

export default connect(mapStateToProps, actions)(DetailedAmountPaid);

import React from "react";
import classNames from "classnames";

const StepConnector = props => {

  const isDottedConnector = props.dottedConnector ? props.dottedConnector : false

  return (
    <div
      className={classNames({
        connector: true,
        dottedConnector: isDottedConnector ,
        complete: props.complete,
        active: props.active,
        progress: props.progress,
        showAnts: props.showAnts,
        invisible: props.hidden,
        dotted: props.dotted
      })}
    />
  );
};

export default StepConnector;

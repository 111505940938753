import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Field, reduxForm } from "redux-form";
import StandardTextField from "Utils/redux_form_inputs/StandardTextField";
import Imgix from "react-imgix";

const NewMessage = props => {
  const { handleSubmit, user } = props;
  return (
    <form onSubmit={handleSubmit} className="newMessage">
      <div className="thumb">
        {user.picture ? (
          <Imgix
            src={user.picture.imgixUrl}
            alt={user.firstName}
            height={200}
            width={200}
          />
        ) : (
          <div>
            {user.firstName.charAt(0)}
            {user.lastName.charAt(0)}
          </div>
        )}
      </div>
      <Field name="message" component={StandardTextField} type="text" />
    </form>
  );
};

const mapStateToProps = state => {
  const { user } = state.user;
  return { user };
};

export default connect(
  mapStateToProps,
  actions
)(
  reduxForm({
    form: "NewMessage"
  })(NewMessage)
);

import React from 'react';
import { connect } from 'react-redux';
import { getSortClass } from 'HelperFunctions/rentals';

const StandardRentalTableHead = (props) => {
  const {
    sortField,
    sortDirection,
    onSort,
    tileView,
    multilocationAllLocations,
  } = props;
  return (
    <tr>
      {multilocationAllLocations && <th className='lg'>Location</th>}
      {tileView && <th className='rTableCell image' />}
      <th style={{ width: '112px', height: '18px' }}>
        <span
          className={getSortClass('name', sortField, sortDirection)}
          onClick={() => onSort('name')}
        >
          Item
        </span>
      </th>
      <th className='checkOutAndIn'>
        <span
          className={getSortClass('start_date', sortField, sortDirection)}
          onClick={() => onSort('start_date')}
        >
          Order Date
        </span>
      </th>
      <th className='checkOutAndIn'>
        <span
          className={getSortClass('start_date_time', sortField, sortDirection)}
          onClick={() => onSort('start_date_time')}
        >
          OUT
        </span>
      </th>
      <th className='checkOutAndIn'>
        <span
          className={getSortClass('quantity', sortField, sortDirection)}
          onClick={() => onSort('quantity')}
        >
          Qty
        </span>
      </th>
      <th className='checkOutAndIn'>
        <span
          className={getSortClass('rentalItemTotal', sortField, sortDirection)}
          onClick={() => onSort('rentalItemTotal')}
        >
          Warehouse Location
        </span>
      </th>
      <th className='sm'>Order Status</th>
    </tr>
  );
};

const mapStateToProps = (state) => {
  const { tileView } = state.products;

  return { tileView };
};

export default connect(mapStateToProps, null)(StandardRentalTableHead);

import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  ADD_STATUS_REQUEST,
  ADD_STATUS_SUCCESS,
  ADD_STATUS_FAILURE,
  LOAD_PICK_LIST_STATUSES,
  LOAD_PICK_LIST_STATUSES_SUCCESS,
  LOAD_PICK_LIST_STATUSES_FAIL,
  UPDATE_STATUS_REQUEST,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAILURE,
  REMOVE_STATUS_REQUEST,
  REMOVE_STATUS_SUCCESS,
  REMOVE_STATUS_FAILURE,
  UPDATE_STATUS_POSITIONS_REQUEST,
  UPDATE_STATUS_POSITIONS_SUCCESS,
  UPDATE_STATUS_POSITIONS_FAILURE,
} from 'Constants/redux';
import { openSuccessSnackBar, setErrors } from './dashboardActionCreators';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

export const addStatus = (data, isDefault = false) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_STATUS_REQUEST });
    dispatch(
      openLoadingSpinner(`${isDefault ? 'Updating' : 'Adding'} status...`)
    );
    return service
      .post(`${ROOT_URL}/pick_list_statuses`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: ADD_STATUS_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: ADD_STATUS_FAILURE,
        });
        return Promise.reject(error);
      });
  };
};

export const updateStatus = (id, data) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner());
    dispatch({ type: UPDATE_STATUS_REQUEST });
    return service
      .patch(`${ROOT_URL}/pick_list_statuses/${id}`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_STATUS_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
        }

        dispatch({
          error,
          type: UPDATE_STATUS_FAILURE,
        });

        dispatch(closeLoadingSpinner());
        return Promise.reject(error);
      });
  };
};

export const getStatusesBySection = () => {
  return (dispatch, getState) => {
    dispatch({ type: LOAD_PICK_LIST_STATUSES });
    service
      .get(`${ROOT_URL}/pick_list_statuses/statuses_by_section`)
      .then((response) => {
        return dispatch({
          pickListStatuses: {
            checkInStatuses: response.data.checking_in,
            checkOutStatuses: response.data.checking_out,
          },
          type: LOAD_PICK_LIST_STATUSES_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error);
        dispatch({ type: LOAD_PICK_LIST_STATUSES_FAIL });
      });
  };
};

export const updatePositions = (data) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner());
    dispatch({ type: UPDATE_STATUS_POSITIONS_REQUEST });
    return service
      .patch(`${ROOT_URL}/pick_list_statuses/update_positions`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_STATUS_POSITIONS_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
        }

        dispatch({
          error,
          type: UPDATE_STATUS_POSITIONS_FAILURE,
        });
        dispatch(closeLoadingSpinner());
        return Promise.reject(error);
      });
  };
};

export const removeStatus = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner());
    dispatch({ type: REMOVE_STATUS_REQUEST });
    return service
      .delete(`${ROOT_URL}/pick_list_statuses/${id}`)
      .then((response) => {
        dispatch({
          response: response.data,
          type: REMOVE_STATUS_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
        return response;
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: REMOVE_STATUS_FAILURE,
        });

        dispatch(closeLoadingSpinner());
        return Promise.reject(error);
      });
  };
};

import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions/dashboardActionCreators";
import { standardService } from "Api/service";
import LoadingSpinner from "Utils/LoadingSpinner";
import classNames from "classnames";
import { parseQuery } from "HelperFunctions/QueryString";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";

class NewFeedback extends React.Component {
  state = {
    business: null,
    customer: null,
    dataLoading: "loading",
    feedback: {
      rental_id: 0,
      customer_location_relationship_id: 0,
      score: 0,
      comment: ""
    },
    submitted: false
  };

  componentDidMount() {
    const { customerId, rentalId, businessId, score } = parseQuery(
      window.location.search
    );
    if (rentalId && customerId && businessId) {
      const component = this;
      standardService
        .all([
          this.getBusiness(businessId),
          this.getCustomer(customerId),
          this.checkIfSubmittedAlready(customerId, rentalId)
        ])
        .then(
          standardService.spread((buss, cust, check) => {
            const submitted = check.data ? true : false;

            const newFeedback = Object.assign(component.state.feedback, {
              customer_location_relationship_id: customerId,
              rental_id: rentalId,
              score: parseInt(score, 10)
            });
            // Both requests are now complete
            component.setState({
              business: buss.data.business,
              customer: cust.data.customerLocationRelationship.customer,
              dataLoading: "success",
              feedback: newFeedback,
              submitted
            });
          })
        )
        .catch(function(error) {
          console.log(error);
          //  saveError(error)
        });
    } else {
      this.setState({
        dataLoading: "missing"
      });
    }
  }

  handleSubmit = () => {
    const { history, setErrors } = this.props;
    const { feedback } = this.state;
    const { businessId } = parseQuery(window.location.search);
    standardService
      .post(process.env.REACT_APP_API_DOMAIN + "/api/feedbacks", { feedback })
      .then(response => {
        history.push(`/feedbacks/thankyou?businessId=${businessId}`);
      })
      .catch(error => {
        setErrors(error.response.data);
      });
  };

  getBusiness = id => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN + "/api/guest/businesses/" + id
    );
  };

  getCustomer = id => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN +
        "/api/guest/customer_location_relationships/" +
        id
    );
  };

  checkIfSubmittedAlready = (customerId, rentalId) => {
    return standardService.get(
      process.env.REACT_APP_API_DOMAIN + "/api/feedbacks/check_for_existing",
      {
        customer_location_relationship_id: customerId,
        rental_id: rentalId
      }
    );
  };

  handleChange = event => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    const newFeedback = Object.assign(this.state.feedback, {
      [name]: value
    });
    this.setState({
      feedback: newFeedback
    });
  };

  handleChangeScore = newScore => {
    const newFeedback = { ...this.state.feedback, score: newScore };
    this.setState({
      feedback: newFeedback
    });
  };

  renderScoreBoard = () => {
    let { feedback } = this.state;
    const options = [];
    for (let i = 0; i <= 10; i++) {
      const labelText = i === 0 ? "UNLIKELY" : i === 10 ? "LIKELY" : "";
      const index = i;
      options.push(
        <li
          key={i}
          className={classNames({
            ["nps" + i]: true,
            active: feedback.score === i
          })}
        >
          <a onClick={() => this.handleChangeScore(index)}>{i}</a>
          {i}
          <label>{labelText}</label>
        </li>
      );
    }
    return options;
  };

  render() {
    const { dataLoading, business, submitted } = this.state;
    if (dataLoading === "success") {
      return (
        <div className="contentWrapper customerPayment">
          <header>
            <div>
              {business.picture === null ? (
                <h1>{business.name}</h1>
              ) : (
                <Imgix
                  src={business.picture.imgixUrl}
                  alt={business.name}
                  width={510}
                  height={288}
                />
              )}
              <div className="details">
                <div>
                  {business.phoneNumber.office &&
                    formatPhoneNumber(business.phoneNumber.office)}
                </div>
                <div>
                  {business.phoneNumber.cell &&
                    formatPhoneNumber(business.phoneNumber.cell)}
                </div>
                <div>
                  {business.phoneNumber.fax &&
                    formatPhoneNumber(business.phoneNumber.fax)}
                </div>
                <div>{business.email}</div>
                {business.physicalAddress && (
                  <div>
                    <div>{business.physicalAddress.streetAddress1}</div>
                    {business.physicalAddress.streetAddress2 && (
                      <div># {business.physicalAddress.streetAddress2}</div>
                    )}
                    <div>
                      {business.physicalAddress.city},{" "}
                      {business.physicalAddress.locale}{" "}
                      {business.physicalAddress.postalCode},{" "}
                      {business.physicalAddress.country}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          {submitted ? (
            <div className="content feedbackForm">
              <h4>You have already submitted a review for this order.</h4>
            </div>
          ) : (
            <div className="content feedbackForm">
              <h4>
                How likely are you to recommend {business.name} to your friends?
              </h4>
              <ul>{this.renderScoreBoard()}</ul>
              <div className="details">
                <label>Share any feedback here:</label>
                <textarea name="comment" onChange={this.handleChange} />
                <div className="actions">
                  <a className="btn full" onClick={this.handleSubmit}>
                    Submit
                  </a>
                </div>
              </div>
            </div>
          )}
        </div>
      );
    } else if (dataLoading === "loading") {
      return (
        <div className="contentWrapper customerPayment">
          <div className="content">
            <LoadingSpinner label="Loading Info..." />
          </div>
        </div>
      );
    } else {
      return (
        <div className="contentWrapper customerPayment">
          <div className="content">
            Oops, we could not find necessary info. Please make sure you have
            navigated to the proper url.
          </div>
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const { errorsOpen, errors } = state.dashboard;
  return { errorsOpen, errors };
};

export default connect(mapStateToProps, actions)(NewFeedback);

import styled from 'styled-components';

export const OrderInfoContainer = styled.div`
  width: 100%;
  display: flex;
  max-width: 19.25rem;
  flex-direction: column;
`;

export const OrderInfoBottomContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const OrderInfoDateContainer = styled.div`
  display: flex;
  justify-content: space-between;

  &:first-of-type {
    margin-top: 0.5rem;
  }
`;

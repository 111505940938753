import {
  REQUEST_TRUCK_NAMES,
  RECEIVE_TRUCK_NAMES,
  SET_TRUCK_FILTERED_NAMES,
  SET_TRUCK_NAMES_FILTER,
} from 'Constants/redux';
import { saveError } from 'Api';
import service from 'Api/service';

const requestTruckNames = () => {
  return {
    type: REQUEST_TRUCK_NAMES,
  };
};

const receiveTruckNames = (trucks) => {
  return {
    type: RECEIVE_TRUCK_NAMES,
    trucks: trucks,
  };
};

export const setTruckNamesFilter = (payload) => {
  return {
    type: SET_TRUCK_NAMES_FILTER,
    payload,
  };
};

export const setTruckFilteredNames = (payload) => {
  return {
    type: SET_TRUCK_FILTERED_NAMES,
    payload,
  };
};

export const fetchTruckNames = () => {
  return (dispatch, getState) => {
    const { multilocationAllLocations } = getState().dashboard;
    dispatch(requestTruckNames());
    return service
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/business/trucks/names`,
        {
          ...(multilocationAllLocations && { multilocation_all_locations: true })
        }
      )
      .then((response) => {
        dispatch(receiveTruckNames(response.data.trucks));
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
};

import React from "react";

const StandardRentalTableHead = props => {
  return (
    <tr>
      <th>Customer</th>
      <th className="medSm">Checkout Date</th>
      <th className="medSm">Checkin Date</th>
      <th className="actionSm" />
    </tr>
  );
};
export default StandardRentalTableHead;

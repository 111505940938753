import { localeToCurrency } from 'Constants/currencyConstants';

const CurrencyFormatter = (props) => {
  const { locale, ...others } = props;
  const currency = localeToCurrency[locale];
  const currencyDisplay = currency === 'CAD' ? 'code' : 'symbol';
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: currency,
    currencyDisplay: currencyDisplay,
    ...others,
  });
  return formatter;
};

export default CurrencyFormatter;

import service from 'Api/service';
import { ROOT_URL, saveError } from 'Api';
import {
  SET_FILTER_SETTING_REQUEST,
  SET_FILTER_SETTING_SUCCESS,
  SET_FILTER_SETTING_FAILURE,
  FETCH_FILTER_SETTING_REQUEST,
  FETCH_FILTER_SETTING_SUCCESS,
  FETCH_FILTER_SETTING_FAILURE,
} from 'Constants/redux';
import converter from "json-style-converter/es5";

export const setFilterSettings = (
  filter,
  { page, subPage, subSubPage = '' }
) => {
  return (dispatch) => {
    dispatch({ type: SET_FILTER_SETTING_REQUEST });
    service
      .post(process.env.REACT_APP_API_DOMAIN + `/api/filter_settings`, {
        filter_setting: {
          ...converter.camelToSnakeCase(filter),
          page: page,
          sub_page: subPage,
          sub_sub_page: subSubPage,
        }
      })
      .then((response) => {
        let result = {}
        response.data.filterSettings.forEach((setting) => { result[setting.setting] = setting.settingValue });
        return dispatch({
          response: { ...converter.snakeToCamelCase(result) },
          section: subSubPage
            ? `${page}${subPage}${subSubPage}`
            : `${page}${subPage}`,
          type: SET_FILTER_SETTING_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          error,
          type: SET_FILTER_SETTING_FAILURE,
        });
      });
  };
};

export const fetchFilterSettings = ({ page, subPage, subSubPage = '' }) => {
  return (dispatch) => {
    dispatch({ type: FETCH_FILTER_SETTING_REQUEST });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          `/api/filter_settings`,
        {
          page: page,
          sub_page: subPage,
          sub_sub_page: subSubPage,
        }
      )
      .then((response) => {
        return dispatch({
          response: { ...converter.snakeToCamelCase(response.data) },
          type: FETCH_FILTER_SETTING_SUCCESS,
          section: subSubPage
            ? `${page}${subPage}${subSubPage}`
            : `${page}${subPage}`,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          error,
          type: FETCH_FILTER_SETTING_FAILURE,
        });
      });
  };
};

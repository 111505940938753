import React from 'react';
import { connect } from 'react-redux';
import LoadingSpinner from 'Utils/LoadingSpinner';
import { Redirect } from 'react-router';
import { employeeIsAllowed } from 'HelperFunctions/locations';

class Home extends React.Component {
  render() {
    const { authenticated, loading, employeeLocationRelationship } = this.props;
    if (loading) {
      return <LoadingSpinner />;
    } else if (
      authenticated &&
      employeeLocationRelationship &&
      employeeIsAllowed(['owner', 'admin'], employeeLocationRelationship)
    ) {
      return <Redirect to='/at-a-glance' />;
    } else {
      return <Redirect to='/todos' />;
    }
  }
}

const mapStateToProps = (state) => {
  const { loading, authenticated } = state.auth;
  const { employeeLocationRelationship } = state.user;
  return {
    employeeLocationRelationship,
    authenticated,
    loading,
  };
};

export default connect(mapStateToProps)(Home);

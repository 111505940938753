import { REQUEST_MESSAGEABLE_NAMES, EPIC_ERROR } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";
const { Observable } = Rx;

const fetchMessageableNamesEpic = action$ =>
  action$
    .ofType(REQUEST_MESSAGEABLE_NAMES)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.get(
          process.env.REACT_APP_API_DOMAIN +
            `/api/customer_location_relationships/messageable_names`
        )
      )
        .map(response => {
          return actions.receiveMessageableNames(response.data.names);
        })
        .catch(error =>
          Observable.of({
            type: EPIC_ERROR
          })
        );
    });

export default fetchMessageableNamesEpic;

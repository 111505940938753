import React, { useEffect, useRef } from 'react';
import NotificationSystem from 'react-notification-system';
import { connect } from 'react-redux';

interface AlertPopUpProps {
  alertMessage: string;
  alertTime: Date;
}

const AlertPopUp = ({ alertMessage, alertTime }: AlertPopUpProps) => {
  const notificationSystemRef = useRef<NotificationSystem.System>(null);

  useEffect(() => {
    if (alertMessage.length > 0) {
      notificationSystemRef.current.clearNotifications();
      notificationSystemRef.current.addNotification({
        level: 'success',
        position: 'bc',
        message: (
          <div>
            {alertMessage}
          </div>
        ),
        action: {
          label: 'OK',
        },
        autoDismiss: 0,
      });
    }
  }, [alertMessage, alertTime]);

  return <NotificationSystem ref={notificationSystemRef} style={false} />;
};

const mapStateToProps = (state: any) => {
  const { alertMessage, alertTime } = state.dashboard;
  return { alertMessage, alertTime };
};

export default connect(mapStateToProps)(AlertPopUp);

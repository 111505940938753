import { SEARCH_VENUE_NAMES, EPIC_ERROR } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";
const { Observable } = Rx;

const SearchVenueNamesEpic = action$ =>
  action$
    .ofType(SEARCH_VENUE_NAMES)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.get(
          process.env.REACT_APP_API_DOMAIN +
            `/api/venues/search?query=${action.query}`
        )
      )
        .debounceTime(500)
        .map(response => {
          return actions.receiveVenueNames(response.data.names);
        })
        .catch(error =>
          Observable.of({
            type: EPIC_ERROR
          })
        );
    });

export default SearchVenueNamesEpic;

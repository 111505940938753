import React from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";

const ProfileSubNav = props => {
  return (
    <div className="subNavWrapper">
      <div className="subNav">
        <div className="subNavMenu">
          <NavLink id="info" exact activeClassName="selected" to="/profile">
            Info
          </NavLink>
          <NavLink
            id="accounts"
            activeClassName="selected"
            to="/profile/accounts"
          >
            Accounts
          </NavLink>
          <NavLink
            id="google_cal"
            activeClassName="selected"
            to={props.business.business.permissions.googleCalendar ? "/profile/googleCal" : '/upgrade/googleCal'}
          >
            Google Cal
          </NavLink>
          <NavLink
            id="notifications"
            activeClassName="selected"
            to="/profile/notifications"
          >
            Notifications
          </NavLink>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    business: state.business
  };
};

export default connect(mapStateToProps)(ProfileSubNav);
//export default ProfileSubNav;

import React from "react";
import { connect } from "react-redux";
import { FormattedNumber } from "react-intl";
import { localeToCurrency } from "Constants/currencyConstants";
import { selectCheckPermission } from 'HelperFunctions/locations';

// ref docs: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/Intl/NumberFormat/NumberFormat
export const CurrencyLabel = props => {
  const {
    value,
    location: { locale },
    hideCurrency,
    checkPermission,
    alternativePermission,
    ...others
  } = props;
  const currency = hideCurrency ? "USD" : (localeToCurrency[locale] || "USD");
  const currencyDisplay = currency === "CAD" ? "code" : "symbol";

  const canViewNumber = (alternativePermission !== null && alternativePermission !== undefined) ?
                        alternativePermission :
                        checkPermission('view_pricing_billing')

  return canViewNumber ?
    (<FormattedNumber
      value={value}
      style="currency"
      currencyDisplay={currencyDisplay}
      currency={currency}
      maximumFractionDigits={2}
      {...others}
    />) : (<span>-</span>);
}

const mapStateToProps = state => {
  const { location } = state.location;
  const checkPermission = selectCheckPermission(state);
  return { location, checkPermission };
}

export default connect(mapStateToProps, null)(CurrencyLabel);

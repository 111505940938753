export const ADD_STATUS_REQUEST = "ADD_STATUS_REQUEST";
export const ADD_STATUS_SUCCESS = "ADD_STATUS_SUCCESS";
export const ADD_STATUS_FAILURE = "ADD_STATUS_FAILURE";

export const UPDATE_STATUS_REQUEST = "UPDATE_STATUS_REQUEST";
export const UPDATE_STATUS_SUCCESS = "UPDATE_STATUS_SUCCESS";
export const UPDATE_STATUS_FAILURE = "UPDATE_STATUS_FAILURE";

export const UPDATE_STATUS_POSITIONS_REQUEST = "UPDATE_STATUS_POSITIONS_REQUEST";
export const UPDATE_STATUS_POSITIONS_SUCCESS = "UPDATE_STATUS_POSITIONS_SUCCESS";
export const UPDATE_STATUS_POSITIONS_FAILURE = "UPDATE_STATUS_POSITIONS_FAILURE";

export const REMOVE_STATUS_REQUEST = "REMOVE_STATUS_REQUEST";
export const REMOVE_STATUS_SUCCESS = "REMOVE_STATUS_SUCCESS";
export const REMOVE_STATUS_FAILURE = "REMOVE_STATUS_FAILURE";

export const LOAD_PICK_LIST_STATUSES = "LOAD_PICK_LIST_STATUSES";
export const LOAD_PICK_LIST_STATUSES_SUCCESS = "LOAD_PICK_LIST_STATUSES_SUCCESS";
export const LOAD_PICK_LIST_STATUSES_FAIL = "LOAD_PICK_LIST_STATUSES_FAIL";

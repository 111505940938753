import React from "react";
import classNames from "classnames";

class PhoneInput extends React.Component {
  render() {
    /*eslint-disable */
    const {
      name,
      className,
      placeholder,
      onChange,
      value,
      ...other
    } = this.props;
    /*eslint-disable */
    return (
      <input
        {...other}
        name={name}
        className={classNames(className, "css-phone")}
        placeholder={placeholder}
        options={{ phone: true, phoneRegionCode: "US" }}
        onChange={onChange}
        value={value}
      />
    );
  }
}

export default PhoneInput;

import ActionCable from 'actioncable';
import { getAuthToken } from '../api';

let consumer: ActionCable.Cable | null = null;

export const getActionCableConsumer = (): ActionCable.Cable => {
  if (!consumer) {
    const authToken = getAuthToken();
    const URL: string = `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${authToken['access-token']}&client=${authToken.client}&uid=${authToken.uid}&resource_type=employee`;
    consumer = ActionCable.createConsumer(URL);
  }
  return consumer;
};


import React from "react";
import { Helmet } from "react-helmet";
import { Switch, Route } from "react-router-dom";

const Helmets = () => (
  <Switch>
    <Route
      path="/feedbacks"
      render={props => (
        <Helmet>
          <title>TapGoods - Feedback</title>
        </Helmet>
      )}
    />
    <Route
      path="/login"
      render={props => (
        <Helmet>
          <title>TapGoods - Sign In</title>
        </Helmet>
      )}
    />
    <Route
      path="/start"
      render={props => (
        <Helmet>
          <title>TapGoods - Start</title>
        </Helmet>
      )}
    />
    <Route
      path="/signUp"
      render={props => (
        <Helmet>
          <title>TapGoods - Sign Up</title>
        </Helmet>
      )}
    />
    <Route
      path="/forgotPassword"
      render={props => (
        <Helmet>
          <title>TapGoods - Forgot Password</title>
        </Helmet>
      )}
    />
    <Route
      path="/passwordReset"
      render={props => (
        <Helmet>
          <title>TapGoods - Password Reset</title>
        </Helmet>
      )}
    />

    <Route
      path="/dashboard"
      render={props => (
        <Helmet>
          <title>TapGoods - Dashboard</title>
        </Helmet>
      )}
    />

    <Route
      path="/orders"
      exact
      render={props => (
        <Helmet>
          <title>TapGoods - Orders</title>
        </Helmet>
      )}
    />
    <Route
      path={"/orders/subrentals"}
      render={props => (
        <Helmet>
          <title>TapGoods - Subrentals</title>
        </Helmet>
      )}
    />
    <Route
      path={"/orders/rentals"}
      render={props => (
        <Helmet>
          <title>TapGoods - Orders</title>
        </Helmet>
      )}
    />

    <Route
      path="/inventory"
      render={props => (
        <Helmet>
          <title>TapGoods - Inventory</title>
        </Helmet>
      )}
    />

    <Route
      path="/contacts"
      render={props => (
        <Helmet>
          <title>TapGoods - Contacts</title>
        </Helmet>
      )}
    />

    <Route
      path="/operations"
      exact
      render={props => (
        <Helmet>
          <title>TapGoods - Operations</title>
        </Helmet>
      )}
    />
    <Route
      path="/operations/calendars"
      render={props => (
        <Helmet>
          <title>TapGoods - Calendars</title>
        </Helmet>
      )}
    />
    <Route
      path="/operations/staff"
      render={props => (
        <Helmet>
          <title>TapGoods - Staff</title>
        </Helmet>
      )}
    />
    <Route
      path="/operations/routing"
      render={props => (
        <Helmet>
          <title>TapGoods - Routing</title>
        </Helmet>
      )}
    />
    <Route
      path="/services"
      render={props => (
        <Helmet>
          <title>TapGoods - Staff</title>
        </Helmet>
      )}
    />
    <Route
      path="/calendars"
      render={props => (
        <Helmet>
          <title>TapGoods - Calendars</title>
        </Helmet>
      )}
    />

    <Route
      path="/admin"
      exact
      render={props => (
        <Helmet>
          <title>TapGoods - Admin</title>
        </Helmet>
      )}
    />
    <Route
      path="/admin/reports"
      render={props => (
        <Helmet>
          <title>TapGoods - Reports</title>
        </Helmet>
      )}
    />
    <Route
      path="/admin/settings"
      render={props => (
        <Helmet>
          <title>TapGoods - Settings</title>
        </Helmet>
      )}
    />

    <Route
      path="/conversations"
      render={props => (
        <Helmet>
          <title>TapGoods - Messages</title>
        </Helmet>
      )}
    />

    <Route
      path="/todos"
      render={props => (
        <Helmet>
          <title>TapGoods - To-Do's</title>
        </Helmet>
      )}
    />

    <Route
      path="/profile"
      render={props => (
        <Helmet>
          <title>TapGoods - Profile</title>
        </Helmet>
      )}
    />
    <Route
      path="/help"
      render={props => (
        <Helmet>
          <title>TapGoods - Help</title>
        </Helmet>
      )}
    />
    <Route
      path="/contact"
      render={props => (
        <Helmet>
          <title>TapGoods - Contact</title>
        </Helmet>
      )}
    />

    <Route
      path="/print"
      render={props => (
        <Helmet>
          <title>TapGoods - Print</title>
        </Helmet>
      )}
    />
    <Route
      path="/tg-admin"
      render={props => (
        <Helmet>
          <title>TapGoods - Admin</title>
        </Helmet>
      )}
    />
  </Switch>
);

export default Helmets;

import React from "react";
import Stepper from "../rentals/Stepper";
import Step from "../rentals/Step";
import StepConnector from "../rentals/StepConnector";

class SettingsProgress extends React.Component {
  getProgressionIndex(step) {
    switch (step) {
      case "start":
        return 0;
      case "business_settings":
        return 1;
      case "select_plan":
        return 2;
      case "accept_card":
        return 3;
      case "accept_payments":
        return 3;
      case "tapgoods_fees":
        return 4;
      case "success":
        return 5;
      default:
        return -1;
    }
  }
  getLabel(path) {
    let label;
    if (path.includes("selectPlan")) {
      label = "Plan (1/3)";
    }
    else if (path.includes("selectUserPlan")) {
      label = "Plan (2/3)";
    }
    else if (path.includes("selectStorefrontPlan")) {
      label = "Plan (3/3)";
    }
    else {
      label = "Plan";
    }
    return label;
  }

  render() {
    const { step } = this.props;
    const activeStep = this.getProgressionIndex(step);
    const path = window.location.href.split("/").pop();
    const label = this.getLabel(path);

    return (
      <Stepper activeStep={activeStep}>
        <Step label="Personal" />
        <Step connector={<StepConnector />} label="Business" />
        <Step connector={<StepConnector />} label={`${label}`} />
        <Step connector={<StepConnector />} label="Verification" />
      </Stepper>
    );
  }
}

export default SettingsProgress;

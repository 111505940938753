import { ROOT_URL, saveError } from "Api";
import service from "Api/service";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";
import { setErrors, openSuccessSnackBar } from "./dashboardActionCreators";
import {
  FETCH_MARKETPLACE_SETTING_REQUEST,
  FETCH_MARKETPLACE_SETTING_FAILURE,
  FETCH_MARKETPLACE_SETTING_SUCCESS,
  UPDATE_MARKETPLACE_SETTING_REQUEST,
  UPDATE_MARKETPLACE_SETTING_SUCCESS,
  DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST
} from "Constants/redux";

export const fetchMarketplaceSetting = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_MARKETPLACE_SETTING_REQUEST });
    service
      .get(`${ROOT_URL}/marketplace_setting/`)
      .then(response => {
        return dispatch({
          response: response.data,
          type: FETCH_MARKETPLACE_SETTING_SUCCESS
        });
      })
      .catch(error => {
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_MARKETPLACE_SETTING_FAILURE
        });
      });
  };
};

export const updateMarketplaceSetting = (data, onSuccess) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_MARKETPLACE_SETTING_REQUEST,
      data: data
    });
    dispatch(openLoadingSpinner("Updating..."));
    return service
      .patch(`${ROOT_URL}/marketplace_setting/`, data)
      .then(response => {
        dispatch(closeLoadingSpinner());
        dispatch(openSuccessSnackBar("Successfully updated."));
        if (onSuccess) {
          onSuccess();
        }
        return dispatch({
          response: response.data,
          type: UPDATE_MARKETPLACE_SETTING_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        dispatch(setErrors(error.response.data));
      });
  };
};

export const debouncedUpdateMarketplaceSetting = data => {
  return (dispatch, getState) => {
    dispatch({
      type: DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST,
      data: data
    });
  };
};

import styled from 'styled-components';

export const TableContainer = styled.div`
  display: grid;
  align-items: center;
  grid-template-columns: 3fr 1fr 1fr;
`;

export const TableHeader = styled.div`
  display: contents;
`;

export const TableCell = styled.div`
  padding: 1rem 0.5rem;
  text-align: left;
  color: ${({ theme, color }) => color ?? theme.cobalt};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
`;

export const TableCellRight = styled(TableCell)`
  text-align: right;
  padding-right: 1rem;
`;

export const StrongDivider = styled.div`
  height: 0.1875rem;
  grid-column: span 3;
  margin-top: ${({ marginTop }) => marginTop ?? 0};
  background-color: ${({ theme, color }) => color ?? theme.blue};
`;

export const LightDivider = styled.div`
  height: 1px;
  grid-column: span 3;
  background-color: ${({ theme }) => theme.meddarkgrey};
`;

export const TableTotal = styled.div`
  display: contents;
`;

export const TableTitle = styled(TableCell)`
  font-weight: bold;
  grid-column: 1 / span 2;
`;

export const TableTotalValue = styled(TableCellRight)`
  font-weight: bold;
`;

import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  UPDATE_SERVICE_TICKET_REQUEST,
  UPDATE_SERVICE_TICKET_SUCCESS,
  UPDATE_SERVICE_TICKET_FAILURE,
} from 'Constants/redux';
import { openSuccessSnackBar, setErrors } from './dashboardActionCreators';
import { fetchRentalStaffs } from './RentalStaffActions';

export const cancelServiceTicket = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_SERVICE_TICKET_REQUEST });
    return service
      .patch(`${ROOT_URL}/service_tickets/${data.id}`, data.service_ticket)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_SERVICE_TICKET_SUCCESS,
        });
        const serviceTicketToken = response.data.serviceTicket.token;
        const data = {
          rental_id: serviceTicketToken.split('-')[0],
        };
        dispatch(openSuccessSnackBar('Service Ticket cancelled successfully'));
        dispatch(fetchRentalStaffs(data));
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
          dispatch({ error, type: UPDATE_SERVICE_TICKET_FAILURE });
        }
      });
  };
};

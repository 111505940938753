export const ADD_DISPLAY_WEBSITE_REQUEST = "ADD_DISPLAY_WEBSITE_REQUEST";
export const REMOVE_DISPLAY_WEBSITE_REQUEST = "REMOVE_DISPLAY_WEBSITE_REQUEST";
export const ADD_TRUCK_REQUEST = "ADD_TRUCK_REQUEST";
export const UPDATE_TRUCK_REQUEST = "UPDATE_TRUCK_REQUEST";
export const REMOVE_TRUCK_REQUEST = "REMOVE_TRUCK_REQUEST";
export const FETCH_BUSINESS_REQUEST = "FETCH_BUSINESS_REQUEST";
export const FETCH_BUSINESS_SUCCESS = "FETCH_BUSINESS_SUCCESS";
export const FETCH_BUSINESS_FAILURE = "FETCH_BUSINESS_FAILURE";
export const UPDATE_BUSINESS_REQUEST = "UPDATE_BUSINESS_REQUEST";
export const UPDATE_BUSINESS_ROUTING_REQUEST =
  "UPDATE_BUSINESS_ROUTING_REQUEST";
export const UPDATE_BUSINESS_SUCCESS = "UPDATE_BUSINESS_SUCCESS";
export const UPDATE_BUSINESS_FAILURE = "UPDATE_BUSINESS_FAILURE";
export const FETCH_DELIVERY_SETTING_REQUEST = "FETCH_DELIVERY_SETTING_REQUEST";
export const FETCH_DELIVERY_SETTING_SUCCESS = "FETCH_DELIVERY_SETTING_SUCCESS";
export const FETCH_DELIVERY_SETTING_FAILURE = "FETCH_DELIVERY_SETTING_FAILURE";
export const FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS =
  "FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS";
export const SET_BUSINESS_PROPS = "SET_BUSINESS_PROPS";
export const REQUEST_FOR_ACTIVATION = "REQUEST_FOR_ACTIVATION";
export const REQUEST_FOR_ACTIVATION_SUCCESS = "REQUEST_FOR_ACTIVATION_SUCCESS";
export const REQUEST_FOR_ACTIVATION_FAILURE = "REQUEST_FOR_ACTIVATION_FAILURE";
export const HIDE_ALERTS_TRIAL_MSG_ON_CROSS = "HIDE_ALERTS_TRIAL_MSG_ON_CROSS";
export const SHOULD_SHOW_ALERT_TRIAL = "SHOULD_SHOW_ALERT_TRIAL";
export const SHOULD_HIDE_ALERT_TRIAL = "SHOULD_HIDE_ALERT_TRIAL";
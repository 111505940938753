import { LOCATION_CHANGE } from 'react-router-redux';
import {
  FIELD_CHANGED,
  REVIEW_USER_SUCCESS,
  REVIEW_BUSINESS_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  CREATE_USER_FAIL,
  LOGIN_USER,
  SIGN_OUT_USER_SUCCESS,
  SIGN_IN_ON_OTHER_DEVICE,
  SIGN_IN_ON_OTHER_TAB,
  LISTENING_FOR_OTHER_SIGN_IN,
  CLEAR_SIGNED_IN_ON_OTHER_DEVICE,
  SIGN_OUT_USER_FAIL,
  SIGN_OUT_USER,
  ADMIN_LOGIN_USER,
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_FAIL,
  SET_NEW_USER,
  SET_NEW_BUSINESS,
  SELECT_BUSINESS_SUBSCRIPTION,
  PASSWORD_VAIDATION_REQUEST,
  PASSWORD_VAIDATION_REQUEST_SUCCESS,
  PASSWORD_VAIDATION_REQUEST_FAILURE,
  TWO_FACTOR_AUTH_STATE_UNKNOWN,
  TWO_FACTOR_AUTH_STATE_REQUIRED,
  TWO_FACTOR_AUTH_STATE_ENTER_CODE,
  TWO_FACTOR_AUTH_STATE_NOT_REQUIRED,
  SEND_SMS_REQUEST,
  SEND_SMS_REQUEST_SUCCESS,
  SEND_SMS_REQUEST_FAILURE,
  RESET_TWO_FACTOR,
} from 'Constants/redux';

import converter from 'json-style-converter/es5';

const INITIAL_STATE = {
  authenticated: false,
  email: '',
  password: '',
  phone: '',
  passwordConfirmation: '',
  isValidatingPassword: false,
  passwordIsValid: false,
  sendingSms: false,
  twoFactorAuthState: TWO_FACTOR_AUTH_STATE_UNKNOWN,
  user: {
    firstName: '',
    lastName: '',
    businesses: [],
  },
  business: {
    name: '',
  },
  errors: {
    email: '',
    password: '',
    login: '',
    signOut: '',
  },
  newUser: null,
  newBusiness: null,
  marketing: null,
  forcedSignOut: false,
  forcedNewTabSignOut: false,
  forcedSignOutCable: null,
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  let newPayload;
  switch (action.type) {
    case FIELD_CHANGED:
      const { field, text } = action.payload;
      return { ...state, [field]: text };
    case LOGIN_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case ADMIN_LOGIN_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case SET_NEW_USER:
      return { ...state, newUser: action.payload };
    case SET_NEW_BUSINESS:
      return { ...state, newBusiness: action.payload };
    case REVIEW_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        newUser: action.payload,
      };
    case REVIEW_BUSINESS_SUCCESS:
      return {
        ...state,
        marketing: action.marketing,
        newBusiness: action.business,
      };
    case SELECT_BUSINESS_SUBSCRIPTION:
      return {
        ...state,
        newBusiness: {
          ...state.newBusiness,
          ...action.payload,
        },
      };
    case LOGIN_USER_SUCCESS:
      newPayload = converter.snakeToCamelCase(action.payload);
      return {
        ...state,
        ...INITIAL_STATE,
        newUser: state.newUser,
        user: newPayload,
        authenticated: true,
        loading: false,
      };
    case LOGIN_USER_FAIL:
      return { ...state, errors: { login: action.payload[0] }, loading: false };

    case CREATE_USER_FAIL:
      const { email, password } = action.payload;
      return {
        ...state,
        errors: {
          email: email ? email[0] : '',
          password: password ? password[0] : '',
        },
      };
    case VALIDATE_USER:
      return { ...state, errors: INITIAL_STATE.errors, loading: true };
    case VALIDATE_USER_SUCCESS:
      newPayload = converter.snakeToCamelCase(action.payload);
      return {
        ...state,
        ...INITIAL_STATE,
        forcedNewTabSignOut: state.forcedNewTabSignOut,
        loading: false,
        user: newPayload,
        newUser: newPayload,
        business: newPayload.business,
        authenticated: true,
      };
    case VALIDATE_USER_FAIL:
      return { ...state, errors: INITIAL_STATE.errors, loading: false };
    case SIGN_OUT_USER:
      return { ...state, loading: true, errors: INITIAL_STATE.errors };
    case SIGN_OUT_USER_FAIL:
      return {
        ...state,
        errors: { signOut: action.payload[0] },
        password: '',
        loading: false,
      };
    case SIGN_OUT_USER_SUCCESS:
      return {
        ...state,
        ...INITIAL_STATE,
        user: null,
        authenticated: false,
        forcedSignOutCable: null,
      };
    case LOCATION_CHANGE:
      return { ...state, errors: INITIAL_STATE.errors };
    case SIGN_IN_ON_OTHER_DEVICE: {
      return {
        ...state,
        ...INITIAL_STATE,
        user: null,
        authenticated: false,
        forcedSignOut: true,
      };
    }
    case SIGN_IN_ON_OTHER_TAB: {
      return {
        ...state,
        ...INITIAL_STATE,
        user: null,
        authenticated: false,
        forcedNewTabSignOut: true,
      };
    }
    case CLEAR_SIGNED_IN_ON_OTHER_DEVICE: {
      return { ...state, forcedSignOut: false, forcedNewTabSignOut: false };
    }
    case LISTENING_FOR_OTHER_SIGN_IN: {
      return {
        ...state,
        forcedSignOut: false,
        forcedSignOutCable: action.cable,
      };
    }
    case PASSWORD_VAIDATION_REQUEST: {
      return {
        ...state,
        isValidatingPassword: true,
        passwordIsValid: false,
        twoFactorAuthState: TWO_FACTOR_AUTH_STATE_UNKNOWN,
        loading: true,
      };
    }

    case PASSWORD_VAIDATION_REQUEST_SUCCESS: {
      const isUserPhoneNumberExist = action.isUserPhoneNumberExist;

      return {
        ...state,
        isValidatingPassword: false,
        passwordIsValid: true,
        twoFactorAuthState: isUserPhoneNumberExist
          ? TWO_FACTOR_AUTH_STATE_ENTER_CODE
          : TWO_FACTOR_AUTH_STATE_REQUIRED,
        loading: false,
      };
    }

    case PASSWORD_VAIDATION_REQUEST_FAILURE: {
      return {
        ...state,
        isValidatingPassword: false,
        passwordIsValid: true,
        twoFactorAuthState: TWO_FACTOR_AUTH_STATE_UNKNOWN,
        loading: false,
      };
    }

    case RESET_TWO_FACTOR: {
      return {
        ...state,
        twoFactorAuthState: TWO_FACTOR_AUTH_STATE_UNKNOWN,
      };
    }

    case SEND_SMS_REQUEST: {
      return {
        ...state,
        sendingSms: true,
      };
    }

    case SEND_SMS_REQUEST_SUCCESS: {
      return {
        ...state,
        twoFactorAuthState: TWO_FACTOR_AUTH_STATE_ENTER_CODE,
        sendingSms: false,
      };
    }

    case SEND_SMS_REQUEST_FAILURE: {
      return {
        ...state,
        sendingSms: false,
      };
    }

    default:
      return state;
  }
};

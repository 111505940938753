import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Drawer from 'material-ui/Drawer';
import { Checkbox, Divider } from 'material-ui';
import { ArrowLeft } from 'Utils/SvgIcons';

const initialState = {
  filterProblems: false,
  filterDuplicates: false,
};

class ImportWizardAdjustFilterDrawer extends React.Component {
  state = initialState;

  componentDidMount() {
    const { filterData } = this.props;
    if (filterData) {
      this.setState({ ...filterData });
    }
  }

  resetState = () => {
    this.setState({ ...initialState });
  };

  handleCancel = () => {
    this.props.toggleDrawerOpen();
    this.resetState();
  };

  handleFilterByColumn = (column) => {
    this.setState({ column });
  };

  handleSubmit = () => {
    let filter = { ...this.state };
    filter.filterDuplicates = filter.filterDuplicates ? true : '';
    filter.filterProblems = filter.filterProblems ? true : '';
    this.props.handleSubmit({ ...filter }, () => {
      this.resetState();
    });
  };

  handleDuplicatedNameSelect = () => {
    this.setState((prevState) => ({
      filterDuplicates: !prevState.filterDuplicates,
    }));
  };

  handleRowsWithProblemSelect = () => {
    this.setState((prevState) => ({
      filterProblems: !prevState.filterProblems,
    }));
  };

  render() {
    return (
      <Drawer
        containerClassName='drawer'
        overlayClassName='drawerOverlay'
        width={340}
        docked={false}
        openSecondary={true}
        open={this.props.drawer}
      >
        <a className='linkBack' onClick={this.props.toggleDrawerOpen}>
          <ArrowLeft />
          Back
        </a>
        <div className='powerbiMarginX-10'>
          <div className='importWizardAdjustFilter'>
            <Checkbox
              className='unsetWidth'
              onClick={this.handleRowsWithProblemSelect}
              checked={this.state.filterProblems}
            />
            <span className='filterOptionColor'>Filter rows with problems</span>
            <Checkbox
              className='unsetWidth'
              onClick={this.handleDuplicatedNameSelect}
              checked={this.state.filterDuplicates}
            />
            <span className='filterOptionColor'>Filter Duplicated Names</span>
          </div>
        </div>
        <div className='actions powerbiMarginTop-20'>
          <div className='btn' onClick={this.handleSubmit}>
            Submit
          </div>
          <a className='btn cancel' onClick={this.handleCancel}>
            Cancel
          </a>
        </div>
      </Drawer>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;
  const { user } = state.user;

  return {
    business,
    user,
  };
};

export default connect(mapStateToProps, null)(ImportWizardAdjustFilterDrawer);

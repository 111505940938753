import {
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
  FETCH_INVENTORY_AVAILABILITY_FAILURE,
  RESET_INVENTORY_AVAILABILITY,
  SET_AVAILABILITY_LOADING_INVENTORY,
} from 'Constants/redux';
import {
  CLOSE_OVERBOOKING_MODAL,
  OPEN_OVERBOOKING_MODAL,
} from '../constants/redux';

const BASE_AVAILABILITY_TYPE = {
  validPeriodStart: new Date(), // Start of period in which the availability numbers is valid
  validPeriodEnd: new Date(), // End of period in which the availability numbers is valid
  products: {},
  accessories: {},
  addOns: {},
  bundles: {},
  loading: false,
  loadingInventory: {
    products: [],
    accessories: [],
    addOns: [],
    bundles: [],
  },
};

const INITIAL_STATE = {
  holds: {
    ...BASE_AVAILABILITY_TYPE,
  },
  overbookingModal: {
    isOpen: false,
    inventoryObject: null,
    quantityForOrder: null,
    rental: null,
    schedule: null,
    manualSubrentIsOpen: false,
    isSoftHold: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  let availabilityTypeKey = 'holds';
  switch (action.type) {
    case OPEN_OVERBOOKING_MODAL:
      return {
        ...state,
        overbookingModal: {
          isOpen: true,
          ...action.payload,
        },
      };
    case CLOSE_OVERBOOKING_MODAL:
      return {
        ...state,
        overbookingModal: {
          isOpen: false,
          inventoryObject: null,
          quantityForOrder: null,
          rental: null,
          schedule: null,
          manualSubrentIsOpen: false,
          isSoftHold: null,
        },
      };
    case FETCH_INVENTORY_AVAILABILITY_REQUEST:
      return {
        ...state,
        ...{
          [availabilityTypeKey]: {
            ...state[availabilityTypeKey],
            loading: true,
          },
        },
      };
    case FETCH_INVENTORY_AVAILABILITY_SUCCESS:
      let availabilityTypeState = {
        ...state[availabilityTypeKey],
      };

      const periodStartChanged =
        action.payload.validPeriodStart !==
        availabilityTypeState.validPeriodStart?.toISOString();

      const periodEndChanged =
        action.payload.validPeriodEnd !==
        availabilityTypeState.validPeriodEnd?.toISOString();

      // If period changes, overwrite type state since the previous results are invalidated.
      // If period is the same, merge response with current state
      if (periodStartChanged || periodEndChanged) {
        availabilityTypeState = { ...action.payload };
      } else {
        availabilityTypeState = {
          ...action.payload,
          products: {
            ...availabilityTypeState.products,
            ...action.payload.products,
          },
          accessories: {
            ...availabilityTypeState.accessories,
            ...action.payload.accessories,
          },
          addOns: { ...availabilityTypeState.addOns, ...action.payload.addOns },
          bundles: {
            ...availabilityTypeState.bundles,
            ...action.payload.bundles,
          },
        };
      }

      // Convert ISO timestamps to actual Date objects
      availabilityTypeState.validPeriodStart = new Date(
        availabilityTypeState.validPeriodStart
      );

      availabilityTypeState.validPeriodEnd = new Date(
        availabilityTypeState.validPeriodEnd
      );

      return {
        ...state,
        [availabilityTypeKey]: {
          ...availabilityTypeState,
          loadingInventory: {
            products: [],
            accessories: [],
            addOns: [],
            bundles: [],
          },
          loading: false,
        },
      };
    case FETCH_INVENTORY_AVAILABILITY_FAILURE:
      return {
        ...state,
        holds: {
          ...state.holds,
          loadingInventory: {
            products: [],
            accessories: [],
            addOns: [],
            bundles: [],
          },
          loading: false,
        },
      };
    case RESET_INVENTORY_AVAILABILITY:
      return { ...INITIAL_STATE };
    case SET_AVAILABILITY_LOADING_INVENTORY:
      return {
        ...state,
        [availabilityTypeKey]: {
          ...state[availabilityTypeKey],
          loadingInventory: { ...action.payload },
          loading: true,
        },
      };
    default:
      return state;
  }
};

import { FETCH_STAFF_ROLE_NAMES_REQUEST, EPIC_ERROR } from 'Constants/redux';
import * as actions from 'Actions';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
const { Observable } = Rx;

const FetchStaffRoleNamesEpic = (action$) =>
  action$.ofType(FETCH_STAFF_ROLE_NAMES_REQUEST).switchMap((action) => {
    return Rx.Observable.fromPromise(
      service.get(process.env.REACT_APP_API_DOMAIN + `/api/staff_roles/names`, {
        per: action.per,
        page: action.page,
        query: action.query,
        type: action.staffRoleType
      })
    )
      .map((response) => {
        return actions.receiveStaffRoleNames(response);
      })
      .catch((error) =>
        Observable.of({
          type: EPIC_ERROR,
        })
      );
  });

export default FetchStaffRoleNamesEpic;

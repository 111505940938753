import moment from 'moment-timezone';

interface FormatDateLongTimezonedArgs {
  date?: string | Date;
  timeZone: string;
}

export const formatLongTimezonedDate = ({
  date,
  timeZone,
}: FormatDateLongTimezonedArgs) => {
  if (!date) {
    return 'Invalid Date';
  }

  const momentDate = moment.utc(date);

  return momentDate
    .tz(timeZone || moment.tz.guess())
    .format('ddd, MMM DD, YYYY hh:mm A z');
};

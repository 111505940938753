import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import LoadingSpinner from 'Utils/LoadingSpinner';
import PrintStop from './PrintStop';
import PrintRouteTerminal from './PrintRouteTerminal';
import update from 'immutability-helper';
import MapContainer from './MapContainer';
import moment from 'moment';
import { FormattedTime } from 'react-intl';
import PrintLocation from './PrintLocation';

// Description: The route print page
class PrintMapRoute extends React.Component {
  state = {
    stops: [],
    routeInfo: null,
  };

  UNSAFE_componentWillMount() {
    const { truckRoute } = this.props;
    this.setState({
      startTime: truckRoute.startTime,
      stops: truckRoute.stops,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.match.params.id !== this.props.match.params.id) {
      const { fetchTruckRoute } = this.props;
      fetchTruckRoute(nextProps.match.params.id);
    }
  }

  componentDidMount() {
    const { fetchTruckRoute } = this.props;
    fetchTruckRoute(this.props.match.params.id);
  }

  handleStartTimeChange = (event) => {
    const { truckRoute, updateTruckRoute } = this.props;
    this.setState({
      startTime: event.target.value,
    });
    const data = {
      truck_route: {
        start_time: event.target.value,
      },
    };
    updateTruckRoute(truckRoute.id, data);
  };

  moveStop = (oldIndex, newIndex) => {
    const { stops } = this.state;
    const dragItem = stops[oldIndex];
    const newState = update(this.state, {
      stops: {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, dragItem],
        ],
      },
    });
    this.handlePositionChange(newState.stops);
    this.setState(newState);
  };

  handlePositionChange = (stops) => {
    const { truckRoute, updateTruckRoute } = this.props;
    const newStops = stops.map((stop, index) => {
      return {
        id: stop.id,
        position: index,
      };
    });

    const data = {
      truck_route: {
        stops_attributes: newStops,
      },
    };
    updateTruckRoute(truckRoute.id, data);
  };

  handleDirectionChange = (response) => {
    this.setState({ routeInfo: response.routes[0] });
  };

  render() {
    const { truckRoute, loading, withMap } = this.props;
    const { name, routeStart, routeEnd } = truckRoute;
    const { routeInfo } = this.state;
    const { startTime, stops } = truckRoute;
    let legs = [];
    let totalDuration = '';
    if (routeInfo) {
      legs = routeInfo.legs;
      let arrivalTime = moment(startTime);
      legs = legs.map((leg, index) => {
        const duration = leg.duration.value;
        arrivalTime = moment(arrivalTime).second(
          arrivalTime.second() + duration
        );
        const result = {
          duration: moment.duration(duration, 'seconds').humanize(),
          arrivalTime: arrivalTime.toDate(),
        };
        if (index < legs.index - 1) {
          arrivalTime = moment(arrivalTime).minute(
            arrivalTime.minute() + stops[index].setupTime
          );
        }
        return result;
      });
      totalDuration = moment
        .duration(arrivalTime.diff(moment(startTime)))
        .humanize();
    }
    let stopNumberCounter = 0;
    const stopNumbers = stops.map((stop) => {
      if (stop.stopType === 'custom' && !stop.physicalAddress) {
        return '';
      } else {
        stopNumberCounter++;
        return stopNumberCounter;
      }
    });
    return (
      <div className='contentWrapper routingPrintWrapper'>
        <div className='content'>
          <PrintLocation />
          {!loading && (
            <MapContainer
              truckRoute={truckRoute}
              onDirectionResponseChange={this.handleDirectionChange}
              displayingMap={withMap !== undefined}
            />
          )}
          {loading ? (
            <LoadingSpinner />
          ) : (
            <div className='mapDrawer routeDrawer'>
              <div className='header'>
                <label>{name}</label>
                {routeInfo && <a>({totalDuration})</a>}
              </div>
              <div className='contents'>
                <div className='start'>
                  <label>Times displayed in timezone:</label>
                  {Intl.DateTimeFormat().resolvedOptions().timeZone + " ("+(((new Date()).getTimezoneOffset()/60)*(-1))+")"}<br/>
                  <label>Set start time:</label>
                  <FormattedTime value={new Date(startTime)} />
                </div>
                <PrintRouteTerminal
                  routeTerminal={routeStart}
                  truckRouteId={truckRoute.id}
                  type='routeStart'
                />
                {stops.map((stop, index) => {
                  const stopNumber = stopNumbers[index];
                  return (
                    <PrintStop
                      key={stop.id}
                      stopNumber={stopNumber}
                      index={index}
                      lastIndex={stops.length - 1}
                      truckRouteId={truckRoute.id}
                      stop={stop}
                      moveStop={this.moveStop}
                      onMoveItem={this.moveStop}
                      leg={legs.length > 0 ? legs[index] : null}
                      truck={truckRoute.truck}
                    />
                  );
                })}
                <PrintRouteTerminal
                  routeTerminal={routeEnd}
                  truckRouteId={truckRoute.id}
                  type='routeEnd'
                  leg={legs.length > 0 ? legs[legs.length - 1] : null}
                />
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { truckRoute, loading } = state.truckRoute;
  return { truckRoute, loading };
};

export default connect(mapStateToProps, actions)(PrintMapRoute);

import {
  SET_CURRENT_TUTORIAL,
  OPEN_HELP_SIDEBAR,
  CLOSE_HELP_SIDEBAR
} from "Constants/redux";

const INITIAL_STATE = {
  open: false,
  currentTutorial: "index"
};
const HelpSidebarReducer = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CURRENT_TUTORIAL:
      return {
        ...state,
        currentTutorial: action.payload.tutorial
      };
    case OPEN_HELP_SIDEBAR:
      return {
        ...state,
        open: true
      };
    case CLOSE_HELP_SIDEBAR:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

export default HelpSidebarReducer;

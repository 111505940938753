import React, { Component } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import CsvHandler from './CsvHandler';
import Papa from 'papaparse';
import { UploadCloud } from 'Utils/SvgIcons';
import service from 'Api/service';
import { saveError } from 'Api';
import { history } from 'Components/Routes';
import ImportWizardHeader from './ImportWizardHeader';
import { setErrors } from 'Actions';

class ImportWizardUpload extends Component {
  state = {
    csvFile: null,
    dataType: '',
    uploading: false,
  };

  componentDidMount() {
    const { dataType } = this.props.match.params;

    this.setState({ dataType });
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business && !prevProps.business.permissions.importWizardEnabled) {
      history.push('/unauthorized');
    }
  }

  fileUploadComplete = (results) => {
    const { dataType } = this.state;
    this.setState({
      uploadData: JSON.stringify(results.data),
    });
    this.saveUploadedData(dataType);
  };

  fileChangeHandler = (event) => {
    const { setErrors } = this.props;

    if (!event.target.files?.length) {
      return;
    } else if (event.target.files[0].type !== 'text/csv') {
      setErrors('File must be a CSV.');
      return;
    }
    this.setState({ selectedFile: event.target.files[0] });
  };

  saveUploadedData() {
    const { uploadData, dataType } = this.state;
    service
      .post(process.env.REACT_APP_API_DOMAIN + '/api/import_data', {
        data: uploadData,
        import_type: dataType,
      })
      .then((response) => {
        this.setState({
          tableData: response.data,
        });
        this.redirectToAdjustPage();
      })
      .catch((error) => {
        let errors = error.response.data || error.response.statusText;
        saveError(errors);
        this.setState({ uploading: false });
      });
  }

  redirectToAdjustPage = () => {
    return history.push(`/importWizard/adjust/${this.state.dataType}`);
  };

  handleCancelAction = () => {
    return history.push('/importWizard');
  };

  handleAcceptFile = () => {
    const { selectedFile } = this.state;
    this.setState({ uploading: true }, () => {
      Papa.parse(selectedFile, {
        header: true,
        skipEmptyLines: true,
        complete: this.fileUploadComplete,
      });
    });
  };

  render() {
    const { uploading, selectedFile } = this.state;
    return (
      <div className='contentWrapper'>
        <ImportWizardHeader
          importStatus='upload'
          uploading={uploading}
          displayButtons={!uploading}
          onNextStep={this.handleAcceptFile}
          onCancel={this.handleCancelAction}
          headerTitle='Upload Your CSV'
        />
        <div className='ImportWizardUploadContainer'>
          <div className='importWizardUpload'>
            {(!uploading && (
              <CsvHandler
                fileHandler={this.fileChangeHandler}
                selectedFile={selectedFile}
              />
            )) || (
              <span>
                <UploadCloud /> <p>Uploading...</p>
              </span>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;

  return { business };
};

export default withRouter(
  connect(mapStateToProps, { setErrors })(ImportWizardUpload)
);

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from 'Actions';
import ImportWizardHeader from './ImportWizardHeader';
import classnames from 'classnames';
import {
  EditCircle,
  CancelCircle,
  CheckOnCircle,
  Filter as FilterIcon,
} from 'Utils/SvgIcons';
import service from 'Api/service';
import { saveError } from 'Api';
import DataHelpers from 'HelperFunctions/import_wizard';
import ReactTooltip from 'react-tooltip';
import PaginationBoxView from 'Utils/PaginationBoxView';
import Dropdown from 'Utils/Dropdown';
import ImportWizardAdjustFilterDrawer from './ImportWizardAdjustFilterDrawer';
import { history } from 'Components/Routes';
import Modal from 'Utils/Modal';
import LoadingSpinner from 'Utils/LoadingSpinner';

const dataHelpers = {
  venue: DataHelpers.VenueHelper,
  customer: DataHelpers.CustomerHelper,
  company: DataHelpers.CompanyHelper,
  item: DataHelpers.ItemHelper,
  purchase: DataHelpers.PurchaseHelper,
  accessory: DataHelpers.AccessoryHelper,
};

class ImportWizardAdjust extends Component {
  state = {
    uploading: false,
    requiredColumns: [],
    userColumns: [],
    editValues: {},
    tableData: [],
    displayColumns: [],
    errorCount: 0,
    loading: false,
    page: 1,
    perPage: 25,
    totalPages: 1,
    totalRows: 0,
    isFilterDrawerOpen: false,
    filterData: {
      filterDuplicates: '',
      filterProblems: '',
    },
    searchQuery: '',
    rowToDelete: {},
    deleteColumnConfirmation: false,
    openCancelConfirmation: false,
    lastRowDeleteConfirmation: false,
  };

  componentDidMount() {
    const { dataType } = this.props.match.params;

    this.setState({ dataType }, () => this.getOnGoingImport());
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business && !prevProps.business.permissions.importWizardEnabled) {
      history.push('/unauthorized');
    }
  }

  updateRow = (data, rowIndex, isFilteringErrorsOnly = false) => {
    let { tableData, dataType } = this.state;
    if (!JSON.parse(data.json_data).errors.length && isFilteringErrorsOnly) {
      tableData = tableData.splice(rowIndex, 1);
    } else {
      tableData[rowIndex] = dataHelpers[dataType].buildFromJson(data);
    }
    return tableData;
  };

  buildOnGoingImportData = (data = []) => {
    const { dataType } = this.state;
    return data.map((rowData) => dataHelpers[dataType].buildFromJson(rowData));
  };

  getOnGoingImport = () => {
    this.setState({ loading: true });
    const {
      page,
      perPage,
      dataType,
      filterData,
      searchQuery = '',
    } = this.state;
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
        `/api/import_data?import_type=${dataType}&page=${page}&per=${perPage}&has_errors=${filterData.filterProblems}&has_duplicated=${filterData.filterDuplicates}&query=${searchQuery}`
      )
      .then((response) => {
        const displayColumns = [
          ...new Set([
            ...response.data.expected_columns,
            ...response.data.columns,
          ]),
        ];
        this.setState({
          tableData: this.buildOnGoingImportData(response.data.data),
          errorCount: response.data.error_count,
          requiredColumns: response.data.expected_columns,
          userColumns: response.data.columns,
          unmatchedColumns: dataHelpers[dataType].countUnmatched(
            displayColumns,
            response.data.expected_columns
          ),
          displayColumns,
          loading: false,
          totalPages: response.data.meta.pagination.total_pages,
          totalRows: response.data.meta.pagination.total_objects,
        });
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  setCellEdit = (rowIndex, columnName) => {
    const { tableData, editValues } = this.state;
    tableData[rowIndex]['isEditing'] = columnName;
    editValues[rowIndex] = tableData[rowIndex][columnName];
    this.setState({ tableData, editValues });
    document.getElementById(`${rowIndex}${columnName}`).focus();
  };

  cancelCellEdit = (rowIndex, columnName) => {
    const { tableData, editValues } = this.state;
    tableData[rowIndex][columnName] = editValues[rowIndex];
    tableData[rowIndex]['isEditing'] = false;
    this.setState({ tableData });
  };

  handleSaveForLater = () => {
    this.redirectToSelectPage();
  };

  shouldReloadTable = (columnName, dataType) => {
    return (
      columnName === 'Name' ||
      (dataType === 'customer' && columnName === 'Email Address')
    );
  };

  handleCellAdjust = (cellData, rowIndex, columnName) => {
    const { dataType, filterData } = this.state;
    if (columnName == 'Name') {
      this.setState({ loading: true });
    }
    service
      .patch(
        process.env.REACT_APP_API_DOMAIN +
        `/api/import_data/${cellData.id}?import_type=${dataType}`,
        { column_name: columnName, column_data: cellData[columnName] }
      )
      .then((response) => {
        this.cancelCellEdit(rowIndex, columnName);
        if (this.shouldReloadTable(columnName, dataType)) {
          this.getOnGoingImport();
        } else {
          this.setState({
            tableData: this.updateRow(
              response.data,
              rowIndex,
              filterData.filterProblems
            ),
            errorCount: response.data.error_count,
            loading: false,
          });
        }
        ReactTooltip.rebuild();
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  buildErrorTooltip = (columnName, rowIndex) => {
    return (
      <ReactTooltip
        id={`${columnName}${rowIndex}`}
        key={`${columnName}${rowIndex}`}
        type='warning'
        multiline={true}
        clickable={true}
        delayHide={750}
        effect='solid'
        className='adjustTableErrorTooltip'
      />
    );
  };

  updateCellValue = (event, rowIndex, columnName) => {
    const { tableData } = this.state;
    tableData[rowIndex][columnName] = event.target.value;
    this.setState({ tableData });
  };

  adjustCell = (
    data,
    rowIndex,
    columnName,
    isEditing = false,
    error = null
  ) => {
    return (
      <div className='importWizardTableCell'>
        {this.buildErrorTooltip(columnName, rowIndex)}
        <span
          data-for={error ? `${columnName}${rowIndex}` : null}
          data-tip={error}
        >
          <input
            className={classnames({
              inputProblemWarning: error,
              importWizardTableInput: true,
            })}
            type='text'
            key={`${rowIndex}${columnName}`}
            id={`${rowIndex}${columnName}`}
            value={data}
            disabled={isEditing != columnName}
            onChange={(e) => this.updateCellValue(e, rowIndex, columnName)}
          />
          {isEditing != columnName ? (
            <a
              onClick={(e) => this.setCellEdit(rowIndex, columnName)}
              className='btnLink'
            >
              <EditCircle />
            </a>
          ) : (
            <span>
              <a
                onClick={(e) => this.cancelCellEdit(rowIndex, columnName)}
                className='btnLink'
              >
                <CancelCircle />
              </a>
              <a
                onClick={(e) =>
                  this.handleCellAdjust(
                    this.state.tableData[rowIndex],
                    rowIndex,
                    columnName
                  )
                }
                className='btnLink'
              >
                <CheckOnCircle />
              </a>
            </span>
          )}
        </span>
      </div>
    );
  };

  adjustRow = (columns, data, rowIndex) => {
    const { dataType } = this.state;
    return (
      <tr key={rowIndex} className='importWizardTableRow'>
        <td
          key={`Actions${rowIndex}`}
          className={classnames({
            tableRowProblem: data.errors?.length,
            actionsColumn: true,
          })}
        >
          <a
            className='btnLink actions'
            onClick={(e) => this.handleDeleteRow(rowIndex, data.id)}
          >
            <CancelCircle />
          </a>
        </td>
        {columns.map((columnName) => {
          let errors = dataHelpers[dataType].getCellErrors(
            data.errors,
            columnName
          );
          return (
            <td
              key={`${columnName}${rowIndex}`}
              className={classnames({
                cellProblemWarning: errors,
              })}
            >
              {this.adjustCell(
                data[columnName],
                rowIndex,
                columnName,
                data.isEditing,
                errors
              )}
            </td>
          );
        })}
      </tr>
    );
  };

  removeRowFromTable = (rowIndex) => {
    const { tableData } = this.state;
    tableData = tableData.splice(rowIndex, 1);
    this.setState({ tableData });
  };

  submitData = () => {
    this.props.openLoadingSpinner('Submitting adjustments');

    service
      .post(process.env.REACT_APP_API_DOMAIN + '/api/imports', {
        import_type: this.state.dataType,
      })
      .then((response) => {
        this.setState(
          {
            loading: false,
          },
          () => {
            this.redirectToDonePage();
          }
        );
      })
      .catch((error) => {
        saveError(error);
      })
      .finally(() => {
        this.props.closeLoadingSpinner();
        this.setState({ loading: false });
      });
  };

  handleDeleteRow = (rowIndex, rowId) => {
    const { totalRows } = this.state;
    if (totalRows <= 1) {
      this.setState({
        lastRowDeleteConfirmation: true,
      });
    } else {
      this.deleteRow(rowIndex, rowId);
    }
  };

  toggleLastRowDeleteModal = () => {
    this.setState((prevState) => ({
      lastRowDeleteConfirmation: !prevState.lastRowDeleteConfirmation,
    }));
  };

  deleteRow = (rowIndex, rowId) => {
    const { dataType } = this.state;
    service
      .delete(
        process.env.REACT_APP_API_DOMAIN +
        `/api/import_data/${rowId}?import_type=${dataType}`
      )
      .then((response) => {
        this.setState({
          loading: false,
          errorCount: response.data.error_count,
        });
        this.getOnGoingImport();
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  cancelCurrentImport = () => {
    const { dataType } = this.state;
    this.props.openLoadingSpinner('Aborting Import');
    service
      .delete(
        process.env.REACT_APP_API_DOMAIN +
        `/api/imports?import_type=${dataType}`
      )
      .then(() => {
        this.props.closeLoadingSpinner();
        this.redirectToSelectPage();
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  toggleDeleteModal = (columnName = '') => {
    this.setState((prevState) => ({
      deleteColumnConfirmation: !prevState.deleteColumnConfirmation,
      columnToDelete: columnName,
    }));
  };

  handleColumnChange = (newColumn, oldColumn) => {
    const { dataType } = this.state;
    this.props.openLoadingSpinner('Switching column data');
    service
      .patch(process.env.REACT_APP_API_DOMAIN + `/api/columns`, {
        import_type: dataType,
        current_column: oldColumn,
        new_column: newColumn,
      })
      .then((response) => {
        this.getOnGoingImport();
        this.props.closeLoadingSpinner();
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  submitFooter = () => {
    const { errorCount, unmatchedColumns } = this.state;
    return (
      <footer className='importWizardSubmit'>
        <a
          className={classnames({
            btn: true,
            submitBtn: !errorCount || !unmatchedColumns,
            disabled: errorCount || unmatchedColumns,
          })}
          onClick={(e) =>
            errorCount || unmatchedColumns ? '' : this.submitData()
          }
        >
          {' '}
          Submit{' '}
        </a>
        <a className='btn cancelBtn' onClick={this.toggleCancelModal}>
          {' '}
          Cancel{' '}
        </a>
      </footer>
    );
  };

  checkErrorType = (errors, columnName) => {
    return errors.some((error) => error[columnName]);
  };

  handleSearch = (searchQuery) => {
    const { searchTimeoutId } = this.state;

    if (searchTimeoutId) {
      clearTimeout(searchTimeoutId);
    }

    const timeoutId = setTimeout(() => {
      this.setState({ searchQuery }, () => this.getOnGoingImport());
    }, 1000);
    this.setState({ searchTimeoutId: timeoutId });
  };

  handleFilterSubmit = (filterData) => {
    let state = { filterData };
    state['page'] =
      filterData.filterProblems || filterData.filterDuplicates
        ? 1
        : this.state.page;
    this.setState({ ...state }, () => this.getOnGoingImport());
    this.handleFilterDrawerOpen();
  };

  handlePerChange = (per) => {
    this.setState({ perPage: per }, () => this.getOnGoingImport());
  };

  tableHeader = () => {
    return (
      <div className='filter'>
        <div className='importWizardTableFilterHeader'>
          <div>
            <input
              name='search'
              placeholder='Search'
              type='search'
              value={this.state.search}
              onChange={(e) => this.handleSearch(e.target.value)}
            />
          </div>
          <div>
            <button className='btnIcon' onClick={this.handleFilterDrawerOpen}>
              <FilterIcon />
              Filter
            </button>
            <select
              className='numberSelect'
              name='numberPer'
              onChange={(e) => this.handlePerChange(e.target.value)}
            >
              <option value='25' selected>
                25
              </option>
              <option value='50'>50</option>
            </select>
          </div>
        </div>
      </div>
    );
  };

  adjustTable = () => {
    const {
      requiredColumns,
      displayColumns,
      tableData,
      dataType,
      loading,
    } = this.state;
    return (
      <div>
        {this.tableHeader()}
        {loading ? (
          <LoadingSpinner />
        ) : (
          <table className='grid adjustTableImportWizard'>
            <tbody>
              <tr>
                <th key='Actions'></th>
                {displayColumns.map((columnName) => {
                  const dropdownColumns = requiredColumns.includes(columnName)
                    ? requiredColumns
                    : [...requiredColumns, 'Delete this column'];
                  const isColumnMatched = dataHelpers[dataType].isColumnMatched(
                    columnName,
                    requiredColumns
                  );
                  return (
                    <th key={columnName}>
                      <Dropdown
                        className={classnames({
                          importColumnUnmatched: !isColumnMatched,
                          importColumnMatched: isColumnMatched,
                        })}
                        options={dropdownColumns}
                        displayValue={requiredColumns.find(
                          (col) => col == columnName
                        )}
                        value={columnName}
                        onChange={(e) => {
                          if (e.target.value === 'Delete this column') {
                            return this.toggleDeleteModal(columnName);
                          } else {
                            this.handleColumnChange(e.target.value, columnName);
                          }
                        }}
                      />
                    </th>
                  );
                })}
              </tr>
              {tableData.map((rowData, rowIndex) => {
                return this.adjustRow(displayColumns, rowData, rowIndex);
              })}
            </tbody>
          </table>
        )}
      </div>
    );
  };

  cancelModal = () => {
    const { openCancelConfirmation } = this.state;
    return (
      <Modal
        open={openCancelConfirmation}
        hideCloseButton
        title='Cancel Import'
        actions={[
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.toggleCancelModal}
          >
            No
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.cancelCurrentImport}
          >
            Yes
          </a>,
        ]}
      >
        <p>
          Are you sure you want to cancel current import? This will delete all
          current import data.
        </p>
      </Modal>
    );
  };

  deleteColumnModal = (deleteColumnConfirmation, columnToDelete) => {
    return (
      <Modal
        open={deleteColumnConfirmation}
        hideCloseButton
        title='Are you sure you want to delete this column?'
        actions={[
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.toggleDeleteModal}
          >
            No
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.deleteColumn}
          >
            Yes
          </a>,
        ]}
      >
        <p>{columnToDelete}</p>
      </Modal>
    );
  };

  lastRowDeleteModal = (lastRowDeleteConfirmation) => {
    return (
      <Modal
        open={lastRowDeleteConfirmation}
        hideCloseButton
        title='Import Cancel'
        actions={[
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.toggleLastRowDeleteModal}
          >
            No
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.cancelCurrentImport}
          >
            Yes
          </a>,
        ]}
      >
        <p>
          Deleting the last row of this import sheet will cancel the import. Are
          you sure you want to continue?
        </p>
      </Modal>
    );
  };

  handlePageChange = (data) => {
    this.setState(
      {
        page: data.selected + 1,
      },
      () => this.getOnGoingImport()
    );
  };

  handleFilterDrawerOpen = () => {
    this.setState((prevState) => ({
      isFilterDrawerOpen: !prevState.isFilterDrawerOpen,
    }));
  };

  toggleCancelModal = () => {
    this.setState((prevState) => ({
      openCancelConfirmation: !prevState.openCancelConfirmation,
    }));
  };

  deleteColumn = () => {
    const { dataType, columnToDelete } = this.state;
    this.props.openLoadingSpinner('Deleting column');
    service
      .delete(
        process.env.REACT_APP_API_DOMAIN +
        `/api/columns?import_type=${dataType}&column_name=${columnToDelete}`
      )
      .then((response) => {
        this.setState({ deleteColumnConfirmation: false }, () => {
          this.props.closeLoadingSpinner();
          this.getOnGoingImport();
        });
      })
      .catch((error) => {
        saveError(error);
        this.setState({ loading: false });
      });
  };

  redirectToDonePage = () => {
    return history.push(`/importWizard/done/${this.state.dataType}`);
  };

  redirectToSelectPage = () => {
    return history.push('/importWizard');
  };

  render() {
    const {
      errorCount,
      totalPages,
      page,
      unmatchedColumns,
      displayColumns,
      isFilterDrawerOpen,
      deleteColumnConfirmation,
      columnToDelete,
      lastRowDeleteConfirmation,
    } = this.state;
    return (
      <div className='contentWrapper'>
        <ImportWizardHeader
          importStatus='adjust'
          onNextStep={this.submitData}
          displayButtons={true}
          onCancel={this.toggleCancelModal}
          onSaveForLater={this.redirectToSelectPage}
          disableFinalizeButton={errorCount || unmatchedColumns}
          headerTitle='Adjust Table'
        />
        <div className='ImportWizardAdjustContainer'>
          <div className='adjustTableMatches'>
            <span className='matches'>
              <b>
                {displayColumns.length - unmatchedColumns} /{' '}
                {displayColumns.length}
              </b>{' '}
              Matches
            </span>
            <span className='unmatched'>
              <b>
                {unmatchedColumns} / {displayColumns.length}
              </b>{' '}
              Unmatched
            </span>
            <span className='problems'>
              <b>{errorCount}</b> Problems
            </span>
          </div>
          {this.adjustTable()}
          <PaginationBoxView
            className='pagerWrapper'
            containerClassName='pager'
            pageCount={totalPages}
            onPageChange={this.handlePageChange}
            forcePage={page - 1}
          />
        </div>
        {this.submitFooter()}
        <ImportWizardAdjustFilterDrawer
          drawer={isFilterDrawerOpen}
          toggleDrawerOpen={this.handleFilterDrawerOpen}
          columns={displayColumns}
          handleSubmit={this.handleFilterSubmit}
        />
        {this.deleteColumnModal(deleteColumnConfirmation, columnToDelete)}
        {this.cancelModal()}
        {this.lastRowDeleteModal(lastRowDeleteConfirmation)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;

  return { business };
};

export default withRouter(
  connect(mapStateToProps, actions)(ImportWizardAdjust)
);

import React from 'react';
import { connect } from 'react-redux';
import { validateUser } from 'Actions';
import LoadingSpinner from '../../components/utils/LoadingSpinner';
import NotAuthorized from '../../components/utils/NotAuthorized';
import {
  selectCheckPermission,
  selectCheckRoles,
} from 'HelperFunctions/locations';
import { Redirect } from 'react-router-dom';

const locationPermissions = {
  settings: 'view_edit_settings',
  staffing: 'view_edit_staffing',
  routing: 'view_routing',
  operations: 'operations_view',
  reports: 'view_reports',
  view_contacts: 'view_contacts',
  edit_contacts: 'edit_add_contacts',
  multilocation: 'view_edit_ml_settings',
};

// Authorization HOC
const Authorization = (allowedRoles, permission, notWarehouse, authGeneral) => {
  return (WrappedComponent) => {
    class WithAuthorization extends React.Component {
      componentDidMount() {
        const { validateUser, authenticated } = this.props;
        if (!authenticated) {
          validateUser();
        }
      }

      render() {
        /*eslint-disable */
        const {
          user,
          loading,
          authenticated,
          location,
          locationLoading,
          validateUser,
          business,
          businessLoading,
          employeeLocationRelationship,
          checkRoles,
          checkPermission,
          ...other
        } = this.props;
        /*eslint-disable */
        if (loading || locationLoading || businessLoading) {
          return <LoadingSpinner />;
        } else if (authenticated && permission) {
          if (
            checkRoles(['warehouse_manager', 'devops']) &&
            devOpsViewOnlyRoutes.includes(this.props.match.path)
          )
            return <WrappedComponent {...other} />;
          if (checkPermission(locationPermissions[permission])) {
            if (!permission.includes('multilocation')) {
              return <WrappedComponent {...other} />;
            } else if (business.multilocationEnabled) {
              return <WrappedComponent {...other} />;
            }
            return <NotAuthorized />;
          } else {
            if (checkRoles(['warehouse_manager'])) {
              return <Redirect to={`${other.match.url}/picklist`} />;
            } else {
              return <NotAuthorized />;
            }
          }
        } else if (notWarehouse && authenticated && !checkRoles(['devops'])) {
          return <WrappedComponent {...other} />;
        } else if (authGeneral && authenticated) {
          return <WrappedComponent {...other} />;
        } else if (authenticated && checkRoles(allowedRoles)) {
          return <WrappedComponent {...other} />;
        } else {
          return <NotAuthorized />;
        }
      }
    }
    const mapStateToProps = (state) => {
      const { user, loading, authenticated } = state.auth;
      const { location } = state.location;
      const { business } = state.business;
      const locationLoading = state.location.loading;
      const businessLoading = state.business.loading;
      const { employeeLocationRelationship } = state.user;
      const checkRoles = selectCheckRoles(state);
      const checkPermission = selectCheckPermission(state);

      return {
        user,
        authenticated,
        loading,
        location,
        locationLoading,
        business,
        businessLoading,
        employeeLocationRelationship,
        checkRoles,
        checkPermission,
      };
    };
    return connect(mapStateToProps, { validateUser })(WithAuthorization);
  };
};

export default Authorization;

export const AuthAllButWarehouse = Authorization(null, null, true);

export const AuthGeneral = Authorization(null, null, null, true);

export const devOpsViewOnlyRoutes = ['/orders/rentals/:id/display'];

import React from 'react';

import { Badge } from './LocationBadge.styles';
import { LocationBadgeProps } from './LocationBadge.types';
import { useTheme } from 'styled-components';

const LocationBadge = ({ code, color }: LocationBadgeProps) => {
  const theme = useTheme();
  const colorToDisplay = color ?? theme.darkGray;

  return (
    <Badge
      className='badge'
      color={colorToDisplay}
    >
      {code}
    </Badge>
  );
}

export default LocationBadge;

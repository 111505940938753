import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  FETCH_STAFF_ROLE_NAMES_REQUEST,
  FETCH_STAFF_ROLE_NAMES_SUCCESS,
  ADD_STAFF_ROLE_NAMES_REQUEST,
  ADD_STAFF_ROLE_NAMES_SUCCESS,
  SET_STAFF_ROLE_QUERY,
  SET_STAFF_ROLE_ATTRIBUTES,
  SET_STAFF_ROLE_PAGINATION,
  SET_STAFF_ROLE_PAGINATION_PAGE,
  FETCH_STAFF_ROLE_REQUEST,
  FETCH_STAFF_ROLE_SUCCESS,
  FETCH_STAFF_ROLE_FAILURE,
} from 'Constants/redux';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

export const setStaffRoleQuery = (query) => {
  return {
    type: SET_STAFF_ROLE_QUERY,
    payload: { query },
  };
};

export const setStaffRoleAttributes = (attributes) => {
  return {
    type: SET_STAFF_ROLE_ATTRIBUTES,
    payload: { ...attributes },
  };
};

export const setStaffRolePagination = (perPage) => {
  return {
    type: SET_STAFF_ROLE_PAGINATION,
    payload: { perPage },
  };
};

export const setStaffRolePaginationPage = (page) => {
  return {
    type: SET_STAFF_ROLE_PAGINATION_PAGE,
    payload: { page },
  };
};

export const receiveStaffRoleNames = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = '';
  if (names.length > 0) {
    firstId = names[0].nameableId;
  }
  return {
    type: FETCH_STAFF_ROLE_NAMES_SUCCESS,
    payload: { names, pagination, firstId },
  };
};

export const addStaffRoleNamesSuccess = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_STAFF_ROLE_NAMES_SUCCESS,
    payload: { names, pagination },
  };
};

export const fetchStaffRoleNames = () => {
  return (dispatch, getState) => {
    const { query, per, page, staffRoleType } = getState().staffRoles;

    dispatch({
      type: FETCH_STAFF_ROLE_NAMES_REQUEST,
      query,
      per,
      page,
      staffRoleType,
    });
  };
};

export const searchStaffRoleNames = (query) => {
  return (dispatch, getState) => {
    dispatch(setStaffRoleQuery(query));
    dispatch(fetchStaffRoleNames());
  };
};

export const paginateStaffRoleNames = (perPage) => {
  return (dispatch, getState) => {
    dispatch(setStaffRolePagination(perPage));
    dispatch(fetchStaffRoleNames());
  };
};

export const paginatePageStaffRoleNames = (page) => {
  return (dispatch, getState) => {
    dispatch(setStaffRolePaginationPage(page));
    dispatch(fetchStaffRoleNames());
  };
};

export const addStaffRoleNames = (link) => {
  return (dispatch, getState) => {
    const { query } = getState().staffRoles;
    dispatch({
      type: ADD_STAFF_ROLE_NAMES_REQUEST,
      query,
      link,
    });
  };
};

export function fetchStaffRole(id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_STAFF_ROLE_REQUEST });
    dispatch(openLoadingSpinner('Updating...'));
    service
      .get(`${ROOT_URL}/staff_roles/${id}`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          id,
          response,
          type: FETCH_STAFF_ROLE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        saveError(error, getState());
        dispatch({
          id,
          error,
          type: FETCH_STAFF_ROLE_FAILURE,
        });
      });
  };
}

import React from "react";
import classnames from "classnames";

class RentalProgress extends React.Component {
  buildSteps(activeStep) {
    let deactivateOthers = activeStep === this.props.inactiveStep;

    const steps = this.props.children.map((step, index) => {
      let connector = step.props.connector;
      const connectorProps = {};
      const controlProps = { index };
      controlProps.icon = step.props.icon;

      // Unless we are deactivating the other steps, past steps are marked completed
      if (!deactivateOthers && index < activeStep) {
        controlProps.complete = true;
        connectorProps.complete = true;
      }
      // The current step is marked active
      if (index === activeStep) {
        controlProps.active = true;
        connectorProps.active = true;
      }
      // The connector after the current step is marked as in progress
      if (index === activeStep + 1) {
        connectorProps.progress = true;
      }

      return [
        React.isValidElement(connector) &&
          React.cloneElement(
            connector,
            Object.assign(connectorProps, connector.props)
          ),
        React.cloneElement(step, Object.assign(controlProps, step.props))
      ];
    });
    return steps;
  }

  render() {
    const { activeStep, className } = this.props;

    return (
      <div
        className={classnames({
          progressHolder: true,
          [className]: className
        })}
      >
        <div className="stepContainer">{this.buildSteps(activeStep)}</div>
      </div>
    );
  }
}

export default RentalProgress;

import {
  FETCH_RENTAL_TRANSPORT_REQUEST,
  FETCH_RENTAL_TRANSPORT_FAILURE,
  FETCH_RENTAL_TRANSPORT_SUCCESS
} from "Constants/redux";

const INITIAL_STATE = {
  rentalTransport: {
    startDateTime: new Date(),
    rental: {
      id: "",
      rentalItems: [],
      rentalAccessories: [],
      rentalAddOns: [],
      rentalBundles: [],
      rentalTransports: []
    }
  },
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTAL_TRANSPORT_REQUEST:
      return { ...state, loading: true };
    case FETCH_RENTAL_TRANSPORT_FAILURE:
      return { ...state, loading: false, isInfiniteLoading: false };
    case FETCH_RENTAL_TRANSPORT_SUCCESS:
      return {
        ...state,
        rentalTransport: action.response.data.rentalTransport,
        loading: false,
        shouldRefresh: false
      };
    default:
      return state;
  }
};

import service from 'Api/service';
import * as actionTypes from 'Constants/redux';
import { openLoadingSpinner, closeLoadingSpinner, setErrors } from 'Actions';
import { saveError } from 'Api';
import converter from 'json-style-converter/es5';
import { modifyLocationState } from './LocationActions';

export const fetchRoles = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_ROLES });
    dispatch(openLoadingSpinner());
    service
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/roles`)
      .then((response) => {
        const payload = converter.snakeToCamelCase(response.data.roles);
        dispatch({ type: actionTypes.FETCH_ROLES_SUCCESS, payload });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        dispatch({ type: actionTypes.FETCH_ROLES_FAIL });
        saveError(error);
        dispatch(closeLoadingSpinner());
      });
  };
};

export const toggleRoleDrawer = () => ({ type: actionTypes.TOGGLE_DRAWER });
export const setEditingRoleStatus = (status) => ({
  type: actionTypes.SET_EDITING_ROLE_STATUS,
  status,
});

export const startEditRoleAction = (role) => {
  return (dispatch, getState) => {
    dispatch(selectRole(role));
    dispatch(setEditingRoleStatus(true));
    dispatch(toggleRoleDrawer());
  };
};

export const selectRole = (role) => ({ type: actionTypes.SELECT_ROLE, role });
export const setCreatingRoleStatus = (status) => ({
  type: actionTypes.SET_CREATING_ROLE_STATUS,
  status,
});

export const startCreateRoleAction = () => {
  return (dispatch, getState) => {
    dispatch(selectRole(undefined));
    dispatch(setCreatingRoleStatus(true));
    dispatch(toggleRoleDrawer());
  };
};

export const createRole = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.CREATE_ROLE });
    dispatch(openLoadingSpinner());
    service
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/roles`, data)
      .then((response) => {
        const role = converter.snakeToCamelCase(response.data.role);
        dispatch(
          modifyLocationState({
            permissionSettings: role.permissionSettings,
          })
        );
        dispatch(closeLoadingSpinner());
        dispatch({ type: actionTypes.CREATE_ROLE_SUCCESS, role });
        dispatch(toggleRoleDrawer());
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({ type: actionTypes.CREATE_ROLE_FAIL, errors });
      });
  };
};

export const updateRole = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.UPDATE_ROLE });
    dispatch(openLoadingSpinner());
    service
      .put(
        `${process.env.REACT_APP_API_DOMAIN}/api/roles/${
          getState().roles.selectedRole.id
        }`,
        data
      )
      .then((response) => {
        const role = converter.snakeToCamelCase(response.data.role);
        dispatch(
          modifyLocationState({
            permissionSettings: role.permissionSettings,
          })
        );
        dispatch(closeLoadingSpinner());
        dispatch({ type: actionTypes.UPDATE_ROLE_SUCCESS, role });
        dispatch(toggleRoleDrawer());
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({ type: actionTypes.UPDATE_ROLE_FAIL, errors });
      });
  };
};

export const startDeleteRoleAction = (role) => {
  return (dispatch, getState) => {
    dispatch(selectRole(role));
    dispatch(toggleRoleDeleteDialog());
  };
};

export const toggleRoleDeleteDialog = () => ({
  type: actionTypes.TOGGLE_DELETE_DIALOG,
});

export const deleteRole = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.DELETE_ROLE });
    dispatch(openLoadingSpinner());
    const roleId = getState().roles.selectedRole.id;
    service
      .delete(`${process.env.REACT_APP_API_DOMAIN}/api/roles/${roleId}`)
      .then((response) => {
        dispatch({
          type: actionTypes.DELETE_ROLE_SUCCESS,
          deletedRoleId: roleId,
        });
        dispatch(toggleRoleDeleteDialog());
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch(toggleRoleDeleteDialog());
        dispatch({ type: actionTypes.DELETE_ROLE_FAIL, errors });
      });
  };
};

import {
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS,
  MODIFY_LOCATION_STATE,
} from 'Constants/redux';

const INITIAL_STATE = {
  location: {
    name: '',
    picture: {},
    physicalAddress: {},
    warehouseLatLong: {},
    displayWebsites: [],
    trucks: [],
    employees: [],
    permissionSettings: [],
    changeHistoryEntries: [],
    viewEditSettingsPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    viewEditStaffingPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },

    makeShareablePermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    shopConnectPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    acceptQuotePermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    bankAccount: null,
    softwareTier: 'all',
    stripeSubscription: {},
    marketplaceSetting: {},
    subscriptionFees: [],
    businessPaymentTerms: [],
    storefrontSetting: {},
    groupInfo: null,
    locale: null,
    posEnabled: false,
    zipCodeDeliveryFees: [],
  },
  deliverySetting: null,
  alerts: [],
  storefrontPagesVisibility: {},
  loading: false,
  smartPricingActive: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_LOCATION_REQUEST:
    case UPDATE_LOCATION_REQUEST:
      return { ...state, loading: true };
    case FETCH_LOCATION_FAILURE:
    case UPDATE_LOCATION_FAILURE:
      return { ...state, loading: false };
    case FETCH_LOCATION_SUCCESS:
    case UPDATE_LOCATION_SUCCESS:
      return {
        ...state,
        location: action.response ? action.response.location : { name: '' },
        loading: false,
        smartPricingActive: action.response ? action.response.location.smartPricingActive : false,
        // TODO Some places are using location.smartPricingActive, some are using location.location.smartPricingActive,
        // so for now we'll just update both but we should fix it at some point.
      };
    case FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS:
      return {
        ...state,
        storefrontPagesVisibility: action.response,
        loading: false,
      };
    case MODIFY_LOCATION_STATE:
      return {
        ...state,
        location: {
          ...state.location,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

import styled from 'styled-components';

interface InformationContainerProps {
  borderWidth?: number;
  borderColor?: string;
  tight?: boolean;
  isFirstItem?: boolean;
}

function handleInformationsContainerPaddingTop(
  props: InformationContainerProps
) {
  if (props.isFirstItem || !props.tight) {
    return '10px';
  }

  if (props.tight) {
    return '4px';
  }
}

export const RootContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HighlightedText = styled.span`
  font-weight: bold;
  color: #278ada;
  font-size: 12px;
  font-weight: bold;
  line-height: 20px;
`;

export const InformationText = styled.span`
  font-family: Heebo;
  letter-spacing: 0;
  line-height: 20px;
  font-size: min(0.75rem, 4vw);
  color: ${({ theme, color }) => color ?? theme.darkBlue};
  font-weight: ${({ bold }) => (bold ? 'bold' : 'normal')};
  margin-bottom: ${({ marginBottom }) => marginBottom ?? 0};
`;

export const SmallerInformationText = styled.span`
  color: ${(props) => (props.color ? props.color : `#466b88`)};
  font-weight: ${(props) => (props.bold ? 'bold' : 'normal')};
  font-family: Heebo;
  font-size: 10px;
  letter-spacing: 0;
  line-height: 20px;
`;

export const InformationContainer = styled.div<InformationContainerProps>`
  display: flex;
  width: 100%;
  border-bottom: ${(props) =>
      props.borderWidth ? `${props.borderWidth}px` : '1px'}
    solid ${(props) => (props.borderColor ? props.borderColor : '#CED8E3')};
  justify-content: space-between;
  padding-top: ${(props) => handleInformationsContainerPaddingTop(props)};
  padding-bottom: ${(props) => (props.tight ? '0px' : '10px')};
  font-size: clamp(10px, 5vw, 12px);
`;

import {
  FETCH_RENTAL_INVENTORY_LIST_REQUEST,
  PAGINATE_RENTAL_INVENTORY_LIST_REQUEST,
  SET_RENTAL_INVENTORY_FILTER,
} from 'Constants/redux';

export function setRentalInventoryFilter(filter) {
  return {
    type: SET_RENTAL_INVENTORY_FILTER,
    payload: { filter },
  };
}

export const loadRentalInventories = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      status,
      numberPer,
      startDate,
      endDate,
      startDeliveryDate,
      endDeliveryDate,
      showAll,
      query,
      page,
      itemId,
      deliveryType,
      departmentIds,
      truckIds,
      selectedDate,
      productType,
      rentalStatus,
      pickedStatus,
      subRentalSource,
      deliveryTime,
      sortField,
      sortDirection,
      listSortBy,
      pickListStatus,
    } = getState().rentalInventoryList.filter;
    const { multilocationAllLocations } = getState().dashboard;

    let zone;
    let sanitizeSelectedDate;
    startDate && (zone = -startDate.getTimezoneOffset() / 60);
    endDate && (zone = -endDate.getTimezoneOffset() / 60);
    startDeliveryDate && (zone = -startDeliveryDate.getTimezoneOffset() / 60);
    endDeliveryDate && (zone = -endDeliveryDate.getTimezoneOffset() / 60);
    selectedDate && (zone = -new Date(selectedDate).getTimezoneOffset() / 60);

    sanitizeSelectedDate =
      status && ['checking_out', 'checking_in'].includes(status)
        ? selectedDate
        : '';
    dispatch({
      type: FETCH_RENTAL_INVENTORY_LIST_REQUEST,
      params: {
        filter: status,
        numberPer,
        startDate,
        endDate,
        startDeliveryDate,
        endDeliveryDate,
        selectedDate: sanitizeSelectedDate,
        showAll,
        query,
        itemId,
        zone,
        page,
        deliveryType,
        truckIds,
        productType,
        rentalStatus,
        pickedStatus,
        subRentalSource,
        deliveryTime,
        sortField,
        sortDirection,
        listSortBy,
        departmentIds,
        pickListStatus,
        ...(prioritize_sticky && { prioritize_sticky: true }),
        ...(multilocationAllLocations && { multilocationAllLocations: true }),
      },
    });
  };
};

export function setRentalInventoryFilterAndReload(
  filter,
  prioritize_sticky = false
) {
  return (dispatch, getState) => {
    dispatch(setRentalInventoryFilter(filter));
    dispatch(loadRentalInventories(prioritize_sticky));
  };
}

export const paginateRentalInventoryList = (link) => {
  return (dispatch, getState) => {
    const {
      status,
      numberPer,
      startDate,
      endDate,
      query,
      departmentIds,
    } = getState().rentalInventoryList.filter;

    dispatch({
      type: PAGINATE_RENTAL_INVENTORY_LIST_REQUEST,
      params: {
        link,
        numberPer,
        status,
        startDate,
        endDate,
        query,
        departmentIds,
      },
    });
  };
};

import { SET_CONTACT_TYPE } from 'Constants/redux';

import * as venueActions from './VenueActions';
import * as customerActions from './customersActionCreators';
import * as companyActions from './CompanyActions';

export const setContactType = (contactType) => {
  return {
    type: SET_CONTACT_TYPE,
    payload: { contactType },
  };
};

export const setContactFilter = (contactType, filter) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.setVenueFilter(filter));
        break;
      case 'companies':
        dispatch(companyActions.setCompanyFilter(filter));
        break;
      case 'customers':
        dispatch(customerActions.setCustomerFilter(filter));
        break;
      default:
        break;
    }
  };
};

export const setContactFilterAndReload = (contactType, filter) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.setVenueFilterAndReload(filter));
        break;
      case 'companies':
        dispatch(companyActions.setCompanyFilterAndReload(filter));
        break;
      case 'customers':
        dispatch(customerActions.setCustomerFilterAndReload(filter));
        break;
      default:
        break;
    }
  };
};

export const fetchContactNamesIfNeeded = (contactType) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.fetchVenueNamesIfNeeded());
        break;
      case 'companies':
        dispatch(companyActions.fetchCompanyNamesIfNeeded());
        break;
      default:
        dispatch(customerActions.fetchCustomerNamesIfNeeded());
        break;
    }
  };
};

export const addContactNames = (contactType, link) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.addVenueNames(link));
        break;
      case 'companies':
        dispatch(companyActions.addCompanyNames(link));
        break;
      default:
        dispatch(customerActions.addCustomerNames(link));
        break;
    }
  };
};

export const searchContactNames = (contactType, query) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.searchVenues(query));
        break;
      case 'companies':
        dispatch(companyActions.searchCompanies(query));
        break;
      default:
        dispatch(customerActions.searchCustomers(query));
        break;
    }
  };
};

export const searchInfiniteContacts = (contactType, query) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.searchInfiniteVenues(query));
        break;
      case 'companies':
        dispatch(companyActions.searchInfiniteCompanies(query));
        break;
      default:
        dispatch(customerActions.searchInfiniteCustomers(query));
        break;
    }
  };
};

export const fetchInfiniteContacts = (contactType) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.fetchInfiniteVenues());
        break;
      case 'companies':
        dispatch(companyActions.fetchInfiniteCompanies());
        break;
      default:
        dispatch(customerActions.fetchInfiniteCustomers());
        break;
    }
  };
};

export const addInfiniteContacts = (contactType, link) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.addInfiniteVenues(link));
        break;
      case 'companies':
        dispatch(companyActions.addInfiniteCompanies(link));
        break;
      default:
        dispatch(customerActions.addInfiniteCustomers(link));
        break;
    }
  };
};

export const resetContacts = (contactType) => {
  return (dispatch, getState) => {
    switch (contactType) {
      case 'venues':
        dispatch(venueActions.resetVenues());
        break;
      case 'companies':
        dispatch(companyActions.resetCompanies());
        break;
      default:
        dispatch(customerActions.resetCustomers());
        break;
    }
  };
};

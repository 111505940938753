import { createSelector } from 'reselect';

export const employeeIsAllowed = (
  allowedRoles,
  employeeLocationRelationship
) => {
  return allowedRoles?.some((role) =>
    employeeLocationRelationship?.roleKeys?.includes(role)
  );
};

const selectLocation = (state) => state.location.location;
const selectEmployeeLocationRelationship = (state) =>
  state.user.employeeLocationRelationship;
const selectBusiness = (state) => state.business.business;

export const selectCheckRoles = createSelector(
  selectEmployeeLocationRelationship,
  (employeeLocationRelationship) => (allowedRoles) =>
    employeeIsAllowed(allowedRoles, employeeLocationRelationship)
);

export const selectCheckPermission = createSelector(
  selectLocation,
  selectEmployeeLocationRelationship,
  selectBusiness,
  (location, employeeLocationRelationship, business) => (permissionKey) => {
    const foundPermission = location.permissionSettings.find(
      (ps) => ps.settingType === permissionKey
    );
    const advancedPermissionsOff = !business.permissions.advancedPermissionsEnabled;
    if (foundPermission?.isAdvancedPermission && advancedPermissionsOff) {
      return true;
    } else {
      return userIsAllowedForPermission(
        location,
        permissionKey,
        employeeLocationRelationship
      );
    }
  }
);

export const userIsAllowedForPermission = (
  location,
  permissionKey,
  employeeLocationRelationship
) => {
  return employeeIsAllowed(
    allowedRolesForPermission(location, permissionKey).split(','),
    employeeLocationRelationship
  );
};

export const allowedRolesForPermission = (location, permissionKey) => {
  const foundPermission = location.permissionSettings.find(
    (ps) => ps.settingType === permissionKey
  );
  return foundPermission !== undefined ? foundPermission.allowedRoles : '';
};

export const cpHostName = (location) => {
  if (location.storefrontSetting.storefrontEnabled) {
    const domain = location.storefrontSetting.domains[0].name;
    return `https://${domain}`;
  } else {
    return convertCpHostName(location.subdomain);
  }
};

export const convertCpHostName = (subdomain) => {
  switch (process.env.REACT_APP_APP_ENV) {
    case 'production':
      return `https://${subdomain}.tapgoods.com`;
    case 'stage':
      return `https://${subdomain}.stage.tapgoods.dev`;
    case 'preprod':
      return `https://${subdomain}.preprod.tapgoods.dev`;
    default:
      return `https://${subdomain}.tapgoods.com`;
  }
};

export const addressIsEmpty = (address) => {
  const fields = ['streetAddress1','streetAddress2','city','postalCode','country'];
  let result = true
  for (let field of fields) {
    if (address[field] && address[field] !== '') {
      result = false;
    }
  }
  return result;
}

import * as actionTypes from 'Constants/redux';
import { saveError } from 'Api';
import service from 'Api/service';
import { history } from 'Components/Routes';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import { setContactType } from './ContactActions';
import {
  singleCustomerTitles,
  apiConnectionsForContact,
} from 'Constants/displayConstants';
import { fetchCompanyNames, fetchInfiniteCompanies } from './CompanyActions';
import { fetchVenueNames, fetchInfiniteVenues } from './VenueActions';

export const setCustomerQuery = (query) => {
  return {
    type: actionTypes.SET_CUSTOMER_QUERY,
    payload: { query },
  };
};

function requestCustomers() {
  return {
    type: actionTypes.REQUEST_CUSTOMERS,
  };
}
function requestCustomer() {
  return {
    type: actionTypes.REQUEST_CUSTOMER,
  };
}
const requestInfiniteCustomers = () => {
  return {
    type: actionTypes.REQUEST_INFINITE_CUSTOMERS,
  };
};
const infiniteCustomersSuccess = (response) => {
  const customers = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: actionTypes.INFINITE_CUSTOMERS_SUCCESS,
    payload: { customers, pagination },
  };
};
const addInfiniteCustomersSuccess = (response) => {
  const customers = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: actionTypes.ADD_INFINITE_CUSTOMERS_SUCCESS,
    payload: { customers, pagination },
  };
};
const infiniteCustomersFailure = () => {
  return {
    type: actionTypes.INFINITE_CUSTOMERS_FAILURE,
  };
};

function receiveCustomers(customers) {
  return {
    type: actionTypes.RECEIVE_CUSTOMERS,
    customers: customers,
    receivedAt: Date.now(),
  };
}
function receiveCustomer(customer) {
  return {
    type: actionTypes.RECEIVE_CUSTOMER,
    customer: customer,
    receivedAt: Date.now(),
  };
}
export const receiveCustomerNames = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = '';
  if (names.length > 0) {
    firstId = names[0].token;
  }
  return {
    type: actionTypes.RECEIVE_CUSTOMER_NAMES,
    payload: { names, pagination, firstId },
  };
};
export const addCustomerNamesSuccess = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: actionTypes.ADD_CUSTOMER_NAMES_SUCCESS,
    payload: { names, pagination },
  };
};

export const updateCustomerNameOnList = (response) => {
  const customer = response.data.customerLocationRelationship;
  const { id, name } = customer;
  return {
    type: actionTypes.UPDATE_CUSTOMER_NAME_ON_LIST,
    payload: { id, name },
  };
};

function fetchCustomers() {
  return (dispatch, getState) => {
    dispatch(requestCustomers());

    dispatch(setContactType('customers'));
    return service
      .get(process.env.REACT_APP_API_DOMAIN + `/api/customers/`)
      .then((response) => {
        dispatch(receiveCustomers(response.data.customers));
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}
function fetchCustomer(id) {
  return (dispatch, getState) => {
    dispatch(requestCustomer());
    return service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          `/api/customer_location_relationships/` +
          id
      )
      .then((response) => {
        dispatch(
          receiveCustomer(response.data.customerLocationRelationship.customer)
        );
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}
function shouldFetchCustomers(state) {
  const customers = state.customers;
  if (!customers) {
    return true;
  } else if (customers.isFetching) {
    return false;
  } else {
    return customers.shouldRefresh;
  }
}
function shouldFetchCustomerNames(state) {
  const customers = state.customers;
  if (!customers) {
    return true;
  } else if (customers.fetchingNames) {
    return false;
  } else {
    return customers.shouldRefresh;
  }
}
function shouldFetchSingleCustomer(state) {
  const customer = state.customer;
  if (!customer) {
    return true;
  } else if (customer.loading) {
    return false;
  } else {
    return customer.shouldRefresh;
  }
}
export const fetchInfiniteCustomers = () => {
  return (dispatch, getState) => {
    const customers = getState().customers;
    const { query } = customers;
    const link = `/api/customer_location_relationships/names`;
    dispatch(requestInfiniteCustomers());
    service
      .get(process.env.REACT_APP_API_DOMAIN + link, { per: 25, query: query })
      .then((response) => {
        dispatch(infiniteCustomersSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteCustomersFailure());
        saveError(error);
      });
  };
};

export const setCustomerSelectedIdsForExport = (selectedIdsForExport) => ({
  type: actionTypes.SET_CUSTOMER_SELECTED_IDS_FOR_EXPORT,
  selectedIdsForExport,
});

export const handleCustomerSelectAll = (isChecked) => (dispatch, getState) => {
  if (isChecked) {
    const { names } = getState().customers;
    const newSelectedIdsForExport = names.reduce(
      (hash, customer) => ({ ...hash, [customer.id]: true }),
      {}
    );
    dispatch(setCustomerSelectedIdsForExport(newSelectedIdsForExport));
  } else {
    dispatch(setCustomerSelectedIdsForExport({}));
  }
};

export const handleCustomerSelect = (id, isChecked) => (dispatch, getState) => {
  const newSelectedIdsForExport = {
    ...getState().customers.selectedIdsForExport,
  };
  if (isChecked) {
    newSelectedIdsForExport[id] = true;
  } else {
    delete newSelectedIdsForExport[id];
  }
  dispatch(setCustomerSelectedIdsForExport(newSelectedIdsForExport));
};

export const setCustomerFilter = (filter) => ({
  type: actionTypes.SET_CUSTOMER_FILTER,
  filter,
});

export const setCustomerFilterAndReload = (filter) => (dispatch, getState) => {
  dispatch(setCustomerFilter(filter));
  dispatch(fetchCustomerNames());
};

export const fetchCustomerNames = () => {
  return (dispatch, getState) => {
    dispatch(setContactType('customers'));
    const { filter } = getState().customers;
    dispatch({
      type: actionTypes.REQUEST_CUSTOMER_NAMES,
      filter,
    });
  };
};

export const deleteContact = (id, customerType) => {
  return (dispatch, getState) => {
    dispatch(
      openLoadingSpinner(`Deleting ${singleCustomerTitles[customerType]}...`)
    );
    return service
      .delete(
        process.env.REACT_APP_API_DOMAIN +
          `/api/${apiConnectionsForContact[customerType]}/` +
          id
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        if (customerType == 'companies') {
          dispatch(fetchCompanyNames());
          dispatch(fetchInfiniteCompanies());
        } else if (customerType == 'venues') {
          dispatch(fetchVenueNames());
          dispatch(fetchInfiniteVenues());
        } else {
          dispatch(fetchCustomerNames());
          dispatch(fetchInfiniteCustomers());
        }
        history.push(`/contacts/${customerType}`);
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
      });
  };
};

const searchCustomerNames = (query) => {
  return (dispatch, getState) => {
    dispatch(setCustomerQuery(query));
    dispatch(fetchCustomerNames());
  };
};

export const addCustomerNames = (link) => {
  return (dispatch, getState) => {
    const { query } = getState().customers;
    dispatch({
      type: actionTypes.ADD_CUSTOMER_NAMES_REQUEST,
      query,
      link,
    });
  };
};

export const searchCustomers = (query) => {
  return (dispatch, getState) => {
    dispatch(searchCustomerNames(query));
  };
};
export const fetchCustomersIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchCustomers(getState())) {
      return dispatch(fetchCustomers());
    }
  };
};
export const fetchCustomerNamesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchCustomerNames(getState())) {
      return dispatch(fetchCustomerNames());
    }
  };
};
export const fetchSingleCustomerIfNeeded = (id) => {
  return (dispatch, getState) => {
    if (shouldFetchSingleCustomer(getState())) {
      return dispatch(fetchCustomer(id));
    }
  };
};

export const addInfiniteCustomers = (link) => {
  return (dispatch, getState) => {
    const customers = getState().customers;
    const { query } = customers;
    dispatch(requestInfiniteCustomers());
    console.warn(process.env.REACT_APP_API_DOMAIN + link);
    service
      .get(process.env.REACT_APP_API_DOMAIN + link, { per: 25, query })
      .then((response) => {
        dispatch(addInfiniteCustomersSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteCustomersFailure());
        saveError(error);
      });
  };
};
export const searchInfiniteCustomers = (query) => {
  return (dispatch, getState) => {
    dispatch(setCustomerQuery(query));
    dispatch(fetchInfiniteCustomers());
  };
};
export const resetCustomers = () => {
  return (dispatch, getState) => {
    dispatch(setCustomerQuery(''));
    dispatch(setContactType('customers'));
    dispatch(fetchInfiniteCustomers());
  };
};

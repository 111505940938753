import { Maybe, PhysicalAddress } from '../../../../../types/graphql';

export const handleContactAddress = (data: Maybe<PhysicalAddress>) => {
  if (!data) {
    return '';
  }

  return `${data?.city}, ${data?.locale}, ${data?.postalCode}, ${data?.country}
`;
};

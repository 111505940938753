import { TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL } from 'Constants/redux';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';

export const toggleNonDiscountablesAlertModal = (
  status: boolean,
  nonDiscountableItems: any[],
  kind: "itemAdded" | "discountAdded" = "itemAdded",
  onSubmit: () => void = () => {}
): ThunkAction<void, AppState, unknown, RentalFormActionTypes> => (
  dispatch
) => {
  dispatch({
    type: TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL,
    status,
    nonDiscountableItems,
    kind,
    onSubmit
  });
};

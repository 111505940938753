import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Alert, ArrowLeft, ArrowRight, RemoveCircle} from "Utils/SvgIcons";
import {withRouter} from "react-router-dom";
import {requestForActivation, hideAlertsTrialMsgOnCross, shouldShowAlertTrial, shouldHideAlertTrial} from "Actions";

class TopWarningTrial extends React.Component {
  state = {
    showTrialAlert: true
  };

  hideAlertTrial = () => {
    this.setState({showTrialAlert: false});
    this.props.hideAlertsTrialMsgOnCross();
  };

  shouldShow() {
    const {showTrialAlert} = this.state;
    const {alertsTrial, location, shouldShowAlertTrial, shouldShowAlertTrialProp, authenticated} = this.props;
    const tgAdmin = location.pathname.toLowerCase().startsWith("/tg-admin");

    if (tgAdmin || !authenticated) {
      return false;
    } else if (!!showTrialAlert && !!alertsTrial.type) {
      if(!shouldShowAlertTrialProp){
        shouldShowAlertTrial();
      }
      return true;
    }
  }

  componentWillUnmount() {
    const {shouldHideAlertTrial} = this.props;
    shouldHideAlertTrial();
  }

  render() {
    const {alertsTrial, requestForActivation} = this.props;

    if (this.shouldShow()) {
      return (
        <div>
          {<div className={`topWarning topWarningTrial ${alertsTrial.type}`}>
            {alertsTrial.message(
              <Link to={alertsTrial.link.url} onClick={(e) => {
                e.preventDefault();
                requestForActivation();
              }}>{alertsTrial.link.text}</Link>
            )}
            {(alertsTrial.type === 'success') && <a className='action' onClick={this.hideAlertTrial}>
              <RemoveCircle/>
            </a>}
          </div>}
        </div>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = state => {
  const {authenticated} = state.auth;
  const {alertsTrial} = state.business;
  const shouldShowAlertTrialProp = state.business.shouldShowAlertTrial;
  return {authenticated, alertsTrial, shouldShowAlertTrial, shouldShowAlertTrialProp};
};

export default withRouter(connect(mapStateToProps, {
  requestForActivation,
  hideAlertsTrialMsgOnCross,
  shouldShowAlertTrial,
  shouldHideAlertTrial
})(TopWarningTrial));

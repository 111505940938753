import React from 'react';

import { Notifier } from '@airbrake/browser';

export const AirbrakeNotifier = new Notifier({
  projectId: process.env.REACT_APP_AIRBRAKE_PROJECT_ID,
  projectKey: process.env.REACT_APP_AIRBRAKE_PROJECT_KEY,
  environment: process.env.REACT_APP_APP_ENV,
});

export default class Airbrake extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
    this.airbrake = AirbrakeNotifier;
  }

  componentDidCatch(error, info) {
    // Display fallback UI
    this.setState({ hasError: true });
    // Send error to Airbrake
    if (process.env.REACT_APP_APP_ENV !== 'development') {
      this.airbrake.notify({
        error: error,
        params: { info: info },
      });
    }
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <h1>Something went wrong.</h1>;
    }
    return this.props.children;
  }
}

import * as actionTypes from 'Constants/redux';

export const INITIAL_GL_LINE_ITEM_STATE = {
  id: null,
  description: '',
  accountNumber: '',
  shouldTrackInventoryWithoutDepartment: false,
  shouldTrackProductsWithoutDepartment: false,
  shouldTrackBundlesWithoutDepartment: false,
  shouldTrackAddOnsWithoutDepartment: false,
  glLineItemMappableRelationships: [],
  indexedGlLineItemMappingRelationships: {},
  glCategory: {
    id: -1,
    name: '',
  },
  glLineItemDepartmentRelationships: [],
  // API expects Array, but in-memory it's stored as Set to maximize look-up performance.
  excludedProducts: new Set(),
  excludedBundles: new Set(),
  excludedAddOns: new Set(),
};

const INITIAL_STATE = {
  glCategories: [],
  selectedGlLineItem: { ...INITIAL_GL_LINE_ITEM_STATE },
  glLineItems: [],
  drawer: false,
  dialog: false,
  dialogData: {},
  loading: false,
  loadingGlLineItem: false,
};

function generalLedger(state = INITIAL_STATE, action) {
  let updatedState;
  let glLineItems;
  let index;

  switch (action.type) {
    case actionTypes.SELECT_GL_LINE_ITEM:
      const selectedGlLineItem = state.glLineItems.find(
        (item) => item.id === action.id
      );

      if (!selectedGlLineItem) {
        console.log('Error when selecting line item');
        return state;
      }

      updatedState = {
        ...state,
        selectedGlLineItem: {
          ...state.selectedGlLineItem,
          ...selectedGlLineItem,
        },
      };

      return updatedState;
    case actionTypes.CLEAR_SELECTED_GL_LINE_ITEM:
      updatedState = Object.assign({}, state, {
        selectedGlLineItem: INITIAL_STATE.selectedGlLineItem,
      });

      return updatedState;
    case actionTypes.FETCH_GL_LINE_ITEM_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_GL_LINE_ITEM_FAILURE:
      return { ...state, loading: false };
    case actionTypes.FETCH_GL_LINE_ITEM_SUCCESS:
      return {
        ...state,
        selectedGlLineItem: {
          ...state.selectedGlLineItem,
          ...action.response.glLineItem,
        },
        loading: false,
      };
    case actionTypes.FETCH_GL_LINE_ITEMS_REQUEST:
      return { ...state, loadingGlLineItems: true };
    case actionTypes.FETCH_GL_LINE_ITEMS_FAILURE:
      return { ...state, loadingGlLineItems: false };
    case actionTypes.FETCH_GL_LINE_ITEMS_SUCCESS:
      updatedState = {
        ...state,
        glLineItems: [...action.response.glLineItems],
        loadingGlLineItems: false,
      };

      return updatedState;
    case actionTypes.FETCH_GL_CATEGORIES_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_GL_CATEGORIES_FAILURE:
      return { ...state, loading: false };
    case actionTypes.FETCH_GL_CATEGORIES_SUCCESS:
      return {
        ...state,
        glCategories: [...action.response.glCategories],
        loading: false,
      };
    case actionTypes.CREATE_GL_LINE_ITEM_REQUEST:
      return { ...state, loading: true };
    case actionTypes.CREATE_GL_LINE_ITEM_FAILURE:
      return { ...state, loading: false };
    case actionTypes.CREATE_GL_LINE_ITEM_SUCCESS:
      glLineItems = [...state.glLineItems];

      glLineItems.push(action.response.glLineItem);

      return {
        ...state,
        glLineItems,
        loading: false,
      };
    case actionTypes.UPDATE_GL_LINE_ITEM_REQUEST:
      return { ...state, loading: true };
    case actionTypes.UPDATE_GL_LINE_ITEM_FAILURE:
      return { ...state, loading: false };
    case actionTypes.UPDATE_GL_LINE_ITEM_SUCCESS:
      glLineItems = [...state.glLineItems];

      index = glLineItems.findIndex(
        (item) => item.id === action.response.glLineItem.id
      );

      glLineItems[index] = action.response.glLineItem;

      return {
        ...state,
        glLineItems,
        loading: false,
      };

    case actionTypes.DELETE_GL_LINE_ITEM_REQUEST:
      return state;
    case actionTypes.DELETE_GL_LINE_ITEM_SUCCESS:
      glLineItems = [...state.glLineItems];

      index = glLineItems.findIndex(
        (item) => item.id === action.response.glLineItem.id
      );

      glLineItems.splice(index, 1);

      return { ...state, glLineItems };
    case actionTypes.OPEN_GENERAL_LEDGER_SETTINGS_DRAWER:
      return { ...state, drawer: true };
    case actionTypes.CLOSE_GENERAL_LEDGER_SETTINGS_DRAWER:
      return { ...state, drawer: false };
    case actionTypes.OPEN_GENERAL_LEDGER_SETTINGS_DIALOG:
      return { ...state, dialog: true, dialogData: action.payload };
    case actionTypes.CLOSE_GENERAL_LEDGER_SETTINGS_DIALOG:
      return { ...state, dialog: false, dialogData: {} };
    default:
      return state;
  }
}

export default generalLedger;

import React from "react";
import Modal from "Utils/Modal";
import * as actions from "Actions";
import { connect } from "react-redux";
import { RemoveCircle } from "Utils/SvgIcons";
import Endpoints from "Api/endpoints";
import ScrollArea from "Utils/react-scrollbar";

class SerializedMultipleCheckedInModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      serialNumber: "",
      quantity: "",
      isSerializedMode: true,
      checkedInQuantity: ""
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSerialNumberReset = () => {
    this.setState({
      serialNumber: "",
      quantity: ""
    });
  };

  performSearch = event => {
    event.preventDefault();
    const { setErrors, item } = this.props;
    const { serialNumber, quantity } = this.state;
    const component = this;

    if (quantity === "" || +quantity <= 0) {
      setErrors("Invalid quantity");
      return;
    }

    Endpoints.serializedProducts
      .scanOneCheckIn(item, serialNumber)
      .then(response => {
        const { selected } = component.state;
        const { serializedProduct } = response.data;
        const foundIndex = selected.findIndex(
          sp => sp.serialNumber === serializedProduct.serialNumber
        );
        if (foundIndex === -1) {
          component.setState({
            selected: [
              ...selected,
              { ...serializedProduct, maintenanceQuantity: quantity }
            ],
            serialNumber: "",
            quantity: ""
          });
        } else {
          setErrors("Serial number already added.");
          component.setState({
            serialNumber: "",
            quantity: ""
          });
        }
      })
      .catch(error => {
        if (error.response) {
          if (error.response.status === 422) {
            setErrors(error.response.data);
          } else {
            setErrors("Oops! an unknown error occurred during check in");
          }
        } else {
          setErrors("Oops! an unknown error occurred during check in");
        }
        console.log(error);
      });
  };

  handleSubmit = () => {
    const { isSerializedMode } = this.state;
    if (isSerializedMode) {
      this.handleSerializedSubmit();
    } else {
      this.handleStandardSubmit();
    }
  };

  handleSerializedSubmit = () => {
    const { selected } = this.state;

    // Validation
    if (selected.length === 0) {
      this.props.setErrorMessage({
        field: "selected",
        text: "You need to select at least one serialized item."
      });
    } else {
      const {
        rental,
        item,
        serializedMultipleCheckInRentalItem,
        toggle
      } = this.props;
      const selectedSp = selected[0];
      const checkedInQuantity = selectedSp.maintenanceQuantity;
      const checkedInId = selectedSp.id;
      serializedMultipleCheckInRentalItem(
        rental.id,
        item.id,
        checkedInQuantity,
        checkedInId
      );
      toggle();
    }
  };

  handleStandardSubmit = () => {
    const { checkedInQuantity } = this.state;

    // Validation
    if (checkedInQuantity < 0 || checkedInQuantity > this.props.item.quantity) {
      this.props.setErrorMessage({
        field: "checkedInQuantity",
        text: "Cannot checkout more than reserved"
      });
    } else {
      const { rental, item, checkInRentalItem, toggle } = this.props;
      checkInRentalItem(rental.id, item.id, checkedInQuantity);
      toggle();
    }
  };

  removeItem = serialNumber => {
    const newSelected = this.state.selected.filter(
      s => s.serialNumber !== serialNumber
    );
    this.setState({
      selected: newSelected
    });
  };

  toggleIsSerializedMode = () => {
    this.setState({
      isSerializedMode: !this.state.isSerializedMode
    });
  };

  render() {
    const { product, open, toggle } = this.props;
    const {
      serialNumber,
      quantity,
      selected,
      isSerializedMode,
      checkedInQuantity
    } = this.state;

    return (
      <Modal
        open={open}
        toggle={toggle}
        large={true}
        className="serializedModal"
        title="Check In Serialized Inventory"
        actions={[
          <a className="btn" onClick={this.handleSubmit}>
            Submit And Update Rental Inventory
          </a>,
          <a className="btn cancel" onClick={toggle}>
            Cancel
          </a>
        ]}
      >
        <div className="form">
          <p>{product.name}</p>
          {isSerializedMode ? (
            <div>
              <form className="fullForm" onSubmit={this.performSearch}>
                <input
                  type="text"
                  name="serialNumber"
                  className="barcode"
                  onChange={this.handleChange}
                  value={serialNumber}
                />
                <input
                  type="text"
                  name="quantity"
                  onChange={this.handleChange}
                  value={quantity}
                />
                <input
                  type="submit"
                  value="Add Item to Check-In List"
                  className="btn"
                />
                <button
                  className="btn cancel"
                  onClick={this.handleSerialNumberReset}
                >
                  Cancel
                </button>
              </form>
              <section>
                <h4>Total</h4>
                <strong>{selected.length}</strong>
                <ScrollArea speed={0.8} horizontal={false}>
                  <table className="grid">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th className="actionSm" />
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((s, index) => (
                        <tr key={index}>
                          <td>{s.serialNumber}</td>
                          <td>
                            <a
                              className="btnLink remove"
                              onClick={() => this.removeItem(s.serialNumber)}
                            >
                              <RemoveCircle />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollArea>
              </section>
            </div>
          ) : (
            <div className="fields">
              <p>How many items need to be Checked-In?</p>
              <input
                type="text"
                name="checkedInQuantity"
                className="sm"
                onChange={this.handleChange}
                value={checkedInQuantity}
              />
            </div>
          )}
          <a onClick={this.toggleIsSerializedMode}>
            {isSerializedMode
              ? "Check In Serialized Items without using Serial Numbers"
              : "Check In Serialized Items using Serial Numbers"}
          </a>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { rental, loading } = state.rental;
  const { errors } = state.dashboard;
  return { rental, loading, errors };
};

export default connect(
  mapStateToProps,
  actions
)(SerializedMultipleCheckedInModal);

import { ROOT_URL, saveError } from "Api";
import { standardService } from "Api/service";
import {
  FETCH_FAQS_REQUEST,
  FETCH_FAQS_SUCCESS,
  FETCH_FAQS_FAILURE
} from "Constants/redux";

export function fetchFaqs() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_FAQS_REQUEST });
    standardService
      .get(`${ROOT_URL}/admin/faqs`)
      .then(response => {
        return dispatch({
          response,
          type: FETCH_FAQS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_FAQS_FAILURE
        });
      });
  };
}

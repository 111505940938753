import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import classnames from 'classnames';
import { ArrowUp, ArrowDown } from './SvgIcons';

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }
  UNSAFE_componentWillMount() {
    if (this.props.open) {
      this.setState({
        open: this.props.open,
      });
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.forceOpen !== undefined &&
      this.props.forceOpen !== nextProps.forceOpen
    ) {
      this.setState({ open: nextProps.forceOpen });
    }
  }
  toggleOpen = (event) => {
    const { permissionInfo, setErrors } = this.props;
    if (
      this.props.hasOwnProperty('permissionInfo') &&
      !permissionInfo.allowed
    ) {
      setErrors(permissionInfo.errorMessage);
      return;
    }

    if (
      event.target.name !== 'link' &&
      !event.target.id?.startsWith('adv-permission-toggle')
    ) {
      const { name, onToggle } = this.props;
      if (onToggle) {
        const fakeEvent = {
          target: {
            value: !this.state.open,
            name: name,
          },
        };
        onToggle(fakeEvent);
      }

      this.setState({
        open: !this.state.open,
      });
    }
  };

  render() {
    const {
      id,
      className,
      header,
      content,
      useHeaderAsLink,
      toggleIconOpen,
      toggleIconClosed,
      customToggles,
      grayBG,
      headerBackgroundColor,
      border,
      hasBorderBottom,
      appendToHeader,
    } = this.props;
    const { open } = this.state;
    let openIcon;
    let closeIcon;
    if (customToggles) {
      openIcon = toggleIconOpen;
      closeIcon = toggleIconClosed;
    } else {
      openIcon = <ArrowUp />;
      closeIcon = <ArrowDown />;
    }
    const headerLinkProps = useHeaderAsLink ? { onClick: this.toggleOpen } : {};

    let contentEvaluated =
      content instanceof Function
        ? content({ onClose: this.toggleOpen })
        : content;

    return (
      <div
        id={id}
        className={'accordian ' + (className ? className : '')}
        onMouseEnter={this.props.onMouseEnter || null}
        onMouseLeave={this.props.onMouseLeave || null}
      >
        {content && (
          <a
            id={id ? id + '_toggle' : null}
            onClick={this.toggleOpen}
            className={classnames({
              btnCollapse: open,
              btnExpand: !open,
            })}
          >
            {open ? closeIcon : openIcon}
          </a>
        )}
        <div
          className={classnames({
            header: true,
            grayBG: grayBG && open,
            accordionBorderBottom: open && hasBorderBottom
          })}
          style={{ backgroundColor: headerBackgroundColor || null }}
          onClick={this.toggleOpen}
        >
          {header}
          {open && appendToHeader}
        </div>
        {open && content && (
          <div
            className={classnames({
              content: true,
              grayBG: grayBG && open,
              accordionBorder: border,
            })}
            style={{ borderColor: headerBackgroundColor }}
          >
            {contentEvaluated}
          </div>
        )}
      </div>
    );
  }
}

Accordion.defaultProps = {
  onToggle: () => { },
  useHeaderAsLink: true,
};

export default connect(null, actions)(Accordion);

import AppState from '../../types/AppState';
import { store } from '../../components/Root';
import RentalSectionsAttributes from '../../types/RentalSectionsAttributes';

// Returns section data for a given ID or temporary ID from the rental form store
// ! This function should be called from within mapStateToProps
// ! That will ensure that the component re-renders when there's a change to the store
export const selectRentalSectionFromRentalVersionStore = (
  store: AppState,
  rentalVersionId: number | string
): RentalSectionsAttributes => {
  const { rentalFormV2 } = store;

  return rentalFormV2.data?.rentalSectionsAttributes?.find(
    (rentalSections: any) =>
      rentalSections.rvId === rentalVersionId ||
      rentalSections.id === rentalVersionId ||
      rentalSections.reactTemporaryIdentifier === rentalVersionId
  );
};

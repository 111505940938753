import {
  SET_ASSOCIATED_CONTACT_VENUES,
  TOGGLE_CONTACT_VENUES_MODAL,
  TOGGLE_VENUE_SEARCH_LIST_DRAWER,
  SET_VENUE,
  SET_VENUE_RENTAL_RELATIONSHIP,
  RESET_VENUE_FORM,
  SET_VENUE_SEARCH_LIST_DRAWER_STATE,
  SET_SHOULD_ADD_VENUE_DOCUMENTS,
  SET_SHOULD_ADD_VENUE_NOTE,
  SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE,
} from 'Constants/redux';

const INITIAL_STATE = {
  associatedContactsVenues: [],
  selectContactVenuesOpen: false,
  venueSearchListDrawerOpen: false,
  venueRentalRelationship: null,
  venue: null,

  // ReusableForm Callback States
  shouldAddVenueDocuments: false,
  shouldAddVenueNote: false,
  shouldChangeDeliveryAddressSourceTypeToVenue: false,
};

function VenueReducer(state = INITIAL_STATE, action) {
  switch (action.type) {
    case TOGGLE_CONTACT_VENUES_MODAL:
      return {
        ...state,
        selectContactVenuesOpen: !state.selectContactVenuesOpen,
      };
    case TOGGLE_VENUE_SEARCH_LIST_DRAWER:
      return {
        ...state,
        venueSearchListDrawerOpen: !state.venueSearchListDrawerOpen,
      };
    case SET_VENUE_SEARCH_LIST_DRAWER_STATE:
      return {
        ...state,
        venueSearchListDrawerOpen: action.open,
      };
    case SET_ASSOCIATED_CONTACT_VENUES:
      return {
        ...state,
        associatedContactsVenues: action.venues,
      };
    case SET_VENUE:
      return {
        ...state,
        venue: action.venue,
      };
    case SET_VENUE_RENTAL_RELATIONSHIP:
      return {
        ...state,
        venueRentalRelationship: action.venueRentalRelationship,
      };
    case SET_SHOULD_ADD_VENUE_DOCUMENTS:
      return {
        ...state,
        shouldAddVenueDocuments: action.boolean,
      };
    case SET_SHOULD_ADD_VENUE_NOTE:
      return {
        ...state,
        shouldAddVenueNote: action.boolean,
      };
    case SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE:
      return {
        ...state,
        shouldChangeDeliveryAddressSourceTypeToVenue: action.boolean,
      };
    case RESET_VENUE_FORM:
      return INITIAL_STATE;
    default:
      return state;
  }
}

export default VenueReducer;

import React from "react";

const UnsupportedBrowser = () => (
  <div className="errorLanding">
    <p>
      TapGoods isn’t currently supported by this browser. <br />
      We’ve optimized our online software for Google Chrome.
    </p>
    <h1>WWW</h1>
    <a
      href="https://www.google.com/chrome/b/"
      className="btn"
      target="_blank"
      rel="noopener noreferrer"
    >
      Download Chrome
    </a>
  </div>
);

export default UnsupportedBrowser;

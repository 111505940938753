import styled from 'styled-components';

export const RootContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const MidSignatureContainer = styled.div`
  width: 60%;
  display: flex;
  padding-top: 2.25rem;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-end;
`;

export const BottomSignatureContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`;

export const RightDateContainer = styled(MidSignatureContainer)`
  width: 20%;
`;

import {
  FETCH_TRUCK_ROUTE_REQUEST,
  FETCH_TRUCK_ROUTE_FAILURE,
  FETCH_TRUCK_ROUTE_SUCCESS,
  ADDED_WAYPOINTS_TO_TRUCK_ROUTE,
  RESET_ERROR_ATTRIBUTE
} from "Constants/redux";

const INITIAL_STATE = {
  truckRoute: {
    name: "",
    startTime: null,
    deliveryDate: new Date(),
    stops: [],
    routeStart: {},
    routeEnd: {},
    truck: {},
    rentalItems: []
  },
  loading: false,
  submitFailed: false,
  errors: {}
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TRUCK_ROUTE_REQUEST:
      return { ...state, loading: true, errors: {} };
    case FETCH_TRUCK_ROUTE_FAILURE:
      return { ...state, loading: false, errors: action?.error?.response?.data, submitFailed: true };
    case FETCH_TRUCK_ROUTE_SUCCESS:
      const newChange = action.response
        ? action.response.truckRoute
        : INITIAL_STATE.truckRoute;

      return {
        ...state,
        errors: {},
        truckRoute: { ...state.truckRoute, ...newChange },
        loading: false,
        submitFailed: false
      };
    case ADDED_WAYPOINTS_TO_TRUCK_ROUTE:
      return {
        ...state,
        truckRoute: {
          ...state.truckRoute,
          waypoints: action.waypoints,
        }
      };
    case RESET_ERROR_ATTRIBUTE:
      return {
        ...state,
        errors: {},
        submitFailed: false,
      };
    default:
      return state;
  }
};

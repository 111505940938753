import * as actionTypes from 'Constants/redux';

function calendars(
  state = {
    selectedDate: new Date(),
    datesArray: [],
    secondaryDatesArray: [],
    mobileView: {
      calendarViewState: true,
    },
    loading: false,
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_SELECTED_DATE:
      return Object.assign({}, state, {
        selectedDate: action.payload.selectedDate,
        mobileView: {
          calendarViewState: false,
        },
      });
    case actionTypes.RESET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: new Date(),
      };
    case actionTypes.FETCH_DATES_ARRAY:
      return {
        ...state,
        loading: true,
        mobileView: {
          calendarViewState: true,
        },
      };
    case actionTypes.FETCH_DATES_ARRAY_SUCCESS:
      return {
        ...state,
        datesArray: action.datesArray,
        loading: false,
      };
    case actionTypes.FETCH_DATES_ARRAY_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.CHANGE_MOBILE_VIEW_CALENDAR_STATE:
      return {
        ...state,
        mobileView: {
          calendarViewState: action.openStatus,
        },
      };
    case actionTypes.SET_SECONDARY_DATES_ARRAY:
      return {
        ...state,
        secondaryDatesArray: action.secondaryDatesArray,
      };
    default:
      return state;
  }
}

export default calendars;

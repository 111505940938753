import { adminService } from "../api/service"
import { ROOT_URL } from "Api";
import { adminUserLogin } from "./AuthActions";
import { ADMIN_LOGIN_USER, LOGIN_USER_FAIL } from "Constants/redux";
import { history } from "Components/Routes";

export const adminGetRentalAndBecome = (id) => {
  return (dispatch, getState) => {
    if (getState().auth.loading) return

    dispatch({
      type: ADMIN_LOGIN_USER
    })

    adminService
      .get(`${ROOT_URL}/admin/rentals/${id}`)
      .then(response => {
        const {
          locationId,
          businessId
        } = response.data.rental;

        adminService
          .get(`${ROOT_URL}/admin/locations/${locationId}/employees/first_super_admin`)
          .then(response => {
            const employeeId = response.data.employee.id;

            history.location.state = {
              from: `/orders/rentals/${id}`
            }

            dispatch(adminUserLogin(businessId, locationId, employeeId));
          })
          .catch(error => {
            dispatch({
              type: LOGIN_USER_FAIL,
            });
          })
      })
      .catch(error => {
        dispatch({
          type: LOGIN_USER_FAIL,
        });
      })
  }
}
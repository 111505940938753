import React from "react";
import { Link } from "react-router-dom";

const ApplicationError = () => {
  return (
    <div className="errorLanding">
      <p>Well, this is embarrasing. This page broke. </p>
      <h1>500</h1>
      <Link to="/" className="btn">
        Take me home
      </Link>
    </div>
  );
};
export default ApplicationError;

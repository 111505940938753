import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import classnames from "classnames";
import Autocomplete from "react-autocomplete";
import validations from "HelperFunctions/validations";
import { Invitations } from "Utils/SvgIcons";
import AutocompleteWithAPI from "HelperFunctions/AutocompleteWithAPI";
import service from "Api/service";

class NewConversation extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      customer: {
        name: ""
      },
      rental: {
        name: ""
      },
      body: ""
    };
  }

  componentDidMount() {
    // const { fetchMessageableNamesIfNeeded, resetRentalNames } = this.props;
    // TODO: check if action can be removed as well
    // fetchMessageableNamesIfNeeded();
    const { resetRentalNames } = this.props;
    resetRentalNames();
  }

  validate = () => {
    const errors = {};
    const { customer, rental, body } = this.state;
    if (validations.required(customer.id, true)) {
      errors.customer = "Please select a customer from the drop down.";
    }
    if (validations.required(rental.id, true)) {
      errors.rental = "Please select one order from the drop down.";
    }
    if (validations.required(body, true)) {
      errors.body = "Message cannot be blank.";
    }
    return errors;
  };

  handleSubmit = () => {
    const { customer, rental, body } = this.state;
    const { setErrors, createConversation } = this.props;
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
    } else {
      createConversation(customer.customerId, rental.id, body);
    }
  };

  refreshRentalNames = () => {
    const { customer } = this.state;
    if (customer.nameableId) {
      this.props.fetchRentalNames(customer.nameableId);
    }
  };

  shouldItemRender(item, value) {
    return item.name.toLowerCase().indexOf(value.toLowerCase()) !== -1;
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  onChange = query => {
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          `/api/customer_location_relationships/messageable_names?query=${query}`
      )
      .then(response => {
        this.refs.customersAutocomplete.setItems(response.data.names);
      })
      .catch(error => {
        console.log(error);
      });
  };

  onSelect = customer => {
    this.setState({ customer: customer }, this.refreshRentalNames);
    return customer.name;
  };

  render() {
    const { rentalNames, errors } = this.props;
    const { customer, rental, body } = this.state;
    return (
      <div className="conversationForm">
        <div className="form">
          <div className="fields">
            <AutocompleteWithAPI
              ref="customersAutocomplete"
              placeholder="To..."
              emptyMessage="No account holding customers"
              value={customer.name}
              onChange={this.onChange}
              onSelect={this.onSelect}
              renderItem={(item, isHighlighted) => {
                return (
                  <span
                    className={`${isHighlighted ? "highlight" : ""}`}
                    key={item.id}
                  >
                    {item.name}
                  </span>
                );
              }}
            />
            <Autocomplete
              value={rental.name}
              placeholder={{ name: "Hi" }}
              inputProps={{
                id: "rental-autocomplete",
                placeholder: "Order Name",
                type: "text",
                className: classnames({
                  error: errors.rental
                })
              }}
              wrapperProps={{ className: "autocomplete" }}
              items={rentalNames}
              getItemValue={item => item.name}
              onChange={(event, value) =>
                this.setState({ rental: { name: value } })
              }
              onSelect={(value, item) => this.setState({ rental: item })}
              renderMenu={children => {
                return (
                  <ul className="results">
                    {children.length === 0 && (
                      <li key="none">There are no orders with that name</li>
                    )}
                    {children}
                  </ul>
                );
              }}
              shouldItemRender={this.shouldItemRender}
              renderItem={(item, isHighlighted) => (
                <li
                  className={`${isHighlighted ? "highlight" : ""}`}
                  key={item.id}
                >
                  {item.name}
                </li>
              )}
            />
            <textarea
              name="body"
              value={body}
              placeholder="Write new message..."
              onChange={this.handleChange}
              className={classnames({
                error: errors["messages.body"]
              })}
            />
          </div>
          <div className="actions">
            <button
              type="submit"
              className="btn full"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </div>
        <Invitations />
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { names: customerNames } = state.messageable;
  const { names: rentalNames } = state.rentalsList;
  const { errors } = state.dashboard;
  return { customerNames, rentalNames, errors };
};

export default connect(mapStateToProps, actions)(NewConversation);

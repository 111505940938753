import {
  TOGGLE_AVAILABILITY_ALERT_MODAL,
  SET_AVAILABILITY_ALERT,
} from 'Constants/redux';

export const toggleAvailabilityAlertModal = () => ({
  type: TOGGLE_AVAILABILITY_ALERT_MODAL,
});

export const setAvailabilityAlert = (alert) => ({
  type: SET_AVAILABILITY_ALERT,
  alert,
});

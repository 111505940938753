import React from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import { AuthGeneral } from 'Components/../containers/authentication/Authorization';
import ProfileSubNav from 'Components/main_menu/ProfileSubNav';
import Profile from './Profile';
import EditProfile from './EditProfile';
import Accounts from './Accounts';
import GoogleCal from './GoogleCal';
import Notifications from './Notifications';

const ProfileMiddle = (props) => (
  <div className='contentWrapper'>
    <ProfileSubNav />
    <div className='content'>
      <Switch>
        <Route
          exact
          path={`${props.match.path}`}
          component={AuthGeneral(Profile)}
        />
        <Route
          path={`${props.match.path}/edit`}
          component={AuthGeneral(EditProfile)}
        />
        <Route
          path={`${props.match.path}/accounts`}
          component={AuthGeneral(Accounts)}
        />
        <Route
          path={`${props.match.path}/googleCal`}
          component={AuthGeneral(GoogleCal)}
        />
        <Route
          path={`${props.match.path}/notifications`}
          component={AuthGeneral(Notifications)}
        />
        <Redirect to={`${props.match.path}`} />
      </Switch>
    </div>
  </div>
);

export default ProfileMiddle;

import React from "react";
import service from "Api/service";
import LoadingSpinner from "Utils/LoadingSpinner";
import { parseQuery } from "HelperFunctions/QueryString";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";

class FeedbackThankYou extends React.Component {
  state = {
    business: null,
    dataLoading: "loading"
  };

  componentDidMount() {
    const { businessId } = parseQuery(window.location.search);
    if (businessId) {
      const component = this;
      service
        .get(
          process.env.REACT_APP_API_DOMAIN +
            "/api/portal/businesses/" +
            businessId
        )
        .then(response => {
          component.setState({
            business: response.data.business,
            dataLoading: "success"
          });
        })
        .catch(function(error) {
          console.log(error);
          //  saveError(error)
        });
    } else {
      this.setState({
        dataLoading: "missing"
      });
    }
  }

  render() {
    const { dataLoading, business } = this.state;

    if (dataLoading === "success") {
      return (
        <div className="contentWrapper customerPayment">
          <header>
            <div>
              {business.picture === null ? (
                <h1>{business.name}</h1>
              ) : (
                <Imgix
                  src={business.picture.imgixUrl}
                  alt={business.name}
                  width={510}
                  height={288}
                />
              )}
              <div className="details">
                <div>
                  {business.phoneNumber.office &&
                    formatPhoneNumber(business.phoneNumber.office)}
                </div>
                <div>
                  {business.phoneNumber.cell &&
                    formatPhoneNumber(business.phoneNumber.cell)}
                </div>
                <div>
                  {business.phoneNumber.fax &&
                    formatPhoneNumber(business.phoneNumber.fax)}
                </div>
                <div>{business.email}</div>
                {business.physicalAddress && (
                  <div>
                    <div>{business.physicalAddress.streetAddress1}</div>
                    {business.physicalAddress.streetAddress2 && (
                      <div># {business.physicalAddress.streetAddress2}</div>
                    )}
                    <div>
                      {business.physicalAddress.city},{" "}
                      {business.physicalAddress.locale}{" "}
                      {business.physicalAddress.postalCode},{" "}
                      {business.physicalAddress.country}
                    </div>
                  </div>
                )}
              </div>
            </div>
          </header>
          <div className="content feedbackThanks">
            <div>Thank you for your feedback!</div>
          </div>
        </div>
      );
    } else if (dataLoading === "loading") {
      return (
        <div className="contentWrapper customerPayment">
          <div className="content">
            <LoadingSpinner label="Loading Info..." />
          </div>
        </div>
      );
    } else {
      return (
        <div className="contentWrapper customerPayment">
          <div className="content">
            Oops, we could not find necessary info. Please make sure you have
            navigated to the proper url.
          </div>
        </div>
      );
    }
  }
}

export default FeedbackThankYou;

import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';
import converter from 'json-style-converter/es5';
import update from 'immutability-helper';
import * as actions from 'Actions';
import service from 'Api/service';
import { saveError } from 'Api';
import ImageDropZone from 'Utils/ImageDropZone';
import PhoneInput from 'Utils/PhoneInput';
import {
  UserCircle,
  PhoneCircle,
  CellCircle,
  FaxCircle,
  EmailCircle,
  LocationCircle,
  StarCircle,
} from 'Utils/SvgIcons';
import { getCountry } from 'HelperFunctions/general';
import LocationFields from 'Utils/LocationFields';

class EditProfile extends Component {
  state = {
    employee: {
      image: '',
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: {
        office: '',
        cell: '',
        fax: '',
      },
      physicalAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        locale: '',
        postalCode: '',
        country: '',
      },
      role: '',
      password: '',
      passwordConfirmation: '',
    },
    file: null,
  };

  componentDidMount() {
    this.props.openLoadingSpinner('Loading...');

    service
      .get(process.env.REACT_APP_API_DOMAIN + '/api/employees/profile')
      .then((response) => {
        const { employee } = response.data;
        const newPhone = Object.assign(
          this.state.employee.phoneNumber,
          employee.phoneNumber && {
            office: employee.phoneNumber.office || '',
            fax: employee.phoneNumber.fax || '',
            cell: employee.phoneNumber.cell || '',
            main_contact_number: true,
          }
        );
        const newAddress = {
          ...this.state.employee.physicalAddress,
          ...employee.physicalAddress,
          country: getCountry(
            employee.physicalAddress && employee.physicalAddress.country,
            'USA'
          ),
        };
        const newEmployee = Object.assign(
          this.state.employee,
          response.data.employee,
          {
            phoneNumber: newPhone,
            physicalAddress: newAddress,
          }
        );
        this.props.closeLoadingSpinner();
        this.setState({
          employee: newEmployee,
        });
      })
      .catch((error) => {
        saveError(error);
        this.props.closeLoadingSpinner();
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;

    this.setState({
      employee: {
        ...this.state.employee,
        [name]: value,
      },
    });
  };

  handleAddressChange = (event) => {
    const { name, value } = event.target;
    const newAddress = Object.assign(
      this.state.employee.physicalAddress,
      { [name]: value },
      name === 'country' && { locale: '' }
    );

    this.setState({
      employee: {
        ...this.state.employee,
        physicalAddress: newAddress,
      },
    });
  };

  handlePhoneChange = (event) => {
    const { name, value } = event.target;
    const newPhone = {
      ...this.state.employee.phoneNumber,
      [name]: value,
    };

    this.setState({
      employee: {
        ...this.state.employee,
        phoneNumber: newPhone,
      },
    });
  };

  handleImageDropZoneChange = (files, pictures) => {
    let newState = update(this.state, {
      employee: { picture: { $set: pictures[0] } },
      file: { $set: files.length > 0 ? files[0] : null },
    });

    this.setState(newState);
  };

  buildRailsObject = () => {
    const { employee } = this.state;
    const {
      firstName,
      lastName,
      email,
      phoneNumber,
      physicalAddress,
      picture,
      password,
      passwordConfirmation,
    } = employee;

    const {
      streetAddress1,
      streetAddress2,
      city,
      locale,
      postalCode,
      country,
    } = physicalAddress;

    const newEmployee = Object.assign(
      {
        firstName,
        lastName,
        email,
        phoneNumberAttributes: phoneNumber,
        physicalAddressAttributes: {
          street_address_1: streetAddress1,
          street_address_2: streetAddress2,
          city: city,
          locale: locale,
          postal_code: postalCode,
          country: country,
        },
        picture,
      },
      password && { password },
      passwordConfirmation && {
        password_confirmation: passwordConfirmation,
      }
    );

    const railsObject = { employee: newEmployee };

    return railsObject;
  };

  handleSubmit = (event) => {
    const { setErrors, openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner('Saving...');

    event.preventDefault();
    const formattedData = converter.camelToSnakeCase(this.buildRailsObject());
    service
      .put(
        process.env.REACT_APP_API_DOMAIN +
          '/api/employees/' +
          this.state.employee.id,
        formattedData
      )
      .then((response) => {
        closeLoadingSpinner();
        this.profileRedirect(response.data);
      })
      .catch((error) => {
        closeLoadingSpinner();
        setErrors(error.response.data);
        saveError(error);
      });
  };

  profileRedirect = () => {
    const { file } = this.state;
    const { history } = this.props;

    if (file != null && file.file) {
      let data = new FormData();

      data.append('image', file.file);
      service
        .patch(
          process.env.REACT_APP_API_DOMAIN +
            '/api/employees/' +
            this.state.employee.id +
            '/update_picture',
          data
        )
        .then(() => {
          history.push('/profile');
        });
    } else {
      history.push('/profile');
    }
  };

  render() {
    const { employee } = this.state;
    const errors = this.props.errors || "";
    const pictures =
      employee.picture === undefined || employee.picture === null
        ? []
        : [employee.picture];

    return (
      <div className='profile'>
        <h1>My Profile</h1>
        <form onSubmit={this.handleSubmit}>
          <ImageDropZone
            aspectRatio={1 / 1}
            multiple={false}
            onChange={this.handleImageDropZoneChange}
            pictures={pictures}
          />
          <div className='iconForm'>
            <div className='fields'>
              <UserCircle />
              <input
                name='firstName'
                type='text'
                placeholder='First Name'
                className={classNames({
                  med: true,
                  error: errors?.first_name,
                })}
                value={employee.firstName}
                onChange={this.handleChange}
              />
              <input
                name='lastName'
                type='text'
                placeholder='Last Name'
                className={classNames({
                  med: true,
                  error: errors?.last_name,
                })}
                value={employee.lastName}
                onChange={this.handleChange}
              />
              <PhoneCircle />
              <PhoneInput
                name='office'
                type='text'
                placeholder='Phone'
                className={classNames({
                  med: true,
                  full: true,
                  error: errors?.['phone_number.office'],
                })}
                value={employee.phoneNumber.office}
                onChange={this.handlePhoneChange}
              />
              <CellCircle />
              <PhoneInput
                name='cell'
                type='text'
                placeholder='Cellphone'
                className={classNames({
                  med: true,
                  full: true,
                  error: errors?.['phone_number.cell'],
                })}
                value={employee.phoneNumber.cell}
                onChange={this.handlePhoneChange}
              />
              <FaxCircle />
              <PhoneInput
                name='fax'
                type='text'
                placeholder='Fax'
                className={classNames({
                  med: true,
                  full: true,
                  error: errors?.['phone_number.fax'],
                })}
                value={employee.phoneNumber.fax}
                onChange={this.handlePhoneChange}
              />
              <EmailCircle />
              <input
                name='email'
                type='text'
                className={classNames({ error: errors?.email })}
                placeholder='Email Address'
                value={employee.email}
                onChange={this.handleChange}
              />
              <LocationCircle />
              <div>
                <LocationFields
                  addressable={employee.physicalAddress}
                  errors={errors}
                  handleAddressChange={this.handleAddressChange}
                  errorPrefix={'physical_address.'}
                />
              </div>
            </div>
            <div className='fields'>
              <StarCircle />
              <div>
                <input
                  name='password'
                  type='password'
                  placeholder='New Password'
                  className={classNames({
                    med: true,
                    full: true,
                    error: errors?.password,
                  })}
                  value={employee.password}
                  onChange={this.handleChange}
                />
                <input
                  name='passwordConfirmation'
                  type='password'
                  placeholder='Confirm Password'
                  className={classNames({
                    med: true,
                    full: true,
                    error: errors?.password_confirmation,
                  })}
                  value={employee.passwordConfirmation}
                  onChange={this.handleChange}
                />
              </div>
            </div>
            <div className='actions'>
              <input className='btn' type='submit' value='Save' />
              <Link className='btn cancel' to='/profile'>
                Cancel
              </Link>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;
  const { errors } = state.dashboard;

  return { businessCountry: business.physicalAddress.country, errors };
};

export default connect(mapStateToProps, actions)(EditProfile);

import {
  EDIT_TRANSACTION_REQUEST,
  EDIT_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
} from 'Constants/redux/transaction';
import { normalize } from 'normalizr';

import { transaction } from '../schema/transaction';

const INITIAL_STATE = {
  transactions: [],
  loading: false,
  updating: false,
  deleting: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case EDIT_TRANSACTION_REQUEST:
      return { ...state, updating: true };
    case EDIT_TRANSACTION_SUCCESS:
      const { entities } = normalize(action.payload, [transaction]);

      return {
        ...state,
        transactions: { ...state.transactions, ...entities.transaction },
        updating: false,
      };
    case DELETE_TRANSACTION_REQUEST:
      return { ...state, deleting: true };
    case DELETE_TRANSACTION_SUCCESS:
      return { ...state, deleting: false };
    default:
      return state;
  }
};

import { ROOT_URL } from 'Api';
import service from 'Api/service';
import {
  typeToRental,
  typeToRentalPickList,
  typeToRentalPlural,
  typeToRentalPluralPickList,
} from 'Constants/displayConstants';

// Rentals
const rentalItems = {
  bulkPick: (rentalId, rentalItemIds, type, status) =>
    service.post(
      `${ROOT_URL}/rentals/${rentalId}/${typeToRentalPluralPickList[type]}/bulk_pick`,
      { ids: rentalItemIds, status: status }
    ),
  serializedBulkPick: (rentalId, body) =>
    service.post(
      `${ROOT_URL}/rentals/${rentalId}/pick_list_items/quick_scan_serialized_product_checkout`,
      body
    ),
  pickOne: (id, itemId, pickedQuantity, type, status) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/pick`,
      {
        picked_quantity: pickedQuantity,
        status: status,
      }
    ),
  serializedPick: (id, itemId, pickedQuantity, pickedIds, type) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_pick`,
      {
        picked_quantity: pickedQuantity,
        picked_ids: pickedIds,
      }
    ),
  serializedMultiplePick: (id, itemId, pickedQuantity, pickedId, type) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_multiple_pick`,
      {
        picked_quantity: pickedQuantity,
        picked_id: pickedId,
      }
    ),

  serializedPickWithUsage: (
    id,
    itemId,
    pickedQuantity,
    pickedIds,
    type,
    usageUnits,
    status,
  ) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_pick`,
      {
        picked_quantity: pickedQuantity,
        picked_ids: pickedIds,
        usage_units: usageUnits,
        status: status,
      }
    ),

  unpickOne: (id, itemId, type) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/unpick`
    ),
  bulkCheckIn: (rentalId, rentalItemIds, type, status) =>
    service.post(
      `${ROOT_URL}/rentals/${rentalId}/${typeToRentalPluralPickList[type]}/bulk_check_in`,
      { ids: rentalItemIds, status: status }
    ),
  serializedBulkCheckIn: (rentalId, body) =>
    service.post(
      `${ROOT_URL}/rentals/${rentalId}/pick_list_items/quick_scan_serialized_product_checkin`,
      body
    ),
  checkIn: (id, itemId, checkedInQuantity, type, status) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/check_in`,
      {
        checked_in_quantity: checkedInQuantity,
        status: status,
      }
    ),
  serializedCheckIn: (id, itemId, checkedInQuantity, checkedInIds, type) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_check_in`,
      {
        checked_in_quantity: checkedInQuantity,
        checked_in_ids: checkedInIds,
      }
    ),

  serializedUniqueCheckIn: (
    id,
    itemId,
    checkedInQuantity,
    checkedInIds,
    usageUnits,
    type
  ) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_check_in`,
      {
        checked_in_quantity: checkedInQuantity,
        checked_in_ids: checkedInIds,
        usage_units: usageUnits,
      }
    ),

  serializedMultipleCheckIn: (
    id,
    itemId,
    checkedInQuantity,
    checkedInId,
    type
  ) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/serialized_multiple_check_in`,
      {
        checked_in_quantity: checkedInQuantity,
        checked_in_id: checkedInId,
      }
    ),
  bulkUncheckIn: (rentalId, rentalItemIds, type) =>
    service.post(
      `${ROOT_URL}/rentals/${rentalId}/${typeToRentalPluralPickList[type]}/bulk_uncheck_in`,
      { ids: rentalItemIds }
    ),
  uncheckIn: (id, itemId, type) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/${typeToRentalPluralPickList[type]}/${itemId}/uncheck_in`
    ),
  updateOne: (id, itemId, data, updateInventory, type, modelType) =>
    service.patch(
      `${ROOT_URL}/rentals/${id}/${
        modelType == 'rentals'
          ? typeToRentalPlural[type]
          : typeToRentalPluralPickList[type]
      }/${itemId}`,
      {
        [modelType == 'rentals'
          ? typeToRental[type]
          : typeToRentalPickList[type]]: data,
        update_inventory: updateInventory,
      }
    ),
};

const connect = {
  connectRental: (id, data) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/connect`, data),
  approve: (id) => service.post(`${ROOT_URL}/connect/rentals/${id}/approve`),
  deny: (id, reason) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/deny`, { reason: reason }),
  approveChangeRequest: (id) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/approve_change_request`),
  denyChangeRequest: (id) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/deny_change_request`),
  approveCancelRequest: (id) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/approve_cancel_request`),
  denyCancelRequest: (id) =>
    service.post(`${ROOT_URL}/connect/rentals/${id}/deny_cancel_request`),
};

const supplierRentals = {
  updateOne: (id, supplierRentalId, data) =>
    service.patch(
      `${ROOT_URL}/rentals/${id}/supplier_rentals/${supplierRentalId}`,
      data
    ),
  submitQuote: (id, supplierRentalId) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/supplier_rentals/${supplierRentalId}/submit_quote`
    ),
  requestChanges: (id, supplierRentalId, data) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/supplier_rentals/${supplierRentalId}/request_changes`,
      data
    ),
  requestCancel: (id, supplierRentalId) =>
    service.post(
      `${ROOT_URL}/rentals/${id}/supplier_rentals/${supplierRentalId}/request_cancel`
    ),
};

const rentalSections = {
  getAll: (rentalId) =>
    service.get(`${ROOT_URL}/rentals/${rentalId}/rental_sections`),
};

export default {
  showOne: (id) => service.get(`${ROOT_URL}/rentals/${id}`),
  display: (id) => service.get(`${ROOT_URL}/rentals/${id}/display`),
  getPickList: (id) => service.get(`${ROOT_URL}/rentals/${id}/picklist`),
  showOneInfo: (id, infoName) =>
    service.get(`${ROOT_URL}/rentals/${id}/${infoName}`),
  cancelOne: (id, reason) =>
    service.post(`${ROOT_URL}/rentals/${id}/cancel`, { reason: reason }),
  deleteOne: (id) => service.delete(`${ROOT_URL}/rentals/${id}`),
  reserveOne: (id) => service.post(`${ROOT_URL}/rentals/${id}/reserve`),
  closeOne: (id) => service.post(`${ROOT_URL}/rentals/${id}/close`),
  ignoreExpiredDocument: (id) =>
    service.post(`${ROOT_URL}/rentals/${id}/ignore_expired_documents`),
  checkOutOne: (id, data) =>
    service.post(`${ROOT_URL}/rentals/${id}/check_out`, data),
  convertOneToInProgress: (id) =>
    service.post(`${ROOT_URL}/rentals/${id}/convert_to_in_progress`),
  updateOne: (id, data) => service.patch(`${ROOT_URL}/rentals/${id}/reactivate_quote`, data),
  repositionRentalInventory: (id, data) =>
    service.patch(`${ROOT_URL}/rentals/${id}/reposition_inventory`, data),
  submitSignature: (id, data) =>
    service.patch(`${ROOT_URL}/rentals/${id}/submit_signature`, data),
  checkInOne: (id, data) =>
    service.post(`${ROOT_URL}/rentals/${id}/check_in`, data),
  requestRequirements: (id, data) =>
    service.post(`${ROOT_URL}/rentals/${id}/request_rental_requirements`, data),
  generateInvoice: (id, type) =>
    service.post(`${ROOT_URL}/rentals/${id}/generate_invoice`, type),
  rentalItems: rentalItems,
  connect: connect,
  supplierRentals: supplierRentals,
  rentalSections: rentalSections,
};

import React from "react";
import classnames from "classnames";
import service from "Api/service";
import { saveError } from "Api";
import * as actions from "Actions";
import { connect } from "react-redux";
import MonthSelect from "Utils/MonthSelect";
import YearSelect from "Utils/YearSelect";
import { formatPhoneNumber } from "HelperFunctions/general";
import validations from "HelperFunctions/validations";
import RegionDropdown from "Utils/RegionDropdown";
import PostalCodeInput from "Utils/PostalCodeInput";
import converter from "json-style-converter/es5";

class ReactivateBusiness extends React.Component {
  state = {
    submitting: false,
    creditCard: {
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      locale: "",
      postalCode: "",
      country: "",
      name: "",
      cvc: "",
      number: "",
      expMonth: "",
      expYear: ""
    },
    errors: {}
  };

  handleCreditCardChange = event => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const newCreditCard = Object.assign(this.state.creditCard, {
      [name]: value
    });
    this.setState({
      creditCard: newCreditCard
    });
  };
  validate = () => {
    const errors = {};
    const {
      name,
      number,
      cvc,
      expMonth,
      expYear,
      postalCode
    } = this.state.creditCard;
    if (validations.required(name, true)) {
      errors.name = "Required";
    }
    if (validations.required(number, true)) {
      errors.number = "Required";
    }
    if (validations.required(cvc, true)) {
      errors.cvc = "Required";
    }
    if (validations.required(expMonth, true)) {
      errors.expMonth = "Required";
    }
    if (validations.required(expYear, true)) {
      errors.expYear = "Required";
    }
    if (validations.required(postalCode, true)) {
      errors.postalCode = "Required";
    }
    return errors;
  };

  submitCreditCard = event => {
    event.preventDefault();
    const errors = this.validate();
    if (Object.keys(errors).length > 0) {
      this.setState({
        errors: errors
      });
    } else {
      const { creditCard } = this.state;
      const { setErrors, receiveBusiness, history } = this.props;
      this.setState({
        submitting: true
      });
      service
        .patch(process.env.REACT_APP_API_DOMAIN + "/api/business/reactivate", {
          credit_card: converter.camelToSnakeCase(creditCard)
        })
        .then(response => {
          this.setState({
            submitting: false
          });
          receiveBusiness(response);
          history.push("/todos");
        })
        .catch(error => {
          this.setState({
            submitting: false,
            errors: error.response.data
          });
          setErrors(error.response.data);
        });
    }
  };

  render() {
    const { business, user } = this.props;
    const { creditCard, errors, submitting } = this.state;

    return (
      <div className="contentWrapper">
        <header className="signUpTitle">
          <div className="title progressContainer">
            <h1>Reactivate Your Account</h1>
          </div>
        </header>
        <div className="content narrow signUp">
          <section>
            <h1>Welcome back!</h1>
            <p>
              In order to get your account back up and running
              <br />
              all we need is updated credit card information.
            </p>
            <h4>Rental Business</h4>
            <fieldset>
              <div>{business.name}</div>
              <div>{business.email}</div>
            </fieldset>
            {business.physicalAddress && (
              <fieldset>
                <div>{business.physicalAddress.streetAddress1}</div>
                <div>{business.physicalAddress.streetAddress2}</div>
                <div>
                  {business.physicalAddress.city},{" "}
                  {business.physicalAddress.locale}{" "}
                  {business.physicalAddress.postalCode},{" "}
                  {business.physicalAddress.country}
                </div>
              </fieldset>
            )}
            <h4>Admin/Contact Info</h4>
            <fieldset>
              <div>
                {user.firstName} {user.lastName}
              </div>
              <div>
                {user.phoneNumber && formatPhoneNumber(user.phoneNumber.office)}
              </div>
              <div>{user.email}</div>
            </fieldset>
            <form onSubmit={this.submitCreditCard} className="form">
              <div className="fields">
                <h4>Billing Information</h4>
                <select
                  name="country"
                  value={creditCard.country}
                  onChange={this.handleCreditCardChange}
                >
                  <option value="">Select country</option>
                  <option value="USA">United States</option>
                  <option value="CAN">Canada</option>
                </select>
                <input
                  name="streetAddress1"
                  type="text"
                  placeholder="Street Address"
                  className={classnames({
                    lg: true
                  })}
                  value={creditCard.streetAddress1}
                  onChange={this.handleCreditCardChange}
                />
                <input
                  name="streetAddress2"
                  type="text"
                  placeholder="Suite #"
                  className="sm"
                  value={creditCard.streetAddress2}
                  onChange={this.handleCreditCardChange}
                />
                <input
                  name="city"
                  type="text"
                  placeholder="City"
                  className={classnames({
                    med: true
                  })}
                  value={creditCard.city}
                  onChange={this.handleCreditCardChange}
                />
                <RegionDropdown
                  name="locale"
                  defaultOptionLabel={
                    creditCard.country === "USA" ? "State" : "Province"
                  }
                  className={classnames({
                    sm: true
                  })}
                  disableWhenEmpty={true}
                  countryValueType="short"
                  labelType="short"
                  valueType="short"
                  country={creditCard.country}
                  value={creditCard.locale}
                  onChange={this.handleCreditCardChange}
                />
                <PostalCodeInput
                  name="postalCode"
                  type="text"
                  placeholder="Postal Code"
                  className={classnames({
                    sm: true
                  })}
                  country={creditCard.country}
                  maxLength={creditCard.country === "USA" ? 10 : 7}
                  value={creditCard.postalCode}
                  onChange={this.handleCreditCardChange}
                />
              </div>
              <div className="fields">
                <input
                  name="name"
                  type="text"
                  placeholder="Name on Card *"
                  className={classnames({ error: errors.name })}
                  value={creditCard.name}
                  onChange={this.handleCreditCardChange}
                />
                <input
                  name="number"
                  type="text"
                  placeholder="Card Number *"
                  className={classnames({ lg: true, error: errors.number })}
                  value={creditCard.number}
                  onChange={this.handleCreditCardChange}
                />
                <input
                  name="cvc"
                  type="text"
                  placeholder="CVV *"
                  className={classnames({ sm: true, error: errors.cvc })}
                  value={creditCard.cvc}
                  onChange={this.handleCreditCardChange}
                />
                <MonthSelect
                  name="expMonth"
                  format="numbers"
                  className={classnames({
                    med: true,
                    error: errors.expMonth
                  })}
                  value={creditCard.expMonth}
                  onChange={this.handleCreditCardChange}
                />
                <YearSelect
                  name="expYear"
                  className={classnames({
                    med: true,
                    error: errors.expYear
                  })}
                  value={creditCard.expYear}
                  onChange={this.handleCreditCardChange}
                />
              </div>
              <div className="actions">
                <input
                  type="submit"
                  className={classnames({
                    btn: true,
                    full: true,
                    disabled: submitting
                  })}
                  value={
                    submitting ? "Reactivating..." : "Reactivate My Account"
                  }
                />
              </div>
            </form>
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { errors } = state.dashboard;
  const { business } = state.business;
  const { user } = state.user;

  return { errors, business, user };
};

export default connect(mapStateToProps, actions)(ReactivateBusiness);

import { ADD_STAFF_ROLE_NAMES_REQUEST, EPIC_ERROR } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";
const { Observable } = Rx;

const AddStaffRoleNamesEpic = action$ =>
  action$.ofType(ADD_STAFF_ROLE_NAMES_REQUEST).switchMap(action => {
    return Rx.Observable.fromPromise(
      service.get(process.env.REACT_APP_API_DOMAIN + action.link)
    )
      .map(response => {
        return actions.addStaffRoleNamesSuccess(response);
      })
      .catch(error =>
        Observable.of({
          type: EPIC_ERROR
        })
      );
  });

export default AddStaffRoleNamesEpic;

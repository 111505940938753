import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { FormattedDate } from "react-intl";
import classnames from "classnames";
import Imgix from "react-imgix";

class Message extends React.Component {
  belongsToEmployee = () => {
    const { message, employee } = this.props;
    if (message.senderId === employee.id && message.senderType === "Employee") {
      return true;
    } else {
      return false;
    }
  };

  render() {
    const { message } = this.props;
    return (
      <div
        className={classnames({
          message: true,
          mine: this.belongsToEmployee(),
          theirs: !this.belongsToEmployee()
        })}
      >
        <time>
          <FormattedDate
            value={message.createdAt}
            day="2-digit"
            month="2-digit"
            year="2-digit"
          />
        </time>
        <p>{message.body}</p>
        <div className="author">
          <div className="thumb">
            {message.sender.picture ? (
              <Imgix
                src={message.sender.picture.imgixUrl}
                alt={message.sender.firstName}
                width={200}
                height={200}
              />
            ) : (
              <div>
                {message.sender.firstName && message.sender.firstName.charAt(0)}
                {message.sender.lastName && message.sender.lastName.charAt(0)}
              </div>
            )}
          </div>
          {message.sender.firstName && (
            <label className="name">
              {message.sender.firstName} {message.sender.lastName}
            </label>
          )}
          <label className="company">{message.senderBusinessName}</label>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user: customer } = state.auth;
  return { employee: customer };
};

export default connect(mapStateToProps, actions)(Message);

import React, { Component } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import NotAuthorized from './NotAuthorized';

class ProtectedRoute extends Component {
  render() {
    const { component: Component, ...props } = this.props;
    const { authenticated, redirect, redirectToUnauthorized } = this.props;
    return (
      <Route
        {...props}
        render={(routeProps) => {
          return authenticated ? (
            <Component {...routeProps} {...props} />
          ) : redirectToUnauthorized ? (
            <NotAuthorized />
          ) : (
            <Redirect to={redirect || '/404'} />
          );
        }}
      />
    );
  }
}

export default ProtectedRoute;

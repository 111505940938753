import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import MapContainer from "./MapContainer";
import Directions from "./Directions";
import moment from "moment";
import { parseQuery } from "HelperFunctions/QueryString";
import PrintLocation from "./PrintLocation";

class PrintMapRouteInfo extends React.Component {
  state = {
    stops: [],
    routeInfo: null,
    directions: null
  };

  setDirections = response => {
    this.setState({
      directions: response
    });
  };
  
  render() {
    const {
      truckRoute,
      withMap,
      loading,
      name,
      startTime,
      totalDuration,
      stops
    } = this.props
    
    const {directions} = this.state

    return (
      <div className="contentWrapper routingPrintWrapper">
        <div className="content">
          <PrintLocation/>
          <MapContainer
            truckRoute={truckRoute}
            displayingMap={withMap !== undefined}
            displayingInfo
            setDirections={this.setDirections}
          />
          <div className="mapDrawer directionsDrawer">
            {loading ? (
              <div className="header">
                <label>Route Information</label>
              </div>
            ) : (
              <div className="header">
                <label>{name}</label>
                {startTime && directions && <a>({totalDuration})</a>}
              </div>
            )}
            <div className="contents">
              <Directions directions={directions} stops={stops}/>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { truckRoute, loading } = state.truckRoute;
  return { truckRoute, loading };
};

export default connect(mapStateToProps, actions)(PrintMapRouteInfo);

import { getAuthToken } from "Api";
import service from "Api/service";
import axios from "axios";

export default {
  updateSubChangeRequest: (id, updatedData) => {
    return axios.patch(
      process.env.REACT_APP_API_DOMAIN +
        `/api/sub_change_requests/${id}`, updatedData,
      {
        headers: getAuthToken()
      }
    );
  }
}
import { CustomerRentalRelationship } from '../../../../../types/graphql';

export const extractCustomerInformations = (
  customerRentalRelationship: CustomerRentalRelationship
) => {
  if (!customerRentalRelationship) return null;

  const { taxExemptionNumber } = customerRentalRelationship;

  const {
    firstName,
    lastName,
    physicalAddress,
    phoneNumber: customerPhoneNumber,
  } = customerRentalRelationship?.client!;

  const name = `${firstName} ${lastName}`;
  const phone = customerPhoneNumber?.cell || customerPhoneNumber?.cell;

  return {
    name,
    phone,
    physicalAddress,
    taxExemptionNumber,
  };
};

import {
  SET_CURRENT_TUTORIAL,
  OPEN_HELP_SIDEBAR,
  CLOSE_HELP_SIDEBAR
} from "Constants/redux";

export function setCurrentTutorial(tutorial) {
  return {
    type: SET_CURRENT_TUTORIAL,
    payload: {
      tutorial: tutorial
    }
  };
}

export function openHelpSidebar() {
  return {
    type: OPEN_HELP_SIDEBAR
  };
}

export function closeHelpSidebar() {
  return {
    type: CLOSE_HELP_SIDEBAR
  };
}

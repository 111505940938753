import React from "react";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import { Alert, ArrowLeft, ArrowRight, RemoveCircle } from "Utils/SvgIcons";
import { withRouter } from "react-router-dom";
import { requestForActivation }  from "Actions";

class TopWarning extends React.Component {
  state = {
    currentIndex: 0,
    showTrialAlert: true
  };
  handleCurrentIndexChange = newIndex => {
    const { alerts } = this.props;
    if (newIndex >= 0 && newIndex < alerts.length) {
      this.setState({
        currentIndex: newIndex
      });
    }
    if (newIndex < 0) {
      this.setState({
        currentIndex: alerts.length - 1
      });
    }
    if (newIndex >= alerts.length) {
      this.setState({
        currentIndex: 0
      });
    }
  };

  hideAlertTrial = () => {
    this.setState({showTrialAlert: false});
  };

  render() {
    const { alerts, authenticated, location, alertsTrial, requestForActivation } = this.props;
    const { currentIndex, showTrialAlert } = this.state;
    const tgAdmin = location.pathname.toLowerCase().startsWith("/tg-admin");

    if ((tgAdmin || !authenticated)) {
      return null;
    } else {
      return (
        <div>
          {!!alerts.length && <div className="topWarning">
            <Alert />
            {alerts.length > 1 && (
                <a onClick={() => this.handleCurrentIndexChange(currentIndex - 1)}>
                  <ArrowLeft />
                </a>
            )}
            You have {alerts.length} thing
            {alerts.length > 1 && "s"} to do:
            <Link to={alerts[currentIndex].link[0]}>
              {alerts[currentIndex].name[0]}
            </Link>
            {alerts[currentIndex].link[1] && (
                <Link to={alerts[currentIndex].link[1]}>
                  {alerts[currentIndex].name[1]}
                </Link>
            )}
            {alerts.length > 1 && (
                <a onClick={() => this.handleCurrentIndexChange(currentIndex + 1)}>
                  <ArrowRight />
                </a>
            )}
          </div>}
        </div>
      );
    }
  }
}

const mapStateToProps = state => {
  const { authenticated } = state.auth;
  const { alerts } = state.business;
  return { alerts, authenticated };
};

export default withRouter(connect(mapStateToProps, {requestForActivation})(TopWarning));

import {
  FETCH_CONNECT_LOCATIONS_REQUEST,
  FETCH_CONNECT_LOCATIONS_FAILURE,
  FETCH_CONNECT_LOCATIONS_SUCCESS,
  SET_LOCATION_FILTER
} from "Constants/redux";

const INITIAL_STATE = {
  locations: [],
  filter: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONNECT_LOCATIONS_REQUEST:
      return { ...state, loading: true };
    case FETCH_CONNECT_LOCATIONS_FAILURE:
      return { ...state, loading: false };
    case FETCH_CONNECT_LOCATIONS_SUCCESS:
      return {
        ...state,
        locations: action.response.data.locations,
        filter: action.response.data.locations.map(location => location.id),
        loading: false
      };
    case SET_LOCATION_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    default:
      return state;
  }
};

import { FETCH_STAFF_MEMBER_NAMES_REQUEST, EPIC_ERROR } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";
const { Observable } = Rx;

const FetchStaffMemberNamesEpic = action$ =>
  action$.ofType(FETCH_STAFF_MEMBER_NAMES_REQUEST).switchMap(action => {
    return Rx.Observable.fromPromise(
      service.get(
        process.env.REACT_APP_API_DOMAIN + `/api/staff_members/names`,
        {
          per: 25,
          query: action.query
        }
      )
    )
      .map(response => {
        return actions.receiveStaffMemberNames(response);
      })
      .catch(error => console.error(error))
      .catch(error =>
        Observable.of({
          type: EPIC_ERROR
        })
      );
  });

export default FetchStaffMemberNamesEpic;

import classnames from "classnames";
import React from "react";

const CustomProgressBar = props => {
  const { title, percent, containerClassNames, progressClassNames } = props;
  return (
    <div className={classnames("progressContainer", containerClassNames)}>
      <div
        className={classnames("progress", progressClassNames)}
        style={{ width: `${percent}%` }}
      />
      <label>{title}</label>
    </div>
  );
};
export default CustomProgressBar;

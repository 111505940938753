import {
  FETCH_NOTIFICATION_ALL_COUNT_REQUEST,
  FETCH_NOTIFICATION_ALL_COUNT_SUCCESS,
  FETCH_NOTIFICATION_ALL_COUNT_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  allCount: {
    pending: {},
    ready: {},
    ready_to_close: {},
    change_request: {},
    expired: {},
    delivery_needed: {},
    autobook: {},
    shortage: {},
    checking_out: {},
    checking_in: {},
    connect_hold: {},
    subrentals_all: {},
  },
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_NOTIFICATION_ALL_COUNT_REQUEST:
      return { ...state, loading: true };

    case FETCH_NOTIFICATION_ALL_COUNT_FAILURE:
      return { ...state, loading: false };
    case FETCH_NOTIFICATION_ALL_COUNT_SUCCESS:
      return {
        ...state,
        allCount: action.response.all_count,
        loading: false
      };
    default:
      return state;
  }
};

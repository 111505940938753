import {
  REQUEST_TRUCK_NAMES,
  RECEIVE_TRUCK_NAMES,
  SET_TRUCK_FILTERED_NAMES,
  SET_TRUCK_NAMES_FILTER,
} from 'Constants/redux';

const INITIAL_STATE = {
  names: [],
  loading: false,
  filteredNames: [],
  filter: {
    startDate: new Date(),
    endDate: new Date(),
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_TRUCK_NAMES:
      return {
        ...state,
        loading: true,
      };
    case RECEIVE_TRUCK_NAMES:
      return {
        ...state,
        loading: false,
        names: action.trucks,
      };
    case SET_TRUCK_NAMES_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
      };
    case SET_TRUCK_FILTERED_NAMES:
      return {
        ...state,
        filteredNames: action.payload,
      };
    default:
      return state;
  }
};

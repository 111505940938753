import React from 'react';
import { StrongDivider } from '../ParentOrderSummaryInvoice.styles';
import { useTheme } from 'styled-components';
import {
  RootContainer,
  RightDateContainer,
  MidSignatureContainer,
  BottomSignatureContainer,
} from './ClientSignatureInvoiceSection.styles';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import { InformationText } from '../../../forms/SideSection/common.styles';
import { ClientSignatureInvoiceSectionProps } from './ClientSignatureInvoiceSection.types';

const ClientSignatureInvoiceSection = ({
  parentOrder,
}: ClientSignatureInvoiceSectionProps) => {
  const { blue } = useTheme() as StyledTheme;

  const renderSignature = () => {};
  const renderSubmittedDate = () => {};

  return (
    <RootContainer>
      <InformationText bold color={blue}>
        Client
      </InformationText>
      <MidSignatureContainer>
        {renderSignature()}
        <StrongDivider backgroundColor={blue} />
        <BottomSignatureContainer>
          <InformationText>Signature</InformationText>
          {renderSubmittedDate()}
        </BottomSignatureContainer>
      </MidSignatureContainer>
      <RightDateContainer>
        <StrongDivider backgroundColor={blue} />
        <InformationText>Date</InformationText>
      </RightDateContainer>
    </RootContainer>
  );
};

export default ClientSignatureInvoiceSection;

import {
  FETCH_RECURRENCES_REQUEST,
  FETCH_RECURRENCES_SUCCESS,
  FETCH_RECURRENCES_FAILURE,
  ADD_RECURRENCE_REQUEST,
  ADD_RECURRENCE_SUCCESS,
  ADD_RECURRENCE_FAILURE,
  SET_RECURRENCES_QUERY,
  SET_RECURRENCES_PAGINATION,
  SET_RECURRENCES_PAGINATION_PAGE,
  FETCH_RECURRENCE_SUCCESS,
} from 'Constants/redux';

const INITIAL_STATE = {
  query: '',
  per: 10,
  pagination: {},
  recurrence: {},
  recurrences: [],
  infiniteLoading: false,
  pagesRemaining: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RECURRENCES_REQUEST:
    case ADD_RECURRENCE_REQUEST:
      return { ...state, infiniteLoading: true };
    case ADD_RECURRENCE_FAILURE:
      return { ...state, infiniteLoading: false };
    case FETCH_RECURRENCES_SUCCESS:
      const { recurrences, pagination } = action.payload;
      return {
        ...state,
        infiniteLoading: false,
        recurrences,
        pagination,
        pagesRemaining: recurrences.length !== pagination.totalObjects,
      };
    case FETCH_RECURRENCE_SUCCESS:
      const { recurrence } = action.payload;
      return { ...state, infiniteLoading: false, recurrence };
    case SET_RECURRENCES_QUERY:
      return { ...state, query: action.payload.query };
    case SET_RECURRENCES_PAGINATION:
      return { ...state, per: action.payload.perPage };
    case SET_RECURRENCES_PAGINATION_PAGE:
      return { ...state, page: action.payload.page };
    case ADD_RECURRENCE_SUCCESS:
      return {
        ...state,
        recurrence: action.payload.recurrence,
      };
    case FETCH_RECURRENCES_FAILURE:
      return { ...state, infiniteLoading: false };
    default:
      return state;
  }
};

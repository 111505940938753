import { Maybe } from '../../types/graphql';

interface FormatDateWithOptionalYear {
  date?: Maybe<Date | string>;
  includeYear?: boolean;
}

export const formatDateWithOptionalYear = ({
  date,
  includeYear = false,
}: FormatDateWithOptionalYear): string => {
  if (!date) {
    return 'Invalid Date';
  }

  date = typeof date === 'string' ? new Date(date) : date;

  const month = (date.getMonth() + 1).toString().padStart(2, '0');
  const day = date
    .getDate()
    .toString()
    .padStart(2, '0');
  const year = date.getFullYear();

  return includeYear ? `${month}/${day}/${year}` : `${month}/${day}`;
};

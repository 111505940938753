import React from 'react';
import classnames from 'classnames';

const Cumulative = ({
  key = null,
  index = null,
  name,
  value,
  onChange,
  disabled,
  containerClassName,
}) => (
  <div
    key={index}
    className={classnames('cumulativeContainer', {
      [containerClassName]: !!containerClassName,
    })}>
    <input
      type='number'
      name={name}
      key={key}
      className='sm wAuto cumulativeText noMargin'
      value={value}
      onChange={(event) => onChange(event)}
      disabled={disabled}
    />
  </div>
);

export default Cumulative;

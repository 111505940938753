import {
  FETCH_RENTAL_INVENTORY_LIST_REQUEST,
  FETCH_RENTAL_INVENTORY_LIST_SUCCESS,
} from 'Constants/redux';
import service from 'Api/service';
import Rx from 'rxjs/Rx';

const fetchRentalInventoryListEpic = (action$) =>
  action$
    .ofType(FETCH_RENTAL_INVENTORY_LIST_REQUEST)
    .debounceTime(500)
    .switchMap((action) => {
      const {
        filter,
        numberPer,
        startDate,
        startDeliveryDate,
        endDeliveryDate,
        endDate,
        showAll,
        query,
        zone,
        page,
        selectedDate,
        deliveryType,
        truckIds,
        itemId,
        productType,
        rentalStatus,
        pickedStatus,
        subRentalSource,
        sortField,
        sortDirection,
        listSortBy,
        deliveryTime,
        prioritize_sticky,
        multilocationAllLocations,
        departmentIds,
        pickListStatus,
      } = action.params;

      return Rx.Observable.fromPromise(
        service.get(
          process.env.REACT_APP_API_DOMAIN +
            '/api/rental_inventories/paginated',
          {
            per: numberPer,
            filter: filter,
            selected_date: selectedDate,
            start_date: startDate,
            end_date: endDate,
            time_zone_offset: zone,
            show_all: showAll,
            query: query,
            page: page,
            delivery_type: deliveryType,
            truck_ids: truckIds,
            item_id: itemId,
            product_type: productType,
            rental_status: rentalStatus,
            picked_status: pickedStatus,
            sub_rental_source: subRentalSource,
            sort_field: sortField,
            sort_direction: sortDirection,
            list_sort_by: listSortBy,
            delivery_time: deliveryTime,
            start_delivery_time: startDeliveryDate,
            end_delivery_time: endDeliveryDate,
            department_ids: departmentIds,
            pick_list_status: pickListStatus,
            ...(prioritize_sticky && { prioritize_sticky: true }),
            ...(multilocationAllLocations && {
              multilocation_all_locations: true,
            }),
          }
        )
      ).map((response) => {
        const newState = Object.assign(
          {},
          {
            rentalInventories: response.data.rentalInventories,
          },
          response.data.meta && { pagination: response.data.meta.pagination }
        );
        return {
          type: FETCH_RENTAL_INVENTORY_LIST_SUCCESS,
          payload: newState,
        };
      });
    });

export default fetchRentalInventoryListEpic;

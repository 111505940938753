import React from 'react';
import {
  OrderInfoContainer,
  OrderInfoDateContainer,
  OrderInfoBottomContainer,
} from './OrderInfoInvoiceSection.styles';
import {
  LineContainer,
  StrongDivider,
  RightAlignedLineContainer,
} from '../ParentOrderSummaryInvoice.styles';
import { useTheme } from 'styled-components';
import { splitDateTime } from '../utils/splitDateTime';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import { InformationText } from '../../../forms/SideSection/common.styles';
import { OrderInfoInvoiceSectionProps } from './OrderInfoInvoiceSection.types';
import originalTerminologies from '../../../../helper_functions/originalTerminologies';

const OrderInfoInvoiceSection = ({
  parentOrder,
}: OrderInfoInvoiceSectionProps) => {
  const { darkblue, blue } = useTheme() as StyledTheme;

  if (!parentOrder?.schedule) return null;

  const { eventStartDateTime, eventEndDateTime } = parentOrder.schedule;

  const renderOrderInfoDate = (label: string, date: string) => {
    const { data } = splitDateTime(date);

    if (data) {
      return (
        <OrderInfoDateContainer>
          <InformationText color={blue}>{label}</InformationText>
          <InformationText color={darkblue} bold>
            {data.formattedDate}
          </InformationText>
          <InformationText color={darkblue} bold>
            {data.formattedTime}
          </InformationText>
        </OrderInfoDateContainer>
      );
    }
  };

  return (
    <OrderInfoContainer>
      <RightAlignedLineContainer>
        <InformationText bold>Invoice #{parentOrder.invoiceId}</InformationText>
      </RightAlignedLineContainer>
      <LineContainer>
        <InformationText bold>Order Info</InformationText>
        <InformationText>
          {originalTerminologies().customIdSingular}: {parentOrder.customId}
        </InformationText>
      </LineContainer>
      <StrongDivider backgroundColor={darkblue} />
      <OrderInfoBottomContainer>
        <InformationText bold color={blue}>
          {parentOrder.name}
        </InformationText>
        {renderOrderInfoDate('Order Start', String(eventStartDateTime))}
        {renderOrderInfoDate('Order End', String(eventEndDateTime))}
      </OrderInfoBottomContainer>
    </OrderInfoContainer>
  );
};

export default OrderInfoInvoiceSection;

import React from 'react';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import LinearProgress from './LinearProgressBar';
import Modal from 'Utils/Modal';
import ScrollArea from './react-scrollbar';
import classnames from 'classnames';

class GlobalProgressBar extends React.Component {
  render() {
    const {
      complete,
      visible,
      successOpen,
      progressOpen,
      closeProgressSuccessModal,
      closeProgressModal,
      importResponse,
      progressType,
    } = this.props;
    let progTitle = '';
    let progText = '';
    let progDescription = '';
    switch (progressType) {
      case 'import':
        progTitle = 'Import in progress';
        progText =
          'You have an import happening. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'export':
        progTitle = 'Export in progress';
        progText =
          'You have an export happening. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'export_invoices':
        progTitle = 'Export in progress';
        progText =
          `Export completed successfully. Please check your account’s email address to download your files. Contact support@tapgoods.com if you do not receive an email.`;
        progDescription = `*Note that larger exports could take up to 6 hours to complete.`
        break;
      case 'generate_serial_numbers':
        progTitle = 'Creation in progress';
        progText =
          'We are generating all serial numbers for this product. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'archive_all_serial_numbers':
        progTitle = 'Archiving in progress';
        progText =
          'We are archiving all serial numbers for this product. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'delete_all_serial_numbers':
        progTitle = 'Deletion in progress';
        progText =
          'We are deleting all serial numbers for this product. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'bulk_check_in_out':
        progTitle = 'Bulk orders action in progress';
        progText =
          'You have a bulk orders action happening. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'bulk_delete_all_serial_numbers':
        progTitle = 'Bulk deletion in progress';
        progText =
          'We are deleting all serial numbers for the selected products. Progress is being displayed in a progress bar at the top of the page.';
        break;
      case 'bulk_generate_all_serial_numbers':
        progTitle = 'Bulk creation in progress';
        progText =
          'We are generating all serial numbers for the selected products. Progress is being displayed in a progress bar at the top of the page.';
        break;
      default:
    }

    const shouldShowProgress = progressType != 'export_invoices' && visible;
    return (
      <div
        className={classnames({
          progressBar: progressType != 'export_invoices',
          visible: shouldShowProgress,
        })}
      >
        {shouldShowProgress && (
          <LinearProgress mode='determinate' value={complete} color='#00ff00' />
        )}
        <Modal
          open={progressOpen}
          toggle={closeProgressModal}
          title={progTitle}
          actions={[
            <a
              id='export_in_progress_ok'
              className='btn full'
              onClick={closeProgressModal}
            >
              OK
            </a>,
          ]}
        >
          <p>{progText}</p>
          {progDescription ?  <p className= 'invoicesPopUpDescription'>{progDescription}</p> : null}
        </Modal>
        <Modal
          open={successOpen}
          toggle={closeProgressSuccessModal}
          title='Import completed!'
          actions={[
            <a
              id='export_completed_ok'
              className='btn full'
              onClick={closeProgressSuccessModal}
            >
              OK
            </a>,
          ]}
        >
          <p>{importResponse.message}</p>
          {importResponse.errors && importResponse.errors.length > 0 && (
            <div>
              <h2>{importResponse.errors.length} Errors:</h2>
              <ScrollArea speed={0.8} horizontal={false}>
                {importResponse.errors.map((err) => {
                  const row = err[0];
                  const rowErrors = err[1];
                  return Object.keys(rowErrors).map((rowErrName) => {
                    const rowErr = rowErrors[rowErrName];
                    return (
                      <div>
                        Row {row} - {rowErr}
                      </div>
                    );
                  });
                })}
              </ScrollArea>
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  const {
    complete,
    visible,
    successOpen,
    importResponse,
    progressOpen,
    progressType,
  } = state.progressBar;
  return {
    complete,
    visible,
    successOpen,
    importResponse,
    progressOpen,
    progressType,
  };
};

export default connect(mapStateToProps, actions)(GlobalProgressBar);

import {
  SET_COMPANY_QUERY,
  REQUEST_COMPANIES,
  REQUEST_INFINITE_COMPANIES,
  REQUEST_COMPANY_NAMES,
  ADD_COMPANY_NAMES_REQUEST,
  INFINITE_COMPANIES_SUCCESS,
  INFINITE_COMPANIES_FAILURE,
  RECEIVE_COMPANIES,
  RECEIVE_COMPANY_NAMES,
  ADD_COMPANY_NAMES_SUCCESS,
  SEARCH_COMPANY_NAMES,
  ADD_INFINITE_COMPANIES_SUCCESS,
  UPDATE_COMPANY_NAME_ON_LIST,
  SET_COMPANY_FILTER,
  SET_COMPANY_SELECTED_IDS_FOR_EXPORT,
} from 'Constants/redux';

const INITIAL_STATE = {
  isFetching: false,
  loading: false,
  shouldRefresh: true,
  pagesRemaining: false,
  items: [],
  pagination: {},
  names: [],
  fetchingNames: false,
  searchCancelToken: null,
  filter: {
    query: '',
    page: 1,
    numberPer: 10,
    categories: '',
  },
  selectedIdsForExport: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_COMPANIES:
      return {
        ...state,
        isFetching: true,
        shouldRefresh: true,
      };
    case REQUEST_COMPANY_NAMES:
    case ADD_COMPANY_NAMES_REQUEST:
      return {
        ...state,
        fetchingNames: true,
        shouldRefresh: true,
      };
    case RECEIVE_COMPANIES:
      return {
        ...state,
        isFetching: false,
        shouldRefresh: true,
        items: action.companies,
        lastUpdated: action.receivedAt,
      };
    case RECEIVE_COMPANY_NAMES:
      const { names, pagination, firstId } = action.payload;
      if (state.filter.page > action.payload.pagination.totalPages) {
        state.filter.page = 1;
      }
      return {
        ...state,
        isFetching: false,
        fetchingNames: false,
        shouldRefresh: true,
        names,
        firstId,
        pagination,
        pagesRemaining: names.length !== pagination.totalObjects,
        selectedIdsForExport: {},
      };
    case ADD_COMPANY_NAMES_SUCCESS:
      const newPagination = action.payload.pagination;
      const newNames = [...state.names, ...action.payload.names];
      return {
        ...state,
        isFetching: false,
        fetchingNames: false,
        shouldRefresh: true,
        names: newNames,
        pagination: newPagination,
        pagesRemaining: newNames.length !== newPagination.totalObjects,
      };
    case SET_COMPANY_QUERY:
      return {
        ...state,
        query: action.payload.query,
      };
    case UPDATE_COMPANY_NAME_ON_LIST:
      const updatedNames = state.names;
      const companyIndex = updatedNames.findIndex(
        (company) => company.id === action.payload.id
      );
      updatedNames[companyIndex] = {
        ...updatedNames[companyIndex],
        name: action.payload.name,
      };
      return {
        ...state,
        names: updatedNames,
      };
    case INFINITE_COMPANIES_SUCCESS:
      const infCompanies = action.payload.companies;
      const infPagination = action.payload.pagination;
      const infTotalCompanies = infCompanies;
      const infPagesRemaining =
        infTotalCompanies.length !== infPagination.totalObjects;
      return {
        ...state,
        loading: false,
        pagination: infPagination,
        pagesRemaining: infPagesRemaining,
        items: infCompanies,
      };
    case ADD_INFINITE_COMPANIES_SUCCESS:
      const addCompanies = action.payload.companies;
      const addPagination = action.payload.pagination;
      const addTotalCompanies = state.items.slice().concat(addCompanies);
      const addPagesRemaining =
        addTotalCompanies.length !== addPagination.totalObjects;
      return {
        ...state,
        loading: false,
        pagination: addPagination,
        pagesRemaining: addPagesRemaining,
        items: addTotalCompanies,
      };
    case INFINITE_COMPANIES_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case REQUEST_INFINITE_COMPANIES:
      return {
        ...state,
        loading: true,
      };
    case SEARCH_COMPANY_NAMES:
      return {
        ...state,
        query: action.query,
      };
    case SET_COMPANY_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case SET_COMPANY_SELECTED_IDS_FOR_EXPORT:
      return {
        ...state,
        selectedIdsForExport: action.selectedIdsForExport,
      };
    default:
      return state;
  }
};

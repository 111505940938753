export const REQUEST_CUSTOMERS = "REQUEST_CUSTOMERS";
export const REQUEST_CUSTOMER_NAMES = "REQUEST_CUSTOMER_NAMES";
export const RECEIVE_CUSTOMERS = "RECEIVE_CUSTOMERS";
export const REQUEST_CUSTOMER = "REQUEST_CUSTOMER";
export const RECEIVE_CUSTOMER = "RECEIVE_CUSTOMER";
export const RECEIVE_CUSTOMER_NAMES = "RECEIVE_CUSTOMER_NAMES";
export const DELETE_CUSTOMER = "DELETE_CUSTOMER";
export const SET_CUSTOMER_TYPE = "SET_CUSTOMER_TYPE";
export const OPEN_SUCCESS_SNACKBAR = "OPEN_SUCCESS_SNACKBAR";
export const CLOSE_SUCCESS_SNACKBAR = "CLOSE_SUCCESS_SNACKBAR";
export const SET_OPEN_VALIDATION_SNACKBAR = "SET_OPEN_VALIDATION_SNACKBAR";
export const SET_CLOSED_VALIDATION_SNACKBAR = "SET_CLOSED_VALIDATION_SNACKBAR";
export const SET_ERRORS = "SET_ERRORS";
export const SET_ALERT = "SET_ALERT";
export const SET_SELECTED_DATE = "SET_SELECTED_DATE";
export const REQUEST_CATEGORIES = "REQUEST_CATEGORIES";
export const RECEIVE_CATEGORIES = "RECEIVE_CATEGORIES";
export const REQUEST_INFINITE_CUSTOMERS = "REQUEST_INFINITE_CUSTOMERS";
export const INFINITE_CUSTOMERS_SUCCESS = "INFINITE_CUSTOMERS_SUCCESS";
export const ADD_INFINITE_CUSTOMERS_SUCCESS = "ADD_INFINITE_CUSTOMERS_SUCCESS";
export const INFINITE_CUSTOMERS_FAILURE = "INFINITE_CUSTOMERS_FAILURE";
export const SET_CUSTOMER_QUERY = "SET_CUSTOMER_QUERY";
export const STORE_SEARCH_CANCEL_TOKEN = "STORE_SEARCH_CANCEL_TOKEN";
export const OPEN_LOADING_SPINNER = "OPEN_LOADING_SPINNER";
export const CLOSE_LOADING_SPINNER = "CLOSE_LOADING_SPINNER";
export const START_PROGRESS_BAR = "START_PROGRESS_BAR";
export const UPDATE_PROGRESS_BAR = "UPDATE_PROGRESS_BAR";
export const CLOSE_PROGRESS_BAR = "CLOSE_PROGRESS_BAR";
export const CLOSE_PROGRESS_SUCCESS_MODAL = "CLOSE_PROGRESS_SUCCESS_MODAL";
export const OPEN_PROGRESS_SUCCESS_MODAL = "OPEN_PROGRESS_SUCCESS_MODAL";
export const CLOSE_PROGRESS_MODAL = "CLOSE_PROGRESS_MODAL";
export const OPEN_PROGRESS_MODAL = "OPEN_PROGRESS_MODAL";

import { createSelector } from 'reselect';

const selectPricingTierStandard = (state) =>
  state.pricingTier.pricingTierStandard?.pricingTier;
const selectPricingTierPremier = (state) =>
  state.pricingTier.pricingTierPremier?.pricingTier;
const selectPricingTierSelect = (state) =>
  state.pricingTier.pricingTierSelect?.pricingTier;
const selectPricingTierEssentials = (state) =>
  state.pricingTier.pricingTierEssentials?.pricingTier;

export const selectPricingTiers = createSelector(
  selectPricingTierStandard,
  selectPricingTierSelect,
  selectPricingTierPremier,
  selectPricingTierEssentials,
  (standard, select, premier, essentials) => ({
    standard,
    select,
    premier,
    essentials,
  })
);

export function pricingTierGetter(
  getPricingTierStandard,
  getPricingTierSelect,
  getPricingTierPremier,
  getPricingTierEssentials,
) {
  const actionMap = {
    standard: getPricingTierStandard,
    select: getPricingTierSelect,
    premier: getPricingTierPremier,
    essentials: getPricingTierEssentials,
  };
  return Object.keys(actionMap).map((st) => {
    const data = {
      planType: st,
    };
    return actionMap[st](data);
  });
}

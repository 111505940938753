import {
  START_PROGRESS_TRACKER,
  UPDATE_PROGRESS_TRACKER,
  CLOSE_PROGRESS_TRACKER,
  CLOSE_PROGRESS_TRACKER_SUCCESS,
  CLOSE_PROGRESS_TRACKER_START,
  OPEN_PROGRESS_TRACKER_START,
  OPEN_PROGRESS_TRACKER_SUCCESS,
  UPDATE_CHANNEL_FOR_TRACKER,
  UPDATE_CONNECTION_FOR_TRACKER
} from "Constants/redux";

import { getAuthToken } from "Api";
import ActionCable from "actioncable";
import {actioncableCheck} from "Actions"

const createConnection = () => {
  const authToken = getAuthToken();
  const connection = ActionCable.createConsumer(
    `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${authToken["access-token"]}&client=${authToken["client"]}&uid=${authToken["uid"]}&resource_type=employee`
  );
  return connection;
};
const createChannel = (dispatch, connection, id) => {
  const subscription = connection.subscriptions.create(
    { channel: "ProgressChannel", id: id },
    {
      connected: () => {},
      received: data => {
        dispatch(checkProgress(data));
      }
    }
  );
  actioncableCheck(connection)
  return subscription
};

const checkProgress = data => {
  return (dispatch, getState) => {
    const { current_count, finish_count, response, status } = data;

    const progress = (current_count / finish_count) * 100;
    dispatch(updateProgressTracker(progress));
    if (status === "completed") {
      dispatch(openProgressTrackerSuccess(response));
      dispatch(closeProgressTracker());
    }
  };
};
export const startProgressTracker = (id, startMessage, completedMessage, title) => {
  return (dispatch, getState) => {
    const state = getState();
    const connection = state.connection || createConnection();
    if (state.channel) {
      state.channel.unsubscribe();
    }
    const channel = createChannel(dispatch, connection, id);
    dispatch({
      type: START_PROGRESS_TRACKER,
      startMessage: startMessage,
      completedMessage: completedMessage,
      title: title
    });
    dispatch({
      type: UPDATE_CONNECTION_FOR_TRACKER,
      connection: connection
    });
    dispatch({
      type: UPDATE_CHANNEL_FOR_TRACKER,
      channel: channel
    });
  };
};
export const updateProgressTracker = (complete = 0) => {
  return {
    type: UPDATE_PROGRESS_TRACKER,
    payload: {
      complete
    }
  };
};
export const closeProgressTracker = () => {
  return {
    type: CLOSE_PROGRESS_TRACKER
  };
};
export const closeProgressTrackerSuccess = () => {
  return {
    type: CLOSE_PROGRESS_TRACKER_SUCCESS
  };
};
export const closeProgressTrackerStart = () => {
  return {
    type: CLOSE_PROGRESS_TRACKER_START
  };
};
export const openProgressTrackerSuccess = (importResponse = {}) => {
  return {
    type: OPEN_PROGRESS_TRACKER_SUCCESS,
    importResponse
  };
};
export const openProgressTrackerStart = (importResponse = {}) => {
  return {
    type: OPEN_PROGRESS_TRACKER_START
  };
};

import { TOGGLE_SERIALIZED_ITEM_DRAWER } from 'Constants/redux';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';
import Inventory from '../../components/rentals/form/Inventory';

export const toggleSerializedItemDrawer = (
  status: boolean,
  productId: number | null = null,
  product: Inventory | {} = {}
): ThunkAction<void, AppState, unknown, RentalFormActionTypes> => (
  dispatch
) => {
  dispatch({
    type: TOGGLE_SERIALIZED_ITEM_DRAWER,
    status,
    productId,
    product,
  });
};

import { getAuthToken } from 'Api';
import service from 'Api/service';
// SerializedProducts

export default {
  showOne: (product, serialNumber) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}`
    );
  },
  showBySerialNumber: (product, serialNumber) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}/show_by_serial_number`
    );
  },
  fetchSerializedProductsForCheckOut: (product, rentalToken) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/available_to_check_out`,
      {
        rental_token: rentalToken
      }
    );
  },
  fetchSerializedProductsFromLink: (link) => {
    return service.get(process.env.REACT_APP_API_DOMAIN + link)
  },
  fetchSerializedProductsForCheckIn: (product, rentalToken) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/available_to_check_in`,
      {
        rental_token: rentalToken
      }
    );
  },
  updateUsageUnit: (product, serialNumber, updatedUsageUnit) => {
    return service.patch(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${product.id}/serialized_products/${serialNumber}/update_usages`,
      updatedUsageUnit
    );
  },
  scanOneCheckIn: (pick_list_item, serialNumber) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/pick_list_items/${pick_list_item.id}/serialized_products/${serialNumber}/scan_check_in`
    );
  },
  printAll: (productId) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/print`
    );
  },
  generateSerializedProducts: (productId, selectedProducts = []) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/generate_serialized_products`,
      {
        serialized_products_ids: selectedProducts
      }
    );
  },
  printOne: (productId, serialNumber) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/products/${productId}/serialized_products/${serialNumber}/print_single`
    );
  },
  itemInfo: (rentalToken, serialNumber, type) =>
    service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/rentals/${rentalToken}/search_by_serial_number`,
      {
        serial_number: serialNumber,
        type: type,
      }
    ),
};

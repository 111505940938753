import React from "react";
import { connect } from "react-redux";
import LoadingSpinner from "../../components/utils/LoadingSpinner";
import { Redirect } from "react-router-dom";

// BusinessAuthorization HOC
const Authorization = allowedTiers => {
  return WrappedComponent => {
    class WithAuthorization extends React.Component {
      render() {
        /*eslint-disable */
        const { business, businessLoading, ...other } = this.props;
        /*eslint-disable */
        if (business.id === undefined && businessLoading) {
          return <LoadingSpinner />;
        } else if (
          business.softwareTier &&
          allowedTiers.includes(business.softwareTier)
        ) {
          return <WrappedComponent {...other} />;
        } else {
          return <Redirect to="notFound" />;
        }
      }
    }
    const mapStateToProps = state => {
      const { business } = state.business;
      const businessLoading = state.business.loading;

      return { business, businessLoading };
    };
    return connect(mapStateToProps)(WithAuthorization);
  };
};

export default Authorization;

import * as actionTypes from "Constants/redux";

export function setTutorialProductProps(newProps) {
  return {
    type: actionTypes.SET_TUTORIAL_PRODUCT_PROPS,
    payload: newProps
  };
}

export function setTutorialRentalProps(newProps) {
  return {
    type: actionTypes.SET_TUTORIAL_RENTAL_PROPS,
    payload: newProps
  };
}

export function setTutorialCustomerProps(newProps) {
  return {
    type: actionTypes.SET_TUTORIAL_CUSTOMER_PROPS,
    payload: newProps
  };
}

import {
  MOCK_PAGE_REQUEST,
  MOCK_PAGE_SUCCESS,
  MOCK_PAGE_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  features: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case MOCK_PAGE_REQUEST:
      return { ...state, loading: true };

    case MOCK_PAGE_FAILURE:
      return { ...state, loading: false };
    case MOCK_PAGE_SUCCESS:
      return {
        ...state,
        mockPageContents: action.response,
        loading: false
      };
    default:
      return state;
  }
};

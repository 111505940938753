import React from 'react';
import { Sparkle } from 'Utils/SvgIcons';
import ImportWizardProgress from './ImportWizardProgress';
import ImportWizardActions from './ImportWizardActions';
const stepsWithActions = ['upload', 'adjust'];

class ImportWizardHeader extends React.Component {
  render() {
    const {
      importStatus,
      uploading,
      onCancel,
      onNextStep,
      displayButtons,
      disableFinalizeButton,
      onSaveForLater,
      headerTitle,
    } = this.props;
    let sparkleColor = stepsWithActions.includes(importStatus)
      ? '#163C5B'
      : '#278ADA';
    return (
      <header className='ImportWizardHeader'>
        <div className='ImportWizardSubHeader'>
          <h1>{headerTitle}</h1>
          <ImportWizardProgress
            importStatus={importStatus}
            uploading={uploading}
          />
        </div>
        <div className='importWizardHeaderActions'>
          <ImportWizardActions
            className='importWizardActions'
            onNextStep={onNextStep}
            onCancel={onCancel}
            displayButtons={displayButtons}
            disableFinalizeButton={disableFinalizeButton}
            importStatus={importStatus}
            onSaveForLater={onSaveForLater}
          />
          <label>
            <Sparkle fill={sparkleColor} />
          </label>
        </div>
      </header>
    );
  }
}

export default ImportWizardHeader;

import { SET_OPERATIONS_FILTER } from 'Constants/redux';

const INITIAL_STATE = {
  operationsFilter: {
    startDate: new Date(),
    endDate: new Date(),
    deliveryDate: new Date(),
    truckIds: [],
    selectedTruckObjects: [],
    employeeIds: [],
    showPickups: '',
    showServiceTickets: '',
    showDeliveries: '',
    rentalStatus: '',
    rentalSource: []
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_OPERATIONS_FILTER:
      return {
        ...state,
        operationsFilter: action.payload.filter,
      };
    default:
      return state;
  }
};

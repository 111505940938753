import { FETCH_PRODUCT_LIST_REQUEST } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";

const fetchProductListEpic = action$ =>
  action$
    .ofType(FETCH_PRODUCT_LIST_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.post(
          `${process.env.REACT_APP_API_DOMAIN}/api/products/list`,
          action.options
        )
      ).map(response => {
        return actions.receiveProductList(response.data.products);
      });
    });

export default fetchProductListEpic;

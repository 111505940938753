export const capitalizeFirstLetter = (string) => {
  if (string == undefined) {
    return '';
  }
  return string.charAt(0).toUpperCase() + string.slice(1);
};

export const capitalizeAllWords = (string, splitter = ' ', joiner = null) => {
  if (string === '') return string;
  if (!joiner) joiner = splitter;
  let splitString = string.split(splitter);

  return splitString
    .map((word) => {
      return word.charAt(0).toUpperCase() + word.substr(1);
    })
    .join(joiner);
};

export const arraysEqual = (arr1, arr2) => {
  if (arr1.length !== arr2.length) return false;
  for (var i = arr1.length; i--; ) {
    if (arr1[i] !== arr2[i]) return false;
  }

  return true;
};

export const determineSortClass = (sort, field) => {
  let newSort = '';
  if (sort.field === field) {
    newSort = sort.direction === 'asc' ? 'desc' : 'asc';
  }

  return 'sortable ' + newSort;
};

export const determineNewSort = (sort, field) => {
  let newSort = 'asc';
  if (sort.field === field) {
    newSort = sort.direction === 'asc' ? 'desc' : 'asc';
  }

  return newSort;
};

export const humanize = (str) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function(m) {
      return m.toUpperCase();
    });
};

export const humanizeAndCapitalizeAll = (str) => {
  const humanized = humanize(str);
  const capitalized = capitalizeAllWords(humanized);
  return capitalized;
};

export const debounce = (fn, delay) => {
  var timer = null;
  return function() {
    var context = this,
      args = arguments;
    clearTimeout(timer);
    timer = setTimeout(function() {
      fn.apply(context, args);
    }, delay);
  };
};

export const throttle = (func, limit) => {
  let lastFunc;
  let lastRan;
  return function() {
    const context = this;
    const args = arguments;
    if (!lastRan) {
      func.apply(context, args);
      lastRan = Date.now();
    } else {
      clearTimeout(lastFunc);
      lastFunc = setTimeout(function() {
        if (Date.now() - lastRan >= limit) {
          func.apply(context, args);
          lastRan = Date.now();
        }
      }, limit - (Date.now() - lastRan));
    }
  };
};

export const removeLastUrlSegment = (url) => {
  return url.substr(0, url.lastIndexOf('/'));
};

export const getLastUrlSegment = (url) => {
  return url.substr(url.lastIndexOf('/') + 1);
};

export const formatPhoneNumber = (phoneNumberString) => {
  if (!phoneNumberString) return '';
  var cleaned = ('' + phoneNumberString).replace(/\D/g, '');
  var match = cleaned.match(/^(1|)?(\d{3})?(\d{3})(\d{4})$/);
  if (match) {
    var intlCode = match[1] ? '+1 ' : '';
    var areaCode = match[2] ? `(${match[2]}) ` : '';
    return [intlCode, areaCode, match[3], '-', match[4]].join('');
  } else {
    return phoneNumberString;
  }
};

export function trim(str) {
  return (str || '').replace(/^\s+|\s+$/g, '');
}

export function isSupportedBrowser() {
  return !isIE();
}
export function isChrome() {
  return (
    /Chrome/.test(navigator.userAgent) && /Google Inc/.test(navigator.vendor)
  );
}

function isIE() {
  const ua = navigator.userAgent;
  /* MSIE used to detect old browsers and Trident used to newer ones*/
  var is_ie = ua.indexOf('MSIE ') > -1 || ua.indexOf('Trident/') > -1;

  return is_ie;
}

export function getCountry(prevCountry, newCountry) {
  return !prevCountry || prevCountry === '' ? newCountry : prevCountry;
}

export function arrayMerge(array1, array2, index = 0) {
  return array1.splice(index, 0, ...array2) && array1;
}

export function generateAlphabetFromNumber(n) {
  var ordA = 'a'.charCodeAt(0);
  var ordZ = 'z'.charCodeAt(0);
  var len = ordZ - ordA + 1;

  var s = '';
  while (n >= 0) {
    s = String.fromCharCode((n % len) + ordA) + s;
    n = Math.floor(n / len) - 1;
  }
  return s.toUpperCase();
}

export function defaultPricingDisplay(product) {
  if (product.defaultPricing) {
    if (product.defaultPricing.includes('standard_flat_price')) {
      const flatPrice = product.flatPrices.find((fp) =>
        product.defaultPricing.includes(fp.name)
      );
      return `Flat: ${flatPrice.name}`;
    } else if (product.defaultPricing === 'time-based') {
      return 'Time-Based';
    }
  }
  return '';
}

export function sepByCapitalLetterAndCapitalize(str) {
  return capitalizeFirstLetter(str.split(/(?=[A-Z])/).join(" "));
}
export const present = (stringValue) => stringValue.trim() !== '';

export const allEmpty = (attributes) =>
  !attributes.some((attribute) => present(attribute));

import {
  FETCH_TERMINOLOGIES_REQUEST,
  FETCH_TERMINOLOGIES_SUCCESS,
  FETCH_TERMINOLOGIES_FAILURE,
  FETCH_ORIGINAL_TERMINOLOGIES_REQUEST,
  FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS,
  FETCH_ORIGINAL_TERMINOLOGIES_FAILURE,
} from 'Constants/redux';

const INITIAL_STATE = {
  terminologies: [],
  originalTerminologies: [
    { originalWord: 'venue', singularWord: 'Venue', pluralWord: 'Venues' },
    {
      originalWord: 'purchase',
      singularWord: 'Purchase',
      pluralWord: 'Purchases',
    },
    {
      originalWord: 'parent_order',
      singularWord: 'Parent Order',
      pluralWord: 'Parent Orders',
    },
    {
      originalWord: 'check_in',
      singularWord: 'Check in',
      pluralWord: 'Check ins',
    },
    {
      originalWord: 'checking_in',
      singularWord: 'Checking in',
      pluralWord: 'Checking ins',
    },
    {
      originalWord: 'checked_in',
      singularWord: 'Checked in',
      pluralWord: 'Checked ins',
    },
    {
      originalWord: 'check_out',
      singularWord: 'Check out',
      pluralWord: 'Check outs',
    },
    {
      originalWord: 'checking_out',
      singularWord: 'Checking out',
      pluralWord: 'Checking outs',
    },
    {
      originalWord: 'checked_out',
      singularWord: 'Checked out',
      pluralWord: 'Checked outs',
    },
    {
      originalWord: 'secondary_order',
      singularWord: 'Secondary Order',
      pluralWord: 'Secondary Orders',
    },
    {
      originalWord: 'custom_id',
      singularWord: 'Custom ID',
      pluralWord: 'Custom IDs',
    },
  ],
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_TERMINOLOGIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_TERMINOLOGIES_FAILURE:
      return { ...state, loading: false };
    case FETCH_TERMINOLOGIES_SUCCESS:
      return {
        ...state,
        terminologies: [...action.response.data.terminologies],
        loading: false,
      };
    case FETCH_ORIGINAL_TERMINOLOGIES_REQUEST:
      return { ...state, loading: true };
    case FETCH_ORIGINAL_TERMINOLOGIES_FAILURE:
      return { ...state, loading: false };
    case FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS:
      return {
        ...state,
        originalTerminologies: [...action.response.data.originalTerminologies],
        loading: false,
      };
    default:
      return state;
  }
};

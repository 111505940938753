import {
  PAGINATE_RENTAL_INVENTORY_LIST_REQUEST,
  PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS
} from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";

const paginateRentalInventoryListEpic = action$ =>
  action$
    .ofType(PAGINATE_RENTAL_INVENTORY_LIST_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      const {
        link,
        numberPer,
        status,
        startDate,
        endDate,
        query
      } = action.params;
      return Rx.Observable.fromPromise(
        service.get(process.env.REACT_APP_API_DOMAIN + link, {
          per: numberPer,
          filter: status,
          start_date: startDate,
          end_date: endDate,
          query
        })
      ).map(response => {
        return {
          type: PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS,
          payload: {
            rentalInventories: response.data.rentalInventories,
            pagination: response.data.meta.pagination
          }
        };
      });
    });

export default paginateRentalInventoryListEpic;

import { adminService } from "Api/service";
import { ROOT_URL, saveError } from "Api";
import {
  FETCH_ADMIN_BUSINESSES_REQUEST,
  FETCH_ADMIN_BUSINESSES_FAILURE,
  FETCH_ADMIN_BUSINESSES_SUCCESS,
  BULK_UPDATE_ADMIN_BUSINESSES_REQUEST,
  BULK_UPDATE_ADMIN_BUSINESSES_FAILURE,
  BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS
} from "Constants/redux";

export const fetchAdminBusinesses = (type, filter = {}) => {
  return (dispatch, getState) => {
    const {
      filter: { locale }
    } = getState().adminReports;

    dispatch({ type: FETCH_ADMIN_BUSINESSES_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/businesses/`, {
        type: type,
        query: filter.query || "",
        locale
      })
      .then(response => {
        return dispatch({
          response,
          type: FETCH_ADMIN_BUSINESSES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_ADMIN_BUSINESSES_FAILURE
        });
      });
  };
};

export const bulkUpdateAdminBusinesses = (data, onSuccess) => {
  return (dispatch, getState) => {
    dispatch({ type: BULK_UPDATE_ADMIN_BUSINESSES_REQUEST });
    adminService
      .put(`${ROOT_URL}/admin/businesses/bulk_update`, data)
      .then(response => {
        dispatch({
          response,
          type: BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS
        });
        onSuccess();
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: BULK_UPDATE_ADMIN_BUSINESSES_FAILURE
        });
      });
  };
};

import React from "react";
import { Link } from "react-router-dom";
import classnames from "classnames";
import TimeAgo from "react-timeago";
import { connect } from "react-redux";

const formatter = (value, unit, suffix, date) => {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date);
  var secondDate = new Date();
  if (date) {
    var diffDays = Math.round(Math.abs((date - secondDate.getTime()) / oneDay));

    if (diffDays > 7) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric"
      });
      return date_formatter.format(firstDate);
    } else if (diffDays > 1) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        weekday: "short"
      });
      return date_formatter.format(firstDate);
    } else {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric"
      });
      return date_formatter.format(firstDate);
    }
  }
};

class MobileConversationCard extends React.Component {
  renderContactName = () => {
    const { conversation, location } = this.props;
    let contactName = "";
    if (conversation.conversationType === "b2b") {
      // If conversation is b2b,
      if (conversation.supplierLocationInfo.id === location.id) {
        contactName = conversation.tenantLocationInfo.name;
      } else {
        contactName = conversation.supplierLocationInfo.name;
      }
    } else {
      // If conversation is b2c,
      contactName = conversation.contactNames;
    }
    return contactName;
  };

  render() {
    const { conversation, forRental, rental } = this.props;
    let first =
      conversation.messages.length > 0
        ? conversation.messages[conversation.messages.length - 1]
        : null;
    const link = forRental
      ? `/orders/rentals/${rental.token}/conversations/${conversation.id}`
      : `/conversations/${conversation.id}`;
    return (
      <Link
        className={classnames({
          rTableRow: true,
          unread: conversation.unreadMessages > 0
        })}
        to={link}
      >
        <td>
          <span>{this.renderContactName()}</span>
        </td>
        <td>
          {first && <TimeAgo date={first.createdAt} formatter={formatter} />}
        </td>
      </Link>
    );
  }
}

const mapStateToProps = state => {
  const { rental } = state.rental;
  const { location } = state.location;
  return { rental, location };
};

export default connect(mapStateToProps)(MobileConversationCard);

import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import NewMessageComponent from "Components/conversations/NewMessage";

const NewMessageCont = ({ createMessage, conversation }) => {
  const handleSubmit = values => {
    createMessage(conversation.id, values.message);
  };

  return <NewMessageComponent onSubmit={handleSubmit} />;
};

const mapStateToProps = state => {
  const { conversation } = state.conversation;

  return { conversation };
};

export default connect(mapStateToProps, actions)(NewMessageCont);

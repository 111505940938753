import React from "react";
import { standardService } from "Api/service";
import { Settings } from "Utils/SvgIcons";

// A Component for showing email content
class Email extends React.Component {
  constructor() {
    super();
    this.state = {
      loading: true,
      data: null,
      failed: false
    };
  }

  componentDidMount() {
    this.fetchEmail(this.props.match.params.id);
  }

  fetchEmail(id) {
    const component = this;
    component.setState({
      loading: true
    });
    standardService
      .get(process.env.REACT_APP_API_DOMAIN + "/api/emails/" + id)
      .then(response => {
        component.setState({
          loading: false,
          data: response.data.email.body
        });
      })
      .catch(error => {
        component.setState({
          loading: false,
          failed: true
        });
      });
  }

  render() {
    const { data, failed } = this.state;
    if (failed) {
      return (
        <div className="errorLanding">
          <p>
            I guess our email didn’t get delivered to this browser. Try it
            again.
          </p>
          <h1>
            <Settings />
          </h1>
          <p>&nbsp;</p>
        </div>
      );
    }
    return (
      <div className="emailWrapper">
        <div
          className="content"
          className="editedText"
          dangerouslySetInnerHTML={{
            __html: data
          }}
        />
      </div>
    );
  }
}

export default Email;

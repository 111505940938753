import React from "react";
import { standardService } from "Api/service";
import { saveError } from "Api";
import classnames from "classnames";
import { connect } from "react-redux";
import * as actions from "Actions/dashboardActionCreators";
import ValidationPopUp from "Utils/ValidationPopUp";
import { parseQuery } from "HelperFunctions/QueryString";

const prefilledMessages = {
  demo: "I would like to schedule a demo."
};

// Don't confuse this with /contacts or ContactFormContainer. Those are not the ContactUs form.

class ContactUs extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
      contact: {
        name: "",
        company: "",
        email_address: "",
        phone_number: "",
        message: ""
      },
      errors: {
        name: "",
        email_address: "",
        company: "",
        phone_number: "",
        message: ""
      },
      nameValid: false,
      emailValid: false
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.build_rails_object = this.build_rails_object.bind(this);
  }

  componentDidMount() {
    const { authenticated, user } = this.props;
    const { message: prefillMessageType, email: prefillEmail } = parseQuery(
      window.location.search
    );
    let contact = this.state.contact;
    if (prefillMessageType && prefilledMessages[prefillMessageType]) {
      contact = Object.assign(contact, {
        message: prefilledMessages[prefillMessageType]
      });
    }

    if (prefillEmail) {
      contact = Object.assign(contact, {
        email_address: prefillEmail
      });
    }
    if (authenticated) {
      contact = Object.assign(contact, {
        name: `${user.firstName} ${user.lastName}`,
        email_address: user.email
      });
    }
    this.setState({
      contact: contact
    });
  }

  UNSAFE_componentWillReceiveProps() {
    const { authenticated, user } = this.props;
    if (authenticated) {
      const contact = Object.assign(this.state.contact, {
        name: user.firstName + " " + user.lastName,
        email_address: user.email
      });

      this.setState({
        contact: contact
      });
    }
  }

  handleSubmit(event) {
    event.preventDefault();
    const component = this;
    standardService
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/contact",
        this.build_rails_object()
      )
      .then(response => {
        component.setState({
          submitted: true
        });
      })
      .catch(error => {
        const errors = error.response.data;
        const { setErrors } = this.props;
        setErrors(errors);
        component.setState({
          errors: errors
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const contact = Object.assign(this.state.contact, { [name]: value });
    this.setState({
      contact: contact
    });
  }

  build_rails_object() {
    return {
      contact: {
        name: this.state.contact.name,
        company: this.state.contact.company,
        email_address: this.state.contact.email_address,
        phone_number: this.state.contact.phone_number,
        message: this.state.contact.message
      }
    };
  }

  render() {
    return (
      <div className="contentWrapper">
        <div className="content thin contactContent">
          <h1>Contact Us</h1>
          {this.state.submitted ? (
            <div>
              <h4>Thank you!</h4>
              <p>We got your message and will get back to you shortly.</p>
            </div>
          ) : (
            <form onSubmit={this.handleSubmit} className="simpleForm">
              <label>*</label>
              <input
                name="name"
                placeholder="Name"
                type="text"
                value={this.state.contact.name}
                className={classnames({
                  error: this.state.errors.name
                })}
                onChange={this.handleChange}
              />
              <input
                name="company"
                placeholder="Company"
                type="text"
                value={this.state.contact.company}
                className={classnames({
                  error: this.state.errors.company
                })}
                onChange={this.handleChange}
              />
              <label>*</label>
              <input
                name="email_address"
                placeholder="Email"
                type="text"
                value={this.state.contact.email_address}
                className={classnames({
                  error: this.state.errors.email_address
                })}
                onChange={this.handleChange}
                pattern="\b[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}\b"
              />
              <input
                name="phone_number"
                placeholder="Phone"
                type="text"
                className={classnames({
                  error: this.state.errors.phone_number
                })}
                value={this.state.contact.phone_number}
                onChange={this.handleChange}
              />
              <textarea
                name="message"
                placeholder="Message"
                value={this.state.contact.message}
                className={classnames({
                  error: this.state.errors.message
                })}
                onChange={this.handleChange}
              />
              <input type="submit" className="btn" value="Submit" />
            </form>
          )}
          <ValidationPopUp message={this.state.errors} />
        </div>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { errorsOpen, errors } = state.dashboard;
  const { user, authenticated } = state.auth;
  return { errorsOpen, errors, user, authenticated };
};

export default connect(mapStateToProps, actions)(ContactUs);

import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';

class PrintDispatchSummary extends React.Component {
  state = {
    directions: null,
  };

  setDirections = (response) => {
    this.setState({
      directions: response,
    });
  };

  componentDidMount() {
    const { fetchTruckRoute, paramsId } = this.props;
    const id = paramsId || this.props.match.params.id;
    fetchTruckRoute(id);
  }

  getTransportList(rentalItems, type) {
    const itemMap = new Map();

    rentalItems.forEach((rentalItem) => {
      rentalItem
        .filter((item) => item.rentalTransportType === type)
        .flatMap((transport) => [
          ...transport.rentalItems,
          ...transport.rentalBundles,
          ...transport.rentalAddOns,
          ...transport.rentalAccessories,
        ])
        .forEach((item) => {
          if (itemMap.has(item.name)) {
            const existingItem = itemMap.get(item.name);
            existingItem.quantity += item.quantity;
          } else {
            itemMap.set(item.name, { ...item });
          }
        });
    });

    return Array.from(itemMap.values());
  }

  renderTable(list, type) {
    const { fullInventorySheet } = this.props;

    const rows = list.map((item) => (
      <tr key={item.id} className='dispatchRow'>
        {!!fullInventorySheet && <td className='filled'>{item.quantity}</td>}
        <td className='filled'>{item.name}</td>
        <td className='empty'></td>
        <td className='empty'></td>
        <td className='empty'></td>
        <td className='empty'></td>
      </tr>
    ));

    return (
      <div className='dispatchSummaryTable'>
        <table>
          <thead>
            <tr className='dispatchTransport'>
              <th>{type === 'drop_off' ? 'Drop Off' : 'Pick Up'}</th>
            </tr>
            <tr className='dispatchHeaders'>
              {!!fullInventorySheet && (
                <th className='caption' style={{ width: '10%' }}>
                  Qty
                </th>
              )}
              <th className='caption' style={{ width: '50%' }}>
                Name
              </th>
              <th className='caption align-center' style={{ width: '8%' }}>
                Out
              </th>
              <th className='caption align-center' style={{ width: '8%' }}>
                In
              </th>
              <th className='caption align-center' style={{ width: '10%' }}>
                Missing
              </th>
              <th className='caption align-center' style={{ width: '20%' }}>
                Initial
              </th>
            </tr>
          </thead>
          <tbody>{rows}</tbody>
        </table>
        <div className='printSignature' style={{ width: '100%' }}>
          <div className='printContainer' style={{ fontSize: '1.2rem' }}>
            <label className='signatureTitle'>
              {type === 'drop_off' ? 'Check Out' : 'Check In'}
            </label>
            <label className='signatureLine' style={{ width: '47%' }}>
              Signature
            </label>
            <label className='signatureLine' style={{ width: '20%' }}>
              Date
            </label>
          </div>
          <p>
            I agree that I have {type === 'drop_off' ? 'delivered' : 'received'}{' '}
            all items listed above and that all items are in acceptable
            condition.
          </p>
        </div>
      </div>
    );
  }

  render() {
    const { truckRoute, fullInventorySheet } = this.props;
    const { name} = truckRoute;
    let { rentalItems } = truckRoute;
    let deliveryDate;
    let dropOffTable;
    let pickUpTable;

    let dropOffs = [];
    let pickUps = [];
    if (rentalItems.length > 0) {
      deliveryDate = truckRoute.deliveryDate;
      rentalItems.forEach((rentalItem) => {
        let dropOffItem = rentalItem.filter(item => item.rentalTransportType === 'drop_off');
        let pickUpItem = rentalItem.filter(item => item.rentalTransportType === 'pick_up');

        if (dropOffItem) {
          dropOffItem.forEach((dropOff) => dropOffs.push([
            ...dropOff.rentalItems,
            ...dropOff.rentalBundles,
            ...dropOff.rentalAddOns,
            ...dropOff.rentalAccessories
          ]));
        };

        if (pickUpItem) {
          pickUpItem.forEach((pickUp) => pickUps.push([
            ...pickUp.rentalItems,
            ...pickUp.rentalBundles,
            ...pickUp.rentalAddOns,
            ...pickUp.rentalAccessories
          ]));
        };
      });
    };

    if (dropOffs.length > 0) {
      const dropOffList = dropOffs.reduce((prev, curr) => {
        return prev.concat(curr);
      }, []);

      dropOffTable = this.renderTable(dropOffList, 'drop_off');
    };

    if (pickUps.length > 0) {
      const pickUpList = pickUps.reduce((prev, curr) => {
        return prev.concat(curr);
      }, []);

      pickUpTable = this.renderTable(pickUpList, 'pick_up');
    };

    return (
      <div className='dispatchSummaryPrintWrapper'>
        <div>
          <div className='content'>
            <div className='header'>
              <h1>
                {!!fullInventorySheet
                  ? 'Full Inventory Sheet'
                  : 'Dispatch Summary'}
              </h1>
            </div>
            <div className='dispatchTruckDetails' style={{ marginTop: 60 }}>
              <div>
                <h2>{name}</h2>
                <span>Route Date: {deliveryDate}</span>
              </div>
              {dropOffTable}
              {pickUpTable}
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { truckRoute, loading } = state.truckRoute;
  return { truckRoute, loading };
};

export default connect(mapStateToProps, actions)(PrintDispatchSummary);

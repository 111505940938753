import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { AddCircle, SubtractCircle } from 'Utils/SvgIcons';

const Additive = ({
                    index = null,
                    name,
                    value,
                    onChange,
                    disabled,
                    unitName,
                    withoutPadding,
                    resetInputValue,
                  }) => {
  const [inputValue, setInputValue] = useState(0);

  const decrease = () => {
    const newValue = inputValue - 1;
    setInputValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  const increase = () => {
    const newValue = inputValue + 1;
    setInputValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  const handleInputChange = (event) => {
    const newValue = parseInt(event.target.value) || 0;
    setInputValue(newValue);
    onChange({ target: { name, value: newValue } });
  };

  useEffect(() => {
    if (resetInputValue) {
      setInputValue(0);
      onChange({target: {name, value: 0}});
    }
  }, [resetInputValue]);

  return (
    <div
      key={index}
      className={classnames('input-container', {
        'without-padding': withoutPadding,
      })}
    >
      <div className='number-input-container'>
        <button
          className='additiveLeft'
          disabled={disabled}
          onClick={decrease}
        >
          <SubtractCircle fill='#8FA9BE' height={17} width={17} />
        </button>
        <input
          className='w100 noBorderRadius'
          name={name}
          type='number'
          min={0}
          value={inputValue}
          onChange={handleInputChange}
          disabled={disabled}
        />
        <button
          className='additiveRight'
          disabled={disabled}
          onClick={increase}
        >
          <AddCircle fill='#8FA9BE' height={17} width={17} />
        </button>
      </div>
      {unitName && <label>{unitName}</label>}
    </div>
  );
};

export default Additive;

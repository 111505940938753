import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { NavLink } from 'react-router-dom';
import ActionCable from 'actioncable';
import { actioncableCheck } from 'Actions';
import { getAuthToken } from 'Api';
import { Inbox } from '../utils/SvgIcons';

class MessagesTab extends React.Component {
  componentDidMount() {
    const { location, addUnreadMessage, fetchConversationList } = this.props;
    fetchConversationList();

    const authToken = getAuthToken();
    this.cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${authToken['access-token']}&client=${authToken['client']}&uid=${authToken['uid']}&resource_type=employee&lid=${location.id}`
    );
    this.channel = this.cable.subscriptions.create(
      { channel: 'UnreadMessagesChannel' },
      {
        connected: () => {},
        received: addUnreadMessage,
      }
    );
    actioncableCheck(this.cable);
  }

  componentWillUnmount() {
    this.channel.unsubscribe();
  }

  render() {
    const { unreadMessages } = this.props;
    return (
      <li className='convMenu offset2'>
        <NavLink
          id='nav_conversations'
          to='/conversations'
          activeClassName='selected'
        >
          <Inbox />
          {unreadMessages > 0 && (
            <span className='callout'>{unreadMessages}</span>
          )}
        </NavLink>
      </li>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors, loading } = state.auth;
  const { location } = state.location;
  const { unreadMessages } = state.conversationList;
  const { user } = state.user;

  return {
    errors,
    loading,
    location,
    user,
    unreadMessages,
  };
};

export default connect(mapStateToProps, actions, null, {
  pure: false,
})(MessagesTab);

import React, { useState } from 'react';
import { connect } from 'react-redux';
import { mockPageRequest } from '../../actions';
import AppState from '../../types/AppState';

const MockPage = (props) => {
  const { mockPageRequest } = props;
  const [httpStatus, setHttpStatus] = useState('200');
  const [contentType, setContentType] = useState('json');

  const submitRequest = () => {
    mockPageRequest({ httpStatus, contentType });
  };

  return (
    <>
      <h1>Mock Page</h1>
      <select
        className='contentTypeSelect'
        name='content_type'
        onChange={(e) => {
          setContentType(e.target.value);
        }}
        value={contentType}
      >
        <option disabled hidden>
          Content Type
        </option>
        <option value='json'>JSON</option>
        <option value='json_with_html_header'>JSON with content-type: text/html</option>
        <option value='html'>HTML</option>
      </select>
      <br />
      <input
        id='httpStatus'
        name='http_status'
        type='text'
        placeholder='HTTP Status code'
        value={httpStatus}
        onChange={(e) => setHttpStatus(e.target.value)}
      />
      <br />
      <button className='btn' onClick={submitRequest}>
        Submit Request
      </button>
    </>
  );
};

const mapStateToProps = (state: AppState) => {
  const { dataSet, loading } = state.routingSchedule;
  const { operationsFilter } = state.operation;

  return { dataSet, loading, operationsFilter };
};

export default connect(mapStateToProps, {
  mockPageRequest,
})(MockPage as any);

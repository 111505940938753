import * as actionTypes from 'Constants/redux';

function ReportsReducer(
  state = {
    loading: true,
    paymentsReceivedData: [],
    tapgoodsPaymentsData: [],
    powerbiReports: [],
    containingDate: new Date(),
    day: new Date(),
    period: 3,
    generalLedgerData: {
      totals: [],
      details: {},
      grandTotal: 0,
    },
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_POWERBI_REPORTS_REQUEST:
    case actionTypes.FETCH_PAYMENTS_RECEIVED_REPORT_REQUEST:
    case actionTypes.FETCH_TAPGOODS_PAYMENTS_REPORT_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_PAYMENTS_RECEIVED_REPORT_SUCCESS:
      return {
        ...state,
        paymentsReceivedData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_TAPGOODS_PAYMENTS_REPORT_SUCCESS:
      return {
        ...state,
        tapgoodsPaymentsData: action.response.data.data,
        loading: false,
      };
    case actionTypes.SELECT_DAY:
      return Object.assign({}, state, {
        day: action.payload.day,
      });
    case actionTypes.SELECT_CONTAINING_DATE:
      return Object.assign({}, state, {
        containingDate: action.payload.containingDate,
      });
    case actionTypes.SELECT_PERIOD:
      return Object.assign({}, state, {
        period: action.payload.period,
      });
    case actionTypes.FETCH_POWERBI_REPORTS_SUCCESS:
      const powerbiReports = action.payload.reports.map((report) => ({
        name: report.name,
        id: report.id,
      }));
      return { ...state, powerbiReports: powerbiReports };
    case actionTypes.FETCH_GENERAL_LEDGER_REPORT_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_GENERAL_LEDGER_REPORT_SUCCESS:
      const payload = { ...action.payload };

      return {
        ...state,
        generalLedgerData: {
          ...state.generalLedgerData,
          totals: [...payload.totals],
          details: JSON.parse(JSON.stringify(payload.details)),
          grandTotal: payload.grand_total,
        },
        loading: false,
      };
    case actionTypes.FETCH_GENERAL_LEDGER_REPORT_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
}

export default ReportsReducer;

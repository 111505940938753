import React from "react";
import { connect } from "react-redux";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import NewConversation from "./NewConversation";
import MobileConversations from "./MobileConversations";

const IndexConversations = props => {
  const { conversations } = props;
  return (
    <MediaBreakpoints
      desktop={<NewConversation />}
      mobile={
        <MobileConversations
          conversations={conversations}
          newConversationLink={`/conversations/new`}
        />
      }
    />
  );
};
const mapStateToProps = state => {
  const { conversations } = state.conversationList;
  return { conversations };
};
export default connect(mapStateToProps, null)(IndexConversations);

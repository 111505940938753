import {
  FETCH_CONVERSATION_SUCCESS,
  CREATE_CONVERSATION_SUCCESS,
  CREATE_MESSAGE_SUCCESS,
  ADD_MESSAGE
} from "Constants/redux";
import createReducer from "../lib/CreateReducer";

const INITIAL_STATE = {
  conversation: {
    id: 1,
    unreadMessages: 2,
    title: "Rental Update",
    messages: [],
    business: {
      name: "Simple Celebrations",
      picture: "test"
    }
  }
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CONVERSATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversation: action.payload.conversation
    };
  },
  [CREATE_CONVERSATION_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversation: action.payload.conversation
    };
  },
  [CREATE_MESSAGE_SUCCESS]: (state, action) => {
    return {
      ...state
    };
  },
  [ADD_MESSAGE]: (state, action) => {
    const newMessages = [action.payload].concat(state.conversation.messages);
    const newConversation = {
      ...state.conversation,
      messages: newMessages
    };
    return {
      ...state,
      conversation: newConversation
    };
  }
});

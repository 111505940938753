import service, { standardService } from 'Api/service';
import axios from 'axios';
import {
  getAuthToken,
  saveAuthToken,
  clearAuthToken,
  saveBusinessId,
  getLocationId,
  getBusinessId,
  saveLocationId,
  saveTgImposter,
  ROOT_URL,
  authToken,
  saveError,
  saveDeviceMemory,
  resetSessionLocationAndBusinessIds,
} from 'Api';
import { history } from 'Components/Routes';
import {
  setErrors,
  setErrorMessage,
  openSuccessSnackBar,
  setClosedValidationSnackBar,
} from './dashboardActionCreators';
import {
  fetchBusiness,
  showAlertIfQbAccountsNeedsAuth,
} from './BusinessActions';
import { fetchLocation } from './LocationActions';
import { fetchUser } from './UserActions';
import { fetchFaqs } from './FaqListActions';
import { openComplianceModal } from './ComplianceActions';
import { openSystemUpdateModal } from './SystemUpdateActions';
import { rememberIsValid } from '../../src/api';

import {
  FIELD_CHANGED,
  REVIEW_USER_SUCCESS,
  REVIEW_BUSINESS_SUCCESS,
  LOGIN_USER_SUCCESS,
  LOGIN_USER_FAIL,
  CREATE_USER_FAIL,
  LOGIN_USER,
  SIGN_OUT_USER_SUCCESS,
  SIGN_IN_ON_OTHER_DEVICE,
  SIGN_IN_ON_OTHER_TAB,
  CLEAR_SIGNED_IN_ON_OTHER_DEVICE,
  LISTENING_FOR_OTHER_SIGN_IN,
  SIGN_OUT_USER_FAIL,
  SIGN_OUT_USER,
  ADMIN_LOGIN_USER,
  VALIDATE_USER,
  VALIDATE_USER_SUCCESS,
  VALIDATE_USER_FAIL,
  SET_NEW_USER,
  SET_NEW_BUSINESS,
  SELECT_BUSINESS_SUBSCRIPTION,
  PASSWORD_VAIDATION_REQUEST,
  PASSWORD_VAIDATION_REQUEST_SUCCESS,
  PASSWORD_VAIDATION_REQUEST_FAILURE,
  RESET_TWO_FACTOR,
  SEND_SMS_REQUEST,
  SEND_SMS_REQUEST_SUCCESS,
  SEND_SMS_REQUEST_FAILURE,
  QB_ACCOUNTS_SYNC_FAILURE,
} from 'Constants/redux';

import ActionCable from 'actioncable';
import { getDashboardNavLinkPath } from '../components/utils/AtAGlanceLink';
import { subscribeToVersionChannel } from '../action_cable/subscribeToVersionChannel';
import { getActionCableConsumer } from '../helper_functions/getActionCableConsumer';

const REDIRECT_ROOT_URL = process.env.REACT_APP_FRONT_END_DOMAIN;

export const fieldChanged = ({ field, text }) => {
  return {
    type: FIELD_CHANGED,
    payload: { field, text },
  };
};

const reviewUserSuccess = (dispatch, data) => {
  dispatch({
    type: REVIEW_USER_SUCCESS,
    payload: data,
  });
};

export const selectBusinessSubscription = (data) => {
  return {
    type: SELECT_BUSINESS_SUBSCRIPTION,
    payload: data,
  };
};

export const reviewBusinessSuccess = (data) => {
  return {
    type: REVIEW_BUSINESS_SUCCESS,
    marketing: data.marketing,
    business: data.business,
  };
};
const dispatchNewSystemUpdateContent = (dispatch, newSystemUpdateContent) => {
  const {
    title,
    htmlContent,
    requireAcknowledge,
    showPinkTag,
    showScreenModal,
    lockScreen,
  } = newSystemUpdateContent;
  dispatch(
    openSystemUpdateModal({
      title,
      htmlContent,
      requireAcknowledge,
      showPinkTag,
      showScreenModal,
      lockScreen,
    })
  );
};
const loginUserSuccess = (dispatch, user) => {
  dispatch(fetchUser());
  const allLocations = user.locations.concat(user.inactiveLocations);
  if (getLocationId() === null && allLocations.length === 1) {
    saveLocationId(allLocations[0].id);
    saveBusinessId(allLocations[0].businessId);
  }
  if (user.newSystemUpdateContent) {
    dispatchNewSystemUpdateContent(dispatch, user.newSystemUpdateContent);
  }
  if (getLocationId() !== null) {
    dispatch(fetchLocation());
    dispatch(fetchBusiness());
    sendChurnZeroLoginEvent();
    initChurnZeroTracking(getBusinessId(), getLocationId(), user);

    if (user.shouldReactivate) {
      history.push('/reactivate');
    } else {
      dispatch(transferAfterLogin());
      if (user.newTermContent) {
        dispatch(openComplianceModal(user.newTermContent));
      }
    }
  } else {
    history.push({
      pathname: '/selectBusiness',
      state: history.location.state,
    });
  }
  dispatch(fetchFaqs());
  if (user.firstName && user.lastName && user.email) {
    window.zE(function() {
      window.zE.identify({
        name: `${user.firstName} ${user.lastName}`,
        email: user.email,
      });
    });
  }

  return dispatch({
    type: LOGIN_USER_SUCCESS,
    payload: user,
  });
  initInlineManualTracking(user);
};

export const selectLocation = (locationId, businessId) => {
  return (dispatch, getState) => {
    saveLocationId(locationId);
    saveBusinessId(businessId);
    dispatch(fetchLocation());
    dispatch(fetchBusiness());
    const user = getState().user.user;
    dispatch(transferAfterLogin());
    sendChurnZeroLoginEvent();
    initChurnZeroTracking(getBusinessId(), getLocationId(), user);
  };
};

export const transferAfterLogin = () => {
  return (dispatch, getState) => {
    const fromPath = history.location.state?.from;
    const { employeeLocationRelationship } = getState().user;

    if (fromPath && fromPath !== '/login') {
      history.push(history.location.state.from);
    } else {
      history.push(getDashboardNavLinkPath(employeeLocationRelationship));
    }
  };
};

const initInlineManualTracking = (user, getState) => {
  if (!window.inlineManualTracking) {
    window.inlineManualTracking = {
      uid: user.id,
      email: user.email,
      username: user.email,
      name: `${user.firstName} ${user.lastName}`,
      created: new Date(user.createdAt).getTime() / 1000,
      updated: new Date(user.updatedAt).getTime() / 1000,
      roles: [user.role],
    };
    const e = document.createElement('script'),
      t = document.getElementsByTagName('script')[0];
    e.async = 1;
    e.src =
      'https://cdn.inlinemanual.com/embed/player.6cb35cf5f0658572cdb27f54bb8b5565.js';
    e.charset = 'UTF-8';
    t.parentNode.insertBefore(e, t);
  }
};

const routesWhereChurnZeroShouldNotConnect = [
  '/tg-admin',
  '/login',
  '/signUp',
  '/start',
  '/forgotPassword',
  '/passwordReset',
];

const initChurnZeroTracking = (businessId, locationId, user) => {
  window.ChurnZero = window.ChurnZero || [];
  var cz = document.createElement('script');
  cz.type = 'text/javascript';
  cz.async = true;
  cz.src = 'https://analytics.churnzero.net/churnzero.js';
  var s = document.getElementsByTagName('script')[0];

  s.parentNode.insertBefore(cz, s);

  // This appends a style tag to the head of the document to hide
  // the ChurnZero tracking elements when printing
  const style = document.createElement('style');
  style.innerHTML = `
    @media print {
      #cz_success_center_container,
      #cz_transBG {
        display: none !important;
      }
    }
  `;
  document.head.appendChild(style);

  if (!window.Cypress)
    window.ChurnZero.push([
      'setAppKey',
      process.env.REACT_APP_CHURNZERO_APP_KEY,
    ]);
  if (
    businessId !== null &&
    locationId !== null &&
    user &&
    user.email &&
    !routesWhereChurnZeroShouldNotConnect.includes(window.location.pathname) &&
    !localStorage.getItem('adminAuthTokenHeaders') &&
    !window.Cypress
  ) {
    service
      .get(`${ROOT_URL}/employee_location_relationships/current`)
      .then((response) => {
        window.ChurnZero.push(['setContact', businessId, user.email]);
      })
      .catch((error) => console.log(error));
  }
};

const stopChurnZeroTrackingInstance = () => {
  window.ChurnZero = window.ChurnZero || [];
  window.ChurnZero.push(['trackEvent', 'Logout']);
  window.ChurnZero.push(['stop']);
};

const validateUserSuccess = (dispatch, user, getState) => {
  dispatch(fetchUser());
  if (getLocationId() === null && user.locations.length === 1) {
    saveLocationId(user.locations[0].id);
    saveBusinessId(user.locations[0].businessId);
  }
  if (getLocationId() !== null) {
    if (user.newTermContent) {
      dispatch(openComplianceModal(user.newTermContent));
    }
    dispatch(fetchLocation());
    dispatch(fetchBusiness());
  } else {
    history.push('/selectBusiness');
  }
  dispatch(fetchFaqs());
  dispatch({
    type: VALIDATE_USER_SUCCESS,
    payload: user,
  });
  initInlineManualTracking(user, getState);
  initChurnZeroTracking(getBusinessId(), getLocationId(), user);
  connectDatadogUser(getBusinessId(), getLocationId(), user);
  dispatch(showAlertIfQbAccountsNeedsAuth(getBusinessId(), getLocationId()));
};

const connectDatadogUser = (businessId, locationId, user) => {
  const datadog = window.DD_RUM;
  if (datadog) {
    datadog.onReady(function() {
      datadog.setUser({
        business_id: businessId,
        location_id: locationId,
        id: user.id,
        email: user.email,
        name: `${user.firstName} ${user.lastName}`,
      });
    });
  }
};

const signOutUserSuccess = () => {
  return (dispatch, getState) => {
    stopChurnZeroTrackingInstance();
    dispatch(stopListeningForOtherSignIn());
    dispatch({
      type: SIGN_OUT_USER_SUCCESS,
    });
  };
};
const stopListeningForOtherSignIn = () => {
  return (dispatch, getState) => {
    const { forcedSignOutCable } = getState().auth;
    if (forcedSignOutCable) {
      forcedSignOutCable.disconnect();
    }
  };
};

export const validateUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: VALIDATE_USER });
    const authTokenHead = authToken();
    if (authTokenHead) {
      standardService
        .get(`${ROOT_URL}/auth/validate_token`, {
          uid: authTokenHead['uid'],
          client: authTokenHead['client'],
          'access-token': authTokenHead['access-token'],
        })
        .then((response) => {
          validateUserSuccess(dispatch, response.data.data, getState);
          saveAuthToken(response.headers);
          listenForOtherSignIn(dispatch, authTokenHead['uid']);
          subscribeToVersionChannel();
        })
        .catch((error) => {
          saveError(error, getState());
          dispatch({ type: VALIDATE_USER_FAIL });
          history.push({
            pathname: '/login',
            state: {
              from: history.location.pathname,
            },
          });
        });
    } else {
      dispatch({ type: VALIDATE_USER_FAIL });
      history.push({
        pathname: '/login',
        state: {
          from: history.location.pathname,
        },
      });
    }
  };
};

export const loginUser = ({ email, password }) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_USER });

    standardService
      .post(`${ROOT_URL}/auth/sign_in`, {
        email,
        password,
        password_inquiry_only: !rememberIsValid(),
      })
      .then((response) => {
        const responseData = response.data.data;
        const isTwoFactorEnabled =
          responseData &&
          responseData.locations &&
          responseData.locations[0].isTwoFactorEnabled;

        if (isTwoFactorEnabled && !rememberIsValid()) {
          const isUserPhoneNumberExist =
            responseData &&
            responseData.phoneNumber &&
            !!responseData.phoneNumber.cell;

          dispatch({
            type: PASSWORD_VAIDATION_REQUEST_SUCCESS,
            isUserPhoneNumberExist: isUserPhoneNumberExist,
          });

          if (isUserPhoneNumberExist) {
            dispatch(hitSmsApi(email, responseData.phoneNumber.cell));
          }

          return;
        } else {
          saveAuthToken(response.headers);
          loginUserSuccess(dispatch, response.data.data);
          dispatch(setClosedValidationSnackBar());
          listenForOtherSignIn(dispatch, email);
          subscribeToVersionChannel();
        }
      })
      .catch((error) => {
        console.log(error);
        if (typeof error.request === 'object') {
          if (error.request.status === 401) {
            dispatch({
              type: PASSWORD_VAIDATION_REQUEST_FAILURE,
            });
          }

          if (error.response?.data?.errors) {
            dispatch(setErrors(error.response.data.errors));
          } else {
            dispatch(setErrors(['Error contacting API server']));
          }
        }
      });
  };
};

export const loginUserWithATwoFactor = ({
  email,
  password,
  phoneCode,
  shouldRememberDevice,
}) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_USER });

    standardService
      .post(`${ROOT_URL}/auth/sign_in`, {
        email,
        password,
        otp_attempt: phoneCode,
      })
      .then((response) => {
        const responseData = response.data.data;

        if (shouldRememberDevice) {
          saveDeviceMemory(new Date().getTime());
        }

        saveAuthToken(response.headers);
        loginUserSuccess(dispatch, responseData);
        dispatch(setClosedValidationSnackBar());
        listenForOtherSignIn(dispatch, email);
        subscribeToVersionChannel();
      })
      .catch((error) => {
        console.log(error);
        dispatch({
          type: LOGIN_USER_FAIL,
          payload: error.response.data.errors,
          isSourceFromTwoFactor: true,
        });
        dispatch(setErrors(error.response.data.errors));
      });
  };
};

export const dispatchSetErrors = (errors) => {
  return (dispatch) => {
    dispatch(setErrors(errors));
  };
};

export const actioncableCheck = (cable, count = 0) => {
  if (cable && cable.connection.disconnected && count < 3) {
    setTimeout(() => actioncableCheck(cable, (count += 1)), 3000);
  }

  if (cable.disconnected) {
    return cable.disconnect();
  } else {
    return true;
  }
};

const listenForOtherSignIn = (dispatch, email) => {
  const consumer = getActionCableConsumer();

  consumer.subscriptions.create(
    { channel: 'EmployeeSignedInChannel' },
    {
      connected: () => {},
      received: (data) => {
        if (data.email === email) {
          dispatch(logOutAfterOtherSignIn());
          clearAuthToken();
          localStorage.removeItem('numberPer');
          localStorage.removeItem('businessId');
          localStorage.removeItem('locationId');
          history.push({
            pathname: '/login',
          });
        }
      },
    }
  );
  actioncableCheck(consumer);
  dispatch({
    type: LISTENING_FOR_OTHER_SIGN_IN,
    actionCableConsumer: consumer,
  });
};

export const logOutAfterOtherSignIn = () => {
  return (dispatch, getState) => {
    dispatch(stopListeningForOtherSignIn());
    dispatch({ type: SIGN_IN_ON_OTHER_DEVICE });
  };
};
export const logOutAfterOtherTabSignIn = () => {
  return (dispatch, getState) => {
    dispatch(stopListeningForOtherSignIn());
    dispatch({ type: SIGN_IN_ON_OTHER_TAB });
  };
};

export const clearSignedInOnOtherDevice = () => {
  return {
    type: CLEAR_SIGNED_IN_ON_OTHER_DEVICE,
  };
};
export const adminUserLogin = (businessId, locationId, employeeId) => {
  return (dispatch, getState) => {
    dispatch({ type: ADMIN_LOGIN_USER });

    standardService
      .post(`${ROOT_URL}/admin/become`, { id: employeeId })
      .then((response) => {
        saveAuthToken(response.headers);
        saveBusinessId(businessId);
        saveLocationId(locationId);
        saveTgImposter();
        dispatch(fetchLocation());
        dispatch(fetchBusiness());
        loginUserSuccess(dispatch, response.data.data);
        dispatch(
          openSuccessSnackBar(
            'You are now successfully logged in as this employee.'
          )
        );
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: LOGIN_USER_FAIL,
          payload: error.response.data.errors,
        });
      });
  };
};

export const signOutUser = () => {
  return (dispatch, getState) => {
    const headers = getAuthToken();
    dispatch({ type: SIGN_OUT_USER });

    standardService
      .delete(`${ROOT_URL}/auth/sign_out`, {
        headers: headers,
      })
      .then((response) => {
        dispatch(signOutUserSuccess());
        clearAuthToken();
        resetSessionLocationAndBusinessIds();
        history.push('/');
      })
      .catch((error) => {
        saveError(error, getState());

        // If it was not possible to logout then delete auth tokens...
        clearAuthToken();

        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: SIGN_OUT_USER_FAIL,
          payload: error.response.data.errors,
        });
      });
  };
};

export const reviewNewUser = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_USER });

    standardService
      .post(`${ROOT_URL}/employees/new_review`, { employee: data })
      .then((response) => {
        reviewUserSuccess(dispatch, data);
        history.push('/start');
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data));
        dispatch({
          type: CREATE_USER_FAIL,
          payload: error.response.data,
        });
      });
  };
};

export const createUser = (data, onSuccess, onError) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_USER });

    standardService
      .post(`${ROOT_URL}/auth`, data)
      .then((response) => {
        saveAuthToken(response.headers);
        localStorage.removeItem('businessId');
        localStorage.removeItem('locationId');
        loginUserSuccess(dispatch, response.data.data.employee);
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data.errors) {
          dispatch(setErrors(error.response.data.errors));
        } else {
          dispatch(setErrors(error.response.data));
        }

        onError();
      });
  };
};

export const sendPasswordReset = ({ email }) => {
  return (dispatch, getState) => {
    standardService
      .post(`${ROOT_URL}/auth/password`, {
        email,
        redirect_url: `${REDIRECT_ROOT_URL}/passwordReset`,
      })
      .then((response) => {
        history.push('/login');
        dispatch(
          openSuccessSnackBar(
            'Instructions to reset your password have been sent. Please check your email.',
            0
          )
        );
      })
      .catch((err) => {
        saveError(err, getState());
        dispatch(setErrors(err.response.data.errors));
      });
  };
};

export const resetPassword = ({ password, passwordConfirmation, query }) => {
  return (dispatch, getState) => {
    const { uid, token, client_id } = query;
    axios
      .put(
        `${ROOT_URL}/auth/password`,
        {
          password,
          password_confirmation: passwordConfirmation,
        },
        {
          headers: {
            uid: uid,
            'access-token': token,
            client: client_id,
          },
        }
      )
      .then((response) => {
        history.push('/login');
        dispatch(
          openSuccessSnackBar('Your password has been successfully reset.', 0)
        );
      })
      .catch((err) => {
        saveError(err, getState());
        dispatch(setErrors(err.response.data.errors));
        const payload = {
          field: 'password',
          text: err.response.data.errors.password[0],
        };
        dispatch(setErrorMessage(payload));
      });
  };
};

export const setNewUser = (newUser) => {
  return {
    type: SET_NEW_USER,
    payload: newUser,
  };
};

export const setNewBusiness = (newBusiness) => {
  return {
    type: SET_NEW_BUSINESS,
    payload: newBusiness,
  };
};

export const sendChurnZeroLoginEvent = () => {
  service
    .post(`${ROOT_URL}/events_logger/send_churn_zero_event`, {
      churn_zero_event_name: 'Login Desktop',
    })
    .then((response) => console.log('ChurnZero login event sent'))
    .catch((error) => console.log(error));
};

export const resetTwoFactor = () => {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_TWO_FACTOR,
    });

    fieldChanged({ field: 'password', text: '' });
  };
};

export const hitSmsApi = (email, phone) => {
  return (dispatch, getState) => {
    // if ( !phone ) {
    //   dispatch(setErrors([ 'Please enter phone number...' ]));
    //   return;
    // }

    dispatch({
      type: SEND_SMS_REQUEST,
    });

    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/auth/two_factor_auth/send_sms',
        {
          phone,
          email,
        }
      )
      .then((response) => {
        dispatch({
          type: SEND_SMS_REQUEST_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch({
          type: SEND_SMS_REQUEST_FAILURE,
        });
        dispatch(setErrors([error.response.data.message]));
      });
  };
};

import {
  TOGGLE_AVAILABILITY_ALERT_MODAL,
  SET_AVAILABILITY_ALERT,
} from 'Constants/redux';

function AvailabilityAlertReducer(
  state = {
    modalOpen: false,
    availabilityAlert: {},
  },
  action
) {
  switch (action.type) {
    case TOGGLE_AVAILABILITY_ALERT_MODAL:
      return {
        ...state,
        modalOpen: !state.modalOpen,
      };
    case SET_AVAILABILITY_ALERT:
      return {
        ...state,
        availabilityAlert: action.alert,
      };
    default:
      return state;
  }
}

export default AvailabilityAlertReducer;

import React, { FC } from 'react';
import classNames from 'classnames';
import RegionDropdown from './RegionDropdown';
import PostalCodeInput from './PostalCodeInput';
import { LocationFieldsProps } from './LocationFields.types';

const LocationFields: FC<LocationFieldsProps> = ({
  addressable,
  errors,
  paymentProcessor,
  handleAddressChange,
  errorPrefix = '',
  disabled = false,
}) => {
  return (
    <>
      <select
        name='country'
        className={classNames({
          error: errors?.[`${errorPrefix}country`],
        })}
        value={addressable.country}
        onChange={handleAddressChange}
        disabled={disabled}
      >
        <option value=''>Select country</option>
        <option value='USA' selected={true}>
          United States
        </option>
        <option value='CAN'>Canada</option>
        {paymentProcessor == 'launchpay' && (
          <option value='INT'>International</option>
        )}
      </select>
      {addressable.country !== 'INT' && (
        <>
          <input
            name='streetAddress1'
            type='text'
            placeholder='Street Address 1'
            className={classNames({
              lg: true,
              error: errors?.[`${errorPrefix}street_address_1`],
            })}
            value={addressable.streetAddress1}
            onChange={handleAddressChange}
          />
          <input
            name='streetAddress2'
            type='text'
            placeholder='Suite #'
            className='sm'
            value={addressable.streetAddress2}
            onChange={handleAddressChange}
          />
          <input
            name='city'
            type='text'
            placeholder='City'
            className={classNames({
              med: true,
              error: errors?.[`${errorPrefix}city`],
            })}
            value={addressable.city}
            onChange={handleAddressChange}
          />
          <RegionDropdown
            name='locale'
            defaultOptionLabel={
              addressable.country === 'USA' ? 'State' : 'Province'
            }
            className={classNames({
              sm: true,
              error: errors?.[`${errorPrefix}postal_code`],
            })}
            disableWhenEmpty={true}
            countryValueType='short'
            labelType='short'
            valueType='short'
            country={addressable.country}
            value={addressable.locale}
            onChange={handleAddressChange}
          />
          <PostalCodeInput
            name='postalCode'
            type='text'
            placeholder='Postal Code'
            className={classNames({
              sm: true,
              error: errors?.[`${errorPrefix}postal_code`],
            })}
            country={addressable.country}
            value={addressable.postalCode}
            onChange={handleAddressChange}
          />
        </>
      )}
    </>
  );
};

export default LocationFields;

import React from "react";
import { Link } from "react-router-dom";
import { HelpCircle, TutorialArrow, MessageBubble, Team } from "Utils/SvgIcons";
import service from "Api/service";
import { saveError } from "Api";
import { connect } from "react-redux";
import * as actions from "Actions/dashboardActionCreators";
import ValidationPopUp from "Utils/ValidationPopUp";
import classnames from "classnames";

class HelpForm extends React.Component {
  constructor() {
    super();
    this.state = {
      submitted: false,
      contact: {
        name: "",
        company: "",
        email_address: "",
        phone_number: "",
        message: "",
        subject: ""
      },
      errors: {
        name: "",
        email_address: "",
        company: "",
        phone_number: "",
        message: ""
      }
    };
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.build_rails_object = this.build_rails_object.bind(this);
  }

  componentDidMount() {
    const { user } = this.props;
    const contact = Object.assign(this.state.contact, {
      name: user.firstName + " " + user.lastName,
      email_address: user.email
    });

    this.setState({
      contact: contact
    });
  }

  UNSAFE_componentWillReceiveProps() {
    const { user } = this.props;
    const contact = Object.assign(this.state.contact, {
      name: user.firstName + " " + user.lastName,
      email_address: user.email
    });

    this.setState({
      contact: contact
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const component = this;

    service
      .post(
        process.env.REACT_APP_API_DOMAIN + "/api/help_form",
        this.build_rails_object()
      )
      .then(response => {
        component.setState({
          submitted: true
        });
      })
      .catch(error => {
        const errors = error.response.data;
        const { setErrors } = this.props;

        saveError(error);
        setErrors(errors);
        component.setState({
          errors: errors
        });
      });
  }

  handleChange(event) {
    const target = event.target;
    const value = target.value;
    const name = target.name;
    const contact = Object.assign(this.state.contact, { [name]: value });
    this.setState({
      contact: contact
    });
  }

  build_rails_object() {
    return {
      contact: {
        name: this.state.contact.name,
        company: this.state.contact.company,
        email_address: this.state.contact.email_address,
        phone_number: this.state.contact.phone_number,
        message: this.state.contact.message,
        subject: this.state.contact.subject
      }
    };
  }

  render() {
    const { supportLevel } = this.props.business;
    return (
      <div className="contentWrapper">
        <header>
          <h1>Support</h1>
        </header>
        <div
          className="content medium support"
          className={classnames({
            content: true,
            narrow: supportLevel === 0 || supportLevel === 1,
            medium: supportLevel === 2,
            support: true,
            [`supportLevel${supportLevel}`]: true
          })}
        >
          <section>
            <div className="icon">
              <HelpCircle />
            </div>
            <h1>Knowledge Base</h1>
            <p>Use our self-service knowledgebase.</p>
            <p>
              If you can't find what you're looking for, use the link below to
              connect with us.
            </p>
            <a
              className="btn"
              href="https://tapgoods.zendesk.com/hc/en-us"
              target="_blank"
              rel="noopener noreferrer"
            >
              Get Support
            </a>
          </section>
          <section>
            <div className="icon">
              <TutorialArrow />
            </div>
            <h1>Interactive Tutorials</h1>
            <ul className="itemList">
              <li>
                <Link
                  to="/tutorials/addItem"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TutorialArrow />
                  Add an Item
                </Link>
              </li>
              <li>
                <Link
                  to="/tutorials/addCustomer"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TutorialArrow />
                  Add a Customer
                </Link>
              </li>
              <li>
                <Link
                  to="/tutorials/createRental"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <TutorialArrow />
                  Create a New Order
                </Link>
              </li>
            </ul>
          </section>
          {supportLevel > 1 && (
            <section>
              <div className="icon">
                <Team />
              </div>
              <h1>Contact Your Support Team</h1>
              <p>Didn't find answers to the questions you were looking for?</p>
              <p>
                Contact your TapGoods support team for more help.{" "}
                <strong>+1 (833) 567-4119</strong>
              </p>
              <a
                className="btn"
                href="https://tapgoods.zendesk.com/hc/en-us/requests/new"
                target="_blank"
              >
                Contact Your Support Team
              </a>
            </section>
          )}
          <a
            href="https://tapgoods.zendesk.com/hc/en-us/requests/new"
            target="_blank"
            rel="noopener noreferrer"
          >
            Didn't find what you were looking for? Contact Us.
          </a>
        </div>
        <ValidationPopUp message={this.state.errors} />
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { errorsOpen, errors } = state.dashboard;
  const { user } = state.auth;
  const { business } = state.business;
  return { errorsOpen, errors, user, business };
};

export default connect(mapStateToProps, actions)(HelpForm);

import { RESET_GEOFENCE_FILTER, MODIFY_GEOFENCE_FILTER } from "Constants/redux";

export const resetGeofenceFilter = () => {
  return {
    type: RESET_GEOFENCE_FILTER
  };
};
export const modifyGeofenceFilter = params => {
  return {
    type: MODIFY_GEOFENCE_FILTER,
    params
  };
};

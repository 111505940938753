import {
  SHOW_ITEM_DISCOUNTS_ALERT,
  HIDE_ITEM_DISCOUNTS_ALERT,
  SHOW_NONDISCOUNTABLES_ALERT,
  HIDE_NONDISCOUNTABLES_ALERT,
} from 'Constants/redux';

export const showItemDiscountsAlert = (nondiscountables) => ({
  type: SHOW_ITEM_DISCOUNTS_ALERT,
  nondiscountables,
});

export const hideItemDiscountsAlert = () => ({
  type: HIDE_ITEM_DISCOUNTS_ALERT,
});

export const showNondiscountablesAlert = (nondiscountables) => ({
  type: SHOW_NONDISCOUNTABLES_ALERT,
  nondiscountables,
});

export const hideNondiscountablesAlert = () => ({
  type: HIDE_NONDISCOUNTABLES_ALERT,
});

import { saveAuthToken, getAuthToken, saveError } from 'Api';
import axios from 'axios';
import { history } from 'Components/Routes';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import { setContactType } from './ContactActions';
import service from 'Api/service';

import {
  SET_VENUE_QUERY,
  REQUEST_VENUES,
  REQUEST_VENUE,
  REQUEST_INFINITE_VENUES,
  REQUEST_VENUE_NAMES,
  ADD_VENUE_NAMES_REQUEST,
  INFINITE_VENUES_SUCCESS,
  INFINITE_VENUES_FAILURE,
  RECEIVE_VENUES,
  RECEIVE_VENUE,
  RECEIVE_VENUE_NAMES,
  ADD_VENUE_NAMES_SUCCESS,
  ADD_INFINITE_VENUES_SUCCESS,
  UPDATE_VENUE_NAME_ON_LIST,
  SET_VENUE_FILTER,
  SET_VENUE_SELECTED_IDS_FOR_EXPORT,
} from 'Constants/redux';

export const setVenueQuery = (query) => {
  return {
    type: SET_VENUE_QUERY,
    payload: { query },
  };
};

function requestVenues() {
  return {
    type: REQUEST_VENUES,
  };
}
function requestVenue() {
  return {
    type: REQUEST_VENUE,
  };
}
const requestInfiniteVenues = () => {
  return {
    type: REQUEST_INFINITE_VENUES,
  };
};
const infiniteVenuesSuccess = (response) => {
  const venues = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: INFINITE_VENUES_SUCCESS,
    payload: { venues, pagination },
  };
};
const addInfiniteVenuesSuccess = (response) => {
  const venues = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_INFINITE_VENUES_SUCCESS,
    payload: { venues, pagination },
  };
};
const infiniteVenuesFailure = () => {
  return {
    type: INFINITE_VENUES_FAILURE,
  };
};

function receiveVenues(venues) {
  return {
    type: RECEIVE_VENUES,
    venues: venues,
    receivedAt: Date.now(),
  };
}
function receiveVenue(venue) {
  return {
    type: RECEIVE_VENUE,
    venue: venue,
    receivedAt: Date.now(),
  };
}
export const receiveVenueNames = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = '';
  if (names.length > 0) {
    firstId = names[0].token;
  }
  return {
    type: RECEIVE_VENUE_NAMES,
    payload: { names, pagination, firstId },
  };
};

function fetchVenues() {
  return (dispatch, getState) => {
    dispatch(requestVenues());

    dispatch(setContactType('venues'));

    return axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/venues/`, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(receiveVenues(response.data.venues));
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}

export const setVenueSelectedIdsForExport = (selectedIdsForExport) => ({
  type: SET_VENUE_SELECTED_IDS_FOR_EXPORT,
  selectedIdsForExport,
});

export const handleVenueSelectAll = (isChecked) => (dispatch, getState) => {
  if (isChecked) {
    const { names } = getState().venues;
    const newSelectedIdsForExport = names.reduce(
      (hash, venue) => ({ ...hash, [venue.id]: true }),
      {}
    );
    dispatch(setVenueSelectedIdsForExport(newSelectedIdsForExport));
  } else {
    dispatch(setVenueSelectedIdsForExport({}));
  }
};

export const handleVenueSelect = (id, isChecked) => (dispatch, getState) => {
  const newSelectedIdsForExport = {
    ...getState().venues.selectedIdsForExport,
  };
  if (isChecked) {
    newSelectedIdsForExport[id] = true;
  } else {
    delete newSelectedIdsForExport[id];
  }
  dispatch(setVenueSelectedIdsForExport(newSelectedIdsForExport));
};

export const setVenueFilter = (filter) => ({
  type: SET_VENUE_FILTER,
  filter,
});

export const setVenueFilterAndReload = (filter) => (dispatch, getState) => {
  dispatch(setVenueFilter(filter));
  dispatch(fetchVenueNames());
};

export const fetchVenueNames = () => {
  return (dispatch, getState) => {
    dispatch(setContactType('venues'));
    const { filter } = getState().venues;
    dispatch({
      type: REQUEST_VENUE_NAMES,
      filter,
    });
  };
};
export const addVenueNamesSuccess = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_VENUE_NAMES_SUCCESS,
    payload: { names, pagination },
  };
};

export const updateVenueNameOnList = (response) => {
  const { venue } = response.data;
  const { id, name } = venue;
  return {
    type: UPDATE_VENUE_NAME_ON_LIST,
    payload: { id, name },
  };
};

function fetchVenue(id) {
  return (dispatch, getState) => {
    dispatch(requestVenue());
    return axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/venues/` + id, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(receiveVenue(response.data.venue));
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}
function shouldFetchVenues(state) {
  const venues = state.venues;
  if (!venues) {
    return true;
  } else if (venues.isFetching) {
    return false;
  } else {
    return venues.shouldRefresh;
  }
}
function shouldFetchVenueNames(state) {
  const venues = state.venues;
  if (!venues) {
    return true;
  } else if (venues.fetchingNames) {
    return false;
  } else {
    return venues.shouldRefresh;
  }
}
function shouldFetchSingleVenue(state) {
  const venue = state.venue;
  if (!venue) {
    return true;
  } else if (venue.loading) {
    return false;
  } else {
    return venue.shouldRefresh;
  }
}

export const deleteVenue = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Deleting venue...'));
    return axios
      .delete(process.env.REACT_APP_API_DOMAIN + `/api/venues/` + id, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(fetchVenueNames());
        history.push(`/contacts/venues`);
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
      });
  };
};

const searchVenueNames = (query) => {
  return (dispatch, getState) => {
    dispatch(setVenueQuery(query));
    dispatch(fetchVenueNames());
  };
};

export const searchVenues = (query) => {
  return (dispatch, getState) => {
    dispatch(searchVenueNames(query));
  };
};
export const addVenueNames = (link) => {
  return (dispatch, getState) => {
    const { query } = getState().venues;
    dispatch({
      type: ADD_VENUE_NAMES_REQUEST,
      query,
      link,
    });
  };
};
export const fetchVenuesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchVenues(getState())) {
      return dispatch(fetchVenues());
    }
  };
};
export const fetchVenueNamesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchVenueNames(getState())) {
      return dispatch(fetchVenueNames());
    }
  };
};
export const fetchSingleVenueIfNeeded = (id) => {
  return (dispatch, getState) => {
    if (shouldFetchSingleVenue(getState())) {
      return dispatch(fetchVenue(id));
    }
  };
};

export const fetchInfiniteVenues = () => {
  return (dispatch, getState) => {
    const venues = getState().venues;
    const { query } = venues;
    const link = `/api/venues/names`;
    dispatch(requestInfiniteVenues());
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + link,
        { per: 25, query: query },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        saveAuthToken(response.headers);
        dispatch(infiniteVenuesSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteVenuesFailure());
        saveError(error);
      });
  };
};
export const addInfiniteVenues = (link) => {
  return (dispatch, getState) => {
    const venues = getState().venues;
    const { query } = venues;
    dispatch(requestInfiniteVenues());
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + link,
        { per: 25, query },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        saveAuthToken(response.headers);
        dispatch(addInfiniteVenuesSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteVenuesFailure());
        saveError(error);
      });
  };
};
export const searchInfiniteVenues = (query) => {
  return (dispatch, getState) => {
    dispatch(setVenueQuery(query));
    dispatch(fetchInfiniteVenues());
  };
};
export const resetVenues = () => {
  return (dispatch, getState) => {
    dispatch(setVenueQuery(''));
    dispatch(setContactType('venues'));
    dispatch(fetchInfiniteVenues());
  };
};

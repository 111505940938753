import React from "react";
import { Link } from "react-router-dom";
import Logo from "../../images/logos/logo_white.png";

const Footer = () => (
  <footer>
    <div className="logo">
      <img src={Logo} alt="TapGoods" />
    </div>
    <p>
      <a
        href="//www.tapgoods.com/pro/terms-conditions/"
        target="_blank"
        rel="noopener noreferrer external"
      >
        Terms & Conditions
      </a>
      <a
        href="//www.tapgoods.com/pro/privacy-policy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy Policy
      </a>
      <Link to="/contact">Contact Us</Link>
    </p>
    <p>© TapGoods</p>
  </footer>
);

export default Footer;

import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import converter from 'json-style-converter/es5';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import { setErrors, openSuccessSnackBar } from './dashboardActionCreators';
import {
  FETCH_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  FETCH_MAINTENANCE_RECORD_FAILURE,
  UPDATE_MAINTENANCE_RECORD_FAILURE,
  FETCH_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE,
  SET_MAINTENANCE_RECORD_PRIORITY,
  SET_MAINTENANCE_RECORD_NOTES,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_REQUEST_SUCCESS,
  CREATE_VENDOR_REQUEST_FAILURE,
  SHOULD_ADD_VENDOR,
  SHOULD_ADD_VENDOR_COMPLETE,
  SHOULD_CREATE_VENDOR,
  SHOULD_CREATE_VENDOR_COMPLETE,
  SELECTED_VENDOR_FOR_WORK_ORDER,
  SHOULD_SHOW_UPDATING_VIEW,
  SHOULD_SHOW_UPDATING_VIEW_COMPLETE,
  HOVER_DOCUMENT,
  UNHOVER_DOCUMENT,
  RETURN_TO_INVENTORY_BULK_REQUEST,
  RETURN_TO_INVENTORY_BULK_SUCCESS,
  RETURN_TO_INVENTORY_BULK_FAILURE,
  CREATE_WORK_ORDER_REQUEST,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAILURE,
  UPDATE_WORK_ORDER_REQUEST,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAILURE,
  RESET_SELECTED_VENDOR,
  REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST,
  REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS,
  REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE,
} from 'Constants/redux';

import { history } from 'Components/Routes';

export const fetchMaintenanceRecord = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_MAINTENANCE_RECORD_REQUEST });
    dispatch(openLoadingSpinner());
    service
      .get(`${ROOT_URL}/maintenance_records/${id}`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const data = converter.snakeToCamelCase(response.data);

        return dispatch({
          response: data,
          type: FETCH_MAINTENANCE_RECORD_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
        dispatch({
          error,
          type: FETCH_MAINTENANCE_RECORD_FAILURE,
        });
      });
  };
};

export const resetSelectedVendor = () => ({ type: RESET_SELECTED_VENDOR });

export const updateDocument = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST });
    dispatch(openLoadingSpinner());
    service
      .patch(`${ROOT_URL}/maintenance_records/${id}/update_documents`, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const data = converter.snakeToCamelCase(response.data);
        return dispatch({
          response: data,
          type: UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          errors,
          type: UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE,
        });
      });
  };
};

export const setPriority = (priority) => ({
  type: SET_MAINTENANCE_RECORD_PRIORITY,
  priority,
});
export const setNotes = (notes) => ({
  type: SET_MAINTENANCE_RECORD_NOTES,
  notes,
});

export const updateMaintenanceRecord = (data, onSuccess) => {
  return (dispatch, getState) => {
    const { token } = getState().maintenanceRecord.selectedMaintenanceRecord;

    dispatch({
      type: UPDATE_MAINTENANCE_RECORD_REQUEST,
      data: data,
    });
    dispatch(openLoadingSpinner('Updating...'));
    return service
      .patch(`${ROOT_URL}/maintenance_records/${token}`, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(openSuccessSnackBar('Successfully updated.'));

        history.push(`/inventory/maintenance/${token}`);
        const data = converter.snakeToCamelCase(response.data);
        dispatch(shouldShowUpdatingViewIntentComplete());
        return dispatch({
          response: data,
          type: UPDATE_MAINTENANCE_RECORD_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          errors,
          type: UPDATE_MAINTENANCE_RECORD_FAILURE,
        });
      });
  };
};

export const removeItemsFromInventory = () => {
  return (dispatch, getState) => {
    const { token } = getState().maintenanceRecord.selectedMaintenanceRecord;

    dispatch({
      type: REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST,
    });
    dispatch(openLoadingSpinner('Deleting...'));
    return service
      .post(
        `${ROOT_URL}/maintenance_records/${token}/remove_items_from_inventory`
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(openSuccessSnackBar('Successfully Deleted.'));
        const data = converter.snakeToCamelCase(response.data);
        return dispatch({
          response: data,
          type: REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          errors,
          type: REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE,
        });
      });
  };
};

export const createWorkOrder = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_WORK_ORDER_REQUEST,
    });

    dispatch(openLoadingSpinner('Creating...'));
    return service
      .post(`${ROOT_URL}/work_orders`, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const data = converter.snakeToCamelCase(response.data);
        dispatch({ type: CREATE_WORK_ORDER_SUCCESS, response: data });
        const {
          token,
        } = getState().maintenanceRecord.selectedMaintenanceRecord;
        history.push(`/inventory/maintenance/${token}`);
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          type: CREATE_WORK_ORDER_FAILURE,
          errors,
        });
      });
  };
};

export const updateWorkOrder = (data, workOrderId) => {
  return (dispatch, getState) => {
    dispatch({
      type: UPDATE_WORK_ORDER_REQUEST,
    });

    dispatch(openLoadingSpinner('Updating...'));
    return service
      .patch(`${ROOT_URL}/work_orders/${workOrderId}`, data)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        const data = converter.snakeToCamelCase(response.data);
        dispatch({ type: UPDATE_WORK_ORDER_SUCCESS, response: data });
        const {
          token,
        } = getState().maintenanceRecord.selectedMaintenanceRecord;
        history.push(`/inventory/maintenance/${token}`);
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          type: UPDATE_WORK_ORDER_FAILURE,
          errors,
        });
      });
  };
};

export const createVendor = (data) => {
  return (dispatch, getState) => {
    const { token } = getState().maintenanceRecord.selectedMaintenanceRecord;

    dispatch({
      type: CREATE_VENDOR_REQUEST,
    });

    dispatch(openLoadingSpinner('Updating...'));
    return service
      .post(`${ROOT_URL}/vendors`, data)
      .then(({ data }) => {
        dispatch(closeLoadingSpinner());
        dispatch(shouldCreateVendorIntentComplete());
        dispatch({
          type: CREATE_VENDOR_REQUEST_SUCCESS,
          response: data?.vendor,
        });
        dispatch({
          type: SELECTED_VENDOR_FOR_WORK_ORDER,
          response: data?.vendor,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        dispatch(shouldAddVendorIntentComplete());
        dispatch({
          type: CREATE_VENDOR_REQUEST_FAILURE,
        });
      });
  };
};

export const getVendorListForSlider = () => {
  return (dispatch, getState) => {
    const { token } = getState().maintenanceRecord.selectedMaintenanceRecord;

    dispatch({
      type: GET_VENDORS_LIST_FOR_SLIDE_DRAWER,
    });

    dispatch(openLoadingSpinner('loading...'));
    return service
      .get(`${ROOT_URL}/vendors`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS,
          response: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        dispatch({
          type: GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE,
        });
      });
  };
};

export const shouldAddVendorIntent = () => {
  return (dispatch) => {
    dispatch({
      type: SHOULD_ADD_VENDOR,
    });
  };
};

export const shouldAddVendorIntentComplete = () => {
  return (dispatch) => {
    dispatch({
      type: SHOULD_ADD_VENDOR_COMPLETE,
    });
  };
};

export const shouldShowUpdatingViewIntent = () => ({
  type: SHOULD_SHOW_UPDATING_VIEW,
});
export const shouldShowUpdatingViewIntentComplete = () => ({
  type: SHOULD_SHOW_UPDATING_VIEW_COMPLETE,
});

export const shouldCreateVendorIntent = () => {
  return (dispatch) => {
    dispatch({
      type: SHOULD_CREATE_VENDOR,
    });
  };
};

export const shouldCreateVendorIntentComplete = () => {
  return (dispatch) => {
    dispatch({
      type: SHOULD_CREATE_VENDOR_COMPLETE,
    });
  };
};

export const selectedVendorForWorkOrder = (vendor) => {
  return (dispatch, getState) => {
    dispatch({
      type: SELECTED_VENDOR_FOR_WORK_ORDER,
      response: vendor,
    });
  };
};

export const selectedOrToggledVendorForWorkOrder = (vendor) => {
  return (dispatch, getState) => {
    if (
      getState().maintenanceRecord.selectedMaintenanceRecord.vendor?.id ===
      vendor.id
    ) {
      return dispatch({
        type: SELECTED_VENDOR_FOR_WORK_ORDER,
      });
    }

    dispatch({
      type: SELECTED_VENDOR_FOR_WORK_ORDER,
      response: vendor,
    });
  };
};

export const mouseEnterForDocument = (index) => ({
  type: HOVER_DOCUMENT,
  index,
});
export const mouseLeaveForDocument = (index) => ({
  type: UNHOVER_DOCUMENT,
  index,
});

export const returnToInventoryBulk = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: RETURN_TO_INVENTORY_BULK_REQUEST,
    });
    dispatch(openLoadingSpinner('loading...'));
    return service
      .post(
        `${ROOT_URL}/serialized_maintenance_records/return_to_inventory_in_bulk`,
        data
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: RETURN_TO_INVENTORY_BULK_SUCCESS,
          response: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        dispatch({
          type: RETURN_TO_INVENTORY_BULK_FAILURE,
        });
      });
  };
};

export const returnToInventoryBulkForSerializedMultiple = (data) => {
  return (dispatch, getState) => {
    dispatch({
      type: RETURN_TO_INVENTORY_BULK_REQUEST,
    });
    dispatch(openLoadingSpinner('loading...'));
    return service
      .post(
        `${ROOT_URL}/serialized_maintenance_records/return_to_inventory_in_bulk_for_serialized_multiple`,
        data
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch({
          type: RETURN_TO_INVENTORY_BULK_SUCCESS,
          response: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        const errors = error.response.data;
        dispatch(setErrors(errors));
        dispatch({
          type: RETURN_TO_INVENTORY_BULK_FAILURE,
        });
      });
  };
};

import {
  DEBOUNCED_UPDATE_PRODUCT,
  FETCH_PRODUCT_SUCCESS
} from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";
import { ROOT_URL } from "Api";
import {
  apiConnections,
  apiToSingularConnections
} from "Constants/displayConstants";

const debouncedUpdateProductEpic = action$ =>
  action$
    .ofType(DEBOUNCED_UPDATE_PRODUCT)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.patch(
          `${ROOT_URL}/${apiConnections[action.productType]}/${action.id}`,
          {
            [apiToSingularConnections[action.productType]]: action.data
          }
        )
      ).map(response => {
        return {
          productType: apiToSingularConnections[action.productType],
          id: action.id,
          response,
          type: FETCH_PRODUCT_SUCCESS
        };
      });
    });

export default debouncedUpdateProductEpic;

import React from 'react';
import {
  RootContainer,
  SectionWrapper,
  StrongDivider,
  TopTitleContainer,
} from '../ParentOrderSummaryInvoice.styles';
import { useTheme } from 'styled-components';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import { InformationText } from '../../../forms/SideSection/common.styles';
import { PaymentTermsInvoiceSectionProps } from './PaymentTermsInvoiceSection.types';

const PaymentTermsInvoiceSection = ({
  paymentTerm,
}: PaymentTermsInvoiceSectionProps) => {
  const { blue } = useTheme() as StyledTheme;

  return (
    <SectionWrapper>
      <TopTitleContainer>
        <InformationText color={blue} bold>
          Payment Terms
        </InformationText>
        <StrongDivider backgroundColor={blue} />
      </TopTitleContainer>
      <InformationText>{paymentTerm}</InformationText>
    </SectionWrapper>
  );
};

export default PaymentTermsInvoiceSection;

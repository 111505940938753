import React from 'react';
import {
  CompanyRentalRelationship,
  CustomerRentalRelationship,
} from '../../../../types/graphql';
import {
  ContactInfoSectionProps,
  ContactInformationArgs,
} from './ContactInfoInvoiceSection.types';
import {
  ContactContainer,
  CompanyAndCustomerContainer,
  ContactInfoContentContainer,
} from './ContactInfoInvoiceSection.styles';
import { useTheme } from 'styled-components';
import { isPrimaryContact } from './utils/isPrimaryContact';
import {
  SectionWrapper,
  StrongDivider,
  TopTitleContainer,
} from '../ParentOrderSummaryInvoice.styles';
import { handleContactAddress } from './utils/handleContactAddress';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import { InformationText } from '../../../forms/SideSection/common.styles';
import { extractCompanyInformations } from './utils/extractCompanyInformations';
import { extractCustomerInformations } from './utils/extractCustomerInformations';

const ContactInfoSection = ({ parentOrder }: ContactInfoSectionProps) => {
  const { darkblue, blue } = useTheme() as StyledTheme;

  const renderContactInformation = ({
    contact,
    isCompany,
  }: ContactInformationArgs) => {
    const contactData = isCompany
      ? extractCompanyInformations(contact as CompanyRentalRelationship)
      : extractCustomerInformations(contact as CustomerRentalRelationship);

    if (!contactData) return null;

    const { name, phone, physicalAddress, taxExemptionNumber } = contactData;

    const titleLabel = isCompany ? 'Company' : 'Customer';

    const primaryText = isPrimaryContact(contact) && (
      <InformationText color={blue} marginBottom={8}>
        Primary
      </InformationText>
    );

    const taxExemptText = taxExemptionNumber && (
      <InformationText>Tax Exempt: {taxExemptionNumber}</InformationText>
    );

    return (
      <ContactInfoContentContainer key={contact.id}>
        <ContactContainer>
          <InformationText bold color={blue}>
            {titleLabel}
          </InformationText>
          {primaryText}
          {taxExemptText}
          <InformationText>{name}</InformationText>
          <InformationText>{phone}</InformationText>
          <InformationText>
            {physicalAddress?.streetAddress1} {physicalAddress?.streetAddress2}
          </InformationText>
          <InformationText>
            {handleContactAddress(physicalAddress!)}
          </InformationText>
        </ContactContainer>
      </ContactInfoContentContainer>
    );
  };

  return (
    <SectionWrapper>
      <TopTitleContainer>
        <InformationText bold>Contact Info</InformationText>
        <StrongDivider backgroundColor={darkblue} />
      </TopTitleContainer>
      <CompanyAndCustomerContainer>
        {renderContactInformation({
          contact: parentOrder.companyRentalRelationship!,
          isCompany: true,
        })}
        {parentOrder.customerRentalRelationships?.map(
          (customerRentalRelationship) =>
            renderContactInformation({
              contact: customerRentalRelationship,
              isCompany: false,
            })
        )}
      </CompanyAndCustomerContainer>
    </SectionWrapper>
  );
};

export default ContactInfoSection;

import React from 'react';
import { dateToFormattedUTCString } from 'HelperFunctions/DateHelpers';

export const mergeRentalSignatures = (
  rental,
  customerSignature,
  businessSignature
) => {
  // Rental Signatures
  rental.signature = customerSignature.signature || rental.signature;
  rental.signerName = customerSignature.signerName || rental.signerName;
  rental.signerTitle = customerSignature.signerTitle || rental.signerTitle;
  rental.signerCompany =
    customerSignature.signerCompany || rental.signerCompany;

  rental.businessSignature =
    businessSignature.signature || rental.businessSignature;
  rental.businessSignerName =
    businessSignature.signerName || rental.businessSignerName;

  // Pick-List signatures Check-Out
  rental.checkOutSignature =
    customerSignature.signature || rental.checkOutSignature;
  rental.checkOutSignerName =
    customerSignature.signerName || rental.checkOutSignerName;
  rental.checkOutSignerTitle =
    customerSignature.signerTitle || rental.checkOutSignerTitle;
  rental.checkOutSignerCompany =
    customerSignature.signerCompany || rental.checkOutSignerCompany;

  rental.businessCheckOutSignature =
    businessSignature.signature || rental.businessCheckOutSignature;
  rental.businessCheckOutSignerName =
    businessSignature.signerName || rental.businessCheckOutSignerName;
  rental.businessCheckOutSignerTitle =
    businessSignature.signerTitle || rental.businessCheckOutSignerTitle;
  rental.businessCheckOutSignerCompany =
    businessSignature.signerCompany || rental.businessCheckOutSignerCompany;

  // Pick-List signatures Check-In
  rental.checkInSignature =
    customerSignature.signature || rental.checkInSignature;
  rental.checkInSignerName =
    customerSignature.signerName || rental.checkInSignerName;
  rental.checkInSignerTitle =
    customerSignature.signerTitle || rental.checkInSignerTitle;
  rental.checkInSignerCompany =
    customerSignature.signerCompany || rental.checkInSignerCompany;

  rental.businessCheckInSignature =
    businessSignature.signature || rental.businessCheckInSignature;
  rental.businessCheckInSignerName =
    businessSignature.signerName || rental.businessCheckInSignerName;
  rental.businessCheckInSignerTitle =
    businessSignature.signerTitle || rental.businessCheckInSignerTitle;
  rental.businessCheckInSignerCompany =
    businessSignature.signerCompany || rental.businessCheckInSignerCompany;

  return rental;
};

export const mergeRentalPickListSignatures = (
  rental,
  customerCheckInSignature,
  customerCheckOutSignature
) => {
  // Pick-List signatures Check-Out
  rental.checkOutSignature =
    customerCheckOutSignature.signature || rental.checkOutSignature;
  rental.checkOutSignerName =
    customerCheckOutSignature.signerName || rental.checkOutSignerName;
  rental.checkOutSignerTitle =
    customerCheckOutSignature.signerTitle || rental.checkOutSignerTitle;
  rental.checkOutSignerCompany =
    customerCheckOutSignature.signerCompany || rental.checkOutSignerCompany;

  // Pick-List signatures Check-In
  rental.checkInSignature =
    customerCheckInSignature.signature || rental.checkInSignature;
  rental.checkInSignerName =
    customerCheckInSignature.signerName || rental.checkInSignerName;
  rental.checkInSignerTitle =
    customerCheckInSignature.signerTitle || rental.checkInSignerTitle;
  rental.checkInSignerCompany =
    customerCheckInSignature.signerCompany || rental.checkInSignerCompany;
  return rental;
};

export const getSignatureFromRental = (rental, signatureType) => {
  if (!rental) {
    return {};
  }
  const rentalSignature = rental.rentalSignatures?.find((signature) => {
    return signature.signatureType === signatureType;
  });
  return rentalSignature || {};
};

export const signatureLabel = (
  rental,
  signatureType,
  signatureTitle,
  signatureDatetime
) => {
  const labelDict = {
    customerCheckIn:
      (rental.checkInSignerName &&
        rental.checkInSignerTitle &&
        `${rental.checkInSignerName} - ${rental.checkInSignerTitle}, ${rental.checkInSignerCompany}`) ||
      '',
    customerCheckOut:
      (rental.checkOutSignerName &&
        rental.checkOutSignerTitle &&
        `${rental.checkOutSignerName} - ${rental.checkOutSignerTitle}, ${rental.checkOutSignerCompany}`) ||
      '',
    businessCheckIn:
      (rental.businessCheckInSignerName &&
        rental.businessCheckInSignerTitle &&
        `${rental.businessCheckInSignerName} - ${rental.businessCheckInSignerTitle}, ${rental.businessCheckInSignerCompany}`) ||
      '',
    businessCheckOut:
      (rental.businessCheckOutSignerName &&
        rental.signerTitle &&
        `${rental.businessCheckOutSignerName} - ${rental.businessCheckOutSignerTitle}, ${rental.businessCheckOutSignerCompany}`) ||
      '',
  };

  return (
    <label className='signatureHeader'>
      <div>
        {signatureTitle} Signature: {labelDict[signatureType]}
      </div>
      <div className='signatureDatetime'>
        {dateToFormattedUTCString(signatureDatetime)}
      </div>
    </label>
  );
};

import service from '../api/service';
import { saveError } from '../api';
import {
  COMBINED_INVENTORY_RESET,
  COMBINED_INVENTORY_SEARCH,
  COMBINED_INVENTORY_REQUEST,
  COMBINED_INVENTORY_SUCCESS,
  COMBINED_INVENTORY_FAILURE,
  COMBINED_INVENTORY_SET_QUERY,
  COMBINED_INVENTORY_SET_FILTER,
} from '../constants/redux';
import { fetchInventoryAvailability } from './AvailabilityActions';
import { availabilityRequestBodyFromCombinedInventory } from '../helper_functions/availability';
import AppState from '../types/AppState';
import { ThunkAction } from 'redux-thunk';
import { RentalFormActionTypes } from './RentalFormActions/types';
import selectEventDatesForInventoryRequest from '../helper_functions/rentalForm/eventDatesForInventoryRequest';

const PAGINATE_ROOT_URL = process.env.REACT_APP_API_DOMAIN;

export const resetInventory = (
  { shouldResetFilter = true } = { shouldResetFilter: Boolean }
) => {
  return {
    type: COMBINED_INVENTORY_RESET,
    payload: { shouldResetFilter },
  };
};
const setQuery = (query) => {
  return {
    type: COMBINED_INVENTORY_SET_QUERY,
    payload: query,
  };
};

const setFilter = (filter) => {
  return {
    type: COMBINED_INVENTORY_SET_FILTER,
    payload: filter,
  };
};

const reloadInventoryCall = (combinedInventory, argums) => {
  return (dispatch, getState) => {
    const data = Object.assign(combinedInventory, argums);
    const { productType, filter, query } = data;
    const { startDate, endDate } = selectEventDatesForInventoryRequest();

    const options = Object.assign({
      filter,
      productType,
      query,
      startDate,
      endDate,
    });
    const link = '/api/inventories/paginated';
    dispatch(infiniteLoadInventoryCallInventories(options, link, true));
  };
};

/*
 * The action dispatched by this method is observed by an availability epic.
 * In order to trigger the epic, the action get start and end dates as arguments
 */
export const searchInventoryCombined = ({
  query,
  query_type,
  startDate,
  endDate,
  excludeBundle,
  productType,
}) => {
  return (dispatch, getState) => {
    dispatch(setQuery(query));
    const combinedInventory = getState().combinedInventory;
    const { productType: productTypeFromStore, filter } = combinedInventory;

    const link = '/api/inventories/paginated';
    dispatch({
      type: COMBINED_INVENTORY_SEARCH,
      link: link,
      productType: productType || 'all',
      options: {
        filter,
        product_type: productType || productTypeFromStore || 'all',
        exclude_bundle: excludeBundle,
        per: 15,
        start_date: startDate,
        end_date: endDate,
        query,
        query_type,
      },
    });
  };
};

export function infiniteLoadInventoryCallInventories(
  options,
  link,
  reset = false
) {
  return (dispatch, getState) => {
    dispatch({ type: COMBINED_INVENTORY_REQUEST });
    service
      .post(`${PAGINATE_ROOT_URL}${link}`, {
        product_type: options.productType,
        exclude_bundle: options.excludeBundle,
        filter: options.filter,
        start_date: options.startDate,
        end_date: options.endDate,
        per: 15,
        query: options.query,
        query_type: options.query_type || 'all',
      })
      .then((response) => {
        dispatch({
          productType: options.productType,
          response,
          reset,
          type: COMBINED_INVENTORY_SUCCESS,
        });

        const { startDate, endDate, rentalId } = options;

        if (!startDate || !endDate) {
          return;
        }

        const serializedInventory = response.data.inventories.filter(
          (inventory) => inventory.isSerialized
        );
        const notSerializedInventory = response.data.inventories.filter(
          (inventory) => !serializedInventory.includes(inventory)
        );
        const availabilityBodySerialized = availabilityRequestBodyFromCombinedInventory(
          serializedInventory
        );
        const availabilityBodyNotSerialized = availabilityRequestBodyFromCombinedInventory(
          notSerializedInventory
        );

        // Fetch availability if date interval is provided.
        // At the future, a flag could be set to fetch it or not, to allow more flexibility.
        dispatch(
          fetchInventoryAvailability({
            startDate,
            endDate,
            ...availabilityBodySerialized,
            rentalId,
            rentalStatuses: ['in_use', 'reservation'],
          })
        );

        dispatch(
          fetchInventoryAvailability({
            startDate,
            endDate,
            ...availabilityBodyNotSerialized,
            rentalId,
          })
        );
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType: options.productType,
          error,
          type: COMBINED_INVENTORY_FAILURE,
        });
      });
  };
}

export const infiniteLoadInventoryCombined = ({
  link,
  productType,
  excludeBundle,
  startDate,
  endDate,
  query,
  query_type = 'all',
  rentalId,
}) => {
  return (dispatch, getState) => {
    const combinedInventory = getState().combinedInventory;
    const { filter } = combinedInventory;
    const options = Object.assign({
      filter,
      productType,
      excludeBundle,
      startDate,
      endDate,
      query,
      query_type,
      rentalId,
    });
    dispatch(infiniteLoadInventoryCallInventories(options, link));
  };
};

// Leaving the interface here for now since it's used across many files
// TODO: Move this and function declaration to a separate file
interface ResetInfiniteAndLoadInventoriesParams {
  productType: string;
  startDate: Date;
  endDate: Date;
  excludeBundle?: boolean;
  rentalId: number;
}

export const resetInfiniteAndLoadInventories = ({
  productType,
  startDate,
  endDate,
  excludeBundle,
  rentalId,
}: ResetInfiniteAndLoadInventoriesParams): ThunkAction<
  void,
  AppState,
  unknown,
  RentalFormActionTypes
> => {
  return (dispatch, getState) => {
    dispatch(resetInventory());
    const link = '/api/inventories/paginated';
    const combinedInventory = getState().combinedInventory;
    const { filter } = combinedInventory;
    const options = Object.assign({
      productType,
      filter,
      startDate,
      endDate,
      excludeBundle,
      rentalId,
    });
    dispatch(infiniteLoadInventoryCallInventories(options, link));
  };
};

export const combinedInventoryFilterAndReload = (filter) => {
  return (dispatch, getState) => {
    dispatch(setFilter(filter));
    const combinedInventory = getState().combinedInventory;
    const { productType, query } = combinedInventory;
    const argums = { productType, filter, query };
    dispatch(reloadInventoryCall(combinedInventory, argums));
  };
};

import React from 'react';
import {
  Hexagon,
  MapMarker,
  MultiMapMarker,
  NumberedDownArrowPin,
  NumberedStopIcon,
  NumberedUpArrowPin,
  ArrowUpMarker,
  ArrowDownMarker,
  MapPickup,
  MapDropoff,
  TruckDropOff,
  StopService,
} from 'Utils/SvgIcons';
import classnames from 'classnames';

const Marker = (props) => {
  let {
    type,
    overlapped,
    transportType,
    truck,
    text,
    color,
    stopId,
    id,
    rentalTransport,
    clickHandler
  } = props;
  truck = Object(truck);

  switch (type) {
    case 'unassigned':
      if (overlapped) {
        return (
          <div
            id={id}
            data-for={`${stopId}-MarkerToolTip`}
            data-tip
            data-event='click focus'
            className={classnames({
              marker: true,
              overlapped: overlapped,
              [type]: type,
              unassignedMarker: true,
            })}
            onClick={() => clickHandler(rentalTransport)}
          >
            {transportType == 'drop_off' && (
              <MapDropoff color={color} />
            )}
            {transportType == 'pick_up' && (
              <MapPickup color={color} />
            )}
          </div>
        );
      }
      return (
        <div
          id={id}
          data-for={`${stopId}-MarkerToolTip`}
          data-tip
          data-event='click focus'
          className={classnames({
            marker: true,
            overlapped: overlapped,
            [type]: type,
            unassignedMarker: true,
          })}
          onClick={() => clickHandler(rentalTransport)}
        >
          {rentalTransport?.serviceTicketId ||
          rentalTransport?.rentalStaffId ? (
            <StopService markerColor={'#FF886D'} />
          ) : transportType == 'drop_off' ? (
            <ArrowDownMarker color={'#FF886D'} />
          ) : (
            <ArrowUpMarker color={'#FF886D'} />
          )}
        </div>
      );
    case 'assigned':
      if (overlapped) {
        return (
          <div
            id={id}
            data-for={`${stopId}-MarkerToolTip`}
            data-tip
            data-event='click focus'
            className={classnames({
              marker: true,
              overlapped: overlapped,
              [type]: type,
              unassignedMarker: true,
            })}
            onClick={() => clickHandler(rentalTransport)}
          >
            {transportType == 'custom' && (
              <NumberedStopIcon color={color} />
            )}
            {transportType == 'drop_off' && (
              <MapDropoff color={color} />
            )}
            {transportType == 'pick_up' && (
              <MapPickup color={color} />
            )}
          </div>
        );
      }
      return (
        <div
          id={id}
          data-for={`${stopId}-MarkerToolTip`}
          data-tip
          data-event='click focus'
          className={classnames({
            marker: true,
            overlapped: overlapped,
            [type]: type,
            unassignedMarker: true,
          })}
          onClick={() => clickHandler(rentalTransport)}
        >
          {(rentalTransport?.serviceTicketId ||
          rentalTransport?.rentalStaffId) && (<StopService markerColor={truck?.color} />)}
          {rentalTransport?.transportType == 'drop_off' && (<ArrowDownMarker color={truck?.color} />)}
          {rentalTransport?.transportType == 'pick_up' && (<ArrowUpMarker color={truck?.color} />)}
          {rentalTransport?.transportType == 'custom' && (<NumberedStopIcon color={truck?.color} />)}
        </div>
      );
    case 'stop':
      return (
        <div
          id={stopId}
          className={classnames({
            marker: true,
            overlapped: overlapped,
            [type]: type,
            stop: true,
          })}
          onClick={() => clickHandler(rentalTransport)}
        >
          <NumberedStopIcon number={text} color={truck && truck.color}/>
        </div>
      );
    case 'route':
      return (
        <div
          id={stopId}
          className={classnames({
            marker: true,
            overlapped: overlapped,
            [type]: type,
            stop: true,
          })}
          onClick={props.onClickHandler}

        >
          {rentalTransport?.serviceTicketId ||
          rentalTransport?.rentalStaffId ? (
            <StopService number={text} markerColor={color} />
          ) : transportType == 'drop_off' ? (
            <NumberedDownArrowPin number={text} color={truck.color} />
          ) : (
            <NumberedUpArrowPin number={text} color={truck.color} />
          )}
        </div>
      );
    case 'truck':
      return (
        <div
          id={id}
          className={classnames({
            marker: true,
            overlapped: overlapped,
            [type]: type,
            unassignedMarker: true,
          })}
        >
          {rentalTransport?.serviceTicketId ||
          rentalTransport?.rentalStaffId ? (
            <StopService markerColor={color} />
          ) : (
            <TruckDropOff color={color} />
          )}
        </div>
      );
    default:
      return (
        <div
          id={id}
          className={classnames({
            marker: true,
            overlapped: props.overlapped,
            [props.type]: props.type,
          })}
        >
          {props.type === 'stop' ? (
            <Hexagon />
          ) : props.overlapped ? (
            <MultiMapMarker />
          ) : (
            <MapMarker color={props.color}/>
          )}
          {props.text}
        </div>
      );
  }
};

export default Marker;

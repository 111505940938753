import React from 'react';
import { FormattedDate, FormattedNumber } from 'react-intl';
import { Link } from 'react-router-dom';
import { renderAlert, renderSubRentalType } from 'HelperFunctions/rentals';
import RentalName from './RentalName';
const ConnectHoldInventoryTableRow = (props) => {
  const {
    rentalInventory,
    link,
    location,
    multilocationAllLocations,
    onClick,
  } = props;
  const { sub_rental } = rentalInventory;

  const startDate = rentalInventory?.rental?.startDate
    ? rentalInventory.rental.startDate
    : rentalInventory?.rental_schedule?.eventStartDate;
  const endDate = rentalInventory?.rental?.startDate
    ? rentalInventory.rental.startDate
    : rentalInventory?.rental_schedule?.eventStartDate;

  return (
    <tbody>
      <Link className='rTableRow' to={link} onClick={onClick}>
        {multilocationAllLocations && (
          <td className='bold valignTop'>{location?.locationCode}</td>
        )}
        <td className='lg'>
          <p>
            <strong>{rentalInventory.name || 'Untitled'}</strong>
          </p>
          <p>{rentalInventory?.rental?.name || rentalInventory?.rental_name}</p>
        </td>
        <td className='middle'>
          {startDate && (
            <FormattedDate value={startDate} day='2-digit' month='2-digit' />
          )}
          {' - '}
          {endDate && (
            <FormattedDate
              value={endDate}
              day='2-digit'
              month='2-digit'
              year='2-digit'
            />
          )}
        </td>
        <td className='middle no-wrap'>{rentalInventory?.sub_rental?.supplier}</td>
        <td className='middle'>{rentalInventory?.sub_rental?.customer_name}</td>
        <td className='middle'>{rentalInventory?.sub_rental?.contact}</td>
        <td className='middle'>
          {rentalInventory?.sub_rental?.expense > 0 && (
            <FormattedNumber
              value={rentalInventory.sub_rental.expense}
              style='currency'
              currency='USD'
            />
          )}
        </td>
        <td rowSpan='2' className='alert'>
          {renderSubRentalType(rentalInventory?.sub_rental?.source)}
        </td>
      </Link>
    </tbody>
  );
};

export default ConnectHoldInventoryTableRow;

import {
  CREDIT_CARD_FIELD_CHANGED,
  CREDIT_CARD_CLEAR_FIELDS
} from "Constants/redux";

const INITIAL_STATE = {
  nickname: "",
  name: "",
  number: "",
  cvc: "",
  paymentMethodType: "Visa",
  expMonth: "select_month",
  expYear: "select_year",
  streetAddress1: "",
  streetAddress2: "",
  city: "",
  locale: "",
  postalCode: "",
  country: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case CREDIT_CARD_FIELD_CHANGED:
      const { field, value } = action.payload;
      return { ...state, [field]: value };
    case CREDIT_CARD_CLEAR_FIELDS:
      return INITIAL_STATE;
    default:
      return state;
  }
};

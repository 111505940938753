import React from "react";
import Imgix from "react-imgix";
import * as actions from "Actions";
import {connect} from "react-redux";
import { formatPhoneNumber } from "HelperFunctions/general";
import {
  Company
} from "Utils/SvgIcons";
import {withRouter} from "react-router-dom";

class PrintLocation extends React.Component {
  render() {
    const { location } = this.props;
    const {
      phoneNumber,
      name,
      picture,
      physicalAddress
    } = location;

    const {
      cell,
      office
    } = phoneNumber;


    return (

      <div className="locationDetailsArea">

        <figure >
          {!picture ? (
            <Company />
          ) : (
            <Imgix
              src={picture.imgixUrl}
              alt={name}
              width={231}
              height={129}
            />
          )}
        </figure>

        <div className="addressDetails">
          <p><strong>{name}</strong></p>

          {phoneNumber && (
            <p>{formatPhoneNumber(office ? office : cell )}</p>
          )}

          {physicalAddress && (
            <span>
                {physicalAddress.streetAddress1}
              {physicalAddress.streetAddress2 &&
              `#${physicalAddress.streetAddress2}`}
              <br />
              {physicalAddress.city},{" "}
              {physicalAddress.locale}{" "}
              {physicalAddress.postalCode},{" "}
              {physicalAddress.country}
              </span>
          )}

        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { location } = state.location;
  return { location };
};

export default withRouter(connect(mapStateToProps, actions)(PrintLocation));



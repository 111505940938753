import {
  PAGINATE_RENTAL_LIST_REQUEST,
  PAGINATE_RENTAL_LIST_SUCCESS
} from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";

const paginateRentalListEpic = action$ =>
  action$
    .ofType(PAGINATE_RENTAL_LIST_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      const {
        link,
        numberPer,
        status,
        startDate,
        endDate,
        query,
        billingStatus,
        salesperson,
        rentalStatus,
        pickedStatus,
        checkedInStatus
      } = action.params;
      return Rx.Observable.fromPromise(
        service.get(process.env.REACT_APP_API_DOMAIN + link, {
          per: numberPer,
          filter: status,
          start_date: startDate,
          end_date: endDate,
          query,
          billing_status: billingStatus,
          salesperson: salesperson,
          rental_status: rentalStatus,
          picked_status: pickedStatus,
          checked_in_status: checkedInStatus,
          show_all: false
        })
      ).map(response => {
        return {
          type: PAGINATE_RENTAL_LIST_SUCCESS,
          payload: {
            rentals: response.data.rentals,
            pagination: response.data.meta.pagination
          }
        };
      });
    });

export default paginateRentalListEpic;

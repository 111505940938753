import React from "react";

class YearSelect extends React.Component {
  render() {
    const { name, value, onChange, startYear, endYear, ...other } = this.props;
    let years = [];
    for (let i = startYear; i <= endYear; i++) {
      years.push(
        <option key={i} value={i}>
          {i}
        </option>
      );
    }

    return (
      <select {...other} name={name} value={value} onChange={onChange}>
        <option value="select_year">Select Year</option>
        {years}
      </select>
    );
  }
}
YearSelect.defaultProps = {
  startYear: new Date().getFullYear(),
  endYear: new Date().getFullYear() + 20
};

export default YearSelect;

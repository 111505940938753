import * as actionTypes from "Constants/redux";

export const receiveMessageableNames = names => {
  let firstId = "";
  if (names.length > 0) {
    firstId = names[0].nameableId;
  }
  return {
    type: actionTypes.RECEIVE_MESSAGEABLE_NAMES,
    names: names,
    firstId: firstId
  };
};
export const fetchMessageableNames = () => {
  return (dispatch, getState) => {
    dispatch({
      type: actionTypes.REQUEST_MESSAGEABLE_NAMES
    });
  };
};

function shouldFetchMessageableNames(state) {
  const messageables = state.messageable;
  if (!messageables) {
    return true;
  } else if (messageables.fetchingNames) {
    return false;
  } else {
    return messageables.shouldRefresh;
  }
}

export const fetchMessageableNamesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchMessageableNames(getState())) {
      return dispatch(fetchMessageableNames());
    }
  };
};

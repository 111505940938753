import {
  FETCH_FEATURES_REQUEST,
  FETCH_FEATURES_SUCCESS,
  FETCH_FEATURES_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  features: [],
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_FEATURES_REQUEST:
      return { ...state, loading: true };

    case FETCH_FEATURES_FAILURE:
      return { ...state, loading: false };
    case FETCH_FEATURES_SUCCESS:
      return {
        ...state,
        features: action.response.features,
        loading: false
      };
    default:
      return state;
  }
};

import * as actionTypes from 'Constants/redux';
import { saveError } from 'Api';
import service from 'Api/service';
import moment from 'moment-timezone';
import { userIsAllowedForPermission } from 'HelperFunctions/locations';

export function setSelectedDate(selectedDate) {
  return {
    type: actionTypes.SET_SELECTED_DATE,
    payload: { selectedDate },
  };
}

export const resetSelectedDate = () => ({
  type: actionTypes.RESET_SELECTED_DATE,
});

export const setDatesArray = (datesArray) => ({
  type: actionTypes.FETCH_DATES_ARRAY_SUCCESS,
  datesArray,
});

export const setSecondaryDatesArray = (secondaryDatesArray) => ({
  type: actionTypes.SET_SECONDARY_DATES_ARRAY,
  secondaryDatesArray,
});

export const fetchCalendarDatesBasedOnRoute = (pathname) => (
  dispatch,
  getState
) => {
  const calendarType = pathname.split('/').slice(-1)[0];
  switch (calendarType) {
    case 'availability':
      return dispatch(fetchCalendarDatesForAvailability());
    case 'calendar':
      return dispatch(fetchCalendarDatesForOrders());
    case 'alerts':
      return dispatch(fetchCalendarDatesForAlerts());
    case 'dropOff-pickUp':
      return dispatch(fetchCalendarDatesForDropOffsPickUps());
    default:
      return;
  }
};

export const fetchCalendarDatesForOrders = () => (dispatch, getState) => {
  if (getState().calendars.loading) return;
  dispatch({ type: actionTypes.FETCH_DATES_ARRAY });
  service
    .get(process.env.REACT_APP_API_DOMAIN + '/api/calendar')
    .then((response) => {
      dispatch(setDatesArray(response.data.schedules));
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_DATES_ARRAY_FAILURE });
      saveError(error);
    });
};

export const fetchCalendarDatesForAlerts = () => (dispatch, getState) => {
  if (getState().calendars.loading) return;
  dispatch({ type: actionTypes.FETCH_DATES_ARRAY });
  service
    .get(process.env.REACT_APP_API_DOMAIN + '/api/calendar/alerts')
    .then((response) => {
      dispatch(setDatesArray(response.data));
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_DATES_ARRAY_FAILURE });
      saveError(error);
    });
};

export const fetchCalendarDatesForDropOffsPickUps = () => (
  dispatch,
  getState
) => {
  if (getState().calendars.loading) return;

  const { employeeLocationRelationship } = getState().user;
  const { location } = getState().location;
  const forDevOps = !userIsAllowedForPermission(
    location,
    'operations_view',
    employeeLocationRelationship
  );
  const timeZone = moment.tz.guess();

  dispatch({ type: actionTypes.FETCH_DATES_ARRAY });
  service
    .get(process.env.REACT_APP_API_DOMAIN + '/api/calendar/rentals', {
      for_devops: forDevOps,
      time_zone: timeZone,
      filter: 'schedule',
    })
    .then((response) => {
      dispatch(setDatesArray(response.data.schedules));
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_DATES_ARRAY_FAILURE });
      saveError(error);
    });
};

export const fetchCalendarDatesForAvailability = () => (dispatch, getState) => {
  if (getState().calendars.loading) return;

  dispatch({ type: actionTypes.FETCH_DATES_ARRAY });
  service
    .get(process.env.REACT_APP_API_DOMAIN + '/api/calendar/availability')
    .then((response) => {
      dispatch(setSecondaryDatesArray(response.data.availability_toggled_schedules));
      dispatch(setDatesArray(response.data.availability_alerts_schedules));
    })
    .catch((error) => {
      console.log(error);
      dispatch({ type: actionTypes.FETCH_DATES_ARRAY_FAILURE });
      saveError(error);
    });
};

export const changeMobileViewCalendarState = (openStatus) => ({
  type: actionTypes.CHANGE_MOBILE_VIEW_CALENDAR_STATE,
  openStatus,
});

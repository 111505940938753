import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions/LoadingSpinnerActions";

function withLoadingSpinner(WrappedComponent) {
  // ...and returns another component...
  class LoadingWrapper extends React.Component {
    constructor(props) {
      super(props);
      this.state = {
        loading: true
      };
    }

    componentDidMount() {
      // ... that takes care of the subscription...
      const { closeLoadingSpinner } = this.props;
      setTimeout(
        function() {
          closeLoadingSpinner();
          this.setState({ loading: false });
        }.bind(this),
        500
      );
    }
    UNSAFE_componentWillMount() {
      this.props.openLoadingSpinner("Loading...");
    }

    render() {
      const { loading } = this.state;
      if (loading) {
        return null;
      }
      return <WrappedComponent {...this.props} />;
    }
  }
  return connect(null, actions)(LoadingWrapper);
}

export default withLoadingSpinner;

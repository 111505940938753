import React from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import { employeeIsAllowed } from 'HelperFunctions/locations';

export const ADMIN_ONLY_ROLES = ['admin', 'owner'];

export const canViewAtAGlanceLink = (employeeLocationRelationship) => {
  return employeeIsAllowed(ADMIN_ONLY_ROLES, employeeLocationRelationship);
};

export const getDashboardNavLinkPath = (employeeLocationRelationship) => {
  return canViewAtAGlanceLink(employeeLocationRelationship)
    ? '/at-a-glance'
    : '/todos';
};

const AtAGlanceLink = (canViewLink) => (props) => {
  const { employeeLocationRelationship, multilocationAllLocations } = props;

  if (multilocationAllLocations || !canViewLink(employeeLocationRelationship)) {
    return null;
  }

  return (
    <NavLink to='/at-a-glance' activeClassName='selected'>
      At-A-Glance
    </NavLink>
  );
};

const mapStateToProps = (state) => {
  const { employeeLocationRelationship } = state.user;
  const { multilocationAllLocations } = state.dashboard;

  return { employeeLocationRelationship, multilocationAllLocations };
};

export default connect(
  mapStateToProps,
  null
)(AtAGlanceLink(canViewAtAGlanceLink));

import { standardService } from "Api/service";
import {
  saveAdminAuthToken,
  clearAdminAuthToken,
  getAdminAuthToken,
  ROOT_URL,
  adminAuthToken,
  saveError
} from "Api";
import { history } from "Components/Routes";
import { setErrors } from "./dashboardActionCreators";
import {
  ADMIN_FIELD_CHANGED,
  LOGIN_ADMIN_SUCCESS,
  LOGIN_ADMIN_FAIL,
  CREATE_ADMIN_FAIL,
  LOGIN_ADMIN,
  SIGN_OUT_ADMIN_SUCCESS,
  SIGN_OUT_ADMIN_FAIL,
  SIGN_OUT_ADMIN,
  VALIDATE_ADMIN,
  VALIDATE_ADMIN_SUCCESS,
  VALIDATE_ADMIN_FAIL
} from "Constants/redux";

export const adminFieldChanged = ({ field, text }) => {
  return {
    type: ADMIN_FIELD_CHANGED,
    payload: { field, text }
  };
};

const loginAdminSuccess = (dispatch, admin) => {
  dispatch({
    type: LOGIN_ADMIN_SUCCESS,
    payload: admin
  });
};
const validateAdminSuccess = (dispatch, user) => {
  dispatch({
    type: VALIDATE_ADMIN_SUCCESS,
    payload: user
  });
};
const signOutAdminSuccess = dispatch => {
  dispatch({
    type: SIGN_OUT_ADMIN_SUCCESS
  });
};

export const loginAdmin = ({ email, password }) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_ADMIN });

    standardService
      .post(`${ROOT_URL}/admin_auth/sign_in`, { email, password })
      .then(response => {
        saveAdminAuthToken(response.headers);
        loginAdminSuccess(dispatch, response.data.data);

        if (history.location.state?.from) {
          history.push(history.location.state.from)
        } else {
          history.push("/tg-admin/businesses");
        }
      })
      .catch(error => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: LOGIN_ADMIN_FAIL,
          payload: error.response.data.errors
        });
      });
  };
};

export const validateAdmin = () => {
  return (dispatch, getState) => {
    dispatch({ type: VALIDATE_ADMIN });
    const authTokenHead = adminAuthToken();

    if (authTokenHead) {
      standardService
        .get(`${ROOT_URL}/admin_auth/validate_token`, {
          uid: authTokenHead["uid"],
          client: authTokenHead["client"],
          "access-token": authTokenHead["access-token"]
        })
        .then(response => {
          validateAdminSuccess(dispatch, response.data.data);
          saveAdminAuthToken(response.headers);
        })
        .catch(error => {
          saveError(error, getState());
          dispatch({ type: VALIDATE_ADMIN_FAIL });
          history.push({
            pathname: "/tg-admin",
            state: {
              from: history.location.pathname
            }
          });
        });
    } else {
      dispatch({ type: VALIDATE_ADMIN_FAIL });
      history.push({
        pathname: "/tg-admin",
        state: {
          from: history.location.pathname
        }
      });
    }
  };
};

export const createAdmin = ({ email, password, passwordConfirmation }) => {
  return (dispatch, getState) => {
    dispatch({ type: LOGIN_ADMIN });

    standardService
      .post(`${ROOT_URL}/admin_auth`, {
        email,
        password,
        password_confirmation: password
      })
      .then(response => {
        loginAdminSuccess(dispatch, response.data.data);
      })
      .catch(error => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.errors));
        dispatch({
          type: CREATE_ADMIN_FAIL,
          payload: error.response.data.errors
        });
      });
  };
};

export const signOutAdmin = () => {
  return (dispatch, getState) => {
    const headers = getAdminAuthToken();
    dispatch({ type: SIGN_OUT_ADMIN });

    standardService
      .delete(`${ROOT_URL}/admin_auth/sign_out`, {
        headers: headers
      })
      .then(response => {
        signOutAdminSuccess(dispatch, response.data.data);
        clearAdminAuthToken();
        history.push("/");
      })
      .catch(error => {
        saveError(error, getState());
        dispatch({
          type: SIGN_OUT_ADMIN_FAIL,
          payload: error.response.data.errors
        });
      });
  };
};

import {
  FETCH_RENTAL_INVENTORY_LIST_REQUEST,
  FETCH_RENTAL_INVENTORY_LIST_SUCCESS,
  PAGINATE_RENTAL_INVENTORY_LIST_REQUEST,
  PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS,
  SET_RENTAL_INVENTORY_FILTER
} from "Constants/redux";

const INITIAL_STATE = {
  rentalInventories: [],
  loading: false,
  pagination: {},
  filter: {
    status: "",
    startDate: null,
    endDate: null,
    query: "",
    showAll: false
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PAGINATE_RENTAL_INVENTORY_LIST_REQUEST:
    case FETCH_RENTAL_INVENTORY_LIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_RENTAL_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case SET_RENTAL_INVENTORY_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };
    default:
      return state;
  }
};

import {
  GET_PRICING_TIER_PREMIER_REQUEST,
  GET_PRICING_TIER_STANDARD_REQUEST,
  GET_PRICING_TIER_SELECT_REQUEST,
  GET_PRICING_TIER_ESSENTIALS_REQUEST,
  GET_PRICING_TIER_STANDARD_SUCCESS,
  GET_PRICING_TIER_SELECT_SUCCESS,
  GET_PRICING_TIER_PREMIER_SUCCESS,
  GET_PRICING_TIER_ESSENTIALS_SUCCESS,
  GET_PRICING_TIER_PREMIER_FAILURE,
  GET_PRICING_TIER_STANDARD_FAILURE,
  GET_PRICING_TIER_SELECT_FAILURE,
  GET_PRICING_TIER_ESSENTIALS_FAILURE
} from "Constants/redux";

import converter from "json-style-converter/es5";

const INITIAL_STATE = {
  pricingTierStandard: {},
  pricingTierSelect: {},
  pricingTierPremier: {},
  pricingTierEssentials: {},
  loading: false,
};

export default (state = INITIAL_STATE, action) => {

  switch (action.type) {
    case GET_PRICING_TIER_PREMIER_REQUEST:
      return { ...state, loading: true };
    case GET_PRICING_TIER_STANDARD_REQUEST:
      return { ...state, loading: true };
    case GET_PRICING_TIER_SELECT_REQUEST:
      return { ...state, loading: true };
    case GET_PRICING_TIER_ESSENTIALS_REQUEST:
      return { ...state, loading: true };
    case GET_PRICING_TIER_STANDARD_SUCCESS:
      return {
        ...state, pricingTierStandard: converter.snakeToCamelCase(action.payload),
        loading: false
      };
    case GET_PRICING_TIER_SELECT_SUCCESS:
      return {
        ...state, pricingTierSelect: converter.snakeToCamelCase(action.payload),
        loading: false
      };
    case GET_PRICING_TIER_PREMIER_SUCCESS:
      return {
        ...state, pricingTierPremier: converter.snakeToCamelCase(action.payload),
        loading: false
      };
    case GET_PRICING_TIER_ESSENTIALS_SUCCESS:
      return {
        ...state, pricingTierEssentials: converter.snakeToCamelCase(action.payload),
        loading: false
      };
    case GET_PRICING_TIER_PREMIER_FAILURE:
      return { ...state, loading: false };
    case GET_PRICING_TIER_STANDARD_FAILURE:
      return { ...state, loading: false };
    case GET_PRICING_TIER_SELECT_FAILURE:
      return { ...state, loading: false };
    case GET_PRICING_TIER_ESSENTIALS_FAILURE:
      return { ...state, loading: false };
    default:
      return state;
  }
};

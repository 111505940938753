import * as actionTypes from "Constants/redux";

function messageableReducer(
  state = {
    isFetching: false,
    shouldRefresh: true,
    items: [],
    query: "",
    names: [],
    fetchingNames: false
  },
  action
) {
  switch (action.type) {
    case actionTypes.REQUEST_MESSAGEABLE_NAMES:
      return {
        ...state,
        fetchingNames: true,
        shouldRefresh: true
      };
    case actionTypes.RECEIVE_MESSAGEABLE_NAMES:
      return {
        ...state,
        names: action.names
      };
    case actionTypes.SEARCH_MESSAGEABLE_NAMES:
      return {
        ...state,
        query: action.query
      };
    default:
      return state;
  }
}

export default messageableReducer;

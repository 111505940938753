import { FETCH_RECURRENCES_REQUEST, EPIC_ERROR } from 'Constants/redux';
import * as actions from 'Actions';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
const { Observable } = Rx;

const requestParameters = (action) => {
  let { payload, per, page, query } = action;
  return payload?.all ? payload : { per, page, query };
}

const FetchRecurrencesEpic = (action$) =>
  action$.ofType(FETCH_RECURRENCES_REQUEST).switchMap((action) => {
    return Rx.Observable.fromPromise(
      service.get(
        process.env.REACT_APP_API_DOMAIN + `/api/recurrences`,
        requestParameters(action)
      )
    ).map((response) => {
      return actions.receiveRecurrences(response);
    }).catch(() => Observable.of({ type: EPIC_ERROR }));
  });
export default FetchRecurrencesEpic;

import { ROOT_URL } from "Api";
import service from "Api/service";

const quickbooks = {
  showBusiness: () => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN + "/api/quickbooks/quickbooks_authentications/show"
    );
  },
  updateQuickbooksSetting: data => {
    return service.put(
      process.env.REACT_APP_API_DOMAIN + "/api/quickbooks/quickbooks_setting/",
      data
    );
  },
  updateQuickbookAuth: (id, data) => {
    const component = this;
    return service.put(
      `
      ${process.env.REACT_APP_API_DOMAIN}/api/quickbooks/quickbooks_authentications/${id}`,
      data
    );
  },
  disconnectQuickbooks: id => {
    return service.delete(
      `${process.env.REACT_APP_API_DOMAIN}/api/quickbooks/quickbooks_authentications/${id}`
    );
  },
  quickbookLabel: params => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN + "/api/quickbooks/accounts",
      params
    );
  },
  quickbookLineItem: params => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN + "/api/quickbooks/custom_labels",
      params
    );
  },
  getQuickbooksLocationClass: (authId, locationId) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/quickbooks/location_classes/${authId}/location/${locationId}`
    );
  },
  updateQuickbooksLocationClass: (authId, locationId, params) => {
    return service.put(
      process.env.REACT_APP_API_DOMAIN +
        `/api/quickbooks/location_classes/${authId}/location/${locationId}`,
      params
    );
  }
};

export default quickbooks;

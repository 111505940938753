import { TOGGLE_ADD_SERVICE_MODAL } from 'Constants/redux';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';

export const toggleAddServiceModal = (
  status: boolean,
  rentalSectionRvId: number | string | null = null
): ThunkAction<void, AppState, unknown, RentalFormActionTypes> => (
  dispatch
) => {
  dispatch({
    type: TOGGLE_ADD_SERVICE_MODAL,
    status,
    rentalSectionRvId,
  });
};

// Calls updateRentalVersion with current rental version id set on the store

import { updateRentalVersion } from './updateRentalVersion';
import RentalVersionData from '../../types/RentalVersionData';
import AppState from '../../types/AppState';
import { ThunkAction, ThunkDispatch } from 'redux-thunk';
import { RentalFormActionTypes } from './types';
import PricingService from '../../helper_functions/rentalForm/pricing_service';
import converter from 'json-style-converter/es5';
import { setAlert } from '../dashboardActionCreators';
import axios from 'axios';

const validatePayload = (currentRentalVersionInStore: RentalVersionData,  payload: Partial<RentalVersionData>, dispatch) => {
  // If the current subtotal is positive and the new subtotal is negative, alert the user that the order total has gone negative
  const snakeCasedCurrentPayload = converter.camelToSnakeCase(currentRentalVersionInStore)
  const pricingServiceForCurrentPayload = new PricingService(snakeCasedCurrentPayload);
  pricingServiceForCurrentPayload.billingLinesForRental();

  const newPayload = { ...currentRentalVersionInStore, ...payload };
  const snakeCasedNewPayload = converter.camelToSnakeCase(newPayload);
  const pricingServiceForNewPayload = new PricingService(snakeCasedNewPayload);
  pricingServiceForNewPayload.billingLinesForRental();

  const currentSubtotal = pricingServiceForCurrentPayload.findBillingLine('Subtotal').finalValue;
  const newSubtotal = pricingServiceForNewPayload.findBillingLine('Subtotal').finalValue;

  if (currentSubtotal >= 0 && newSubtotal < 0) {
    dispatch(setAlert("The discounts applied to this order are greater than the subtotal. If this is intentional, continue."))
  }
}

export const updateCurrentRentalVersion = (
  payload: Partial<RentalVersionData>,
  cancelToken = axios.CancelToken.source().token
): ThunkAction<Promise<void>, AppState, unknown, RentalFormActionTypes> => (
  dispatch: any,
  getState: any,
) => {
  const currentRentalVersionInStore = getState().rentalFormV2.data;
  const id = getState().rentalFormV2.id;

  if (!id) return Promise.resolve();

  validatePayload(currentRentalVersionInStore, payload, dispatch)

  return dispatch(updateRentalVersion(id, payload));
};


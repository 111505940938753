import {
  REFRESH_RENTAL,
  FETCH_RENTAL_REQUEST,
  FETCH_RENTAL_FAILURE,
  FETCH_RENTAL_SUCCESS,
  FETCH_RENTAL_PICKLIST_REQUEST,
  FETCH_RENTAL_PICKLIST_SUCCESS,
  FETCH_RENTAL_PICKLIST_FAILURE,
  FETCH_RENTAL_INFO_REQUEST,
  FETCH_RENTAL_INFO_FAILURE,
  FETCH_RENTAL_INFO_SUCCESS,
  UPDATE_RENTAL_REQUEST,
  UPDATE_RENTAL_FAILURE,
  UPDATE_RENTAL_SUCCESS,
  CANCEL_RENTAL_REQUEST,
  CANCEL_RENTAL_FAILURE,
  CANCEL_RENTAL_SUCCESS,
  DELETE_RENTAL_REQUEST,
  DELETE_RENTAL_FAILURE,
  DELETE_RENTAL_SUCCESS,
  RESERVE_RENTAL_REQUEST,
  RESERVE_RENTAL_FAILURE,
  RESERVE_RENTAL_SUCCESS,
  CLOSE_RENTAL_REQUEST,
  CLOSE_RENTAL_FAILURE,
  CLOSE_RENTAL_SUCCESS,
  CHECK_OUT_RENTAL_REQUEST,
  CHECK_OUT_RENTAL_FAILURE,
  CHECK_OUT_RENTAL_SUCCESS,
  CHECK_IN_RENTAL_REQUEST,
  CHECK_IN_RENTAL_FAILURE,
  CHECK_IN_RENTAL_SUCCESS,
  SET_CHECK_IN_SIGNATURE,
  SET_CHECK_OUT_SIGNATURE,
  SET_RENTAL_PROPS,
  BULK_PICK_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE,
  SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS,
  BULK_PICK_RENTAL_ITEMS_SUCCESS,
  BULK_PICK_RENTAL_ITEMS_FAILURE,
  PICK_RENTAL_ITEM_REQUEST,
  PICK_RENTAL_ITEM_SUCCESS,
  PICK_RENTAL_ITEM_FAILURE,
  UNPICK_RENTAL_ITEM_REQUEST,
  UNPICK_RENTAL_ITEM_SUCCESS,
  UNPICK_RENTAL_ITEM_FAILURE,
  BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
  BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
  BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS,
  SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE,
  BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST,
  BULK_UNCHECK_IN_RENTAL_ITEMS_SUCCESS,
  BULK_UNCHECK_IN_RENTAL_ITEMS_FAILURE,
  CHECK_IN_RENTAL_ITEM_REQUEST,
  CHECK_IN_RENTAL_ITEM_SUCCESS,
  CHECK_IN_RENTAL_ITEM_FAILURE,
  UNCHECK_IN_RENTAL_ITEM_REQUEST,
  UNCHECK_IN_RENTAL_ITEM_SUCCESS,
  UNCHECK_IN_RENTAL_ITEM_FAILURE,
  REPORT_LACK_RENTAL_ITEM_REQUEST,
  REPORT_LACK_RENTAL_ITEM_SUCCESS,
  REPORT_LACK_RENTAL_ITEM_FAILURE,
  ADD_FEE_RENTAL_ITEM_REQUEST,
  ADD_FEE_RENTAL_ITEM_SUCCESS,
  ADD_FEE_RENTAL_ITEM_FAILURE,
  UPDATE_RENTAL_ITEM_REQUEST,
  UPDATE_RENTAL_ITEM_SUCCESS,
  UPDATE_RENTAL_ITEM_FAILURE,
  CONNECT_RENTAL_REQUEST,
  CONNECT_RENTAL_SUCCESS,
  CONNECT_RENTAL_FAILURE,
  SUBMIT_QUOTE_REQUEST,
  REQUEST_CHANGES_REQUEST,
  REQUEST_CANCEL_REQUEST,
  UPDATE_SUPPLIER_RENTAL_REQUEST,
  SET_SUBRENTAL_ITEM,
  ADD_RENTAL_UNREAD_MESSAGE,
  REPOSITION_INVENTORY_SUCCESS,
  SET_MANIPULATED_PICK_LIST_ITEMS,
  FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS,
  FETCH_DELIVERY_TICKETS_REQUEST_FAILURE,
  FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS,
  FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE,
  SET_SELECTED_DT_ID,
  CHECKOUT_DELIVERY_TICKET_REQUEST,
  CHECKOUT_DELIVERY_TICKET_SUCCESS,
  CHECKOUT_DELIVERY_TICKET_FAILURE,
  CHECKIN_DELIVERY_TICKET_REQUEST,
  CHECKIN_DELIVERY_TICKET_SUCCESS,
  CHECKIN_DELIVERY_TICKET_FAILURE,
  CLOSE_DELIVERY_TICKET_REQUEST,
  CLOSE_DELIVERY_TICKET_SUCCESS,
  CLOSE_DELIVERY_TICKET_FAILURE,
  SET_SELECTED_DELIVERY_TICKET_SUCCESS,
  SET_SELECTED_DELIVERY_TICKET_FAILURE,
} from 'Constants/redux';

import { produce } from 'immer';

const INITIAL_STATE = {
  rental: {
    id: '',
    rentalType: '',
    rentalAgreement: '',
    createdAt: new Date(),
    amountRemaining: 0,
    minimumDeposit: 0,
    paymentBalance: 0,
    rentalAddOnTotal: 0,
    rentalItemTotal: 0,
    rentalBundleTotal: 0,
    rentalBundleTotalAfterDiscount: 0,
    deliveryCost: 0,
    discountTotal: 0,
    feeTotal: 0,
    subTotal: 0,
    subTotalForTax: 0,
    taxTotal: 0,
    overallTotal: 0,
    tapgoodsFeeTotal: 0,
    tgBusinessFee: 0,
    amountPaid: 0,
    expenseTotal: 0,
    name: '',
    token: '',
    taxExempt: false,
    isAllowedToMakePayment: true,
    depositRequired: false,
    signatureRequired: false,
    npsRequired: false,
    depositComplete: false,
    sigComplete: false,
    depositType: 'optional',
    shouldDepositOneFullPayment: false,
    shouldSyncToQb: false,
    expireType: 'no_expire',
    customExpireDate: null,
    hasShortage: false,
    billingNotes: [],
    changeHistoryEntries: [],
    emailHistoryEntries: [],
    emails: [],
    schedule: {
      startDate: new Date(0),
      endDate: new Date(0),
      startTime: new Date(0),
      endTime: new Date(0),
      startWindowFinish: new Date(),
      endWindowBeginning: new Date(),
      eventStartDate: new Date(0),
      eventEndDate: new Date(0),
      eventStartTime: new Date(0),
      eventEndTime: new Date(0),
      shelfEnabled: false,
      offShelfAt: new Date(0),
      onShelfAt: new Date(0),
    },
    delivery: {
      delivery_type: '',
      delivery_address_location_name: '',
      delivery_address_street_address_1: '',
      delivery_address_street_address_2: '',
      delivery_address_city: '',
      delivery_address_locale: '',
      delivery_address_postal_code: '',
      delivery_address_country: '',
    },
    pickup: {
      pickup_same_as_delivery: true,
      pickup_address_location_name: '',
      pickup_address_street_address_1: '',
      pickup_address_street_address_2: '',
      pickup_address_city: '',
      pickup_address_locale: '',
      pickup_address_postal_code: '',
      pickup_address_country: '',
    },
    source: '',
    status: '',
    cancellationStatus: '',
    customers: [],
    customerRentalRelationships: [],
    customerLocationRelationships: [],
    items: [],
    accessories: [],
    addons: [],
    pickListItems: [],
    pickListAccessories: [],
    pickListAddOns: [],
    bundles: [],
    staffs: [],
    sections: [],
    rentalStaffMembers: [],
    rentalTransports: [],
    documents: [],
    documentRentalRelationships: [],
    disclaimerRentalRelationships: [],
    itemDisclaimers: [],
    accessoryDisclaimers: [],
    addOnDisclaimers: [],
    bundleDisclaimers: [],
    salesperson: null,
    venue: null,
    company: null,
    proposalSetting: null,

    fees: [],
    pickListDamageFees: [],
    pickListAccessoryFees: [],
    taxableFees: [],
    creditCardFees: [],
    itemFees: {},
    inventoryDiscounts: {},
    discounts: [],
    payments: [],
    refunds: [],
    referralSource: '',
    billing: {
      rental_add_on_total: 0,
      rental_item_total: 0,
      rental_bundle_total: 0,
      rental_bundle_total_after_discount: 0,
      staffing_total: 0,
      delivery_cost: 0,
      discount_total: 0,
      fee_total: 0,
      sub_total: 0,
      subTotalForTax: 0,
      tax_total: 0,
      total_custom_tax: 0,
      overall_total: 0,
      tapgoods_fee_total: 0,
      amount_paid: 0,
      amount_remaining: 0,
      expenseTotal: 0,
      total_delivery_cost: 0,
    },
    rentalSetting: {},
    changeRequest: null,
    changeRequests: [],
    processingFeeTotal: 0,
    billingStatus: '',
    notes: [],
    feedbacks: [],
    hasFlip: false,
    flipDateTime: new Date(),
    flipNotes: '',
    storefrontPercent: 0,
    supplierRentals: [],
    conversations: [],
    unreadMessages: 0,
    expenses: [],
    salesTaxes: [],
    open: false,
    deliveryTickets: [],
    secondaryOrders: [],
    finalPaymentDueDateType: null,
  },
  maxCreditCardRefund: 0,
  paymentBalance: 0,
  loading: false,
  shouldRefresh: false,
  checkInSignature: null,
  checkInSignerName: '',
  checkInSignerTitle: '',
  checkInSignerCompany: '',
  checkOutSignature: null,
  checkOutSignerName: '',
  checkOutSignerTitle: '',
  checkOutSignerCompany: '',
  businessCheckInSignature: null,
  businessCheckInSignerName: '',
  businessCheckInSignerTitle: '',
  businessCheckInSignerCompany: '',
  businessCheckOutSignature: null,
  businessCheckOutSignerName: '',
  businessCheckOutSignerTitle: '',
  businessCheckOutSignerCompany: '',
  subrentalItemId: 0,
  additionalOrderInfo: '',
  additionalDeliveryInfo: '',
  damageWaiverExempt: false,
  isRecurringPayment: false,
  recurringTransactionInfo: {},
  recurringPaymentDetails: {},
  gstTaxExempt: false,
  pstTaxExempt: false,
  manipulatedPickListItems: [],
  smartPricingActive: false,
  // Delivery Tickets stuff
  unassignedPickList: {
    items: [],
    accessories: [],
    addOns: [],
  },
  selectedDeliveryTicketId: null,
  selectedDeliveryTicket: {},
  hasAuthorizationHold: false,
  quickbooksConnections: [],
  qbPeriodClosedError: null,
  type: '',
  eventId: null,
  event: null
};

const convertRentalToBillingInfo = (rental) => {
  const taxableFees = rental.feeRentalRelationships
    ? rental.feeRentalRelationships.filter(
        (fee) => fee.feeType !== 'credit_card_fee'
      )
    : [];
  const creditCardFees = rental.feeRentalRelationships
    ? rental.feeRentalRelationships.filter(
        (fee) => fee.feeType === 'credit_card_fee'
      )
    : [];
  const billing = {
    rental_item_total: rental.rentalItemTotal,
    rental_item_total_before_discount: rental.rentalItemTotalBeforeDiscount,
    rental_add_on_total: rental.rentalAddOnTotal,
    rental_bundle_total: rental.rentalBundleTotal,
    rental_bundle_total_after_discount: rental.rentalBundleTotalAfterDiscount,
    staffing_total: rental.staffingTotal,
    delivery_cost: rental.deliveryCost,
    total_delivery_cost: rental.totalDeliveryCost,
    discount_total: rental.discountTotal,
    expenseTotal: rental.expenseTotal,
    fee_total: rental.feeTotal,
    sub_total: rental.subTotal,
    subTotalForTax: rental.subTotalForTax,
    tax_total: rental.taxTotal,
    total_custom_tax: rental.totalCustomTax,
    overall_total: rental.overallTotal,
    tapgoods_fee_total: rental.tapgoodsFeeTotal,
    tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
    amount_paid: rental.totalPaid,
    damageWaiverFeeTotal: rental.damageWaiverFeeTotal,
    amount_remaining: rental.amountRemaining,
    processingFeeRefunded: rental.processingFeeRefunded,
    tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
    tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded,
    itemFees: rental.itemFees || {},
    inventoryDiscounts: rental.inventoryDiscounts || {},
    damageWaiverExempt: rental.damageWaiverExempt,
    isRecurringPayment: rental.isRecurringPayment,
    recurringTransactionInfo: rental.recurringTransactionInfo,
    recurringPaymentDetails: rental.recurringPaymentDetails,
    gstTaxExempt: rental.gstTaxExempt,
    pstTaxExempt: rental.pstTaxExempt,
    rentalSignatures: rental.rentalSignatures,
    quickbooksConnections: rental.quickbooksConnections,
    qbPeriodClosedError: rental.qbPeriodClosedError,
  };
  return {
    ...rental,
    taxableFees,
    creditCardFees,
    billing,
  };
};

const convertRentalPickListToState = (state_rental, rental) => {
  return {
    ...state_rental,
    checkOutSignature: rental.checkoutSignature,
    checkOutSignerName: rental.checkoutSignerName,
    checkOutSignerTitle: rental.checkoutSignerTitle,
    checkOutSignerCompany: rental.checkoutSignerCompany,
    checkoutSignatureDatetime: rental.checkoutSignatureDatetime,
    businessCheckOutSignature: rental.businessCheckoutSignature,
    businessCheckOutSignerName: rental.businessCheckoutSignerName,
    businessCheckOutSignerTitle: rental.businessCheckoutSignerTitle,
    businessCheckOutSignerCompany: rental.businessCheckoutSignerCompany,
    businessCheckoutSignatureDatetime: rental.businessCheckoutSignatureDatetime,
    checkInSignature: rental.checkinSignature,
    checkInSignerName: rental.checkinSignerName,
    checkInSignerTitle: rental.checkinSignerTitle,
    checkInSignerCompany: rental.checkinSignerCompany,
    businessCheckInSignature: rental.businessCheckinSignature,
    businessCheckInSignerName: rental.businessCheckinSignerName,
    businessCheckInSignerTitle: rental.businessCheckinSignerTitle,
    businessCheckInSignerCompany: rental.businessCheckinSignerCompany,
    pickListItems: rental.pickListItems,
    pickListAccessories: rental.pickListAccessories,
    pickListAddOns: rental.pickListAddOns,
    customerRentalRelationships: rental.customerRentalRelationships,
    rentalTransports: rental.rentalTransports,
    notes: rental.allNotes,
    documents: rental.documents,
    companyRentalRelationship: rental.companyRentalRelationship,
    rentalSignatures: rental.rentalSignatures,
    hasFlip: rental.hasFlip,
    flipDateTime: rental.flipDateTime,
    company: rental.companyRentalRelationship?.company,
    teamMembers: rental.teamMembers,
    venue: rental.venueRentalRelationship?.venue,
    staffs: rental.rentalStaffs,
    schedule: {
      startDate: rental.schedule.startDate,
      endDate: rental.schedule.endDate,
      startTime: rental.schedule.startTime,
      endTime: rental.schedule.endTime,
      startWindowFinish: rental.schedule.startWindowFinish,
      endWindowBeginning: rental.schedule.endWindowBeginning,
      eventStartDate: rental.schedule.eventStartDateTime,
      eventEndDate: rental.schedule.eventEndDateTime,
      eventStartTime: rental.schedule.eventStartDateTime,
      eventEndTime: rental.schedule.eventEndDateTime,
      shelfEnabled: rental.schedule.shelfEnabled,
      offShelfAt: rental.schedule.offShelfAt,
      onShelfAt: rental.schedule.onShelfAt,
      timeZone: rental.schedule.timeZone,
      eventStartSetting: rental.schedule.eventStartSetting,
      eventEndSetting: rental.schedule.eventEndSetting,
    },
    delivery: {
      delivery_type: rental.deliveryType,
      delivery_address_location_name: rental.deliveryAddressLocationName,
      delivery_address_street_address_1: rental.deliveryAddressStreetAddress1,
      delivery_address_street_address_2: rental.deliveryAddressStreetAddress2,
      delivery_address_city: rental.deliveryAddressCity,
      delivery_address_locale: rental.deliveryAddressLocale,
      delivery_address_postal_code: rental.deliveryAddressPostalCode,
      delivery_address_country: rental.deliveryAddressCountry,
    },
    isRecurringPayment: rental.isRecurringPayment,
    recurringTransactionInfo: rental.recurringTransactionInfo,
    recurringPaymentDetails: rental.recurringPaymentDetails,
    gstTaxExempt: rental.gstTaxExempt,
    pstTaxExempt: rental.pstTaxExempt,
    sections: rental.rentalSections,
  };
};

const convertDeliveryTicketToState = (deliveryTicket, rental) => {
  if (!deliveryTicket) return null;
  // This standarize DT with how FE handles rental.

  // Filter picklist elements
  // Api returns this but without some used keys like subRental
  // const filterPickListElements = (elements, dtId) => {
  //   return elements ? elements.filter(element => element.deliveryTicketId == dtId) : [];
  // }
  // @ToDo this does not work because the content we are interested comes after:
  //     FETCH_RENTAL_PICKLIST_SUCCESS.
  // const pickListItems = filterPickListElements(rental.pickListItems);
  // const pickListAccessories = filterPickListElements(rental.pickListAccessories);
  // const pickListAddOns = filterPickListElements(rental.pickListAddOns);

  // @ToDo on some unidentified cases dt.rental is not present
  const deliveryTicketRental = deliveryTicket.rental
    ? deliveryTicket.rental
    : rental;
  return {
    ...deliveryTicket,
    // pickListItems: pickListItems,
    // pickListAccessories: pickListAccessories,
    // pickListAddOns: pickListAddOns,
    delivery: {
      delivery_type: deliveryTicket.deliveryType,
      delivery_address_location_name:
        deliveryTicket.deliveryAddressLocationName,
      delivery_address_street_address_1:
        deliveryTicket.deliveryAddressStreetAddress1,
      delivery_address_street_address_2:
        deliveryTicket.deliveryAddressStreetAddress2,
      delivery_address_city: deliveryTicket.deliveryAddressCity,
      delivery_address_locale: deliveryTicket.deliveryAddressLocale,
      delivery_address_postal_code: deliveryTicket.deliveryAddressPostalCode,
      delivery_address_country: deliveryTicket.deliveryAddressCountry,
    },
    pickup: {
      pickup_same_as_delivery: deliveryTicket.pickupSameAsDelivery,
      pickup_address_location_name: deliveryTicket.pickupAddressLocationName,
      pickup_address_street_address_1:
        deliveryTicket.pickupAddressStreetAddress1,
      pickup_address_street_address_2:
        deliveryTicket.pickupAddressStreetAddress2,
      pickup_address_city: deliveryTicket.pickupAddressCity,
      pickup_address_locale: deliveryTicket.pickupAddressLocale,
      pickup_address_postal_code: deliveryTicket.pickupAddressPostalCode,
      pickup_address_country: deliveryTicket.pickupAddressCountry,
    },
    schedule: {
      startDate: deliveryTicket.startDateTime,
      endDate: deliveryTicket.endDateTime,
      startTime: deliveryTicket.startDateTime,
      endTime: deliveryTicket.endDateTime,
      startWindowFinish: deliveryTicket.startWindowFinish,
      endWindowBeginning: deliveryTicket.endWindowBeginning,
      eventStartDate: deliveryTicketRental.schedule.event_start_date_time, // @ToDo Why this are not camelCased?
      eventEndDate: deliveryTicketRental.eventEndDateTime,
      eventStartTime: deliveryTicketRental.schedule.event_start_date_time, // @ToDo Why this are not camelCased?
      eventEndTime: deliveryTicketRental.eventEndDateTime,
      shelfEnabled: deliveryTicketRental.shelfEnabled,
      offShelfAt: deliveryTicketRental.schedule.off_shelf_at, // @ToDo Why this are not camelCased?
      onShelfAt: deliveryTicketRental.schedule.on_shelf_at, // @ToDo Why this are not camelCased?
      timeZone: deliveryTicket.timeZone,
    },
    venue: {
      // @ToDo
    },
  };
};

// This method should not include data returned by billing_info or payment_info
//   that is not send by GET /requesst/token, or it will be overwriten (and lost)
//   from the state object
const convertRentalToState = (rental) => {
  rental.customerRentalRelationships.map((customer) => {
    if (customer.isPrimaryContact && rental.companyRentalRelationship)
      rental.companyRentalRelationship.isPrimaryContact = false;
  });

  const taxableFees = rental.feeRentalRelationships
    ? rental.feeRentalRelationships.filter(
        (fee) => fee.feeType !== 'credit_card_fee'
      )
    : [];
  const creditCardFees = rental.feeRentalRelationships
    ? rental.feeRentalRelationships.filter(
        (fee) => fee.feeType === 'credit_card_fee'
      )
    : [];
  const rentalStaffMembers = rental.rentalStaffs
    ? rental.rentalStaffs
        .map((staff) => staff.rentalStaffMembers)
        .reduce((acc, val) => acc.concat(val), [])
    : [];

  return {
    id: rental.id,
    type: rental.type || 'Rental',
    employeeRentalRelationships: rental.employeeRentalRelationships,
    commissionedCustomerRentalRelationships:
      rental.commissionedCustomerRentalRelationships,
    rentalAgreement: rental.rentalAgreement,
    amountRemaining: rental.amountRemaining,
    minimumDeposit: rental.minimumDeposit,
    paymentBalance: rental.paymentBalance,
    rentalItemTotal: rental.rentalItemTotal,
    inventoryTotalForTax: rental.inventoryTotalForTax,
    rentalAddOnTotal: rental.rentalAddOnTotal,
    rentalBundleTotal: rental.rentalBundleTotal,
    rentalBundleTotalAfterDiscount: rental.rentalBundleTotalAfterDiscount,
    deliveryCost: rental.deliveryCost,
    deliverySetting: rental.deliverySetting,
    discount_total: rental.discountTotal,
    isAllowedToMakePayment: rental.isAllowedToMakePayment,
    expenseTotal: rental.expenseTotal,
    feeTotal: rental.feeTotal,
    subTotal: rental.subTotal,
    subTotalForTax: rental.subTotalForTax,
    taxTotal: rental.taxTotal,
    overallTotal: rental.overallTotal,
    tapgoodsFeeTotal: rental.tapgoodsFeeTotal,
    tgBusinessFee: rental.tgBusinessFee,
    tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
    storefrontPercent: rental.storefrontPercent,
    amountPaid: rental.totalPaid,
    processingFeeRefunded: rental.processingFeeRefunded,
    tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
    tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded,
    name: rental.name,
    businessName: rental.businessName,
    token: rental.token,
    customId: rental.customId,
    createdAt: rental.createdAt,
    expireDate: rental.expireDate,
    changeHistoryEntries: rental.changeHistoryEntries,
    emailHistoryEntries: rental.emailHistoryEntries,
    emails: rental.emails,
    damageWaiverFeeTotal: rental.damageWaiverFeeTotal,
    taxExempt: rental.taxExempt,
    depositRequired: rental.depositRequired,
    signatureRequired: rental.signatureRequired,
    npsRequired: rental.npsRequired,
    depositComplete: rental.depositComplete,
    sigComplete: rental.sigComplete,
    depositType: rental.depositType,
    shouldDepositOneFullPayment: rental.shouldDepositOneFullPayment,
    shouldSyncToQb: rental.shouldSyncToQb,
    expireType: rental.expireType,
    customExpireDate: rental.customExpireDate,
    hasShortage: rental.hasShortage,
    hasDeposit: rental.hasDeposit,
    hasSignature: rental.hasSignature,
    signature: rental.signature,
    signerName: rental.signerName,
    signerTitle: rental.signerTitle,
    signerCompany: rental.signerCompany,
    businessSignature: rental.businessSignature,
    businessSignerName: rental.businessSignerName,
    businessSignerTitle: rental.businessSignerTitle,
    businessSignerCompany: rental.businessSignerCompany,
    rentalSignatures: rental.rentalSignatures,
    hasAgreement: rental.hasAgreement,
    proposalSetting: rental.proposalSetting,
    source: rental.source,
    schedule: {
      startDate: rental.schedule.startDate,
      endDate: rental.schedule.endDate,
      startTime: rental.schedule.startTime,
      endTime: rental.schedule.endTime,
      startWindowFinish: rental.schedule.startWindowFinish,
      endWindowBeginning: rental.schedule.endWindowBeginning,
      eventStartDate: rental.schedule.eventStartDateTime,
      eventEndDate: rental.schedule.eventEndDateTime,
      eventStartTime: rental.schedule.eventStartDateTime,
      eventEndTime: rental.schedule.eventEndDateTime,
      shelfEnabled: rental.schedule.shelfEnabled,
      offShelfAt: rental.schedule.offShelfAt,
      onShelfAt: rental.schedule.onShelfAt,
      timeZone: rental.schedule.timeZone,
      eventStartSetting: rental.schedule.eventStartSetting,
      eventEndSetting: rental.schedule.eventEndSetting,
    },
    delivery: {
      delivery_type: rental.deliveryType,
      delivery_address_location_name: rental.deliveryAddressLocationName,
      delivery_address_street_address_1: rental.deliveryAddressStreetAddress1,
      delivery_address_street_address_2: rental.deliveryAddressStreetAddress2,
      delivery_address_city: rental.deliveryAddressCity,
      delivery_address_locale: rental.deliveryAddressLocale,
      delivery_address_postal_code: rental.deliveryAddressPostalCode,
      delivery_address_country: rental.deliveryAddressCountry,
    },
    pickup: {
      pickup_same_as_delivery: rental.pickupSameAsDelivery,
      pickup_address_location_name: rental.pickupAddressLocationName,
      pickup_address_street_address_1: rental.pickupAddressStreetAddress1,
      pickup_address_street_address_2: rental.pickupAddressStreetAddress2,
      pickup_address_city: rental.pickupAddressCity,
      pickup_address_locale: rental.pickupAddressLocale,
      pickup_address_postal_code: rental.pickupAddressPostalCode,
      pickup_address_country: rental.pickupAddressCountry,
    },

    fees: rental.feeRentalRelationships,
    pickListDamageFees: rental.pickListDamageFees || [],
    pickListAccessoryFees: rental.pickListAccessoryFees || [],
    taxableFees,
    creditCardFeeTotal: rental.creditCardFeeTotal,
    creditCardFees,
    discounts: rental.discountRentalRelationships,
    payments: rental.payments,
    refunds: rental.refunds,
    referralSource: rental.referralSource,
    feedbacks: rental.feedbacks,
    notes: rental.allNotes,
    hasFlip: rental.hasFlip,
    flipDateTime: rental.flipDateTime,
    flipNotes: rental.flipNotes,
    hasSetByTime: rental.hasSetByTime,
    setByTimeDateTime: rental.setByTimeDateTime,
    setByTimeNotes: rental.setByTimeNotes,
    hasStrikeTime: rental.hasStrikeTime,
    strikeDateTime: rental.strikeDateTime,
    strikeTimeNotes: rental.strikeTimeNotes,
    status: rental.status,
    cancelRequested: rental.cancelRequested,
    cancellationStatus: rental.cancellationStatus,
    customers: rental.customers,
    customerRentalRelationships: rental.customerRentalRelationships,
    customerLocationRelationships: rental.customerRentalRelationships
      ? rental.customerRentalRelationships.map((cr) => cr.client)
      : [],
    items: rental.rentalItems,
    accessories: rental.rentalAccessories,
    addons: rental.rentalAddOns,
    bundles: rental.rentalBundles,
    staffs: rental.rentalStaffs,
    sections: rental.rentalSections,
    rentalStaffMembers: rentalStaffMembers,
    rentalStaffs: rental.rentalStaffs,
    rentalTransports: rental.rentalTransports,
    paymentTerm: rental.paymentTerm,
    paymentProcessor: rental.paymentProcessor,
    teamMembers: rental.teamMembers,
    venue: rental.venueRentalRelationship?.venue,
    company: rental.companyRentalRelationship?.company,
    companyRentalRelationship: rental.companyRentalRelationship,
    billing: {
      rental_item_total: rental.rentalItemTotal,
      rental_item_total_before_discount: rental.rentalItemTotalBeforeDiscount,
      rental_add_on_total: rental.rentalAddOnTotal,
      rental_bundle_total: rental.rentalBundleTotal,
      rental_bundle_total_after_discount: rental.rentalBundleTotalAfterDiscount,
      staffing_total: rental.staffingTotal,
      delivery_cost: rental.deliveryCost,
      total_delivery_cost: rental.totalDeliveryCost,
      discount_total: rental.discountTotal,
      expenseTotal: rental.expenseTotal,
      fee_total: rental.feeTotal,
      sub_total: rental.subTotal,
      subTotalForTax: rental.subTotalForTax,
      tax_total: rental.taxTotal,
      total_custom_tax: rental.totalCustomTax,
      overall_total: rental.overallTotal,
      tapgoods_fee_total: rental.tapgoodsFeeTotal,
      tapgoodsTaxTotal: rental.tapgoodsTaxTotal,
      amount_paid: rental.totalPaid,
      amount_remaining: rental.amountRemaining,
      processingFeeRefunded: rental.processingFeeRefunded,
      tapgoodsFeeRefunded: rental.tapgoodsFeeRefunded,
      tapgoodsTaxRefunded: rental.tapgoodsTaxRefunded,
    },
    processingFeeTotal: rental.processingFeeTotal,
    billingStatus: rental.billingStatus,
    pickedStatus: rental.pickedStatus,
    checkedInStatus: rental.checkedInStatus,
    checkedInAlerting: rental.checkedInAlerting,
    maxCreditCardRefund: rental.maxCreditCardRefund,
    billingNotes: rental.billingNotes,
    documents: rental.documents,
    documentRentalRelationships: rental.documentRentalRelationships,
    disclaimerRentalRelationships: rental.disclaimerRentalRelationships,
    itemDisclaimers: rental.itemDisclaimers,
    accessoryDisclaimers: rental.accessoryDisclaimers,
    addOnDisclaimers: rental.addOnDisclaimers,
    bundleDisclaimers: rental.bundleDisclaimers,
    supplierRentals: rental.supplierRentals,
    changeRequest: rental.changeRequest,
    changeRequests: rental.changeRequests || [],
    changeRequestStatus: rental.changeRequestStatus,
    approvalStatus: rental.approvalStatus,
    conversations: rental.conversations,
    tenantRentalId: rental.tenantRentalId,
    expenses: rental.expenses,
    salesTaxes: rental.salesTaxes,
    rentalSetting: rental.rentalSetting,
    lastUndidStatus: rental.lastUndidStatus,
    autoBooked: rental.autoBooked,
    preferredDeliveryWindow: rental.preferredDeliveryWindow,
    preferredPickupWindow: rental.preferredPickupWindow,
    customerContactPhone: rental.customerContactPhone,
    checkInSignature: rental.checkinSignature,
    checkInSignerName: rental.checkinSignerName,
    checkInSignerTitle: rental.checkinSignerTitle,
    checkInSignerCompany: rental.checkinSignerCompany,
    checkInSignatureDatetime: rental.checkinDatetime,
    checkOutSignature: rental.checkoutSignature,
    checkOutSignerName: rental.checkoutSignerName,
    checkOutSignerTitle: rental.checkoutSignerTitle,
    checkOutSignerCompany: rental.checkoutSignerCompany,
    businessCheckInSignature: rental.businessCheckinSignature,
    businessCheckInSignerName: rental.businessCheckinSignerName,
    businessCheckInSignerTitle: rental.businessCheckinSignerTitle,
    businessCheckInSignerCompany: rental.businessCheckinSignerCompany,
    businessCheckInSignatureDatetime: rental.businessCheckinDatetime,
    businessCheckOutSignature: rental.businessCheckoutSignature,
    businessCheckOutSignerName: rental.businessCheckoutSignerName,
    businessCheckOutSignerTitle: rental.businessCheckoutSignerTitle,
    businessCheckOutSignerCompany: rental.businessCheckoutSignerCompany,
    documentExpired: rental.documentExpired,
    itemFees: rental.itemFees || {},
    inventoryDiscounts: rental.inventoryDiscounts || {},
    additionalOrderInfo: rental.additionalOrderInfo,
    additionalDeliveryInfo: rental.additionalDeliveryInfo,
    damageWaiverExempt: rental.damageWaiverExempt,
    isRecurringPayment: rental.isRecurringPayment,
    recurringTransactionInfo: rental.recurringTransactionInfo,
    recurringPaymentDetails: rental.recurringPaymentDetails,
    gstTaxExempt: rental.gstTaxExempt,
    pstTaxExempt: rental.pstTaxExempt,
    unreadMessages: rental.conversations
      ? rental.conversations.reduce(
          (sum, conversation) =>
            (sum += parseInt(conversation.unreadMessages, 10)),
          0
        )
      : 0,
    carrierType: rental.carrierType,
    deliveryTrackingNumber: rental.deliveryTrackingNumber,
    returnTrackingNumber: rental.returnTrackingNumber,
    smartPricingActive: rental.smartPricingActive,
    deliveryTickets: rental.deliveryTickets,
    activeRentalInventoryJob: rental.activeRentalInventoryJob,
    hasAuthorizationHold: rental.hasAuthorizationHold,
    // quickbooksConnections: rental.quickbooksConnections,
    // qbPeriodClosedError: rental.qbPeriodClosedError,
    secondaryOrders: rental.secondaryOrders,
    rentalVersioningEnabled: rental.rentalVersioningEnabled,
    finalPaymentDueDateType: rental.finalPaymentDueDateType,
    event: rental.event ?? null,
    eventId: rental.eventId,
  };
};

const getPickListItem = (rental, state) => {
  const {
    pickListItems,
    pickListAccessories,
    pickListAddOns,
    pickListDamageFees,
    pickListAccessoryFees,
  } = rental;

  return {
    ...state.rental,
    pickListItems: pickListItems,
    pickListAccessories: pickListAccessories,
    pickListAddOns: pickListAddOns,
    pickListDamageFees: pickListDamageFees,
    pickListAccessoryFees: pickListAccessoryFees,
    pickedStatus: rental.pickedStatus,
    checkedInStatus: rental.checkedInStatus,
  };
};

const updatePickListData = (payload, state) => {
  const { rental, inventoryType } = payload;
  let keyName = '';
  if (inventoryType === 'items') {
    keyName = 'pickListItem';
  } else if (inventoryType === 'accessories') {
    keyName = 'pickListAccessory';
  } else if (inventoryType === 'addOns') {
    keyName = 'pickListAddOn';
  }
  let pluralizedName =
    inventoryType === 'accessories' ? 'pickListAccessories' : `${keyName}s`;
  let newPickListData = state.rental[pluralizedName];
  let updateableData = rental[keyName];
  for (var i in newPickListData) {
    if (newPickListData[i].id == updateableData.id) {
      newPickListData[i] = updateableData;
      break;
    }
  }

  return {
    ...state.rental,
    [pluralizedName]: newPickListData,
    pickedStatus: updateableData.pickedStatus,
    checkedInStatus: updateableData.checkedInStatus,
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case PICK_RENTAL_ITEM_SUCCESS:
    case UNPICK_RENTAL_ITEM_SUCCESS:
    case CHECK_IN_RENTAL_ITEM_SUCCESS:
    case UNCHECK_IN_RENTAL_ITEM_SUCCESS:
      return {
        ...state,
        rental: updatePickListData(action.payload, state),
        loading: false,
        shouldRefresh: false,
      };
    case BULK_UNCHECK_IN_RENTAL_ITEMS_SUCCESS:
    case BULK_PICK_RENTAL_ITEMS_SUCCESS:
    case BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS:
    case SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS:
    case SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS:
      return {
        ...state,
        rental: getPickListItem(action.payload.rental, state),
        loading: false,
        shouldRefresh: false,
      };
    case REPORT_LACK_RENTAL_ITEM_SUCCESS:
    case ADD_FEE_RENTAL_ITEM_SUCCESS:
      return {
        ...state,
        rental: getPickListItem(action.response.data.rental, state),
        loading: false,
        shouldRefresh: false,
      };
    case SET_MANIPULATED_PICK_LIST_ITEMS:
      return {
        ...state,
        manipulatedPickListItems: action.payload,
      };
    case CANCEL_RENTAL_REQUEST:
    case DELETE_RENTAL_REQUEST:
    case CLOSE_RENTAL_REQUEST:
    case RESERVE_RENTAL_REQUEST:
    case CHECK_OUT_RENTAL_REQUEST:
    case CHECK_IN_RENTAL_REQUEST:
    case BULK_PICK_RENTAL_ITEMS_REQUEST:
    case SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST:
    case PICK_RENTAL_ITEM_REQUEST:
    case UNPICK_RENTAL_ITEM_REQUEST:
    case BULK_CHECK_IN_RENTAL_ITEMS_REQUEST:
    case SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST:
    case BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST:
    case CHECK_IN_RENTAL_ITEM_REQUEST:
    case UNCHECK_IN_RENTAL_ITEM_REQUEST:
    case REPORT_LACK_RENTAL_ITEM_REQUEST:
    case ADD_FEE_RENTAL_ITEM_REQUEST:
    case UPDATE_RENTAL_ITEM_REQUEST:
    case UPDATE_RENTAL_REQUEST:
    case CONNECT_RENTAL_REQUEST:
    case SUBMIT_QUOTE_REQUEST:
    case REQUEST_CHANGES_REQUEST:
    case REQUEST_CANCEL_REQUEST:
    case UPDATE_SUPPLIER_RENTAL_REQUEST:
    case FETCH_RENTAL_PICKLIST_REQUEST:
    case CHECKOUT_DELIVERY_TICKET_REQUEST:
    case CHECKIN_DELIVERY_TICKET_REQUEST:
    case CLOSE_DELIVERY_TICKET_REQUEST:
      return { ...state, loading: true };
    case FETCH_RENTAL_REQUEST:
      if (action.background) {
        return {
          ...state,
          loading: false,
          shouldRefresh: false,
        };
      } else {
        return {
          ...INITIAL_STATE,
          loading: true,
          shouldRefresh: false,
        };
      }
    case FETCH_RENTAL_INFO_REQUEST:
      return {
        ...state,
        loading: false,
        shouldRefresh: false,
      };

    case RESERVE_RENTAL_FAILURE:
    case CANCEL_RENTAL_FAILURE:
    case DELETE_RENTAL_FAILURE:
    case DELETE_RENTAL_SUCCESS:
    case FETCH_RENTAL_FAILURE:
    case FETCH_RENTAL_INFO_FAILURE:
    case UPDATE_RENTAL_FAILURE:
    case CLOSE_RENTAL_FAILURE:
    case CHECK_OUT_RENTAL_FAILURE:
    case CHECK_IN_RENTAL_FAILURE:
    case BULK_PICK_RENTAL_ITEMS_FAILURE:
    case PICK_RENTAL_ITEM_FAILURE:
    case UNPICK_RENTAL_ITEM_FAILURE:
    case BULK_CHECK_IN_RENTAL_ITEMS_FAILURE:
    case SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE:
    case BULK_UNCHECK_IN_RENTAL_ITEMS_FAILURE:
    case CHECK_IN_RENTAL_ITEM_FAILURE:
    case UNCHECK_IN_RENTAL_ITEM_FAILURE:
    case REPORT_LACK_RENTAL_ITEM_FAILURE:
    case ADD_FEE_RENTAL_ITEM_FAILURE:
    case UPDATE_RENTAL_ITEM_FAILURE:
    case CONNECT_RENTAL_FAILURE:
    case SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE:
    case FETCH_RENTAL_PICKLIST_FAILURE:
      return { ...state, loading: false, isInfiniteLoading: false };
    case RESERVE_RENTAL_SUCCESS:
    case CHECK_OUT_RENTAL_SUCCESS:
      const rentalData = convertRentalToState(action.payload.rental);
      const newRentalObject = { ...state.rental, ...rentalData };
      return {
        ...state,
        rental: newRentalObject,
        loading: false,
        shouldRefresh: false,
      };
    case CANCEL_RENTAL_SUCCESS:
    case FETCH_RENTAL_SUCCESS:
    case UPDATE_RENTAL_SUCCESS:
    case CHECK_IN_RENTAL_SUCCESS:
    case CLOSE_RENTAL_SUCCESS:
    case UPDATE_RENTAL_ITEM_SUCCESS:
    case CONNECT_RENTAL_SUCCESS:
      return produce(state, (draft) => {
        // ! Currently, the API responses and the rental state have different fields.
        // TODO: Use same interface for both.
        const rentalData = action.rental || action.response?.data?.rental;

        const newData =
          action.bypassRentalConversion || !rentalData
            ? rentalData
            : convertRentalToState(rentalData);

        const newRentalObj = { ...state.rental, ...newData };

        draft.rental = newRentalObj;
        draft.loading = false;
        draft.shouldRefresh = false;
        draft.fetchRentalDone = true;
      });
    case FETCH_RENTAL_PICKLIST_SUCCESS:
      return {
        ...state,
        rental: convertRentalPickListToState(
          state.rental,
          action.response.data.rental
        ),
        loading: false,
        shouldRefresh: true,
      };
    case FETCH_RENTAL_INFO_SUCCESS:
      const { infoName, response } = action;
      const newRental =
        infoName === 'billing_info'
          ? convertRentalToBillingInfo(response.data.rental)
          : response.data.rental;
      return {
        ...state,
        rental: {
          ...state.rental,
          ...newRental,
        },
        loading: false,
        shouldRefresh: false,
        ...(infoName === 'billing_info' && { fetchBillingInfoDone: true }),
      };
    case REPOSITION_INVENTORY_SUCCESS:
      return {
        ...state,
        loading: false,
        shouldRefresh: false,
      };

    case REFRESH_RENTAL:
      return {
        ...state,
        shouldRefresh: true,
      };
    case SET_SUBRENTAL_ITEM:
      return {
        ...state,
        subrentalItemId: action.payload.subrentalItemId,
      };
    case ADD_RENTAL_UNREAD_MESSAGE:
      let newConversations = state.rental.conversations;

      if (!action.payload.status && action.payload !== 'new unread message') {
        newConversations = state.rental.conversations.map((conversation) => {
          const { id, unreadMessages } = conversation;
          const { conversationId } = action.payload.message;

          if (id !== conversationId) {
            return conversation;
          }

          return {
            ...conversation,
            messages: [...conversation.messages, action.payload.message],
            unreadMessages:
              action.payload.message.senderId !== action.currentUser.id
                ? unreadMessages + 1
                : unreadMessages,
          };
        });
      }

      return {
        ...state,
        rental: {
          ...state.rental,
          conversations: newConversations,
        },
      };
    case SET_CHECK_IN_SIGNATURE:
      return {
        ...state,
        checkInSignature: action.payload.signature,
        checkInSignerName: action.payload.signerName,
        checkInSignerTitle: action.payload.signerTitle,
        checkInSignerCompany: action.payload.signerCompany,
      };
    case SET_CHECK_OUT_SIGNATURE:
      return {
        ...state,
        checkOutSignature: action.payload.signature,
        checkOutSignerName: action.payload.signerName,
        checkOutSignerTitle: action.payload.signerTitle,
        checkOutSignerCompany: action.payload.signerCompany,
      };
    case SET_RENTAL_PROPS:
      return {
        ...state,
        rental: {
          ...state.rental,
          ...action.payload,
        },
      };
    case FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS:
    case CHECKOUT_DELIVERY_TICKET_SUCCESS:
      return {
        ...state,
        rental: {
          ...state.rental,
          deliveryTickets: action.response.deliveryTickets.map((dt) =>
            convertDeliveryTicketToState(dt, state.rental)
          ),
        },
        selectedDeliveryTicket: convertDeliveryTicketToState(
          action.response.deliveryTickets.find(
            (dt) => dt.id === +state.selectedDeliveryTicketId
          ),
          state.rental
        ),
      };
    case CHECKIN_DELIVERY_TICKET_SUCCESS:
    case CLOSE_DELIVERY_TICKET_SUCCESS:
      return {
        ...state,
        loading: true,
        rental: {
          ...state.rental,
          deliveryTickets: action.response.deliveryTickets.map((dt) =>
            convertDeliveryTicketToState(dt, state.rental)
          ),
        },
        selectedDeliveryTicket: convertDeliveryTicketToState(
          action.response.deliveryTickets.find(
            (dt) => dt.id === +state.selectedDeliveryTicketId
          ),
          state.rental
        ),
      };
    case FETCH_DELIVERY_TICKETS_REQUEST_FAILURE:
    case CHECKOUT_DELIVERY_TICKET_FAILURE:
    case CHECKIN_DELIVERY_TICKET_FAILURE:
    case CLOSE_DELIVERY_TICKET_FAILURE:
      return {
        ...state,
        loading: false,
      };

    case FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS:
      return {
        ...state,
        loading: true,
        unassignedPickList: {
          items: action.response.pickListItems,
          accessories: action.response.pickListAccessories,
          addOns: action.response.pickListAddOns,
        },
      };
    case FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE:
      return {
        ...state,
        loading: false,
        unassignedPickList: {
          items: [],
          accessories: [],
          addOns: [],
        },
      };

    case SET_SELECTED_DT_ID:
      return {
        ...state,
        selectedDeliveryTicketId: action.selectedDeliveryTicketId,
        selectedDeliveryTicket: convertDeliveryTicketToState(
          state.rental.deliveryTickets.find(
            (dt) => dt.id === +action.selectedDeliveryTicketId
          ),
          state.rental
        ),
      };

    case SET_SELECTED_DELIVERY_TICKET_SUCCESS:
      return {
        ...state,
        selectedDeliveryTicket: convertDeliveryTicketToState(
          action.response.deliveryTicket,
          state.rental
        ),
      };
    case SET_SELECTED_DELIVERY_TICKET_FAILURE:
      return {
        ...state,
        selectedDeliveryTicket: {},
      };
    default:
      return state;
  }
};

export const SEARCH_INVENTORY = 'SEARCH_INVENTORY';
export const SET_ERRORS = 'SET_ERRORS';
export const SET_ALERT = 'SET_ALERT';
export const SET_SELECTED_DATE = 'SET_SELECTED_DATE';
export const RESET_SELECTED_DATE = 'RESET_SELECTED_DATE';
export const FETCH_DATES_ARRAY_SUCCESS = 'FETCH_DATES_ARRAY_SUCCESS';
export const FETCH_DATES_ARRAY = 'FETCH_DATES_ARRAY';
export const FETCH_DATES_ARRAY_FAILURE = 'FETCH_DATES_ARRAY_FAILURE';
export const CHANGE_MOBILE_VIEW_CALENDAR_STATE =
  'CHANGE_MOBILE_VIEW_CALENDAR_STATE';
export const SET_SECONDARY_DATES_ARRAY = 'SET_SECONDARY_DATES_ARRAY';

export const REQUEST_CATEGORIES = 'REQUEST_CATEGORIES';
export const RECEIVE_CATEGORIES = 'RECEIVE_CATEGORIES';
export const STORE_SEARCH_CANCEL_TOKEN = 'STORE_SEARCH_CANCEL_TOKEN';

export const SET_MULTILOCATION_ALL_LOCATIONS =
  'SET_MULTILOCATION_ALL_LOCATIONS';

// Spinner
export const OPEN_LOADING_SPINNER = 'OPEN_LOADING_SPINNER';
export const CLOSE_LOADING_SPINNER = 'CLOSE_LOADING_SPINNER';

// Snackbar
export const OPEN_SUCCESS_SNACKBAR = 'OPEN_SUCCESS_SNACKBAR';
export const CLOSE_SUCCESS_SNACKBAR = 'CLOSE_SUCCESS_SNACKBAR';
export const SET_OPEN_VALIDATION_SNACKBAR = 'SET_OPEN_VALIDATION_SNACKBAR';
export const SET_CLOSED_VALIDATION_SNACKBAR = 'SET_CLOSED_VALIDATION_SNACKBAR';

// Staff
//// Member
export const FETCH_STAFF_MEMBER_NAMES_REQUEST =
  'FETCH_STAFF_MEMBER_NAMES_REQUEST';
export const FETCH_STAFF_MEMBER_NAMES_SUCCESS =
  'FETCH_STAFF_MEMBER_NAMES_SUCCESS';
export const ADD_STAFF_MEMBER_NAMES_REQUEST = 'ADD_STAFF_MEMBER_NAMES_REQUEST';
export const ADD_STAFF_MEMBER_NAMES_SUCCESS = 'ADD_STAFF_MEMBER_NAMES_SUCCESS';
export const SET_STAFF_MEMBER_QUERY = 'SET_STAFF_MEMBER_QUERY';

//// Role
export const FETCH_STAFF_ROLE_NAMES_REQUEST = 'FETCH_STAFF_ROLE_NAMES_REQUEST';
export const FETCH_STAFF_ROLE_NAMES_SUCCESS = 'FETCH_STAFF_ROLE_NAMES_SUCCESS';
export const ADD_STAFF_ROLE_NAMES_REQUEST = 'ADD_STAFF_ROLE_NAMES_REQUEST';
export const ADD_STAFF_ROLE_NAMES_SUCCESS = 'ADD_STAFF_ROLE_NAMES_SUCCESS';
export const SET_STAFF_ROLE_QUERY = 'SET_STAFF_ROLE_QUERY';
export const SET_STAFF_ROLE_ATTRIBUTES = 'SET_STAFF_ROLE_ATTRIBUTES';
export const SET_STAFF_ROLE_PAGINATION = 'SET_STAFF_ROLE_PAGINATION';
export const SET_STAFF_ROLE_PAGINATION_PAGE = 'SET_STAFF_ROLE_PAGINATION_PAGE';

export const FETCH_STAFF_ROLE_REQUEST = 'FETCH_STAFF_ROLE_REQUEST';
export const FETCH_STAFF_ROLE_SUCCESS = 'FETCH_STAFF_ROLE_SUCCESS';
export const FETCH_STAFF_ROLE_FAILURE = 'FETCH_STAFF_ROLE_FAILURE';

//// Recurrences
export const FETCH_RECURRENCES_REQUEST = 'FETCH_RECURRENCES_REQUEST';
export const FETCH_RECURRENCES_SUCCESS = 'FETCH_RECURRENCES_SUCCESS';
export const ADD_RECURRENCE_REQUEST = 'ADD_RECURRENCE_REQUEST';
export const ADD_RECURRENCE_SUCCESS = 'ADD_RECURRENCE_SUCCESS';
export const ADD_RECURRENCE_FAILURE = 'ADD_RECURRENCE_FAILURE';
export const SET_RECURRENCES_QUERY = 'SET_RECURRENCES_QUERY';
export const SET_RECURRENCES_PAGINATION = 'SET_RECURRENCES_PAGINATION';
export const SET_RECURRENCES_PAGINATION_PAGE =
  'SET_RECURRENCES_PAGINATION_PAGE';
export const FETCH_RECURRENCES_FAILURE = 'FETCH_RECURRENCES_FAILURE';
export const FETCH_RECURRENCE_SUCCESS = 'FETCH_RECURRENCE_SUCCESS';

// Routing
//// RentalTransportList
export const FETCH_RENTAL_TRANSPORT_LIST_REQUEST =
  'FETCH_RENTAL_TRANSPORT_LIST_REQUEST';
export const FETCH_RENTAL_TRANSPORT_LIST_SUCCESS =
  'FETCH_RENTAL_TRANSPORT_LIST_SUCCESS';
export const SET_RENTAL_TRANSPORT_FILTER = 'SET_RENTAL_TRANSPORT_FILTER';
export const SHOW_OVERLAPPED_RENTAL_ON_MAP = 'SHOW_OVERLAPPED_RENTAL_ON_MAP';
export const CLOSE_OVERLAPPED_RENTAL_ON_MAP = 'CLOSE_OVERLAPPED_RENTAL_ON_MAP';
export const RESET_TRANSPORT_LIST_FILTERS = 'RESET_TRANSPORT_LIST_FILTERS';
//// TruckRouteList
export const FETCH_TRUCK_ROUTE_LIST_REQUEST = 'FETCH_TRUCK_ROUTE_LIST_REQUEST';
export const FETCH_ORDER_ROUTE_LIST_REQUEST = 'FETCH_ORDER_ROUTE_LIST_REQUEST';
export const FETCH_TRUCK_ROUTE_LIST_SUCCESS = 'FETCH_TRUCK_ROUTE_LIST_SUCCESS';
export const FETCH_ORDER_ROUTE_LIST_SUCCESS = 'FETCH_ORDER_ROUTE_LIST_SUCCESS';
export const FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST =
  'FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST';
export const FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST =
  'FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST';
export const FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS =
  'FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS';

export const FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS =
  'FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS';

//// TruckRoute
export const FETCH_TRUCK_ROUTE_REQUEST = 'FETCH_TRUCK_ROUTE_REQUEST';
export const FETCH_TRUCK_ROUTE_SUCCESS = 'FETCH_TRUCK_ROUTE_SUCCESS';
export const FETCH_TRUCK_ROUTE_FAILURE = 'FETCH_TRUCK_ROUTE_FAILURE';
export const UPDATE_TRUCK_ROUTE_REQUEST = 'UPDATE_TRUCK_ROUTE_REQUEST';
export const ADDED_WAYPOINTS_TO_TRUCK_ROUTE = 'ADDED_WAYPOINTS_TO_TRUCK_ROUTE';
export const RESET_ERROR_ATTRIBUTE = 'RESET_ERROR_ATTRIBUTE';

//// RoutingRental
export const FETCH_RENTAL_TRANSPORT_REQUEST = 'FETCH_RENTAL_TRANSPORT_REQUEST';
export const FETCH_RENTAL_TRANSPORT_SUCCESS = 'FETCH_RENTAL_TRANSPORT_SUCCESS';
export const FETCH_RENTAL_TRANSPORT_FAILURE = 'FETCH_RENTAL_TRANSPORT_FAILURE';
export const ASSIGN_TRUCK_REQUEST = 'ASSIGN_TRUCK_REQUEST';
export const UNASSIGN_TRUCK_REQUEST = 'UNASSIGN_TRUCK_REQUEST';

//// TruckList
export const REQUEST_TRUCK_NAMES = 'REQUEST_TRUCK_NAMES';
export const RECEIVE_TRUCK_NAMES = 'RECEIVE_TRUCK_NAMES';
export const SET_TRUCK_NAMES_FILTER = 'SET_TRUCK_NAMES_FILTER';
export const SET_TRUCK_FILTERED_NAMES = 'SET_TRUCK_FILTERED_NAMES';

//// RoutingSchedule
export const FETCH_ROUTING_SCHEDULE_REQUEST = 'FETCH_ROUTING_SCHEDULE_REQUEST';
export const FETCH_ROUTING_SCHEDULE_SUCCESS = 'FETCH_ROUTING_SCHEDULE_SUCCESS';
export const SET_ROUTING_SCHEDULE_FILTER = 'SET_ROUTING_SCHEDULE_FILTER';
export const RESET_ROUTING_FILTERS = 'RESET_ROUTING_FILTERS';
// Contacts
export const SET_CONTACT_TYPE = 'SET_CONTACT_TYPE';
// Venues
export const SET_VENUE_QUERY = 'SET_VENUE_QUERY';
export const REQUEST_VENUES = 'REQUEST_VENUES';
export const REQUEST_VENUE = 'REQUEST_VENUES';
export const REQUEST_INFINITE_VENUES = 'REQUEST_INFINITE_VENUES';
export const REQUEST_VENUE_NAMES = 'REQUEST_VENUE_NAMES';
export const INFINITE_VENUES_SUCCESS = 'INFINITE_VENUES_SUCCESS';
export const INFINITE_VENUES_FAILURE = 'INFINITE_VENUES_FAILURE';
export const RECEIVE_VENUES = 'RECEIVE_VENUES';
export const RECEIVE_VENUE = 'RECEIVE_VENUE';
export const RECEIVE_VENUE_NAMES = 'RECEIVE_VENUE_NAMES';
export const SEARCH_VENUE_NAMES = 'SEARCH_VENUE_NAMES';
export const ADD_INFINITE_VENUES_SUCCESS = 'ADD_INFINITE_VENUES_SUCCESS';
export const ADD_VENUE_NAMES_REQUEST = 'ADD_VENUE_NAMES_REQUEST';
export const ADD_VENUE_NAMES_SUCCESS = 'ADD_VENUE_NAMES_SUCCESS';
export const UPDATE_VENUE_NAME_ON_LIST = 'UPDATE_VENUE_NAME_ON_LIST';
export const SET_VENUE_FILTER = 'SET_VENUE_FILTER';
export const SET_VENUE_SELECTED_IDS_FOR_EXPORT =
  'SET_VENUE_SELECTED_IDS_FOR_EXPORT';

// Companies
export const SET_COMPANY_QUERY = 'SET_COMPANY_QUERY';
export const REQUEST_COMPANIES = 'REQUEST_COMPANIES';
export const REQUEST_COMPANY = 'REQUEST_COMPANIES';
export const REQUEST_INFINITE_COMPANIES = 'REQUEST_INFINITE_COMPANIES';
export const REQUEST_COMPANY_NAMES = 'REQUEST_COMPANY_NAMES';
export const INFINITE_COMPANIES_SUCCESS = 'INFINITE_COMPANIES_SUCCESS';
export const INFINITE_COMPANIES_FAILURE = 'INFINITE_COMPANIES_FAILURE';
export const RECEIVE_COMPANIES = 'RECEIVE_COMPANIES';
export const RECEIVE_COMPANY = 'RECEIVE_COMPANY';
export const RECEIVE_COMPANY_NAMES = 'RECEIVE_COMPANY_NAMES';
export const SEARCH_COMPANY_NAMES = 'SEARCH_COMPANY_NAMES';
export const ADD_INFINITE_COMPANIES_SUCCESS = 'ADD_INFINITE_COMPANIES_SUCCESS';
export const ADD_COMPANY_NAMES_REQUEST = 'ADD_COMPANY_NAMES_REQUEST';
export const ADD_COMPANY_NAMES_SUCCESS = 'ADD_COMPANY_NAMES_SUCCESS';
export const UPDATE_COMPANY_NAME_ON_LIST = 'UPDATE_COMPANY_NAME_ON_LIST';
export const SET_COMPANY_FILTER = 'SET_COMPANY_FILTER';
export const SET_COMPANY_SELECTED_IDS_FOR_EXPORT =
  'SET_COMPANY_SELECTED_IDS_FOR_EXPORT';

// Customers
export const REQUEST_CUSTOMERS = 'REQUEST_CUSTOMERS';
export const REQUEST_CUSTOMER_NAMES = 'REQUEST_CUSTOMER_NAMES';
export const RECEIVE_CUSTOMERS = 'RECEIVE_CUSTOMERS';
export const REQUEST_CUSTOMER = 'REQUEST_CUSTOMER';
export const RECEIVE_CUSTOMER = 'RECEIVE_CUSTOMER';
export const RECEIVE_CUSTOMER_NAMES = 'RECEIVE_CUSTOMER_NAMES';
export const ADD_CUSTOMER_NAMES_REQUEST = 'ADD_CUSTOMER_NAMES_REQUEST';
export const ADD_CUSTOMER_NAMES_SUCCESS = 'ADD_CUSTOMER_NAMES_SUCCESS';
export const UPDATE_CUSTOMER_NAME_ON_LIST = 'UPDATE_CUSTOMER_NAME_ON_LIST';
export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const SET_CUSTOMER_TYPE = 'SET_CUSTOMER_TYPE';
export const REQUEST_INFINITE_CUSTOMERS = 'REQUEST_INFINITE_CUSTOMERS';
export const INFINITE_CUSTOMERS_SUCCESS = 'INFINITE_CUSTOMERS_SUCCESS';
export const ADD_INFINITE_CUSTOMERS_SUCCESS = 'ADD_INFINITE_CUSTOMERS_SUCCESS';
export const INFINITE_CUSTOMERS_FAILURE = 'INFINITE_CUSTOMERS_FAILURE';
export const SET_CUSTOMER_QUERY = 'SET_CUSTOMER_QUERY';
export const SET_CUSTOMER_FILTER = 'SET_CUSTOMER_FILTER';
export const SET_CUSTOMER_SELECTED_IDS_FOR_EXPORT =
  'SET_CUSTOMER_SELECTED_IDS_FOR_EXPORT';

// BusinessList
export const FETCH_CONNECT_LOCATIONS_REQUEST =
  'FETCH_CONNECT_LOCATIONS_REQUEST';
export const FETCH_CONNECT_LOCATIONS_SUCCESS =
  'FETCH_CONNECT_LOCATIONS_SUCCESS';
export const FETCH_CONNECT_LOCATIONS_FAILURE =
  'FETCH_CONNECT_LOCATIONS_FAILURE';

export const FIELD_CHANGED = 'field_changed';
export const REVIEW_USER_SUCCESS = 'review_user_success';
export const REVIEW_BUSINESS_SUCCESS = 'review_business_success';
export const LOGIN_USER_SUCCESS = 'login_user_success';
export const LOGIN_USER_FAIL = 'login_user_fail';
export const CREATE_USER_FAIL = 'create_user_fail';
export const LOGIN_USER = 'login_user';
export const SET_NEW_USER = 'SET_NEW_USER';
export const SET_NEW_BUSINESS = 'SET_NEW_BUSINESS';
export const ADD_LOCATION_TO_USER = 'ADD_LOCATION_TO_USER';
export const ADMIN_FIELD_CHANGED = 'admin_field_changed';
export const LOGIN_ADMIN_SUCCESS = 'login_admin_success';
export const LOGIN_ADMIN_FAIL = 'login_admin_fail';
export const CREATE_ADMIN_FAIL = 'create_admin_fail';
export const LOGIN_ADMIN = 'login_admin';
export const SIGN_OUT_USER_SUCCESS = 'sign_out_user_success';
export const SIGN_IN_ON_OTHER_DEVICE = 'SIGN_IN_ON_OTHER_DEVICE';
export const SIGN_IN_ON_OTHER_TAB = 'SIGN_IN_ON_OTHER_TAB';
export const CLEAR_SIGNED_IN_ON_OTHER_DEVICE =
  'CLEAR_SIGNED_IN_ON_OTHER_DEVICE';
export const LISTENING_FOR_OTHER_SIGN_IN = 'LISTENING_FOR_OTHER_SIGN_IN';
export const SIGN_OUT_USER_FAIL = 'sign_out_user_fail';
export const SIGN_OUT_USER = 'sign_out_user';
export const SIGN_OUT_ADMIN_SUCCESS = 'sign_out_admin_success';
export const SIGN_OUT_ADMIN_FAIL = 'sign_out_admin_fail';
export const SIGN_OUT_ADMIN = 'sign_out_admin';
export const ADMIN_LOGIN_USER = 'ADMIN_LOGIN_USER';
export const SET_ERROR_MESSAGE = 'SET_ERROR_MESSAGE';
export const VALIDATE_USER = 'VALIDATE_USER';
export const VALIDATE_USER_SUCCESS = 'VALIDATE_USER_SUCCESS';
export const VALIDATE_USER_FAIL = 'VALIDATE_USER_FAIL';
export const VALIDATE_ADMIN = 'VALIDATE_ADMIN';
export const VALIDATE_ADMIN_SUCCESS = 'VALIDATE_ADMIN_SUCCESS';
export const VALIDATE_ADMIN_FAIL = 'VALIDATE_ADMIN_FAIL';
export const FETCH_ADMIN_BUSINESSES_REQUEST = 'FETCH_ADMIN_BUSINESSES_REQUEST';
export const FETCH_ADMIN_BUSINESSES_SUCCESS = 'FETCH_ADMIN_BUSINESSES_SUCCESS';
export const FETCH_ADMIN_BUSINESSES_FAILURE = 'FETCH_ADMIN_BUSINESSES_FAILURE';
export const BULK_UPDATE_ADMIN_BUSINESSES_REQUEST =
  'BULK_UPDATE_ADMIN_BUSINESSES_REQUEST';
export const BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS =
  'BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS';
export const BULK_UPDATE_ADMIN_BUSINESSES_FAILURE =
  'BULK_UPDATE_ADMIN_BUSINESSES_FAILURE';
export const FETCH_ADMIN_PAYMENTS_REPORT_REQUEST =
  'FETCH_ADMIN_PAYMENTS_REPORT_REQUEST';
export const FETCH_ADMIN_PAYMENTS_REPORT_SUCCESS =
  'FETCH_ADMIN_PAYMENTS_REPORT_SUCCESS';
export const FETCH_ADMIN_PAYMENTS_REPORT_FAILURE =
  'FETCH_ADMIN_PAYMENTS_REPORT_FAILURE';
export const FETCH_ADMIN_MARKETPLACE_REPORT_REQUEST =
  'FETCH_ADMIN_MARKETPLACE_REPORT_REQUEST';
export const FETCH_ADMIN_MARKETPLACE_REPORT_SUCCESS =
  'FETCH_ADMIN_MARKETPLACE_REPORT_SUCCESS';
export const FETCH_ADMIN_MARKETPLACE_REPORT_FAILURE =
  'FETCH_ADMIN_MARKETPLACE_REPORT_FAILURE';
export const FETCH_ADMIN_STOREFRONT_REPORT_REQUEST =
  'FETCH_ADMIN_STOREFRONT_REPORT_REQUEST';
export const FETCH_ADMIN_STOREFRONT_REPORT_SUCCESS =
  'FETCH_ADMIN_STOREFRONT_REPORT_SUCCESS';
export const FETCH_ADMIN_STOREFRONT_REPORT_FAILURE =
  'FETCH_ADMIN_STOREFRONT_REPORT_FAILURE';
export const FETCH_ADMIN_SUBSCRIPTIONS_REPORT_REQUEST =
  'FETCH_ADMIN_SUBSCRIPTIONS_REPORT_REQUEST';
export const FETCH_ADMIN_SUBSCRIPTIONS_REPORT_SUCCESS =
  'FETCH_ADMIN_SUBSCRIPTIONS_REPORT_SUCCESS';
export const FETCH_ADMIN_SUBSCRIPTIONS_REPORT_FAILURE =
  'FETCH_ADMIN_SUBSCRIPTIONS_REPORT_FAILURE';
export const FETCH_ADMIN_CUSTOMERS_REPORT_REQUEST =
  'FETCH_ADMIN_CUSTOMERS_REPORT_REQUEST';
export const FETCH_ADMIN_CUSTOMERS_REPORT_SUCCESS =
  'FETCH_ADMIN_CUSTOMERS_REPORT_SUCCESS';
export const FETCH_ADMIN_CUSTOMERS_REPORT_FAILURE =
  'FETCH_ADMIN_CUSTOMERS_REPORT_FAILURE';
export const FETCH_ADMIN_CONNECT_INVENTORY_REPORT_REQUEST =
  'FETCH_ADMIN_CONNECT_INVENTORY_REPORT_REQUEST';
export const FETCH_ADMIN_CONNECT_INVENTORY_REPORT_SUCCESS =
  'FETCH_ADMIN_CONNECT_INVENTORY_REPORT_SUCCESS';
export const FETCH_ADMIN_CONNECT_INVENTORY_REPORT_FAILURE =
  'FETCH_ADMIN_CONNECT_INVENTORY_REPORT_FAILURE';
export const FETCH_ADMIN_CONNECT_RENTALS_REPORT_REQUEST =
  'FETCH_ADMIN_CONNECT_RENTALS_REPORT_REQUEST';
export const FETCH_ADMIN_CONNECT_RENTALS_REPORT_SUCCESS =
  'FETCH_ADMIN_CONNECT_RENTALS_REPORT_SUCCESS';
export const FETCH_ADMIN_CONNECT_RENTALS_REPORT_FAILURE =
  'FETCH_ADMIN_CONNECT_RENTALS_REPORT_FAILURE';
export const FETCH_ADMIN_RENTALS_REPORT_REQUEST =
  'FETCH_ADMIN_RENTALS_REPORT_REQUEST';
export const FETCH_ADMIN_RENTALS_REPORT_SUCCESS =
  'FETCH_ADMIN_RENTALS_REPORT_SUCCESS';
export const FETCH_ADMIN_RENTALS_REPORT_FAILURE =
  'FETCH_ADMIN_RENTALS_REPORT_FAILURE';
export const SELECT_MONTH = 'SELECT_MONTH';
export const SELECT_BUSINESS = 'SELECT_BUSINESS';
export const SET_ADMIN_REPORT_FILTER = 'SET_ADMIN_REPORT_FILTER';
export const SET_DEFAULT_SETUP_TIME = 'SET_DEFAULT_SETUP_TIME';

export const FETCH_PAYMENTS_RECEIVED_REPORT_REQUEST =
  'FETCH_PAYMENTS_RECEIVED_REPORT_REQUEST';
export const FETCH_PAYMENTS_RECEIVED_REPORT_SUCCESS =
  'FETCH_PAYMENTS_RECEIVED_REPORT_SUCCESS';
export const FETCH_PAYMENTS_RECEIVED_REPORT_FAILURE =
  'FETCH_PAYMENTS_RECEIVED_REPORT_FAILURE';
export const FETCH_TAPGOODS_PAYMENTS_REPORT_REQUEST =
  'FETCH_TAPGOODS_PAYMENTS_REPORT_REQUEST';
export const FETCH_TAPGOODS_PAYMENTS_REPORT_SUCCESS =
  'FETCH_TAPGOODS_PAYMENTS_REPORT_SUCCESS';
export const FETCH_TAPGOODS_PAYMENTS_REPORT_FAILURE =
  'FETCH_TAPGOODS_PAYMENTS_REPORT_FAILURE';
export const SELECT_DAY = 'SELECT_DAY';
export const SELECT_CONTAINING_DATE = 'SELECT_CONTAINING_DATE';
export const SELECT_PERIOD = 'SELECT_PERIOD';

export const FETCH_INVENTORY_REQUEST = 'FETCH_INVENTORY_REQUEST';
export const FETCH_INVENTORY_SUCCESS = 'FETCH_INVENTORY_SUCCESS';
export const FETCH_INVENTORY_FAILURE = 'FETCH_INVENTORY_FAILURE';
export const DELETE_PRODUCT_REQUEST = 'DELETE_PRODUCT_REQUEST';
export const DELETE_PRODUCT_SUCCESS = 'DELETE_PRODUCT_SUCCESS';
export const DELETE_PRODUCT_FAILURE = 'DELETE_PRODUCT_FAILURE';
export const FILTER_PRODUCTS_REQUEST = 'FILTER_PRODUCTS_REQUEST';
export const FILTER_PRODUCTS_SUCCESS = 'FILTER_PRODUCTS_SUCCESS';
export const FILTER_PRODUCTS_FAILURE = 'FILTER_PRODUCTS_FAILURE';
export const SHOW_ALL_PRODUCTS_REQUEST = 'SHOW_ALL_PRODUCTS_REQUEST';
export const SHOW_ALL_PRODUCTS_SUCCESS = 'SHOW_ALL_PRODUCTS_SUCCESS';
export const SHOW_ALL_PRODUCTS_FAILURE = 'SHOW_ALL_PRODUCTS_FAILURE';
export const PAGINATE_REQUEST = 'PAGINATE_REQUEST';
export const PAGINATE_SUCCESS = 'PAGINATE_SUCCESS';
export const PAGINATE_FAILURE = 'PAGINATE_FAILURE';
export const REQUEST_ALERTS = 'REQUEST_ALERTS';
export const RECEIVE_ALERTS = 'RECEIVE_ALERTS';
export const BULK_READ_ALERTS = 'BULK_READ_ALERTS';
export const SHOW_TILES = 'SHOW_TILES';
export const HIDE_TILES = 'HIDE_TILES';
export const TOGGLE_DETAILS = 'TOGGLE_DETAILS';
export const SET_MANIPULATED_PICK_LIST_ITEMS =
  'SET_MANIPULATED_PICK_LIST_ITEMS';
export const SHOW_ITEM_NOTES = 'SHOW_ITEM_NOTES';
export const HIDE_ITEM_NOTES = 'HIDE_ITEM_NOTES';
export const TOGGLE_BAR_CODES = 'TOGGLE_BAR_CODES';
export const FETCH_PRODUCTS_REQUEST = 'FETCH_PRODUCTS_REQUEST';
export const FETCH_PRODUCTS_SUCCESS = 'FETCH_PRODUCTS_SUCCESS';
export const FETCH_PRODUCTS_FAILURE = 'FETCH_PRODUCTS_FAILURE';
export const FETCH_PRODUCT_LIST_REQUEST = 'FETCH_PRODUCTS_LIST_REQUEST';
export const FETCH_PRODUCT_LIST_SUCCESS = 'FETCH_PRODUCTS_LIST_SUCCESS';
export const FETCH_ACCESSORY_LIST_REQUEST = 'FETCH_ACCESSORY_LIST_REQUEST';
export const FETCH_ACCESSORY_LIST_SUCCESS = 'FETCH_ACCESSORY_LIST_SUCCESS';
export const FETCH_ADD_ON_LIST_REQUEST = 'FETCH_ADD_ON_LIST_REQUEST';
export const FETCH_ADD_ON_LIST_SUCCESS = 'FETCH_ADD_ON_LIST_SUCCESS';
export const FETCH_BUNDLE_LIST_REQUEST = 'FETCH_BUNDLE_LIST_REQUEST';
export const FETCH_BUNDLE_LIST_SUCCESS = 'FETCH_BUNDLE_LIST_SUCCESS';
export const FETCH_PRODUCT_REQUEST = 'FETCH_PRODUCT_REQUEST';
export const FETCH_PRODUCT_SUCCESS = 'FETCH_PRODUCT_SUCCESS';
export const FETCH_PRODUCT_FAILURE = 'FETCH_PRODUCT_FAILURE';
export const ADD_MAINTENANCE_REQUEST = 'ADD_MAINTENANCE_REQUEST';
export const ADD_MAINTENANCE_SUCCESS = 'ADD_MAINTENANCE_SUCCESS';
export const ADD_MAINTENANCE_FAILURE = 'ADD_MAINTENANCE_FAILURE';
export const UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST =
  'UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST';
export const UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS =
  'UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS';
export const UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE =
  'UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE';
export const SET_MAINTENANCE_RECORD_PRIORITY =
  'SET_MAINTENANCE_RECORD_PRIORITY';
export const SET_MAINTENANCE_RECORD_NOTES = 'SET_MAINTENANCE_RECORD_NOTES';
export const DELETE_MAINTENANCE_REQUEST = 'DELETE_MAINTENANCE_REQUEST';
export const DELETE_MAINTENANCE_SUCCESS = 'DELETE_MAINTENANCE_SUCCESS';
export const DELETE_MAINTENANCE_FAILURE = 'DELETE_MAINTENANCE_FAILURE';
export const FETCH_ALL_PRODUCTS_REQUEST = 'FETCH_ALL_PRODUCTS_REQUEST';
export const FETCH_ALL_PRODUCTS_SUCCESS = 'FETCH_ALL_PRODUCTS_SUCCESS';
export const FETCH_ALL_PRODUCTS_FAILURE = 'FETCH_ALL_PRODUCTS_FAILURE';
export const UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS =
  'UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS';
export const SET_FILTER = 'SET_FILTER';
export const SET_INVENTORY_FILTER = 'SET_INVENTORY_FILTER';
export const SET_LOCATION_FILTER = 'SET_LOCATION_FILTER';
export const SET_FOR_CONNECT = 'SET_FOR_CONNECT';
export const SET_SORT = 'SET_SORT';
export const SET_SHOW_ALL = 'SET_SHOW_ALL';
export const SET_QUERY = 'SET_QUERY';
export const SET_NUMBER_PER = 'SET_NUMBER_PER';
export const SET_INVENTORY_TYPE = 'SET_INVENTORY_TYPE';
export const RELOAD_INVENTORY_REQUEST = 'RELOAD_INVENTORY_REQUEST';
export const RELOAD_INVENTORY_SUCCESS = 'RELOAD_INVENTORY_SUCCESS';
export const RELOAD_INVENTORY_FAILURE = 'RELOAD_INVENTORY_FAILURE';
export const LOAD_INVENTORY_REQUEST = 'LOAD_INVENTORY_REQUEST';
export const LOAD_INVENTORY_SUCCESS = 'LOAD_INVENTORY_SUCCESS';
export const LOAD_INVENTORY_FAILURE = 'LOAD_INVENTORY_FAILURE';
export const INFINITE_REQUEST = 'INFINITE_REQUEST';
export const INFINITE_SUCCESS = 'INFINITE_SUCCESS';
export const INFINITE_FAILURE = 'INFINITE_FAILURE';
export const RESET_INVENTORY = 'RESET_INVENTORY';
export const RESET_INVENTORY_TO_DEFAULT = 'RESET_INVENTORY_TO_DEFAULT';
export const CREDIT_CARD_FIELD_CHANGED = 'CREDIT_CARD_FIELD_CHANGED';
export const CREDIT_CARD_CLEAR_FIELDS = 'CREDIT_CARD_CLEAR_FIELDS';
export const FETCH_CONNECT_DEFAULT_REQUEST = 'FETCH_CONNECT_DEFAULT_REQUEST';
export const FETCH_CONNECT_DEFAULT_SUCCESS = 'FETCH_CONNECT_DEFAULT_SUCCESS';
export const FETCH_CONNECT_DEFAULT_FAILURE = 'FETCH_CONNECT_DEFAULT_FAILURE';
export const UPDATE_CONNECT_DEFAULT_REQUEST = 'UPDATE_CONNECT_DEFAULT_REQUEST';
export const DEBOUNCED_UPDATE_CONNECT_DEFAULT_REQUEST =
  'DEBOUNCED_UPDATE_CONNECT_DEFAULT_REQUEST';
export const UPDATE_CONNECT_DEFAULT_SUCCESS = 'UPDATE_CONNECT_DEFAULT_SUCCESS';
export const UPDATE_CONNECT_DEFAULT_FAILURE = 'UPDATE_CONNECT_DEFAULT_FAILURE';

export const REFRESH_RENTAL = 'REFRESH_RENTAL';
export const FETCH_RENTAL_REQUEST = 'FETCH_RENTAL_REQUEST';
export const FETCH_RENTAL_SUCCESS = 'FETCH_RENTAL_SUCCESS';
export const FETCH_RENTAL_FAILURE = 'FETCH_RENTAL_FAILURE';
export const FETCH_RENTAL_PICKLIST_REQUEST = 'FETCH_RENTAL_PICKLIST_REQUEST';
export const FETCH_RENTAL_PICKLIST_SUCCESS = 'FETCH_RENTAL_PICKLIST_SUCCESS';
export const FETCH_RENTAL_PICKLIST_FAILURE = 'FETCH_RENTAL_PICKLIST_FAILURE';
export const FETCH_RENTAL_INFO_REQUEST = 'FETCH_RENTAL_INFO_REQUEST';
export const FETCH_RENTAL_INFO_SUCCESS = 'FETCH_RENTAL_INFO_SUCCESS';
export const FETCH_RENTAL_INFO_FAILURE = 'FETCH_RENTAL_INFO_FAILURE';
export const UPDATE_RENTAL_REQUEST = 'UPDATE_RENTAL_REQUEST';
export const UPDATE_RENTAL_SUCCESS = 'UPDATE_RENTAL_SUCCESS';
export const UPDATE_RENTAL_FAILURE = 'UPDATE_RENTAL_FAILURE';
export const CANCEL_RENTAL_REQUEST = 'CANCEL_RENTAL_REQUEST';
export const CANCEL_RENTAL_SUCCESS = 'CANCEL_RENTAL_SUCCESS';
export const CANCEL_RENTAL_FAILURE = 'CANCEL_RENTAL_FAILURE';
export const DELETE_RENTAL_REQUEST = 'DELETE_RENTAL_REQUEST';
export const DELETE_RENTAL_SUCCESS = 'DELETE_RENTAL_SUCCESS';
export const DELETE_RENTAL_FAILURE = 'DELETE_RENTAL_FAILURE';
export const RESERVE_RENTAL_REQUEST = 'RESERVE_RENTAL_REQUEST';
export const RESERVE_RENTAL_SUCCESS = 'RESERVE_RENTAL_SUCCESS';
export const RESERVE_RENTAL_FAILURE = 'RESERVE_RENTAL_FAILURE';
export const CLOSE_RENTAL_REQUEST = 'CLOSE_RENTAL_REQUEST';
export const CLOSE_RENTAL_SUCCESS = 'CLOSE_RENTAL_SUCCESS';
export const CLOSE_RENTAL_FAILURE = 'CLOSE_RENTAL_FAILURE';
export const CREATE_RENTAL_SERVICE_ROUTES_FAILURE =
  'CREATE_RENTAL_SERVICE_ROUTES_FAILURE';
export const CREATE_RENTAL_SERVICE_ROUTES_REQUEST =
  'CREATE_RENTAL_SERVICE_ROUTES_REQUEST';
export const CREATE_RENTAL_SERVICE_ROUTES_SUCCESS =
  'CREATE_RENTAL_SERVICE_ROUTES_SUCCESS';
export const UPGRADE_RENTAL_REQUEST = 'UPGRADE_RENTAL_REQUEST';
export const UPGRADE_RENTAL_SUCCESS = 'UPGRADE_RENTAL_SUCCESS';
export const UPGRADE_RENTAL_FAILURE = 'UPGRADE_RENTAL_FAILURE';

export const FETCH_USER_REQUEST = 'FETCH_USER_REQUEST';
export const FETCH_USER_SUCCESS = 'FETCH_USER_SUCCESS';
export const FETCH_USER_FAILURE = 'FETCH_USER_FAILURE';
export const FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST =
  'FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST';
export const FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS =
  'FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS';
export const FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_FAILURE =
  'FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_FAILURE';

export const UPDATE_USER_PROFILE = 'UPDATE_USER_PROFILE';

export const FETCH_CATEGORIES_FAILURE = 'FETCH_CATEGORIES_FAILURE';
export const FETCH_CATEGORIES_SUCCESS = 'FETCH_CATEGORIES_SUCCESS';
export const FETCH_CATEGORIES_REQUEST = 'FETCH_CATEGORIES_REQUEST';
export const FETCH_INVENTORY_CATEGORIES_FAILURE =
  'FETCH_INVENTORY_CATEGORIES_FAILURE';
export const FETCH_INVENTORY_CATEGORIES_SUCCESS =
  'FETCH_INVENTORY_CATEGORIES_SUCCESS';
export const FETCH_INVENTORY_CATEGORIES_REQUEST =
  'FETCH_INVENTORY_CATEGORIES_REQUEST';

// TERMINOLOGY Actions
export const FETCH_TERMINOLOGIES_FAILURE = 'FETCH_TERMINOLOGIES_FAILURE';
export const FETCH_TERMINOLOGIES_SUCCESS = 'FETCH_TERMINOLOGIES_SUCCESS';
export const FETCH_TERMINOLOGIES_REQUEST = 'FETCH_TERMINOLOGIES_REQUEST';

// Original TERMINOLOGY Actions
export const FETCH_ORIGINAL_TERMINOLOGIES_FAILURE =
  'FETCH_ORIGINAL_TERMINOLOGIES_FAILURE';
export const FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS =
  'FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS';
export const FETCH_ORIGINAL_TERMINOLOGIES_REQUEST =
  'FETCH_ORIGINAL_TERMINOLOGIES_REQUEST';

export const FETCH_FEATURES_FAILURE = 'FETCH_FEATURES_FAILURE';
export const FETCH_FEATURES_SUCCESS = 'FETCH_FEATURES_SUCCESS';
export const FETCH_FEATURES_REQUEST = 'FETCH_FEATURES_REQUEST';
export const REQUEST_RENTAL_REQUEST = 'REQUEST_RENTAL_REQUEST';
export const REQUEST_RENTAL_SUCCESS = 'REQUEST_RENTAL_SUCCESS';
export const REQUEST_RENTAL_FAILURE = 'REQUEST_RENTAL_FAILURE';
export const CHECK_OUT_RENTAL_REQUEST = 'CHECK_OUT_RENTAL_REQUEST';
export const CHECK_OUT_RENTAL_SUCCESS = 'CHECK_OUT_RENTAL_SUCCESS';
export const CHECK_OUT_RENTAL_FAILURE = 'CHECK_OUT_RENTAL_FAILURE';
export const CHECK_IN_RENTAL_REQUEST = 'CHECK_IN_RENTAL_REQUEST';
export const CHECK_IN_RENTAL_SUCCESS = 'CHECK_IN_RENTAL_SUCCESS';
export const CHECK_IN_RENTAL_FAILURE = 'CHECK_IN_RENTAL_FAILURE';
export const SET_CHECK_IN_SIGNATURE = 'SET_CHECK_IN_SIGNATURE';
export const SET_CHECK_OUT_SIGNATURE = 'SET_CHECK_OUT_SIGNATURE';
export const SET_RENTAL_PROPS = 'SET_RENTAL_PROPS';
export const BULK_PICK_RENTAL_ITEMS_REQUEST = 'BULK_PICK_RENTAL_ITEMS_REQUEST';
export const SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST =
  'SERIALIZED_BULK_PICK_RENTAL_ITEMS_REQUEST';
export const SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS =
  'SERIALIZED_BULK_PICK_RENTAL_ITEMS_SUCCESS';
export const SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE =
  'SERIALIZED_BULK_PICK_RENTAL_ITEMS_FAILURE';
export const BULK_PICK_RENTAL_ITEMS_SUCCESS = 'BULK_PICK_RENTAL_ITEMS_SUCCESS';
export const BULK_PICK_RENTAL_ITEMS_FAILURE = 'BULK_PICK_RENTAL_ITEMS_FAILURE';
export const PICK_RENTAL_ITEM_REQUEST = 'PICK_RENTAL_ITEM_REQUEST';
export const PICK_RENTAL_ITEM_SUCCESS = 'PICK_RENTAL_ITEM_SUCCESS';
export const PICK_RENTAL_ITEM_FAILURE = 'PICK_RENTAL_ITEM_FAILURE';
export const UNPICK_RENTAL_ITEM_REQUEST = 'UNPICK_RENTAL_ITEM_REQUEST';
export const UNPICK_RENTAL_ITEM_SUCCESS = 'UNPICK_RENTAL_ITEM_SUCCESS';
export const UNPICK_RENTAL_ITEM_FAILURE = 'UNPICK_RENTAL_ITEM_FAILURE';
export const BULK_CHECK_IN_RENTAL_ITEMS_REQUEST =
  'BULK_CHECK_IN_RENTAL_ITEMS_REQUEST';
export const BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS =
  'BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS';
export const BULK_CHECK_IN_RENTAL_ITEMS_FAILURE =
  'BULK_CHECK_IN_RENTAL_ITEMS_FAILURE';
export const BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST =
  'BULK_UNCHECK_IN_RENTAL_ITEMS_REQUEST';
export const BULK_UNCHECK_IN_RENTAL_ITEMS_SUCCESS =
  'BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS';
export const BULK_UNCHECK_IN_RENTAL_ITEMS_FAILURE =
  'BULK_CHECK_IN_RENTAL_ITEMS_FAILURE';
export const SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST =
  'SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_REQUEST';
export const SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS =
  'SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_SUCCESS';
export const SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE =
  'SERIALIZED_BULK_CHECK_IN_RENTAL_ITEMS_FAILURE';
export const CHECK_IN_RENTAL_ITEM_REQUEST = 'CHECK_IN_RENTAL_ITEM_REQUEST';
export const CHECK_IN_RENTAL_ITEM_SUCCESS = 'CHECK_IN_RENTAL_ITEM_SUCCESS';
export const CHECK_IN_RENTAL_ITEM_FAILURE = 'CHECK_IN_RENTAL_ITEM_FAILURE';
export const UNCHECK_IN_RENTAL_ITEM_REQUEST = 'UNCHECK_IN_RENTAL_ITEM_REQUEST';
export const UNCHECK_IN_RENTAL_ITEM_SUCCESS = 'UNCHECK_IN_RENTAL_ITEM_SUCCESS';
export const UNCHECK_IN_RENTAL_ITEM_FAILURE = 'UNCHECK_IN_RENTAL_ITEM_FAILURE';
export const REPORT_LACK_RENTAL_ITEM_REQUEST =
  'REPORT_LACK_RENTAL_ITEM_REQUEST';
export const REPORT_LACK_RENTAL_ITEM_SUCCESS =
  'REPORT_LACK_RENTAL_ITEM_SUCCESS';
export const REPORT_LACK_RENTAL_ITEM_FAILURE =
  'REPORT_LACK_RENTAL_ITEM_FAILURE';
export const ADD_FEE_RENTAL_ITEM_REQUEST = 'ADD_FEE_RENTAL_ITEM_REQUEST';
export const ADD_FEE_RENTAL_ITEM_SUCCESS = 'ADD_FEE_RENTAL_ITEM_SUCCESS';
export const ADD_FEE_RENTAL_ITEM_FAILURE = 'ADD_FEE_RENTAL_ITEM_FAILURE';
export const UPDATE_RENTAL_ITEM_REQUEST = 'UPDATE_RENTAL_ITEM_REQUEST';
export const UPDATE_RENTAL_ITEM_SUCCESS = 'UPDATE_RENTAL_ITEM_SUCCESS';
export const UPDATE_RENTAL_ITEM_FAILURE = 'UPDATE_RENTAL_ITEM_FAILURE';
export const CONNECT_RENTAL_REQUEST = 'CONNECT_RENTAL_REQUEST';
export const CONNECT_RENTAL_SUCCESS = 'CONNECT_RENTAL_SUCCESS';
export const CONNECT_RENTAL_FAILURE = 'CONNECT_RENTAL_FAILURE';
export const SUBMIT_QUOTE_REQUEST = 'SUBMIT_QUOTE_REQUEST';
export const REQUEST_CHANGES_REQUEST = 'REQUEST_CHANGES_REQUEST';
export const REQUEST_CANCEL_REQUEST = 'REQUEST_CANCEL_REQUEST';
export const SET_SUBRENTAL_ITEM = 'SET_SUBRENTAL_ITEM';

export const UPDATE_SUPPLIER_RENTAL_REQUEST = 'UPDATE_SUPPLIER_RENTAL_REQUEST';

export const SET_RENTAL_FILTER = 'SET_RENTAL_FILTER';
export const FETCH_RENTAL_LIST_REQUEST = 'FETCH_RENTAL_LIST_REQUEST';
export const FETCH_RENTAL_LIST_SUCCESS = 'FETCH_RENTAL_LIST_SUCCESS';
export const FETCH_RENTAL_LIST_FAILURE = 'FETCH_RENTAL_LIST_FAILURE';
export const ADD_INFINITE_RENTALS_REQUEST = 'ADD_INFINITE_RENTALS_REQUEST';
export const ADD_INFINITE_RENTALS_SUCCESS = 'ADD_INFINITE_RENTALS_SUCCESS';
export const ADD_INFINITE_RENTALS_FAILURE = 'ADD_INFINITE_RENTALS_FAILURE';
export const PAGINATE_RENTAL_LIST_REQUEST = 'PAGINATE_RENTAL_LIST_REQUEST';
export const PAGINATE_RENTAL_LIST_SUCCESS = 'PAGINATE_RENTAL_LIST_SUCCESS';
export const PAGINATE_RENTAL_LIST_FAILURE = 'PAGINATE_RENTAL_LIST_FAILURE';
export const REQUEST_RENTAL_NAMES = 'REQUEST_RENTAL_NAMES';
export const RECEIVE_RENTAL_NAMES = 'RECEIVE_RENTAL_NAMES';
export const RESET_RENTAL_NAMES = 'RESET_RENTAL_NAMES';

export const SET_RENTAL_INVENTORY_FILTER = 'SET_RENTAL_INVENTORY_FILTER';
export const FETCH_RENTAL_INVENTORY_LIST_REQUEST =
  'FETCH_RENTAL_INVENTORY_LIST_REQUEST';
export const FETCH_RENTAL_INVENTORY_LIST_SUCCESS =
  'FETCH_RENTAL_INVENTORY_LIST_SUCCESS';
export const FETCH_RENTAL_INVENTORY_LIST_FAILURE =
  'FETCH_RENTAL_INVENTORY_LIST_FAILURE';
export const PAGINATE_RENTAL_INVENTORY_LIST_REQUEST =
  'PAGINATE_RENTAL_INVENTORY_LIST_REQUEST';
export const PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS =
  'PAGINATE_RENTAL_INVENTORY_LIST_SUCCESS';
export const PAGINATE_RENTAL_INVENTORY_LIST_FAILURE =
  'PAGINATE_RENTAL_INVENTORY_LIST_FAILURE';

export const OPEN_VERSION_MODAL = 'OPEN_VERSION_MODAL';
export const CLOSE_VERSION_MODAL = 'CLOSE_VERSION_MODAL';

export const OPEN_COMPLIANCE_MODAL = 'OPEN_COMPLIANCE_MODAL';
export const CLOSE_COMPLIANCE_MODAL = 'CLOSE_COMPLIANCE_MODAL';
export const OPEN_SYSTEM_UPDATE_MODAL = 'OPEN_SYSTEM_UPDATE_MODAL';
export const CLOSE_SYSTEM_UPDATE_MODAL = 'CLOSE_SYSTEM_UPDATE_MODAL';

// Conversations
export const FETCH_CONVERSATION_LIST_REQUEST =
  'FETCH_CONVERSATION_LIST_REQUEST';
export const FETCH_CONVERSATION_LIST_FAILURE =
  'FETCH_CONVERSATION_LIST_FAILURE';
export const FETCH_CONVERSATION_LIST_SUCCESS =
  'FETCH_CONVERSATION_LIST_SUCCESS';
export const FETCH_CONVERSATION_REQUEST = 'FETCH_CONVERSATION_REQUEST';
export const FETCH_CONVERSATION_FAILURE = 'FETCH_CONVERSATION_FAILURE';
export const FETCH_CONVERSATION_SUCCESS = 'FETCH_CONVERSATION_SUCCESS';
export const READ_CONVERSATION_REQUEST = 'READ_CONVERSATION_REQUEST';
export const READ_CONVERSATION_FAILURE = 'READ_CONVERSATION_FAILURE';
export const READ_CONVERSATION_SUCCESS = 'READ_CONVERSATION_SUCCESS';
export const CREATE_CONVERSATION_REQUEST = 'CREATE_CONVERSATION_REQUEST';
export const CREATE_CONVERSATION_FAILURE = 'CREATE_CONVERSATION_FAILURE';
export const CREATE_CONVERSATION_SUCCESS = 'CREATE_CONVERSATION_SUCCESS';
export const CREATE_MESSAGE_REQUEST = 'CREATE_MESSAGE_REQUEST';
export const CREATE_MESSAGE_FAILURE = 'CREATE_MESSAGE_FAILURE';
export const CREATE_MESSAGE_SUCCESS = 'CREATE_MESSAGE_SUCCESS';
export const ADD_MESSAGE = 'ADD_MESSAGE';
export const ADD_UNREAD_MESSAGE = 'ADD_UNREAD_MESSAGE';
export const ADD_RENTAL_UNREAD_MESSAGE = 'ADD_RENTAL_UNREAD_MESSAGE';
export const REQUEST_MESSAGEABLE_NAMES = 'REQUEST_MESSAGEABLE_NAMES';
export const RECEIVE_MESSAGEABLE_NAMES = 'RECEIVE_MESSAGEABLE_NAMES';
export const DEBOUNCED_UPDATE_PRODUCT = 'DEBOUNCED_UPDATE_PRODUCT';
export const SEARCH_MESSAGEABLE_NAMES = 'SEARCH_MESSAGEABLE_NAMES';
export const SEARCH_CATEGORIES_REQUEST = 'SEARCH_CATEGORIES_REQUEST';
export const SEARCH_CATEGORIES_SUCCESS = 'SEARCH_CATEGORIES_SUCCESS';
export const SEARCH_CATEGORIES_FAILURE = 'SEARCH_CATEGORIES_FAILURE';

// Message Alerts
export const REQUEST_MESSAGE_ALERTS = 'REQUEST_MESSAGE_ALERTS';
export const RECEIVE_MESSAGE_ALERTS = 'RECEIVE_MESSAGE_ALERTS';
export const BULK_READ_MESSAGE_ALERTS = 'BULK_READ_MESSAGE_ALERTS';

export * from './GeofenceFilterConstants';
export * from './redux/business';
export * from './redux/location';
export * from './tapgoodsConstants';
export * from './redux/CombinedInventoryConstants';
export * from './redux/picklist';

// handles error catching for epics
export const EPIC_ERROR = 'EPIC_ERROR';

// Help Sidebar
export const SET_CURRENT_TUTORIAL = 'SET_CURRENT_TUTORIAL';
export const OPEN_HELP_SIDEBAR = 'OPEN_HELP_SIDEBAR';
export const CLOSE_HELP_SIDEBAR = 'CLOSE_HELP_SIDEBAR';

// FAQs
export const FETCH_FAQS_REQUEST = 'FETCH_FAQS_REQUEST';
export const FETCH_FAQS_SUCCESS = 'FETCH_FAQS_SUCCESS';
export const FETCH_FAQS_FAILURE = 'FETCH_FAQS_FAILURE';

// Marketplace Setting
export const FETCH_MARKETPLACE_SETTING_REQUEST =
  'FETCH_MARKETPLACE_SETTING_REQUEST';
export const FETCH_MARKETPLACE_SETTING_SUCCESS =
  'FETCH_MARKETPLACE_SETTING_SUCCESS';
export const FETCH_MARKETPLACE_SETTING_FAILURE =
  'FETCH_MARKETPLACE_SETTING_FAILURE';
export const UPDATE_MARKETPLACE_SETTING_REQUEST =
  'UPDATE_MARKETPLACE_SETTING_REQUEST';
export const DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST =
  'DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST';
export const UPDATE_MARKETPLACE_SETTING_SUCCESS =
  'UPDATE_MARKETPLACE_SETTING_SUCCESS';
export const UPDATE_MARKETPLACE_SETTING_FAILURE =
  'UPDATE_MARKETPLACE_SETTING_FAILURE';

// Notifications
export const FETCH_NOTIFICATION_ALL_COUNT_REQUEST =
  'FETCH_NOTIFICATION_ALL_COUNT_REQUEST';
export const FETCH_NOTIFICATION_ALL_COUNT_SUCCESS =
  'FETCH_NOTIFICATION_ALL_COUNT_SUCCESS';
export const FETCH_NOTIFICATION_ALL_COUNT_FAILURE =
  'FETCH_NOTIFICATION_ALL_COUNT_FAILURE';

// Tutorials
export const SET_TUTORIAL_PRODUCT_PROPS = 'SET_TUTORIAL_PRODUCT_PROPS';
export const SET_TUTORIAL_RENTAL_PROPS = 'SET_TUTORIAL_RENTAL_PROPS';
export const SET_TUTORIAL_CUSTOMER_PROPS = 'SET_TUTORIAL_CUSTOMER_PROPS';

export const START_PROGRESS_TRACKER = 'START_PROGRESS_TRACKER';
export const UPDATE_PROGRESS_TRACKER = 'UPDATE_PROGRESS_TRACKER';
export const CLOSE_PROGRESS_TRACKER = 'CLOSE_PROGRESS_TRACKER';
export const CLOSE_PROGRESS_TRACKER_SUCCESS = 'CLOSE_PROGRESS_TRACKER_SUCCESS';
export const OPEN_PROGRESS_TRACKER_SUCCESS = 'OPEN_PROGRESS_TRACKER_SUCCESS';
export const CLOSE_PROGRESS_TRACKER_START = 'CLOSE_PROGRESS_TRACKER_START';
export const OPEN_PROGRESS_TRACKER_START = 'OPEN_PROGRESS_TRACKER_START';
export const UPDATE_CONNECTION_FOR_TRACKER = 'UPDATE_CONNECTION_FOR_TRACKER';
export const UPDATE_CHANNEL_FOR_TRACKER = 'UPDATE_CHANNEL_FOR_TRACKER';
export const REPOSITION_INVENTORY_SUCCESS = 'REPOSITION_INVENTORY_SUCCESS';
export const SELECT_BUSINESS_SUBSCRIPTION = 'SELECT_BUSINESS_SUBSCRIPTION';
export const SET_ROUTING_TRUCK_FILTER = 'SET_ROUTING_TRUCK_FILTER';
export const SET_ROUTING_ORDER_FILTER = 'SET_ROUTING_ORDER_FILTER';
export const SAVE_SELECTED_PRODUCT = 'SAVE_SELECTED_PRODUCT';

// Global Cricle of Death Error Modal
export const OPEN_CIRCLE_OF_DEATH_ERROR_MODAL =
  'OPEN_CIRCLE_OF_DEATH_ERROR_MODAL';
export const CLOSE_CIRCLE_OF_DEATH_ERROR_MODAL =
  'CLOSE_CIRCLE_OF_DEATH_ERROR_MODAL';
export const RESET_RENTAL_LOADING = 'RESET_RENTAL_LOADING';
export const RESET_RENTAL_SUCCESS = 'RESET_RENTAL_SUCCESS';

// MaintenanceRecord
export const FETCH_MAINTENANCE_RECORD_REQUEST =
  'FETCH_MAINTENANCE_RECORD_REQUEST';
export const UPDATE_MAINTENANCE_RECORD_REQUEST =
  'UPDATE_MAINTENANCE_RECORD_REQUEST';
export const FETCH_MAINTENANCE_RECORD_FAILURE =
  'FETCH_MAINTENANCE_RECORD_FAILURE';
export const UPDATE_MAINTENANCE_RECORD_FAILURE =
  'UPDATE_MAINTENANCE_RECORD_FAILURE';
export const FETCH_MAINTENANCE_RECORD_SUCCESS =
  'FETCH_MAINTENANCE_RECORD_SUCCESS';
export const UPDATE_MAINTENANCE_RECORD_SUCCESS =
  'UPDATE_MAINTENANCE_RECORD_SUCCESS';
export const GET_VENDORS_LIST_FOR_SLIDE_DRAWER =
  'GET_VENDORS_LIST_FOR_SLIDE_DRAWER';
export const GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS =
  'GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS';
export const GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE =
  'GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE';
export const CREATE_VENDOR_REQUEST = 'CREATE_VENDOR_REQUEST';
export const CREATE_VENDOR_REQUEST_SUCCESS = 'CREATE_VENDOR_REQUEST_SUCCESS';
export const CREATE_VENDOR_REQUEST_FAILURE = 'CREATE_VENDOR_REQUEST_FAILURE';
export const SHOULD_ADD_VENDOR = 'SHOULD_ADD_VENDOR';
export const SHOULD_ADD_VENDOR_COMPLETE = 'SHOULD_ADD_VENDOR_COMPLETE';
export const SHOULD_CREATE_VENDOR = 'SHOULD_CREATE_VENDOR';
export const SHOULD_CREATE_VENDOR_COMPLETE = 'SHOULD_CREATE_VENDOR_COMPLETE';
export const SELECTED_VENDOR_FOR_WORK_ORDER = 'SELECTED_VENDOR_FOR_WORK_ORDER';
export const SHOULD_SHOW_UPDATING_VIEW = 'SHOULD_SHOW_UPDATING_VIEW';
export const SHOULD_SHOW_UPDATING_VIEW_COMPLETE =
  'SHOULD_SHOW_UPDATING_VIEW_COMPLETE';
export const HOVER_DOCUMENT = 'HOVER_DOCUMENT';
export const UNHOVER_DOCUMENT = 'UNHOVER_DOCUMENT';
export const RETURN_TO_INVENTORY_BULK_REQUEST =
  'RETURN_TO_INVENTORY_BULK_REQUEST';
export const RETURN_TO_INVENTORY_BULK_SUCCESS =
  'RETURN_TO_INVENTORY_BULK_SUCCESS';
export const RETURN_TO_INVENTORY_BULK_FAILURE =
  'RETURN_TO_INVENTORY_BULK_FAILURE';
export const CREATE_WORK_ORDER_REQUEST = 'CREATE_WORK_ORDER_REQUEST';
export const CREATE_WORK_ORDER_SUCCESS = 'CREATE_WORK_ORDER_SUCCESS';
export const CREATE_WORK_ORDER_FAILURE = 'CREATE_WORK_ORDER_FAILURE';
export const UPDATE_WORK_ORDER_REQUEST = 'UPDATE_WORK_ORDER_REQUEST';
export const UPDATE_WORK_ORDER_SUCCESS = 'UPDATE_WORK_ORDER_SUCCESS';
export const UPDATE_WORK_ORDER_FAILURE = 'UPDATE_WORK_ORDER_FAILURE';
export const RESET_SELECTED_VENDOR = 'RESET_SELECTED_VENDOR';
export const REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST =
  'REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST';
export const REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS =
  'REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS';
export const REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE =
  'REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE';

export const PASSWORD_VAIDATION_REQUEST = 'PASSWORD_VAIDATION_REQUEST';
export const PASSWORD_VAIDATION_REQUEST_SUCCESS =
  'PASSWORD_VAIDATION_REQUEST_SUCCESS';
export const PASSWORD_VAIDATION_REQUEST_FAILURE =
  'PASSWORD_VAIDATION_REQUEST_FAILURE';

export const TWO_FACTOR_AUTH_STATE_UNKNOWN = 'TWO_FACTOR_AUTH_STATE_UNKNOWN';
export const TWO_FACTOR_AUTH_STATE_REQUIRED = 'TWO_FACTOR_AUTH_STATE_REQUIRED';
export const TWO_FACTOR_AUTH_STATE_ENTER_CODE =
  'TWO_FACTOR_AUTH_STATE_ENTER_CODE';
export const TWO_FACTOR_AUTH_STATE_NOT_REQUIRED =
  'TWO_FACTOR_AUTH_STATE_NOT_REQUIRED';
export const RESET_TWO_FACTOR = 'RESET_TWO_FACTOR';

export const SEND_SMS_REQUEST = 'SEND_SMS_REQUEST';
export const SEND_SMS_REQUEST_SUCCESS = 'SEND_SMS_REQUEST_SUCCESS';
export const SEND_SMS_REQUEST_FAILURE = 'SEND_SMS_REQUEST_FAILURE';

export const FETCH_ROLES = 'FETCH_ROLES';
export const FETCH_ROLES_SUCCESS = 'FETCH_ROLES_SUCCESS';
export const FETCH_ROLES_FAIL = 'FETCH_ROLES_FAIL';
export const TOGGLE_DRAWER = 'TOGGLE_DRAWER';
export const CREATE_ROLE = 'CREATE_ROLE';
export const CREATE_ROLE_SUCCESS = 'CREATE_ROLE_SUCCESS';
export const CREATE_ROLE_FAIL = 'CREATE_ROLE_FAIL';
export const SELECT_ROLE = 'SELECT_ROLE';
export const UPDATE_ROLE = 'UPDATE_ROLE';
export const UPDATE_ROLE_SUCCESS = 'UPDATE_ROLE_SUCCESS';
export const UPDATE_ROLE_FAIL = 'UPDATE_ROLE_FAIL';
export const SET_CREATING_ROLE_STATUS = 'SET_CREATING_ROLE_STATUS';
export const SET_EDITING_ROLE_STATUS = 'SET_EDITING_ROLE_STATUS';
export const DELETE_ROLE = 'DELETE_ROLE';
export const DELETE_ROLE_SUCCESS = 'DELETE_ROLE_SUCCESS';
export const DELETE_ROLE_FAIL = 'DELETE_ROLE_FAIL';
export const TOGGLE_DELETE_DIALOG = 'TOGGLE_DELETE_DIALOG';

// Rental Form
// Rental Form General
export const SET_CAN_LEAVE = 'SET_CAN_LEAVE';

// Availability Alert
export const TOGGLE_AVAILABILITY_ALERT_MODAL =
  'TOGGLE_AVAILABILITY_ALERT_MODAL';
export const SET_AVAILABILITY_ALERT = 'SET_AVAILABILITY_ALERT';
// Venue
export const SET_ASSOCIATED_CONTACT_VENUES = 'SET_ASSOCIATED_CONTACT_VENUES';
export const TOGGLE_CONTACT_VENUES_MODAL = 'TOGGLE_CONTACT_VENUES_MODAL';
export const TOGGLE_VENUE_SEARCH_LIST_DRAWER =
  'TOGGLE_VENUE_SEARCH_LIST_DRAWER';
export const SET_VENUE = 'SET_VENUE';
export const SET_VENUE_RENTAL_RELATIONSHIP = 'SET_VENUE_RENTAL_RELATIONSHIP';
export const RESET_VENUE_FORM = 'RESET_VENUE_FORM';
export const SET_VENUE_SEARCH_LIST_DRAWER_STATE =
  'SET_VENUE_SEARCH_LIST_DRAWER_STATE';
export const SET_SHOULD_ADD_VENUE_DOCUMENTS = 'SET_SHOULD_ADD_VENUE_DOCUMENTS';
export const SET_SHOULD_ADD_VENUE_NOTE = 'SET_SHOULD_ADD_VENUE_NOTE';
export const SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE =
  'SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE';

export const FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS =
  'FETCH_DELIVERY_TICKETS_REQUEST_SUCCESS';
export const FETCH_DELIVERY_TICKETS_REQUEST_FAILURE =
  'FETCH_DELIVERY_TICKETS_REQUEST_FAILURE';
export const FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS =
  'FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_SUCCESS';
export const FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE =
  'FETCH_DELIVERY_TICKETS_PICKLIST_REQUEST_FAILURE';
export const SET_SELECTED_DT_ID = 'SET_SELECTED_DT_ID';
export const CHECKOUT_DELIVERY_TICKET_REQUEST =
  'CHECKOUT_DELIVERY_TICKET_REQUEST';
export const CHECKOUT_DELIVERY_TICKET_SUCCESS =
  'CHECKOUT_DELIVERY_TICKET_SUCCESS';
export const CHECKOUT_DELIVERY_TICKET_FAILURE =
  'CHECKOUT_DELIVERY_TICKET_FAILURE';
export const CHECKIN_DELIVERY_TICKET_REQUEST =
  'CHECKIN_DELIVERY_TICKET_REQUEST';
export const CHECKIN_DELIVERY_TICKET_SUCCESS =
  'CHECKIN_DELIVERY_TICKET_SUCCESS';
export const CHECKIN_DELIVERY_TICKET_FAILURE =
  'CHECKIN_DELIVERY_TICKET_FAILURE';
export const CLOSE_DELIVERY_TICKET_REQUEST = 'CLOSE_DELIVERY_TICKET_REQUEST';
export const CLOSE_DELIVERY_TICKET_SUCCESS = 'CLOSE_DELIVERY_TICKET_SUCCESS';
export const CLOSE_DELIVERY_TICKET_FAILURE = 'CLOSE_DELIVERY_TICKET_FAILURE';
export const SET_SELECTED_DELIVERY_TICKET_SUCCESS =
  'SET_SELECTED_DELIVERY_TICKET_SUCCESS';
export const SET_SELECTED_DELIVERY_TICKET_FAILURE =
  'SET_SELECTED_DELIVERY_TICKET_FAILURE';

export const QB_ACCOUNTS_AUTHENTICATION_SUCCESS =
  'QB_ACCOUNTS_AUTHENTICATION_SUCCESS';
export const QB_ACCOUNTS_AUTHENTICATION_FAILURE =
  'QB_ACCOUNTS_AUTHENTICATION_FAILURE';
export const QB_ACCOUNTS_AUTHENTICATION_REQUEST =
  'QB_ACCOUNTS_AUTHENTICATION_REQUEST';
export const GET_PRICING_TIER_STANDARD_REQUEST =
  'GET_PRICING_TIER_STANDARD_REQUEST';
export const GET_PRICING_TIER_SELECT_REQUEST =
  'GET_PRICING_TIER_SELECT_REQUEST';
export const GET_PRICING_TIER_PREMIER_REQUEST =
  'GET_PRICING_TIER_PREMIER_REQUEST';
export const GET_PRICING_TIER_ESSENTIALS_REQUEST =
  'GET_PRICING_TIER_ESSENTIALS_REQUEST';
export const GET_PRICING_TIER_STANDARD_SUCCESS =
  'GET_PRICING_TIER_STANDARD_SUCCESS';
export const GET_PRICING_TIER_SELECT_SUCCESS =
  'GET_PRICING_TIER_SELECT_SUCCESS';
export const GET_PRICING_TIER_PREMIER_SUCCESS =
  'GET_PRICING_TIER_PREMIER_SUCCESS';
export const GET_PRICING_TIER_ESSENTIALS_SUCCESS =
  'GET_PRICING_TIER_ESSENTIALS_SUCCESS';
export const GET_PRICING_TIER_STANDARD_FAILURE =
  'GET_PRICING_TIER_STANDARD_FAILURE';
export const GET_PRICING_TIER_SELECT_FAILURE =
  'GET_PRICING_TIER_SELECT_FAILURE';
export const GET_PRICING_TIER_PREMIER_FAILURE =
  'GET_PRICING_TIER_PREMIER_FAILURE';
export const GET_PRICING_TIER_ESSENTIALS_FAILURE =
  'GET_PRICING_TIER_ESSENTIALS_FAILURE';

export const FETCH_POWERBI_REPORTS_REQUEST = 'FETCH_POWERBI_REPORTS_REQUEST';
export const FETCH_POWERBI_REPORTS_SUCCESS = 'FETCH_POWERBI_REPORTS_SUCCESS';
export const FETCH_POWERBI_REPORTS_FAILURE = 'FETCH_POWERBI_REPORTS_FAILURE';

export const SHOW_ITEM_DISCOUNTS_ALERT = 'SHOW_ITEM_DISCOUNTS_ALERT';
export const HIDE_ITEM_DISCOUNTS_ALERT = 'HIDE_ITEM_DISCOUNTS_ALERT';
export const SHOW_NONDISCOUNTABLES_ALERT = 'SHOW_NONDISCOUNTABLES_ALERT';
export const HIDE_NONDISCOUNTABLES_ALERT = 'HIDE_NONDISCOUNTABLES_ALERT';

export const FETCH_GL_CATEGORIES_REQUEST = 'FETCH_GL_CATEGORIES_REQUEST';
export const FETCH_GL_CATEGORIES_SUCCESS = 'FETCH_GL_CATEGORIES_SUCCESS';
export const FETCH_GL_CATEGORIES_FAILURE = 'FETCH_GL_CATEGORIES_FAILURE';

export const FETCH_GL_LINE_ITEM_REQUEST = 'FETCH_GL_LINE_ITEM_REQUEST';
export const FETCH_GL_LINE_ITEM_SUCCESS = 'FETCH_GL_LINE_ITEM_SUCCESS';
export const FETCH_GL_LINE_ITEM_FAILURE = 'FETCH_GL_LINE_ITEM_FAILURE';

export const FETCH_GL_LINE_ITEMS_REQUEST = 'FETCH_GL_LINE_ITEMS_REQUEST';
export const FETCH_GL_LINE_ITEMS_SUCCESS = 'FETCH_GL_LINE_ITEMS_SUCCESS';
export const FETCH_GL_LINE_ITEMS_FAILURE = 'FETCH_GL_LINE_ITEMS_FAILURE';

export const SELECT_GL_LINE_ITEM = 'SELECT_GL_LINE_ITEM';
export const CLEAR_SELECTED_GL_LINE_ITEM = 'CLEAR_SELECTED_GL_LINE_ITEM';

export const UPDATE_GL_LINE_ITEM_REQUEST = 'UPDATE_GL_LINE_ITEM_REQUEST';
export const UPDATE_GL_LINE_ITEM_SUCCESS = 'UPDATE_GL_LINE_ITEM_SUCCESS';
export const UPDATE_GL_LINE_ITEM_FAILURE = 'UPDATE_GL_LINE_ITEM_FAILURE';

export const CREATE_GL_LINE_ITEM_REQUEST = 'CREATE_GL_LINE_ITEM_REQUEST';
export const CREATE_GL_LINE_ITEM_SUCCESS = 'CREATE_GL_LINE_ITEM_SUCCESS';
export const CREATE_GL_LINE_ITEM_FAILURE = 'CREATE_GL_LINE_ITEM_FAILURE';

export const DELETE_GL_LINE_ITEM_REQUEST = 'DELETE_GL_LINE_ITEM_REQUEST';
export const DELETE_GL_LINE_ITEM_SUCCESS = 'DELETE_GL_LINE_ITEM_SUCCESS';
export const DELETE_GL_LINE_ITEM_FAILURE = 'DELETE_GL_LINE_ITEM_FAILURE';

export const OPEN_GENERAL_LEDGER_SETTINGS_DRAWER =
  'OPEN_GENERAL_LEDGER_SETTINGS_DRAWER';
export const CLOSE_GENERAL_LEDGER_SETTINGS_DRAWER =
  'CLOSE_GENERAL_LEDGER_SETTINGS_DRAWER';

export const OPEN_GENERAL_LEDGER_SETTINGS_DIALOG =
  'OPEN_GENERAL_LEDGER_SETTINGS_DIALOG';
export const CLOSE_GENERAL_LEDGER_SETTINGS_DIALOG =
  'CLOSE_GENERAL_LEDGER_SETTINGS_DIALOG';

export const FETCH_GENERAL_LEDGER_REPORT_REQUEST =
  'FETCH_GENERAL_LEDGER_REPORT_REQUEST';
export const FETCH_GENERAL_LEDGER_REPORT_SUCCESS =
  'FETCH_GENERAL_LEDGER_REPORT_SUCCESS';
export const FETCH_GENERAL_LEDGER_REPORT_FAILURE =
  'FETCH_GENERAL_LEDGER_REPORT_FAILURE';
// Filter Settings
export const FETCH_FILTER_SETTING_REQUEST = 'FETCH_FILTER_SETTING_REQUEST';
export const FETCH_FILTER_SETTING_SUCCESS = 'FETCH_FILTER_SETTING_SUCCESS';
export const FETCH_FILTER_SETTING_FAILURE = 'FETCH_FILTER_SETTING_FAILURE';
export const SET_FILTER_SETTING_REQUEST = 'SET_FILTER_SETTING_REQUEST';
export const SET_FILTER_SETTING_SUCCESS = 'SET_FILTER_SETTING_SUCCESS';
export const SET_FILTER_SETTING_FAILURE = 'SET_FILTER_SETTING_FAILURE';
// Operations
export const SET_OPERATIONS_FILTER = 'SET_OPERATIONS_FILTER';

export const SET_PICKLIST_FILTER = 'SET_PICKLIST_FILTER';

export const OPEN_DRAWER = 'OPEN_DRAWER';

export const CREATE_POWERBI_REPORT_SUCCESS = 'CREATE_POWERBI_REPORT_SUCCESS';
export const CREATE_POWERBI_REPORT_FAILURE = 'CREATE_POWERBI_REPORT_FAILURE';
export const UPDATE_DELIVERY_TYPE = 'UPDATE_DELIVERY_TYPE';

// ServiceTickets
export const UPDATE_SERVICE_TICKET_REQUEST = 'UPDATE_SERVICE_TICKET_REQUEST';
export const UPDATE_SERVICE_TICKET_SUCCESS = 'UPDATE_SERVICE_TICKET_SUCCESS';
export const UPDATE_SERVICE_TICKET_FAILURE = 'UPDATE_SERVICE_TICKET_FAILURE';

// RentalStaff
export const FETCH_RENTAL_STAFFS_REQUEST = 'FETCH_RENTAL_STAFFS_REQUEST';
export const FETCH_RENTAL_STAFFS_SUCCESS = 'FETCH_RENTAL_STAFFS_SUCCESS';
export const FETCH_RENTAL_STAFFS_FAILURE = 'FETCH_RENTAL_STAFFS_FAILURE';
export const UPDATE_RENTAL_STAFF_FILTERS = 'UPDATE_RENTAL_STAFF_FILTERS';
export const CLEAR_RENTAL_STAFF_FILTERS = 'CLEAR_RENTAL_STAFF_FILTERS';
export const REFRESH_RENTAL_STAFF = 'REFRESH_RENTAL_STAFF';
export const CREATE_RENTAL_STAFF_ROUTES_FAILURE =
  'CREATE_RENTAL_STAFF_ROUTES_FAILURE';
export const CREATE_RENTAL_STAFF_ROUTES_REQUEST =
  'CREATE_RENTAL_STAFF_ROUTES_REQUEST';
export const CREATE_RENTAL_STAFF_ROUTES_SUCCESS =
  'CREATE_RENTAL_STAFF_ROUTES_SUCCESS';

export const FETCH_INVENTORY_AVAILABILITY_REQUEST =
  'FETCH_INVENTORY_AVAILABILITY_REQUEST';
export const FETCH_INVENTORY_AVAILABILITY_SUCCESS =
  'FETCH_INVENTORY_AVAILABILITY_SUCCESS';
export const FETCH_INVENTORY_AVAILABILITY_FAILURE =
  'FETCH_INVENTORY_AVAILABILITY_FAILURE';
export const RESET_INVENTORY_AVAILABILITY = 'RESET_INVENTORY_AVAILABILITY';
export const SET_AVAILABILITY_LOADING_INVENTORY =
  'SET_AVAILABILITY_LOADING_INVENTORY';

export const UPDATE_RENTAL_FORM_V2 = 'UPDATE_RENTAL_FORM_V2';

export const CREATE_RENTAL_FORM_V2_REQUEST = 'CREATE_RENTAL_FORM_V2_REQUEST';
export const CREATE_RENTAL_FORM_V2_SUCCESS = 'CREATE_RENTAL_FORM_V2_SUCCESS';

export const UPDATE_RENTAL_FORM_V2_REQUEST = 'UPDATE_RENTAL_FORM_V2_REQUEST';
export const UPDATE_RENTAL_FORM_V2_SUCCESS = 'UPDATE_RENTAL_FORM_V2_SUCCESS';

export const RESET_RENTAL_FORM_V2 = 'RESET_RENTAL_FORM_V2';

export const FETCH_RENTAL_FORM_V2_REQUEST = 'FETCH_RENTAL_FORM_V2_REQUEST';
export const FETCH_RENTAL_FORM_V2_SUCCESS = 'FETCH_RENTAL_FORM_V2_SUCCESS';

export const SET_RENTAL_FORM_V2_TYPE = 'SET_RENTAL_FORM_V2_TYPE';
export const TOGGLE_RENTAL_FORM_V2_LOADING = 'TOGGLE_RENTAL_FORM_V2_LOADING';

export const TOGGLE_ITEMS_MODAL = 'TOGGLE_ITEMS_MODAL';
export const TOGGLE_ADD_INVENTORY_MODAL = 'TOGGLE_ADD_INVENTORY_MODAL';
export const TOGGLE_ADD_INVENTORY_TO_BUNDLE_MODAL =
  'TOGGLE_ADD_INVENTORY_TO_BUNDLE_MODAL';
export const TOGGLE_TAX_CALCULATED = 'TOGGLE_TAX_CALCULATED';
export const TOGGLE_ADD_SERVICE_MODAL = 'TOGGLE_ADD_SERVICE_MODAL';
export const TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL =
  'TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL';
export const RENTAL_FORM_V2_FAILURE = 'RENTAL_FORM_V2_FAILURE';
export const TOGGLE_SERIALIZED_ITEM_DRAWER = 'TOGGLE_SERIALIZED_ITEM_DRAWER';

export const SET_FLOW_SHEETS_OPERATIONS_FILTER =
  'SET_FLOW_SHEETS_OPERATIONS_FILTER';

export const SET_COLLAPSABLE_DAILY_ORDER = 'SET_COLLAPSABLE_DAILY_ORDER';
export const NEW_UPDATE_RENTAL_REQUEST = 'NEW_UPDATE_RENTAL_REQUEST';
export const NEW_SUBMIT_RENTAL_REQUEST = 'NEW_SUBMIT_RENTAL_REQUEST';

export const UPDATE_EVENT_STATE = 'UPDATE_EVENT_STATE';
export const SUBMIT_EVENT_REQUEST = 'SUBMIT_EVENT_REQUEST';
export const SUBMIT_EVENT_SUCCESS = 'SUBMIT_EVENT_SUCCESS';
export const CLEAR_EVENT = 'CLEAR_EVENT';
export const RESET_PARENT_ORDER_FORM = 'RESET_PARENT_ORDER_FORM';
export const OPEN_OVERBOOKING_MODAL = 'OPEN_OVERBOOKING_MODAL';
export const CLOSE_OVERBOOKING_MODAL = 'CLOSE_OVERBOOKING_MODAL';

export const MOCK_PAGE_REQUEST = 'MOCK_PAGE_REQUEST';
export const MOCK_PAGE_SUCCESS = 'MOCK_PAGE_SUCCESS';
export const MOCK_PAGE_FAILURE = 'MOCK_PAGE_FAILURE';

import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="errorLanding">
      <p>
        Hmmm, this is weird.
        <br />
        This page isn't here.
      </p>
      <h1>404</h1>
      <Link to="/" className="btn">
        Take me home
      </Link>
    </div>
  );
};
export default NotFound;

import React from 'react';
import Imgix from 'react-imgix';
import { connect } from 'react-redux';

import { Company } from '../../../components/utils/SvgIcons';
import { selectLocation } from '../../../actions';
import AppState from '../../../types/AppState';
import LocationBadge from '../../../components/utils/LocationBadge';
import { SelectBusinessProps } from './SelectBusiness.types';
import { LocationName } from './SelectBusiness.styles';

const SelectBusiness = ({ selectLocation, user }: SelectBusinessProps) => {
  return (
    <div className="selectBusiness">
      <div className="content">
        <h1>Which location would you like to work in?</h1>
        {user.locations.map(location => (
          <section key={location.id}>
            <figure>
              {!location.picture?.imgixUrl ?
                <Company /> :
                <Imgix
                  src={location.picture.imgixUrl}
                  alt={location.name}
                  width={510}
                  height={288}
                />
              }
            </figure>
            <LocationName>
              {location.name}
              <LocationBadge
                code={location.locationCode}
                color={location.locationColor}
              />
            </LocationName>
            <p>{location.email}</p>
            <button
              className="btn"
              onClick={() => selectLocation(location.id, location.businessId)}
            >
              Select Location
            </button>
          </section>
        ))}
      </div>
    </div>
  );
}

const mapStateToProps = (state: AppState) => {
  const { user } = state.user;

  return { user };
}

export default connect(mapStateToProps, { selectLocation })(SelectBusiness);

import * as actionTypes from "Constants/redux";

const INITIAL_STATE = {
  modalOpen: false,
  loading: false,
  resetSuccess: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN_CIRCLE_OF_DEATH_ERROR_MODAL:
      return {
        ...state,
        modalOpen: true
      };
    case actionTypes.CLOSE_CIRCLE_OF_DEATH_ERROR_MODAL:
      return {
        ...state,
        modalOpen: false,
      };
    case actionTypes.RESET_RENTAL_LOADING:
      return {
        ...state,
        loading: true,
      }
    case actionTypes.RESET_RENTAL_SUCCESS:
      return {
        ...state,
        loading: false,
        resetSuccess: true,
      }
    default:
      return state;
  }
};

export * from './eventDatesForInventoryRequest';
export * from './selectRentalSectionFromRentalVersionStore';

/* These are utilities for the rental from when adding/updating items inside of a rental.
 * Most are required due to the polymorphic nature of section memberships.

   Naming convention for constants below:
 * RentalInventoryAssociation is the association name in Rails' nested attributes format
 * ProductType is a specific naming convention used on the v1 form (i.e. addons, items, accessories, bundles)
 * InventoryForeignKey is the foreign key to the actual item that own the rental inventory association
 */
export const mapProductTypeToRentalInventoryAssociation: {
  [key: string]: string;
} = {
  bundles: 'rentalBundleAttributes',
  items: 'rentalItemAttributes',
  accessories: 'rentalAccessoryAttributes',
  addons: 'rentalAddOnAttributes',
};

export const mapProductTypeToInventoryKey: {
  [key: string]: string;
} = {
  bundles: 'bundle',
  items: 'product',
  accessories: 'accessory',
  addons: 'addOn',
};

export const mapProductTypeToInventoryForeignKey: { [key: string]: string } = {
  bundles: 'bundleId',
  items: 'productId',
  accessories: 'accessoryId',
  addons: 'addOnId',
  staffs: 'staffRoleId',
};

export const mapProductTypeToRentalInventoryClass: { [key: string]: string } = {
  bundles: 'RentalBundle',
  items: 'RentalItem',
  accessories: 'RentalAccessory',
  addons: 'RentalAddOn',
  staffs: 'RentalStaff',
};

export const mapProductTypeToAvailabilityKey: { [key: string]: string } = {
  addons: 'addOns',
  items: 'products',
  bundles: 'bundles',
  accessories: 'accessories',
};

export const mapProductTypeToLinkedInventoryAssociationName: {
  [key: string]: string;
} = {
  addons: 'addOnProductRelationships',
  items: 'commodityProductRelationships',
  accessories: 'accessoryProductRelationships',
};

export const mapPluralRentalInventoryAssociationToInventoryForeignKey: {
  [key: string]: string;
} = {
  rentalItemsAttributes: 'productId',
  rentalAccessoriesAttributes: 'accessoryId',
  rentalAddOnsAttributes: 'addOnId',
};

export const mapRentalInventoryClassToInventoryParentForeignKey = {
  RentalItem: 'productId',
  RentalAccessory: 'accessoryId',
  RentalAddOn: 'addOnId',
};

export const mapRentalInventoryAssociationToRentalInventoryClass = {
  rentalBundleAttributes: 'RentalBundle',
  rentalItemAttributes: 'RentalItem',
  rentalAccessoryAttributes: 'RentalAccessory',
  rentalAddOnAttributes: 'RentalAddOn',
};

export const mapRentalInventoryClassToPluralRentalInventoryAssociation: {
  [key: string]: string;
} = {
  RentalAddOn: 'rentalAddOnsAttributes',
  RentalItem: 'rentalItemsAttributes',
  RentalAccessory: 'rentalAccessoriesAttributes',
};

export const mapRentalInventoryClassToProductType = {
  RentalAddOn: 'addons',
  RentalBundle: 'bundles',
  RentalItem: 'items',
  RentalAccessory: 'accessories',
};

export const mapRentalInventoryClassToInventoryForeignKey: {
  [key: string]: string;
} = {
  RentalAddOn: 'addOnId',
  RentalBundle: 'bundleId',
  RentalItem: 'productId',
  RentalAccessory: 'accessoryId',
  RentalStaff: 'staffRoleId',
};

export const mapInventoryForeignKeyToRentalInventoryClass: {
  [key: string]: string;
} = {
  addOnId: 'RentalAddOn',
  bundleId: 'RentalBundle',
  productId: 'RentalItem',
  accessoryId: 'RentalAccessory',
  staffRoleId: 'RentalStaff',
};

export const timeBasedPeriodConstants = [
  'hourly_price',
  'daily_price',
  'weekly_price',
  'monthly_price',
  'half_day_price',
];

// // This array is used to filter out any unpermitted attributes from products when creating rental item
// export const unpermittedNonBundleInventoryAttributes = ['purchase_price'];

export const rentalItemPermittedAttributes = [
  'name',
  'flatPricesAttributes',
  'taxExempt',
  'damageWaiverExempt',
  'hourlyPrice',
  'halfDayPrice',
  'dailyPrice',
  'weeklyPrice',
  'monthlyPrice',
  'itemNotesAttributes',
  'discountEligible',
];

export const rentalAccessoryPermittedAttributes = [
  'name',
  'itemNotesAttributes',
];

export const rentalAddOnPermittedAttributes = [
  'name',
  'taxExempt',
  'itemNotesAttributes',
];

export const rentalBundlePermittedAttributes = [
  'name',
  'priceLocked',
  'discountPercent',
  'purchasePrice',
  'taxExemptionSetting',
  'damageWaiverExempt',
  'hourlyPrice',
  'halfDayPrice',
  'dailyPrice',
  'weeklyPrice',
  'monthlyPrice',
  'discountEligible',
  'itemNotesAttributes',
];

import {
  CompanyRentalRelationship,
  CustomerRentalRelationship,
} from '../../../../../types/graphql';

export const isPrimaryContact = (
  data: CustomerRentalRelationship | CompanyRentalRelationship
) => {
  return data?.isPrimaryContact!;
};

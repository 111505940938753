import { history } from 'Components/Routes';
import moment from 'moment';

export const ROOT_URL = process.env.REACT_APP_API_DOMAIN + '/api';

export const isValidToken = () => {
  const currentToken = JSON.parse(localStorage.getItem('authTokenHeaders'));
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const clearAuthToken = () => {
  localStorage.removeItem('authTokenHeaders');
  localStorage.removeItem('numberPer');
  localStorage.removeItem('businessId');
  localStorage.removeItem('locationId');
  localStorage.removeItem('isTgImposter');

  //localStorage.removeItem("rememberDeviceTill");
};

export const clearAdminAuthToken = () => {
  localStorage.removeItem('adminAuthTokenHeaders');
};

export const getAuthToken = () => {
  if (isValidToken()) {
    let currentToken = JSON.parse(localStorage.getItem('authTokenHeaders'));
    currentToken['business-id'] = getBusinessId();
    currentToken['location-id'] = getLocationId();
    currentToken['is-tg-employee'] = getTgImposter() || false;
    return currentToken;
  } else {
    history.push('/login');
  }
};

export const getBusinessId = () => {
  const currentBusinessId = localStorage.getItem('businessId');
  const currentBusinessIdSession = sessionStorage.getItem('businessId');
  const sessionOrLocalBusinessId =
    currentBusinessIdSession || currentBusinessId;
  return sessionOrLocalBusinessId;
};

export const saveBusinessId = (businessId) => {
  localStorage.setItem('businessId', businessId);
  sessionStorage.setItem('businessId', businessId);
};

export const getLocationId = () => {
  const currentLocationId = localStorage.getItem('locationId');
  const currentLocationIdSession = sessionStorage.getItem('locationId');
  const sessionOrLocalLocationId =
    currentLocationIdSession || currentLocationId;
  return sessionOrLocalLocationId;
};

export const saveLocationId = (locationId) => {
  localStorage.setItem('locationId', locationId);
  sessionStorage.setItem('locationId', locationId);
};

export const resetSessionLocationAndBusinessIds = () => {
  sessionStorage.removeItem('locationId');
  sessionStorage.removeItem('businessId');
};

export const getTgImposter = () => {
  const isTgImposter = localStorage.getItem('isTgImposter');

  return isTgImposter;
};

export const saveTgImposter = () => {
  localStorage.setItem('isTgImposter', true);
};

export const authToken = () => {
  const currentToken = JSON.parse(localStorage.getItem('authTokenHeaders'));
  return currentToken;
};

export const adminAuthToken = () => {
  const currentToken = JSON.parse(
    localStorage.getItem('adminAuthTokenHeaders')
  );
  return currentToken;
};

export const saveAuthToken = (headers) => {
  if (headers['access-token']) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      'token-type': 'Bearer',
      'access-token': headers['access-token'],
      'Content-Type': 'application/json',
    };
    localStorage.setItem('authTokenHeaders', JSON.stringify(authTokenObj));
  }
};

export const saveDeviceMemory = (timestamp) => {
  localStorage.setItem('rememberDeviceTill', timestamp);
};

export const getRememberDeviceStamp = () => {
  return localStorage.getItem('rememberDeviceTill');
};

export const rememberIsValid = () => {
  const lastSignedInStamp = getRememberDeviceStamp();
  const now = moment.utc();
  const end = moment(+lastSignedInStamp);
  const days = now.diff(end, 'days');
  return days <= 14;
};

export const isValidAdminToken = () => {
  const currentToken = JSON.parse(
    localStorage.getItem('adminAuthTokenHeaders')
  );
  if (currentToken) {
    const expDate = new Date(currentToken.expiry * 1000);
    const now = new Date();
    if (expDate > now) {
      return true;
    } else {
      return false;
    }
  }
  return false;
};

export const getAdminAuthToken = () => {
  if (isValidToken) {
    const currentToken = JSON.parse(
      localStorage.getItem('adminAuthTokenHeaders')
    );
    return currentToken;
  } else {
    history.push('/login');
  }
};

export const saveAdminAuthToken = (headers) => {
  if (headers['access-token']) {
    const { expiry, uid, client } = headers;
    const authTokenObj = {
      uid,
      expiry,
      client: client,
      'token-type': 'Bearer',
      'access-token': headers['access-token'],
      'Content-Type': 'application/json',
    };
    localStorage.setItem('adminAuthTokenHeaders', JSON.stringify(authTokenObj));
    localStorage.removeItem('authTokenHeaders');
  }
};

export const saveError = (error, state = {}) => {
  console.error(error);
};

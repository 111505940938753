import * as actionTypes from 'Constants/redux';

function AdminReportsReducer(
  state = {
    loading: true,
    paymentsData: [],
    marketplaceData: [],
    storefrontData: [],
    subscriptionsData: [],
    subscriptionRecords: [],
    subscriptionsSum: null,
    rentalsData: [],
    rentalsResponse: {},
    customersData: [],
    connectInventoryData: [],
    connectRentalsData: [],
    businessId: 'all',
    storefrontTier: '',
    month: new Date(),
    filter: {
      page: 1,
      locale: '',
    },
    drawer: false,
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_RENTALS_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_CONNECT_INVENTORY_REPORT_REQUEST:
    case actionTypes.FETCH_ADMIN_CONNECT_RENTALS_REPORT_REQUEST:
      return { ...state, loading: true };
    case actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_FAILURE:
    case actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_FAILURE:
    case actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_FAILURE:
    case actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_FAILURE:
    case actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_FAILURE:
    case actionTypes.FETCH_ADMIN_RENTALS_REPORT_FAILURE:
      return { ...state, loading: false };
    case actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_SUCCESS:
      return {
        ...state,
        paymentsData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_SUCCESS:
      return {
        ...state,
        marketplaceData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_SUCCESS:
      return {
        ...state,
        storefrontData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_SUCCESS:
      return {
        ...state,
        subscriptionRecords: action.response.data.subscriptionRecords,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_SUCCESS:
      return {
        ...state,
        customersData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_CONNECT_INVENTORY_REPORT_SUCCESS:
      return {
        ...state,
        connectInventoryData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_CONNECT_RENTALS_REPORT_SUCCESS:
      return {
        ...state,
        connectRentalsData: action.response.data.data,
        loading: false,
      };
    case actionTypes.FETCH_ADMIN_RENTALS_REPORT_SUCCESS:
      return {
        ...state,
        rentalsData: action.response.data.data,
        rentalsResponse: action.response.data,
        loading: false,
      };
    case actionTypes.SELECT_MONTH:
      return Object.assign({}, state, {
        month: action.payload.month,
      });
    case actionTypes.SELECT_BUSINESS:
      return Object.assign({}, state, {
        businessId: action.payload.businessId,
      });
    case actionTypes.SET_ADMIN_REPORT_FILTER:
      return {
        ...state,
        filter: {
          ...state.filter,
          ...action.payload,
        },
        loading: false,
      };
    case actionTypes.OPEN_DRAWER:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
}

export default AdminReportsReducer;

import {
  OPEN_SYSTEM_UPDATE_MODAL,
  CLOSE_SYSTEM_UPDATE_MODAL
} from "Constants/redux";

export const openSystemUpdateModal = systemUpdateSettings => {
  return {
    type: OPEN_SYSTEM_UPDATE_MODAL,
    payload: systemUpdateSettings
  };
};
export const closeSystemUpdateModal = () => {
  return {
    type: CLOSE_SYSTEM_UPDATE_MODAL
  };
};

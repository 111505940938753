import {
  FETCH_STAFF_MEMBER_NAMES_REQUEST,
  FETCH_STAFF_MEMBER_NAMES_SUCCESS,
  ADD_STAFF_MEMBER_NAMES_REQUEST,
  ADD_STAFF_MEMBER_NAMES_SUCCESS,
  SET_STAFF_MEMBER_QUERY
} from "Constants/redux";

export const setStaffMemberQuery = query => {
  return {
    type: SET_STAFF_MEMBER_QUERY,
    payload: { query }
  };
};

export const receiveStaffMemberNames = response => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = "";
  if (names.length > 0) {
    firstId = names[0].nameableId;
  }
  return {
    type: FETCH_STAFF_MEMBER_NAMES_SUCCESS,
    payload: { names, pagination, firstId }
  };
};

export const addStaffMemberNamesSuccess = response => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_STAFF_MEMBER_NAMES_SUCCESS,
    payload: { names, pagination }
  };
};

export const fetchStaffMemberNames = () => {
  return (dispatch, getState) => {
    const { query } = getState().staffMembers;
    dispatch({
      type: FETCH_STAFF_MEMBER_NAMES_REQUEST,
      query
    });
  };
};

export const searchStaffMemberNames = query => {
  return (dispatch, getState) => {
    dispatch(setStaffMemberQuery(query));
    dispatch(fetchStaffMemberNames());
  };
};

export const addStaffMemberNames = link => {
  return (dispatch, getState) => {
    const { query } = getState().staffMembers;
    dispatch({
      type: ADD_STAFF_MEMBER_NAMES_REQUEST,
      query,
      link
    });
  };
};

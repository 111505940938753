import React from 'react';
import Dropzone from 'react-dropzone';
import Modal from 'Utils/Modal';
import Cropper from 'react-cropper';
import 'cropperjs/dist/cropper.css';
import ColorSelector from 'Utils/ColorSelector';
import update from 'immutability-helper';
import DragDropContextWrapper from 'Utils/DragDropContextWrapper';
import Picture from './Picture';
import { AddCircle, Camera } from './SvgIcons';
import { arraysEqual } from 'HelperFunctions/general';

class ImageDropZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      pictures: [],
      selectedFile: null,
      cropperOpen: false,
      cropperColor: '#fff',
      isUploading: false,
    };

    this.handlePictureDelete = this.handlePictureDelete.bind(this);
    this.movePicture = this.movePicture.bind(this);
    this.handleCrop = this.handleCrop.bind(this);
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    if (!arraysEqual(this.props.pictures, nextProps.pictures)) {
      this.setState({ pictures: [...nextProps.pictures] });
    }
  }

  onDrop(acceptedFiles) {
    this.setState({
      cropperOpen: true,
      selectedFile: acceptedFiles[0],
    });
  }

  onDropZoneOpen() {
    this.dropzone.open();
  }

  movePicture(dragIndex, hoverIndex) {
    const { pictures } = this.state;
    const dragPicture = pictures[dragIndex];
    const newState = update(this.state, {
      pictures: {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, dragPicture],
        ],
      },
    });
    this.handleChange(newState.pictures);
    this.setState(newState);
  }

  handleCrop() {
    this.setState({ isUploading: true });
    this.refs.cropper
      .getCroppedCanvas({ fillColor: this.state.cropperColor })
      .toBlob((croppedFile) => {
        const newPictures = this.props.multiple
          ? [...this.state.pictures, { file: croppedFile }]
          : [{ file: croppedFile }];
        this.handleChange(newPictures);
        this.setState({
          pictures: newPictures,
          cropperOpen: false,
          selectedFile: null,
          isUploading: false,
        });
      });
  }

  handleCloseCropper() {
    this.setState({
      cropperOpen: false,
      selectedFile: null,
    });
  }

  handlePictureDelete(index) {
    const { pictures } = this.state;
    let newPictures = pictures.slice(0);
    newPictures[index]._destroy = 1;
    this.handleChange(newPictures);
    this.setState({ pictures: newPictures });
  }

  handleChange(pictures) {
    const newPictures = pictures.map((picture, index) => {
      return { ...picture, position: index };
    });
    const picturesToSubmit = newPictures.filter((picture) => picture.url);
    const filesToSubmit = newPictures.filter(
      (picture) => picture.file && picture._destroy !== 1
    );
    this.props.onChange(filesToSubmit, picturesToSubmit);
  }

  handleCropperColorChange(color) {
    this.setState({
      cropperColor: `rgba(${color.r}, ${color.g}, ${color.b}, ${color.a})`,
    });
  }

  renderDropZoneContent() {
    const { pictures } = this.state;
    const primaryPictureIndex = pictures.findIndex(
      (picture) => picture._destroy !== 1
    );

    if (primaryPictureIndex > -1) {
      return (
        <Picture
          index={primaryPictureIndex}
          picture={pictures[primaryPictureIndex]}
          onDelete={this.handlePictureDelete}
          movePicture={this.movePicture}
          draggable={this.props.multiple}
        />
      );
    } else {
      const { uploadTitle } = this.props;
      return (
        <div className='placeholder'>
          <Camera />
          {uploadTitle
            ? uploadTitle
            : this.props.multiple
            ? 'Upload Photos'
            : 'Upload Photo'}
        </div>
      );
    }
  }

  renderAdditionalSection() {
    const { pictures } = this.state;
    const primaryPictureIndex = pictures.findIndex(
      (picture) => picture._destroy !== 1
    );
    return (
      <ul>
        {pictures.map(
          (picture, index) =>
            picture._destroy !== 1 &&
            index !== primaryPictureIndex && (
              <li key={index}>
                <Picture
                  index={index}
                  picture={picture}
                  onDelete={this.handlePictureDelete}
                  movePicture={this.movePicture}
                  draggable={this.props.multiple}
                />
              </li>
            )
        )}
        {this.renderUploadButtons()}
      </ul>
    );
  }

  renderUploadButtons() {
    var buttons = [];
    const { pictures } = this.state;
    let additionalPictureCount = Math.max(
      0,
      pictures.filter((picture) => picture._destroy !== 1).length - 1
    );
    for (var i = 0; i < 3 - additionalPictureCount; i++) {
      buttons.push(
        <li key={i}>
          <a
            className='btnLink add'
            onClick={() => {
              this.onDropZoneOpen();
            }}
          >
            <AddCircle />
          </a>
        </li>
      );
    }
    return buttons;
  }
  render() {
    const { aspectRatio, multiple } = this.props;
    const { cropperOpen, selectedFile, isUploading } = this.state;

    return (
      <div className='uploadZone'>
        <Dropzone
          className='drop'
          accept='image/jpeg, image/png'
          ref={(node) => {
            this.dropzone = node;
          }}
          onDropAccepted={(acceptedFiles) => this.onDrop(acceptedFiles)}
        >
          {this.renderDropZoneContent()}
        </Dropzone>
        {multiple && this.renderAdditionalSection()}

        <Modal
          open={cropperOpen}
          large={true}
          toggle={() => this.handleCloseCropper()}
          title='Upload Image'
          actions={[
            <button
              id='upload_image_upload'
              className='btn'
              disabled={isUploading}
              onClick={() => this.handleCrop()}
            >
              Upload
            </button>,
            <button
              id='upload_image_cancel'
              className='btn cancel'
              onClick={() => this.handleCloseCropper()}
            >
              Cancel
            </button>,
          ]}
        >
          {selectedFile !== null && (
            <div>
              <Cropper
                className='cropper'
                ref='cropper'
                src={selectedFile.preview}
                aspectRatio={aspectRatio}
                guides={false}
              />
              <ColorSelector
                defaultColor={{ r: 255, g: 255, b: 255, a: 1 }}
                onChange={this.handleCropperColorChange.bind(this)}
              />
            </div>
          )}
        </Modal>
      </div>
    );
  }
}
ImageDropZone.defaultProps = {
  aspectRatio: 16 / 9,
};
export default DragDropContextWrapper(ImageDropZone);

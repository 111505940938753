import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import Accordion from "Utils/AccordionRouting";
import Marker from "Utils/Marker";
import { FormattedTime, FormattedDate } from "react-intl";
import { Settings } from "Utils/SvgIcons";
import { Link } from "react-router-dom";
import momentTimezone from "moment-timezone";
import moment from "moment";
import { displayTimeIcon } from "../../../../helper_functions/rentals";

class Stop extends React.Component {
  state = {
    open: true,
    setupTime: 0
  };

  UNSAFE_componentWillMount() {
    const { stop } = this.props;
    this.setState({
      setupTime: stop.setupTime
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const { stop } = nextProps;
    this.setState({
      setupTime: stop.setupTime
    });
  }

  renderRental = stop => {
    const timeZone = momentTimezone.tz.guess();
    const { rental } = stop.rentalTransport;
    const {
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressLocationName,
      deliveryAddressPostalCode,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCountry,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressLocationName,
      pickupAddressPostalCode,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupAddressCountry,
      pickupSameAsDelivery,
      rentalItems: items,
      schedule,
      hasSetByTime,
      setByTimeDateTime,
      setByTimeNotes
    } = stop.rentalTransport.rental;
    const type = stop.rentalTransport.transportType;

    let client = rental.customerRentalRelationships[0]?.client ?? '';
    let customer = rental.customerRentalRelationships.find(
      crr => crr.clientType === "CustomerLocationRelationship" && crr.isPrimaryContact
    );
    if (!customer) {
      customer = rental.customerRentalRelationships[0];
    }
    else {
      client = customer.client;
    }

    const { companyRentalRelationship } = rental;
    const allItems = rental.rentalItems
      .concat(rental.rentalAccessories)
      .concat(rental.rentalAddOns)
      .concat(rental.rentalBundles);
    return (
      <div className="details">
        <div className="routeContact">
          <p className="rentalName">{rental.name}</p>
          <Link
            className="btnLink config"
            to={`/operations/routing/map/locations/${stop.rentalTransport.id}`}
          >
            <Settings />
          </Link>
        </div>
        {type === "pick_up" && !pickupSameAsDelivery ? (
          <div className="routeAddress">
            {pickupAddressLocationName && (
              <strong>{pickupAddressLocationName}</strong>
            )}
            <div>{pickupAddressStreetAddress1}</div>
            {pickupAddressStreetAddress2 && (
              <div># {pickupAddressStreetAddress2}</div>
            )}
            <div>
              {pickupAddressCity}
              {", "}
              {pickupAddressLocale} {pickupAddressPostalCode}
              {", "}
              {pickupAddressCountry}
            </div>
          </div>
        ) : (
          <div className="routeAddress">
            {deliveryAddressLocationName && (
              <strong>{deliveryAddressLocationName}</strong>
            )}
            <div>{deliveryAddressStreetAddress1}</div>
            {deliveryAddressStreetAddress2 && (
              <div># {deliveryAddressStreetAddress2}</div>
            )}
            <div>
              {deliveryAddressCity}
              {", "}
              {deliveryAddressLocale} {deliveryAddressPostalCode}
              {", "}
              {deliveryAddressCountry}
            </div>
          </div>
        )}
        {stop.stopType !== "custom" &&
          (stop.rentalTransport.transportType !== "drop_off" ? (
            <div className="routeDelivery" style={{ display: 'flex' }}>
              { displayTimeIcon(stop.rentalTransport) }
              <div style={{ paddingLeft: '10px' }} >
                <strong>Pick-up Window</strong>
                <div>
                  <FormattedTime
                    value={schedule.endWindowBeginning}
                  />
                  {" "}-{" "}
                  <FormattedTime
                    value={schedule.endTime}
                  />
                </div>
              </div>
            </div>
          ) : (
            <div className="routeDelivery" style={{ display: 'flex' }}>
              { displayTimeIcon(stop.rentalTransport) }
              <div style={{ paddingLeft: '10px'}}>
                <strong>Delivery Window</strong>
                <div>
                  <FormattedTime
                    value={schedule.startTime}
                  />
                  {" "}-{" "}
                  <FormattedTime
                    value={schedule.startWindowFinish}
                  />
                </div>
              </div>
            </div>
          ))}
        {hasSetByTime &&
          type === "drop_off" &&
          <div className="routeSetByTime">
            <strong>
              <label>
                Set By Time
              </label>
              <label>
                <FormattedDate
                  value={
                    new Date(moment(setByTimeDateTime).tz(timeZone))
                  }
                  month="numeric"
                  year="numeric"
                  day="numeric"
                />
              </label>
              <label>
                <FormattedTime
                  value={
                    new Date(moment(setByTimeDateTime).tz(timeZone))
                  }
                />
              </label>
            </strong>
            {setByTimeNotes && <div>{setByTimeNotes}</div>}
          </div>
        }
        {
          (companyRentalRelationship && companyRentalRelationship.isPrimaryContact) &&
          <div className="routeContact">
            <h4>Company</h4>
            <div className="caption">Primary</div>
            <strong>{companyRentalRelationship.company.name}</strong>
          </div>
        }
        {
          customer && customer.clientType === "CustomerLocationRelationship" && (
            <div className="routeContact">
              <h4>Customer</h4>
              {customer.isPrimaryContact && <div className="caption">Primary</div>}
              <strong>{client.finalInfo.name}</strong>
              {client.finalInfo.cell ? (
                <div>{client.finalInfo.cell}</div>
              ) : (
                <div>{client.finalInfo.office}</div>
              )}
              {client.finalInfo.email && <div>{client.finalInfo.email}</div>}
            </div>
          )
        }
        {
          companyRentalRelationship &&
          !companyRentalRelationship.isPrimaryContact &&
          <div className="routeContact">
            <h4>Company</h4>
            <strong>{companyRentalRelationship.company.name}</strong>
          </div>
        }
        <div className="routeItems">
          <strong>Items</strong>
          {allItems.map(item => (
            <div key={item.id}>
              <label>{item.quantity}</label>
              {item.name}
              {item.bundleId &&
                item.rentalItems
                  .concat(item.rentalAccessories)
                  .concat(item.rentalAddOns)
                  .map(
                    ri =>
                      ri && (
                        <div key={ri.id} className="indent">
                          <label>{ri.quantity}</label>
                          {ri.name}
                        </div>
                      )
                  )}
            </div>
          ))}
        </div>
      </div>
    );
  };

  renderAddress(stop) {
    const {
      deliveryAddressCity,
      deliveryAddressLocale,
      deliveryAddressPostalCode,
      deliveryAddressStreetAddress1,
      deliveryAddressStreetAddress2,
      deliveryAddressCountry,
      pickupAddressCity,
      pickupAddressLocale,
      pickupAddressPostalCode,
      pickupAddressStreetAddress1,
      pickupAddressStreetAddress2,
      pickupSameAsDelivery,
      pickupAddressCountry
    } = stop.rentalTransport.rental;

    const type = stop.rentalTransport.transportType;

    return (
      <p>
        {type === "pick_up" && !pickupSameAsDelivery ? (
          <strong>
            {pickupAddressStreetAddress1}{" "}
            {pickupAddressStreetAddress2 && `# ${pickupAddressStreetAddress2}`}{" "}
            {pickupAddressCity}
            {", "}
            {pickupAddressLocale} {pickupAddressPostalCode}
            {", "}
            {pickupAddressCountry}
          </strong>
        ) : (
          <strong>
            {deliveryAddressStreetAddress1}{" "}
            {deliveryAddressStreetAddress2 &&
              `# ${deliveryAddressStreetAddress2}`}{" "}
            {deliveryAddressCity}
            {", "}
            {deliveryAddressLocale} {deliveryAddressPostalCode}
            {", "}
            {deliveryAddressCountry}
          </strong>
        )}
      </p>
    );
  }
  renderPhysicalAddress(stop, display_type) {
    const {
      city,
      locale,
      postalCode,
      streetAddress1,
      streetAddress2,
      country
    } = stop.physicalAddress;
    const stop_name = stop.stopName;
    const type = display_type;
    return (
      <p>
        {type && type === "listing" ? (
          <span>
            {
              <strong>
                {stop_name} {streetAddress1}{" "}
                {streetAddress2 && `# ${streetAddress2}`} {city}
                {", "}
                {locale} {postalCode}
                {", "}
                {country}
              </strong>
            }
          </span>
        ) : (
          <strong>

            {stop_name && (
              <span>
                {stop_name}
                {", "}
              </span>
            )}
            {streetAddress1}
            {streetAddress2 && <span># {streetAddress2} </span>}
            <span>
              {city}
              {", "}
              {locale} {postalCode}
              {", "}
              {country}
            </span>

          </strong>
        )}
      </p>
    );
  }

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  render() {
    const {
      stop,
      leg,
      stopNumber,
      truck
    } = this.props;
    const { setupTime } = this.state;

    return (
      <div className='stopContainer'>
        <Accordion
          forceOpen={true}
          key={'accordion'}
          onToggle={this.toggleOpen}
          useHeaderAsLink={false}
          header={
            <div>
              <div style={{ display: 'flex' }}>
                <Marker
                  type={'route'}
                  text={stopNumber}
                  truck={truck}
                  transportType={
                    stop.stopType === 'standard'
                      ? stop.rentalTransport.transportType
                      : null
                  }
                  rentalTransport={stop.rentalTransport}
                />
              </div>
              <div className='details'>
                {leg && (
                  <label>
                    {stop.stopType === 'standard' && this.renderAddress(stop)}
                    {stop.stopType === 'custom' &&
                      this.renderPhysicalAddress(stop)}
                    <span className='highlight'>
                      (Drive Time: {leg.duration})
                    </span>
                    Approx. Arrival: <FormattedTime value={leg.arrivalTime} />
                  </label>
                )}
              </div>
            </div>
          }
          content={
            <div>
              {stop.stopType === 'standard' && this.renderRental(stop)}
              <div className='setup'>
                <span className='transportTime'>{setupTime}</span>
                <span className='transportType'>
                  <label>Setup/Take-Down</label>
                </span>
              </div>
            </div>
          }
        />
      </div>
    );
  }
}
export default connect(null, actions)(Stop);

import { createStore, applyMiddleware } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import thunkMiddleware from 'redux-thunk';
import { routerMiddleware } from 'react-router-redux';
import createLogger from 'redux-logger';
import rootReducer from './reducers';
import callAPIMiddleware from './middleware/callAPIMiddleware';
import { createEpicMiddleware } from 'redux-observable';
import rootEpic from './epics/index';
import { history } from 'Components/Routes';

const routingMiddleware = routerMiddleware(history);

const epicMiddleware = createEpicMiddleware(rootEpic);

const loggerMiddleware = createLogger();
const enhancer = applyMiddleware(
  thunkMiddleware,
  callAPIMiddleware,
  routingMiddleware,
  epicMiddleware,
  loggerMiddleware
);

export default function configureStore(preloadedState) {
  return createStore(
    rootReducer,
    preloadedState,
    composeWithDevTools(enhancer)
  );
}

import * as actionTypes from 'Constants/redux';

export const openLoadingSpinner = (label) => {
  return {
    type: actionTypes.OPEN_LOADING_SPINNER,
    payload: {
      label,
    },
  };
};
export const closeLoadingSpinner = () => {
  return {
    type: actionTypes.CLOSE_LOADING_SPINNER,
  };
};

import React from 'react';
import classnames from 'classnames';
import { Spinner } from 'Utils/SvgIcons';

const LoadingSpinner = ({
  loading = true,
  label = '',
  style = '',
  containerClassName = '',
  containerStyle = '',
  ...other
}) => {
  if (!loading) {
    return <div />;
  }

  return (
    <div
      className={classnames('spinner', {
        [containerClassName]: !!containerClassName,
      })}
      style={containerStyle}
    >
      <Spinner style={style} />
      {label && <p>{label}</p>}
    </div>
  );
};

LoadingSpinner.defaultProps = {
  containerStyle: {},
  style: {},
};

export default LoadingSpinner;

import React from "react";

const MonthSelect = props => {
  const { name, value, onChange, ...other } = props;
  return (
    <select {...other} name={name} value={value} onChange={onChange}>
      <option value="select_month">Select Month</option>
      <option value={1}>{"January (01)"}</option>
      <option value={2}>{"February (02)"}</option>
      <option value={3}>{"March (03)"}</option>
      <option value={4}>{"April (04)"}</option>
      <option value={5}>{"May (05)"}</option>
      <option value={6}>{"June (06)"}</option>
      <option value={7}>{"July (07)"}</option>
      <option value={8}>{"August (08)"}</option>
      <option value={9}>{"September (09)"}</option>
      <option value={10}>{"October (10)"}</option>
      <option value={11}>{"November (11)"}</option>
      <option value={12}>{"December (12)"}</option>
    </select>
  );
};

export default MonthSelect;

import React, { Component } from "react"
import { connect } from "react-redux";
import Modal from "./Modal";
import * as actions from "Actions";
import { Spinner } from "Utils/SvgIcons";
class GlobalCircleOfDeathErrorModal extends Component {
  renderContent = () => {
    const {
      loading, resetSuccess
    } = this.props;

    if (loading) {
      return <div className="spinner">
        <Spinner />
      </div>
    }

    if (resetSuccess) {
      return <span>
        <p>
          The dates and settings of the rental have been reset! Please <b>ask the customer to verify both the rental date and rental settings</b>.
        </p>
        <button
          className="btn"
          onClick={() => {
            window.location.reload()
          }} >
          Okay
        </button>
      </span>
    }

    return <span>
      <p>
        The rental ("{this.props.name}") has been corrupted.
      </p>
      <button
        className="btn"
        onClick={this.props.resetRentalScheduleAndSettings}>
        Reset Rental
      </button>
    </span>
  }


  render() {
    const { modalOpen } = this.props;
    return (
      <Modal
        open={modalOpen}
        hideCloseButton={true}>
        <div style={{ marginTop: 16 }} />
        {this.renderContent()}
      </Modal>
    )
  }
}

const mapStateToProps = state => {
  const { modalOpen, loading, resetSuccess } = state.circleOfDeathError
  const { name } = state.rental.rental
  return { modalOpen, name, loading, resetSuccess };
}

export default connect(mapStateToProps, actions)(GlobalCircleOfDeathErrorModal);
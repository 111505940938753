export const getSubTotal = (workOrder) => {
  return workOrder.workOrderLineItems.reduce((total, lineItem) => total + (lineItem.rate * lineItem.amount), 0)
}

export const getTotalWithTax = (workOrder) => {
  return getSubTotal(workOrder) * (1 + (workOrder.taxRate / 100))
}

// export const getTotalForLineItemsTable = (workOrder, type) => {
//   const lineItems = workOrder.workOrderLineItems?.filter(lineItem => lineItem.lineItemType === type)
//   return lineItems?.reduce((total, lineItem) => (
//     total + (lineItem.rate * lineItem.amount)
//   ), 0)
// }

// accepts lineItems & taxRate instead of entire workOrder.
export const getTotalUsingLineItems = (lineItems, taxRate) => {
  return getTotalForLineItems(lineItems) * (1 + taxRate / 100)
}

// This one is different because it accepts lineItems.
export const getTotalForLineItems = (lineItems) => {
  return lineItems.reduce((total, lineItem) => (total + getTotalForLineItem(lineItem)), 0)
}

export const getTotalForLineItem = (lineItem) => (lineItem.rate * lineItem.amount)
import {
  UPDATE_SERVICE_TICKET_REQUEST,
  UPDATE_SERVICE_TICKET_SUCCESS,
  UPDATE_SERVICE_TICKET_FAILURE,
} from 'Constants/redux';

const INITIAL_STATE = {
  serviceTicket: {
    token: '',
    status: '',
    serviced: false,
    routed: false,
    business_signature: '',
    customer_signature: '',
    start_date_time: '',
    end_date_time: '',
    street_address_1: '',
    street_address_2: '',
    city: '',
    locale: '',
    postal_code: '',
    rental_staff_id: '',
  },
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_SERVICE_TICKET_REQUEST:
      return { ...state, loading: true };
    case UPDATE_SERVICE_TICKET_FAILURE:
      return { ...state, loading: false };
    case UPDATE_SERVICE_TICKET_SUCCESS:
      const newState = action.response
        ? action.response.serviceTicket
        : INITIAL_STATE.serviceTicket;

      return {
        ...state,
        errors: {},
        serviceTicket: { ...state.serviceTicket, ...newState },
        loading: false,
      };
    default:
      return state;
  }
};

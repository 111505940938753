import { TOGGLE_ADD_INVENTORY_MODAL } from 'Constants/redux';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';

export const toggleAddInventoryModal = (
  status: boolean,
  rentalSectionId: number | string | null = null,
): ThunkAction<void, AppState, unknown, RentalFormActionTypes> => (
  dispatch
) => {
  dispatch({
    type: TOGGLE_ADD_INVENTORY_MODAL,
    status,
    rentalSectionId,
  });
};

import {
  RESET_PARENT_ORDER_FORM,
  NEW_UPDATE_RENTAL_REQUEST,
  NEW_SUBMIT_RENTAL_REQUEST,
  SET_COLLAPSABLE_DAILY_ORDER,
} from '../constants/redux';
import { cloneDeep } from 'lodash';
import { camelToSnakeCase } from 'json-style-converter/es5';
import {
  RentalInput,
  RoomActivitySettingEnum,
  SectionMembership,
} from '../types/graphql';
import PricingService from '../helper_functions/rentalForm/pricing_service';
import { propagateContactChangesToSecondaryOrders } from './utils/propagateContactChangesToSecondaryOrders';

const storedValue = window.localStorage.getItem('collapsableDailyOrder');

const dailyOrdersCollapsed =
  storedValue !== null ? JSON.parse(storedValue) : true;

const INITIAL_STATE: Partial<RentalInput> & {
  loading: boolean;
  dailyOrdersCollapsed: boolean;
} = {
  id: '',
  loading: false,
  finalPaymentDueDateDaysNum: null,
  finalPaymentDueDateDaysOption: '',
  finalPaymentDueDateType: 'off',
  manualDamageWaiver: false,
  damageWaiverFeeTotal: 0,
  noCostSubrentalTransfer: false,
  eventId: null,
  rentalSettingAttributes: {
    hideEventTimes: false,
    hideEventDates: false,
    hideDeliveryInfo: false,
    hideDeliveryTimes: false,
    hasCustomerSurvey: false,
  },
  depositFixedFee: 0,
  depositPercent: 0,
  depositRequired: true,
  depositType: 'optional',
  customDepositAmount: 0,
  shouldDepositOneFullPayment: false,
  paymentNotification1Enabled: false,
  paymentNotification1Num: null,
  paymentNotification2Enabled: false,
  paymentNotification2Num: null,
  paymentNotification3Enabled: false,
  paymentNotification3Num: null,
  paymentNotificationDaysOption: null,
  paymentNotificationType: 'off',
  salesTaxSetting: 'default',
  manualSalesTaxName: '',
  manualSalesTaxPercent: 0,
  damageWaiverTaxExempt: false,
  deliveryTaxEnabled: true,
  gstTaxExempt: false,
  pstTaxExempt: false,
  taxExempt: false,
  paymentTerm: null,
  signatureRequired: false,
  expensesAttributes: [],
  rentalAgreementId: null,
  rentalAgreementAttributes: {},
  salesTaxRentalRelationshipsAttributes: [],
  name: 'Default Parent Order',
  customId: '',
  referralSource: '',
  venueRentalRelationshipAttributes: null,
  secondaryOrdersAttributes: [],
  discountRentalRelationshipsAttributes: [],
  feeRentalRelationshipsAttributes: [],
  disclaimerRentalRelationshipsAttributes: [],
  locationAttributes: {},
  strikeDateTime: null,
  strikeTimeNotes: '',
  hasStrikeTime: false,
  type: 'ParentRental',
  // TODO: Initialize from location settings
  scheduleAttributes: {
    eventStartDateTime: new Date(),
    eventEndDateTime: new Date(),
  },
  taxCalculated: false,
  deliveryType: 'custom_delivery',
  dailyOrdersCollapsed,
};

const calculatePricing = (
  updatedPayload: Partial<RentalInput>,
  applyRoomActivitySettingPricing = false
) => {
  let parentRentalSectionsAttributes = [] as SectionMembership[];

  // cloneDeep has some issues while cloning complex objects with circular references, which is the case of our payload
  // Using cloneDeep on this function was causing problems with circular references
  // JSON.parse + JSON.stringify is a way to clone objects with key-value pairs without functions
  // It has other limitations so be careful when using this method to clone payloads
  // It works on this method because the payload is compatible
  const clonedPayload = JSON.parse(JSON.stringify(updatedPayload));

  const PRICEABLE_ENTITIES_PER_ROOM_ACTIVITY_SETTING: {
    [key in RoomActivitySettingEnum]: string[];
  } = {
    set_up: ['RentalStaff'],
    dark: ['RentalStaff'],
    active: [
      'RentalStaff',
      'RentalItem',
      'RentalItemTemporary',
      'RentalBundle',
      'RentalAddOn',
    ],
  };

  let totalDeliveryCost = 0;

  clonedPayload.secondaryOrdersAttributes?.forEach(
    (secondaryOrderAttributes, secondaryOrderIndex) => {
      let dailyOrdersDeliveryCost = 0;

      secondaryOrderAttributes.dailyRentalsAttributes?.forEach(
        (dailyRentalAttributes, dailyRentalIndex) => {
          if (
            dailyRentalAttributes._destroy != '1' &&
            secondaryOrderAttributes._destroy != '1'
          )
            dailyOrdersDeliveryCost +=
              Number(dailyRentalAttributes.deliveryCost) || 0;

          dailyRentalAttributes.rentalSectionsAttributes?.forEach(
            (rentalSectionAttributes, rentalSectionIndex) => {
              rentalSectionAttributes.sectionMembershipsAttributes?.forEach(
                (sectionMembershipAttributes) => {
                  if (!sectionMembershipAttributes.rentalInventoryAttributes) {
                    return;
                  }

                  if (
                    sectionMembershipAttributes?.rentalInventoryType ===
                    'RentalStaff'
                  ) {
                    const inTime =
                      dailyRentalAttributes.scheduleAttributes
                        ?.eventStartDateTime;
                    const outTime =
                      dailyRentalAttributes.scheduleAttributes
                        ?.eventEndDateTime;

                    Object.assign(
                      sectionMembershipAttributes.rentalInventoryAttributes
                        ?.staffScheduleAttributes || {},
                      { inTime, outTime }
                    );
                  }

                  sectionMembershipAttributes.rentalInventoryAttributes.startDateTime =
                    dailyRentalAttributes.scheduleAttributes?.eventStartDateTime;
                  sectionMembershipAttributes.rentalInventoryAttributes.endDateTime =
                    dailyRentalAttributes.scheduleAttributes?.eventEndDateTime;
                  sectionMembershipAttributes.rentalInventoryAttributes.entityIndexes = {
                    secondaryOrderIndex,
                    dailyRentalIndex,
                    rentalSectionIndex,
                  };

                  const roomActivitySetting = (dailyRentalAttributes.roomActivitySetting ||
                    'active') as RoomActivitySettingEnum;

                  const entityPriceable = applyRoomActivitySettingPricing
                    ? PRICEABLE_ENTITIES_PER_ROOM_ACTIVITY_SETTING[
                        roomActivitySetting
                      ].includes(
                        sectionMembershipAttributes?.rentalInventoryType
                      )
                    : true;

                  // Tell the pricing service to note include items from destroyed daily orders
                  // This solution is needed instead of filtering out the destroyed daily rentals because the pricing calculations use
                  // the indexes of the daily rentals to aggregate the pricing of the same item in different daily rentals
                  // If the daily rentals are filtered out, the indexes will be different and the pricing will be wrong
                  if (
                    dailyRentalAttributes._destroy == '1' ||
                    !entityPriceable
                  ) {
                    sectionMembershipAttributes.rentalInventoryAttributes.removeFromPricing = true;
                  }
                }
              );
            }
          );

          parentRentalSectionsAttributes.push(
            dailyRentalAttributes.rentalSectionsAttributes
          );
        }
      );

      const secondaryOrderTotal =
        dailyOrdersDeliveryCost +
        (Number(secondaryOrderAttributes.additionalDeliveryCost) ?? 0);

      updatedPayload.secondaryOrdersAttributes![
        secondaryOrderIndex
      ].deliveryCost = secondaryOrderTotal;

      if (secondaryOrderAttributes._destroy != '1') {
        totalDeliveryCost += secondaryOrderTotal;
      }
    }
  );

  updatedPayload.deliveryCost = totalDeliveryCost;

  parentRentalSectionsAttributes = parentRentalSectionsAttributes.flat();

  const billingLines = new PricingService(
    camelToSnakeCase({
      ...clonedPayload,
      rentalSectionsAttributes: parentRentalSectionsAttributes,
      deliveryCost: totalDeliveryCost,
    })
  ).billingLinesForRental();

  return billingLines;
};

const applyCustomIdToSecondaryOrders = (
  customId: string | null,
  secondaryOrders: RentalInput[]
) => {
  return secondaryOrders.map((secondaryOrder) => ({
    ...secondaryOrder,
    customId,
  }));
};

const NewRentalReducer = (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_COLLAPSABLE_DAILY_ORDER:
      return {
        ...state,
        dailyOrdersCollapsed: action.payload,
      };
    case NEW_UPDATE_RENTAL_REQUEST:
      let updatedPayload = {
        ...state,
        ...propagateContactChangesToSecondaryOrders({
          action,
          rental: state,
        }),
      };

      const billingLines = calculatePricing(
        updatedPayload,
        action.pricingConfig?.applyRoomActivitySettingPricing
      );

      updatedPayload = {
        ...updatedPayload,
        billingLines,
        ...(!updatedPayload.manualDamageWaiver && {
          damageWaiverFeeTotal: billingLines.find(
            (line) => line.name === 'Damage Waiver Fee Total'
          ).finalValue,
        }),
      };

      updatedPayload.secondaryOrdersAttributes = applyCustomIdToSecondaryOrders(
        updatedPayload.customId,
        updatedPayload.secondaryOrdersAttributes
      );

      return updatedPayload;
    case NEW_SUBMIT_RENTAL_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case RESET_PARENT_ORDER_FORM:
      return cloneDeep(INITIAL_STATE);
    default:
      return state;
  }
};

export default NewRentalReducer;

import {
  CustomerRentalRelationship,
  CustomerRentalRelationshipInput,
  RentalInput,
} from './../../types/graphql';
import { cloneDeep } from '@apollo/client/utilities';
import {
  PropagateContactChangesToSecondaryOrders,
  UpdateSecondaryOrdersRentalContactAttributes,
} from './propagateContactChangesToSecondaryOrders.type';

// This function updates the secondary orders contacts with the parent orders' ones
// If what is being updated is a secondary order or the payload does not contain contact attributes,
// it returns the payload as is
export const propagateContactChangesToSecondaryOrders = ({
  rental,
  action,
}: PropagateContactChangesToSecondaryOrders) => {
  const isSecondaryOrder = 'secondaryOrdersAttributes' in action.payload;

  const contactAttributes: (keyof RentalInput)[] = [
    'companyRentalRelationshipAttributes',
    'customerRentalRelationshipsAttributes',
  ];

  const noContactAttributeOnActionPayload = !contactAttributes.some(
    (attribute) => attribute in action.payload
  );

  const shouldNotUpdateSecondaryOrders =
    isSecondaryOrder || noContactAttributeOnActionPayload;

  if (shouldNotUpdateSecondaryOrders) {
    return {
      ...action.payload,
    };
  }

  const clonedSecondaryOrders = cloneDeep(rental.secondaryOrdersAttributes);

  contactAttributes.forEach((attribute) => {
    updateSecondaryOrdersRentalContactAttribute({
      rentals: clonedSecondaryOrders,
      attribute,
      action,
    });
  });

  return {
    ...action.payload,
    secondaryOrdersAttributes: clonedSecondaryOrders,
  };
};

const updateSecondaryOrdersRentalContactAttribute = ({
  action,
  rentals,
  attribute,
}: UpdateSecondaryOrdersRentalContactAttributes) => {
  if (attribute in action.payload) {
    rentals?.forEach((rental) => {
      let payload = null;

      if (attribute === 'companyRentalRelationshipAttributes') {
        payload = {
          ...action.payload[attribute],
          id: '',
        };
      } else if (attribute === 'customerRentalRelationshipsAttributes') {
        payload = action.payload[attribute].map(
          (customerAttribute: CustomerRentalRelationshipInput) => {
            return {
              ...customerAttribute,
              id: '',
            };
          }
        );
      }

      Object.assign(rental, {
        [attribute]: payload ?? action.payload[attribute],
      });
    });
  }
};

import { standardService } from 'Api/service';
import { ROOT_URL, saveError } from 'Api';

export const requestAndDispatchSetter = (
  requestType,
  successType,
  failureType,
  planType
) => {
  return (dispatch, getState) => {
    dispatch({ type: requestType });
    standardService
      .get(`${ROOT_URL}/admin/pricing_tiers/${planType}`)
      .then((response) => {
        return dispatch({
          type: successType,
          payload: response.data,
        });
      })
      .catch((error) => {
        saveError(error);
        console.log(error);
        dispatch({
          error,
          type: failureType,
        });
        alert(`There was a problem ${planType} plan`);
      });
  };
};

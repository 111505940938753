import {
  FETCH_TRUCK_ROUTE_LIST_REQUEST,
  FETCH_TRUCK_ROUTE_LIST_SUCCESS,
  FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST,
  FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS,
  SET_ROUTING_TRUCK_FILTER,
  FETCH_ORDER_ROUTE_LIST_REQUEST,
  FETCH_ORDER_ROUTE_LIST_SUCCESS,
  FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST,
  FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS,
  SET_ROUTING_ORDER_FILTER,
} from 'Constants/redux';
import service from 'Api/service';

import moment from 'moment-timezone';
import { setRentalTransportFilter } from './RentalTransportListActions';

export const loadTruckRoutes = ({ startDate, endDate }) => {
  const timeZone = moment.tz.guess();
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_TRUCK_ROUTE_LIST_REQUEST,
    });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/routing/truck_routes/search',
        {
          time_zone: timeZone,
          start_date: startDate,
          end_date: endDate,
        }
      )
      .then((response) => {
        const newState = {
          truckRoutes: response.data.truckRoutes,
        };
        dispatch({
          type: FETCH_TRUCK_ROUTE_LIST_SUCCESS,
          payload: newState,
        });
      });
  };
};

export function setRoutingTruckFilter(filter) {
  return {
    type: SET_ROUTING_TRUCK_FILTER,
    payload: { filter },
  };
}
export const loadTruckRoutesbyDeliveryDate = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      startDate,
      truckIds,
      employeeIds,
      transportType,
      rentalStatus,
      showServiceTickets,
    } = getState().truckRouteList.filter;
    dispatch({
      type: FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST,
    });
    const timeZone = moment.tz.guess();
    let delivery_date = moment(startDate).format('YYYY/MM/DD');
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          '/api/routing/truck_routes/search_by_delivery_date',
        {
          deliveryDate: delivery_date,
          truck_ids: truckIds.join(','),
          employee_ids: employeeIds.join(','),
          transport_type: transportType,
          rental_status: rentalStatus,
          show_service_tickets: showServiceTickets,
          time_zone: timeZone,
          ...(prioritize_sticky && { prioritize_sticky: true }),
        }
      )
      .then((response) => {
        const newState = {
          filteredTruckRoutes: response.data.rentalTransports,
        };
        dispatch({
          type: FETCH_TRUCK_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS,
          payload: newState,
        });
      });
  };
};
export function setRoutingTruckFilterAndReload(filter) {
  return (dispatch, getState) => {
    dispatch(setRoutingTruckFilter(filter));
    dispatch(
      setRentalTransportFilter({
        startDate: filter.startDate,
        endDate: filter.startDate,
      })
    );

    dispatch(loadTruckRoutesbyDeliveryDate());
  };
}

export const loadOrderRoutes = ({ startDate, endDate }) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_ORDER_ROUTE_LIST_REQUEST,
    });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/routing/truck_routes/search',
        {
          start_date: startDate,
          end_date: endDate,
        }
      )
      .then((response) => {
        const newState = {
          truckRoutes: response.data.truckRoutes,
        };
        dispatch({
          type: FETCH_ORDER_ROUTE_LIST_SUCCESS,
          payload: newState,
        });
      });
  };
};

export function setRoutingOrderFilter(filter) {
  return {
    type: SET_ROUTING_ORDER_FILTER,
    payload: { filter },
  };
}
export const loadOrderRoutesByDeliveryDate = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      startDate,
      truckIds,
      employeeIds,
      transportType,
      rentalStatus,
      showServiceTickets,
    } = getState().orderRouteList.filter;
    dispatch({
      type: FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST,
    });
    const timeZone = moment.tz.guess();

    let beginningOfDay = new Date(JSON.parse(JSON.stringify(startDate))).setHours(0,0,0)
    let endOfDay = new Date(JSON.parse(JSON.stringify(startDate))).setHours(23,59,59);
    let delivery_date = moment.tz(beginningOfDay, timeZone).format('YYYY-MM-DDTHH:mm:ss.sssZ');
    let end_delivery_date = moment.tz(endOfDay, timeZone).format('YYYY-MM-DDTHH:mm:ss.sssZ');

    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          '/api/routing/truck_routes/search_by_delivery_date',
        { start_date_time_gte: new Date(delivery_date),
          start_date_time_lte: new Date(end_delivery_date),
          ...(truckIds.length > 0 ? { truck_ids: truckIds.join(',') } : {}),
          ...(employeeIds.length > 0 ? { employee_ids: employeeIds.join(',') } : {}),
          ...(transportType ? { transport_type: transportType } : {}),
          rental_status: rentalStatus,
          ...(showServiceTickets ? { show_service_tickets: showServiceTickets } : {}),
          ...(prioritize_sticky && { prioritize_sticky: true }),
        }
      )
      .then((response) => {
        const newState = {
          filteredTruckRoutes: response.data.rentalTransports,
        };
        dispatch({
          type: FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS,
          payload: newState,
        });
      });
  };
};
export function setRoutingOrderFilterAndReload(filter) {
  return (dispatch, getState) => {
    dispatch(setRoutingOrderFilter(filter));
    dispatch(loadOrderRoutesByDeliveryDate());
    dispatch(
      setRentalTransportFilter({
        startDate: filter.startDate,
        endDate: filter.endDate,
      })
    );
  };
}

import {
  UPDATE_RENTAL_FORM_V2_REQUEST,
  CREATE_RENTAL_FORM_V2_SUCCESS,
  FETCH_RENTAL_FORM_V2_SUCCESS,
  FETCH_RENTAL_FORM_V2_REQUEST,
  UPDATE_RENTAL_FORM_V2_SUCCESS,
  RENTAL_FORM_V2_FAILURE,
  TOGGLE_RENTAL_FORM_V2_LOADING,
} from 'Constants/redux';
import {
  TOGGLE_ITEMS_MODAL,
  TOGGLE_ADD_INVENTORY_MODAL,
  TOGGLE_ADD_INVENTORY_TO_BUNDLE_MODAL,
  TOGGLE_TAX_CALCULATED,
  TOGGLE_ADD_SERVICE_MODAL,
  TOGGLE_SERIALIZED_ITEM_DRAWER,
  RESET_RENTAL_FORM_V2,
  SET_RENTAL_FORM_V2_TYPE,
  TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL,
} from '../constants/redux';
import RentalVersion from '../types/RentalVersion';
import PricingService from '../helper_functions/rentalForm/pricing_service';
import { camelToSnakeCase } from 'json-style-converter/es5';
import { cloneDeep } from '@apollo/client/utilities';
import Inventory from '../components/rentals/form/Inventory';

const INITIAL_STATE: RentalVersion = {
  id: null,
  rentalId: null,
  data: {},
  loading: true,
  isItemsModalOpen: false,
  addInventoryModal: {
    open: false,
    rentalSectionId: '',
  },
  nonDiscountablesAlertModal: {
    open: false,
    kind: 'itemAdded',
    nonDiscountableItems: [],
  },
  addInventoryToBundleModal: {
    open: false,
    rentalSectionId: '',
    rentalBundle: {},
  },
  taxCalculated: true,
  addServiceModal: {
    open: false,
    rentalSectionRvId: '',
    serializedItemDrawer: {
      open: false,
      productId: null,
      product: {} as Inventory,
    },
  },
  type: 'new',
};

// Date attributes on the top-level of the store
const SHALLOW_DATE_ATTRIBUTES = [
  'flipDateTime',
  'setByTimeDateTime',
  'strikeDateTime',
  'customExpireDate',
];

const SCHEDULE_DATE_ATTRIBUTES = [
  'eventEnd',
  'eventEndDateTime',
  'eventEndTime',
  'eventStart',
  'eventStartDateTime',
  'eventStartTime',
  'endDateTime',
  'startDateTime',
  'flipDateTime',
  'setByTimeDateTime',
  'strikeDateTime',
  'checkin',
  'checkinTime',
  'checkinWindowStart',
  'checkout',
  'checkoutTime',
  'checkoutWindowEnd',
  'offShelfAt',
  'onShelfAt',
  'endWindowBeginning',
  'startWindowFinish',
];

const parseDates = (data, dateFields) => {
  Object.entries(data).forEach(([key, value]) => {
    if (dateFields.includes(key) && value !== null) {
      data[key] = new Date(value);
    }
  });
};

const RentalFormV2Reducer = (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case RESET_RENTAL_FORM_V2:
      return cloneDeep(INITIAL_STATE);
    case UPDATE_RENTAL_FORM_V2_REQUEST:
    case FETCH_RENTAL_FORM_V2_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case CREATE_RENTAL_FORM_V2_SUCCESS:
      parseDates(action.payload.rentalVersion.data, SHALLOW_DATE_ATTRIBUTES);

      parseDates(
        action.payload.rentalVersion.data.scheduleAttributes,
        SCHEDULE_DATE_ATTRIBUTES
      );

      return {
        ...state,
        ...action.payload.rentalVersion,
        currentRentalStatus: action.payload.rentalVersion.data.status,
        pricing: new PricingService(
          camelToSnakeCase(action.payload.rentalVersion.data)
        ).billingLinesForRental(),
        loading: false,
        taxCalculated:
          action.payload.rentalVersion.data.deliverySettingAttributes
            .defaultDelivery !== 'customer_pick_up',
      };
    case FETCH_RENTAL_FORM_V2_SUCCESS:
      parseDates(action.payload.rentalVersion.data, SHALLOW_DATE_ATTRIBUTES);

      parseDates(
        action.payload.rentalVersion.data.scheduleAttributes,
        SCHEDULE_DATE_ATTRIBUTES
      );

      return {
        ...state,
        ...action.payload.rentalVersion,
        currentRentalStatus: action.payload.rentalVersion.data.status,
        loading: false,
      };
    case UPDATE_RENTAL_FORM_V2_SUCCESS:
      parseDates(action.payload, SHALLOW_DATE_ATTRIBUTES);

      if (action.payload.scheduleAttributes)
        parseDates(action.payload.scheduleAttributes, SCHEDULE_DATE_ATTRIBUTES);

      const completeRentalVersion = { ...state.data, ...action.payload };

      return {
        ...state,
        data: { ...state.data, ...action.payload },
        pricing: new PricingService(
          camelToSnakeCase(completeRentalVersion)
        ).billingLinesForRental(),
        loading: false,
      };
    case TOGGLE_ITEMS_MODAL:
      return {
        ...state,
        isItemsModalOpen: action.status,
      };
    case TOGGLE_ADD_INVENTORY_MODAL:
      return {
        ...state,
        addInventoryModal: {
          ...state.addServiceModal,
          open: action.status,
          rentalSectionId: action.rentalSectionId,
          serializedItemDrawer: {
            open: false,
            productId: null,
            product: {},
          },
        },
      };
    case TOGGLE_ADD_INVENTORY_TO_BUNDLE_MODAL:
      return {
        ...state,
        addInventoryToBundleModal: {
          open: action.status,
          rentalSectionId: action.rentalSectionId,
          rentalBundle: action.rentalBundle,
          serializedItemDrawer: {
            open: false,
            productId: null,
            product: {},
          },
        },
      };
    case TOGGLE_TAX_CALCULATED:
      return {
        ...state,
        taxCalculated: action.status,
      };
    case TOGGLE_ADD_SERVICE_MODAL:
      return {
        ...state,
        addServiceModal: {
          open: action.status,
          rentalSectionRvId: action.rentalSectionRvId,
        },
      };
    case TOGGLE_NON_DISCOUNTABLES_ALERT_MODAL:
      return {
        ...state,
        nonDiscountablesAlertModal: {
          open: action.status,
          nonDiscountableItems: action.nonDiscountableItems,
          kind: action.kind,
          onSubmit: action.onSubmit,
        },
      };
    case TOGGLE_SERIALIZED_ITEM_DRAWER:
      return {
        ...state,
        addInventoryModal: {
          ...state.addInventoryModal,
          serializedItemDrawer: {
            open: action.status,
            productId: action.productId,
            product: action.product,
          },
        },
      };
    case RENTAL_FORM_V2_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case TOGGLE_RENTAL_FORM_V2_LOADING:
      return {
        ...state,
        loading: action.status,
      };
    case SET_RENTAL_FORM_V2_TYPE:
      return {
        ...state,
        type: action.payload,
      };
    default:
      return state;
  }
};

export default RentalFormV2Reducer;

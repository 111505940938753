import React from "react";
import reactCSS from "reactcss";
import { ChromePicker } from "react-color";

class ColorSelector extends React.Component {
  state = {
    displayColorPicker: false,
    color: {
      r: "255",
      g: "255",
      b: "255",
      a: "1"
    }
  };
  componentDidMount = () => {
    const { defaultColor } = this.props;

    this.setState({
      color: defaultColor
    });
  };

  handleClick = () => {
    this.setState({ displayColorPicker: !this.state.displayColorPicker });
  };

  handleClose = () => {
    this.setState({ displayColorPicker: false });
  };

  handleChange = color => {
    const { onChange } = this.props;
    this.setState({ color: color.rgb });
    onChange(color.rgb);
  };

  render() {
    const { color } = this.state;
    const styles = reactCSS({
      default: {
        color: {
          background: `rgb(${this.state.color.r}, ${this.state.color.g}, ${this.state.color.b})`
        },
        popover: {
          position: "absolute",
          zIndex: "2"
        },
        cover: {
          position: "fixed",
          top: "0px",
          right: "0px",
          bottom: "0px",
          left: "0px"
        }
      }
    });
    return (
      <div className="colorSelector">
        <div className="swatch" onClick={this.handleClick}>
          <label>Background Color</label>
          <div style={styles.color} />
        </div>
        {this.state.displayColorPicker ? (
          <div style={styles.popover}>
            <div style={styles.cover} onClick={this.handleClose} />
            <ChromePicker
              color={this.state.color}
              onChange={this.handleChange}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

export default ColorSelector;

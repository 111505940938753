import converter from 'json-style-converter/es5';
import CurrencyFormatter from 'HelperFunctions/CurrencyFormatter';

export function getIndex(value, arr, prop) {
  for (var i = 0; i < arr.length; i++) {
    if (arr[i][prop] === value) {
      return i;
    }
  }
  return -1; //to handle the case where the value doesn't exist
}
export function nestedFieldStateFor(name, count, base_state) {
  let holder = [];
  for (var i = 0; i < count; i++) {
    holder.push({
      key: i,
      [name]: base_state,
    });
  }
  return holder;
}

export function range(start, end) {
  return Array(end - start + 1)
    .fill()
    .map((_, idx) => start + idx);
}

export function formatSmartPrincingLabel(rentalItem, formatter) {
  switch (rentalItem.period) {
    case 'hourly_price':
      return `${formatter.format(rentalItem.hourlyPrice)}/Hour`;
    case 'half_day_price':
      return `${formatter.format(rentalItem.halfDayPrice)}/Half-Day`;
    case 'daily_price':
      return `${formatter.format(rentalItem.dailyPrice)}/Day`;
    case 'weekly_price':
      return `${formatter.format(rentalItem.weeklyPrice)}/Week`;
    case 'monthly_price':
      return `${formatter.format(rentalItem.monthlyPrice)}/Month`;
  }
}

const aboutToBeReserved = (selectedProduct) => {
  return (
    Number(selectedProduct?.quantity || 0) -
    Number(selectedProduct?.reservedQuantity || 0)
  );
};

export const getItemAvailabilityFromStore = (
  currentItem,
  itemType,
  inventoryHolds = {}
) => {
  const currentItemType = getAvailabilityKey(itemType);
  const idType = getItemIdType(itemType);
  const currentItemId = currentItem[idType];

  return inventoryHolds?.[currentItemType]?.[currentItemId];
};

export const getRemainingAmountInRental = (
  allItems,
  currentItem,
  itemType,
  inventoryHolds = {}
) => {
  const idType = getItemIdType(itemType);
  const currentItemId = currentItem[idType];
  const itemAvailability = getItemAvailabilityFromStore(
    currentItem,
    itemType,
    inventoryHolds
  );

  let numberAvailable = Number(
    itemAvailability?.available - itemAvailability?.maintenance
  );
  let amountRemaining = numberAvailable;
  if (!amountRemaining && itemType === 'rental_add_on') {
    amountRemaining = currentItem.addOn.quantity;
  }

  const itemContainerFound = allItems.find((itemContainer) => {
    const item = itemContainer[itemType];

    return (
      item[idType] === currentItemId &&
      item._destroy !== '1' &&
      !itemContainer.duplicate
    );
  });

  if (itemContainerFound) {
    const item = itemContainerFound[itemType];
    let product;
    if (item.type === 'RentalItemTemporary') {
      product = item;
    } else if (itemType === 'rental_add_on') {
      product = item?.addOn;
    } else if (itemType == 'rental_item') {
      product = item?.product;
    } else {
      product = item?.accessory;
    }
    const productQuantity = parseInt(product?.quantity);
    if (productQuantity !== numberAvailable) {
      amountRemaining -= aboutToBeReserved(item);
    } else {
      amountRemaining -= Number(item.quantity);
    }
  }

  return amountRemaining;
};

// TODO: Refactor to support direct items (not just rental items)
export const getItemIdType = (itemType) => {
  switch (itemType) {
    case 'rental_item':
      return 'productId';
    case 'rental_accessory':
      return 'accessoryId';
    case 'rental_add_on':
      return 'addOnId';
  }
};

export const getAvailabilityKey = (itemType) => {
  switch (itemType) {
    case 'rental_item':
      return 'products';
    case 'rental_accessory':
      return 'accessories';
    case 'rental_add_on':
      return 'addOns';
    case 'rental_bundle':
      return 'bundles';
  }
};

export function newStateForNested(
  prevArr,
  key,
  name,
  newModelState,
  position = null
) {
  let array = prevArr.slice();
  let index = getIndex(key, array, 'key');
  if (index === -1) {
    array.push({
      key: key,
      originalIndex: position,
      [name]: newModelState,
    });
  } else {
    let oldModelState = array[index];
    oldModelState[name] = Object.assign(oldModelState[name], newModelState);
  }

  return array;
}

export function removeNestedObject(prevArr, key, name) {
  let destroyObject = {
    _destroy: '1',
  };
  let newState = newStateForNested(prevArr, key, name, destroyObject);
  return newState;
}

export function canRemoveObjectFromArray(array, name) {
  let removedObjectsCount = 0;
  for (let object of array) {
    if (object[name]._destroy === '1') {
      removedObjectsCount++;
    }
  }
  return removedObjectsCount < array.length - 1;
}

export function validObjectsCountOfArray(array, name) {
  let removedObjectsCount = 0;
  for (let object of array) {
    if (object[name]._destroy === '1') {
      removedObjectsCount++;
    }
  }
  return array.length - removedObjectsCount;
}

export function nestedStateForRails(containerArray, indName) {
  let attributes = {};
  for (let indObj of containerArray) {
    attributes[indObj.key] = indObj[indName];
  }
  return attributes;
}
export function nestedStateForRailsWithConversion(containerArray, indName) {
  let attributes = {};
  let key = 0;
  let newArr = converter.camelToSnakeCase(containerArray);
  let name = converter.camelToSnakeCase(indName);
  for (let indObj of newArr) {
    let newObj = indObj[name];
    attributes[key] = newObj;
    key += 1;
  }
  return attributes;
}

export function prepopulateArray(nextKey, array, name) {
  let holder = [];

  for (let item of array) {
    holder.push({
      key: nextKey,
      originalIndex: item.membershipPosition,
      [name]: Object.assign(item, { sectionId: item.rentalSectionId }),
    });
    nextKey += 1;
  }
  return [holder, nextKey];
}

export function prepopulateArrayWithoutIds(nextKey, array, name) {
  let holder = [];
  for (let item of array) {
    let tempObj = {
      key: nextKey,
      originalIndex: item.membershipPosition,
      [name]: Object.assign(item, { sectionId: item.rentalSectionId }),
    };
    tempObj[name].id = '';
    holder.push(tempObj);
    nextKey += 1;
  }
  return [holder, nextKey];
}

export function objectToFormData(obj, form, namespace) {
  var fd = form || new FormData();
  var formKey;
  for (var property in obj) {
    if (obj.hasOwnProperty(property)) {
      if (namespace) {
        formKey = namespace + '[' + property + ']';
      } else {
        formKey = property;
      }

      // if the property is an object, but not a File,
      // use recursivity.
      if (
        typeof obj[property] === 'object' &&
        !(
          obj[property] instanceof File ||
          obj[property] instanceof Blob ||
          obj[property] instanceof Date
        )
      ) {
        objectToFormData(obj[property], fd, formKey);
      } else {
        // if it's a string or a File object
        fd.append(formKey, obj[property]);
      }
    }
  }

  return fd;
}

export function updateObject(oldObject, newValues) {
  // Encapsulate the idea of passing a new object as the first parameter
  // to Object.assign to ensure we correctly copy data instead of mutating
  return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array, itemKey, updateItemCallback) {
  const updatedItems = array.map((item) => {
    if (item.key !== itemKey) {
      // Since we only want to update one item, preserve all others as they are now
      return item;
    }

    // Use the provided callback to create an updated item
    const updatedItem = updateItemCallback(item);
    return updatedItem;
  });

  return updatedItems;
}

export function objectToQueryString(object, name) {
  return Object.keys(object)
    .filter((key) => object[key] != null)
    .map((key) => `${name}[${key}]=${object[key]}`)
    .join('&');
}
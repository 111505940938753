import {
  COMBINED_INVENTORY_SEARCH,
  COMBINED_INVENTORY_SUCCESS,
} from 'Constants/redux';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
import { fetchInventoryAvailability } from 'Actions/AvailabilityActions';
import { availabilityRequestBodyFromCombinedInventory } from '../helper_functions/availability';

const fetchCombinedInventoryEpic = (action$) =>
  action$
    .ofType(COMBINED_INVENTORY_SEARCH)
    .debounceTime(500)
    .switchMap((action) => {
      return Rx.Observable.fromPromise(
        service.post(
          `${process.env.REACT_APP_API_DOMAIN}${action.link}`,
          action.options
        )
      ).flatMap((response) => {
        const returnObservables = [
          {
            options: action.options,
            link: action.link,
            reset: true,
            response: response,
            productType: action.productType,
            type: COMBINED_INVENTORY_SUCCESS,
          },
        ];

        // Only fetch availability if a date interval is provided.
        // A flag could also be used.
        const { start_date, end_date } = action.options;

        if (start_date && end_date) {
          const serializedInventory = response.data.inventories.filter(
            (inventory) => inventory.isSerialized
          );
          const notSerializedInventory = response.data.inventories.filter(
            (inventory) => !serializedInventory.includes(inventory)
          );
          const availabilityBodySerialized = availabilityRequestBodyFromCombinedInventory(
            serializedInventory
          );
          const availabilityBodyNotSerialized = availabilityRequestBodyFromCombinedInventory(
            notSerializedInventory
          );

          const availabilitySerializedRequest = fetchInventoryAvailability({
            startDate: start_date,
            endDate: end_date,
            ...availabilityBodySerialized,
            rental_statuses: ['in_use', 'reservation'],
          });

          const availabilityNotSerializedRequest = fetchInventoryAvailability({
            startDate: start_date,
            endDate: end_date,
            ...availabilityBodyNotSerialized,
          });

          returnObservables.push(
            availabilitySerializedRequest,
            availabilityNotSerializedRequest
          );
        }

        return returnObservables;
      });
    });

export default fetchCombinedInventoryEpic;

import {
  FETCH_STAFF_ROLE_NAMES_REQUEST,
  FETCH_STAFF_ROLE_NAMES_SUCCESS,
  ADD_STAFF_ROLE_NAMES_REQUEST,
  ADD_STAFF_ROLE_NAMES_SUCCESS,
  SET_STAFF_ROLE_QUERY,
  SET_STAFF_ROLE_PAGINATION,
  SET_STAFF_ROLE_PAGINATION_PAGE,
  FETCH_STAFF_ROLE_REQUEST,
  FETCH_STAFF_ROLE_SUCCESS,
  FETCH_STAFF_ROLE_FAILURE,
} from 'Constants/redux';
import { SET_STAFF_ROLE_ATTRIBUTES } from '../constants/redux';

const INITIAL_STATE = {
  query: '',
  per: 10,
  names: [],
  firstId: '',
  pagesRemaining: false,
  loading: false,
  infiniteLoading: false,
  pagination: {},
  staffRole: {},
  staffRoleType: null,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STAFF_ROLE_NAMES_REQUEST:
    case ADD_STAFF_ROLE_NAMES_REQUEST:
      return { ...state, firstId: '', infiniteLoading: true };
    case FETCH_STAFF_ROLE_NAMES_SUCCESS:
      const { names, pagination, firstId } = action.payload;
      return {
        ...state,
        loading: false,
        infiniteLoading: false,
        names,
        firstId,
        pagination,
        pagesRemaining: names.length !== pagination.totalObjects,
      };
    case ADD_STAFF_ROLE_NAMES_SUCCESS:
      const newPagination = action.payload.pagination;
      const newNames = [...state.names, ...action.payload.names];
      return {
        ...state,
        infiniteLoading: false,
        names: newNames,
        pagination: newPagination,
        pagesRemaining: newNames.length !== newPagination.totalObjects,
      };
    case SET_STAFF_ROLE_QUERY:
      return {
        ...state,
        query: action.payload.query,
      };
    case SET_STAFF_ROLE_ATTRIBUTES:
      return {
        ...state,
        ...action.payload,
      };
    case SET_STAFF_ROLE_PAGINATION:
      return {
        ...state,
        per: action.payload.perPage,
      };
    case SET_STAFF_ROLE_PAGINATION_PAGE:
      return {
        ...state,
        page: action.payload.page,
      };
    case FETCH_STAFF_ROLE_REQUEST:
      return { ...state, infiniteLoading: true };
    case FETCH_STAFF_ROLE_SUCCESS:
      return {
        ...state,
        staffRole: action.response.data.staffRole,
        loading: false,
      };
    case FETCH_STAFF_ROLE_FAILURE:
      return { ...state, loading: false, infiniteLoading: false };
    default:
      return state;
  }
};

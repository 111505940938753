import {
  FETCH_CONVERSATION_LIST_SUCCESS,
  ADD_UNREAD_MESSAGE
} from "Constants/redux";
import createReducer from "../lib/CreateReducer";

const INITIAL_STATE = {
  conversations: [],
  unreadMessages: 0
};

export default createReducer(INITIAL_STATE, {
  [FETCH_CONVERSATION_LIST_SUCCESS]: (state, action) => {
    return {
      ...state,
      conversations: action.payload.conversations,
      unreadMessages: action.payload.unreadMessages
    };
  },
  [ADD_UNREAD_MESSAGE]: (state, action) => {
    let newConversations = state.conversations.slice();

    if (!action.payload.status && action.payload !== "new unread message") {
      newConversations = state.conversations.map(conversation => {
        const { id, unreadMessages } = conversation;
        const { conversationId } = action.payload.message;

        if (id !== conversationId) {
          return conversation;
        }

        return {
          ...conversation,
          messages: [...conversation.messages, action.payload.message],
          unreadMessages: unreadMessages + 1
        };
      });
    }
    if (action.payload.status === "new unread message") {
      newConversations.unshift({
        ...action.payload.conversation,
        unreadMessages: 1
      });
    }

    return {
      ...state,
      conversations: newConversations,
      unreadMessages: state.unreadMessages + 1
    };
  }
});

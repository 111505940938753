import {
  GET_PRICING_TIER_STANDARD_REQUEST,
  GET_PRICING_TIER_SELECT_REQUEST,
  GET_PRICING_TIER_PREMIER_REQUEST,
  GET_PRICING_TIER_ESSENTIALS_REQUEST,
  GET_PRICING_TIER_STANDARD_SUCCESS,
  GET_PRICING_TIER_SELECT_SUCCESS,
  GET_PRICING_TIER_PREMIER_SUCCESS,
  GET_PRICING_TIER_ESSENTIALS_SUCCESS,
  GET_PRICING_TIER_STANDARD_FAILURE,
  GET_PRICING_TIER_SELECT_FAILURE,
  GET_PRICING_TIER_PREMIER_FAILURE,
  GET_PRICING_TIER_ESSENTIALS_FAILURE
} from "Constants/redux";
import { requestAndDispatchSetter } from '../helper_functions/requestAndDispatchSetter';

export const getPricingTierStandard = (data) => {
  const { planType } = data;
  return requestAndDispatchSetter(GET_PRICING_TIER_STANDARD_REQUEST, GET_PRICING_TIER_STANDARD_SUCCESS, GET_PRICING_TIER_STANDARD_FAILURE, planType)
};

export const getPricingTierSelect = (data) => {
  const { planType } = data;
  return requestAndDispatchSetter(GET_PRICING_TIER_SELECT_REQUEST, GET_PRICING_TIER_SELECT_SUCCESS, GET_PRICING_TIER_SELECT_FAILURE, planType)
};

export const getPricingTierPremier = (data) => {
  const { planType } = data;
  return requestAndDispatchSetter(GET_PRICING_TIER_PREMIER_REQUEST, GET_PRICING_TIER_PREMIER_SUCCESS, GET_PRICING_TIER_PREMIER_FAILURE, planType)
};

export const getPricingTierEssentials = (data) => {
  const { planType } = data;
  return requestAndDispatchSetter(GET_PRICING_TIER_ESSENTIALS_REQUEST, GET_PRICING_TIER_ESSENTIALS_SUCCESS, GET_PRICING_TIER_ESSENTIALS_FAILURE, planType)
};

import React from 'react';
import { Link } from 'react-router-dom';
import ScrollArea from 'Utils/react-scrollbar';
import { FormattedDate, FormattedTime } from 'react-intl';
import { AlertWhite } from 'Utils/SvgIcons';

class PicklistConversation extends React.Component {
  render() {
    const { conversation } = this.props;
    return (
      <div className='pickListUpdateMessage'>
        <div className='blue-box'>
          <div className='textSection'>
            <AlertWhite/>
            <p>
              Picklist Change
            </p>
          </div>
        </div>

        <div className='messageSection'>
          <ScrollArea speed={0.8} horizontal={false}>
            {conversation.messages.map((message) => {
              return (

                <div className='content'>
                  <div className='date time div'>
                    <time className='timeSection'>
                      <div className='date-div '>
                        {' '}
                        <FormattedDate
                          value={message.createdAt}
                          day='2-digit'
                          month='2-digit'
                        />
                      </div>
                      <div className='time'>
                        <FormattedTime value={message.createdAt} />
                      </div>
                    </time>
                  </div>
                  <p className='textArea'>
                    {`${message.body} `}
                    Click
                    <Link
                      to={`/orders/rentals/${conversation.rentalToken}/pickList`}
                    >
                      {' '}
                      here{' '}
                    </Link>
                    to print/review it.
                  </p>

                </div>
              );
            })

            }
          </ScrollArea>
        </div>
      </div>
    );
  }
}

export default PicklistConversation;

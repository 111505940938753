import React from 'react';
import Modal from 'Utils/Modal';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import Endpoints from 'Api/endpoints';
import SerializedInventoryTable from 'Utils/SerializedInventoryTable';
import {
  sortSerializedProducts,
  searchSerializedProducts,
  handleResponse,
} from 'Utils/SerializedProducts';
import LoadingSpinner from 'Utils/LoadingSpinner';
import Infinite from 'react-infinite';

class SerializedUniqueCheckedInModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      serializedProducts: [],
      serialNumber: '',
      isSerializedMode: true,
      checkedInQuantity: '',
      usageUnits: props.product ? props.product.usageUnits : [],
      textValues: { notes: '' },
      direction: false,
      loading: false,
      searching: false,
      pagination: {},
    };
  }

  componentDidMount() {
    if (this.props.product && this.props.product.usageUnits) {
      const { usageUnits } = this.props.product.usageUnits;

      this.fetchSerializedProducts();

      usageUnits &&
        usageUnits.length > 0 &&
        usageUnits.map((unit) => {
          this.setState({
            textValues: {
              ...this.state.textValues,
              [unit.name]: '',
            },
          });
        });
    }
  }

  componentWillReceiveProps(nextProps) {
    this.fetchSerializedProducts();
  }

  addRows = () => {
    const { pagination } = this.state;

    this.setState({ searching: true });

    if (pagination?.links?.next) {
      const link = pagination.links.next;

      this.fetchSerializedProductsFromLink(link);
    } else {
      this.setState({ loading: false });
    }
  };

  getDataFromSerialNumber = async (value) => {
    const { product } = this.props;
    const serialNumber = value.trim();

    return Endpoints.serializedProducts
      .showBySerialNumber(product, serialNumber)
      .then((response) => {
        if (response.data && response.data !== null) {
          let { serializedProduct } = response.data;
          serializedProduct = {
            ...serializedProduct,
            checkIn: serializedProduct?.usageWithUnitDetails?.map((unit) => ({
              id: unit.unitId,
              label: unit.unitName,
              value: unit.usage,
              type: unit.unitType,
            })),
            checked: true,
          };
          return serializedProduct;
        } else {
          this.props.setErrorMessage({
            field: 'selected',
            text: `Serial Number ${serialNumber} not found`,
          });
          return {};
        }
      });
  };

  fetchSerializedProducts() {
    const { product, item, rental } = this.props;

    this.setState({ loading: true });

    Endpoints.serializedProducts
      .fetchSerializedProductsForCheckIn(product, rental.token)
      .then((response) => {
        if (response.data && response.data !== null) {
          const pagination = response.data.meta.pagination;
          let serializedProducts = response.data.serializedProducts;

          const { newSerializedProducts, newSelected } = handleResponse(
            serializedProducts,
            item,
            'checkIn',
            this.state
          );
          this.setState({
            selected: newSelected,
            serializedProducts: newSerializedProducts,
            pagination,
          });
        }
      })
      .catch((error) => {
        const { setErrors } = this.props;
        const errors = error?.response?.data;
        setErrors(errors);
      })
      .finally(() => {
        this.setState({ loading: false });
      });
  }

  fetchSerializedProductsFromLink(link) {
    const { item } = this.props;

    this.setState({ loading: true });

    Endpoints.serializedProducts
      .fetchSerializedProductsFromLink(link)
      .then((response) => {
        if (response.data && response.data !== null) {
          const pagination = response.data.meta.pagination;
          let serializedProducts = response.data.serializedProducts;

          const { newSerializedProducts, newSelected } = handleResponse(
            serializedProducts,
            item,
            'checkIn',
            this.state
          );

          this.setState({
            selected: newSelected,
            serializedProducts: newSerializedProducts,
            pagination,
          });
        }
      })
      .catch((error) => {
        const { setErrors } = this.props;
        const errors = error?.response?.data;
        setErrors(errors);
      })
      .finally(() => {
        this.setState({ loading: false, searching: false });
      });
  }

  handleChange = (event) => {
    const { name, value } = event.target;
    this.setState({
      [name]: value,
    });
  };

  performSearch = async (event) => {
    event.preventDefault();
    const { selected, serializedProducts } = this.state;
    let { serialNumber } = this.state;

    serialNumber = serialNumber.trim();

    this.setState({ searching: true });

    let newSerializedProducts = searchSerializedProducts(
      serialNumber,
      selected,
      serializedProducts
    );

    const isIncluded = newSerializedProducts.filter(
      (serializedProduct) => serializedProduct.serialNumber === serialNumber
    );

    if (isIncluded.length == 0) {
      let missingSerializedProduct;
      missingSerializedProduct = await this.getDataFromSerialNumber(
        serialNumber
      );

      if (!!missingSerializedProduct.serialNumber) {
        newSerializedProducts = [
          ...newSerializedProducts,
          missingSerializedProduct,
        ];
      }
    }

    const newSelected = newSerializedProducts.filter(
      (serializedProduct) => serializedProduct.checked
    );

    this.setState({
      serializedProducts: newSerializedProducts,
      selected: newSelected,
      searching: false,
      serialNumber: '',
    });
  };

  handleSubmit = () => {
    const { isSerializedMode } = this.state;
    const { afterSubmit } = this.props;
    if (isSerializedMode) {
      this.handleSerializedSubmit();
    } else {
      this.handleStandardSubmit();
    }
    if (afterSubmit) afterSubmit();
  };

  handleSerializedSubmit = () => {
    const { selected } = this.state;

    // Validation
    if (selected.length === 0) {
      this.props.setErrorMessage({
        field: 'selected',
        text: 'You need to select at least one serialized item.',
      });
    } else {
      const {
        rental,
        item,
        serializedUniqueCheckInRentalItem,
        toggle,
      } = this.props;
      const checkedInQuantity = selected.length;
      const checkedInIds = selected.map((s) => s.id);
      const usageValues = selected.map((item) => {
        return {
          serialized_product_id: item.id,
          unit: item.checkIn.map((usage) => {
            if (usage !== undefined && usage.value !== undefined) {
              return { id: usage.id, usage: usage.value };
            }
          }),
          notes: item.notes,
        };
      });
      serializedUniqueCheckInRentalItem(
        rental.id,
        item.id,
        checkedInQuantity,
        checkedInIds,
        usageValues
      );
      toggle();
    }
  };

  handleStandardSubmit = () => {
    const { checkedInQuantity } = this.state;

    // Validation
    if (checkedInQuantity < 0 || checkedInQuantity > this.props.item.quantity) {
      this.props.setErrorMessage({
        field: 'checkedInQuantity',
        text: 'Cannot checkout more than reserved',
      });
    } else {
      const { rental, item, checkInRentalItem, toggle } = this.props;
      checkInRentalItem(rental.id, item.id, checkedInQuantity);
      toggle();
    }
  };

  toggleIsSerializedMode = () => {
    this.setState({
      isSerializedMode: !this.state.isSerializedMode,
    });
  };

  closeModal = () => {
    const { product, toggle } = this.props;
    this.setState({
      selected: [],
      serialNumber: '',
      isSerializedMode: true,
      checkedInQuantity: '',
      usageUnits: product ? product.usageUnits : [],
      textValues: { notes: '' },
    });
    toggle();
  };

  updateSelected = (selected, checkInId) => {
    const newSelected = this.state.selected.map((s) => {
      if (s.serialNumber === selected.serialNumber) {
        let checkin = s.checkIn.find((ci) => ci.id == checkInId);
        checkin.value = selected.unit;
      }
      return s;
    });

    this.setState({ selected: newSelected });
  };

  checkSelected = (serializedProduct) => {
    const newSelected = this.state.serializedProducts.map((s) => {
      if (s.serialNumber === serializedProduct.serialNumber) {
        s.checked = !s.checked;
      }
      return s;
    });

    const selected = newSelected.filter(
      (serializedProduct) => serializedProduct.checked
    );

    this.setState({
      serializedProducts: newSelected,
      selected: selected,
    });
  };

  sortBySelected = () => {
    const { direction, serializedProducts } = this.state;
    this.setState({
      direction: !direction,
      serializedProducts: sortSerializedProducts(direction, serializedProducts),
    });
  };

  render() {
    const {
      product,
      item: { serializedPickListItems },
      open,
    } = this.props;
    const {
      serialNumber,
      selected,
      isSerializedMode,
      checkedInQuantity,
      textValues,
      serializedProducts,
      loading,
    } = this.state;

    return (
      <Modal
        open={open}
        toggle={this.closeModal}
        large={true}
        className='serializedModal checkin-modal'
        title='Check In Serialized Inventory'
        actions={[
          <a className='btn' onClick={this.handleSubmit}>
            Check In Items
          </a>,
          <a className='btn cancel' onClick={this.closeModal}>
            Cancel
          </a>,
        ]}
      >
        <div className='form'>
          <p>{product.name}</p>
          {isSerializedMode ? (
            <div>
              <form className='fullForm' onSubmit={this.performSearch}>
                <input
                  type='text'
                  name='serialNumber'
                  className='barcode'
                  onChange={this.handleChange}
                  value={serialNumber}
                />
                <input
                  type='submit'
                  value='Add Item to Check-In List'
                  className='btn'
                />
              </form>
              <section>
                <h4>Total Needed for Order</h4>
                <strong>{selected.length}</strong>
                <Infinite
                  className='infinite'
                  elementHeight={
                    40 * serializedProducts.length > 0
                      ? 40 * serializedProducts.length
                      : 40
                  }
                  preloadBatchSize={40 * serializedProducts.length}
                  useWindowAsScrollContainer={false}
                  containerHeight={300}
                  infiniteLoadBeginEdgeOffset={100}
                  onInfiniteLoad={this.addRows}
                  loadingSpinnerDelegate={<LoadingSpinner />}
                  isInfiniteLoading={loading}
                >
                  <SerializedInventoryTable
                    selected={selected}
                    serializedProducts={serializedProducts}
                    serializedPickListItems={serializedPickListItems}
                    updateSelected={this.updateSelected}
                    checkSelected={this.checkSelected}
                    sortBySelected={this.sortBySelected}
                    isCheckIn
                  />
                </Infinite>
              </section>
            </div>
          ) : (
            <div className='fields'>
              <p>How many items need to be Checked-In?</p>
              <input
                type='text'
                name='checkedInQuantity'
                className='sm'
                onChange={this.handleChange}
                value={checkedInQuantity}
              />
            </div>
          )}
          <a id='check_in_type_toggle' onClick={this.toggleIsSerializedMode}>
            {isSerializedMode
              ? 'Check In Serialized Items without using Serial Numbers'
              : 'Check In Serialized Items using Serial Numbers'}
          </a>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { rental } = ownProps.overwriteRental || state.rental;
  const { loading } = state.rental
  const { errors } = state.dashboard;
  return { rental, loading, errors };
};

export default connect(
  mapStateToProps,
  actions
)(SerializedUniqueCheckedInModal);

import { FETCH_ADD_ON_LIST_REQUEST } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";

const fetchAddOnListEpic = action$ =>
  action$
    .ofType(FETCH_ADD_ON_LIST_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.post(
          `${process.env.REACT_APP_API_DOMAIN}/api/add_ons/list?page=all`,
          action.options
        )
      ).map(response => {
        return actions.receiveAddOnList(response.data.addOns);
      });
    });

export default fetchAddOnListEpic;

import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import CustomProgressBar from "./CustomProgressBar";
import Modal from "Utils/Modal";
import classnames from "classnames";

class ProgressTracker extends React.Component {
  render() {
    const {
      complete,
      visible,
      successOpen,
      progressOpen,
      closeProgressTrackerSuccess,
      closeProgressTrackerStart,
      completedMessage,
      startMessage,
      title
    } = this.props;

    return (
      <div
        className={classnames({
          infoProgressBar: true,
          visible: visible
        })}
      >
        {visible && (
          <CustomProgressBar
            percent={complete}
            title={title}
          />
        )}
        <Modal
          open={progressOpen}
          toggle={closeProgressTrackerStart}
          title="Progress"
          actions={[
            <a
              id="progress_start_ok"
              className="btn full"
              onClick={closeProgressTrackerStart}
            >
              OK
            </a>
          ]}
        >
          <p>{startMessage}</p>
        </Modal>
        <Modal
          open={successOpen}
          toggle={closeProgressTrackerSuccess}
          title="Progress"
          actions={[
            <a
              id="progress_complete_ok"
              className="btn full"
              onClick={closeProgressTrackerSuccess}
            >
              OK
            </a>
          ]}
        >
          <p>{completedMessage}</p>
        </Modal>
      </div>
    );
  }
}
const mapStateToProps = state => {
  const {
    complete,
    visible,
    successOpen,
    importResponse,
    progressOpen,
    startMessage,
    completedMessage,
    title
  } = state.progressTracker;
  return {
    complete,
    visible,
    successOpen,
    importResponse,
    progressOpen,
    startMessage,
    completedMessage,
    title
  };
};

export default connect(mapStateToProps, actions)(ProgressTracker);

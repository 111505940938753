import {
  COMBINED_INVENTORY_RESET,
  COMBINED_INVENTORY_REQUEST,
  COMBINED_INVENTORY_SUCCESS,
  COMBINED_INVENTORY_FAILURE,
  COMBINED_INVENTORY_SET_QUERY,
  COMBINED_INVENTORY_SET_FILTER,
} from 'Constants/redux';
import { COMBINED_INVENTORY_SEARCH } from '../constants/redux';

const INITIAL_STATE = {
  categories: [],
  inventory: [],
  productType: 'all',
  numberPer: 10,
  page: 0,
  filter: {},
  query: '',
  pagination: {},
  loading: false,
  pagesRemaining: true,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case COMBINED_INVENTORY_RESET:
      const { shouldDeleteFilter } = action.payload;
      const filter = shouldDeleteFilter ? {} : state.filter;

      return {
        ...state,
        inventory: [],
        filter,
        loading: false,
        pagesRemaining: true,
        pagination: {},
      };
    case COMBINED_INVENTORY_REQUEST:
      return { ...state, loading: true };
    case COMBINED_INVENTORY_FAILURE:
      return { ...state, loading: false };
    case COMBINED_INVENTORY_SUCCESS:
      const newProducts = action.response.data.inventories;
      let totalProducts = newProducts;
      if (!action.reset) {
        totalProducts = state.inventory.concat(totalProducts);
      }
      const pagesRemaining =
        totalProducts.length <
        action.response.data.meta.pagination.totalObjects;
      return {
        ...state,
        inventory: totalProducts,
        pagination: action.response.data.meta.pagination,
        productType: action.productType,
        pagesRemaining: pagesRemaining,
        isInfiniteLoading: false,
        loading: false,
      };
    case COMBINED_INVENTORY_SEARCH:
      return {
        ...state,
        loading: true,
      };
    case COMBINED_INVENTORY_SET_QUERY:
      return {
        ...state,
        query: action.payload,
      };
    case COMBINED_INVENTORY_SET_FILTER:
      return {
        ...state,
        filter: action.payload,
      };
    default:
      return state;
  }
};

import classnames from "classnames";
import {
  Alert,
  Connect,
  WithSubRentals,
  CheckOnCircle,
  StatusCircle
} from "Utils/SvgIcons";
import React from "react";
import { stringify, parseQuery } from "HelperFunctions/QueryString";
import moment from "moment";
import { DollarsCircle } from "Utils/SvgIcons";
import ReactTooltip from "react-tooltip";

class DraggableToolTip extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      tooltipEnabled: false
    };
  }
  enableTooltip = () => {
    const { tooltipEnabled } = this.state;
    if (!tooltipEnabled) {
      this.setState({ tooltipEnabled: true }, () =>
        ReactTooltip.show(this.tip)
      );
    }
  };
  render() {
    const { tooltipEnabled } = this.state;
    const { tooltipProps, linkProps, tooltipContent } = this.props;

    const link = (
      <a key="link" onClick={this.enableTooltip} ref={ref => (this.tip = ref)} {...linkProps}>
        {this.props.children}
      </a>
    );
    let tooltip = null;
    if (tooltipEnabled) {
      tooltip = (
        <ReactTooltip key="tooltip" {...tooltipProps}>
          {tooltipContent}
        </ReactTooltip>
      );
    }
    return <div>{[link, tooltip]}</div>;
  }
}

export default DraggableToolTip;

import React from 'react';
import { useTheme } from 'styled-components';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import {
  TableCell,
  TableTotal,
  TableHeader,
  LightDivider,
  StrongDivider,
  TableContainer,
  TableCellRight,
} from './SecondaryOrdersInvoiceTotalsTable.styles';
import TGFormattedNumber from '../../../utils/TGFormattedNumber';
import { SecondaryOrdersInvoiceTotalsTableProps } from './SecondaryOrdersInvoiceTotalsTable.types';
import { formatDateWithOptionalYear } from '../../../../helper_functions/dates/formatDateWithOptionalYear';
import originalTerminologies from '../../../../helper_functions/originalTerminologies';

const SecondaryOrdersInvoiceTotalsTable = ({
  secondaryOrders,
}: SecondaryOrdersInvoiceTotalsTableProps) => {
  const { blue, darkblue } = useTheme() as StyledTheme;

  const secondaryOrdersSubtotal = secondaryOrders?.reduce(
    (acc, secondaryOrder) => acc + (secondaryOrder.rentalTotal?.subTotal ?? 0),
    0
  );

  const secondaryOrdersTotal = secondaryOrders?.reduce(
    (acc, secondaryOrder) =>
      acc + (secondaryOrder.rentalTotal?.overallTotal ?? 0),
    0
  );

  const renderSecondaryOrderInvoiceRows = () => {
    return secondaryOrders?.map((secondaryOrder, index) => {
      const isLastIndex = index === secondaryOrders.length - 1;
      const secondaryOrderTotal = secondaryOrder.rentalTotal?.overallTotal ?? 0;
      const { eventEndDateTime, eventStartDateTime } = secondaryOrder.schedule!;

      const secondaryOrderStart = formatDateWithOptionalYear({
        date: eventStartDateTime,
      });
      const secondaryOrderEnd = formatDateWithOptionalYear({
        date: eventEndDateTime,
        includeYear: true,
      });

      const dateLabel = `${secondaryOrderStart} - ${secondaryOrderEnd}`;

      const divider = isLastIndex ? (
        <StrongDivider color={darkblue} />
      ) : (
        <LightDivider />
      );

      return (
        <React.Fragment key={index}>
          <TableCell>{secondaryOrder?.name}</TableCell>
          <TableCell>{dateLabel}</TableCell>
          <TableCellRight>
            <TGFormattedNumber value={secondaryOrderTotal} />
          </TableCellRight>
          {divider}
        </React.Fragment>
      );
    });
  };

  return (
    <TableContainer>
      <TableHeader>
        <TableCell color={blue} bold>
          Secondary Orders
        </TableCell>
        <TableCell color={blue} bold>
          Dates
        </TableCell>
        <TableCellRight color={blue} bold>
          Secondary Orders Totals
        </TableCellRight>
      </TableHeader>
      <StrongDivider />
      {renderSecondaryOrderInvoiceRows()}
      <TableTotal>
        <TableCell />
        <TableCell bold>
          {originalTerminologies().secondaryOrderSingular}(s) Subtotal
        </TableCell>
        <TableCellRight bold>
          <TGFormattedNumber value={secondaryOrdersSubtotal} />
        </TableCellRight>
      </TableTotal>
      <StrongDivider />
      <StrongDivider marginTop={'4px'} />
      <TableTotal>
        <TableCell color={blue} bold>
          Total Due
        </TableCell>
        <TableCell bold>
          {originalTerminologies().secondaryOrderSingular}(s) Total
        </TableCell>
        <TableCellRight color={blue} bold>
          <TGFormattedNumber value={secondaryOrdersTotal} />
        </TableCellRight>
      </TableTotal>
    </TableContainer>
  );
};

export default SecondaryOrdersInvoiceTotalsTable;

import { REQUEST_CUSTOMER_NAMES, EPIC_ERROR } from 'Constants/redux';
import * as actions from 'Actions';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
const { Observable } = Rx;

const fetchCustomerNamesEpic = (action$) =>
  action$
    .ofType(REQUEST_CUSTOMER_NAMES)
    .debounceTime(500)
    .switchMap((action) => {
      const { numberPer: per, query, page, categories } = action.filter;
      return Rx.Observable.fromPromise(
        service.get(
          process.env.REACT_APP_API_DOMAIN +
            `/api/customer_location_relationships/names`,
          { per, query, page, categories }
        )
      )
        .map((response) => {
          return actions.receiveCustomerNames(response);
        })
        .catch((error) => {
          console.log('error', error);
          return Observable.of({
            type: EPIC_ERROR,
          });
        });
    });

export default fetchCustomerNamesEpic;

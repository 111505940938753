import React from "react";
import { connect } from "react-redux";
import OverlayLoadingSpinner from "./OverlayLoadingSpinner";

class GlobalLoadingSpinner extends React.Component {
  render() {
    const { loading, label } = this.props;
    return <OverlayLoadingSpinner loading={loading} label={label} />;
  }
}
const mapStateToProps = state => {
  const { label, loading } = state.loadingSpinner;

  return { label, loading };
};

export default connect(mapStateToProps)(GlobalLoadingSpinner);

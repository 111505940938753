import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import classnames from 'classnames';
import { FormattedTime } from 'react-intl';
import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';

class PrintDeliveryManifest extends React.Component {
  state = {
    route_data: null,
    errors: '',
    timeZone: null,
  };

  fetchDeliveryManifest() {
    const id = this.props.match.params.id;
    const { setErrors } = this.props;

    service
      .get(`${ROOT_URL}/routing/truck_routes/${id}/delivery_manifest`)
      .then((response) => {
        const route_data = response.data;
        this.setState({
          route_data: route_data,
          timeZone: route_data[0]?.stops[0]?.schedule?.time_zone,
        });
      })
      .catch((error) => {
        this.setState({ errors: error.response.data });
        setErrors(error.response.data);
      });
  }

  componentDidMount() {
    const { fetchTruckRoute } = this.props;
    fetchTruckRoute(this.props.match.params.id);
    this.fetchDeliveryManifest();
  }

  sanitizeStopAddress = (address = '') => {
    return address
      .split(', ')
      .filter((addressPart) => addressPart && addressPart.trim())
      .join(', ');
  };

  render() {
    const { route_data, timeZone, errors } = this.state;

    return (
      <div className='deliveryManifestPrintWrapper'>
        <div>
          <div className='content'>
            <div className='header'>
              <h1>Delivery Manifest</h1>
            </div>

            {!!route_data ? (
                <div className='driverWrapper'>
                  <div className='drivers'>
                    {route_data.drivers.length ? (
                      <>
                        <p>Driver(s):{'  '}
                          <ul>
                            {route_data.drivers.map((driver) => (
                              <li>
                                {driver}
                              </li>
                            ))}
                          </ul>
                        </p>
                      </>
                    ) : (
                      <p>Driver: No Driver Assigned</p>
                    ) }
                    <p>{`Delivery Date: ${route_data.delivery_date}`}</p>
                  </div>
                  <table className='driverTable'>
                    <tr>
                      <td className='blueFont va-top mt-15'>Truck</td>
                      <td className='mt-15'>
                        {route_data.truck && (
                          <ul>
                            <li>{route_data.truck.name}</li>
                          </ul>
                        )}
                      </td>
                    </tr>
                    <tr className='mt-27 va-bottom blueFont'>
                      <td>Leave Warehouse</td>
                      <td className='blankSpace'></td>
                      <td>Lunch</td>
                      <td className='blankSpace'></td>
                      <td>Till</td>
                      <td className='blankSpace'></td>
                    </tr>
                    <tr className='mt-27'>
                      <td className='blueFont va-top'>Helpers</td>
                      <td className='va-bottom' colSpan='5'>
                        {route_data.helpers && (
                          <ul>
                            {route_data.helpers.map((helper, index) => (
                              <li key={index}>{helper}</li>
                            ))}
                          </ul>
                        )}
                      </td>
                    </tr>
                  </table>

                  <div className='routes'>
                    <p>Route(s)</p>
                  </div>
                  <table className='bodyPadding'>
                    <tr className='routesHeader blueFont'>
                      <td width='10%'>Order</td>
                      <td width='20%'>Delivery Time</td>
                      <td width='20%'>Order Time</td>
                      <td width='20%'>Contact</td>
                      <td width='20%'>Address</td>
                      <td width='10%'>Delivery Method</td>
                    </tr>

                    {!!route_data.stops &&
                      route_data.stops.map((stop, index) => (
                        <tbody
                          key={index}
                          className={classnames({
                            grayRuler: route_data.stops.length > index + 1,
                            bodyPadding: true,
                            routesCell: true,
                          })}
                        >
                          <tr>
                            <td>
                              <b>{stop.order_name}</b>
                              {stop.order_id && `(#${stop.order_id})`}
                            </td>
                            {stop.delivery_method !== 'Custom' ? (
                              <td>
                                <FormattedTime
                                  value={stop.start_date_time}
                                  timeZone={timeZone}
                                />
                                {'-'}
                                <FormattedTime
                                  value={stop.end_date_time}
                                  timeZone={timeZone}
                                />
                                {stop.delivery_time}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            {stop.delivery_method != 'Custom' ? (
                              <td>
                                <FormattedTime
                                  value={stop.schedule.event_start_date_time}
                                  timeZone={timeZone}
                                />
                                {'-'}
                                <FormattedTime
                                  value={stop.schedule.event_end_date_time}
                                  timeZone={timeZone}
                                />
                                {stop.delivery_time}
                              </td>
                            ) : (
                              <td></td>
                            )}

                            <td>{stop.contact}</td>
                            <td>{this.sanitizeStopAddress(stop.address)}</td>
                            <td>{stop.delivery_method}</td>
                          </tr>
                          {stop.team_members && (
                            <tr className='noteRow'>
                              <td className='blueFont'>Team Member:</td>
                              <td colSpan='5'>{stop.team_members}</td>
                            </tr>
                          )}
                          {stop.has_set_by_time &&
                            stop.set_by_time_date_time && (
                              <tr className='noteRow'>
                                <td className='blueFont'>Set By Time:</td>
                                <td colSpan='5'>
                                  {stop.set_by_time_date_time}
                                </td>
                              </tr>
                            )}
                          {stop.has_set_by_time && stop.set_by_time_notes && (
                            <tr className='noteRow'>
                              <td colSpan='6' className='routesHeader'>
                                {stop.set_by_time_notes}
                              </td>
                            </tr>
                          )}
                          {stop.notes && stop.notes.length > 0 && (
                            <tr>
                              <td className='blueFont'>Notes:</td>
                            </tr>
                          )}
                          {stop.notes &&
                            stop.notes.map((note, index) => (
                              <tr key={index} className='noteRow'>
                                <td colSpan='6' className='routesHeader'>
                                  {note.replace(/(<([^>]+)>)/gi, '')}
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      ))}
                  </table>
                </div>

            ) : (
              <div className='drivers'>
                <p>{errors}</p>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { truckRoute } = state.truckRoute;
  return { truckRoute };
};

export default connect(mapStateToProps, actions)(PrintDeliveryManifest);

import {
  LOAD_PICK_LIST_STATUSES,
  LOAD_PICK_LIST_STATUSES_SUCCESS,
  LOAD_PICK_LIST_STATUSES_FAIL,
} from "Constants/redux";

const INITIAL_STATE = {
  loading: false,
  pickListStatuses: []
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOAD_PICK_LIST_STATUSES:
      return { ...state, loading: true };
    case LOAD_PICK_LIST_STATUSES_FAIL:
      return { ...state, loading: false };
    case LOAD_PICK_LIST_STATUSES_SUCCESS:
      const pickListStatuses = action.pickListStatuses;
      return {
        ...state,
        pickListStatuses,
        loading: false
      };
    default:
      return state;
  }
};

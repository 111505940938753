import React from 'react';
import { FormattedDate } from 'react-intl';
import Checkbox from 'Utils/Checkbox';
import classNames from 'classnames';
import Additive from 'Components/usage_units/Additive';
import Cumulative from 'Components/usage_units/Cumulative';
import Gauge from 'Components/usage_units/Gauge';

class SerializedRow extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usageOut: null,
    };
  }

  componentDidMount() {
    const { checkObj } = this.props;
    this.setState({ usageOut: checkObj?.value });
  }

  handleChange = (e, checkObjId) => {
    const { serializedProduct, updateSelected } = this.props;

    const value = e.target.value;

    this.setState({ usageOut: value }, () => {
      serializedProduct.unit = value;
      updateSelected(serializedProduct, checkObjId);
    });
  };

  handleCheckboxToggle = () => {
    const { serializedProduct, checkSelected } = this.props;
    checkSelected(serializedProduct);
  };

  render() {
    const { serializedProduct, checkObj, index } = this.props;

    return (
      <tr
        className={classNames({ noBorderTop: index !== 0 })}
        key={`${serializedProduct.serialNumber}-${index}`}
      >
        <td>
          {index === 0 && (
            <Checkbox
              name='checked'
              checked={serializedProduct.checked}
              onCheck={this.handleCheckboxToggle}
            />
          )}
        </td>
        <td>{index === 0 && serializedProduct.serialNumber}</td>

        {!!checkObj && <td>{checkObj.label}</td>}
        {!!checkObj && (
          <td>
            {checkObj.type === 'additive' && (
              <Additive
                name='usageOut'
                index={index}
                onChange={(e) => {
                  this.handleChange(e, checkObj.id);
                }}
                value={checkObj.value}
                disabled={!serializedProduct.checked}
              />
            )}
            {checkObj.type === 'cumulative' && (
              <Cumulative
                name='usageOut'
                index={index}
                onChange={(e) => {
                  this.handleChange(e, checkObj.id);
                }}
                value={checkObj.value}
                disabled={!serializedProduct.checked}
              />
            )}
            {checkObj.type === 'gauge' && (
              <Gauge
                name='usageOut'
                index={index}
                onChange={(e) => {
                  this.handleChange(e, checkObj.id);
                }}
                value={checkObj.value}
                disabled={!serializedProduct.checked}
              />
            )}
          </td>
        )}
      </tr>
    );
  }
}

export default SerializedRow;

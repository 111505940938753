import React from "react";
import { Link } from "react-router-dom";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import AppBar from "material-ui/AppBar";

class SignedOutNav extends React.Component {
  state = {
    open: false,
    signOut: false
  };

  toggleMobile = () => {
    this.setState({
      open: !this.state.open
    });
  };

  toggleSignOut = event => {
    this.setState({
      signOutOpen: !this.state.signOutOpen
    });
  };

  render() {
    return (
      <MediaBreakpoints
        desktop={
          <div className="header">
            <div className="mainMenu">
              <Link to="/" className="menuLogo">
                TapGoods
              </Link>
              <ul>
                <li>
                  <Link to="/login">Sign In</Link>
                </li>
              </ul>
            </div>
          </div>
        }
        mobile={
          <div className="header">
            <AppBar
              className="mobileMenu"
              title={
                <Link to="/" className="menuLogo">
                  TapGoods
                </Link>
              }
              showMenuIconButton={false}
              iconElementRight={<Link to="/login">Sign In</Link>}
            />
          </div>
        }
      />
    );
  }
}

export default SignedOutNav;

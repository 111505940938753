import { saveAuthToken, getAuthToken, saveError } from 'Api';
import axios from 'axios';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import { setContactType } from './ContactActions';

import {
  SET_COMPANY_QUERY,
  REQUEST_COMPANIES,
  REQUEST_COMPANY,
  REQUEST_INFINITE_COMPANIES,
  REQUEST_COMPANY_NAMES,
  ADD_COMPANY_NAMES_REQUEST,
  INFINITE_COMPANIES_SUCCESS,
  INFINITE_COMPANIES_FAILURE,
  RECEIVE_COMPANIES,
  RECEIVE_COMPANY,
  RECEIVE_COMPANY_NAMES,
  ADD_COMPANY_NAMES_SUCCESS,
  ADD_INFINITE_COMPANIES_SUCCESS,
  UPDATE_COMPANY_NAME_ON_LIST,
  SET_COMPANY_FILTER,
  SET_COMPANY_SELECTED_IDS_FOR_EXPORT,
} from 'Constants/redux';
import { history } from 'Components/Routes';
import service from 'Api/service';

export const setCompanyQuery = (query) => {
  return {
    type: SET_COMPANY_QUERY,
    payload: { query },
  };
};

function requestCompanies() {
  return {
    type: REQUEST_COMPANIES,
  };
}
function requestCompany() {
  return {
    type: REQUEST_COMPANY,
  };
}
const requestInfiniteCompanies = () => {
  return {
    type: REQUEST_INFINITE_COMPANIES,
  };
};
const infiniteCompaniesSuccess = (response) => {
  const companies = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: INFINITE_COMPANIES_SUCCESS,
    payload: { companies, pagination },
  };
};
const addInfiniteCompaniesSuccess = (response) => {
  const companies = response.data.names;
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_INFINITE_COMPANIES_SUCCESS,
    payload: { companies, pagination },
  };
};
const infiniteCompaniesFailure = () => {
  return {
    type: INFINITE_COMPANIES_FAILURE,
  };
};

function receiveCompanies(companies) {
  return {
    type: RECEIVE_COMPANIES,
    companies: companies,
    receivedAt: Date.now(),
  };
}
function receiveCompany(company) {
  return {
    type: RECEIVE_COMPANY,
    company: company,
    receivedAt: Date.now(),
  };
}
export const receiveCompanyNames = (response) => {
  const names = response.data.names;
  const pagination = response.data.meta.pagination;
  let firstId = '';
  if (names.length > 0) {
    firstId = names[0].token;
  }
  return {
    type: RECEIVE_COMPANY_NAMES,
    payload: { names, pagination, firstId },
  };
};
export const addCompanyNamesSuccess = (response) => {
  const { data } = response;
  const names = data.companies || data.names || [];
  const pagination = response.data.meta.pagination;
  return {
    type: ADD_COMPANY_NAMES_SUCCESS,
    payload: { names, pagination },
  };
};

function fetchCompanies() {
  return (dispatch, getState) => {
    dispatch(requestCompanies());

    dispatch(setContactType('companies'));

    return axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/companies/`, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(receiveCompanies(response.data.companies));
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}
export const fetchCompanyNames = () => {
  return (dispatch, getState) => {
    dispatch(setContactType('companies'));
    const { filter } = getState().companies;
    dispatch({
      type: REQUEST_COMPANY_NAMES,
      filter,
    });
  };
};

export const updateCompanyNameOnList = (response) => {
  const { company } = response.data;
  const { id, name } = company;
  return {
    type: UPDATE_COMPANY_NAME_ON_LIST,
    payload: { id, name },
  };
};

function fetchCompany(id) {
  return (dispatch, getState) => {
    dispatch(requestCompany());
    return axios
      .get(process.env.REACT_APP_API_DOMAIN + `/api/companies/` + id, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(receiveCompany(response.data.company));
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        saveError(error, getState());
      });
  };
}
function shouldFetchCompanies(state) {
  const companies = state.companies;
  if (!companies) {
    return true;
  } else if (companies.isFetching) {
    return false;
  } else {
    return companies.shouldRefresh;
  }
}
function shouldFetchCompanyNames(state) {
  const companies = state.companies;
  if (!companies) {
    return true;
  } else if (companies.fetchingNames) {
    return false;
  } else {
    return companies.shouldRefresh;
  }
}
function shouldFetchSingleCompany(state) {
  const company = state.company;
  if (!company) {
    return true;
  } else if (company.loading) {
    return false;
  } else {
    return company.shouldRefresh;
  }
}

export const deleteCompany = (id) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Deleting company...'));
    return axios
      .delete(process.env.REACT_APP_API_DOMAIN + `/api/companies/` + id, {
        headers: getAuthToken(),
      })
      .then((response) => {
        dispatch(closeLoadingSpinner());
        dispatch(fetchCompanyNames());
        history.push('/contacts/companies');
        saveAuthToken(response.headers);
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
      });
  };
};

export const setCompanySelectedIdsForExport = (selectedIdsForExport) => ({
  type: SET_COMPANY_SELECTED_IDS_FOR_EXPORT,
  selectedIdsForExport,
});

export const handleCompanySelectAll = (isChecked) => (dispatch, getState) => {
  if (isChecked) {
    const { names } = getState().companies;
    const newSelectedIdsForExport = names.reduce(
      (hash, company) => ({ ...hash, [company.id]: true }),
      {}
    );
    dispatch(setCompanySelectedIdsForExport(newSelectedIdsForExport));
  } else {
    dispatch(setCompanySelectedIdsForExport({}));
  }
};

export const handleCompanySelect = (id, isChecked) => (dispatch, getState) => {
  const newSelectedIdsForExport = {
    ...getState().companies.selectedIdsForExport,
  };
  if (isChecked) {
    newSelectedIdsForExport[id] = true;
  } else {
    delete newSelectedIdsForExport[id];
  }
  dispatch(setCompanySelectedIdsForExport(newSelectedIdsForExport));
};

export const setCompanyFilter = (filter) => ({
  type: SET_COMPANY_FILTER,
  filter,
});

export const setCompanyFilterAndReload = (filter) => (dispatch, getState) => {
  dispatch(setCompanyFilter(filter));
  dispatch(fetchCompanyNames());
};

const searchCompanyNames = (query) => {
  return (dispatch, getState) => {
    dispatch(setCompanyQuery(query));
    dispatch(fetchCompanyNames());
  };
};

export const addCompanyNames = (link) => {
  return (dispatch, getState) => {
    const { query } = getState().companies;
    dispatch({
      type: ADD_COMPANY_NAMES_REQUEST,
      query,
      link,
    });
  };
};
export const searchCompanies = (query) => {
  return (dispatch, getState) => {
    dispatch(searchCompanyNames(query));
  };
};
export const fetchCompaniesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchCompanies(getState())) {
      return dispatch(fetchCompanies());
    }
  };
};
export const fetchCompanyNamesIfNeeded = () => {
  return (dispatch, getState) => {
    if (shouldFetchCompanyNames(getState())) {
      return dispatch(fetchCompanyNames());
    }
  };
};
export const fetchSingleCompanyIfNeeded = (id) => {
  return (dispatch, getState) => {
    if (shouldFetchSingleCompany(getState())) {
      return dispatch(fetchCompany(id));
    }
  };
};

export const fetchInfiniteCompanies = () => {
  return (dispatch, getState) => {
    const companies = getState().companies;
    const { query } = companies;
    const link = `/api/companies/names`;
    dispatch(requestInfiniteCompanies());
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + link,
        { per: 25, query: query },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        saveAuthToken(response.headers);
        dispatch(infiniteCompaniesSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteCompaniesFailure());
        saveError(error);
      });
  };
};
export const addInfiniteCompanies = (link) => {
  return (dispatch, getState) => {
    const companies = getState().companies;
    const { query } = companies;
    dispatch(requestInfiniteCompanies());
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + link,
        { per: 25, query },
        {
          headers: getAuthToken(),
        }
      )
      .then((response) => {
        saveAuthToken(response.headers);
        dispatch(addInfiniteCompaniesSuccess(response));
      })
      .catch((error) => {
        dispatch(infiniteCompaniesFailure());
        saveError(error);
      });
  };
};
export const searchInfiniteCompanies = (query) => {
  return (dispatch, getState) => {
    dispatch(setCompanyQuery(query));
    dispatch(fetchInfiniteCompanies());
  };
};
export const resetCompanies = () => {
  return (dispatch, getState) => {
    dispatch(setCompanyQuery(''));
    dispatch(setContactType('companies'));
    dispatch(fetchInfiniteCompanies());
  };
};

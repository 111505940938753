import React from "react";
import ScrollArea from "Utils/react-scrollbar";
import Marker from "Utils/Marker";
import classnames from "classnames";
import Accordion from "Utils/Accordion";

const Directions = (props) => {
  const { directions, truck, stops } = props;

  return (
    <ScrollArea speed={0.8} horizontal={false}>
      {directions && (
        <ul className="directions printDirectionAccordionBtn">
          {directions.legs.map((leg, legIndex) => {
            if (leg.start_address !== leg.end_address) {
              return (
                  <Accordion
                    key={legIndex}
                    header={
                      <div className="start ">
                        <Marker
                          type={stops[legIndex]?.stopType === 'standard' ? 'route' : 'stop'}
                          text={legIndex}
                          truck={truck}
                          stopId={stops[legIndex]?.id}
                          transportType={
                            stops[legIndex]?.stopType === 'standard'
                              ? stops[legIndex]?.rentalTransport?.transportType
                              : null
                          }
                        />
                        {legIndex === 0 ? "Get on" : "Follow"}
                        {" "}
                        {leg.start_address}
                      </div>
                    }
                    content={
                      <li key={legIndex}>
                        <div>
                          <div>
                            <ul>
                              {leg.steps.map((step, stepIndex) => (
                                <div>
                                <hr/>
                                <li key={stepIndex}>
                                  <div
                                    className={classnames({
                                      icon: true,
                                      [step.maneuver]: step.maneuver,
                                      noManeuver: !step.maneuver || step.maneuver === ""
                                    })}
                                  />
                                  <div
                                    className="instructions"
                                    className="editedText"
                                    dangerouslySetInnerHTML={{
                                      __html: step.instructions ? step.instructions : ""
                                    }}
                                  />
                                  <div className="details">{step.distance.text}</div>
                                </li>
                                </div>
                              ))}
                            </ul>
                          </div>
                        </div>
                      </li>
                    }
                  />

              );
            } else {
              return null;
            }
          })}
          <div className="directionsInstructions">
            These directions are for planning purposes only. You may find that construction projects, traffic, weather, or other events may cause conditions to differ from the map results, and you should plan your route accordingly. You must obey all signs or notices regarding your route.
          </div>
        </ul>
      )}
    </ScrollArea>
  );
};

export default Directions;

import React from 'react';
import { Upload } from 'Utils/SvgIcons';

const CsvHandler = (props) => {
  const { fileHandler, selectedFile } = props;

  return (
    <label className='uploadInput'>
      <Upload />
      {selectedFile?.name ? selectedFile?.name : 'Upload File'}
      <input type='file' name='csv' accept='.csv' onChange={fileHandler} />
    </label>
  );
};

export default CsvHandler;

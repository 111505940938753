import {
  SET_FILTER_SETTING_REQUEST,
  SET_FILTER_SETTING_SUCCESS,
  SET_FILTER_SETTING_FAILURE,
  FETCH_FILTER_SETTING_REQUEST,
  FETCH_FILTER_SETTING_SUCCESS,
  FETCH_FILTER_SETTING_FAILURE,
} from 'Constants/redux';
import converter from 'json-style-converter/es5';

const INITIAL_STATE = {
  OperationsWarehouseOrders: {
    startDate: null,
    endDate: null,
    startDeliveryDate: null,
    endDeliveryDate: null,
    itemName: '',
    deliveryType: '',
    deliveryTime: '',
    source: '',
    truckIds: '',
    salesperson: '',
    billingStatus: '',
    pickedStatus: '',
    checkedInStatus: '',
    status: '',
    search: undefined,
    eventTiming: '',
    numberPer: '10',
    customerId: '',
    companyId: '',
    customerName: null,
    contactCategories: '',
    departmentIds: '',
    pickListStatus: '',
  },
  OperationsWarehouseItems: {
    startDate: null,
    endDate: null,
    startDeliveryDate: null,
    endDeliveryDate: null,
    itemName: '',
    deliveryTime: '',
    deliveryType: '',
    containingDate: null,
    date: null,
    includeDeliveryTickets: undefined,
    itemId: '',
    listSortBy: 'items',
    numberPer: '10',
    pickedStatus: '',
    productType: 'items',
    query: '',
    rentalStatus: '',
    showAll: false,
    sortDirection: 'asc',
    sortField: 'start_date',
    status: '',
    truckIds: '',
    departmentIds: '',
    pickListStatus: '',
    search: undefined,
  },
  OperationsRouting: {
    employeeIds: [],
    truckIds: [],
    selectedTruckObjects: [],
    rentalStatus: '',
    transportType: [],
    showDeliveries: false,
    showPickups: false,
    deliveryDate: new Date(),
    startDate: new Date(),
    endDate: new Date(),
    showServiceTickets: false
  },
  loading: false,
};

const buildStateObject = (response) => {
  let stateObj = {};
  for (const key in response) {
    stateObj[key] = response[key].value;
  }
  return { ...converter.snakeToCamelCase(stateObj) };
};

const updateStateSection = (state, section, settings) => {
  const updatedSection = { [section]: { ...state[section], ...settings } };
  const updatedState = { ...state, ...updatedSection };
  return updatedState;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_FILTER_SETTING_REQUEST:
      return { ...state, loading: true };
    case SET_FILTER_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case FETCH_FILTER_SETTING_REQUEST:
      return { ...state, loading: true };
    case SET_FILTER_SETTING_SUCCESS:
    case FETCH_FILTER_SETTING_SUCCESS:
      const updatedState = updateStateSection(
        state,
        action.section,
        buildStateObject(action.response)
      );

      return {
        ...updatedState,
        loading: false,
      };
    case FETCH_FILTER_SETTING_FAILURE:
      return {
        ...state,
        loading: false,
      };
    default:
      return state;
  }
};

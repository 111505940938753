import {
  FETCH_RENTAL_TRANSPORT_LIST_REQUEST,
  FETCH_RENTAL_TRANSPORT_LIST_SUCCESS,
  SET_RENTAL_TRANSPORT_FILTER,
  SHOW_OVERLAPPED_RENTAL_ON_MAP,
  CLOSE_OVERLAPPED_RENTAL_ON_MAP,
  RESET_TRANSPORT_LIST_FILTERS
} from "Constants/redux";

const INITIAL_STATE_FILTER = {
  startDate: new Date(),
  endDate: new Date(),
  selectedDate: new Date(),
  showDeliveries: true,
  showPickups: true,
  transportType: "",
  truckIds: [],
  employeeIds: [],
  rentalStatus: ""
};

const INITIAL_STATE = {
  rentalTransports: [],
  loading: false,
  overlappedClickRentalTransportId: null,
  filter: INITIAL_STATE_FILTER
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTAL_TRANSPORT_LIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_RENTAL_TRANSPORT_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case SET_RENTAL_TRANSPORT_FILTER:
      return {
        ...state,
        filter: {...state.filter, ...action.payload.filter}
      };
    case SHOW_OVERLAPPED_RENTAL_ON_MAP:
      return {
        ...state,
        overlappedClickRentalTransportId: action.payload.rentalTransportId,
      };
    case CLOSE_OVERLAPPED_RENTAL_ON_MAP:
      return {
        ...state,
        overlappedClickRentalTransportId: null,
      };
    case RESET_TRANSPORT_LIST_FILTERS:
      return {
        ...state,
        filter: {
          ...INITIAL_STATE_FILTER,
          startDate: state.filter.startDate,
          endDate: state.filter.endDate,
        }
      };
    default:
      return state;
  }
};

import {
  FETCH_RENTAL_STAFFS_REQUEST,
  FETCH_RENTAL_STAFFS_SUCCESS,
  FETCH_RENTAL_STAFFS_FAILURE,
  UPDATE_RENTAL_STAFF_FILTERS,
  CLEAR_RENTAL_STAFF_FILTERS,
  REFRESH_RENTAL_STAFF,
} from 'Constants/redux';

const INITIAL_STATE = {
  rentalStaff: {
    size: '',
    name: '',
    cost: '',
    position: '',
    staff_pricing_id: '',
    staff_role_id: '',
    rental_token: '',
    rental_id: '',
    position_in_section: '',
    product_type: '',
    service_tickets: {},
  },
  rentalStaffs: [],
  loading: false,
  errors: {},
  filters: {
    startDate: null,
    endDate: null,
    routed: null,
    serviced: null,
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_RENTAL_STAFFS_REQUEST:
      return { ...state, loading: true, errors: {} };
    case FETCH_RENTAL_STAFFS_FAILURE:
      return { ...state, loading: false, errors: action.error.response.data };
    case FETCH_RENTAL_STAFFS_SUCCESS:
      const newState = action.response
        ? action.response.rentalStaffs
        : INITIAL_STATE.rentalStaff.rentalStaffs;

      return {
        ...state,
        errors: {},
        rentalStaffs: newState,
        loading: false,
      };
    case UPDATE_RENTAL_STAFF_FILTERS:
      return {
        ...state,
        filters: Object.assign(state.filters, action.payload),
      };
    case CLEAR_RENTAL_STAFF_FILTERS:
      return {
        ...state,
        filters: {
          startDate: null,
          endDate: null,
          routed: null,
          serviced: null,
        },
      };
    case REFRESH_RENTAL_STAFF:
      const rentalStaffs = [...state.rentalStaffs];

      const index = rentalStaffs.findIndex(
        (obj) => obj.id === action.payload.id
      );

      rentalStaffs[index] = action.payload;

      return {
        ...state,
        rentalStaffs,
      };
    default:
      return state;
  }
};

import React from 'react';
import { connect } from 'react-redux';
import { withRouter, Link } from 'react-router-dom';
import * as actions from 'Actions';
import {
  Copy,
  Venue,
  Items,
  Company,
  Contacts,
  StarIcon,
  ImportCircle,
} from '../utils/SvgIcons';
import service from 'Api/service';
import { saveError } from 'Api';
import LoadingSpinner from 'Utils/LoadingSpinner';
import ImportWizardHeader from './ImportWizardHeader';
import PaginationBoxView from 'Utils/PaginationBoxView';
import { history } from 'Components/Routes';
import Modal from 'Utils/Modal';
import { selectCheckPermission } from 'HelperFunctions/locations';
import originalTerminologies from '../../helper_functions/originalTerminologies';

class ImportWizardSelect extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      importHistories: [],
      loading: true,
      totalPages: 1,
      page: 1,
      totalObjects: 0,
      perPage: 5,
      isImportHappening: false,
      selectedOption: '',
      pending: null,
      revertModal: false,
      confirmationModal: false,
    };
  }

  componentDidMount() {
    this.fetchHistories();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business && !prevProps.business.permissions.importWizardEnabled) {
      history.push('/unauthorized');
    }
  }

  fetchHistories = () => {
    const { perPage, page } = this.state;
    service
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/import_histories?per=${perPage}&page=${page}`
      )
      .then((response) => {
        this.setState({
          importHistories: response.data.importHistories,
          loading: false,
          ...response.data.meta?.pagination,
        });
      })
      .catch((error) => {
        this.setState({ loading: false, displayError: true });
        saveError(error);
      });
  };

  handlePageChange = (pagination) => {
    this.setState({ page: pagination.selected + 1 }, () =>
      this.fetchHistories()
    );
  };

  openRevertModal = (history) => {
    this.setState({ history, revertModal: true });
  };

  handleOptionSelect = () => {
    const { selectedOption } = this.state;

    return history.push(`/importWizard/upload/${selectedOption}`);
  };

  continueImport = () => {
    const { selectedOption } = this.state;
    return history.push(`/importWizard/adjust/${selectedOption}`);
  };

  checkOngoingImport = (optionUrl) => {
    service
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/imports?import_type=${optionUrl}`
      )
      .then((response) => {
        const { pending } = response.data;
        if (pending) {
          return this.setState({
            confirmationModal: true,
            selectedOption: optionUrl,
          });
        } else {
          this.setState(
            {
              selectedOption: optionUrl,
            },
            () => {
              this.handleOptionSelect();
            }
          );
        }
      })
      .catch((error) => {
        this.setState({ loading: false, displayError: true });
        saveError(error);
      });
  };

  renderOptions = (options) => {
    return options.map((option) => (
      <span key={option.name} className='ImportOption'>
        {option.comingSoon && <div className='banner'>COMING SOON</div>}
        <span
          onClick={(e) =>
            option.comingSoon ? '' : this.checkOngoingImport(option.url)
          }
        >
          <div className='Header'>
            <span>{option.name}</span>
            <ImportCircle />
          </div>
          <div className='CircleStyle'>
            <div>{option.svg}</div>
          </div>
        </span>
        {option.csvLink && (
          <a
            href={option.csvLink}
            download={`example_${option.url}_spreadsheet`}
          >
            {`Example ${option.name} CSV`}
          </a>
        )}
        {option.minimalCsv && (
          <a href='' download='example_spreadsheet'>
            {`Minimal ${option.name} CSV`}
          </a>
        )}
      </span>
    ));
  };

  renderHistories = (histories) => {
    const { checkPermission } = this.props;
    const { purchasePlural } = originalTerminologies();

    const prettyNames = {
      product: 'Items',
      add_on: purchasePlural,
      accessory: 'Accessories',
      venue: 'Venues',
      customer: 'Customers',
      company: 'Companies',
    };
    return histories.map((history) => (
      <tr>
        <td>
          <b>{history.authorName}</b>
        </td>
        <td>{history.createdAt}</td>
        {history.action === 'revert' ? (
          <td>
            Revert <b>{prettyNames[history.importType]}</b>
          </td>
        ) : (
          <td>
            Bulk Import <b>{prettyNames[history.importType]}</b>
          </td>
        )}
        {history.action === 'import' &&
        history.allowRevert &&
        checkPermission('import_wizard_revert') ? (
          <td onClick={() => this.openRevertModal(history)}>
            <a>Revert</a>
          </td>
        ) : (
          <td></td>
        )}
      </tr>
    ));
  };

  handleRevert = () => {
    const { history } = this.state;
    this.props.openLoadingSpinner('Reverting Import');

    service
      .patch(`${process.env.REACT_APP_API_DOMAIN}/api/imports/${history.id}`, {
        import_type: history.importType,
      })
      .then((response) => {
        this.setState({ revertModal: false });
        this.props.closeLoadingSpinner();
        this.props.openSuccessSnackBar('Reverted Successfully');
        this.fetchHistories();
      })
      .catch((error) => {
        this.setState({ loading: false, displayError: true });
        saveError(error);
      });
  };

  closeConfirmationModal = () => {
    this.setState({ confirmationModal: false });
  };

  closeRevertModal = () => {
    this.setState({ revertModal: false });
  };

  revertConfirmationModal = (revertModal) => {
    return (
      <Modal
        open={revertModal}
        toggle={this.closeRevertModal}
        title='Warning'
        actions={[
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.handleRevert}
          >
            Yes
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.closeRevertModal}
          >
            Cancel
          </a>,
        ]}
      >
        <p>Are you sure you want to revert this import? It cannot be undone.</p>
      </Modal>
    );
  };

  onGoingImportModal = (confirmationModal, selectedOption) => {
    return (
      <Modal
        open={confirmationModal}
        toggle={this.closeConfirmationModal}
        title='There is already an import happening. Do you want to start a new one?'
        actions={[
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.handleOptionSelect}
          >
            Start New Import
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.continueImport}
          >
            Continue Existing Import
          </a>,
          <a
            id='export_in_progress_ok'
            className='btn full'
            onClick={this.closeConfirmationModal}
          >
            Cancel
          </a>,
        ]}
      >
        <p>
          Proceeding with this action will delete all ongoing {selectedOption}{' '}
          import data.
        </p>
      </Modal>
    );
  };

  render() {
    const {
      importHistories,
      loading,
      totalPages,
      page,
      confirmationModal,
      selectedOption,
      revertModal,
    } = this.state;
    const { purchasePlural } = originalTerminologies();
    const options = [
      {
        name: 'Items',
        svg: <Items />,
        url: 'item',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/items.csv',
        comingSoon: false,
      },
      {
        name: 'Accessories',
        svg: <StarIcon />,
        url: 'accessory',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/accessories.csv',
        comingSoon: false,
      },
      {
        name: purchasePlural,
        svg: <Copy />,
        url: 'purchase',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/purchases.csv',
        comingSoon: false,
      },
      {
        name: 'Customers',
        svg: <Contacts />,
        url: 'customer',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/customers.csv',
        comingSoon: false,
      },
      {
        name: 'Companies',
        svg: <Company />,
        url: 'company',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/companies.csv',
        comingSoon: false,
      },
      {
        name: 'Venues',
        svg: <Venue />,
        url: 'venue',
        // minimalCsv: 'minimalCsv',
        csvLink:
          'https://tapgoods-import-wizard-test-files.s3.amazonaws.com/venues.csv',
        comingSoon: false,
      },
    ];

    return (
      <div>
        <ImportWizardHeader
          importStatus='selectType'
          headerTitle='Select Data Type To Import'
        />
        <div className='ImportWizardSelectBody'>
          <div className='ImportWizardOptions'>
            {this.renderOptions(options)}
          </div>
          <div className='ImportHistories'>
            <table className='grid'>
              <thead>
                <tr>
                  <th>Person</th>
                  <th>Date</th>
                  <th>Action</th>
                </tr>
              </thead>
              {loading ? (
                <LoadingSpinner />
              ) : (
                <tbody>
                  {importHistories?.length > 0
                    ? this.renderHistories(importHistories)
                    : 'No History '}
                </tbody>
              )}
            </table>
            <PaginationBoxView
              className='pagerWrapper'
              containerClassName='pager'
              pageCount={totalPages}
              onPageChange={this.handlePageChange}
              forcePage={page - 1}
            />
          </div>
        </div>
        {this.onGoingImportModal(confirmationModal, selectedOption)}
        {this.revertConfirmationModal(revertModal)}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const checkPermission = selectCheckPermission(state);
  const { business } = state.business;

  return { business, checkPermission };
};

export default withRouter(
  connect(mapStateToProps, actions)(ImportWizardSelect)
);

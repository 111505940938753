import {
  MOCK_PAGE_REQUEST,
} from 'Constants/redux';
import service from 'Api/service';
import { MOCK_PAGE_FAILURE, MOCK_PAGE_SUCCESS } from '../constants/redux';

export const mockPageRequest = ({ httpStatus, contentType = 'json' }) => {
  return (dispatch, getState) => {
    dispatch({
      type: MOCK_PAGE_REQUEST,
    });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN +
        `/api/mock_page/${httpStatus || '200'}/${contentType}`
      )
      .then((response) => {
        const newState = {
          mockPageResponse: response.data,
        };
        dispatch({
          type: MOCK_PAGE_SUCCESS,
          payload: newState,
        });
      }).catch((error) => {
      const newState = {
        mockPageResponse: { error },
      };
      dispatch({
        type: MOCK_PAGE_FAILURE,
        payload: newState,
      });
    })

    ;
  };
};

import React from 'react';
import { FormattedDate, FormattedTime } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert, PickUp, StatusCircle, CheckOnCircle } from 'Utils/SvgIcons';
import classnames from 'classnames';
import RentalName from './RentalName';
import { connect } from 'react-redux';
import Imgix from 'react-imgix';
import { capitalizeFirstLetter } from 'HelperFunctions/general';
import {
  Items,
  PurpleClockIcon,
  PurpleMorningIcon,
  PurpleNightIcon,
} from 'Utils/SvgIcons';

const renderProgress = (rentalInventory) => {
  if (rentalInventory.has_shortage) {
    return (
      <td rowSpan='2' className='alert'>
        <Alert />
      </td>
    );
  } else {
    let status = rentalInventory.pick_list_item?.status;
    let rentalStatus = rentalInventory.parent_rental?.status;
    let pickedQuantity =
      rentalInventory.pick_list_item?.quantity -
      rentalInventory.pick_list_item?.picked_quantity;
    let missingQuantity = rentalInventory.pick_list_item?.missing_quantity;

    let classNameToUse;
    let componentToUse;

    if (
      (rentalStatus === 'reservation' &&
        status === 'in_use' &&
        pickedQuantity === 0) ||
      (rentalStatus !== 'reservation' &&
        status === 'checked_in' &&
        missingQuantity === 0)
    ) {
      classNameToUse = 'key full static paddings';
      componentToUse = <CheckOnCircle />;
    } else if (
      (rentalStatus === 'reservation' &&
        status === 'in_use' &&
        pickedQuantity != 0) ||
      (rentalStatus !== 'reservation' &&
        status === 'checked_in' &&
        missingQuantity != 0)
    ) {
      classNameToUse = 'key partial paddings';
      componentToUse = <StatusCircle />;
    } else {
      classNameToUse = 'key none paddings';
      componentToUse = <StatusCircle />;
    }

    return (
      <td
        rowSpan='2'
        className={classnames({
          [status]: true,
        })}
      >
        <div className='warehouse-legend'>
          <div className={classNameToUse}>{componentToUse}</div>
        </div>
      </td>
    );
  }
};

const CheckingInRentalTableRow = (props) => {
  const { rentalInventory, link, tileView } = props;

  return (
    <tbody className='CheckingInRentalTableRow'>
      <Link className='rTableRow' to={link}>
        {tileView && (
          <td className='image rTableCell'>
            <figure>
              {rentalInventory.pictures ? (
                <Imgix
                  src={rentalInventory.pictures}
                  alt={rentalInventory.name}
                  height={70}
                  width={50}
                />
              ) : (
                <Items />
              )}
            </figure>
          </td>
        )}
        <td className='lg invtName'>
          <b>{rentalInventory?.name || 'Untitled'}</b>
          <div>
            <div className='rentalRowName'>
              <span>
                {rentalInventory?.rental_name || rentalInventory?.parent_rental?.name}
              </span>
              &nbsp;
              <span className='rentalToken'>
                (#{rentalInventory?.parent_rental?.token})
              </span>
            </div>
          </div>
        </td>
        <td className='department'>
          {rentalInventory.rental_schedule.start_date_time && (
            <FormattedDate
              value={rentalInventory.rental_schedule.start_date_time}
              day='2-digit'
              month='2-digit'
            />
          )}
          {' - '}
          {rentalInventory.rental_schedule.end_window_beginning && (
            <FormattedDate
              value={rentalInventory.rental_schedule.end_window_beginning}
              day='2-digit'
              month='2-digit'
              year='2-digit'
            />
          )}
        </td>
        <td className='highlight medium'>
          {rentalInventory.rental_schedule.eventStartSetting === 'clock' && (
            <PurpleClockIcon />
          )}
          {rentalInventory.rental_schedule.eventStartSetting === 'morning' && (
            <PurpleMorningIcon />
          )}
          {rentalInventory.rental_schedule.eventStartSetting === 'evening' && (
            <PurpleNightIcon />
          )}
          <FormattedDate
            value={rentalInventory.rental_schedule.start_date_time}
            day='2-digit'
            month='2-digit'
          />
          &nbsp;
          <FormattedTime
            value={rentalInventory.rental_schedule.start_date_time}
          />
          &nbsp;
          {rentalInventory.delivery_type === 'customer_pick_up' && <PickUp />}
        </td>
        <td className='location'>
          <b>{rentalInventory.quantity}</b>
        </td>
        <td className='location'>{rentalInventory.warehouse_location}</td>
        {renderProgress(rentalInventory)}
      </Link>
    </tbody>
  );
};

const mapStateToProps = (state) => {
  const { tileView } = state.products;

  return { tileView };
};

export default connect(mapStateToProps, null)(CheckingInRentalTableRow);

import {
  START_PROGRESS_BAR,
  CLOSE_PROGRESS_BAR,
  CLOSE_PROGRESS_SUCCESS_MODAL,
  OPEN_PROGRESS_SUCCESS_MODAL,
  UPDATE_PROGRESS_BAR,
  CLOSE_PROGRESS_MODAL,
  OPEN_PROGRESS_MODAL
} from "Constants/tapgoodsConstants";

const INITIAL_STATE = {
  visible: false,
  complete: 0,
  importResponse: {},
  successOpen: false,
  progressOpen: false,
  progressType: "import"
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_PROGRESS_BAR:
      return {
        ...state,
        complete: 0,
        visible: action.progressType !== "export_invoices",
        progressOpen: true,
        progressType: action.progressType
      };
    case UPDATE_PROGRESS_BAR:
      return {
        ...state,
        complete: action.payload.complete,
        visible: true
      };
    case CLOSE_PROGRESS_BAR:
      return {
        ...state,
        visible: false,
        progressOpen: false,
        complete: 0
      };
    case CLOSE_PROGRESS_SUCCESS_MODAL:
      return {
        ...state,
        successOpen: false
      };
    case OPEN_PROGRESS_SUCCESS_MODAL:
      return {
        ...state,
        successOpen: true,
        progressOpen: false,
        importResponse: action.importResponse
      };
    case CLOSE_PROGRESS_MODAL:
      return {
        ...state,
        progressOpen: false
      };
    case OPEN_PROGRESS_MODAL:
      return {
        ...state,
        progressOpen: true
      };
    default:
      return state;
  }
};

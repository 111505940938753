import {
  getActionCableConsumer
} from '../helper_functions/getActionCableConsumer';

export const subscribeToVersionChannel = () => {
  getActionCableConsumer().subscriptions.create("VersionChannel", {
    received(data: { version: string; }) {
      if (data.version !== process.env.REACT_APP_DATADOG_VERSION) {
        alert('A new version of the app is available. Please refresh your browser. ' +
          'you are on version ' + process.env.REACT_APP_DATADOG_VERSION + ' and the new ' +
          'version is ' + data.version + '.');
      }
    }
  });
};

import {
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS
} from "Constants/redux";
import { ROOT_URL } from "Api";
import service from "Api/service";
import { apiConnections } from "Constants/displayConstants";
import Rx from "rxjs/Rx";

const reloadInventoryEpic = action$ =>
  action$
    .ofType(RELOAD_INVENTORY_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      let link;
      if (action.options.for_connect) {
        link = `${ROOT_URL}/connect/inventories/paginated`;
      } else {
        link = `${ROOT_URL}/${apiConnections[action.inventoryType]}/paginated`;
      }
      return Rx.Observable.fromPromise(service.post(link, action.options)).map(
        response => {
          return {
            response,
            inventoryType: action.inventoryType,
            type: RELOAD_INVENTORY_SUCCESS
          };
        }
      );
    });

export default reloadInventoryEpic;

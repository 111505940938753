import React from 'react';

import {
  FETCH_BUSINESS_REQUEST,
  FETCH_BUSINESS_FAILURE,
  FETCH_BUSINESS_SUCCESS,
  UPDATE_BUSINESS_REQUEST,
  UPDATE_BUSINESS_FAILURE,
  UPDATE_BUSINESS_SUCCESS,
  FETCH_DELIVERY_SETTING_REQUEST,
  FETCH_DELIVERY_SETTING_FAILURE,
  FETCH_DELIVERY_SETTING_SUCCESS,
  FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS,
  SET_BUSINESS_PROPS,
  SET_DEFAULT_SETUP_TIME,
  REQUEST_FOR_ACTIVATION_SUCCESS,
  HIDE_ALERTS_TRIAL_MSG_ON_CROSS,
  SHOULD_SHOW_ALERT_TRIAL,
  SHOULD_HIDE_ALERT_TRIAL,
  QB_ACCOUNTS_AUTHENTICATION_FAILURE,
  QB_ACCOUNTS_AUTHENTICATION_SUCCESS,
  QB_ACCOUNTS_AUTHENTICATION_REQUEST,
} from 'Constants/redux';

const INITIAL_STATE = {
  business: {
    name: '',
    picture: {},
    physicalAddress: {},
    warehouseLatLong: {},
    displayWebsites: [],
    locations: [],
    trucks: [],
    employees: [],
    permissionSettings: [],
    changeHistoryEntries: [],
    viewEditSettingsPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    viewEditStaffingPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },

    makeShareablePermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    shopConnectPermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    acceptQuotePermission: {
      allowedRoles: 'admin,manager,salesperson',
    },
    bankAccount: null,
    softwareTier: 'all',
    stripeSubscription: {},
    marketplaceSetting: {},
    subscriptionFees: [],
    businessPaymentTerms: [],
    storefrontSetting: {},
    rentalAgreements: [],
    groupInfo: null,
    activationRequestedAt: null,
    permissions: {},
  },
  deliverySetting: null,
  alerts: [],
  alertsTrial: {},
  storefrontPagesVisibility: {},
  defaultSetupTime: 45,
  loading: false,
  shouldShowAlertTrial: false,
};

const getAlerts = (business) => {
  const alerts = [];
  if (business.id) {
    if (
      business.stripeSubscription &&
      (business.stripeSubscription.status === 'past_due' ||
        business.stripeSubscription.status === 'unpaid')
    ) {
      alerts.push({
        key: 'payment_failed',
        name: [
          'Overdue Subscription: Update Credit Card',
          'Make Subscription Payment',
        ],
        link: [
          '/admin/settings/account/card',
          '/admin/settings/account/subscription',
        ],
      });
    }
    if (business.subdomain === null || business.subdomain === '') {
      alerts.push({
        key: 'subdomain_needed',
        name: ['Set Up Vanity Url'],
        link: ['/admin/settings/business/edit'],
      });
    }
    if (business.bankAccount) {
      // do nothing
      return alerts;
    } else if (business.creditCard) {
      const now = new Date();
      const nowStamp = now.getFullYear() * 12 + now.getMonth() + 1;
      const cardStamp =
        business.creditCard.expYear * 12 + business.creditCard.expMonth;
      if (cardStamp === nowStamp) {
        alerts.push({
          key: 'card_expiring',
          name: ['Update Credit Card. It is about to expire.'],
          link: ['/admin/settings/account/card'],
        });
      } else if (cardStamp < nowStamp) {
        alerts.push({
          key: 'card_expired',
          name: ['Update Credit Card. It is expired.'],
          link: ['/admin/settings/account/card'],
        });
      }
    } else {
      alerts.push({
        key: 'card_needed',
        name: ['Add a Payment Method'],
        link: ['/admin/settings/account/bank'],
      });
    }
  }
  return alerts;
};

const getAlertsTrial = (business) => {
  let alertsTrial = {};

  if (!business.activationRequestedAt && !business.permissions.activationAppliedAt) {
    alertsTrial = {
      type: 'alert',
      link: { url: '/google', text: 'activate your account now.' },
      message: (link) => {
        return (
          <span>
            Your account is in trial status.
            <strong>
              <span> To prepare for launch,</span>
              {link}
            </strong>
          </span>
        );
      },
    };
  } else if (!!business.temporaryMsgActivationSent) {
    alertsTrial = {
      type: 'success',
      link: { url: '/google', text: 'activate your account now.' },
      message: (link) => {
        return (
          <span>
            <strong>Congratulations!</strong>{' '}
            <span>Your account will be activated.</span>
          </span>
        );
      },
    };
  }

  return alertsTrial;
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_BUSINESS_PROPS:
      const newBusiness = {
        ...state.business,
        ...action.payload,
      };
      return { ...state, business: newBusiness };
    case FETCH_BUSINESS_REQUEST:
    case UPDATE_BUSINESS_REQUEST:
    case FETCH_DELIVERY_SETTING_REQUEST:
      return { ...state, loading: true };
    case FETCH_BUSINESS_FAILURE:
    case UPDATE_BUSINESS_FAILURE:
    case FETCH_DELIVERY_SETTING_FAILURE:
      return { ...state, loading: false };
    case FETCH_BUSINESS_SUCCESS:
    case UPDATE_BUSINESS_SUCCESS:
      return {
        ...state,
        business: action.response
          ? { ...state.business, ...action.response.business }
          : { name: '' },
        alerts: [...state.alerts, ...getAlerts(action.response.business)],
        alertsTrial: getAlertsTrial(action.response.business),
        defaultSetupTime:
          action.response.business.defaultSetupTime || state.defaultSetupTime,
        loading: false,
      };
    case FETCH_DELIVERY_SETTING_SUCCESS:
      return {
        ...state,
        deliverySetting: action.response.deliverySetting,
        loading: false,
      };
    case FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS:
      return {
        ...state,
        storefrontPagesVisibility: action.response,
        loading: false,
      };
    case SET_DEFAULT_SETUP_TIME:
      return {
        ...state,
        defaultSetupTime: action.payload,
      };
    case REQUEST_FOR_ACTIVATION_SUCCESS:
      action.response.business.stripeSubscription.activationRequestAt = '';

      return {
        ...state,
        business: action.response
          ? { ...state.business, ...action.response.business }
          : { name: '' },
        alertsTrial: getAlertsTrial({
          ...action.response.business,
          temporaryMsgActivationSent: true,
        }),
      };

    case QB_ACCOUNTS_AUTHENTICATION_REQUEST:
      return {
        ...state,
        alerts: state.alerts.filter((el) => el.key !== 'qb_auth'),
        qbAccountsAuthenticationResponse: {
          required_re_auth: false,
          qb_re_auth_locations: [],
        },
      };

    case QB_ACCOUNTS_AUTHENTICATION_SUCCESS:
      return {
        ...state,
        qbAccountsAuthenticationResponse: {
          required_re_auth: false,
          qb_re_auth_locations: action.qb_re_auth_locations,
        },
      };

    case QB_ACCOUNTS_AUTHENTICATION_FAILURE:
      return {
        ...state,
        alerts: [
          ...state.alerts.filter((el) => el.key !== 'qb_auth'),
          {
            key: 'qb_auth',
            name: [
              `Uh oh! It looks like you were disconnected from QuickBooks for locations: ${(
                action.qb_re_auth_locations || []
              )
                .map((obj) => obj.name)
                .join(',')}. Please re-authenticate to restore the connection.`,
            ],
            link: ['/admin/reports/quickbooks/settings'],
          },
        ],
        qbAccountsAuthenticationResponse: {
          required_re_auth: true,
          qb_re_auth_locations: action.qb_re_auth_locations,
        },
      };
    case HIDE_ALERTS_TRIAL_MSG_ON_CROSS:
      return {
        ...state,
        alertsTrial: {},
        shouldShowAlertTrial: false,
      };
    case SHOULD_SHOW_ALERT_TRIAL:
      return {
        ...state,
        shouldShowAlertTrial: true,
      };
    case SHOULD_HIDE_ALERT_TRIAL:
      return {
        ...state,
        shouldShowAlertTrial: false,
      };
    default:
      return state;
  }
};

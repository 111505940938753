import service from "Api/service";
import { ROOT_URL, saveError } from "Api";
import {
  FETCH_CONNECT_LOCATIONS_REQUEST,
  FETCH_CONNECT_LOCATIONS_FAILURE,
  FETCH_CONNECT_LOCATIONS_SUCCESS,
  SET_LOCATION_FILTER
} from "Constants/redux";

export const fetchConnectLocations = (params = {}) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_CONNECT_LOCATIONS_REQUEST });
    return service
      .post(`${ROOT_URL}/locations/connect`, params)
      .then(response => {
        return dispatch({
          response,
          type: FETCH_CONNECT_LOCATIONS_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_CONNECT_LOCATIONS_FAILURE
        });
      });
  };
};

export const setLocationFilter = locationFilter => {
  return {
    type: SET_LOCATION_FILTER,
    payload: locationFilter
  };
};

import React from 'react';
import StandardRentalTableRow from './rows/StandardRentalTableRow';
import CheckingOutRow from './rows/CheckingOutInventory';
import CheckingInRow from './rows/CheckingInInventory';
import ShortageRow from './rows/ShortageInventory';
import ConnectHoldRow from './rows/ConnectHoldInventory';
import { getLocationId, saveLocationId } from 'Api';
import { fetchLocation } from 'Actions/LocationActions';
import { setMultilocationAllLocations } from 'Actions/dashboardActionCreators';
import { selectCheckPermission } from 'HelperFunctions/locations';
import { connect } from 'react-redux';

const RentalInventoryTableRowFunc = (type) => {
  class RentalInventoryTableRow extends React.Component {
    link = () => {
      const {
        rentalInventory,
        checkPermission,
      } = this.props;

      const isRentalItemTemporary = rentalInventory.type === 'RentalItemTemporary';
      const rental = rentalInventory.parent_rental || rentalInventory.rental
      const isDraftOrTemplate = ['draft', 'template'].includes(rental.status);

      const basePath = '/orders/rentals/' + rental.token;
      const editPath = basePath + '/edit';
      const pickListPath = basePath + '/pickList';

      const userLink = isRentalItemTemporary ? (isDraftOrTemplate ? editPath : basePath) : basePath;
      const devopsLink = isRentalItemTemporary ? pickListPath : basePath + '/pickList';

      return checkPermission('operations_view') ? userLink : devopsLink;
    };


    onClick = () => {
      const {
        fetchLocation,
        location,
        multilocationAllLocations,
        setMultilocationAllLocations,
      } = this.props;

      if (multilocationAllLocations) {
        saveLocationId(location.id);
        fetchLocation();
        setMultilocationAllLocations(false);
      }
    };

    render() {
      /*eslint-disable */
      const { user, ...other } = this.props;
      /*eslint-disable */

      switch (type) {
        case 'checking_out':
          return <CheckingOutRow {...other} link={this.link()} />;
        case 'checking_in':
          return <CheckingInRow {...other} link={this.link()} />;
        case 'shortage':
          return (
            <ShortageRow {...other} onClick={this.onClick} link={this.link()} />
          );
        case 'connect_hold':
        case 'subrentals_all':
          return (
            <ConnectHoldRow
              {...other}
              onClick={this.onClick}
              link={this.link()}
            />
          );
        default:
          return <StandardRentalTableRow {...other} link={this.link()} />;
      }
    }
  }

  const mapStateToProps = (state) => {
    const checkPermission = selectCheckPermission(state);
    return { checkPermission };
  };

  return connect(mapStateToProps, {
    fetchLocation,
    setMultilocationAllLocations,
  })(RentalInventoryTableRow);
};
export default RentalInventoryTableRowFunc;

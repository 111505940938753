import * as actionTypes from 'Constants/redux';

function customers(
  state = {
    isFetching: false,
    loading: false,
    shouldRefresh: true,
    pagesRemaining: false,
    items: [],
    names: [],
    fetchingNames: false,
    searchCancelToken: null,
    pagination: {},
    filter: {
      query: '',
      page: 1,
      numberPer: 10,
      categories: '',
    },
    selectedIdsForExport: {},
  },
  action
) {
  switch (action.type) {
    case actionTypes.REQUEST_CUSTOMERS:
      return Object.assign({}, state, {
        isFetching: true,
        shouldRefresh: true,
      });
    case actionTypes.REQUEST_CUSTOMER_NAMES:
    case actionTypes.ADD_CUSTOMER_NAMES_REQUEST:
      return {
        ...state,
        fetchingNames: true,
        shouldRefresh: true,
      };
    case actionTypes.RECEIVE_CUSTOMERS:
      return Object.assign({}, state, {
        isFetching: false,
        shouldRefresh: true,
        items: action.customers,
        lastUpdated: action.receivedAt,
      });
    case actionTypes.RECEIVE_CUSTOMER_NAMES:
      const { names, pagination, firstId } = action.payload;
      return {
        ...state,
        isFetching: false,
        fetchingNames: false,
        shouldRefresh: true,
        names,
        firstId,
        pagination,
        pagesRemaining: names.length !== pagination.totalObjects,
        selectedIdsForExport: {},
      };
    case actionTypes.ADD_CUSTOMER_NAMES_SUCCESS:
      const newPagination = action.payload.pagination;
      const newNames = [...state.names, ...action.payload.names];
      return {
        ...state,
        isFetching: false,
        fetchingNames: false,
        shouldRefresh: true,
        names: newNames,
        pagination: newPagination,
        pagesRemaining: newNames.length !== newPagination.totalObjects,
      };
    case actionTypes.UPDATE_CUSTOMER_NAME_ON_LIST:
      const updatedNames = state.names;
      const customerIndex = updatedNames.findIndex(
        (customer) => customer.id == action.payload.id
      );
      updatedNames[customerIndex] = {
        ...updatedNames[customerIndex],
        name: action.payload.name,
      };
      return {
        ...state,
        names: updatedNames,
      };
    case actionTypes.SET_CUSTOMER_TYPE:
      return {
        ...state,
        firstId: '',
      };
    case actionTypes.SET_CUSTOMER_QUERY:
      return {
        ...state,
        query: action.payload.query,
      };
    case actionTypes.INFINITE_CUSTOMERS_SUCCESS:
      const infCustomers = action.payload.customers;
      const infPagination = action.payload.pagination;
      const infTotalCustomers = infCustomers;
      const infPagesRemaining =
        infTotalCustomers.length !== infPagination.totalObjects;
      return {
        ...state,
        loading: false,
        pagination: infPagination,
        pagesRemaining: infPagesRemaining,
        items: infCustomers,
      };
    case actionTypes.ADD_INFINITE_CUSTOMERS_SUCCESS:
      const addCustomers = action.payload.customers;
      const addPagination = action.payload.pagination;
      const addTotalCustomers = state.items.slice().concat(addCustomers);
      const addPagesRemaining =
        addTotalCustomers.length !== addPagination.totalObjects;
      return {
        ...state,
        loading: false,
        pagination: addPagination,
        pagesRemaining: addPagesRemaining,
        items: addTotalCustomers,
      };
    case actionTypes.INFINITE_CUSTOMERS_FAILURE:
      return {
        ...state,
        loading: false,
      };
    case actionTypes.REQUEST_INFINITE_CUSTOMERS:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.STORE_SEARCH_CANCEL_TOKEN:
      return {
        ...state,
        searchCancelToken: action.source,
      };
    case actionTypes.SET_CUSTOMER_FILTER:
      return {
        ...state,
        filter: action.filter,
      };
    case actionTypes.SET_CUSTOMER_SELECTED_IDS_FOR_EXPORT:
      return {
        ...state,
        selectedIdsForExport: action.selectedIdsForExport,
      };
    default:
      return state;
  }
}

export default customers;

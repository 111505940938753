import React from 'react';
import Dialog from 'material-ui/Dialog';
import { Close } from 'Utils/SvgIcons';
import classnames from 'classnames';

const Modal = (props) => {
  const {
    className,
    actions,
    open,
    toggle,
    title,
    large,
    children,
    modal,
    hideCloseButton,
    steps,
    currentStep,
    threeButtons,
  } = props;
  return (
    <Dialog
      actions={actions}
      modal={modal}
      open={open}
      className={classnames({
        modal: true,
        large: large,
        [className]: className,
      })}
      bodyClassName='body'
      contentClassName='content'
      actionsContainerClassName={`actions${threeButtons ? ' threeButtons' : ''}`.trim()}
      overlayClassName='overlay'
      onRequestClose={toggle}
      title={title}
      disableBackdropClick={hideCloseButton}
      disableEscapeKeyDown={hideCloseButton}
    >
      {!hideCloseButton && (
        <a className='btnClose' onClick={toggle}>
          <Close />
        </a>
      )}

      {!!steps && (
        <a className='btnClose' style={{ width: '35px', top: '20px' }}>
          {currentStep} of {steps}
        </a>
      )}
      {children}
    </Dialog>
  );
};

Modal.defaultProps = {
  modal: false,
};

export default Modal;

import {
  CLOSE_OVERBOOKING_MODAL,
  FETCH_INVENTORY_AVAILABILITY_FAILURE,
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
  OPEN_OVERBOOKING_MODAL,
  SET_AVAILABILITY_LOADING_INVENTORY,
} from 'Constants/redux';
import { client } from '../components/Root';
import { mapProductTypeToRentalInventoryClass } from '../helper_functions/rentalForm';
import { GetRentalInventoryForOverbookingModalDocument } from '../types/graphql';
import {
  closeLoadingSpinner,
  openLoadingSpinner,
} from './LoadingSpinnerActions';
import { setErrors } from './dashboardActionCreators';

export const openOverbookingModal = ({
  inventoryObject,
  rental,
  schedule,
  manualSubrentIsOpen,
  multilocationSubrentIsOpen,
  isSoftHold,
}) => (dispatch) => {
  dispatch(openLoadingSpinner('Loading overbooking data...'));

  const request = client.query({
    query: GetRentalInventoryForOverbookingModalDocument,
    variables: {
      rentalId: rental.id,
    },
  });

  request
    .then((response) => {
      if (response.data?.errors?.length > 0) {
        dispatch(setErrors('An error occured while loading modal data.'));

        return;
      }

      const requestedInventoryId = inventoryObject.parentInventory?.id;
      const requestedInventoryType = inventoryObject.productType;
      const requestedRentalInventoryType =
        mapProductTypeToRentalInventoryClass[requestedInventoryType];

      const quantityForOrder =
        inventoryObject.type === 'RentalItemTemporary'
          ? inventoryObject.quantity
          : response.data.getRentalInventory.reduce(
              (acc, { inventoryId, __typename, quantity }) => {
                if (
                  requestedInventoryId === inventoryId &&
                  requestedRentalInventoryType === __typename
                ) {
                  return acc + quantity;
                }

                return acc;
              },
              0
            );

      dispatch({
        type: OPEN_OVERBOOKING_MODAL,
        payload: {
          inventoryObject,
          rental,
          schedule,
          quantityForOrder,
          manualSubrentIsOpen,
          multilocationSubrentIsOpen,
          isSoftHold,
        },
      });
    })
    .catch((err) => {
      console.error(err);
      dispatch(setErrors('An error occured while loading modal data.'));
    })
    .finally(() => dispatch(closeLoadingSpinner()));
};

export const closeOverbookingModal = () => {
  return {
    type: CLOSE_OVERBOOKING_MODAL,
  };
};

export const fetchInventoryAvailability = ({
  dateAttribute,
  startDate,
  endDate,
  products,
  bundles,
  accessories,
  addOns,
  shelfEnabled,
  rentalId,
  rentalStatuses,
  multilocation,
  formatHours,
}) => (dispatch, getState) => {
  const inventory = {
    dateAttribute,
    products,
    accessories,
    addOns,
    bundles,
    rentalStatuses,
    shelfEnabled,
    multilocation,
  };

  dispatch(setLoadingInventory(inventory));

  let fromDate = startDate;
  let toDate = endDate;
  if (formatHours) {
    fromDate = new Date(fromDate);
    toDate = new Date(toDate);
    fromDate.setHours(0, 0, 0);
    toDate.setHours(23, 59, 59);
  }

  dispatch({
    type: FETCH_INVENTORY_AVAILABILITY_REQUEST,
    requestPayload: {
      startDate: fromDate,
      endDate: toDate,
      ...inventory,
      rentalStatuses,
      rentalId,
    },
  });
};

export const fetchInventoryAvailabilitySuccess = (payload) => (dispatch) =>
  dispatch({
    type: FETCH_INVENTORY_AVAILABILITY_SUCCESS,
    payload,
  });

export const fetchInventoryAvailabilityFailure = (payload) => (dispatch) =>
  dispatch({ type: FETCH_INVENTORY_AVAILABILITY_FAILURE, payload });

export const setLoadingInventory = (payload) => (dispatch) =>
  dispatch({
    type: SET_AVAILABILITY_LOADING_INVENTORY,
    payload,
  });

export default {
  required: function(value, prop) {
    if (typeof value == 'string') {
      value = value.trim();
    }
    return prop ? value == null || value === '' : false;
  },

  minLength: function(value, prop) {
    return prop && value ? value.length < prop : false;
  },

  maxLength: function(value, prop) {
    return prop && value ? value.length > prop : false;
  },

  email: function(value, prop) {
    return prop && value
      ? /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/.test(
          value
        )
      : false;
  },
  without: function(value, prop) {
    return !value ? true : prop.test(value);
  },

  min: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },

  greaterThanOrEqual: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) < prop : false;
  },

  greaterThan: function(value, prop) {
    return prop && value
      ? parseFloat(value) == prop || parseFloat(value) < prop
      : false;
  },

  lessThanOrEqual: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },

  max: function(value, prop) {
    return prop && value ? !isFinite(value) || parseFloat(value) > prop : false;
  },

  pattern: function(value, prop) {
    return !value ? false : !prop.test(value);
  },

  equalTo: function(value, prop) {
    return !value ? false : prop !== value;
  },

  oneOf: function(value, prop) {
    return !value ? false : prop.indexOf(value) === -1;
  },

  promise: function(value, prop, dispatch) {
    if (typeof prop === 'function') {
      return prop(value, dispatch);
    }
    throw new Error('FormValidation: type promise must be a function!');
  },
  digits: function(value, prop) {
    return prop ? isNaN(Number(value)) : false;
  },
  creditcard: function(value, prop) {
    if (!value) {
      return false;
    }
    // accept only spaces, digits and dashes
    let strWithoutSpaces = value.replace(/\s/g, '');
    if(!/^\d+$/.test(strWithoutSpaces)){
      return false
    }

    value = value.replace(/\D/g, '');

    // Basing min and max length on
    // http://developer.ean.com/general-info/valid-card-types/
    if (value.length < 13 || value.length > 19) {
      return false;
    }
    return true
    
  },
  matchField: function(value, prop, dispatch, allValues) {
    return !value ? false : value !== allValues[prop];
  },
};

import React, { Component } from 'react'
import { Items } from 'Utils/SvgIcons';
import { connect } from 'react-redux';
import * as actions from "Actions";
import { FormattedDate, FormattedNumber } from 'react-intl';
import classNames from "classnames";
import Imgix from "react-imgix";
import { displayDocumentType } from "Constants/displayConstants";
import { getSubTotal, getTotalWithTax, getTotalForLineItems, getTotalForLineItem } from "HelperFunctions/workOrder";

class PrintMaintenanceDetails extends Component {
  UNSAFE_componentWillMount() {
    const { fetchMaintenanceRecord, selectedMaintenanceRecord } = this.props;
    fetchMaintenanceRecord(this.props.match.params.id);
  }

  renderDisplayId = () => {
    const { selectedMaintenanceRecord } = this.props;
    const firstWorkOrder = selectedMaintenanceRecord.workOrders[0];

    if (firstWorkOrder.customDisplayId) {
        return firstWorkOrder.customDisplayId;
    } else if (firstWorkOrder.displayId) {
        return firstWorkOrder.displayId
    } else {
        return "N/A"
    }
  }

  render() {
    const { selectedMaintenanceRecord } = this.props;
    const firstWorkOrder = selectedMaintenanceRecord.workOrders[0];
    const {
      name,
      addressStreetAddress1,
      addressStreetAddress2,
      addressCity,
      addressLocale,
      addressPostalCode,
      phoneNumber,
      website
    } = firstWorkOrder?.vendor || {};
    const serializedRecords = selectedMaintenanceRecord.serializedMaintenanceRecords;

    const laborLineItems = firstWorkOrder?.workOrderLineItems?.filter(lineItem => lineItem.lineItemType === "labor")
    const partsLineItems = firstWorkOrder?.workOrderLineItems?.filter(lineItem => lineItem.lineItemType === "parts")

    return (
      <div className="printWrapper printMaintenanceWrapper">
        <div className="content">
          <div className="maintenanceHeader">
            <h4>
              <span>
                In Maintenance&nbsp;
                <span>{selectedMaintenanceRecord.quantity}</span>
              </span>
              <span>
                Priority&nbsp;
                <span>{selectedMaintenanceRecord.priority}</span>
              </span>
            </h4>
          </div>

          <div className="workOrderHeader">
            <h4>
              WO: {this.renderDisplayId()}

              <span>
                {firstWorkOrder && firstWorkOrder.paidStatus ? "Paid" : "Not Paid"}
              </span>
            </h4>
          </div>
          <div className="maintenanceDetails">
            <figure>
              {selectedMaintenanceRecord.maintainable.pictures?.length === 0 ? (
                <Items />
              ) : (
                <Imgix
                  src={selectedMaintenanceRecord.maintainable.pictures[0].imgixUrl}
                  alt={selectedMaintenanceRecord.maintainable.name}
                />
              )}
            </figure>
            <div>
              <h2>{selectedMaintenanceRecord.name}</h2>
              <div className="grid">
                <span>Order</span>
                <span>{selectedMaintenanceRecord.rentalToken ? `#${selectedMaintenanceRecord.rentalToken}` : "Manually Entered"}</span>
                <span>Date</span>
                <FormattedDate value={selectedMaintenanceRecord.createdAt} />
                <span>Location</span>
                <span>{selectedMaintenanceRecord.locationName}</span>
                <span>Notes</span>
                <span>{selectedMaintenanceRecord.notes}</span>
              </div>
            </div>
          </div>
          {selectedMaintenanceRecord.documents.length > 0 ? <div className="documents">
            <h5>
              Documents
            </h5>
            <div className="grid">
              <span className="gridHeader">Name</span>
              <span className="gridHeader">Type</span>
              {selectedMaintenanceRecord.documents.map(document => (
                <div className="gridRow">
                  <span>{document.name}</span>
                  <span>{displayDocumentType[document.documentType]}</span>
                </div>
              ))}
            </div>
          </div> : <div />}
          {firstWorkOrder && <div className="workHeader">
            <h2>Work</h2>
            {firstWorkOrder.vendor ? (<span>
              <h5>{name}</h5>
              <p>
                {addressStreetAddress1}
                {addressStreetAddress2 && (
                  <div># {addressStreetAddress2}</div>
                )}<br />
                {addressCity},{" "}
                {addressLocale}{" "}
                {addressPostalCode}<br />
                {phoneNumber}<br />
                <u>{website}</u><br />
              </p>
            </span>) : <span>
              INTERNAL WORK ORDER
            </span>}

          </div>}
          {firstWorkOrder && <div className="workSide">
            <span>Work Order</span>
            <span>{this.renderDisplayId()}</span>
            <span>Work Order Created</span>
            <FormattedDate value={firstWorkOrder.createdOn} />
            <span>Inventory Return</span>
            {firstWorkOrder.inventoryReturnDateTime ? <FormattedDate value={firstWorkOrder.inventoryReturnDateTime} /> : <span />}
            <span>Assigned</span>
            <span>{firstWorkOrder.assignedTo}</span>
          </div>}


          {firstWorkOrder && <div className="workNotes">
            <h4 className="smallerHeader">
              Job
            </h4>
            <p>{firstWorkOrder.jobDescription}</p>
          </div>}
          {firstWorkOrder && <div className="shipTo">
            <h4 className="smallerHeader">
              Ship To
            </h4>
            <h6>
              {selectedMaintenanceRecord.locationName}
            </h6>
            <p>
              {firstWorkOrder.returnAddressStreetAddress1}
              {firstWorkOrder.returnAddressStreetAddress2 && (
                <span><br /># {firstWorkOrder.returnAddressStreetAddress2}</span>
              )}<br />
              {firstWorkOrder.returnAddressCity},{" "}
              {firstWorkOrder.returnAddressLocale}{" "}
              {firstWorkOrder.returnAddressPostalCode}<br />
            </p>
          </div>}
          {laborLineItems && <div className="lineItems">
            <div className="lineItemRow smallerHeader">
              <span>Service/Labor</span>
              <span>Hour</span>
              <span>Rate</span>
              <span className="center">Total</span>
            </div>
            {laborLineItems.map((lineItem, index, array) => (
              <div className={classNames({
                lineItemRow: true,
                dataRow: true,
                lastDataRow: index === array.length - 1
              })}>
                <span>{lineItem.name}</span>
                <span>{lineItem.amount}</span>
                <span>
                  <FormattedNumber value={lineItem.rate} style="currency" currency="USD" />{lineItem.rateUnit === "hour" ? "/hr" : "/day"}</span>
                <FormattedNumber className="center" value={getTotalForLineItem(lineItem)} style="currency" currency="USD" />
              </div>
            ))}
            <div className="lineItemRow dataRow">
              <span className="subTotal">
                SubTotal
              </span>
              <FormattedNumber
                value={getTotalForLineItems(laborLineItems)}
                style="currency"
                currency="USD" />
            </div>
          </div>}
          {partsLineItems && <div className="lineItems">
            <div className="lineItemRow smallerHeader">
              <span>Parts Material</span>
              <span>QTY</span>
              <span>Unit Price</span>
              <span className="center">Total</span>
            </div>
            {partsLineItems.map((lineItem, index, array) => (
              <div className={classNames({
                lineItemRow: true,
                dataRow: true,
                lastDataRow: index === array.length - 1
              })}>
                <span>{lineItem.name}</span>
                <span>{lineItem.amount}</span>
                <FormattedNumber
                  value={lineItem.rate}
                  style="currency"
                  currency="USD" />
                <FormattedNumber
                  className="center"
                  value={getTotalForLineItem(lineItem)}
                  style="currency"
                  currency="USD" />
              </div>
            ))}
            <div className="lineItemRow dataRow">
              <span className="subTotal">
                SubTotal
              </span>
              <FormattedNumber
                value={getTotalForLineItems(partsLineItems)}
                style="currency"
                currency="USD" />
            </div>
          </div>}

          {serializedRecords?.length > 0 && <div className="barcodedItems">
            <h4 className="smallerHeader">
              Barcoded Items
            </h4>
            <p>
              {serializedRecords.reduce((runningString, record, index) => {
                if (index === 0) {
                  runningString += record.serialNumber
                } else {
                  runningString += `, ${record.serialNumber}`
                }
                return runningString;
              }, "")}
            </p>
          </div>}

          {firstWorkOrder && <div className="workOrderNotes">
            <h4 className="smallerHeader">
              Work Order Notes
            </h4>
            <p>
              {firstWorkOrder.notes}
            </p>
          </div>}
          {firstWorkOrder && <div className="total">
            <h4>&nbsp;</h4>
            <div className="grid">
              <span>SubTotal(s)</span>
              <FormattedNumber
                value={getSubTotal(firstWorkOrder)}
                style="currency"
                currency="USD" />
              <span>Tax</span>
              <span>{firstWorkOrder.taxRate}%</span>
              <span>Total</span>
              <FormattedNumber
                value={getTotalWithTax(firstWorkOrder)}
                style="currency"
                currency="USD" />
            </div>
          </div>}
        </div>
      </div >
    )
  }
}

const mapStateToProps = (state) => {
  const { selectedMaintenanceRecord } = state.maintenanceRecord;
  return { selectedMaintenanceRecord }
}

export default connect(mapStateToProps, actions)(PrintMaintenanceDetails)
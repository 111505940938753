import service from 'Api/service';
import { ROOT_URL, saveError, getLocationId } from 'Api';
import {
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_FAILURE,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS,
  UPDATE_USER_PROFILE,
} from 'Constants/redux';

export const fetchUser = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_USER_REQUEST });
    service
      .get(`${ROOT_URL}/employees/profile`)
      .then((response) => {
        return dispatch({
          response,
          type: FETCH_USER_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_USER_FAILURE,
        });
      });
  };
};

// export const fetchEmployeeLocationRelationship = (user = undefined) => {
//   return (dispatch, getState) => {
//     const newUser = user ?? getState().auth.user;
//     const employeeLocationRelationship = newUser.employeeLocationRelationships?.find(
//       (elr) => elr.locationId == getLocationId()
//     );
//     dispatch({
//       type: FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS,
//       employeeLocationRelationship,
//     });
//   };
// };

export const updateUserProfile = (user) => {
  return {
    type: UPDATE_USER_PROFILE,
    payload: user,
  };
};

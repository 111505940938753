import {
  START_PROGRESS_TRACKER,
  CLOSE_PROGRESS_TRACKER,
  CLOSE_PROGRESS_TRACKER_SUCCESS,
  OPEN_PROGRESS_TRACKER_SUCCESS,
  UPDATE_PROGRESS_TRACKER,
  CLOSE_PROGRESS_TRACKER_START,
  OPEN_PROGRESS_TRACKER_START,
  UPDATE_CHANNEL_FOR_TRACKER,
  UPDATE_CONNECTION_FOR_TRACKER
} from "Constants/redux";

const INITIAL_STATE = {
  visible: false,
  complete: 0,
  importResponse: {},
  successOpen: false,
  progressOpen: false,
  channel: null,
  connection: null,
  startMessage: "",
  completedMessage: "",
  title: ""
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case START_PROGRESS_TRACKER:
      return {
        ...state,
        complete: 0,
        visible: true,
        progressOpen: true,
        startMessage: action.startMessage,
        completedMessage: action.completedMessage,
        title: action.title
      };
    case UPDATE_PROGRESS_TRACKER:
      return {
        ...state,
        complete: action.payload.complete,
        visible: true
      };
    case CLOSE_PROGRESS_TRACKER:
      return {
        ...state,
        visible: false,
        complete: 0
      };
    case CLOSE_PROGRESS_TRACKER_SUCCESS:
      return {
        ...state,
        successOpen: false
      };
    case OPEN_PROGRESS_TRACKER_SUCCESS:
      return {
        ...state,
        successOpen: true,
        progressOpen: false,
        importResponse: action.importResponse
      };
    case CLOSE_PROGRESS_TRACKER_START:
      return {
        ...state,
        progressOpen: false
      };
    case OPEN_PROGRESS_TRACKER_START:
      return {
        ...state,
        progressOpen: true
      };
    case UPDATE_CHANNEL_FOR_TRACKER:
      return {
        ...state,
        channel: action.channel
      };
    case UPDATE_CONNECTION_FOR_TRACKER:
      return {
        ...state,
        connection: action.connection
      };
    default:
      return state;
  }
};

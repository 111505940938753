import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import { setErrors } from './dashboardActionCreators';
import {
  UNASSIGN_TRUCK_REQUEST,
  ASSIGN_TRUCK_REQUEST,
  FETCH_RENTAL_TRANSPORT_FAILURE,
  FETCH_RENTAL_TRANSPORT_REQUEST,
  FETCH_RENTAL_TRANSPORT_SUCCESS,
} from 'Constants/redux';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

export const fetchRoutingRental = (id, background = false) => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_RENTAL_TRANSPORT_REQUEST,
      background: background,
    });
    service
      .get(`${ROOT_URL}/rental_transports/${id}`)
      .then((response) => {
        return dispatch({
          response,
          type: FETCH_RENTAL_TRANSPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_TRANSPORT_FAILURE,
        });
      });
  };
};

export const assignTruck = (
  id,
  rental_transport_id,
  truck_id,
  delivery_date,
  team_members = [],
  fromRoutingTab = false,
  relationship_id = null,
  additionalTrucks = []
) => {
  return (dispatch, getState) => {
    dispatch({
      type: ASSIGN_TRUCK_REQUEST,
    });
    dispatch(openLoadingSpinner('Assigning truck...'));
    service
      .post(
        `${ROOT_URL}/rental_transports/${rental_transport_id}/assign_truck`,
        {
          rental_transport_id,
          truck_id,
          delivery_date,
          relationship_id,
          additional_trucks: additionalTrucks,
          drivers_attributes: team_members.map(employee => ({
            id: employee.id,
            employee_id: employee.employeeId,
            '_destroy': 0,
            role: employee.selectedRoleId,
            truck_id
          })),
          fromRoutingTab
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          response,
          type: FETCH_RENTAL_TRANSPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_TRANSPORT_FAILURE,
        });
        dispatch(setErrors(error.response.data));
      });
  };
};

export const unassignTruck = (id, rental_transport_id, relationship_id, truck_id) => {
  return (dispatch, getState) => {
    dispatch({
      type: UNASSIGN_TRUCK_REQUEST,
    });
    dispatch(openLoadingSpinner('Unassigning truck...'));
    service
      .post(
        `${ROOT_URL}/rental_transports/${rental_transport_id}/unassign_truck`,
        {
          rental_transport_id,
          relationship_id,
          truck_id
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          response,
          type: FETCH_RENTAL_TRANSPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_TRANSPORT_FAILURE,
        });
        dispatch(setErrors(error.response.data));
      });
  };
};

export const assignDriver = (
  id,
  rental_transport_id,
  relationship_id,
  employee_id,
  truck_route_id,
  role,
  update
) => {
  if (!role) {
    role = '';
    update = false;
  }
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Assigning team member...'));
    service
      .post(
        `${ROOT_URL}/rental_transports/${rental_transport_id}/assign_driver`,
        {
          rental_transport_id,
          relationship_id,
          employee_id,
          truck_route_id,
          role,
          update,
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          response,
          type: FETCH_RENTAL_TRANSPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_TRANSPORT_FAILURE,
        });
        dispatch(setErrors(error.response.data));
      });
  };
};

export const unassignDriver = (
  id,
  rental_transport_id,
  relationship_id,
  driver_id,
  truck_route_id
) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Unassigning team member...'));
    service
      .post(
        `${ROOT_URL}/rental_transports/${rental_transport_id}/unassign_driver`,
        {
          rental_transport_id,
          relationship_id,
          driver_id,
          truck_route_id,
        }
      )
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          response,
          type: FETCH_RENTAL_TRANSPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_TRANSPORT_FAILURE,
        });
        dispatch(setErrors(error.response.data));
      });
  };
};

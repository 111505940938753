import React from "react";
import { ConnectedRouter } from "react-router-redux";
import { Route, Switch } from "react-router-dom";
import Footer from "Components/marketing/Footer";
import ContactUs from "Components/marketing/ContactUs";

import RouteChangeWrapper from "Utils/RouteChangeWrapper";
import UnsupportedBrowser from "Utils/UnsupportedBrowser";

const UnsupportedRoute = ({ history }) => (
  <ConnectedRouter history={history}>
    <RouteChangeWrapper>
      <div className="container">
        <main>
          <Switch>
            <Route path="/contact" component={ContactUs} />
            <Route path="/" component={UnsupportedBrowser} />
          </Switch>
        </main>
        <Route component={Footer} />
      </div>
    </RouteChangeWrapper>
  </ConnectedRouter>
);

export default UnsupportedRoute;

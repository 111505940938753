import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import { camelToSnakeCase } from 'json-style-converter/es5';
import {
  EDIT_TRANSACTION_REQUEST,
  EDIT_TRANSACTION_SUCCESS,
  EDIT_TRANSACTION_FAILURE,
  DELETE_TRANSACTION_REQUEST,
  DELETE_TRANSACTION_SUCCESS,
  DELETE_TRANSACTION_FAILURE,
} from 'Constants/redux/transaction';
import { UPDATE_RENTAL_SUCCESS } from 'Constants/redux';
import { openSuccessSnackBar, setErrors } from './dashboardActionCreators';

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

const mergeNewAttributesToRental = (newAttributes, rental) => {
  return { ...rental, ...newAttributes };
};

export function updateTransaction(data) {
  return (dispatch, getState) => {
    dispatch({ type: EDIT_TRANSACTION_REQUEST });
    dispatch(openLoadingSpinner('Updating payment...'));

    return service
      .put(
        `${ROOT_URL}/transactions/${data.id}`,
        camelToSnakeCase({ transaction: data })
      )
      .then((response) => {
        const {
          data,
          data: {
            transaction: { rental },
          },
        } = response;

        dispatch({
          bypassRentalConversion: true,
          rental: mergeNewAttributesToRental(rental, getState().rental.rental),
          type: UPDATE_RENTAL_SUCCESS,
        });

        dispatch(openSuccessSnackBar('Payment updated successfully'));

        dispatch({
          payload: data,
          type: EDIT_TRANSACTION_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        
        saveError(error, getState());
        dispatch({
          error,
          type: EDIT_TRANSACTION_FAILURE,
        });

        // Interrupts chained Promise.then() callbacks.
        return Promise.reject(error);
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
}

export function deleteTransaction(id) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Deleting payment...'));

    dispatch({ type: DELETE_TRANSACTION_REQUEST });
    return service
      .delete(`${ROOT_URL}/transactions/${id}`)
      .then((response) => {
        const {
          data,
          data: {
            transaction: { rental },
          },
        } = response;

        dispatch({
          bypassRentalConversion: true,
          rental: mergeNewAttributesToRental(rental, getState().rental.rental),
          type: UPDATE_RENTAL_SUCCESS,
        });

        dispatch(openSuccessSnackBar('Payment deleted successfully'));

        dispatch({
          payload: data,
          type: DELETE_TRANSACTION_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        
        saveError(error, getState());
        dispatch({
          error,
          type: DELETE_TRANSACTION_FAILURE,
        });

        // Interrupts chained Promise.then() callbacks.
        return Promise.reject(error);
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
}

import React from 'react';
import Imgix from 'react-imgix';
import {
  BusinessContainer,
  BusinessTextContainer,
} from './BusinessInvoiceSection.styles';
import { BusinessInvoiceSectionProps } from './BusinessInvoice.types';
import { InformationText } from '../../../forms/SideSection/common.styles';

const BusinessInvoiceSection = ({ business }: BusinessInvoiceSectionProps) => {
  const businessPicture = business?.picture;
  const businessPhoneNumber =
    business?.phoneNumber?.office || business?.phoneNumber?.cell;

  const renderBusinessPicture = () => {
    if (!businessPicture || !businessPicture.imgixUrl) {
      return null;
    }

    return (
      <Imgix
        src={businessPicture.imgixUrl}
        alt={'Business picture'}
        width={231}
        height={129}
      />
    );
  };

  return (
    <BusinessContainer>
      {renderBusinessPicture()}
      <BusinessTextContainer>
        <InformationText>Remit payment to:</InformationText>
        <InformationText bold>{business?.name}</InformationText>
        <InformationText>{businessPhoneNumber}</InformationText>
        <InformationText>{business?.email}</InformationText>
        <InformationText>{business?.website}</InformationText>
      </BusinessTextContainer>
    </BusinessContainer>
  );
};

export default BusinessInvoiceSection;

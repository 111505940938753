import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import { NavLink, Link, withRouter } from 'react-router-dom';
import MediaBreakpoints from 'Utils/MediaBreakpoints';
import AppBar from 'material-ui/AppBar';
import { User, Close, MenuIcon } from 'Utils/SvgIcons';
import classnames from 'classnames';
import isSelected from 'HelperFunctions/ActiveStyling';

class AdminNav extends React.Component {
  state = {
    dashboard: {
      value: 1,
    },
    mobile: {
      open: false,
    },
    signOut: {
      open: false,
    },
  };

  handleChange = (event) => {
    const target = event.target;
    const value = target.value;
    const name = target.name;

    this.setState({
      [name]: value,
    });
  };

  mobileSignOut = () => {
    this.toggleMobile();
    this.props.signOutAdmin();
  };

  openRentals = (event) => {
    this.setState({
      rentals: {
        open: true,
        anchorEl: event.currentTarget,
      },
    });
  };

  closeRentals = (event) => {
    this.setState({
      rentals: {
        open: false,
      },
    });
  };

  toggleMobile = () => {
    this.setState({
      mobile: {
        open: !this.state.mobile.open,
      },
    });
  };

  toggleSettingsMenu = (event) => {
    this.setState({
      settingsMenu: {
        open: !this.state.settingsMenu.open,
        anchorEl: event.currentTarget,
      },
    });
  };

  toggleSignOut = (event) => {
    this.setState({
      signOut: {
        open: !this.state.signOut.open,
      },
    });
  };

  checkCSPermission = () => {
    const { user } = this.props;

    return user?.client_success_permission;
  }

  render() {
    const { authenticated } = this.props;

    return (
      <MediaBreakpoints
        desktop={
          <div className='header'>
            <div className='mainMenu'>
              <Link to='/' className='menuLogo'>
                TapGoods
              </Link>
              {authenticated && (
                <ul>
                  <li>
                    <NavLink
                      id='admin-menu-tapgods'
                      to='/tg-admin/tapgods'
                      activeClassName='selected'
                    >
                      TapGods
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-businesses'
                      to='/tg-admin/businesses'
                      activeClassName='selected'
                    >
                      Businesses
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-marketplace'
                      to='/tg-admin/marketplace'
                      activeClassName='selected'
                    >
                      Marketplace
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-categories'
                      to='/tg-admin/categoryManagement'
                      activeClassName='selected'
                    >
                      Categories
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-faqs'
                      to='/tg-admin/faqManagement'
                      activeClassName='selected'
                    >
                      FAQs
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-features'
                      to='/tg-admin/featureManagement'
                      activeClassName='selected'
                    >
                      Features
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      id='admin-menu-reports'
                      to='/tg-admin/reports'
                      activeClassName='selected'
                    >
                      Reports
                    </NavLink>
                  </li>
                  <li
                    className={classnames({
                      selected: isSelected(
                        window.location,
                        '/tg-admin/billing'
                      ),
                    })}
                  >
                    <NavLink
                      id='admin-menu-billing'
                      to='/tg-admin/billing'
                      activeClassName='selected'
                    >
                      Billing
                    </NavLink>
                  </li>
                  <li
                    className={classnames({
                      selected: isSelected(
                        window.location,
                        '/tg-admin/billing'
                      ),
                    })}
                  >
                    <NavLink
                      id='admin-menu-setting'
                      to='/tg-admin/settings'
                      activeClassName='selected'
                    >
                      Settings
                    </NavLink>
                  </li>
                  {this.checkCSPermission() &&
                    <li
                      className={classnames({
                        selected: isSelected(
                          window.location,
                          '/tg-admin/client-success'
                        ),
                      })}
                    >
                      <NavLink
                        id='admin-menu-client-success'
                        to='/tg-admin/client-success'
                        activeClassName='selected'
                        className='noBreak'
                      >
                        Client Success
                      </NavLink>
                    </li>
                  }
                  <li
                    className='adminSignOut'
                    onMouseEnter={this.toggleSignOut}
                    onMouseLeave={this.toggleSignOut}
                  >
                    <User />
                    {this.state.signOut.open && (
                      <div className='subMenu' value={2}>
                        <ul>
                          <li>
                            <a onClick={this.props.signOutAdmin}>Sign Out</a>
                          </li>
                        </ul>
                      </div>
                    )}
                  </li>
                </ul>
              )}
            </div>
          </div>
        }
        mobile={
          <div className='header'>
            {authenticated ? (
              <AppBar
                className='mobileMenu'
                title={
                  <Link to='/' className='menuLogo'>
                    TapGoods
                  </Link>
                }
                showMenuIconButton={false}
                iconElementRight={
                  this.state.mobile.open ? <Close /> : <MenuIcon />
                }
                onRightIconButtonClick={this.toggleMobile}
              />
            ) : (
              <AppBar
                className='mobileMenu'
                title={
                  <Link to='/' className='menuLogo'>
                    TapGoods
                  </Link>
                }
                showMenuIconButton={false}
              />
            )}

            {this.state.mobile.open && (
              <div className='mobileMenuOverlay'>
                <div>
                  <ul>
                    <li>
                      <Link to='/tg-admin/tapgods' onClick={this.toggleMobile}>
                        TapGods
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/tg-admin/businesses'
                        onClick={this.toggleMobile}
                      >
                        Businesses
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/tg-admin/marketplace'
                        onClick={this.toggleMobile}
                      >
                        Marketplace
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/tg-admin/categoryManagement'
                        onClick={this.toggleMobile}
                      >
                        Category Management
                      </Link>
                    </li>
                    <li>
                      <Link
                        to='/tg-admin/featureManagement'
                        onClick={this.toggleMobile}
                      >
                        Feature Management
                      </Link>
                    </li>
                    <li>
                      <Link to='/tg-admin/reports' onClick={this.toggleMobile}>
                        Reports
                      </Link>
                    </li>
                    <li>
                      <a onClick={this.mobileSignOut}>Sign Out</a>
                    </li>
                  </ul>
                </div>
              </div>
            )}
          </div>
        }
      />
    );
  }
}

AdminNav.propTypes = {
  signOutAdmin: PropTypes.func.isRequired,
};
const mapStateToProps = (state) => {
  const { errors, loading, authenticated, user } = state.admin_auth;

  return { errors, loading, authenticated, user };
};

export default withRouter(
  connect(mapStateToProps, actions, null, { pure: false })(AdminNav)
);

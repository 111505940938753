import {
  CREDIT_CARD_FIELD_CHANGED,
  CREDIT_CARD_CLEAR_FIELDS
} from "Constants/redux";

export const creditCardFieldChanged = (field, value) => {
  return {
    type: CREDIT_CARD_FIELD_CHANGED,
    payload: { field, value }
  };
};
export const creditCardClearFields = (field, value) => {
  return {
    type: CREDIT_CARD_CLEAR_FIELDS,
    payload: { field, value }
  };
};

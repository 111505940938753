import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  FETCH_RECURRENCES_REQUEST,
  FETCH_RECURRENCES_SUCCESS,
  ADD_RECURRENCE_REQUEST,
  ADD_RECURRENCE_SUCCESS,
  ADD_RECURRENCE_FAILURE,
  SET_RECURRENCES_QUERY,
  SET_RECURRENCES_PAGINATION,
  SET_RECURRENCES_PAGINATION_PAGE,
  FETCH_RECURRENCES_FAILURE,
  FETCH_RECURRENCE_SUCCESS,
} from 'Constants/redux';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';
import { setErrors, openSuccessSnackBar } from './dashboardActionCreators';

export const setRecurrenceQuery = (query) => {
  return {
    type: SET_RECURRENCES_QUERY,
    payload: { query },
  };
};

export const setRecurrencePagination = (perPage) => {
  return {
    type: SET_RECURRENCES_PAGINATION,
    payload: { perPage },
  };
};

export const setRecurrencePaginationPage = (page) => {
  return {
    type: SET_RECURRENCES_PAGINATION_PAGE,
    payload: { page },
  };
};

export const receiveRecurrences = (response) => {
  const recurrences = response.data.recurrences;
  const pagination = response.data.meta?.pagination || {};
  return {
    type: FETCH_RECURRENCES_SUCCESS,
    payload: { recurrences, pagination },
  };
};

export const fetchRecurrences = (payload = {}) => {
  return (dispatch, getState) => {
    const { per, query, recurrences, page } = getState().recurrence;
    dispatch({
      type: FETCH_RECURRENCES_REQUEST,
      payload,
      query,
      per,
      page,
      recurrences,
    });
  };
};

export const searchRecurrenceNames = (query) => {
  return (dispatch) => {
    dispatch(setRecurrenceQuery(query));
    dispatch(fetchRecurrences());
  };
};

export const paginateRecurrence = (perPage) => {
  return (dispatch) => {
    dispatch(setRecurrencePagination(perPage));
    dispatch(fetchRecurrences());
  };
};

export const paginatePageRecurrence = (page) => {
  return (dispatch) => {
    dispatch(setRecurrencePaginationPage(page));
    dispatch(fetchRecurrences());
  };
};

export const addRecurrenceNames = (link) => {
  return (dispatch, getState) => {
    const { query } = getState().staffRoles;
    dispatch({
      type: ADD_RECURRENCE_REQUEST,
      query,
      link,
    });
  };
};

export function fetchRecurrence(id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_RECURRENCES_REQUEST });
    dispatch(openLoadingSpinner('Retrieving...'));
    service
      .get(`${ROOT_URL}/recurrences/${id}`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          payload: response.data,
          type: FETCH_RECURRENCE_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RECURRENCES_FAILURE,
        });
      });
  };
}

export const createRecurrence = (data) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Saving new recurrence...'));
    return service
      .post(`${ROOT_URL}/recurrences`, data)
      .then((response) => {
        dispatch({
          type: ADD_RECURRENCE_SUCCESS,
          payload: response.data,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        const errors = error.response.data;
        console.log(error);
        saveError(error, getState());
        dispatch(setErrors(errors));
        dispatch({
          error,
          type: ADD_RECURRENCE_FAILURE,
        });

        return Promise.reject(error);
      })
      .finally(() => {
        dispatch(closeLoadingSpinner());
      });
  };
};

export const updateRecurrence = (id, data) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Saving service...'));
    return service
      .patch(`${ROOT_URL}/recurrences/${id}`, data)
      .then((response) => {
        dispatch({
          type: ADD_RECURRENCE_SUCCESS,
          payload: response.data,
        });

        return Promise.resolve(response);
      })
      .catch((error) => {
        const errors = error.response.data;
        console.log(error);
        saveError(error, getState());
        dispatch(setErrors(errors));
        dispatch({
          error,
          type: ADD_RECURRENCE_FAILURE,
        });

        return Promise.reject(error);
      })
      .finally(() => {
        dispatch(closeLoadingSpinner());
      });
  };
};

import React from 'react';

const StandardRentalTableHead = (props) => {
  return (
    <tr>
      {props?.multilocationAllLocations && <th className='exSm'>Location</th>}
      <th>Item</th>
      <th>Item Token</th>
      <th>Overbooked Quantity</th>
      <th className='date'>Date</th>
      <th className='actionSm' />
    </tr>
  );
};
export default StandardRentalTableHead;

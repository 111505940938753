import { FunctionSuccess } from '../../../../types/misc/FunctionSuccessType';
import { SplitDateTimeProps } from '../ParentOrderSummaryInvoice.types';

export const splitDateTime = (
  dateTimeString: string
): FunctionSuccess<SplitDateTimeProps> => {
  const date = new Date(dateTimeString);

  if (isNaN(date.getTime())) {
    return { success: false, error: 'Invalid Date' };
  }

  const dateOptions: Intl.DateTimeFormatOptions = {
    weekday: 'short',
    year: 'numeric',
    month: 'short',
    day: 'numeric',
  };

  const timeOptions: Intl.DateTimeFormatOptions = {
    hour: '2-digit',
    minute: '2-digit',
    timeZoneName: 'short',
  };

  const formattedDate = date.toLocaleDateString('en-US', dateOptions);
  const formattedTime = date.toLocaleTimeString('en-US', timeOptions);

  return { success: true, data: { formattedDate, formattedTime } };
};

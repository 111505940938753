import service from '../../api/service';
import {
  CREATE_RENTAL_FORM_V2_REQUEST,
  CREATE_RENTAL_FORM_V2_SUCCESS,
} from '../../constants/redux';
import { ROOT_URL } from '../../api';
import { ThunkAction } from 'redux-thunk';
import AppState from '../../types/AppState';
import { RentalFormActionTypes } from './types';
import {
  closeLoadingSpinner,
  openLoadingSpinner,
} from '../LoadingSpinnerActions';

import converter from 'json-style-converter/es5';

export interface CreateRentalVersionPayload {
  rentalId?: number;
  timeZone?: string;
  shouldDuplicate?: boolean;
  preserveName?: boolean;
  shouldDuplicateDocuments?: boolean;
  eventId?: number;
}

export const createRentalVersion = (
  payload: CreateRentalVersionPayload = {}
): ThunkAction<
  Promise<void>,
  AppState,
  unknown,
  RentalFormActionTypes
> => async (dispatch) => {
  dispatch({
    type: CREATE_RENTAL_FORM_V2_REQUEST,
  });
  dispatch(openLoadingSpinner('Saving rental data...'));

  try {
    const response = await service.post(
      `${ROOT_URL}/rental_versions`,
      converter.camelToSnakeCase(payload)
    );
    dispatch({
      type: CREATE_RENTAL_FORM_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    // ! return before "finally"
    return Promise.reject();
  } finally {
    dispatch(closeLoadingSpinner());
  }
};

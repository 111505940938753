import { SET_CONTACT_TYPE } from "Constants/redux";

const INITIAL_STATE = {
  contactType: ""
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_CONTACT_TYPE:
      return {
        ...state,
        contactType: action.payload.contactType
      };
    default:
      return state;
  }
};

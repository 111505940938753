import React from "react";
import { Link } from "react-router-dom";
import service from "Api/service";
import { saveError } from "Api";
import { connect } from "react-redux";
import * as actions from "Actions";
import {
  EditCircle,
  UserCircle,
  PhoneCircle,
  CellCircle,
  FaxCircle,
  EmailCircle,
  LocationCircle,
  User
} from "Utils/SvgIcons";
import { formatPhoneNumber } from "HelperFunctions/general";
import Imgix from "react-imgix";

class Profile extends React.Component {
  constructor() {
    super();
    this.state = {
      employee: {
        firstName: "",
        lastName: "",
        email: "",
        phoneNumber: {
          office: "",
          cell: "",
          fax: ""
        },
        physicalAddress: {
          streetAddress1: "",
          streetAddress2: "",
          city: "",
          locale: "",
          postalCode: "",
          country: ""
        },
        role: "",
        picture: null
      }
    };
  }
  componentDidMount() {
    const { fetchUser, openLoadingSpinner, closeLoadingSpinner } = this.props;
    fetchUser();
    openLoadingSpinner("Loading...");
    service
      .get(process.env.REACT_APP_API_DOMAIN + "/api/employees/profile")
      .then(response => {
        const { employee } = response.data;
        const newPhone = Object.assign(
          this.state.employee.phoneNumber,
          employee.phoneNumber
        );
        const newAddress = Object.assign(
          this.state.employee.physicalAddress,
          employee.physicalAddress
        );
        const newEmployee = Object.assign(
          this.state.employee,
          response.data.employee,
          { phoneNumber: newPhone, physicalAddress: newAddress }
        );

        this.setState({
          employee: newEmployee
        });
        closeLoadingSpinner();
      })
      .catch(error => {
        console.log(error);
        saveError(error);
        closeLoadingSpinner();
      });
  }

  render() {
    const { employee } = this.state;
    const address = employee.physicalAddress;
    return (
      <div className="profile">
        <h1>My Profile</h1>
        <Link to={"/profile/edit"} className="btnLink">
          Edit Profile
          <EditCircle />
        </Link>
        <figure>
          {employee.picture === null ? (
            <User />
          ) : (
            <Imgix
              src={employee.picture.imgixUrl}
              alt={employee.firstName + " " + employee.lastName}
              height={200}
              width={200}
            />
          )}
        </figure>
        <div className="iconView">
          <div className="fields">
            <UserCircle />
            <h2>
              {employee.firstName} {employee.lastName}
            </h2>
            {employee.phoneNumber.office && <PhoneCircle />}
            {employee.phoneNumber.office && (
              <a
                href={"tel:" + employee.phoneNumber.office}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber(employee.phoneNumber.office)}
              </a>
            )}
            {employee.phoneNumber.cell && <CellCircle />}
            {employee.phoneNumber.cell && (
              <a
                href={"tel:" + employee.phoneNumber.cell}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber(employee.phoneNumber.cell)}
              </a>
            )}
            {employee.phoneNumber.fax && <FaxCircle />}
            {employee.phoneNumber.fax && (
              <a
                href={"tel:" + employee.phoneNumber.fax}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber(employee.phoneNumber.fax)}
              </a>
            )}
            <EmailCircle />
            <a
              href={"mailto:" + employee.email}
              target="_blank"
              rel="noopener noreferrer"
            >
              {employee.email}
            </a>
            {address.streetAddress1 && <LocationCircle />}
            {address.streetAddress1 && (
              <a
                href={
                  "http://maps.google.com/maps?q=" +
                  address.streetAddress1 +
                  " " +
                  address.city +
                  "," +
                  address.locale +
                  " " +
                  address.postalCode +
                  " " +
                  address.country
                }
                target="_blank"
                rel="noopener noreferrer"
              >
                <div>{address.streetAddress1}</div>
                {address.streetAddress2 && (
                  <div># {address.streetAddress2}</div>
                )}
                <div>
                  {address.city}, {address.locale} {address.postalCode},{" "}
                  {address.country}
                </div>
              </a>
            )}
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { errorsOpen, errors } = state.dashboard;

  return { errorsOpen, errors };
};

export default connect(mapStateToProps, actions)(Profile);

import React from 'react';
import StandardRentalTableHead from './heads/StandardRentalTableHead';
import CheckingInHead from './heads/CheckingInInventory';
import CheckingOutHead from './heads/CheckingOutInventory';
import ShortageHead from './heads/ShortageInventory';
import ConnectHoldHead from './heads/ConnectHoldInventory';

const RentalInventoryTableHead = (props) => {
  const { type, onSort, multilocationAllLocations } = props;
  switch (type) {
    case 'checking_in':
      return <CheckingInHead {...props} />;
    case 'checking_out':
      return <CheckingOutHead {...props} />;
    case 'shortage':
      return (
        <ShortageHead multilocationAllLocations={multilocationAllLocations} />
      );
    case 'connect_hold':
    case 'subrentals_all':
      return <ConnectHoldHead onSort={onSort} {...props} />;
    default:
      return <StandardRentalTableHead />;
  }
};
export default RentalInventoryTableHead;

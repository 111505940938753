import * as actionTypes from 'Constants/redux';

export function openSuccessSnackBar(message, autoDismiss = 3) {
  return {
    type: actionTypes.OPEN_SUCCESS_SNACKBAR,
    payload: {
      successOpen: true,
      successMessage: message,
      autoDismiss: autoDismiss,
    },
  };
}
export function closeSuccessSnackBar() {
  return {
    type: actionTypes.CLOSE_SUCCESS_SNACKBAR,
    payload: false,
  };
}

export function setOpenValidationSnackBar() {
  return {
    type: actionTypes.SET_OPEN_VALIDATION_SNACKBAR,
    payload: true,
  };
}
export function setClosedValidationSnackBar() {
  return {
    type: actionTypes.SET_CLOSED_VALIDATION_SNACKBAR,
    payload: false,
  };
}

export function setErrors(errors) {
  return {
    type: actionTypes.SET_ERRORS,
    payload: {
      errors: errors,
      errorsOpen: true,
    },
  };
}

export function setAlert(alertMessage) {
  return {
    type: actionTypes.SET_ALERT,
    payload: {
      alertMessage: alertMessage,
      alertOpen: true,
    },
  };
}

export const setErrorMessage = ({ field, text }) => {
  return {
    type: actionTypes.SET_ERROR_MESSAGE,
    payload: {
      field,
      text,
    },
  };
};

export const setMultilocationAllLocations = (value) => {
  return (dispatch) => {
    dispatch({
      type: actionTypes.SET_MULTILOCATION_ALL_LOCATIONS,
      payload: value,
    });
  };
};

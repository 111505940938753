import React from "react";
import * as actions from "Actions";
import { connect } from "react-redux";
import service from "Api/service";
import Logo from "../../images/logos/logo_googleCalendar.png";
import { GoogleLogin } from "react-google-login";
import LoadingSpinner from "Utils/LoadingSpinner";
import { RadioButton, RadioButtonGroup } from "material-ui/RadioButton";
import { CheckOnCircle, CheckOn, RemoveCircle, Settings } from "Utils/SvgIcons";
import Checkbox from "Utils/Checkbox";
import { displayRentalStatus } from "Constants/displayConstants";
import Dialog from "material-ui/Dialog";

class GoogleCal extends React.Component {
  state = {
    synced: false,
    loading: false,
    selectingCalendar: false,
    updatingCalendarSync: false,
    calendarId: "",
    calendarSyncRentalStatus: "",
    calendars: [],
    disconnectModal: false
  };
  componentDidMount() {
    this.fetchCalendars();
  }
  fetchCalendars = () => {
    const component = this;
    component.setState({ loading: true });
    service
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/google_calendar/employee_location_relationship/google_calendars`
      )
      .then(response => {
        component.setState({
          loading: false,
          synced: true,
          calendarSyncRentalStatus: response.data.calendarSyncRentalStatus,
          calendarId: response.data.calendarId,
          calendars: response.data.calendars
        });
      })
      .catch(error => {
        component.setState({
          loading: false,
          synced: false
        });
      });
  };
  handleLoginSuccess = response => {
    const { openLoadingSpinner, closeLoadingSpinner, setErrors } = this.props;
    openLoadingSpinner("Connecting...");
    const component = this;
    service
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/google_calendar/employee_location_relationship/connect_google_calendar`,
        {
          code: response.code
        }
      )
      .then(response => {
        component.setState({
          synced: true,
          calendarSyncRentalStatus: response.data.calendarSyncRentalStatus,
          calendars: response.data.calendars
        });
        closeLoadingSpinner();
      })
      .catch(error => {
        closeLoadingSpinner();
        setErrors(error.response.data);
      });
  };
  handleLoginFailure = response => {
    console.log(response);
  };
  showDisconnectModal = () => {
    this.setState({ disconnectModal: true });
  };
  hideDisconnectModal = () => {
    this.setState({ disconnectModal: false });
  };
  handleLogoutSuccess = () => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    const component = this;
    openLoadingSpinner("Disconnecting...");
    service
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/google_calendar/employee_location_relationship/disconnect_google_calendar`
      )
      .then(response => {
        this.hideDisconnectModal();
        closeLoadingSpinner();
        component.setState({
          synced: false,
          calendars: [],
          calendarId: ""
        });
      })
      .catch(error => {
        this.hideDisconnectModal();
        closeLoadingSpinner();
      });
  };
  selectCalendar = () => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    const { calendarId } = this.state;
    if (calendarId) {
      openLoadingSpinner("Adjusting sync settings...");
      const component = this;
      service
        .put(
          `${process.env.REACT_APP_API_DOMAIN}/api/google_calendar/employee_location_relationship/select_calendar`,
          {
            employee_location_relationship: {
              google_calendar_id: calendarId
            }
          }
        )
        .then(response => {
          component.setState({
            synced: true,
            selectingCalendar: false
          });
          closeLoadingSpinner();
        })
        .catch(error => {
          closeLoadingSpinner();
        });
    } else {
      this.props.setErrors("Please select a calendar.");
    }
  };
  updateCalendarSyncRentalStatus = () => {
    const { openLoadingSpinner, closeLoadingSpinner } = this.props;
    openLoadingSpinner("Adjusting sync settings...");
    const component = this;
    service
      .put(
        `${process.env.REACT_APP_API_DOMAIN}/api/google_calendar/employee_location_relationship`,
        {
          employee_location_relationship: {
            calendar_sync_rental_status: this.state.calendarSyncRentalStatus
          }
        }
      )
      .then(response => {
        component.setState({
          synced: true,
          updatingCalendarSync: false
        });
        closeLoadingSpinner();
      })
      .catch(error => {
        closeLoadingSpinner();
      });
  };
  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };
  toggleSelectingCalendar = () => {
    this.setState({
      selectingCalendar: !this.state.selectingCalendar
    });
  };
  toggleUpdatingCalendarSync = () => {
    this.setState({
      updatingCalendarSync: !this.state.updatingCalendarSync
    });
  };
  handleSelectCalendarSyncStatus = (id, isChecked) => {
    const { calendarSyncRentalStatus } = this.state;
    let newSelected = [];
    let selected = calendarSyncRentalStatus.split(",");
    if (isChecked) {
      if (selected.indexOf(id) === -1) {
        newSelected = selected.concat(id);
      }
    } else {
      if (selected.indexOf(id) !== -1) {
        newSelected = selected.filter(oid => oid !== id);
      }
    }
    this.setState({
      calendarSyncRentalStatus: newSelected.join(",")
    });
  };
  renderCalendars = () => {
    const { calendarId, calendars, selectingCalendar } = this.state;
    if (!selectingCalendar) {
      return (
        <ul>
          {calendars.map((calendar, index) => (
            <li key={index} className="calendarGrid calendarMargin">
              <span className="blueCheck">
                {calendar.id === calendarId && <CheckOn />}
              </span>
              <span>{calendar.summary}</span>
            </li>
          ))}
        </ul>
      );
    } else {
      return (
        <div>
          <RadioButtonGroup
            name="calendarId"
            className="radioList"
            onChange={this.handleChange}
            valueSelected={calendarId}
          >
            {calendars.map((calendar, index) => (
              <RadioButton
                key={index}
                className="radio calendarMargin"
                value={calendar.id}
                label={calendar.summary}
              />
            ))}
          </RadioButtonGroup>
          <div className="actions" style={{ height: `30px` }}>
            <a className="btn" onClick={this.selectCalendar}>
              Submit
            </a>
            <a className="btn cancel" onClick={this.toggleSelectingCalendar}>
              Cancel
            </a>
          </div>
        </div>
      );
    }
  };
  renderCalendarSyncStatus = () => {
    const allStatuses = [
      "quote",
      "reservation",
      "in_use",
      "checked_in",
      "closed"
    ];
    let selected = this.state.calendarSyncRentalStatus.split(",");
    if (this.state.updatingCalendarSync) {
      return (
        <div>
          <ul>
            {allStatuses.map((status, index) => (
              <li key={index} className="calendarMargin">
                <Checkbox
                  onCheck={(event, isChecked) =>
                    this.handleSelectCalendarSyncStatus(status, isChecked)
                  }
                  label={displayRentalStatus[status]}
                  checked={selected.includes(status)}
                />
              </li>
            ))}
          </ul>
          <div className="actions">
            <a className="btn" onClick={this.updateCalendarSyncRentalStatus}>
              Submit
            </a>
            <a className="btn cancel" onClick={this.toggleUpdatingCalendarSync}>
              Cancel
            </a>
          </div>
        </div>
      );
    } else {
      return (
        <ul>
          {allStatuses.map((status, index) => (
            <li key={index} className="calendarGrid">
              <span className="blueCheck">
                {selected.includes(status) && <CheckOn />}
              </span>
              <span>{displayRentalStatus[status]}</span>
            </li>
          ))}
        </ul>
      );
    }
  };
  render() {
    const { loading, synced, disconnectModal } = this.state;
    return (
      <div className="googleCalendar">
        <h1>Sync Google Calendar</h1>
        <img src={Logo} alt="Google Calendar" />
        {loading ? (
          <LoadingSpinner />
        ) : synced ? (
          <div className="calendarFlex">
            <div>
              <span className="connected">
                <CheckOnCircle />
                Google Calendar Synced
              </span>
              <button
                className="btn connectedFull"
                onClick={this.showDisconnectModal}
              >
                Disconnect
              </button>
            </div>
            <div className="calendarFlexInner">
              <section>
                <a
                  className="sectionEdit"
                  onClick={this.toggleSelectingCalendar}
                >
                  <Settings />
                </a>
                <h2>Select which calendar to sync to:</h2>
                {this.renderCalendars()}
              </section>
              <section>
                <a
                  className="sectionEdit"
                  onClick={this.toggleUpdatingCalendarSync}
                >
                  <Settings />
                </a>
                <h2>Order Status:</h2>
                {this.renderCalendarSyncStatus()}
              </section>
            </div>
            <div />
          </div>
        ) : (
          <div>
            <span>
              <RemoveCircle />
              Google Calendar not synced
            </span>
            <GoogleLogin
              clientId={process.env.REACT_APP_GCAL_API_CLIENT_ID}
              onSuccess={this.handleLoginSuccess}
              onFailure={this.handleLoginFailure}
              cookiePolicy={"single_host_origin"}
              responseType="code"
              accessType="offline"
              approvalPrompt="force"
              prompt="consent"
              render={renderProps => {
                return (
                  <button
                    className="btn full"
                    onClick={renderProps.onClick}
                    disabled={renderProps.disabled}
                  >
                    Connect
                  </button>
                );
              }}
              scope="https://www.googleapis.com/auth/calendar https://www.googleapis.com/auth/calendar.readonly https://www.googleapis.com/auth/calendar.events"
            />
          </div>
        )}
        <Dialog
          modal={false}
          open={disconnectModal}
          className="dialog"
          bodyClassName="body"
          contentClassName="content"
          actionsContainerClassName="actions"
          overlayClassName="overlay"
          onRequestClose={this.hideDisconnectModal}
          actions={[
            <a className="btn warn" onClick={this.handleLogoutSuccess}>
              Yes, Disconnect Google Calendar
            </a>,
            <div>
              <a
                className="btn cancel calendarCancel"
                onClick={this.hideDisconnectModal}
              >
                No
              </a>
            </div>
          ]}
        >
          <p>
            Are you sure you want to disconnect Google Calendar integration?
          </p>
          <p>
            Upon disconnecting, all entries in your calendar will be removed.
          </p>
        </Dialog>
      </div>
    );
  }
}

export default connect(null, actions)(GoogleCal);

import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import Modal from 'Utils/Modal';
import Checkbox from 'Utils/Checkbox';
import service from 'Api/service';
import ScrollArea from 'Utils/react-scrollbar';

class CompliancePopUp extends React.Component {
  state = {
    complied: false,
  };

  toggleComplied = () => {
    this.setState({
      complied: !this.state.complied,
    });
  };

  handleSubmit = () => {
    const { complied } = this.state;
    const { employeeLocationRelationship } = this.props;

    if (!complied) {
      this.props.setErrors('You need to agree to the new Terms & Conditions.');
    } else {
      this.props.openLoadingSpinner('Agreeing to New Terms and Conditions...');
      service
        .post(
          process.env.REACT_APP_API_DOMAIN +
            '/api/employees/comply_to_new_terms',
          {
            employee_location_relationship_id: employeeLocationRelationship.id,
          }
        )
        .then((response) => {
          this.props.closeLoadingSpinner();
          this.props.openSuccessSnackBar(
            'Terms and Conditions Agreement Complete'
          );
          this.props.closeComplianceModal();
        });
    }
  };

  render() {
    const { open, newTermContent } = this.props;
    const { complied } = this.state;

    return (
      <Modal
        open={open}
        className='large'
        title='TapGoods Terms & Conditions Have Changed'
        hideCloseButton
        actions={[
          <a className='btn full' onClick={this.handleSubmit}>
            Accept Terms and Conditions
          </a>,
        ]}
      >
        <section style={{ backgroundColor: `#e6e8e9` }}>
          <ScrollArea speed={0.8} horizontal={false}>
            <div
              className='editedText'
              dangerouslySetInnerHTML={{
                __html: newTermContent,
              }}
            />
          </ScrollArea>
        </section>
        <Checkbox
          checked={complied}
          onCheck={this.toggleComplied}
          label='I agree to the new Terms & Conditions'
        />
      </Modal>
    );
  }
}
const mapStateToProps = (state) => {
  const { open, newTermContent } = state.compliance;
  const { employeeLocationRelationship } = state.user;
  return { open, newTermContent, employeeLocationRelationship };
};

export default connect(mapStateToProps, actions)(CompliancePopUp);

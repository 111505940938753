import { SEARCH_INVENTORY, INFINITE_SUCCESS } from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";

const fetchInfiniteInventoryEpic = action$ =>
  action$
    .ofType(SEARCH_INVENTORY)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.post(
          `${process.env.REACT_APP_API_DOMAIN}${action.link}`,
          action.options
        )
      ).map(response => {
        return {
          options: action.options,
          link: action.link,
          reset: true,
          response: response,
          productType: action.productType,
          type: INFINITE_SUCCESS
        };
      });
    });

export default fetchInfiniteInventoryEpic;

import * as actionTypes from 'Constants/redux';
import { SET_FLOW_SHEETS_OPERATIONS_FILTER } from 'Constants/redux';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';
import { FlowSheetsFilterType } from './types';
import { FlowSheetsActionTypes } from './types';

export const setFlowSheetsFilters = (
  filter: FlowSheetsFilterType
): ThunkAction<void, AppState, unknown, FlowSheetsActionTypes> => (dispatch) => {
  window.localStorage.setItem('flowSheetsFilters', JSON.stringify(filter));
  dispatch({
    type: SET_FLOW_SHEETS_OPERATIONS_FILTER,
    payload: filter
  })
}

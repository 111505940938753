// TODO: move permissionGate() logic here if possible -- need checkPermission and setErrors from Redux

// TO USE: add permission_key and associated error message as needed for checks that run on FE Links, Accordions, Dropdowns, etc.

export const PERMISSION_ERROR_MESSAGES = {
  add_new_contacts:
    'You don’t currently have the ability to create new contacts. Contact a supervisor to proceed.',
  send_statements_admin:
    'You are not currently permitted to view this statement. Contact a supervisor to proceed.',
};

import React from "react";
import { Helmet } from "react-helmet";
import ValidationPopUp from "Utils/ValidationPopUp";
import SuccessPopUp from "Utils/SuccessPopUp";
import VersionPopUp from "Utils/VersionPopUp";
import GlobalLoadingSpinner from "Utils/GlobalLoadingSpinner";
import GlobalCircleOfDeathErrorModal from "Utils/GlobalCircleOfDeathErrorModal";
import AlertPopUp from "./utils/AlertPopUp";

const App = props => (
  <aside>
    <Helmet>
      <title>TapGoods</title>
      <meta
        name="description"
        content="Renting Reinvented. TapGoods offers rental businesses a revolutionary platform to improve internal operations, wow current customers, and attract new customers."
      />
      <meta
        name="keywords"
        content="Inventory Management Software, Rental Software, Point of rental software, Rental, Renting Items, Rentals Dallas Texas, Event Rentals, Party Rentals, Inventory Management"
      />
    </Helmet>
    <ValidationPopUp />
    <AlertPopUp />
    <GlobalLoadingSpinner />
    <GlobalCircleOfDeathErrorModal />
    <SuccessPopUp />
    <VersionPopUp />

  </aside>
);

export default App;

import React from 'react';
import { Link } from 'react-router-dom';
import { AddCircle } from 'Utils/SvgIcons';
import ConversationCard from './ConversationCard';
import ScrollArea from 'Utils/react-scrollbar';
import classnames from 'classnames';

// IndexRentals is the parent of RentalsFilter.
// This page is called whenever a Filter is in the path.

const ConversationsDesktopSidePane = (props) => {
  const {
    conversations,
    newConversationLink,
    forRental,
    unreadMessages,
  } = props;
  return (
    <aside>
      <div
        className={classnames({
          full: true,
          static: forRental,
        })}
      >
        <Link className='btnLink add' to={newConversationLink}>
          New Message
          <AddCircle />
        </Link>
        {unreadMessages > 0 && (
          <div>
            <span className='callout'>{unreadMessages} </span>
            Unread
          </div>
        )}
        <ScrollArea speed={0.8} horizontal={false}>
          <ul className='itemList convItemList'>
            {conversations?.map((conversation, index) => {
              return (
                <ConversationCard
                  conversation={conversation}
                  key={index}
                  forRental={forRental}
                />
              );
            })}
          </ul>
        </ScrollArea>
      </div>
    </aside>
  );
};

export default ConversationsDesktopSidePane;

import React from 'react';
import { FormattedDate } from 'react-intl';
import { Link } from 'react-router-dom';
import { Alert } from 'Utils/SvgIcons';
import RentalName from './RentalName';

const ShortageInventoryTableRow = (props) => {
  const {
    rentalInventory,
    link,
    location,
    multilocationAllLocations,
    onClick,
  } = props;

  const startDate = rentalInventory?.parent_rental?.start_date
    ? rentalInventory.parent_rental.start_date
    : rentalInventory?.parent_rental?.schedule?.event_start_date_time;

  const itemToken = rentalInventory.parent_inventory?.token;

  let itemResourceName = rentalInventory.parent_inventory?.product_type;

  if (itemResourceName === 'addons') {
    itemResourceName = 'purchases';
  }

  const itemLink = `/inventory/${itemResourceName}/${itemToken}`;

  return (
    <tbody>
      <Link className='rTableRow' onClick={onClick} to={link}>
        {multilocationAllLocations && (
          <td className='bold'>{location?.locationCode}</td>
        )}
        <td colSpan='4' className='bold'>
          {rentalInventory.name ? rentalInventory.name : 'Untitled'}
        </td>
        <td rowSpan='3' className='alert'>
          <Alert />
        </td>
      </Link>
      <Link className='rTableRow' onClick={onClick} to={link}>
        {multilocationAllLocations && <td />}
        <td>
          <RentalName rental={rentalInventory.parent_rental} />
        </td>
        <Link to={itemLink}>
          <td>#{itemToken}</td>
        </Link>
        <td>{rentalInventory.shortage_quantity}</td>
        <td>
          {startDate && (
            <FormattedDate value={startDate} day='2-digit' month='2-digit' />
          )}
        </td>
      </Link>
    </tbody>
  );
};

export default ShortageInventoryTableRow;

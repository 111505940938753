import {
  DEBOUNCED_UPDATE_CONNECT_DEFAULT_REQUEST,
  UPDATE_CONNECT_DEFAULT_SUCCESS,
  UPDATE_CONNECT_DEFAULT_FAILURE
} from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";
import { ROOT_URL } from "Api";

const connectDefaultEpic = action$ =>
  action$
    .ofType(DEBOUNCED_UPDATE_CONNECT_DEFAULT_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.patch(`${ROOT_URL}/connect_default/`, action.data)
      )
        .map(response => {
          return {
            response: response.data,
            type: UPDATE_CONNECT_DEFAULT_SUCCESS
          };
        })
        .catch(error => {
          console.log(error);
          return {
            error,
            type: UPDATE_CONNECT_DEFAULT_FAILURE
          };
        });
    });

export default connectDefaultEpic;

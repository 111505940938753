import queryString from "query-string";

export const stringify = obj => {
  let newObj = {};
  Object.keys(obj).forEach(key => {
    const value = obj[key];
    if (value !== null && value !== undefined && value !== "") {
      newObj[key] = value;
    }
  });

  return queryString.stringify(newObj);
};

export const parseQuery = query => queryString.parse(query);

import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  FETCH_LOCATION_REQUEST,
  FETCH_LOCATION_FAILURE,
  FETCH_LOCATION_SUCCESS,
  UPDATE_LOCATION_REQUEST,
  UPDATE_LOCATION_FAILURE,
  UPDATE_LOCATION_SUCCESS,
  FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS,
  ADD_LOCATION_TO_USER,
  MODIFY_LOCATION_STATE,
  ADD_TRUCK_REQUEST,
  UPDATE_TRUCK_REQUEST,
  REMOVE_TRUCK_REQUEST,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST,
} from 'Constants/redux';
import { openSuccessSnackBar, setErrors } from './dashboardActionCreators';

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

export const receiveLocation = (response) => {
  return (dispatch, getState) => {
    dispatch({
      response: response.data,
      type: FETCH_LOCATION_SUCCESS,
    });
  };
};

export const fetchLocation = () => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_LOCATION_REQUEST });
    service
      .get(`${ROOT_URL}/location/`)
      .then((response) => {
        const user = getState().auth.user;
        const location = response.data.location;
        const employeeLocationRelationship = location.employeeLocationRelationships?.find(
          (elr) => elr.locationId == location.id && elr.employeeId == user.id
        );
        dispatch({
          type: FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS,
          employeeLocationRelationship,
        });
        dispatch({
          response: response.data,
          type: FETCH_LOCATION_SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_LOCATION_FAILURE,
        });
      });
  };
};

export const modifyLocationState = (state) => {
  return (dispatch, getState) => {
    dispatch({
      type: MODIFY_LOCATION_STATE,
      payload: state,
    });
  };
};

export const cleanupAfterAddNewLocation = (location) => {
  return (dispatch, getState) => {
    dispatch(fetchLocation());
    dispatch({
      type: ADD_LOCATION_TO_USER,
      payload: location,
    });
  };
};

export const updateLocation = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_LOCATION_REQUEST });
    return service
      .patch(`${ROOT_URL}/location/`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_LOCATION_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        if (error.response.data) {
          dispatch(setErrors(error.response.data));
        }

        dispatch({
          error,
          type: UPDATE_LOCATION_FAILURE,
        });

        return Promise.reject(error);
      });
  };
};

export const fetchStorefrontPagesVisibility = () => {
  return (dispatch, getState) => {
    return service
      .get(
        process.env.REACT_APP_API_DOMAIN +
          '/api/location/storefront_pages_visibility'
      )
      .then((response) => {
        dispatch({
          response: response.data,
          type: FETCH_STOREFRONT_PAGES_VISIBILITY_SUCCESS,
        });
      })
      .catch(function(error) {
        console.log(error);
        saveError(error, getState());
      });
  };
};

export const addTruck = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: ADD_TRUCK_REQUEST });
    dispatch(openLoadingSpinner('Adding truck...'));
    return service
      .post(`${ROOT_URL}/business/trucks/`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_LOCATION_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: UPDATE_LOCATION_FAILURE,
        });
        return Promise.reject(error);
      });
  };
};

export const updateTruck = (id, data) => {
  return (dispatch, getState) => {
    dispatch({ type: UPDATE_TRUCK_REQUEST });
    dispatch(openLoadingSpinner('Saving truck...'));
    return service
      .patch(`${ROOT_URL}/business/trucks/${id}`, data)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_LOCATION_SUCCESS,
        });
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        saveError(error, getState());
        dispatch({
          error,
          type: UPDATE_LOCATION_FAILURE,
        });
        return Promise.reject(error);
      });
  };
};

export const sendChurnZeroEvent = (eventName, description = null) => {
  const payload = {
    event: {
        name: eventName,
        description: description,
    },
  };

  return service
    .post(`${ROOT_URL}/churn_zero_events`, payload)
    .catch((error) => {
        saveError(error);
    });
}
export const removeTruck = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: REMOVE_TRUCK_REQUEST });
    return service
      .delete(`${ROOT_URL}/business/trucks/${id}`)
      .then((response) => {
        dispatch({
          response: response.data,
          type: UPDATE_LOCATION_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: UPDATE_LOCATION_FAILURE,
        });
        return Promise.reject(error);
      });
  };
};

import {
  CompanyRentalRelationship,
  Rental,
} from '../../../../../types/graphql';

export const extractCompanyInformations = (
  companyRentalRelationship: CompanyRentalRelationship
) => {
  if (!companyRentalRelationship) return null;

  const { taxExemptionNumber } = companyRentalRelationship;

  const {
    name,
    mainContactAddress: physicalAddress,
    mainPhoneNumber: companyMainPhoneNumber,
  } = companyRentalRelationship?.company!;

  const phone = companyMainPhoneNumber?.office || companyMainPhoneNumber?.cell;

  return {
    name,
    phone,
    physicalAddress,
    taxExemptionNumber,
  };
};

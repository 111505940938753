import React from 'react';
import { EventIcon } from '../../../utils/SvgIcons';
import { Link } from 'react-router-dom';

export default function RentalName({
  rental,
  withEvent = false,
}) {
  return (
    <div>
      <div className='rentalRowName'>
        <span className='rentalRow__rentalToken'>{`#${rental.token}`}</span>{' '}
        {rental.name ? rental.name : 'Untitled'}
      </div>
      {withEvent && rental.event?.token && (
        <Link
          to={`/orders/events/details/${rental.event.token}`}
          className='rentalRow__eventNameContainer'
        >
          <EventIcon className='rentalRow__eventIcon' />
          <p className='rentalRow__eventName'>
            #{rental.event.token}: {rental.event.name}
          </p>
        </Link>
      )}
    </div>
  );
}

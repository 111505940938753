import service from 'Api/service';
import { saveError } from 'Api';
import * as actionTypes from 'Constants/redux';
import converter from 'json-style-converter/es5';
import { setErrors } from './dashboardActionCreators';

export const fetchGeneralLedgerReport = (filter) => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_GENERAL_LEDGER_REPORT_REQUEST });

    const processedFilter = filter ? converter.camelToSnakeCase(filter) : {};

    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/reports/general_ledger',
        processedFilter
      )
      .then((response) => {
        return dispatch({
          response,
          payload: response.data,
          type: actionTypes.FETCH_GENERAL_LEDGER_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch(setErrors(error.response.data.error));
        dispatch({
          error,
          type: actionTypes.FETCH_GENERAL_LEDGER_REPORT_FAILURE,
        });
      });
  };
};

export const fetchPowerbiReports = () => {
  return (dispatch, getState) => {
    dispatch({ type: actionTypes.FETCH_POWERBI_REPORTS_REQUEST });

    service
      .get(process.env.REACT_APP_API_DOMAIN + '/api/reports/powerbi')
      .then((response) => {
        return dispatch({
          response,
          payload: response.data,
          type: actionTypes.FETCH_POWERBI_REPORTS_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_POWERBI_REPORTS_FAILURE,
        });
      });
  };
};

export const fetchPaymentsReceivedReport = (daily = false) => {
  return (dispatch, getState) => {
    const { containingDate, period, day } = getState().reports;
    const params = Object.assign(
      {
        containing_date: containingDate,
        period: period,
      },
      daily && { day: day }
    );
    dispatch({ type: actionTypes.FETCH_PAYMENTS_RECEIVED_REPORT_REQUEST });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/reports/payments_received',
        params
      )
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_PAYMENTS_RECEIVED_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_PAYMENTS_RECEIVED_REPORT_FAILURE,
        });
      });
  };
};
export const fetchTapgoodsPaymentsReport = () => {
  return (dispatch, getState) => {
    const { containingDate, period } = getState().reports;
    const params = Object.assign({
      containing_date: containingDate,
      period: period,
    });
    dispatch({ type: actionTypes.FETCH_TAPGOODS_PAYMENTS_REPORT_REQUEST });
    service
      .get(
        process.env.REACT_APP_API_DOMAIN + '/api/reports/tapgoods_payments',
        params
      )
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_TAPGOODS_PAYMENTS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_TAPGOODS_PAYMENTS_REPORT_FAILURE,
        });
      });
  };
};
export function selectDay(day) {
  return {
    type: actionTypes.SELECT_DAY,
    payload: { day },
  };
}
export function selectContainingDate(containingDate) {
  return {
    type: actionTypes.SELECT_CONTAINING_DATE,
    payload: { containingDate },
  };
}
export function selectPeriod(period) {
  return {
    type: actionTypes.SELECT_PERIOD,
    payload: { period },
  };
}

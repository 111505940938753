import {
  OPEN_SYSTEM_UPDATE_MODAL,
  CLOSE_SYSTEM_UPDATE_MODAL
} from "Constants/redux";

const INITIAL_STATE = {
  title: "",
  newSystemUpdateContent: "",
  requireAcknowledge: false,
  showPinkTag: false,
  showScreenModal: false,
  lockScreen: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case OPEN_SYSTEM_UPDATE_MODAL:
      return {
        ...state,
        title: action.payload.title,
        htmlContent: action.payload.htmlContent,
        requireAcknowledge: action.payload.requireAcknowledge,
        showPinkTag: action.payload.showPinkTag,
        showScreenModal: action.payload.showScreenModal,
        lockScreen: action.payload.lockScreen
      };
    case CLOSE_SYSTEM_UPDATE_MODAL:
      return {
        ...state,
        open: false
      };
    default:
      return state;
  }
};

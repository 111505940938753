import React from 'react';
import Stepper from '../rentals/Stepper';
import Step from '../rentals/Step';
import StepConnector from '../rentals/StepConnector';
import { ThreeDots, ArrowUp, Settings, CheckMarkEmpty } from 'Utils/SvgIcons';

class ImportWizardProgress extends React.Component {
  getProgressionIndex = (status) => {
    switch (status) {
      case 'selectType':
        return 0;
      case 'upload':
        return 1;
      case 'adjust':
        return 2;
      case 'done':
        return 3;
      default:
        return -1;
    }
  };

  render() {
    const { importStatus, uploading } = this.props;
    const activeStep = this.getProgressionIndex(importStatus);

    return (
      <Stepper activeStep={activeStep} inactiveStep={4}>
        <Step label='Select Type' icon={<ThreeDots viewBox='0 0 12 4' />} />
        <Step label='Upload' icon={<ArrowUp />} connector={<StepConnector />} />
        <Step
          label='Adjust'
          icon={<Settings />}
          connector={
            uploading ? (
              <StepConnector complete={false} dottedConnector={true} />
            ) : (
              <StepConnector />
            )
          }
        />
        <Step
          label='Done!'
          icon={<CheckMarkEmpty />}
          connector={<StepConnector />}
        />
      </Stepper>
    );
  }
}

export default ImportWizardProgress;

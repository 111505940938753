import React from 'react';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import PrintLink from 'Utils/PrintLink';
import LoadingSpinner from 'Utils/LoadingSpinner';
import RentalInventoryTableRow from './tables/RentalInventoryTableRow';
import RentalInventoryTableHead from './tables/RentalInventoryTableHead';
import { selectCheckPermission } from 'HelperFunctions/locations';
import service from 'Api/service';
import { parseQuery } from 'HelperFunctions/QueryString';
import moment from 'moment-timezone';

class PrintCheckingOut extends React.Component {
  state = {
    loading: true,
    inventory: [],
  };
  componentDidMount() {
    this.fetchInventory();
  }
  fetchInventory = () => {
    const component = this;
    const { startDate, endDate } = parseQuery(this.props.location.search);
    const timeZone = moment.tz.guess();
    service
      .get(
        `${process.env.REACT_APP_API_DOMAIN}/api/rental_inventories/checking_out`,
        {
          start_date: startDate || new Date(),
          end_date: endDate || new Date(),
          time_zone: timeZone,
        }
      )
      .then((response) => {
        component.setState({
          inventory: response.data.rentalInventories,
          loading: false,
        });
      })
      .catch((error) => {
        component.setState({
          loading: false,
        });
        console.log(error);
      });
  };

  _renderTableData = (inventory, user, businessLocation) => {
    const TableRow = RentalInventoryTableRow('checking_out');

    if (inventory.length > 0) {
      return (
        <table className='grid double'>
          <thead>
            <RentalInventoryTableHead type={'checking_out'} />
          </thead>
          {inventory.map((rentalInventory, index) => {
            return (
              <TableRow
                key={index}
                rentalInventory={rentalInventory}
                user={user}
                location={businessLocation}
              />
            );
          })}
        </table>
      );
    } else {
      return (
        <table className='grid double'>
          <thead>
            <span>No inventory checking out during the selected dates.</span>
          </thead>
        </table>
      );
    }
  };

  render() {
    const { user, businessLocation, checkPermission } = this.props;
    const { inventory, loading } = this.state;
    const TableRow = RentalInventoryTableRow('checking_out');

    return (
      <div className='contentWrapper'>
        <div className='content rentalList checking_out'>
          {checkPermission('operations_view') && checkPermission('view_pricing_billing') && (
            <PrintLink className='btnLink' />
          )}
          <h1>Checking Out</h1>
          <section>
            {loading ? (
              <LoadingSpinner />
            ) : (
              this._renderTableData(inventory, user, businessLocation)
            )}
          </section>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { user } = state.auth;
  const { location } = state.location;
  const checkPermission = selectCheckPermission(state);
  return {
    user,
    businessLocation: location,
    checkPermission,
  };
};

export default withRouter(connect(mapStateToProps, actions)(PrintCheckingOut));

import { ADD_VENUE_NAMES_REQUEST, EPIC_ERROR } from "Constants/redux";
import * as actions from "Actions";
import service from "Api/service";
import Rx from "rxjs/Rx";
const { Observable } = Rx;

const AddVenueNamesEpic = action$ =>
  action$
    .ofType(ADD_VENUE_NAMES_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.get(process.env.REACT_APP_API_DOMAIN + action.link)
      )
        .map(response => {
          return actions.addVenueNamesSuccess(response);
        })
        .catch(error =>
          Observable.of({
            type: EPIC_ERROR
          })
        );
    });

export default AddVenueNamesEpic;

import { UPDATE_DELIVERY_TYPE } from 'Constants/redux';

const INITIAL_STATE = {
  deliveryType: ''
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case UPDATE_DELIVERY_TYPE:
      return {
        ...state,
        deliveryType: action.deliveryType,
      };
   default:
      return state;
  }
}

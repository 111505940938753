import React from 'react';
import classnames from 'classnames';

class Gauge extends React.Component {
  state = {
    value: 50,
  };

  componentDidMount() {
    const { value } = this.props
    this.setState({ value });
  }

  componentDidUpdate(prevProps) {
    const { value } = this.props;
    if (value !== prevProps.value) {
      this.setState({ value });
    }
  }

  handleChange = (event) => {
    const { value } = event.target;
    const { onChange } = this.props;

    this.setState({ value });
    onChange(event)
  };

  render() {
    const { name, disabled, containerClassName } = this.props;
    const { value } = this.state;

    return (
      <div
        className={classnames(`input-container input-range range${value}`, {
          [containerClassName]: !!containerClassName,
        })}
      >
        <input
          name={name}
          type='range'
          min='0'
          max='100'
          step='25'
          onChange={this.handleChange}
          disabled={disabled}
          value={value}
        />
        <div className='range-slider-bar'>
          <div
            className={classnames({
              sliderLeft: value > 0,
              sliderSelected: value == 0,
              sliderRigth: value < 0,
            })}
          >
            E
          </div>
          <div
            className={classnames({
              sliderLeft: value > 25,
              sliderSelected: value == 25,
              sliderRigth: value < 25,
            })}
          >
            1/4
          </div>
          <div
            className={classnames({
              sliderLeft: value > 50,
              sliderSelected: value == 50,
              sliderRigth: value < 50,
            })}
          >
            1/2
          </div>
          <div
            className={classnames({
              sliderLeft: value > 75,
              sliderSelected: value == 75,
              sliderRigth: value < 75,
            })}
          >
            3/4
          </div>
          <div
            className={classnames({
              sliderLeft: value > 100,
              sliderSelected: value == 100,
              sliderRigth: value < 100,
            })}
          >
            F
          </div>
        </div>
      </div>
    );
  }
}

export default Gauge;

export const calculateBillingFields = rental => {
  const total =
    parseFloat(rental.rentalItemTotal) +
    parseFloat(rental.rentalAddOnTotal) +
    parseFloat(rental.rentalBundleTotal);
  const transactionFeeTotal = Number(rental.processingFeeTotal);
  const transactionFeeRefundTotal = Number(rental.processingFeeRefunded);
  const tapgoodsFeeTotal =
    Number(rental.tapgoodsFeeTotal) + Number(rental.tgBusinessFee);
  const netEarnings = parseFloat(
    rental.overallTotal - transactionFeeTotal - tapgoodsFeeTotal
  );
  const overallTotal =
    total +
    parseFloat(rental.feeTotal) +
    parseFloat(rental.deliveryCost) +
    parseFloat(rental.tapgoodsFeeTotal);
  return {
    overallTotal,
    total,
    transactionFeeTotal,
    transactionFeeRefundTotal,
    netEarnings
  };
};
export function round(value, decimals) {
  return Number(Math.round(value + "e" + decimals) + "e-" + decimals);
}

import serializedProducts from "./serializedProducts";
import rentals from "./rentals";
import multilocation from "./multilocation";
import quickbooks from "./quickbooks";
import routing from "./routing";
import subChangeRequests from "./subChangeRequests";

export default {
  rentals,
  serializedProducts,
  multilocation,
  quickbooks,
  routing,
  subChangeRequests
};

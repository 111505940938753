import {
  FETCH_ADMIN_BUSINESSES_REQUEST,
  FETCH_ADMIN_BUSINESSES_SUCCESS,
  FETCH_ADMIN_BUSINESSES_FAILURE,
  BULK_UPDATE_ADMIN_BUSINESSES_REQUEST,
  BULK_UPDATE_ADMIN_BUSINESSES_FAILURE,
  BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS
} from "Constants/redux";

const INITIAL_STATE = {
  items: [],
  firstId: "",
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ADMIN_BUSINESSES_REQUEST:
      return { ...state, firstId: "", loading: true };
    case FETCH_ADMIN_BUSINESSES_FAILURE:
      return { ...state, loading: false };
    case FETCH_ADMIN_BUSINESSES_SUCCESS:
      const items = action.response.data.businesses;
      return {
        ...state,
        items: action.response.data.businesses,
        firstId: items.length > 0 ? items[0].id : "",
        loading: false
      };
    case BULK_UPDATE_ADMIN_BUSINESSES_REQUEST:
      return { ...state, loading: true };
    case BULK_UPDATE_ADMIN_BUSINESSES_FAILURE:
      return { ...state, loading: false };
    case BULK_UPDATE_ADMIN_BUSINESSES_SUCCESS:
      const newBusinesses = state.items.map(business => {
        let updatedBusiness = action.response.data.businesses.find(
          item => item.id === business.id
        );
        if (updatedBusiness) {
          return updatedBusiness;
        } else {
          return business;
        }
      });
      return {
        ...state,
        items: newBusinesses,
        loading: false
      };
    default:
      return state;
  }
};

import React from 'react';
import {
  SectionWrapper,
  StrongDivider,
  TopTitleContainer,
} from '../ParentOrderSummaryInvoice.styles';
import { useTheme } from 'styled-components';
import { StyledTheme } from '../../../forms/SideSection/common.types';
import { NotesInvoiceSectionProps } from './NotesInvoiceSection.types';
import { InformationText } from '../../../forms/SideSection/common.styles';
import { NoteContent, NotesContainer } from './NotesInvoiceSection.styles';
import { formatLongTimezonedDate } from '../../../../helper_functions/dates/formatDateLongTimezoned';
import { LightDivider } from '../SecondaryOrdersInvoiceTotalsTable/SecondaryOrdersInvoiceTotalsTable.styles';

const NotesInvoiceSection = ({ notes, timeZone }: NotesInvoiceSectionProps) => {
  const { blue } = useTheme() as StyledTheme;
  const filteredNotes = notes?.filter((note) => note.visibleToCustomer);

  const renderNotes = () => {
    return filteredNotes?.map((note, index) => {
      const noteDate = formatLongTimezonedDate({
        date: note.createdAt,
        timeZone: timeZone,
      });
      const isLastIndex = index === filteredNotes.length - 1;

      return (
        <NotesContainer key={note.id}>
          <InformationText color={blue}>{noteDate}</InformationText>
          <NoteContent
            dangerouslySetInnerHTML={{
              __html: note.message,
            }}
          />
          {isLastIndex ? null : <LightDivider backgroundColor={blue} />}
        </NotesContainer>
      );
    });
  };

  return (
    <SectionWrapper>
      <TopTitleContainer>
        <InformationText color={blue} bold>
          Notes
        </InformationText>
        <StrongDivider backgroundColor={blue} />
      </TopTitleContainer>
      {renderNotes()}
    </SectionWrapper>
  );
};

export default NotesInvoiceSection;

import {
  START_PROGRESS_BAR,
  UPDATE_PROGRESS_BAR,
  CLOSE_PROGRESS_BAR,
  CLOSE_PROGRESS_SUCCESS_MODAL,
  CLOSE_PROGRESS_MODAL,
  OPEN_PROGRESS_MODAL,
  OPEN_PROGRESS_SUCCESS_MODAL
} from "Constants/tapgoodsConstants";

export const startProgressBar = (progressType = "import") => {
  return {
    type: START_PROGRESS_BAR,
    progressType: progressType
  };
};
export const updateProgressBar = (complete = 0) => {
  return {
    type: UPDATE_PROGRESS_BAR,
    payload: {
      complete
    }
  };
};
export const closeProgressBar = () => {
  return {
    type: CLOSE_PROGRESS_BAR
  };
};
export const closeProgressSuccessModal = () => {
  return {
    type: CLOSE_PROGRESS_SUCCESS_MODAL
  };
};
export const closeProgressModal = () => {
  return {
    type: CLOSE_PROGRESS_MODAL
  };
};
export const openProgressSuccessModal = (importResponse = {}) => {
  return {
    type: OPEN_PROGRESS_SUCCESS_MODAL,
    importResponse
  };
};
export const openProgressModal = (importResponse = {}) => {
  return {
    type: OPEN_PROGRESS_MODAL
  };
};

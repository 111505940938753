import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import MediaBreakpoints from "Utils/MediaBreakpoints";
import ConversationsDesktopSidePane from "./ConversationsDesktopSidePane";
import { Route, Switch } from "react-router-dom";
import IndexConversations from "Components/conversations/IndexConversations";
import Conversation from "Containers/conversations/Conversation";
import NewConversation from "Components/conversations/NewConversation";

class ConversationsMiddle extends React.Component {
  componentDidMount() {
    const { fetchConversationList } = this.props;
    fetchConversationList();
  }

  renderRoutes = () => {
    const { match } = this.props;
    return (
      <Switch>
        <Route exact path={`${match.path}`} component={IndexConversations} />
        <Route exact path={`${match.path}/new`} component={NewConversation} />
        <Route
          path={`${match.path}/:conversationId`}
          component={Conversation}
        />
      </Switch>
    );
  };

  render() {
    const { conversations, unreadMessages } = this.props;
    return (
      <MediaBreakpoints
        desktop={
          <div className="contentWrapper">
            <ConversationsDesktopSidePane
              conversations={conversations}
              unreadMessages={unreadMessages}
              newConversationLink={`/conversations/new`}
            />
            <div className="content">{this.renderRoutes()}</div>
          </div>
        }
        mobile={
          <div className="contentWrapper">
            <div className="content">{this.renderRoutes()}</div>
          </div>
        }
      />
    );
  }
}

const mapStateToProps = state => {
  const { conversations, unreadMessages } = state.conversationList;
  return { conversations, unreadMessages };
};
export default connect(mapStateToProps, actions)(ConversationsMiddle);

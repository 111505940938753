import React from "react";
import { connect } from "react-redux";
import { NavLink, withRouter } from "react-router-dom";
import classnames from "classnames";
import isSelected from "HelperFunctions/ActiveStyling";
import TimeAgo from "react-timeago";

const formatter = (value, unit, suffix, date) => {
  var oneDay = 24 * 60 * 60 * 1000; // hours*minutes*seconds*milliseconds
  const firstDate = new Date(date);
  var secondDate = new Date();
  if (date) {
    var diffDays = Math.round(Math.abs((date - secondDate.getTime()) / oneDay));

    if (diffDays > 7) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        month: "short",
        day: "numeric"
      });
      return date_formatter.format(firstDate);
    } else if (diffDays > 1) {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        weekday: "short"
      });
      return date_formatter.format(firstDate);
    } else {
      const date_formatter = Intl.DateTimeFormat("en-US", {
        hour: "numeric",
        minute: "numeric"
      });
      return date_formatter.format(firstDate);
    }
  }
};

class ConversationCard extends React.Component {
  renderContactName = () => {
    const { conversation, location } = this.props;
    let contactName = "";
    if (conversation.conversationType === "b2b") {
      // If conversation is b2b,
      if (conversation.supplierLocationInfo.id === location.id) {
        contactName = conversation.tenantLocationInfo
          ? conversation.tenantLocationInfo.name
          : "";
      } else {
        contactName = conversation.supplierLocationInfo
          ? conversation.supplierLocationInfo.name
          : "";
      }
    } else if (
      conversation.conversationType === 'b2c ' ||
      conversation.conversationType === 'picklist'
    ) {
      // If conversation is b2c || picklist,
      contactName = conversation.contactNames;
    }
    return contactName;
  };

  render() {
    const { conversation, forRental, rental } = this.props;
    let first =
      conversation.messages.length > 0
        ? conversation.messages[conversation.messages.length - 1]
        : null;
    const link = forRental
      ? `/orders/rentals/${rental.token}/conversations/${conversation.id}`
      : `/conversations/${conversation.id}`;
    const msgSectionClassName = (conversation.customerName || conversation.rentalName) ? '' : 'emptyMsg'

    return (
      <li
        className={classnames({
          selected: isSelected(window.location, link, true),
          unread: conversation.unreadMessages > 0
        })}
      >
        <NavLink activeClassName='selected' to={link}>
          {conversation.conversationType === 'picklist' ? (
            <div className={msgSectionClassName}>
              <label>TapGoods</label>
              <p className='smallRentalName pickListConvMessage'>{conversation.title}</p>
            </div>
          ) : conversation.customerName ? (
            <div className={msgSectionClassName}>
              <label>{conversation.customerName}</label>
              <p className="smallRentalName">{conversation.rentalName}</p>
            </div>
          ) : (
            <div className={msgSectionClassName} >
              <label>{conversation.rentalName}</label>
            </div>
          )}
          {first && <TimeAgo date={first.createdAt} formatter={formatter} />}
        </NavLink>
      </li>
    );
  }
}

const mapStateToProps = state => {
  const { rental } = state.rental;
  const { location } = state.location;
  return { rental, location };
};

export default withRouter(connect(mapStateToProps)(ConversationCard));

import React from "react";

const BreakView = props => {
  let label = props.breakLabel;
  let className = props.breakClassName || "break";

  return <li className={className}>{label}</li>;
};

export default BreakView;

import styled from 'styled-components';

export const ContactInfoContentContainer = styled.div`
  flex: 1;
  width: 100%;
  gap: 1.5rem;
  display: flex;
  flex-direction: row;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const CompanyAndCustomerContainer = styled.div`
  gap: 3rem;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

import React from "react";
import ActionCable from "actioncable";
import {actioncableCheck} from "Actions"
import { getAuthToken, getBusinessId, getLocationId } from "Api";
import * as actions from "Actions";
import { Burst } from "Utils/SvgIcons";
import SystemUpdatePopUp from "Utils/SystemUpdatePopUp";
import service from "Api/service";
import { connect } from "react-redux";

class SystemUpdateWidget extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      requireAcknowledge: false,
      systemUpdate: null,
      open: false,
      systemUpdatePopUpOpen: false,
      showScreenModal: false,
      lockScreen: false
    };
  }

  componentDidMount() {
    const { authenticated } = this.props;
    if (authenticated) {
      this.connectCable();
    }
  }
  UNSAFE_componentWillReceiveProps(nextProps) {
    const { authenticated } = nextProps;
    if (authenticated && !this.cable) {
      this.connectCable();
    }
    if (Object.keys(nextProps.systemUpdateSettings).length > 0) {
      const {
        requireAcknowledge,
        showPinkTag,
        showScreenModal,
        lockScreen
      } = nextProps.systemUpdateSettings;
      this.setState({
        requireAcknowledge,
        systemUpdate: nextProps.systemUpdateSettings,
        systemUpdatePopUpOpen: showPinkTag,
        showScreenModal,
        lockScreen
      });
    }
  }

  connectCable = () => {
    const authToken = getAuthToken();
    this.cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${
        authToken["access-token"]
      }&client=${authToken["client"]}&uid=${
        authToken["uid"]
      }&resource_type=employee&bid=${getBusinessId()}&lid=${getLocationId()}`
    );
    this.channel = this.cable.subscriptions.create(
      { channel: "SystemUpdateChannel" },
      {
        connected: () => {},
        received: this.addSystemUpdate
      }
    );
    actioncableCheck(this.cable)
  };
  componentWillUnmount() {
    if (this.cable) {
      this.cable.disconnect();
    }
  }

  addSystemUpdate = response => {
    const {
      systemUpdateHistoryEntry: {
        requireAcknowledge,
        showPinkTag,
        showScreenModal,
        lockScreen
      }
    } = response;
    this.setState({
      requireAcknowledge,
      systemUpdate: response.systemUpdateHistoryEntry,
      systemUpdatePopUpOpen: showPinkTag,
      showScreenModal,
      lockScreen,
      open: true
    });
  };

  toggleOpen = () => {
    this.setState({
      open: !this.state.open
    });
  };

  toggleSystemUpdatePopUp = () => {
    if (!this.state.lockScreen) {
      this.setState({
        showScreenModal: !this.state.showScreenModal
      });
    }
  };

  toggleRequireAcknowledgePopUp = () => {
    this.setState({
      requireAcknowledge: !this.state.requireAcknowledge
    });
  };

  refreshSystem = () => {
    service
      .post(
        process.env.REACT_APP_API_DOMAIN +
          "/api/employees/refresh_system_update"
      )
      .then(response => {
        window.location.reload(true);
      });
  };

  render() {
    const {
      requireAcknowledge,
      systemUpdate,
      open,
      systemUpdatePopUpOpen,
      showScreenModal,
      lockScreen
    } = this.state;
    //[&& !requireAcknowledge] check is to not show update for user who will
    // Login in Future since they will get an updated version of app anyways.
    if ((!systemUpdate || !open) && !requireAcknowledge) {
      return null;
    }
    return (
      <div>
        {systemUpdatePopUpOpen && (
          <div className="systemUpdateWidget">
            <Burst />A New Version of TapGoods is Ready!
            <a onClick={this.refreshSystem}>Refresh your page.</a>
          </div>
        )}
        {showScreenModal && (
          <SystemUpdatePopUp
            refreshSystem={this.refreshSystem}
            systemUpdate={systemUpdate}
            open={showScreenModal}
            toggle={this.toggleSystemUpdatePopUp}
            hideCloseButton={lockScreen}
          />
        )}
      </div>
    );
  }
}
const mapStateToProps = state => {
  const { authenticated } = state.auth;
  const systemUpdateSettings = state.systemUpdate;
  return { authenticated, systemUpdateSettings };
};

export default connect(mapStateToProps, actions)(SystemUpdateWidget);

import {
  FETCH_ROUTING_SCHEDULE_REQUEST,
  FETCH_ROUTING_SCHEDULE_SUCCESS,
  SET_ROUTING_SCHEDULE_FILTER,
  RESET_ROUTING_FILTERS,
} from 'Constants/redux';
import service from 'Api/service';
import moment from 'moment-timezone';

export function setRoutingScheduleFilter(filter) {
  return {
    type: SET_ROUTING_SCHEDULE_FILTER,
    payload: { filter },
  };
}

export const loadRoutingSchedule = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      startDate,
      truckIds,
      employeeIds,
      transportType,
      rentalStatus,
      rentalSource
    } = getState().operation.operationsFilter;
    dispatch({
      type: FETCH_ROUTING_SCHEDULE_REQUEST,
    });
    const timeZone = moment.tz.guess();
    const startDateTimeGte = new Date(startDate.getFullYear(), startDate.getMonth(), 1); // Beginning of the month
    const startDateTimeLte = new Date(startDate.getFullYear(), startDate.getMonth() + 1, 0, 23, 59, 59); // End of the month

    service
      .get(process.env.REACT_APP_API_DOMAIN + '/api/routing_schedule_reports', {
        start_date_time_gte: startDateTimeGte,
        start_date_time_lte: startDateTimeLte,
        truck_ids: truckIds.join(',') || [],
        employee_ids: employeeIds.join(',') || [],
        transport_type: transportType,
        rental_status: rentalStatus,
        time_zone: timeZone,
        rental_source: rentalSource
      })

      .then((response) => {
        const newState = {
          dataSet: response.data.data,
        };
        dispatch({
          type: FETCH_ROUTING_SCHEDULE_SUCCESS,
          payload: newState,
        });
      });
  };
};

export function resetRoutingFilters() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_ROUTING_FILTERS,
    });
  };
}

export function setRoutingScheduleFilterAndReload(filter) {
  return (dispatch, getState) => {
    dispatch(setRoutingScheduleFilter(filter));
    dispatch(loadRoutingSchedule());
  };
}

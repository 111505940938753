import React from "react";
import { withRouter } from "react-router-dom";

class RouteChangeWrapper extends React.Component {
  componentDidUpdate(prevProps) {
    // if (this.props.location.pathname !== prevProps.location.pathname) {
    //   window.zE.setHelpCenterSuggestions({url: true})
    // }
  }

  render() {
    return this.props.children;
  }
}

export default withRouter(RouteChangeWrapper);

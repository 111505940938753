import { RESET_GEOFENCE_FILTER, MODIFY_GEOFENCE_FILTER } from "Constants/redux";

const INITIAL_STATE = {
  address: "",
  mileage: "any"
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_GEOFENCE_FILTER:
      return {
        ...INITIAL_STATE
      };
    case MODIFY_GEOFENCE_FILTER:
      return {
        ...state,
        ...action.params
      };
    default:
      return state;
  }
};

import React from 'react';
import { withRouter } from 'react-router-dom';
import GoogleMapReact from 'google-map-react';
import { MapMarker } from 'Utils/SvgIcons';
import Marker from 'Utils/Marker';
import service from 'Api/service';
import { history } from 'Components/Routes';
import { isEqual } from 'lodash';
import { connect } from 'react-redux';
import * as actions from 'Actions';

const DEFAULT_ZOOM = 1;
let DEFAULT_CENTER = { lat: 32.7544, lng: -96.822 };

class MapContainer extends React.Component {
  static defaultProps = {
    displayingMap: true,
  };

  state = {
    googleServiceLoaded: false,
    markers: [],
    gpsMarkers: [],
    points: [],
    origin: {},
    destination: {},
    directionsService: null,
    directionsDisplay: null,
    truckRoute: {},
    intervalId: 0,
  };

  UNSAFE_componentWillMount() {
    if (this.props.truckRoute.stops.length > 0) {
      this.refreshMap(this.props);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (
      nextProps.truckRoute.stops.length > 0 &&
      this.props.truckRoute.stops.length !== nextProps.truckRoute.stops.length
    ) {
      this.refreshMap(nextProps);
    } else if (
      nextProps.truckRoute.stops.length > 0 &&
      this.props.truckRoute.stops.length ===
      nextProps.truckRoute.stops.length &&
      this.props.truckRoute.stops.some((stop, index) => {
        return stop.id !== nextProps.truckRoute.stops[index].id;
      })
    ) {
      this.refreshMap(nextProps);
    } else if (
      !isEqual(
        nextProps.truckRoute.routeStart,
        this.props.truckRoute.routeStart
      ) ||
      !isEqual(nextProps.truckRoute.routeEnd, this.props.truckRoute.routeEnd)
    ) {
      this.refreshMap(nextProps);
    }
  }

  componentDidMount() {
    const { business, truckRoute } = this.props;

    if (business.gpsApiConnected && truckRoute) {
      const gpsInterval = setInterval(this._paintGpsMarkers, 5000);

      this.setState((prevState) => {
        return {
          ...prevState,
          intervalId: gpsInterval,
        };
      });
    }
  }

  componentWillUnmount() {
    if (this.state.intervalId) {
      clearInterval(this.state.intervalId);
    }
  }

  findCoordinates = (rentalTransport) => {
    const { rental } = rentalTransport;
    const type = rentalTransport.transportType;
    // the below array of two null values are used if coordinates are null in any case then gets a error
    //  wherever this method is called, as the call expects return of atleast two array values
    const obj = [null, null];
    if (!rentalTransport.deliveryCoordinates) {
      rentalTransport.deliveryCoordinates = obj;
    }
    if (!rentalTransport.pickupCoordinates) {
      rentalTransport.pickupCoordinates = obj;
    }
    if (type === 'pick_up') {
      if (rental && rental.pickupSameAsDelivery) {
        return rentalTransport.deliveryCoordinates;
      } else {
        return rentalTransport.pickupCoordinates;
      }
    } else {
      return rentalTransport.deliveryCoordinates;
    }
  };

  findAddress = (rentalTransport) => {
    const { rental } = rentalTransport;
    const type = rentalTransport.transportType;
    if (type === 'pick_up') {
      if (rental.pickupSameAsDelivery) {
        return rentalTransport.deliveryAddress;
      } else {
        return rentalTransport.pickupAddress;
      }
    } else {
      return rentalTransport.deliveryAddress;
    }
  };

  refreshMap = (props) => {
    let markers = [];
    const { truckRoute, history } = props;
    const {
      googleServiceLoaded,
      directionsService,
      directionsDisplay,
    } = this.state;
    const originMarker = (
      <Marker
        key='origin'
        type='warehouse'
        lat={truckRoute.routeStart.latitude}
        lng={truckRoute.routeStart.longitude}
        truck={truckRoute.truck}
        text={`*`}
        onClickHandler={(event) => {
          event.preventDefault();
        }}
      />
    );
    markers.push(originMarker);

    let points = [];
    let loc;
    let origin = {
      lat: truckRoute.routeStart.latitude,
      lng: truckRoute.routeStart.longitude,
      address: truckRoute.routeStart.fullStreetAddress,
    };

    let stopNum = 0;
    truckRoute.stops.forEach((stop) => {
      if (stop.stopType === 'custom' && !stop.physicalAddress) {
        return;
      }
      if (
        stop.stopType !== 'custom' &&
        !stop.rentalTransport.deliveryCoordinates
      ) {
        return;
      }
      let marker;
      stopNum++;
      if (stop.stopType === 'custom') {
        loc = {
          lat: stop.physicalAddress.latitude,
          lng: stop.physicalAddress.longitude,
        };
        const overlapped = truckRoute.stops.some((s) => {
          if (s.id !== stop.id) {
            if (s.stopType === 'custom') {
              return (
                loc.lat === s.physicalAddress.latitude &&
                loc.lng === s.physicalAddress.longitude
              );
            } else {
              return (
                loc.lat === this.findCoordinates(s.rentalTransport)[0] &&
                loc.lng === this.findCoordinates(s.rentalTransport)[1]
              );
            }
          } else {
            return false;
          }
        });
        marker = (
          <Marker
            key={stop.id}
            stopId={stop.id}
            truck={truckRoute.truck}
            rentalTransport={stop.rentalTransport}
            type='stop'
            lat={stop.physicalAddress.latitude}
            lng={stop.physicalAddress.longitude}
            text={stopNum}
            onClickHandler={(event) => {
              event.preventDefault();
            }}
            overlapped={overlapped}
          />
        );
      } else {
        loc = {
          lat: this.findCoordinates(stop.rentalTransport)[0],
          lng: this.findCoordinates(stop.rentalTransport)[1],
          address: this.findAddress(stop.rentalTransport),
        };
        const overlapped = truckRoute.stops.some((s) => {
          if (s.id !== stop.id) {
            if (s.stopType === 'custom') {
              return (
                loc.lat === s.physicalAddress.latitude &&
                loc.lng === s.physicalAddress.longitude
              );
            } else {
              return (
                loc.lat === this.findCoordinates(s.rentalTransport)[0] &&
                loc.lng === this.findCoordinates(s.rentalTransport)[1]
              );
            }
          } else {
            return false;
          }
        });
        marker = (
          <Marker
            key={stop.id}
            stopId={stop.id}
            type='route'
            truck={truckRoute.truck}
            transportType={stop.rentalTransport.transportType}
            rentalTransport={stop.rentalTransport}
            lat={this.findCoordinates(stop.rentalTransport)[0]}
            lng={this.findCoordinates(stop.rentalTransport)[1]}
            text={stopNum}
            overlapped={overlapped}
          />
        );
      }
      markers.push(marker);
      points.push(loc);
    });

    let destination = {
      lat: truckRoute.routeEnd.latitude,
      lng: truckRoute.routeEnd.longitude,
      address: truckRoute.routeStart.fullStreetAddress,
    };
    const destinationMarker = (
      <Marker
        type='warehouse'
        key={'destination'}
        lat={truckRoute.routeStart.latitude}
        lng={truckRoute.routeStart.longitude}
        truck={truckRoute.truck}
        text={`*`}
        onClickHandler={(event) => {
          event.preventDefault();
        }}
      />
    );
    markers.push(destinationMarker);

    if (googleServiceLoaded) {
      this.setState(
        {
          points: points,
          origin: origin,
          destination: destination,
          markers,
          truckRoute: truckRoute,
        },
        () => {
          this.calculateAndDisplayRoute(directionsService, directionsDisplay);
        }
      );
    } else {
      this.setState({ points, origin, destination, markers, truckRoute });
    }
    // this.points = [
    //   { lat: Number(32.7544), lng: Number(-96.8220) },
    //   { lat: Number(32.7702), lng: Number(-96.8451) }
    // ];

    // this.origin = { lat: Number(32.7413), lng: Number(-96.8481) };
    // this.destination = { lat: Number(32.7778), lng: Number(-96.8010) };
  };

  onGoogleApiLoaded = ({ map, maps }) => {
    console.info('maps api : onGoogleApiLoaded');

    this._maps = maps;
    const { points, truckRoute } = this.state;
    let directionsService = new maps.DirectionsService();
    let directionsDisplay = new maps.DirectionsRenderer({
      polylineOptions: {
        strokeColor: Object(truckRoute.truck).color,
      },
      suppressMarkers: true,
    });

    directionsDisplay.setMap(map);
    if (directionsService && directionsDisplay) {
      if (points.length > 0) {
        this.setState(
          {
            googleServiceLoaded: true,
            directionsService,
            directionsDisplay,
          },
          () => {
            this.calculateAndDisplayRoute(directionsService, directionsDisplay);
          }
        );
      } else {
        this.setState({
          googleServiceLoaded: true,
          directionsService,
          directionsDisplay,
        });
      }
    }
  };

  calculateAndDisplayRoute = (directionsService, directionsDisplay) => {
    const { points, origin, destination } = this.state;
    const { addWaypoints } = this.props;

    console.log('maps api : calculateAndDisplayRoute')

    var delayCounter = 0;

    const waypoints = points.map((point) => {
      if (point.address) {
        return { location: point.address, stopover: true };
      }
      return { location: new window.google.maps.LatLng(point), stopover: true };
    });

    addWaypoints(waypoints);

    const component = this;
    directionsService.route(
      {
        origin: origin.address,
        destination: destination.address,
        waypoints: waypoints,
        travelMode: 'DRIVING',
      },
      function (response, status) {
        let raise = false;

        if (status === 'OK') {
          directionsDisplay.setDirections(response);
          const { setDirections, displayingInfo } = component.props;
          if (displayingInfo) {
            setDirections(response.routes[0]);
          }
          const { onDirectionResponseChange } = component.props;
          if (onDirectionResponseChange) {
            onDirectionResponseChange(response);
          }
        } else if (status === 'OVER_QUERY_LIMIT') {
          console.warn('delaying query to google maps api service')

          setTimeout(function () {
            component.calculateAndDisplayRoute(directionsService, directionsDisplay);
          }, delayCounter * 1000);

          delayCounter++;
        } else {
          let msg = 'Directions request failed due to ' + status;

          if (status === 'ZERO_RESULTS') {
            msg =
              'Directions request failed due to invalid address. Please make sure the address associated with the order is valid.';
          } else {
            raise = true;
          }

          window.alert(msg);
          if (raise) {
            throw msg;
          }
        }
      }
    );
  };

  _paintGpsMarkers = () => {
    let { truck, rentalTransports } = this.props.truckRoute;

    let gpsTransports = [];
    let gpsStringIds = [];

    rentalTransports &&
      rentalTransports.forEach((rt) => {
        if (truck.gpsDevice) {
          gpsTransports.push({
            truck,
            rt,
          });
          gpsStringIds.push(truck.gpsDevice.gpsDeviceId);
        }
      });

    let gpsQueryString = gpsStringIds.join(',');
    if (gpsStringIds != '') {
      this._fetchGpsCoordinates(gpsQueryString, gpsTransports);
    }
  };

  _fetchGpsCoordinates = (deviceIds, gpsTransports) => {
    const { gpsApiConnected } = this.props.business;
    if (gpsApiConnected) {
      service
        .get(
          process.env.REACT_APP_API_DOMAIN +
          `/api/gps_devices/get_gps_locations`,
          {
            gps_device_ids: deviceIds,
          }
        )
        .then((response) => {
          this._buildMarkers(response.data.data, gpsTransports);
        });
    }
  };

  _buildMarkers = (coordinates, gpsTransports) => {
    const gpsMarkers = coordinates.map((coord) => {
      const gpsTransport = gpsTransports.find(
        (gpsTransport) => gpsTransport.truck.gpsDevice.gpsDeviceId === coord.id
      );

      return (
        <Marker
          id={gpsTransport.truck.id}
          color={
            gpsTransport.truck.color ? gpsTransport.truck.color : '#FF886D'
          }
          type={'truck'}
          transportType={gpsTransport.rt.transportType}
          key={gpsTransport.truck.id}
          truck={gpsTransport.truck}
          lat={coord.lat}
          lng={coord.lng}
          onClickHandler={() => {
            history.push(
              `/operations/routing/map/locations/${gpsTransport.rt.id}`
            );
          }}
        />
      );
    });

    let newGpsMarkers = [];

    gpsMarkers.forEach((marker) => {
      newGpsMarkers.push(marker);
    });

    this.setState({
      gpsMarkers: newGpsMarkers,
    });
  };

  render() {
    const { displayingMap } = this.props;
    const { markers, gpsMarkers } = this.state;

    const combinedMarkers = [...markers, ...gpsMarkers];
    return (
      <div className='mapContainer'>
        {displayingMap ? (
          <div className='map'>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
              options={{
                fullscreenControl: false,
                scrollwheel: false,
              }}
              defaultCenter={DEFAULT_CENTER}
              zoom={DEFAULT_ZOOM}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.onGoogleApiLoaded}
            >
              {combinedMarkers}
            </GoogleMapReact>
          </div>
        ) : (
          <div style={{ display: 'none' }}>
            <GoogleMapReact
              bootstrapURLKeys={{ key: process.env.REACT_APP_GMAP_API_KEY }}
              options={{
                fullscreenControl: false,
                scrollwheel: false,
              }}
              defaultCenter={DEFAULT_CENTER}
              zoom={DEFAULT_ZOOM}
              yesIWantToUseGoogleMapApiInternals={true}
              onGoogleApiLoaded={this.onGoogleApiLoaded}
            >
              {combinedMarkers}
            </GoogleMapReact>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;

  return { business };
};

export default connect(mapStateToProps, actions)(withRouter(MapContainer));

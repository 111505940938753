import { OPEN_COMPLIANCE_MODAL, CLOSE_COMPLIANCE_MODAL } from "Constants/redux";

export const openComplianceModal = newTermContent => {
  return {
    type: OPEN_COMPLIANCE_MODAL,
    payload: { newTermContent }
  };
};
export const closeComplianceModal = () => {
  return {
    type: CLOSE_COMPLIANCE_MODAL
  };
};

import React from 'react';
import Camera from '../images/icons/upload_icon.png';
import { dateToFormattedUTCString } from 'HelperFunctions/DateHelpers';
import {
  mergeRentalSignatures,
  getSignatureFromRental,
  signatureLabel,
} from 'HelperFunctions/RentalSignatures';

export const renderReservationSignatures = (rental, actions) => {
  const {
    toggleSignatureOpen,
    toggleBusinessSignatureOpen,
    uploadInventoryImages,
  } = actions;

  const customerSignature = getSignatureFromRental(rental, 'customerCheckOut');
  const businessSignature = getSignatureFromRental(rental, 'businessCheckOut');
  rental = mergeRentalSignatures(rental, customerSignature, businessSignature);

  return (
    <div className='signatures'>
      {rental.checkOutSignature ? (
        <div className='signaturePad'>
          <img src={rental.checkOutSignature} alt='signature' />
          {signatureLabel(
            rental,
            'customerCheckOut',
            'Customer',
            customerSignature?.updatedAt
          )}
        </div>
      ) : (
        <a className='signaturePad' onClick={toggleSignatureOpen}>
          {signatureLabel(rental, '', 'Customer', customerSignature?.updatedAt)}
        </a>
      )}
      <div className='business-signatures'>
        {rental.businessCheckOutSignature ? (
          <div className='signaturePad'>
            <img src={rental.businessCheckOutSignature} alt='signature' />
            {signatureLabel(
              rental,
              'businessCheckOut',
              'Business',
              businessSignature?.updatedAt
            )}
          </div>
        ) : (
          <a className='signaturePad' onClick={toggleBusinessSignatureOpen}>
            {signatureLabel(
              rental,
              '',
              'Business',
              businessSignature?.updatedAt
            )}
          </a>
        )}
        <div className='upload-wrapper' onClick={uploadInventoryImages}>
          <div className='upload-inner'>
            <img src={Camera} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderCheckedInSignatures = (rental, actions) => {
  const {
    toggleSignatureOpen,
    toggleBusinessSignatureOpen,
    uploadInventoryImages,
  } = actions;

  const customerSignature = getSignatureFromRental(rental, 'customerCheckIn');
  const businessSignature = getSignatureFromRental(rental, 'businessCheckIn');
  rental = mergeRentalSignatures(rental, customerSignature, businessSignature);
  return (
    <div className='signatures'>
      {rental.checkInSignature ? (
        <div className='signaturePad'>
          <img src={rental.checkInSignature} alt='signature' />
          {signatureLabel(
            rental,
            'customerCheckIn',
            'Customer',
            customerSignature?.updatedAt
          )}
        </div>
      ) : (
        <a className='signaturePad' onClick={toggleSignatureOpen}>
          {signatureLabel(rental, '', 'Customer', customerSignature?.updatedAt)}
        </a>
      )}
      <div className='business-signatures'>
        {rental.businessCheckInSignature ? (
          <div className='signaturePad'>
            <img src={rental.businessCheckInSignature} alt='signature' />
            {signatureLabel(
              rental,
              'businessCheckIn',
              'Business',
              businessSignature?.updatedAt
            )}
          </div>
        ) : (
          <a className='signaturePad' onClick={toggleBusinessSignatureOpen}>
            {signatureLabel(
              rental,
              'businessCheckIn',
              'Business',
              businessSignature?.updatedAt
            )}
          </a>
        )}
        <div className='upload-wrapper' onClick={uploadInventoryImages}>
          <div className='upload-inner'>
            <img src={Camera} />
          </div>
        </div>
      </div>
    </div>
  );
};

export const renderServiceTicketSignatures = (serviceTicket, actions) => {
  const {
    toggleSignatureOpen,
    toggleBusinessSignatureOpen,
    uploadInventoryImages,
  } = actions;
  return (
    <div className='signatures'>
      <a className='signaturePad' onClick={toggleSignatureOpen}>
        {serviceTicket.customerSignature && (
          <img src={serviceTicket.customerSignature} alt='signature' />
        )}
        <label>Customer Signature: {serviceTicket.customerSignatureName}</label>
      </a>
      <div className='business-signatures'>
        <a className='signaturePad' onClick={toggleBusinessSignatureOpen}>
          {serviceTicket.businessSignature && (
            <img src={serviceTicket.businessSignature} alt='signature' />
          )}
          <label>
            Business Signature: {serviceTicket.businessSignatureName}
          </label>
        </a>
        <div className='upload-wrapper' onClick={uploadInventoryImages}>
          <div className='upload-inner'>
            <img src={Camera} />
          </div>
        </div>
      </div>
    </div>
  );
};

const getCellErrors = (errors, columnName) => {
  return errors.find((error) => error[columnName])?.[columnName] || '';
};

const venueErrors = {
  Name: 'Name',
  Country: 'Country',
  City: 'City',
  Street: 'Street Address',
  Locale: 'State',
  Postal: 'Postal Code',
  Email: 'Email Address',
  Last: 'Last Name',
  ID: 'Venue ID',
  First: 'First Name',
};

const venueCompoundErrorName = {
  Email: 2,
  Street: 3,
  Postal: 2,
  Last: 2,
  First: 2,
};

const isColumnMatched = (columnName, requiredColumns) => {
  return requiredColumns.includes(columnName);
};

const parseErrors = (data = []) => {
  return data.map((error) => {
    let columnError = error.split(' ')[0];
    let formattedError = {};
    formattedError[venueErrors[columnError]] = error
      .split(' ')
      .slice(venueCompoundErrorName[columnError] || 1)
      .join(' ');
    return formattedError;
  });
};

const countUnmatched = (columns = [], requiredColumns = []) => {
  return columns.reduce((count, curr) => {
    if (!requiredColumns.includes(curr)) {
      count++;
    }
    return count;
  }, 0);
};

const buildVenueFromJson = (rowData = {}) => {
  const rowDataFormatted = JSON.parse(rowData.json_data);
  return {
    ...rowDataFormatted,
    errors: parseErrors(rowDataFormatted.errors),
    id: rowData.id,
  };
};

export default {
  errors: venueErrors,
  getCellErrors,
  compoundErrorName: venueCompoundErrorName,
  parseErrors,
  buildFromJson: buildVenueFromJson,
  isColumnMatched,
  countUnmatched,
};

import React, { Component } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import * as actions from 'Actions';
import ImportWizardHeader from './ImportWizardHeader';
import { GreenUploadCloud } from 'Utils/SvgIcons';
import { history } from 'Components/Routes';

class ImportWizardDone extends Component {
  state = {
    dataType: '',
  };

  componentDidMount() {
    const { dataType } = this.props.match.params;

    this.setState({ dataType });
    this.props.openSuccessSnackBar('Import completed successfully!');
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business && !prevProps.business.permissions.importWizardEnabled) {
      history.push('/unauthorized');
    }
  }

  redirectAfterDonePage = () => {
    const { dataType } = this.state;
    if (dataType === 'venue') {
      return history.push('/contacts/venues');
    } else if (dataType === 'company') {
      return history.push('/contacts/companies');
    } else if (dataType === 'customer') {
      return history.push('/contacts/customers');
    } else if (dataType === 'item') {
      return history.push('/inventory/items');
    } else if (dataType === 'purchase') {
      return history.push('/inventory/purchases');
    } else if (dataType === 'accessory') {
      return history.push('/inventory/accessories');
    }
  };

  render() {
    const { dataType } = this.state;
    const prettyNames = {
      venue: 'Venues',
      company: 'Companies',
      customer: 'Customers',
      item: 'Items',
      purchase: 'Purchases',
      accessory: 'Accessories',
    };
    return (
      <div className='contentWrapper'>
        <ImportWizardHeader
          importStatus='done'
          displayButtons={false}
          headerTitle='Done'
        />
        <div className='ImportWizardUploadContainer'>
          <div className='importWizardUpload'>
            <span className='importWizardDone'>
              <GreenUploadCloud />{' '}
              <a className='btn full' onClick={this.redirectAfterDonePage}>
                See {prettyNames[dataType]}
              </a>
            </span>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;

  return { business };
};

export default withRouter(connect(mapStateToProps, actions)(ImportWizardDone));

import {
  SET_FLOW_SHEETS_OPERATIONS_FILTER
} from 'Constants/redux';
import { cloneDeep } from '@apollo/client/utilities';
import { Venue } from '../types/graphql';
import { FlowSheetsFilterType } from '../components/FlowSheets/FlowSheets.types';
import { Employee } from '../types';

const futureDate = new Date();
futureDate.setDate(futureDate.getDate() + 2);
futureDate.setHours(23, 59, 59, 999);

const storedFilter = JSON.parse(window.localStorage.getItem('flowSheetsFilters'));

const INITIAL_STATE: FlowSheetsFilterType = {
  days: 3,
  dateAttribute: storedFilter?.dateAttribute || 'setBy',
  startDate: new Date(),
  startTime: new Date(new Date().setHours(0, 0, 0, 0)),
  endDate: futureDate,
  endTime: futureDate,
  venues: [] as Venue[],
  employees: [] as Employee[],
  pickListStatuses: [] as any[],
  page: 1,
  totalPages: 1,
  perPage: 10,
}

const FlowSheetsReducer = (state = cloneDeep(INITIAL_STATE), action) => {
  switch (action.type) {
    case SET_FLOW_SHEETS_OPERATIONS_FILTER:
      return {
        ...state,
        ...action.payload
      };

    default:
      return state;
  }
};

export default FlowSheetsReducer;

const getCellErrors = (errors, columnName) => {
  return errors.find((error) => error[columnName])?.[columnName] || '';
};

const itemErrors = {
  ID: 'Item ID',
  Name: 'Name',
  Accessory: 'Accessories',
  Purchases: 'purchases',
  Department: 'Department',
  'Turnover time': 'Turnover Time',
  'Turnover unit': 'Turnover Unit',
  Hourly: 'Hourly Price',
  Half: 'Half Day Price',
  Daily: 'Daily Price',
  Weekly: 'Weekly Price',
  Monthly: 'Monthly Price',
  Purchase: 'Wholesale Cost',
  Weight: 'Weight',
  'Length ft': 'Length (ft)',
  'Width ft': 'Width (ft)',
  'Height ft': 'Height (ft)',
  Length: 'Length (in)',
  Width: 'Width (in)',
  Height: 'Height (in)',
  Buffer: 'Buffer',
  Quantity: 'Quantity',
  Material: 'Material',
  Warehouse: 'Warehouse location',
  Colors: 'Color(s)',
};

const buildErrorName = (splittedError) => {
  let columnError = splittedError[0];
  if (
    (columnError == 'Width' ||
      columnError == 'Length' ||
      columnError == 'Height') &&
    splittedError[1] == 'ft'
  ) {
    return `${columnError} ft`;
  }
  if (columnError == 'Turnover') {
    return splittedError[1] == 'unit' ? 'Turnover unit' : 'Turnover time';
  }
  return columnError;
};

const itemCompoundErrorName = {
  TurnoverTime: 2,
  TurnoverUnit: 2,
  'Length ft': 2,
  'Width ft': 2,
  'Height ft': 2,
  Warehouse: 2,
  Wholesale: 2,
  Half: 3,
  Hourly: 2,
  Daily: 2,
  Weekly: 2,
  Monthly: 2,
  Purchase: 2,
};

const isColumnMatched = (columnName, requiredColumns) => {
  return requiredColumns.includes(columnName);
};

const parseErrors = (data = []) => {
  return data.map((error) => {
    let splittedError = error.split(' ');
    let columnError = buildErrorName(splittedError);
    let formattedError = {};
    formattedError[itemErrors[columnError]] = error
      .split(' ')
      .slice(itemCompoundErrorName[columnError] || 1)
      .join(' ');
    return formattedError;
  });
};

const countUnmatched = (columns = [], requiredColumns = []) => {
  return columns.reduce((count, curr) => {
    if (!requiredColumns.includes(curr)) {
      count++;
    }
    return count;
  }, 0);
};

const buildItemFromJson = (rowData = {}) => {
  const rowDataFormatted = JSON.parse(rowData.json_data);
  return {
    ...rowDataFormatted,
    errors: parseErrors(rowDataFormatted.errors),
    id: rowData.id,
  };
};

export default {
  errors: itemErrors,
  getCellErrors,
  compoundErrorName: itemCompoundErrorName,
  parseErrors,
  buildFromJson: buildItemFromJson,
  isColumnMatched,
  countUnmatched,
};

import { LOCATION_CHANGE } from 'react-router-redux';
import * as actionTypes from 'Constants/redux';

function dashboard(
  state = {
    errors: '',
    errorsOpen: false,
    successOpen: false,
    errorTime: null,
    autoDismiss: 10,
    multilocationAllLocations: false,
    alertMessage: '',
    alertOpen: false,
    alertTime: null,
  },
  action
) {
  switch (action.type) {
    case actionTypes.SET_ERROR_MESSAGE:
      return {
        ...state,
        errors: { [action.payload.field]: action.payload.text },
        errorsOpen: true,
      };
    case actionTypes.OPEN_SUCCESS_SNACKBAR:
      return Object.assign({}, state, {
        successOpen: action.payload.successOpen,
        successMessage: action.payload.successMessage,
        autoDismiss: parseInt(action.payload.autoDismiss),
      });
    case actionTypes.CLOSE_SUCCESS_SNACKBAR:
      return Object.assign({}, state, {
        successOpen: action.payload,
      });
    case actionTypes.SET_OPEN_VALIDATION_SNACKBAR:
      return Object.assign({}, state, {
        errorsOpen: true,
      });
    case actionTypes.SET_CLOSED_VALIDATION_SNACKBAR:
      return Object.assign({}, state, {
        errorsOpen: false,
      });
    case actionTypes.SET_ERRORS:
      return Object.assign({}, state, {
        errorsOpen: action.payload.errorsOpen,
        errorTime: new Date(),
        errors: action.payload.errors,
      });
    case actionTypes.SET_ALERT:
      return Object.assign({}, state, {
        alertOpen: action.payload.alertOpen,
        alertMessage: action.payload.alertMessage,
        alertTime: new Date(),
      });
    case LOCATION_CHANGE:
      return {
        ...state,
        errors: '',
        errorsOpen: false,
        errorTime: null,
      };
    case actionTypes.SET_MULTILOCATION_ALL_LOCATIONS:
      return {
        ...state,
        multilocationAllLocations: action.payload,
      };
    default:
      return state;
  }
}

export default dashboard;

import React from "react";
import { FormattedNumber } from "react-intl";
import CurrencyLabel from "Utils/CurrencyLabel";

const TGFormattedNumber = props => {
  const { value, ...others } = props;

  return <CurrencyLabel value={value ? value : ""} {...others} />;
};
export default TGFormattedNumber;

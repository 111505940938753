import { adminService } from 'Api/service';
import { ROOT_URL, saveError } from 'Api';
import * as actionTypes from 'Constants/redux';
import { camelToSnakeCase } from 'json-style-converter/es5';

export const fetchAdminPaymentsReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      month,
      filter: { page, locale },
    } = getState().adminReports;
    const params = Object.assign(
      {
        business_id: businessId || 'all',
        page,
        locale,
      },
      monthly && { month: month }
    );
    dispatch({ type: actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/payments`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_PAYMENTS_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminMarketplaceReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      month,
      filter: { page },
    } = getState().adminReports;
    const params = Object.assign(
      {
        business_id: businessId || 'all',
        page: page,
      },
      monthly && { month: month }
    );
    dispatch({ type: actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/marketplace`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_MARKETPLACE_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminStorefrontReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      month,
      filter: { page },
    } = getState().adminReports;
    const params = Object.assign(
      {
        business_id: businessId || 'all',
        page: page,
      },
      monthly && { month: month }
    );
    dispatch({ type: actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/storefront`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_STOREFRONT_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminSubscriptionsReport = (subscriptionStatus) => {
  return (dispatch, getState) => {
    const params = Object.assign({
      status: subscriptionStatus,
    });
    dispatch({ type: actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/subscriptions`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_SUBSCRIPTIONS_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminCustomersReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      filter: { page, locale },
    } = getState().adminReports;
    const params = {
      page,
      locale,
    };
    dispatch({ type: actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/customers`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_CUSTOMERS_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminConnectInventoryReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      filter: { page },
    } = getState().adminReports;
    const params = {
      business_id: businessId || 'all',
      page: page,
    };
    dispatch({
      type: actionTypes.FETCH_ADMIN_CONNECT_INVENTORY_REPORT_REQUEST,
    });
    adminService
      .get(`${ROOT_URL}/admin/reports/connect_inventory`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_CONNECT_INVENTORY_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_CONNECT_INVENTORY_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminConnectRentalsReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      filter: { page },
    } = getState().adminReports;
    const params = {
      business_id: businessId || 'all',
      page: page,
    };
    dispatch({ type: actionTypes.FETCH_ADMIN_CONNECT_RENTALS_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/connect_rentals`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_CONNECT_RENTALS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_CONNECT_RENTALS_REPORT_FAILURE,
        });
      });
  };
};

export const fetchAdminRentalsReport = (monthly = false) => {
  return (dispatch, getState) => {
    const {
      businessId,
      month,
      filter: { page, locale },
    } = getState().adminReports;
    const params = Object.assign(
      {
        business_id: businessId || 'all',
        page,
        locale,
      },
      monthly && { month: month }
    );
    dispatch({ type: actionTypes.FETCH_ADMIN_RENTALS_REPORT_REQUEST });
    adminService
      .get(`${ROOT_URL}/admin/reports/rentals`, params)
      .then((response) => {
        return dispatch({
          response,
          type: actionTypes.FETCH_ADMIN_RENTALS_REPORT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: actionTypes.FETCH_ADMIN_RENTALS_REPORT_FAILURE,
        });
      });
  };
};
export function selectMonth(month) {
  return {
    type: actionTypes.SELECT_MONTH,
    payload: { month },
  };
}
export function selectAdminBusiness(businessId) {
  return {
    type: actionTypes.SELECT_BUSINESS,
    payload: { businessId },
  };
}
export function setAdminReportFilter(filter) {
  return {
    type: actionTypes.SET_ADMIN_REPORT_FILTER,
    payload: filter,
  };
}

export const openDrawer = () => (dispatch, getState) => {
  const currentStatus = getState().drawer;

  dispatch({
    type: actionTypes.OPEN_DRAWER,
    payload: { drawer: !currentStatus },
  });
};

const preprocessSubmissionData = (data) => {
  const { powerbiDatasets: powerbiDatasetsAttributes = {} } = { ...data };

  return camelToSnakeCase({
    report: { ...data, ...powerbiDatasetsAttributes },
  });
};

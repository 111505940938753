import * as actionTypes from "Constants/redux";

const INITIAL_STATE = {
  loading: false,
  label: ""
};
export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case actionTypes.OPEN_LOADING_SPINNER:
      return {
        ...state,
        loading: true,
        label: action.payload.label
      };
    case actionTypes.CLOSE_LOADING_SPINNER:
      return {
        ...state,
        label: "",
        loading: false
      };
    default:
      return state;
  }
};

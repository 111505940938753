import { SET_CAN_LEAVE } from '../../constants/redux';
import { resetVenueForm, setVenue, setVenueForm } from './VenueActions';

export const resetRentalForm = () => (dispatch, getState) => {
  dispatch(resetVenueForm());
};

export const setCanLeave = (canLeave) => (dispatch, getState) => {
  dispatch({ type: SET_CAN_LEAVE, canLeave });
};

export const fillEditRentalData = (
  rental,
  // @ToDo ReusableFormRefactor: once all ReusableForm state has been moved to Redux.store.rentalForm, we won't need to pass in temporarilyNeededCOmponentState
  temporarilyNeededComponentState
) => (dispatch, getState) => {
  dispatch(setVenueForm(rental, temporarilyNeededComponentState));
};

export const fillReorderRentalData = (
  rental,
  // @ToDo ReusableFormRefactor: once all ReusableForm state has been moved to Redux.store.rentalForm, we won't need to pass in temporarilyNeededCOmponentState
  temporarilyNeededComponentState
) => (dispatch, getState) => {
  dispatch(setVenueForm(rental, temporarilyNeededComponentState, true));
};

export const fillTemplateRentalData = (
  rental,
  // @ToDo ReusableFormRefactor: once all ReusableForm state has been moved to Redux.store.rentalForm, we won't need to pass in temporarilyNeededCOmponentState
  temporarilyNeededComponentState
) => (dispatch, getState) => {
  dispatch(setVenueForm(rental, temporarilyNeededComponentState, true));
};

import React from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';
import { CheckOnCircle, StatusCircle } from 'Utils/SvgIcons';
import { isQuoteExpired } from 'HelperFunctions/rentals';
import classnames from 'classnames';

class ImportWizardActions extends React.Component {
  billingStatusLabel = () => {
    const {
      rental: { billingStatus },
    } = this.props;
    if (billingStatus === 'Full') {
      return (
        <label key='5' className='active'>
          <CheckOnCircle />
          Fully Paid
        </label>
      );
    } else if (billingStatus === 'None') {
      return (
        <label key='5'>
          <StatusCircle />
          Nothing Paid
        </label>
      );
    } else {
      return (
        <label key='5' className='partial'>
          <StatusCircle />
          Partially Paid
        </label>
      );
    }
  };

  render() {
    const {
      onNextStep,
      onCancel,
      onSaveForLater,
      importStatus,
      displayButtons,
      disableFinalizeButton,
    } = this.props;

    if (!displayButtons) {
      return <div></div>;
    }

    let btn;
    const requestNextStep = (
      <a onClick={onNextStep} className='btn importWizardNextButton'>
        Next Step
      </a>
    );

    const submitButton = (
      <a
        onClick={disableFinalizeButton ? '' : onNextStep}
        className={classnames({
          btn: true,
          importWizardNextButton: true,
          disabled: disableFinalizeButton,
        })}
      >
        Finalize CSV
      </a>
    );

    const saveForLaterButton = (
      <a
        onClick={onSaveForLater}
        className='btn outline importWizardSecondaryBtn'
      >
        Save as Draft
      </a>
    );

    const cancelButton = <a onClick={onCancel}>Cancel</a>;

    switch (importStatus) {
      case 'upload':
        btn = [requestNextStep, cancelButton];
        break;
      case 'adjust':
        btn = [submitButton, saveForLaterButton, cancelButton];
        break;
      default:
        break;
    }

    return <div className='importWizardActions'>{btn}</div>;
  }
}

const mapStateToProps = (state) => {
  const { business } = state.business;
  const { user } = state.user;

  return {
    business,
    user,
  };
};

export default connect(mapStateToProps, null)(ImportWizardActions);

import moment from 'moment';

const COMPLETE_DAYS = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];
const DAYS = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
const MONTHS = [
  'Jan.',
  'Feb.',
  'Mar.',
  'Apr.',
  'May.',
  'Jun.',
  'Jul.',
  'Aug.',
  'Sep.',
  'Oct.',
  'Nov.',
  'Dec.',
];
const COMPLETE_MONTHS = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const twoDigitMonthDayYear = (date) => {
  let dateParsed = new Date(date);

  return `${dateParsed.getMonth() +
    1}/${dateParsed.getDate()}/${dateParsed.getFullYear()}`;
};

export const dayOfWeek = (date, dayCompleted = false) => {
  let dateParsed = new Date(date);
  return dayCompleted
    ? COMPLETE_DAYS[dateParsed.getDay()]
    : DAYS[dateParsed.getDay()];
};

export const monthAndDayNumber = (date) => {
  let dateParsed = new Date(date);
  return `${COMPLETE_MONTHS[dateParsed.getMonth()]} ${dateParsed.getUTCDate()}`;
};

export const getHourAMPMFormat = (date, forceUTC = false) => {
  let dateParsed = new Date(date);
  let hours = forceUTC ? dateParsed.getUTCHours() : dateParsed.getHours();
  let minutes = forceUTC ? dateParsed.getUTCMinutes() : dateParsed.getMinutes();
  let ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12;
  minutes = (minutes || 0).toString().padStart(2, '0');
  return hours + ':' + minutes + ' ' + ampm;
};

export const shortMonthDayWithYear = (date) => {
  let dateParsed = new Date(date);

  return `${monthOfYear(date)} ${dateFormat(date)} ${dateParsed.getFullYear()}`;
};

export const monthOfYear = (date) => {
  let dateParsed = new Date(date);
  return MONTHS[dateParsed.getMonth()];
};

export const dateFormat = (date) => {
  let dateParsed = new Date(date);
  return dateParsed.getDate();
};

export const monthDayNumberFormat = (date) => {
  let dateParsed = new Date(date);
  let dayWrite = DAYS[dateParsed.getDay()];
  var day = dateParsed
    .getDate()
    .toString()
    .padStart(2, '0');
  var month = (dateParsed.getMonth() + 1).toString().padStart(2, '0');
  return `${dayWrite} ${month}/${day}`;
};

const minMaxToString = (min, max) => {
  return (
    `${min.toLocaleDateString('en-US')} - ` +
    `${max.toLocaleDateString('en-US')}`
  );
};

export const getMaxAndMin = (dates) => {
  dates = dates.map((date) => new Date(date));
  let min = new Date(Math.min(...dates));
  let max = new Date(Math.max(...dates));
  return isFinite(min) && isFinite(max) ? minMaxToString(min, max) : '-';
};

export const dateToFormattedUTCString = (date) => {
  if (!date) {
    return '';
  }
  let currentDate = new Date(date);
  let day = currentDate.getUTCDate();
  let month = currentDate.getUTCMonth() + 1;
  let year = currentDate.getUTCFullYear();

  let minutes = currentDate.getUTCMinutes();
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  let hoursAndMinutes = `${currentDate.getUTCHours()}:${minutes}:${currentDate.getUTCSeconds()}`;

  return `Submitted ${month}/${day}/${year} ${hoursAndMinutes} UTC`;
};

export const convertDateTime = (date) => {
  return moment.utc(date).format('MM/DD/YYYY');
}

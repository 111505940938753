import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import Modal from "Utils/Modal";

class VersionPopUp extends React.Component {
  render() {
    const { open, closeVersionModal } = this.props;

    return (
      <Modal
        open={open}
        toggle={closeVersionModal}
        title="New Version Available"
        actions={[
          <a className="btn full" onClick={closeVersionModal}>
            Close
          </a>
        ]}
      >
        <p>Refresh your browser to use the newest version of TapGoods.</p>
      </Modal>
    );
  }
}
const mapStateToProps = state => {
  const { open } = state.version;
  return { open };
};

export default connect(mapStateToProps, actions)(VersionPopUp);

import styled from 'styled-components';

export const BusinessContainer = styled.div`
  display: flex;
  gap: 0.6875rem;
`;

export const BusinessTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BusinessImage = styled.img`
  width: 14.5rem;
  height: 8rem;
`;

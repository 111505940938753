import React from 'react';
import { connect } from 'react-redux';
import { Bell } from '../utils/SvgIcons';
import Toggle from 'material-ui/Toggle';
import { RadioButton } from 'material-ui/RadioButton';
import withLoadingSpinner from 'Components/../hocs/withLoadingSpinner';
import * as actions from 'Actions';
import converter from 'json-style-converter/es5';
import Checkbox from 'Utils/Checkbox';
import classnames from 'classnames';
import { sendChurnZeroEvent } from '../../actions';

class Notifications extends React.Component {
  constructor(props) {
    super(props);

    const {
      shouldReceiveMessageEmails,
      shouldReceiveMessageEmailsToEmail,
      shouldReceiveMessageEmailsToTeam,
      defaultMessageEmail,
      defaultTeamMessageEmail,
      shouldReceiveLeadsEmails,
      shouldReceiveLeadsToCompanyEmail,
      shouldReceiveLeadsToCustomEmail,
      leadsCustomEmail,
      email,
      shouldReceivePickListEmails,
      shouldReceivePickListToCompanyEmail,
      shouldReceivePickListToCustomEmails,
      shouldDeliveryAddressChange,
      shouldPickListChange,
      pickListOrderStartDaysCount,
      pickListOrderStartDaysOption,
      pickListOrderStartBeforeOption,
      pickListDeliveryDaysCount,
      pickListDeliveryDaysOption,
      pickListDeliveryBeforeOption,
      pickListOffShelfDaysCount,
      pickListOffShelfDaysOption,
      pickListOffShelfBeforeOption,
      pickListCustomEmails,
    } = this.props.location;
    this.state = {
      shouldReceiveMessageEmails,
      shouldReceiveMessageEmailsToEmail,
      shouldReceiveMessageEmailsToTeam,
      defaultMessageEmail: defaultMessageEmail || email,
      defaultTeamMessageEmail,
      shouldReceiveLeadsEmails,
      shouldReceiveLeadsToCompanyEmail,
      shouldReceiveLeadsToCustomEmail,
      leadsCustomEmail,
      shouldReceivePickListEmails,
      shouldReceivePickListToCompanyEmail,
      shouldReceivePickListToCustomEmails,
      shouldDeliveryAddressChange,
      shouldPickListChange,
      pickListOrderStartDaysCount,
      pickListOrderStartDaysOption,
      pickListOrderStartBeforeOption,
      pickListDeliveryDaysCount,
      pickListDeliveryDaysOption,
      pickListDeliveryBeforeOption,
      pickListOffShelfDaysCount,
      pickListOffShelfDaysOption,
      pickListOffShelfBeforeOption,
      pickListCustomEmails,
    };
  }

  handleSubmitStorefront = () => {
    let error = false;
    const data = converter.camelToSnakeCase({
      ...this.props.location,
      ...this.state,
    });
    if (
      data.should_receive_leads_emails &&
      data.should_receive_leads_to_custom_email &&
      !this.validateEmail(data.leads_custom_email)
    ) {
      error = true;
    }
    this.onSubmit(data, error);
  };

  handleSubmitMessage = () => {
    let error = false;
    const data = converter.camelToSnakeCase({
      ...this.props.location,
      ...this.state,
    });
    if (
      data.should_receive_message_emails_to_team &&
      !this.validateEmail(data.default_team_message_email)
    ) {
      error = true;
    }
    if (
      data.should_receive_message_emails_to_email &&
      !this.validateEmail(data.default_message_email)
    ) {
      error = true;
    }
    this.onSubmit(data, error);
  };

  onSubmit = (data, error) => {
    const { setErrors } = this.props;
    if (!error) {
      sendChurnZeroEvent('Email Preferences Updated')
      this.props.updateLocation(data).then(() => {
        this.props.openSuccessSnackBar('Notification changes saved.');
      });
    } else {
      setErrors('Email is not valid.');
    }
  };

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return email && re.test(String(email).toLowerCase());
  }

  handleChange = (e) => {
    let state = {};
    if (e.target.type === 'radio') {
      if (
        e.target.name === 'shouldReceiveMessageEmailsToTeam' &&
        e.target.checked
      )
        state = {
          shouldReceiveMessageEmailsToTeam: true,
          shouldReceiveMessageEmailsToEmail: false,
        };
      else if (
        e.target.name === 'shouldReceiveMessageEmailsToEmail' &&
        e.target.checked
      )
        state = {
          shouldReceiveMessageEmailsToTeam: false,
          shouldReceiveMessageEmailsToEmail: true,
        };
      else if (
        e.target.name === 'shouldReceiveLeadsToCustomEmail' &&
        e.target.checked
      )
        state = {
          shouldReceiveLeadsToCompanyEmail: false,
          shouldReceiveLeadsToCustomEmail: true,
        };
      else if (
        e.target.name === 'shouldReceiveLeadsToCompanyEmail' &&
        e.target.checked
      )
        state = {
          shouldReceiveLeadsToCompanyEmail: true,
          shouldReceiveLeadsToCustomEmail: false,
        };
      else if (
        e.target.name === 'shouldReceivePickListToCompanyEmail' &&
        e.target.checked
      )
        state = {
          shouldReceivePickListToCompanyEmail: true,
          shouldReceivePickListToCustomEmails: false,
        };
      else if (
        e.target.name === 'shouldReceivePickListToCustomEmails' &&
        e.target.checked
      )
        state = {
          shouldReceivePickListToCustomEmails: true,
          shouldReceivePickListToCompanyEmail: false,
        };
    } else if (e.target.type === 'checkbox') {
      if (
        e.target.name === 'shouldReceiveLeadsEmails' &&
        e.target.checked &&
        !this.state.shouldReceiveLeadsToCompanyEmail &&
        !this.state.shouldReceiveLeadsToCustomEmail
      ) {
        state = {
          shouldReceiveLeadsEmails: true,
          shouldReceiveLeadsToCompanyEmail: true,
        };
        ////////////////////
      } else {
        state = { [e.target.name]: e.target.checked };
      }
    } else {
      state = { [e.target.name]: e.target.value };
    }
    this.setState(state);
  };

  render() {
    const {
      shouldReceiveMessageEmails,
      shouldReceiveMessageEmailsToEmail,
      shouldReceiveMessageEmailsToTeam,
      defaultMessageEmail,
      defaultTeamMessageEmail,
      shouldReceiveLeadsEmails,
      shouldReceiveLeadsToCompanyEmail,
      shouldReceiveLeadsToCustomEmail,
      leadsCustomEmail,
      shouldReceivePickListEmails,
      shouldReceivePickListToCompanyEmail,
      shouldReceivePickListToCustomEmails,
      shouldDeliveryAddressChange,
      shouldPickListChange,
      pickListOrderStartDaysCount,
      pickListOrderStartDaysOption,
      pickListOrderStartBeforeOption,
      pickListDeliveryDaysCount,
      pickListDeliveryDaysOption,
      pickListDeliveryBeforeOption,
      pickListOffShelfDaysCount,
      pickListOffShelfDaysOption,
      pickListOffShelfBeforeOption,
      pickListCustomEmails,
    } = this.state;
    const { email } = this.props.location;
    const styles = {
      thumbOn: {
        backgroundColor: '#278ADA',
      },
      trackOn: {
        backgroundColor: '#278ADA',
        opacity: '0.5',
      },
      thumbOff: {
        backgroundColor: '#0E2C44',
      },
      trackOff: {
        backgroundColor: '#0E2C44',
        opacity: '0.5',
      },
      bellSection: {
        backgroundColor: '#185DA1',
        width: '35%',
        textAlign: 'center',
      },
      notificationsSection: {
        float: 'right',
        width: '65%',
        marginLeft: 65,
      },
      notificationSecondSection: {
        float: 'right',
        width: '61%',
        marginLeft: 0,
      },
      flex: {
        display: 'flex',
      },
      bellStyle: {
        position: 'absolute',
        top: '30%',
        left: '40%',
        display: 'inline-block',
        userSelect: 'none',
      },
      pullRight: {
        float: 'right',
      },
      notificationThirdSection: {
        thinText: {
          fontSize: '10px',
          color: '#7194AC',
          lineHeight: '14px',
        },
        pickListChangeBtn: {
          margin: '15px 0px 8px 0px',
        },
        daysArea: {
          marginTop: '15px',

          fieldDistance: {
            width: '100px',
            display: 'inline-block',
          },
          inputField: {
            width: '50px',
          },
          gapField: {
            marginLeft: '10px',
          },
        },
      },
    };

    return (
      <div className='profileNotifications'>
        <h1>Notifications</h1>
        <div style={styles.flex}>
          <section style={styles.bellSection}>
            <Bell style={styles.bellStyle} width='80px' height='80px' />
          </section>
          <section style={styles.notificationsSection}>
            <h4>Message Email Notifications</h4>
            <Toggle
              name='shouldReceiveMessageEmails'
              toggled={shouldReceiveMessageEmails}
              label='Email me when messages are received.'
              labelPosition='right'
              thumbStyle={styles.thumbOff}
              trackStyle={styles.trackOff}
              thumbSwitchedStyle={styles.thumbOn}
              trackSwitchedStyle={styles.trackOn}
              onToggle={this.handleChange}
            />
            <div className='radioList notificationInputs'>
              <RadioButton
                className='radio'
                name='shouldReceiveMessageEmailsToEmail'
                checked={shouldReceiveMessageEmailsToEmail}
                onClick={this.handleChange}
                label='Email Notification to: Custom Email Address'
              />

              {shouldReceiveMessageEmailsToEmail && (
                <input
                  type='email'
                  onChange={this.handleChange}
                  name='defaultMessageEmail'
                  value={defaultMessageEmail}
                />
              )}

              <RadioButton
                className='radio'
                name='shouldReceiveMessageEmailsToTeam'
                checked={shouldReceiveMessageEmailsToTeam}
                onClick={this.handleChange}
                label={
                  <span>
                    Email Notification to:{' '}
                    <b>Team Members Who Are Assigned To The Order</b>
                  </span>
                }
              />
              {shouldReceiveMessageEmailsToTeam && (
                <div className='label-input'>
                  <label>If no team members are assigned, default to:</label>
                  <input
                    type='email'
                    onChange={this.handleChange}
                    name='defaultTeamMessageEmail'
                    value={defaultTeamMessageEmail}
                  />
                </div>
              )}
            </div>
            <button
              className='btn'
              style={styles.pullRight}
              onClick={this.handleSubmitMessage}
            >
              Save
            </button>
          </section>
        </div>
        <section style={styles.notificationSecondSection}>
          <h4>Storefront Email Notifications</h4>
          <Toggle
            name='shouldReceiveLeadsEmails'
            toggled={shouldReceiveLeadsEmails}
            label='Email me when Leads are received.'
            labelPosition='right'
            thumbStyle={styles.thumbOff}
            trackStyle={styles.trackOff}
            thumbSwitchedStyle={styles.thumbOn}
            trackSwitchedStyle={styles.trackOn}
            onToggle={this.handleChange}
          />
          <div className='radioList notificationInputs'>
            <RadioButton
              className='radio'
              name='shouldReceiveLeadsToCompanyEmail'
              checked={shouldReceiveLeadsToCompanyEmail}
              onClick={this.handleChange}
              label={`Email Notification to: Company Email (${email})`}
            />

            <RadioButton
              className='radio'
              name='shouldReceiveLeadsToCustomEmail'
              checked={shouldReceiveLeadsToCustomEmail}
              onClick={this.handleChange}
              label={<span>Email Notification to: Custom Email Address</span>}
            />
            {shouldReceiveLeadsToCustomEmail && (
              <input
                type='email'
                onChange={this.handleChange}
                name='leadsCustomEmail'
                value={leadsCustomEmail}
              />
            )}
          </div>
          <button
            className='btn'
            style={styles.pullRight}
            onClick={this.handleSubmitStorefront}
          >
            Save
          </button>
        </section>

        <section style={styles.notificationSecondSection}>
          <h4>Picklist Email Notification</h4>
          <Toggle
            name='shouldReceivePickListEmails'
            toggled={shouldReceivePickListEmails}
            label='Email me when Picklists have changes'
            labelPosition='right'
            thumbStyle={styles.thumbOff}
            trackStyle={styles.trackOff}
            thumbSwitchedStyle={styles.thumbOn}
            trackSwitchedStyle={styles.trackOn}
            onToggle={this.handleChange}
          />
          <div className='radioList notificationInputs'>
            <RadioButton
              className='radio'
              name='shouldReceivePickListToCompanyEmail'
              checked={shouldReceivePickListToCompanyEmail}
              onClick={this.handleChange}
              label={
                <span>
                  Email Notification to:<b> Company Email</b> ({email})
                </span>
              }
            />
            <RadioButton
              className='radio'
              name='shouldReceivePickListToCustomEmails'
              checked={shouldReceivePickListToCustomEmails}
              onClick={this.handleChange}
              label={
                <label>
                  <span> Email to: Custom Email Address</span>
                </label>
              }
            />
            {shouldReceivePickListToCustomEmails && (
              <input
                type='email'
                onChange={this.handleChange}
                name='pickListCustomEmails'
                value={pickListCustomEmails}
              />
            )}
          </div>
          <div>
            <hr></hr>
          </div>
          <div style={styles.notificationThirdSection.thinText}>
            <p>
              An email(s) will be sent if either delivery or delivery address
              changes within the setting below
            </p>
          </div>
          <div style={styles.notificationThirdSection.pickListChangeBtn}>
            <Checkbox
              checked={shouldDeliveryAddressChange}
              name='shouldDeliveryAddressChange'
              onCheck={this.handleChange}
              label='Delivery Address Change'
            />
          </div>
          <div>
            <Checkbox
              checked={shouldPickListChange}
              name='shouldPickListChange'
              onCheck={this.handleChange}
              label='Picklist Change'
            />
          </div>

          <div style={styles.notificationThirdSection.daysArea}>
            <div>
              <span
                style={styles.notificationThirdSection.daysArea.fieldDistance}
              >
                Order Start
              </span>
              <input
                type='number'
                name='pickListOrderStartDaysCount'
                value={pickListOrderStartDaysCount}
                style={styles.notificationThirdSection.daysArea.inputField}
                onChange={this.handleChange}
              />
              <select
                name='pickListOrderStartDaysOption'
                value={pickListOrderStartDaysOption}
                style={styles.notificationThirdSection.daysArea.gapField}
                onChange={this.handleChange}
              >
                <option value=''></option>

                <option value='day'>Day(s)</option>
                <option value='month'>Month(s)</option>
              </select>
              <select
                name='pickListOrderStartBeforeOption'
                value={pickListOrderStartBeforeOption}
                style={styles.notificationThirdSection.daysArea.gapField}
                onChange={this.handleChange}
              >
                <option value=''></option>

                <option value='before'>Before</option>
                <option value='after'>After</option>
              </select>
              <span style={styles.notificationThirdSection.daysArea.gapField}>
                or
              </span>
            </div>
          </div>
          <div style={styles.notificationThirdSection.daysArea}>
            <div>
              <span
                style={styles.notificationThirdSection.daysArea.fieldDistance}
              >
                Delivery
              </span>
              <input
                type='number'
                name='pickListDeliveryDaysCount'
                value={pickListDeliveryDaysCount}
                style={styles.notificationThirdSection.daysArea.inputField}
                onChange={this.handleChange}
              />
              <select
                name='pickListDeliveryDaysOption'
                value={pickListDeliveryDaysOption}
                style={styles.notificationThirdSection.daysArea.gapField}
                onChange={this.handleChange}
              >
                <option value=''></option>

                <option value='day'>Day(s)</option>
                <option value='month'>Month(s)</option>
              </select>
              <select
                name='pickListDeliveryBeforeOption'
                value={pickListDeliveryBeforeOption}
                style={styles.notificationThirdSection.daysArea.gapField}
                onChange={this.handleChange}
              >
                <option value=''></option>

                <option value='before'>Before</option>
                <option value='after'>After</option>
              </select>
              <span style={styles.notificationThirdSection.daysArea.gapField}>
                or
              </span>
            </div>
            <div style={styles.notificationThirdSection.daysArea}>
              <div>
                <span
                  style={styles.notificationThirdSection.daysArea.fieldDistance}
                >
                  Off-Shelf Time
                </span>
                <input
                  type='number'
                  name='pickListOffShelfDaysCount'
                  value={pickListOffShelfDaysCount}
                  style={styles.notificationThirdSection.daysArea.inputField}
                  onChange={this.handleChange}
                />
                <select
                  name='pickListOffShelfDaysOption'
                  value={pickListOffShelfDaysOption}
                  style={styles.notificationThirdSection.daysArea.gapField}
                  onChange={this.handleChange}
                >
                  <option value=''></option>

                  <option value='day'>Day(s)</option>
                  <option value='month'>Month(s)</option>
                </select>
                <select
                  name='pickListOffShelfBeforeOption'
                  value={pickListOffShelfBeforeOption}
                  style={styles.notificationThirdSection.daysArea.gapField}
                  onChange={this.handleChange}
                >
                  <option value=''></option>

                  <option value='before'>Before</option>
                  <option value='after'>After</option>
                </select>
              </div>
            </div>
          </div>
          <button
            className='btn'
            style={styles.pullRight}
            onClick={this.handleSubmitStorefront}
          >
            Save
          </button>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { user } = state.user;
  const { location } = state.location;
  return { location, user };
};

export default connect(
  mapStateToProps,
  actions
)(withLoadingSpinner(Notifications));

import {
  FETCH_STAFF_MEMBER_NAMES_REQUEST,
  FETCH_STAFF_MEMBER_NAMES_SUCCESS,
  ADD_STAFF_MEMBER_NAMES_REQUEST,
  ADD_STAFF_MEMBER_NAMES_SUCCESS,
  SET_STAFF_MEMBER_QUERY
} from "Constants/redux";

const INITIAL_STATE = {
  query: "",
  names: [],
  firstId: "",
  pagesRemaining: false,
  loading: false,
  infiniteLoading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_STAFF_MEMBER_NAMES_REQUEST:
    case ADD_STAFF_MEMBER_NAMES_REQUEST:
      return { ...state, firstId: "", infiniteLoading: true };
    case FETCH_STAFF_MEMBER_NAMES_SUCCESS:
      const { names, pagination, firstId } = action.payload;
      return {
        ...state,
        loading: false,
        names,
        firstId,
        pagination,
        pagesRemaining: names.length !== pagination.totalObjects
      };
    case ADD_STAFF_MEMBER_NAMES_SUCCESS:
      const newPagination = action.payload.pagination;
      const newNames = [...state.names, ...action.payload.names];
      return {
        ...state,
        infiniteLoading: false,
        names: newNames,
        pagination: newPagination,
        pagesRemaining: newNames.length !== newPagination.totalObjects
      };
    case SET_STAFF_MEMBER_QUERY:
      return {
        ...state,
        query: action.payload.query
      };
    default:
      return state;
  }
};

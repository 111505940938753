import {
  DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST,
  UPDATE_MARKETPLACE_SETTING_SUCCESS,
  UPDATE_MARKETPLACE_SETTING_FAILURE
} from "Constants/redux";
import service from "Api/service";
import Rx from "rxjs/Rx";
import { ROOT_URL } from "Api";

const marketplaceSettingEpic = action$ =>
  action$
    .ofType(DEBOUNCED_UPDATE_MARKETPLACE_SETTING_REQUEST)
    .debounceTime(500)
    .switchMap(action => {
      return Rx.Observable.fromPromise(
        service.patch(`${ROOT_URL}/marketplace_setting/`, action.data)
      )
        .map(response => {
          return {
            response: response.data,
            type: UPDATE_MARKETPLACE_SETTING_SUCCESS
          };
        })
        .catch(error => {
          console.log(error);
          return {
            error,
            type: UPDATE_MARKETPLACE_SETTING_FAILURE
          };
        });
    });

export default marketplaceSettingEpic;

import {
  getAvailabilityKey,
  getItemAvailabilityFromStore,
  getItemIdType,
} from 'HelperFunctions/formHelpers';
import { difference, each, groupBy, isEmpty, map, mapKeys, min } from 'lodash';
import moment from 'moment';

/*
 * Takes the combined inventory request body and turns into an availability request body
 * @return [Hash] {}
 */
export const availabilityRequestBodyFromCombinedInventory = (
  combinedInventoryData
) => {
  const availabilityRequestBody = combinedInventoryData.reduce((obj, item) => {
    const key = productTypeToKey[item.productType];

    if (key in obj) obj[key].push(item.id);
    else obj[key] = [item.id];

    return obj;
  }, {});

  return availabilityRequestBody;
};

// The default behavior defined by this function is to use location default dates as a fallback
// However, that sometimes is not the expected use case.
// TODO: Get clarity on date fallback
export const offAndOnShelfAt = (schedule, location) => {
  let { offShelfAt, onShelfAt } = schedule || {};

  if (onShelfAt && offShelfAt) {
    offShelfAt = new Date(offShelfAt);
    onShelfAt = new Date(onShelfAt);
  } else {
    const defaultStart = new Date(location.defaultStart);
    const defaultEnd = new Date(location.defaultEnd);
    offShelfAt = new Date();
    onShelfAt = new Date();
    offShelfAt.setHours(
      defaultStart.getHours(),
      defaultStart.getMinutes(),
      0,
      0
    );
    onShelfAt.setHours(defaultEnd.getHours(), defaultEnd.getMinutes(), 0, 0);
  }

  return {
    offShelfAt,
    onShelfAt,
  };
};

/*
  @params itemType [String] 'products' | 'accessories' | 'bundles' | 'add_ons'
  @params inventoryHolds [Hash] inventory holds from availability store
*/
export const getNumberAvailableWithMaintenance = (
  itemId,
  itemType,
  inventoryHolds
) => {
  const itemAvailability = isEmpty(inventoryHolds)
    ? null
    : inventoryHolds[itemType][itemId];

  if (!itemAvailability) return 0;

  return itemAvailability.available - (itemAvailability.maintenance || 0);
};

export const checkInventoryAvailabilityLoading = (
  itemId,
  itemType,
  inventoryHolds
) => inventoryHolds.loadingInventory[itemType]?.includes(itemId);

export const fetchItemAvailabilityIfNeeded = (
  currentItem,
  itemType,
  inventoryHolds,
  location,
  fetchInventoryAvailability,
  shouldUpdateInventoryAvailability = false
) => {
  const { offShelfAt: startDate, onShelfAt: endDate } = offAndOnShelfAt(
    currentItem?.rentalSchedule,
    location
  );

  const itemAvailability = getItemAvailabilityFromStore(
    currentItem,
    itemType,
    inventoryHolds
  );

  if (
    startDate.getTime() === inventoryHolds?.validPeriodStart?.getTime() &&
    endDate.getTime() === inventoryHolds?.validPeriodEnd?.getTime() &&
    !isEmpty(itemAvailability) &&
    !shouldUpdateInventoryAvailability
  )
    return;

  const itemKey = getAvailabilityKey(itemType);
  const idType = getItemIdType(itemType);

  fetchInventoryAvailability({
    startDate,
    endDate,
    [itemKey]: [currentItem?.[idType]],
  });
};

export const fetchCollectionAvailabilityIfNeeded = (
  options,
  fetchInventoryAvailability
) => {
  const { inventoryHolds, location, inventory, schedule } = options;

  const { offShelfAt, onShelfAt } = offAndOnShelfAt(schedule, location);
  let collection = {},
    missingItems = {};

  const groupedInventory = groupBy(inventory, 'productType');

  each(groupedInventory, (value, key) => (collection[key] = map(value, 'id')));

  // * Convert keys to a supported format using productTypeToAvailabilityKey
  // * Example: 'addons' -> 'addOns'
  collection = mapKeys(
    collection,
    (_, key) => productTypeToAvailabilityKey[key]
  );

  if (
    offShelfAt.getTime() === inventoryHolds?.validPeriodStart?.getTime() &&
    onShelfAt.getTime() === inventoryHolds?.validPeriodEnd?.getTime()
  ) {
    each(collection, (ids, key) => {
      const inventoryByType = inventoryHolds?.[key];
      const currentInventory = Object.keys(inventoryByType).map((e) =>
        parseInt(e)
      );
      const differentElements = difference(ids, currentInventory);

      if (!isEmpty(differentElements)) {
        missingItems[key] = differentElements;
      }
    });
  } else {
    missingItems = collection;
  }

  if (!isEmpty(missingItems)) {
    fetchInventoryAvailability({
      startDate: offShelfAt,
      endDate: onShelfAt,
      ...missingItems,
    });
  }
};

export const productTypeToKey = {
  items: 'products',
  accessories: 'accessories',
  addons: 'addOns',
  bundles: 'bundles',
};

export const productTypeToAvailabilityKey = {
  addons: 'addOns',
  items: 'products',
  bundles: 'bundles',
  accessories: 'accessories',
};

export const minBundleProductQuantity = (bundle, inventoryHolds) => {
  const isProductAvailabilityStored =
    bundle?.productBundleRelationships?.length &&
    !isEmpty(inventoryHolds?.products);
  const isAccessoryAvailabilityStored =
    bundle?.accessoryBundleRelationships?.length &&
    !isEmpty(inventoryHolds?.accessories);
  const isAddOnAvailabilityStored =
    bundle?.addOnBundleRelationships?.length &&
    !isEmpty(inventoryHolds?.addOns);

  return isProductAvailabilityStored ||
    isAccessoryAvailabilityStored ||
    isAddOnAvailabilityStored
    ? findMinimumProductQuantity(bundle, inventoryHolds)
    : '-';
};

export const findMinimumProductQuantity = (bundle, inventoryHolds) => {
  let currentQty = min(doableBundles(bundle, inventoryHolds)) || 0;
  if (currentQty < 0) currentQty = 0;

  return currentQty;
};

export const doableBundles = (bundle, inventoryHolds) => {
  const {
    id,
    productBundleRelationships,
    accessoryBundleRelationships,
    addOnBundleRelationships,
  } = bundle;

  const result = [];

  if (id) {
    // Calculate how many units of the bundle could be made from a single item's perspective.
    // ! This method requires inventoryHolds to be loaded with the requested inv. obj.
    const bundleProductsAvailability = productBundleRelationships.map(
      (item) => {
        const numberAvailable = getNumberAvailableWithMaintenance(
          item.productId,
          'products',
          inventoryHolds
        );

        return Math.floor(numberAvailable / item.quantity);
      }
    );

    const bundleAccessoriesAvailability = accessoryBundleRelationships.map(
      (item) => {
        const numberAvailable = getNumberAvailableWithMaintenance(
          item.accessoryId,
          'accessories',
          inventoryHolds
        );

        return Math.floor(numberAvailable / item.quantity);
      }
    );

    const bundleAddOnsAvailability = addOnBundleRelationships.map((item) => {
      const numberAvailable = getNumberAvailableWithMaintenance(
        item.addOnId,
        'addOns',
        inventoryHolds
      );

      return Math.floor(numberAvailable / item.quantity);
    });

    result.push(
      ...bundleProductsAvailability,
      ...bundleAddOnsAvailability,
      ...bundleAccessoriesAvailability
    );
  }

  return result;
};

export const availabilityKeyMapper = {
  products: 'products',
  bundles: 'bundles',
  accessories: 'accessories',
  addons: 'addOns',
};

export const groupCombinedLedgerEntries = (
  inventoryGroup,
  availabilityResultForInventoryType
) => {
  const { inventoryObjects = [] } = inventoryGroup;

  const combinedLedgerEntries = [];

  inventoryObjects.forEach((inventoryObject) => {
    const { id } = inventoryObject;

    const availabilityData = availabilityResultForInventoryType?.[Number(id)];

    if (availabilityData) {
      const { combinedLedgerEntries: entries } = availabilityData;

      if (entries) {
        combinedLedgerEntries.push(...entries);
      }
    }
  });

  return combinedLedgerEntries;
};

export const filterSelectedLocationsFromInventoryGroup = (
  locationIds,
  inventoryGroup
) => {
  const filteredInventoryGroup = { ...inventoryGroup, inventoryObjects: [] };

  const { inventoryObjects = [] } = inventoryGroup;

  inventoryObjects.forEach((inventoryObject) => {
    if (locationIds.includes(String(inventoryObject.locationId))) {
      filteredInventoryGroup.inventoryObjects.push(inventoryObject);
    }
  });

  return filteredInventoryGroup;
};

export const sortCombinedLedgerEntries = (combinedLedgerEntries) => {
  return combinedLedgerEntries.sort((a, b) => {
    if (a.removeDate < b.removeDate) return -1;
    if (a.removeDate > b.removeDate) return 1;
    return 0;
  });
};

export const parseDateFromQueryParams = (search) => {
  const queryParams = new URLSearchParams(search);
  const dateStringFromQuery = queryParams.get('selectedDate');

  const currentDate = new Date();
  const dateFromQuery = moment(dateStringFromQuery, moment.ISO_8601);
  const selectedDate = dateFromQuery.isValid()
    ? dateFromQuery.toDate()
    : currentDate;

  return selectedDate;
};

import {
  LOGIN_USER_SUCCESS,
  VALIDATE_USER_SUCCESS,
  FETCH_USER_REQUEST,
  FETCH_USER_FAILURE,
  FETCH_USER_SUCCESS,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_FAILURE,
  FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS,
  ADD_LOCATION_TO_USER,
  UPDATE_USER_PROFILE,
} from 'Constants/redux';

import converter from 'json-style-converter/es5';

const INITIAL_STATE = {
  user: {
    firstName: '',
    lastName: '',
    email: '',
    businesses: [],
    locations: [],
  },
  employeeLocationRelationship: {},
  loading: false,
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOGIN_USER_SUCCESS:
    case VALIDATE_USER_SUCCESS:
      const newPayload = converter.snakeToCamelCase(action.payload);
      return {
        ...state,
        user: newPayload,
      };
    case FETCH_USER_REQUEST:
    case FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_REQUEST:
      return { ...state, loading: true };
    case FETCH_USER_FAILURE:
    case FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_FAILURE:
      return { ...state, loading: false };
    case FETCH_USER_SUCCESS:
      return {
        ...state,
        user: action.response.data.employee,
        loading: false,
      };
    case FETCH_EMPLOYEE_LOCATION_RELATIONSHIP_SUCCESS:
      return {
        ...state,
        employeeLocationRelationship: action.employeeLocationRelationship,
        loading: false,
      };
    case ADD_LOCATION_TO_USER:
      return {
        ...state,
        user: {
          ...state.user,
          locations: [...state.user.locations, action.payload],
        },
      };

    case UPDATE_USER_PROFILE:
      return {
        ...state,
        user: {
          ...state.user,
          ...action.payload,
        },
      };
    default:
      return state;
  }
};

if (
  process.env.REACT_APP_APP_ENV === 'stage' ||
  process.env.REACT_APP_APP_ENV === 'production'
) {
  window.DD_RUM.onReady(function () {
    window.DD_RUM.init({
      clientToken: process.env.REACT_APP_DATADOG_CLIENT_TOKEN,
      applicationId: process.env.REACT_APP_DATADOG_APPLICATION_ID,
      service: 'tapgoods_front_end',
      version: process.env.REACT_APP_DATADOG_VERSION,
      env: process.env.REACT_APP_APP_ENV,
      site: 'datadoghq.com',
      sampleRate: 100,
      premiumSampleRate: 100, // if not included - default 100
      trackInteractions: true,
      allowedTracingOrigins: [
        'https://api.tapgoods.com',
        /https:\/\/.*\.tapgoods\.dev/,
      ],
    });
  });
}

import * as actionTypes from "Constants/redux";

function customer(
  state = {
    loading: false,
    shouldRefresh: true,
    customer: {
      id: "",
      creditCards: [],
      bankAccounts: []
    }
  },
  action
) {
  switch (action.type) {
    case actionTypes.REQUEST_CUSTOMER:
      return {
        ...state,
        loading: true,
        shouldRefresh: true
      };
    case actionTypes.RECEIVE_CUSTOMER:
      return {
        ...state,
        loading: false,
        shouldRefresh: true,
        customer: action.customer,
        lastUpdated: action.receivedAt
      };
    default:
      return state;
  }
}

export default customer;

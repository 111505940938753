import React from 'react';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import classnames from 'classnames';
import { ArrowUp, ArrowDown } from './SvgIcons';

class Accordion extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.toggleOpen = this.toggleOpen.bind(this);
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.forceOpen !== undefined) {
      this.setState({ open: nextProps.forceOpen });
    }
  }
  toggleOpen = (event) => {
    if (event.target.name !== 'link') {
      const { name, onToggle } = this.props;
      if (onToggle) {
        const fakeEvent = {
          target: {
            value: !this.state.open,
            name: name,
          },
        };
        onToggle(fakeEvent);
      }

      this.setState({
        open: !this.state.open,
      });
    }
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.open && prevState.open !== this.state.open) {
      const tesNode = ReactDOM.findDOMNode(this.refs.test);
      tesNode.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'start',
      });
    }
  }
  render() {
    const {
      className,
      header,
      content,
      useHeaderAsLink,
      toggleIconOpen,
      toggleIconClosed,
      customToggles,
    } = this.props;
    const { open } = this.state;
    let openIcon;
    let closeIcon;
    if (customToggles) {
      openIcon = toggleIconOpen;
      closeIcon = toggleIconClosed;
    } else {
      openIcon = <ArrowUp />;
      closeIcon = <ArrowDown />;
    }
    const headerLinkProps = useHeaderAsLink ? { onClick: this.toggleOpen } : {};

    return (
      <div className={'accordian ' + (className ? className : '')} ref='test'>
        {content && (
          <a
            onClick={this.toggleOpen}
            className={classnames({
              btnCollapse: !open,
              btnExpand: open,
            })}
          >
            {open ? closeIcon : openIcon}
          </a>
        )}
        <div className='header' {...headerLinkProps}>
          {header}
        </div>
        {open && content && <div className='content'>{content}</div>}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { rentalTransports } = state.rentalTransportList;
  return { rentalTransports };
};

export default connect(mapStateToProps)(Accordion);

import React from 'react';
import SerializedMultiplePickModal from 'Components/rentals/rental_items/SerializedMultiplePickModal';
import SerializedMultipleCheckInModal from 'Components/rentals/rental_items/SerializedMultipleCheckInModal';
import {
  renderReservationSignatures,
  renderCheckedInSignatures,
} from './renderPickListSignatures';
import SerializedUniquePickModal from 'Components/rentals/rental_items/SerializedUniquePickModal';
import SerializedUniqueCheckInModal from 'Components/rentals/rental_items/SerializedUniqueCheckInModal';

import { groupBy, uniq, concat } from 'lodash';

export const pickListManagerFeatureName = (businessId) => {
  if (![1635, 1890].includes(businessId)) {
    return 'Picklist Manager'
  } else {
    return 'Flowsheet'
  }
}

export const defineGoalState = (rentalStatus) => {
  switch (rentalStatus) {
    case 'reservation':
      return 'in_use';
    case 'in_use':
      return 'checked_in';
  }
};

export const defineGoalStateDisplayName = (rentalStatus) => {
  switch (rentalStatus) {
    case 'reservation':
      return 'picked';
    case 'in_use':
      return 'checked in';
  }
};

export const defineBulkActionDisplayName = (status, rentalStatus) => {
  switch (status) {
    case 'in_use':
      return rentalStatus === 'reservation' ? 'picked' : 'checked out';
    case 'checked_in':
      return 'checked in';
    case 'reservation':
      return 'reserved';
    default:
      if (!status) {
        console.error('rental status is undefined', status);
        return 'Invalid Status'; // @ToDO I'm not sure if returning a "even more" invalid status makes sense
      }
      return status
        .split('_')
        .join(' ')
        .toLowerCase();
  }
};

export const renderSerializedMultipleModal = (
  rentalStatus,
  serializeModalOpen,
  toggleSerializePick,
  inventoryProduct,
  item
) => {
  switch (rentalStatus) {
    case 'reservation':
      return (
        <SerializedMultiplePickModal
          open={serializeModalOpen}
          toggle={toggleSerializePick}
          product={inventoryProduct}
          item={item}
        />
      );
    case 'in_use':
      return (
        <SerializedMultipleCheckInModal
          open={serializeModalOpen}
          toggle={toggleSerializePick}
          product={inventoryProduct}
          item={item}
        />
      );
  }
};

export const renderSerializedModal = (
  rentalStatus,
  serializeModalOpen,
  toggleSerializePick,
  inventoryProduct,
  item,
  handleItemsCheckedOut,
) => {
  switch (rentalStatus) {
    case 'reservation':
      return (
        <SerializedUniquePickModal
          open={serializeModalOpen}
          toggle={toggleSerializePick}
          product={inventoryProduct}
          item={item}
          afterSubmit={handleItemsCheckedOut}
        />
      );
    case 'in_use':
      return (
        <SerializedUniqueCheckInModal
          open={serializeModalOpen}
          toggle={toggleSerializePick}
          product={inventoryProduct}
          item={item}
        />
      );
  }
};

export const renderSignatureData = (
  signatureData,
  rentalStatus,
  signatureType
) => {
  switch (rentalStatus) {
    case 'reservation':
      return signatureType === 'business'
        ? renderReservationBusinessSignatureData(signatureData)
        : renderReservationSignatureData(signatureData);
    case 'in_use':
      return signatureType === 'business'
        ? renderCheckInBusinessSignatureData(signatureData)
        : renderCheckInSignatureData(signatureData);
  }
};

export const renderSignatureInputs = (rentalStatus, rental, actions) => {
  switch (rentalStatus) {
    case 'reservation':
      return renderReservationSignatures(rental, actions);
    case 'in_use':
      return renderCheckedInSignatures(rental, actions);
  }
};

export const checkErrorMessages = (
  rentalStatus,
  item,
  pickedQuantity,
  status
) => {
  switch (rentalStatus) {
    case 'reservation':
      return checkReservationErrors(item, pickedQuantity);
    case 'in_use':
      return checkCheckedInErrors(item, pickedQuantity, status);
  }
};

export const handleItemUpdate = (
  rentalStatus,
  rentalId,
  itemId,
  pickedQuantity,
  type,
  status,
  actions
) => {
  const { pickRentalItem, checkInRentalItem } = actions;

  switch (rentalStatus) {
    case 'reservation':
      pickRentalItem(rentalId, itemId, pickedQuantity, type, status);
      return;
    case 'in_use':
      checkInRentalItem(rentalId, itemId, pickedQuantity, type, status);
      return;
  }
};

export const handleBulkItemUpdate = (
  rentalStatus,
  status,
  rental,
  rentalId,
  actions
) => {
  switch (rentalStatus) {
    case 'reservation':
      handleBulkReservationUpdate(
        status,
        rental,
        rentalId,
        actions,
        rentalStatus
      );
      return;
    case 'in_use':
      handleBulkCheckInUpdate(status, rental, rentalId, actions, rentalStatus);
      return;
  }
};

export const getQuantityType = (rentalStatus) => {
  switch (rentalStatus) {
    case 'reservation':
      return 'pickedQuantity';
    case 'in_use':
      return 'checkedInQuantity';
  }
};

export const getInitialQuantityType = (rentalStatus) => {
  switch (rentalStatus) {
    case 'reservation':
      return 'quantity';
    case 'in_use':
      return 'pickedQuantity';
  }
};

const renderReservationBusinessSignatureData = (signatureData) => {
  const {
    signature,
    signerName,
    signerTitle,
    signerCompany,
    id,
  } = signatureData;
  const rentalSignature = {
    signature,
    signer_name: signerName,
    signer_title: signerTitle,
    signer_company: signerCompany,
    id,
    signature_type: 'businessCheckOut',
  };
  return {
    rental: {
      rental_signatures_attributes: [rentalSignature],
    },
  };
};

const renderReservationSignatureData = (signatureData) => {
  const {
    signature,
    signerName,
    signerTitle,
    signerCompany,
    id,
  } = signatureData;
  const rentalSignature = {
    signature,
    signer_name: signerName,
    signer_title: signerTitle,
    signer_company: signerCompany,
    id,
    signature_type: 'customerCheckOut',
  };
  return {
    rental: {
      rental_signatures_attributes: [rentalSignature],
    },
  };
};

const renderCheckInBusinessSignatureData = (signatureData) => {
  const {
    signature,
    signerName,
    signerTitle,
    signerCompany,
    id,
  } = signatureData;
  const rentalSignature = {
    signature,
    signer_name: signerName,
    signer_title: signerTitle,
    signer_company: signerCompany,
    id,
    signature_type: 'businessCheckIn',
  };
  return {
    rental: {
      rental_signatures_attributes: [rentalSignature],
    },
  };
};

const renderCheckInSignatureData = (signatureData) => {
  const {
    signature,
    signerName,
    signerTitle,
    signerCompany,
    id,
  } = signatureData;
  const rentalSignature = {
    signature,
    signer_name: signerName,
    signer_title: signerTitle,
    signer_company: signerCompany,
    id,
    signature_type: 'customerCheckIn',
  };
  return {
    rental: {
      rental_signatures_attributes: [rentalSignature],
    },
  };
};

const checkReservationErrors = (item, pickedQuantity) => {
  if (pickedQuantity < 0 || pickedQuantity > item.quantity) {
    return {
      field: 'pickedQuantity',
      text: 'Cannot checkout more than reserved',
    };
  } else if (pickedQuantity === '') {
    return {
      field: 'pickedQuantity',
      text: 'Please put checkout quantity',
    };
  }

  return null;
};

const checkCheckedInErrors = (item, pickedQuantity, status) => {
  if (status === 'in_use') {
    return {
      field: 'pickedQuantity',
      text: 'Cannot change the checked out quantity',
    };
  }

  if (pickedQuantity > item.quantity) {
    return {
      field: 'pickedQuantity',
      text: 'Cannot check in more than reserved',
    };
  } else if (pickedQuantity > item.pickedQuantity) {
    return {
      field: 'pickedQuantity',
      text: 'Cannot check in more than checked out',
    };
  } else if (pickedQuantity < 0) {
    return {
      field: 'pickedQuantity',
      text: 'Invalid check in amount.',
    };
  } else if (pickedQuantity === '') {
    return {
      field: 'pickedQuantity',
      text: 'Please put checkout quantity',
    };
  }

  return null;
};

export const renderTextColor = (backgroundColor) => {
  const whiteTextColors = ['#F5C515', '#BFB52B', '#A4D61A', '#E5E8E9'];

  return whiteTextColors.includes(backgroundColor) ? 'black' : 'white';
};

export const checkForItemsWithChangedAmounts = (rental, rentalStatus) => {
  const selectedQuantityType = getQuantityType(rentalStatus);
  const initialQuantityType = getInitialQuantityType(rentalStatus);
  const goalState = defineGoalState(rentalStatus);

  const manipulatedItems = rental.pickListItems
    .map((item) => {
      if (
        item.status === goalState &&
        item[selectedQuantityType] < item[initialQuantityType]
      ) {
        return item.id;
      }
    })
    .filter((item) => item);
  const manipulatedAccessories = rental.pickListAccessories
    .map((item) => {
      if (
        item.status === goalState &&
        item[selectedQuantityType] < item[initialQuantityType]
      ) {
        return item.id;
      }
    })
    .filter((item) => item);
  const manipulatedAddOns = rental.pickListAddOns
    .map((item) => {
      if (
        item.status === goalState &&
        item[selectedQuantityType] < item[initialQuantityType]
      ) {
        return item.id;
      }
    })
    .filter((item) => item);

  const hasChangedValues =
    manipulatedItems.length > 0 ||
    manipulatedAccessories.length > 0 ||
    manipulatedAddOns.length > 0;

  return {
    hasChangedValues,
    items: manipulatedItems,
    accessories: manipulatedAccessories,
    addOns: manipulatedAddOns,
  };
};

const handleBulkReservationUpdate = (status, rentalOrDt, rentalId, actions) => {
  const { bulkPickRentalItems } = actions;

  const {
    rentalItemIds,
    rentalAccessoryIds,
    rentalAddOnIds,
  } = getBulkRentalDataIds(rentalOrDt);

  bulkPickRentalItems(rentalId, rentalItemIds, 'items', status);
  bulkPickRentalItems(rentalId, rentalAccessoryIds, 'accessories', status);
  bulkPickRentalItems(rentalId, rentalAddOnIds, 'addOns', status);
};

const handleBulkCheckInUpdate = (status, rentalOrDt, rentalId, actions) => {
  const { bulkCheckInRentalItems } = actions;
  const { rentalItemIds, rentalAccessoryIds } = getBulkRentalDataIds(
    rentalOrDt
  );

  bulkCheckInRentalItems(rentalId, rentalItemIds, 'items', status);
  bulkCheckInRentalItems(rentalId, rentalAccessoryIds, 'accessories', status);
};

// @ToDo use from DT instead
const getBulkRentalDataIds = (rentalOrDt, rentalStatus) => {
  const rentalItemIds = rentalOrDt.pickListItems.map((item) => item.id);
  const rentalAccessoryIds = rentalOrDt.pickListAccessories.map(
    (item) => item.id
  );
  const rentalAddOnIds = rentalOrDt.pickListAddOns.map((item) => item.id);

  return { rentalItemIds, rentalAccessoryIds, rentalAddOnIds };
};

const getSortedKeys = (
  itemsCollection,
  accessoriesCollection,
  AddOnsCollection
) => {
  return uniq(
    concat(
      Object.keys(itemsCollection),
      Object.keys(accessoriesCollection),
      Object.keys(AddOnsCollection)
    )
  ).sort();
};
const groupedByDepartments = (elements, key) => {
  return groupBy(elements, (e) => {
    return !!e[key]?.departmentNames
      ? e[key].departmentNames
      : 'No Department Assigned';
  });
};

export const productsByDepartment = (items, accessories, addOns) => {
  const departmentItems = groupedByDepartments(items, 'product');
  const departmentAccessories = groupedByDepartments(accessories, 'accessory');
  const departmentAddOns = groupedByDepartments(addOns, 'addOn');

  let departmentsKeys = getSortedKeys(
    departmentItems,
    departmentAccessories,
    departmentAddOns
  );
  departmentsKeys.push(
    departmentsKeys.splice(
      departmentsKeys.indexOf('No Department Assigned'),
      1
    )[0]
  );

  let groupedDepartments = {};

  const departmentElements = [
    departmentItems,
    departmentAccessories,
    departmentAddOns,
  ];

  departmentsKeys.forEach((departmentKey) => {
    groupedDepartments[departmentKey] = [];
    departmentElements.forEach((elements) => {
      if (elements[departmentKey]) {
        groupedDepartments[departmentKey].push(...elements[departmentKey]);
      }
    });
  });

  return { departmentsKeys, groupedDepartments };
};

const groupedByCategories = (elements, key) => {
  return groupBy(elements, (e) => {
    return !!e[key]?.sfCategories?.length
      ? uniq(e[key].sfCategories.map((category) => category?.name)).join(', ')
      : 'No Category Assigned';
  });
};

export const productsByCategory = (items, accessories, addOns) => {
  const categoryItems = groupedByCategories(items, 'product');
  const categoryAccessories = groupedByCategories(accessories, 'accessory');
  const categoryAddOns = groupedByCategories(addOns, 'addOn');

  let categoryKeys = getSortedKeys(
    categoryItems,
    categoryAccessories,
    categoryAddOns
  );
  categoryKeys.push(
    categoryKeys.splice(categoryKeys.indexOf('No Category Assigned'), 1)[0]
  );

  let groupedCategories = {};

  const categoryElements = [categoryItems, categoryAccessories, categoryAddOns];
  categoryKeys.forEach((categoryKey) => {
    groupedCategories[categoryKey] = [];
    categoryElements.forEach((elements) => {
      if (elements[categoryKey]) {
        groupedCategories[categoryKey].push(...elements[categoryKey]);
      }
    });
  });

  return { categoryKeys, groupedCategories };
};

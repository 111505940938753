import React from "react";
import { Spinner } from "Utils/SvgIcons";

const OverlaySpinner = ({ loading = true, label }) => {
  if (!loading) {
    return <div />;
  }

  return (
    <div className="overlay modalSpinner">
      <div className="spinner">
        <Spinner />
        <p>{label}</p>
      </div>
    </div>
  );
};

export default OverlaySpinner;

import styled from 'styled-components';
import { StrongDividerProps } from './ParentOrderSummaryInvoice.types';

export const RootContainer = styled.div.attrs({
  className: 'printWrapper',
})`
  display: flex;
  flex-direction: column;
  width: 80%;
  padding: 4rem 5.3125rem;
  background-color: ${({ theme }) => theme.white};
`;

export const TitleText = styled.h1`
  line-height: 22px;
  letter-spacing: -0.5px;
  font-size: min(22px, 6vw);
  color: ${({ theme }) => theme.blue};
`;

export const TopContainer = styled.div`
  gap: 7rem;
  display: flex;
  margin-top: 1rem;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: 768px) {
    flex-direction: column;
  }
`;

export const LineContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
`;

export const RightAlignedLineContainer = styled(LineContainer)`
  justify-content: flex-end;
`;

export const LightDivider = styled.div`
  height: 1px;
  width: 100%;
  background-color: ${({ theme }) => theme.meddarkgrey};
`;

export const StrongDivider = styled.div<StrongDividerProps>`
  width: 100%;
  display: flex;
  margin-top: 0.25rem;
  background-color: ${({ backgroundColor, theme }) =>
    backgroundColor ?? theme['darkblue']};
  margin-bottom: 1rem;
  height: ${({ large }) => (large ? '0.25rem' : '0.1875rem')};
`;

export const MidContainer = styled.div`
  flex: 1;
  display: flex;
  gap: 2rem;
  margin-top: 4.5rem;
  flex-direction: column;
`;

export const TopTitleContainer = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;

export const BottomContainer = styled.div`
  flex: 1;
  gap: 3rem;
  width: 80%;
  display: flex;
  margin-top: 3rem;
  flex-direction: column;
`;

export const ContactContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const DeliveryAndFlipNotesContainer = styled.div`
  flex: 0.6;
  gap: 1.875rem;
  display: flex;
  flex-direction: column;
`;

export const SectionWrapper = styled.div``;

import service from 'Api/service';
import { ROOT_URL, saveError } from 'Api';
import {
  FETCH_GL_CATEGORIES_REQUEST,
  FETCH_GL_CATEGORIES_SUCCESS,
  FETCH_GL_CATEGORIES_FAILURE,
  FETCH_GL_LINE_ITEM_REQUEST,
  FETCH_GL_LINE_ITEM_SUCCESS,
  FETCH_GL_LINE_ITEM_FAILURE,
  FETCH_GL_LINE_ITEMS_REQUEST,
  FETCH_GL_LINE_ITEMS_SUCCESS,
  FETCH_GL_LINE_ITEMS_FAILURE,
  SELECT_GL_LINE_ITEM,
  CLEAR_SELECTED_GL_LINE_ITEM,
  UPDATE_GL_LINE_ITEM_REQUEST,
  UPDATE_GL_LINE_ITEM_SUCCESS,
  UPDATE_GL_LINE_ITEM_FAILURE,
  CREATE_GL_LINE_ITEM_REQUEST,
  CREATE_GL_LINE_ITEM_SUCCESS,
  CREATE_GL_LINE_ITEM_FAILURE,
  DELETE_GL_LINE_ITEM_REQUEST,
  DELETE_GL_LINE_ITEM_SUCCESS,
  DELETE_GL_LINE_ITEM_FAILURE,
  OPEN_GENERAL_LEDGER_SETTINGS_DRAWER,
  CLOSE_GENERAL_LEDGER_SETTINGS_DRAWER,
  OPEN_GENERAL_LEDGER_SETTINGS_DIALOG,
  CLOSE_GENERAL_LEDGER_SETTINGS_DIALOG,
} from 'Constants/redux';

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

import { cloneDeep } from 'lodash';

import { setErrors } from 'Actions/dashboardActionCreators';

import converter from 'json-style-converter/es5';

// TODO: use this instead of build submission data in componentconst
// const preprocessSubmissionData = (data) => {
//   const {
//     glAccount,
//     glLineItemMappableRelationships,
//     glLineItemDepartmentRelationships,
//     excludedAddOns,
//     excludedBundles,
//     excludedProducts,
//   } = cloneDeep(data);

//   const submissionData = {
//     ...data,
//     glAccountAttributes: { ...glAccount },
//     glLineItemMappableRelationshipsAttributes: Object.values(
//       glLineItemMappableRelationships
//     ),
//     glLineItemDepartmentRelationshipsAttributes: glLineItemDepartmentRelationships.map(
//       (dpt) => ({
//         ...dpt,
//         ...(dpt.departmentId === -1 && { departmentId: null }),
//       })
//     ),
//     excludedAddOns: Array.from(excludedAddOns),
//     excludedBundles: Array.from(excludedBundles),
//     excludedProducts: Array.from(excludedProducts),
//   };

//   return submissionData;
// };

// ! Keep any structural change to API response objects here.
const preprocessReceivedData = (data) => {
  const preprocessedData = cloneDeep(data);

  data.glLineItemDepartmentRelationships.forEach((dpt) => {
    if (dpt.departmentId === null) dpt.departmentId = -1;
  });

  return preprocessedData;
};

const preprocessSubmissionData = (data) => {
  return converter.camelToSnakeCase({ glLineItem: data });
};

export function openDialog(glLineItem, pickedConflicts) {
  return (dispatch) => {
    dispatch({
      type: OPEN_GENERAL_LEDGER_SETTINGS_DIALOG,
      payload: { glLineItem, pickedConflicts },
    });
  };
}

export function closeDialog() {
  return (dispatch) => {
    dispatch({ type: CLOSE_GENERAL_LEDGER_SETTINGS_DIALOG });
  };
}

export function openSettingsDrawer(id) {
  return (dispatch) => {
    dispatch({ type: OPEN_GENERAL_LEDGER_SETTINGS_DRAWER });
  };
}

export function closeSettingsDrawer() {
  return (dispatch) => {
    dispatch({ type: CLOSE_GENERAL_LEDGER_SETTINGS_DRAWER });
  };
}

export function selectGlLineItem(id) {
  return (dispatch) => {
    dispatch({ id, type: SELECT_GL_LINE_ITEM });
  };
}

export function clearSelectedGlLineItem() {
  return (dispatch) => {
    dispatch({ type: CLEAR_SELECTED_GL_LINE_ITEM });
  };
}

export function updateGlLineItem(payload) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Updating GL Line Item...'));
    dispatch({ type: UPDATE_GL_LINE_ITEM_REQUEST });

    payload = preprocessSubmissionData(payload);

    return service
      .put(`${ROOT_URL}/gl_line_items/${payload.gl_line_item.id}`, payload)
      .then((response) => {
        dispatch({
          response: {
            glLineItem: preprocessReceivedData(response.data.glLineItem),
          },
          type: UPDATE_GL_LINE_ITEM_SUCCESS,
        });

        dispatch(closeSettingsDrawer());
        dispatch(clearSelectedGlLineItem());
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(setErrors(error.response.data));

        dispatch(openSettingsDrawer());

        dispatch({
          error,
          type: UPDATE_GL_LINE_ITEM_FAILURE,
        });

        return Promise.reject();
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
}

export function deleteGlLineItem(id) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Deleting GL Line Item...'));
    dispatch({ type: DELETE_GL_LINE_ITEM_REQUEST });

    return service
      .delete(`${ROOT_URL}/gl_line_items/${id}`)
      .then((response) => {
        dispatch({
          response: response.data,
          type: DELETE_GL_LINE_ITEM_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(setErrors(error.response.data));

        dispatch({
          error,
          type: DELETE_GL_LINE_ITEM_FAILURE,
        });

        return Promise.reject();
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
}

export function createGlLineItem(payload) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Creating GL Line Item...'));
    dispatch({ type: CREATE_GL_LINE_ITEM_REQUEST });

    payload = preprocessSubmissionData(payload);

    return service
      .post(`${ROOT_URL}/gl_line_items`, payload)
      .then((response) => {
        dispatch({
          response: {
            glLineItem: preprocessReceivedData(response.data.glLineItem),
          },
          type: CREATE_GL_LINE_ITEM_SUCCESS,
        });

        dispatch(closeSettingsDrawer());
        dispatch(clearSelectedGlLineItem());
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(setErrors(error.response.data));

        dispatch(openSettingsDrawer());

        dispatch({
          error,
          type: CREATE_GL_LINE_ITEM_FAILURE,
        });

        return Promise.reject();
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
}

export function fetchGlLineItem(id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_GL_LINE_ITEM_REQUEST });
    service
      .get(`${ROOT_URL}/gl_line_items/${id}`)
      .then((response) => {
        return dispatch({
          response: { glLineItem: preprocessReceivedData(response.data) },
          type: FETCH_GL_LINE_ITEM_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(
          setErrors(
            'A problem occurred when fetching the selected GL line item'
          )
        );

        dispatch({
          error,
          type: FETCH_GL_LINE_ITEM_FAILURE,
        });
      });
  };
}

export function fetchGlLineItems() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_GL_LINE_ITEMS_REQUEST });
    service
      .get(`${ROOT_URL}/gl_line_items`)
      .then((response) => {
        const data = {
          glLineItems: response.data?.glLineItems.map((glLineItem) =>
            preprocessReceivedData(glLineItem)
          ),
        };

        return dispatch({
          response: data,
          type: FETCH_GL_LINE_ITEMS_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(
          setErrors('A problem occurred when fetching the GL line items')
        );

        dispatch({
          error,
          type: FETCH_GL_LINE_ITEMS_FAILURE,
        });
      });
  };
}

export function fetchGlCategories() {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_GL_CATEGORIES_REQUEST });
    service
      .get(`${ROOT_URL}/gl_categories`)
      .then((response) => {
        return dispatch({
          response: response.data,
          type: FETCH_GL_CATEGORIES_SUCCESS,
        });
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(
          setErrors(
            'A problem occurred when fetching general ledger mappings. Refresh and try again.'
          )
        );

        dispatch({
          error,
          type: FETCH_GL_CATEGORIES_FAILURE,
        });
      });
  };
}

import {
  FETCH_MARKETPLACE_SETTING_REQUEST,
  FETCH_MARKETPLACE_SETTING_FAILURE,
  FETCH_MARKETPLACE_SETTING_SUCCESS,
  UPDATE_MARKETPLACE_SETTING_REQUEST,
  UPDATE_MARKETPLACE_SETTING_FAILURE,
  UPDATE_MARKETPLACE_SETTING_SUCCESS
} from "Constants/redux";

const INITIAL_STATE = {
  marketplaceSetting: {
    searchable: true,
    signatureRequired: false,
    depositType: "optional",
    hasDistanceLimit: false,
    distanceLimit: 0
  },
  loading: false,
  updating: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MARKETPLACE_SETTING_REQUEST:
      return { ...state, loading: true };
    case UPDATE_MARKETPLACE_SETTING_REQUEST:
      return { ...state, updating: true };
    case FETCH_MARKETPLACE_SETTING_FAILURE:
    case UPDATE_MARKETPLACE_SETTING_FAILURE:
      return { ...state, loading: false, updating: false };
    case FETCH_MARKETPLACE_SETTING_SUCCESS:
    case UPDATE_MARKETPLACE_SETTING_SUCCESS:
      return {
        ...state,
        marketplaceSetting: action.response.marketplaceSetting,
        loading: false,
        updating: false
      };
    default:
      return state;
  }
};

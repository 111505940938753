import { ROOT_URL, saveError } from "Api";
import service from "Api/service";
import {
  OPEN_LOADING_SPINNER,
  CLOSE_LOADING_SPINNER,
  FETCH_TRUCK_ROUTE_REQUEST,
  FETCH_TRUCK_ROUTE_FAILURE,
  FETCH_TRUCK_ROUTE_SUCCESS,
  UPDATE_TRUCK_ROUTE_REQUEST,
  ADDED_WAYPOINTS_TO_TRUCK_ROUTE,
  RESET_ERROR_ATTRIBUTE
} from "Constants/redux";
import { setErrors } from "./dashboardActionCreators";

export const fetchTruckRoute = id => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_TRUCK_ROUTE_REQUEST });
    service
      .get(`${ROOT_URL}/routing/truck_routes/${id}`)
      .then(response => {
        return dispatch({
          response: response.data,
          type: FETCH_TRUCK_ROUTE_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_TRUCK_ROUTE_FAILURE
        });
      });
  };
};

export const addWaypoints = (waypoints) => {
  return (dispatch, getState) => {
    return dispatch({
      waypoints,
      type: ADDED_WAYPOINTS_TO_TRUCK_ROUTE
    });
  }
};

export const resetErrorsTruckRoute = () => {
  return (dispatch, getState) => {
    return dispatch({
      type: RESET_ERROR_ATTRIBUTE
    });
  }
};

export const updateTruckRoute = (id, data) => {
  return (dispatch, getState) => {
    const label = 'Recalculating route...';
    dispatch({ payload: {label}, type: OPEN_LOADING_SPINNER });
    dispatch({ type: UPDATE_TRUCK_ROUTE_REQUEST });

    return service
      .patch(`${ROOT_URL}/routing/truck_routes/${id}`, data)
      .then(response => {
        dispatch({
          response: response.data,
          type: FETCH_TRUCK_ROUTE_SUCCESS
        });
        dispatch({ type: CLOSE_LOADING_SPINNER });
        return true;
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_TRUCK_ROUTE_FAILURE
        });
        // return Promise.reject(error);
        dispatch(setErrors(error.response.data));
      });
  };
};

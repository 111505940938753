import {
  FETCH_ORDER_ROUTE_LIST_REQUEST,
  FETCH_ORDER_ROUTE_LIST_SUCCESS,
  FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST,
  FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS,
  SET_ROUTING_ORDER_FILTER
} from "Constants/redux";

const INITIAL_STATE = {
  truckRoutes: [],
  filteredTruckRoutes: [],
  loading: false,
  filter: {
    deliveryDate: new Date(),
    transportType: "drop_off,pick_up",
    truckIds: [],
    employeeIds: [],
    rentalStatus: ""
  }
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ORDER_ROUTE_LIST_REQUEST:
      return { ...state, loading: true };
    case FETCH_ORDER_ROUTE_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_REQUEST:
      return { ...state, loading: true };
    case FETCH_ORDER_ROUTE_LIST_BY_DELIVERY_DATE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case SET_ROUTING_ORDER_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };
    default:
      return state;
  }
};

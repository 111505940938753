import React from 'react';
import { SubRental, Items, TempItem } from 'Utils/SvgIcons';
import Imgix from 'react-imgix';
import CurrencyLabel from 'Utils/CurrencyLabel';
import { selectCheckPermission } from '../../helper_functions/locations';
import { connect } from 'react-redux';

class PrintRentalItem extends React.Component {
  renderDimensions(product) {
    return (
      <div>
        {product.length || product.width || product.height ? (
          <div>
            <span>
              {product.lengthFt ? `${product.lengthFt}'` : ''}{' '}
              {product.length ? `${product.length}"` : ''}
            </span>
            <span>x</span>
            <span>
              {product.widthFt ? `${product.widthFt}'` : ''}{' '}
              {product.width ? `${product.width}"` : ''}
            </span>
            <span>
              {product.heightFt ? `${product.heightFt}'` : ''}{' '}
              {product.height ? `${product.height}"` : ''}
            </span>
          </div>
        ) : (
          <div>
            <span>Not specified</span>
          </div>
        )}
        {product.weight && (
          <p>
            {product.weight}
            lbs
          </p>
        )}
      </div>
    );
  }

  render() {
    const {
      item,
      index,
      type,
      tileView,
      checkPermission,
      dimensionsAllowed,
      descriptionsAllowed,
    } = this.props;

    const periodPriceLabel = checkPermission('view_pricing_billing')
      ? item.periodPrice || 0
      : '-';

    let inventoryProduct = item.product;
    if (type && type !== '') {
      inventoryProduct = item[type];
    }

    return (
      <tbody key={index}>
        {item.warehousedAmount > 0 && (
          <tr className='top'>
            {tileView && (
              <td className='image'>
                <figure>
                  {inventoryProduct && inventoryProduct.pictures[0] ? (
                    <Imgix
                      src={inventoryProduct.pictures[0].imgixUrl}
                      alt={item.name}
                      height={70}
                      width={124}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              </td>
            )}
            <td className='center'>{item.warehousedAmount}</td>
            <td>
              {item.name}
              <span className='wrap'>
                {item.itemNotes &&
                  item.itemNotes?.map((itemNote) => (
                    <p>
                      {itemNote.shouldIncludeNotesInRentals && itemNote.message}
                    </p>
                  ))}
              </span>
            </td>
            <td className='center'>{periodPriceLabel}</td>
            <td className='center'>
              {' '}
              <CurrencyLabel value={item.selectedPrice || 0} />
            </td>
            {descriptionsAllowed && <td>{item.description}</td>}
            {dimensionsAllowed && (
              <td>{this.renderDimensions(inventoryProduct)}</td>
            )}
          </tr>
        )}
        {item.nonWarehousedAmount > 0 && (
          <tr className='top'>
            {tileView && (
              <td className='image'>
                <figure>
                  {inventoryProduct && inventoryProduct.pictures[0] ? (
                    <Imgix
                      src={inventoryProduct.pictures[0].imgixUrl}
                      alt={item.name}
                      height={70}
                      width={124}
                    />
                  ) : (
                    <Items />
                  )}
                </figure>
              </td>
            )}
            <td className='center'>
              {item.type === 'RentalItemStandard' ? (
                <SubRental />
              ) : (
                <TempItem />
              )}
              ({item.nonWarehousedAmount})
            </td>
            <td>
              {item.name}
              <span className='wrap'>
                {item.itemNotes &&
                  item.itemNotes?.map((itemNote) => (
                    <p>
                      {itemNote.shouldIncludeNotesInRentals && itemNote.message}
                    </p>
                  ))}
              </span>
            </td>
            <td className='center'>{periodPriceLabel}</td>
            <td className='center'>
              {' '}
              <CurrencyLabel value={item.selectedPrice || 0} />
            </td>
            {descriptionsAllowed && <td>{item.description}</td>}
            {dimensionsAllowed && (
              <td>{this.renderDimensions(inventoryProduct)}</td>
            )}
          </tr>
        )}
      </tbody>
    );
  }
}

const mapStateToProps = (state) => {
  const checkPermission = selectCheckPermission(state);
  return { checkPermission };
};

export default connect(mapStateToProps, null)(PrintRentalItem);

import {
  FETCH_CONNECT_DEFAULT_REQUEST,
  FETCH_CONNECT_DEFAULT_FAILURE,
  FETCH_CONNECT_DEFAULT_SUCCESS,
  UPDATE_CONNECT_DEFAULT_REQUEST,
  UPDATE_CONNECT_DEFAULT_FAILURE,
  UPDATE_CONNECT_DEFAULT_SUCCESS
} from "Constants/redux";

const INITIAL_STATE = {
  connectDefault: {
    searchable: true,
    signatureRequired: false,
    depositType: "optional",
    permissionType: "none",
    locations: [],
    locationConnectDefaultRelationships: [],
    discounts: [],
    hasDistanceLimit: false,
    distanceLimit: 0
  },
  loading: false,
  updating: false
};

const convertConnectDefaultToState = connectDefault => {
  const newLocations = connectDefault.locationConnectDefaultRelationships.map(
    rel => {
      return {
        id: rel.id,
        locationId: rel.location.id,
        name: rel.location.name
      };
    }
  );

  const newDiscounts = connectDefault.discountConnectDefaultRelationships.map(
    rel => {
      return {
        id: rel.id,
        discountId: rel.discount.id,
        name: rel.discount.name,
        amount: rel.discount.amount,
        valueType: rel.discount.valueType
      };
    }
  );
  return {
    id: connectDefault.id,
    searchable: connectDefault.searchable,
    signatureRequired: connectDefault.signatureRequired,
    depositType: connectDefault.depositType,
    permissionType: connectDefault.permissionType,
    hasDistanceLimit: connectDefault.hasDistanceLimit,
    distanceLimit: connectDefault.distanceLimit,
    locations: newLocations,
    locationConnectDefaultRelationships:
      connectDefault.locationConnectDefaultRelationships,
    discounts: newDiscounts
  };
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CONNECT_DEFAULT_REQUEST:
      return { ...state, loading: true };
    case UPDATE_CONNECT_DEFAULT_REQUEST:
      return { ...state, updating: true };
    case FETCH_CONNECT_DEFAULT_FAILURE:
    case UPDATE_CONNECT_DEFAULT_FAILURE:
      return { ...state, loading: false, updating: false };
    case FETCH_CONNECT_DEFAULT_SUCCESS:
    case UPDATE_CONNECT_DEFAULT_SUCCESS:
      return {
        ...state,
        connectDefault: convertConnectDefaultToState(
          action.response.connectDefault
        ),
        loading: false,
        updating: false
      };
    default:
      return state;
  }
};

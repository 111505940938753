const getCellErrors = (errors, columnName) => {
  return errors.find((error) => error[columnName])?.[columnName] || '';
};

const purchaseErrors = {
  ID: 'Purchase ID',
  Name: 'Name',
  Product: 'Products',
  Department: 'Department',
  TurnoverTime: 'Turnover Time',
  TurnoverUnit: 'Turnover Unit',
  Pricing: 'Price',
  Weight: 'Weight',
  'Length ft': 'Length (ft)',
  'Width ft': 'Width (ft)',
  'Height ft': 'Height (ft)',
  Length: 'Length (in)',
  Width: 'Width (in)',
  Height: 'Height (in)',
  Buffer: 'Buffer',
  Quantity: 'Quantity',
  Material: 'Material',
  WarehouseLocation: 'Warehouse location',
  Purchase: 'Wholesale Cost',
  Colors: 'Color(s)',
};

const buildErrorName = (splittedError) => {
  let columnError = splittedError[0];
  if (
    (columnError == 'Width' ||
      columnError == 'Length' ||
      columnError == 'Height') &&
    splittedError[1] == 'ft'
  ) {
    return `${columnError} ft`;
  }
  return columnError;
};

const purchaseCompoundErrorName = {
  TurnoverTime: 2,
  TurnoverUnit: 2,
  'Length ft': 2,
  'Width ft': 2,
  'Height ft': 2,
  WarehouseLocation: 2,
  Purchase: 2,
};

const isColumnMatched = (columnName, requiredColumns) => {
  return requiredColumns.includes(columnName);
};

const parseErrors = (data = []) => {
  return data.map((error) => {
    let splittedError = error.split(' ');
    let columnError = buildErrorName(splittedError);
    let formattedError = {};
    formattedError[purchaseErrors[columnError]] = error
      .split(' ')
      .slice(purchaseCompoundErrorName[columnError] || 1)
      .join(' ');
    return formattedError;
  });
};

const countUnmatched = (columns = [], requiredColumns = []) => {
  return columns.reduce((count, curr) => {
    if (!requiredColumns.includes(curr)) {
      count++;
    }
    return count;
  }, 0);
};

const buildPurchaseFromJson = (rowData = {}) => {
  const rowDataFormatted = JSON.parse(rowData.json_data);
  return {
    ...rowDataFormatted,
    errors: parseErrors(rowDataFormatted.errors),
    id: rowData.id,
  };
};

export default {
  errors: purchaseErrors,
  getCellErrors,
  compoundErrorName: purchaseCompoundErrorName,
  parseErrors,
  buildFromJson: buildPurchaseFromJson,
  isColumnMatched,
  countUnmatched,
};

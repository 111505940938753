import * as actionTypes from "Constants/redux";
import { saveError } from "Api";
import { adminService } from "Api/service";
import { ROOT_URL } from "Api"

export const openCircleOfDeathErrorModal = () => ({
  type: actionTypes.OPEN_CIRCLE_OF_DEATH_ERROR_MODAL,
})

export const setLoading = () => ({
  type: actionTypes.RESET_RENTAL_LOADING
})

export const resetRentalSuccess = () => ({
  type: actionTypes.RESET_RENTAL_SUCCESS
})

export const resetRentalScheduleAndSettings = () => {
  return (dispatch, getState) => {
    dispatch(setLoading());
    const { id } = getState().rental.rental;
    adminService
      .post(`${ROOT_URL}/admin/rentals/${id}/recreate_rental_setting_and_schedule`)
      .then(response => {
        dispatch(resetRentalSuccess());
      })
      .catch(error => {
        console.log(error);
        saveError(error);
      })
  }
}
import service from 'Api/service';

import { history } from 'Components/Routes';
import { ROOT_URL, saveError } from 'Api';
import {
  DEBOUNCED_UPDATE_PRODUCT,
  FETCH_INVENTORY_REQUEST,
  SEARCH_INVENTORY,
  FETCH_INVENTORY_SUCCESS,
  FETCH_INVENTORY_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_MAINTENANCE_REQUEST,
  ADD_MAINTENANCE_SUCCESS,
  ADD_MAINTENANCE_FAILURE,
  DELETE_PRODUCT_REQUEST,
  DELETE_PRODUCT_SUCCESS,
  DELETE_PRODUCT_FAILURE,
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_ACCESSORY_LIST_REQUEST,
  FETCH_ACCESSORY_LIST_SUCCESS,
  FETCH_ADD_ON_LIST_REQUEST,
  FETCH_ADD_ON_LIST_SUCCESS,
  FETCH_BUNDLE_LIST_REQUEST,
  FETCH_BUNDLE_LIST_SUCCESS,
  PAGINATE_REQUEST,
  PAGINATE_SUCCESS,
  PAGINATE_FAILURE,
  RELOAD_INVENTORY_REQUEST,
  INFINITE_REQUEST,
  INFINITE_FAILURE,
  INFINITE_SUCCESS,
  SET_INVENTORY_TYPE,
  SET_FILTER,
  SET_FOR_CONNECT,
  SET_SHOW_ALL,
  SHOW_TILES,
  HIDE_TILES,
  TOGGLE_DETAILS,
  SHOW_ITEM_NOTES,
  HIDE_ITEM_NOTES,
  TOGGLE_BAR_CODES,
  RESET_INVENTORY,
  RESET_INVENTORY_TO_DEFAULT,
  SET_NUMBER_PER,
  SET_INVENTORY_FILTER,
  UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS,
} from 'Constants/redux';
import {
  apiConnections,
  apiToSingularConnections,
  apiToSingularRoot,
  productTypeToUrl,
} from 'Constants/displayConstants';

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

const PAGINATE_ROOT_URL = process.env.REACT_APP_API_DOMAIN;

export const setQuery = (query) => {
  return {
    type: SET_INVENTORY_FILTER,
    payload: { query },
  };
};
const resetInventory = () => {
  return {
    type: RESET_INVENTORY,
  };
};
export function fetchProducts(productType) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCTS_REQUEST });
    service
      .get(`${ROOT_URL}/${productType}`)
      .then((response) => {
        return dispatch({
          productType,
          response,
          type: FETCH_PRODUCTS_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType,
          error,
          type: FETCH_PRODUCTS_FAILURE,
        });
      });
  };
}

export function fetchProduct(productType, id) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_PRODUCT_REQUEST });
    dispatch(openLoadingSpinner('Updating...'));
    service
      .get(`${ROOT_URL}/${apiConnections[productType]}/${id}`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        return dispatch({
          productType,
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS,
        });
      })
      .catch((error) => {
        dispatch(closeLoadingSpinner());
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType,
          id,
          error,
          type: FETCH_PRODUCT_FAILURE,
        });
      });
  };
}

export function setProductFromResponse(productType, id, response) {
  return {
    productType,
    id,
    response,
    type: FETCH_PRODUCT_SUCCESS,
  };
}

export function addMaintenance(productType, id, maintenanceRecord) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_MAINTENANCE_REQUEST });
    service
      .patch(`${ROOT_URL}/${apiConnections[productType]}/${id}`, {
        [productType]: {
          maintenance_records_attributes: {
            0: {
              quantity: maintenanceRecord.quantity,
              lack_notes: maintenanceRecord.lackNotes,
            },
          },
        },
      })
      .then((response) => {
        return dispatch({
          productType,
          id,
          response,
          type: ADD_MAINTENANCE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType,
          id,
          error,
          type: ADD_MAINTENANCE_FAILURE,
        });
      });
  };
}

export function updateProduct(productType, id, data) {
  return (dispatch, getState) => {
    return service
      .patch(`${ROOT_URL}/${apiConnections[productType]}/${id}`, {
        [apiToSingularRoot[productType]]: data,
      })
      .then((response) => {
        return dispatch({
          productType: apiToSingularConnections[productType],
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS,
        });
      });
  };
}

export function updateServices(productType, id, data) {
  return (dispatch, getState) => {
    return service
      .post(`${ROOT_URL}/${apiConnections[productType]}/${id}/services`, {
        inventory_staff_roles: data,
      })
      .then((response) => {
        return dispatch({
          productType: apiToSingularConnections[productType],
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS,
        });
      });
  };
}

export function removeService(productType, id, data) {
  return (dispatch, getState) => {
    return service
      .delete(
        `${ROOT_URL}/${apiConnections[productType]}/${id}/services/${data.serviceId}`
      )
      .then((response) => {
        return dispatch({
          productType: apiToSingularConnections[productType],
          id,
          response,
          type: FETCH_PRODUCT_SUCCESS,
        });
      });
  };
}

export function debouncedUpdateProduct(productType, id, data) {
  return (dispatch, getState) => {
    dispatch({
      type: DEBOUNCED_UPDATE_PRODUCT,
      id: id,
      data: data,
      productType: productType,
    });
  };
}

const standardReloadInventoryCall = (
  inventoryType,
  sort,
  numberPer,
  selectedCategories,
  selectedSubCategories,
  selectedSfCategories,
  selectedSfSubCategories,
  selectedSerialized,
  allowed,
  query,
  query_type,
  page,
  forConnect,
  locationFilter,
  disableLoading,
  filter,
  status,
  sortBy
) => {
  const options = Object.assign(
    {
      categories: selectedCategories,
      sub_categories: selectedSubCategories,
      sf_categories: selectedSfCategories,
      sf_sub_categories: selectedSfSubCategories,
      serialization_status: selectedSerialized,
      allowed: allowed,
      sort,
      sort_by: sortBy,
      query,
      query_type,
      page,
      per: numberPer,
      status,
    },
    forConnect && {
      locations: locationFilter,
      filter: filter,
      for_connect: true,
    }
  );
  return {
    type: RELOAD_INVENTORY_REQUEST,
    options,
    inventoryType,
    disableLoading,
  };
};

const reloadInventoryCall = (products, argums) => {
  return (dispatch, getState) => {
    const locationFilter = getState().locationList.filter;
    const data = Object.assign(products, argums);
    const {
      inventoryType,
      inventoryFilter: {
        sort,
        numberPer,
        query,
        query_type,
        page,
        selectedCategories,
        selectedSubCategories,
        selectedSfCategories,
        selectedSfSubCategories,
        selectedSerialized,
        allowed,
        status,
        sortBy,
      },
      filter,
      infinite,
      forConnect,
      disableLoading,
    } = data;
    if (infinite) {
      const options = Object.assign({
        filter,
        sort,
        query,
        query_type,
      });
      const link = '/api/' + apiConnections[inventoryType] + '/paginated';
      dispatch(infiniteLoadInventoryCall(options, link, inventoryType, true));
    } else {
      dispatch(
        standardReloadInventoryCall(
          inventoryType,
          sort,
          numberPer,
          selectedCategories,
          selectedSubCategories,
          selectedSfCategories,
          selectedSfSubCategories,
          selectedSerialized,
          allowed,
          query,
          query_type,
          page,
          forConnect,
          locationFilter,
          disableLoading,
          filter,
          status,
          sortBy
        )
      );
    }
  };
};

export function updateMaintenance(id, data) {
  return (dispatch, getState) => {
    dispatch({ type: ADD_MAINTENANCE_REQUEST });
    service
      .patch(`${ROOT_URL}/maintenance_records/${id}`, data)
      .then((response) => {
        const products = getState().products;
        const { inventoryType, sort, filter, showAll, query } = products;
        const argums = { inventoryType, showAll, filter, sort, query };
        dispatch(reloadInventoryCall(products, argums));

        dispatch({
          response: response.data,
          type: UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
      });
  };
}

export function deleteMaintenance(id, onSuccess, onFailure) {
  return (dispatch, getState) => {
    service
      .delete(`${ROOT_URL}/maintenance_records/${id}`)
      .then((response) => {
        const products = getState().products;
        const { inventoryType, sort, filter, showAll, query } = products;
        const argums = {
          inventoryType,
          showAll,
          filter,
          sort,
          query,
          disableLoading: true,
        };
        dispatch(reloadInventoryCall(products, argums));
        onSuccess();
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        onFailure();
      });
  };
}

export function fetchInventory(options, productType) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_INVENTORY_REQUEST });
    service
      .post(`${ROOT_URL}/${apiConnections[productType]}/paginated`, options)
      .then((response) => {
        return dispatch({
          productType,
          options,
          response,
          type: FETCH_INVENTORY_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType,
          options,
          error,
          type: FETCH_INVENTORY_FAILURE,
        });
      });
  };
}

export function deleteProduct(productId, productType) {
  return (dispatch, getState) => {
    dispatch({ type: DELETE_PRODUCT_REQUEST });
    service
      .delete(`${ROOT_URL}/${apiConnections[productType]}/${productId}`)
      .then((response) => {
        dispatch(closeLoadingSpinner());
        history.push('/inventory/' + productTypeToUrl[productType]);
        return dispatch({
          productId,
          response,
          type: DELETE_PRODUCT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productId,
          error,
          type: DELETE_PRODUCT_FAILURE,
        });
      });
  };
}

export function fetchProductList(options) {
  return {
    type: FETCH_PRODUCT_LIST_REQUEST,
    options,
  };
}

export const fetchAddOnList = (options) => {
  return {
    type: FETCH_ADD_ON_LIST_REQUEST,
    options,
  };
};

export const fetchAccessoryList = (options) => {
  return {
    type: FETCH_ACCESSORY_LIST_REQUEST,
    options,
  };
};

export const fetchBundleList = (options) => {
  return {
    type: FETCH_BUNDLE_LIST_REQUEST,
    options,
  };
};

export function receiveProductList(inventory) {
  return {
    type: FETCH_PRODUCT_LIST_SUCCESS,
    inventory,
  };
}
export const receiveAccessoryList = (inventory) => {
  return {
    type: FETCH_ACCESSORY_LIST_SUCCESS,
    inventory,
  };
};
export const receiveAddOnList = (inventory) => {
  return {
    type: FETCH_ADD_ON_LIST_SUCCESS,
    inventory,
  };
};
export function receiveBundleList(inventory) {
  return {
    type: FETCH_BUNDLE_LIST_SUCCESS,
    inventory,
  };
}

export function paginate(options, link, productType, page) {
  return (dispatch, getState) => {
    dispatch({ type: PAGINATE_REQUEST });
    service
      .post(`${PAGINATE_ROOT_URL}${link}`, options)
      .then((response) => {
        return dispatch({
          options,
          link,
          productType,
          page,
          response,
          type: PAGINATE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          options,
          link,
          productType,
          page,
          error,
          type: PAGINATE_FAILURE,
        });
      });
  };
}

export function showTiles() {
  localStorage.setItem('tileView', 'true');
  return {
    type: SHOW_TILES,
  };
}

export function hideTiles() {
  localStorage.setItem('tileView', 'false');
  return {
    type: HIDE_TILES,
  };
}

export function toggleDetails() {
  return {
    type: TOGGLE_DETAILS,
  };
}

export function showItemNotes() {
  localStorage.setItem('itemNotesView', 'true');
  return {
    type: SHOW_ITEM_NOTES,
  };
}

export function hideItemNotes() {
  localStorage.setItem('itemNotesView', 'false');
  return {
    type: HIDE_ITEM_NOTES,
  };
}

export function toggleBarcodes() {
  return {
    type: TOGGLE_BAR_CODES,
  };
}

export const setFilter = (filter) => {
  return {
    type: SET_FILTER,
    payload: filter,
  };
};

export const setForConnect = (forConnect) => {
  return {
    type: SET_FOR_CONNECT,
    payload: forConnect,
  };
};

export const setSort = (sort) => {
  return {
    type: SET_INVENTORY_FILTER,
    payload: { sort },
  };
};
export const resetInventoryToDefault = () => {
  return {
    type: RESET_INVENTORY_TO_DEFAULT,
  };
};

export const setInventoryFilter = (filter) => {
  return {
    type: SET_INVENTORY_FILTER,
    payload: filter,
  };
};

export const setInventoryFilterAndReload = (filter) => {
  return (dispatch, getState) => {
    dispatch(setInventoryFilter(filter));
    dispatch(reloadInventory());
  };
};

export const setSortAndReload = (sort) => {
  return (dispatch, getState) => {
    dispatch(setSort(sort));
    const products = getState().products;
    const { inventoryType, showAll, filter, query } = products;
    const argums = { inventoryType, showAll, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};

export const setFilterAndReload = (filter) => {
  return (dispatch, getState) => {
    dispatch(setFilter(filter));
    const products = getState().products;
    const { inventoryType, sort, showAll, query } = products;
    const argums = { inventoryType, showAll, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};

export const reloadWithLocationFilter = () => {
  return (dispatch, getState) => {
    const products = getState().products;
    const locationList = getState().locationList;
    const { inventoryType, sort, showAll, query } = products;
    const { filter } = locationList;

    const argums = {
      inventoryType,
      showAll,
      sort,
      query,
      locationFilter: filter,
    };
    dispatch(reloadInventoryCall(products, argums));
  };
};
export const setShowAll = (showAll) => {
  return {
    type: SET_SHOW_ALL,
    payload: showAll,
  };
};

export const setNumberPer = (numberPer) => {
  return {
    type: SET_NUMBER_PER,
    payload: numberPer,
  };
};

export const setShowAllAndReload = (showAll) => {
  return (dispatch, getState) => {
    dispatch(setShowAll(showAll));
    const products = getState().products;
    const { inventoryType, sort, filter, query } = products;
    const argums = { inventoryType, showAll, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};

export const setNumberPerAndReload = (numberPer) => {
  return (dispatch, getState) => {
    dispatch(setNumberPer(numberPer));
    const products = getState().products;
    const { inventoryType, sort, filter, query } = products;
    const argums = { inventoryType, numberPer, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};

export const reloadInventory = (argums = {}) => {
  return (dispatch, getState) => {
    dispatch(reloadInventoryCall(getState().products, argums));
  };
};

export const setInventoryType = (inventoryType) => {
  return {
    type: SET_INVENTORY_TYPE,
    payload: inventoryType,
  };
};

export const loadInventory = (productType) => {
  return (dispatch, getState) => {
    const products = getState().products;
    const { sort, showAll, filter, query } = products;
    const inventoryType = productType;
    const argums = { inventoryType, showAll, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};
export const searchProducts = (query) => {
  return (dispatch, getState) => {
    dispatch(setQuery(query));
    const products = getState().products;
    const { inventoryType, sort, filter } = products;

    const link = '/api/' + apiConnections[inventoryType] + '/paginated';
    dispatch({
      type: SEARCH_INVENTORY,
      link: link,
      productType: inventoryType,
      options: {
        filter,
        sort,
        per: 15,
        query,
      },
    });
  };
};
export function infiniteLoadInventoryCall(
  options,
  link,
  productType,
  reset = false
) {
  return (dispatch, getState) => {
    dispatch({ type: INFINITE_REQUEST });
    service
      .post(`${PAGINATE_ROOT_URL}${link}`, options)
      .then((response) => {
        return dispatch({
          options,
          link,
          productType,
          reset,
          response,
          type: INFINITE_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          options,
          link,
          productType,
          reset,
          error,
          type: INFINITE_FAILURE,
        });
      });
  };
}

export const infiniteLoadInventory = (link, productType) => {
  return (dispatch, getState) => {
    const products = getState().products;
    const { sort, filter } = products;
    const options = Object.assign({
      filter,
      sort,
      per: 15,
    });
    dispatch(infiniteLoadInventoryCall(options, link, productType));
  };
};

export const resetInfiniteAndLoad = (productType) => {
  return (dispatch, getState) => {
    dispatch(resetInventory());
    const link = '/api/' + apiConnections[productType] + '/paginated';
    const products = getState().products;
    const { sort, filter } = products;
    const options = Object.assign({
      filter,
      sort,
      per: 15,
    });
    dispatch(infiniteLoadInventoryCall(options, link, productType));
  };
};

export const setQueryAndReload = (query) => {
  return (dispatch, getState) => {
    dispatch(setQuery(query));
    const products = getState().products;
    const { inventoryType, sort, filter, showAll } = products;
    const argums = { inventoryType, showAll, filter, sort, query };
    dispatch(reloadInventoryCall(products, argums));
  };
};

export function convertInventory(data) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Converting inventory...'));
    service
      .post(`${ROOT_URL}/inventories/convert`, data)
      .then((response) => {
        dispatch(reloadInventory());
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
      });
  };
}

export function bulkUpdateProducts(productType, data, onComplete = null) {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Updating inventory...'));
    service
      .post(`${ROOT_URL}/${apiConnections[productType]}/bulk_update`, data)
      .then((response) => {
        if (onComplete) {
          onComplete();
        } else {
          dispatch(reloadInventory());
        }
        dispatch(closeLoadingSpinner());
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch(closeLoadingSpinner());
      });
  };
}

export function submitInventoryCount(
  productId,
  inventoryCount,
  changeHistoryEntry
) {
  return (dispatch, getState) => {
    const { inventoryType } = getState().products;
    const data = {
      [apiToSingularRoot[inventoryType]]: {
        inventory_count_attributes: inventoryCount,
        change_history_entries_attributes: {
          '0': changeHistoryEntry,
        },
      },
    };
    service
      .post(
        `${ROOT_URL}/${apiConnections[inventoryType]}/${productId}/submit_count`,
        data
      )
      .then((response) => {
        dispatch(reloadInventory());
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
      });
  };
}

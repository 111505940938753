import styled from 'styled-components';

export const NotesContainer = styled.div`
  gap: 0.6rem;
  display: flex;
  padding-top: 0.5rem;
  flex-direction: column;
`;

export const NoteContent = styled.span``;

import {
  SET_ASSOCIATED_CONTACT_VENUES,
  TOGGLE_CONTACT_VENUES_MODAL,
  TOGGLE_VENUE_SEARCH_LIST_DRAWER,
  SET_VENUE,
  SET_VENUE_RENTAL_RELATIONSHIP,
  RESET_VENUE_FORM,
  SET_VENUE_SEARCH_LIST_DRAWER_STATE,
  SET_SHOULD_ADD_VENUE_DOCUMENTS,
  SET_SHOULD_ADD_VENUE_NOTE,
  SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE,
} from 'Constants/redux';
import service from 'Api/service';
import { setCanLeave } from './RentalFormActions';
import { saveError } from 'Api';

export const toggleContactVenueModal = () => ({
  type: TOGGLE_CONTACT_VENUES_MODAL,
});
export const toggleVenueSearchListDrawer = () => ({
  type: TOGGLE_VENUE_SEARCH_LIST_DRAWER,
});
export const setVenueSearchListDrawerState = (open) => ({
  type: SET_VENUE_SEARCH_LIST_DRAWER_STATE,
  open,
});

export const handleSelectVenueClick = () => (dispatch, getState) => {
  const { associatedContactsVenues } = getState().rentalForm.venue;
  if (associatedContactsVenues.length >= 1) {
    dispatch(toggleContactVenueModal());
  } else {
    dispatch(toggleVenueSearchListDrawer());
  }
};

export const updateAssociatedContactVenues = (
  // @ToDo Refactor once rentalForm.customers and rentalForm.company is implemented customers and company values
  // should be fetched from getState().rentalForm...
  customers,
  company
) => (dispatch, getState) => {
  const allVenues = [];
  const promises = [];
  const { venue } = getState().rentalForm.venue;

  for (const customer of customers) {
    const {
      clientType,
      _destroy,
      clientId,
    } = customer.customerRentalRelationship;
    if (clientType === 'CustomerLocationRelationship' && _destroy !== '1') {
      promises.push(
        service.get(
          `${process.env.REACT_APP_API_DOMAIN}/api/customer_location_relationships/${clientId}/venues`
        )
      );
    }
  }

  Promise.all(promises)
    .then((responses) => {
      for (const response of responses) {
        const { venues } = response.data.customerLocationRelationship;
        allVenues.push(...venues);
      }

      if (company && company._destroy !== '1')
        allVenues.push(...company.venues);

      const uniqueVenues = allVenues.filter(
        (value, index, self) =>
          index === self.findIndex((venue) => venue.id === value.id)
      );

      dispatch({ type: SET_ASSOCIATED_CONTACT_VENUES, venues: uniqueVenues });
    })
    .catch((error) => {
      console.log(error);
      saveError(error);
    });
};

export const setVenue = (venue) => ({ type: SET_VENUE, venue });

export const setVenueRentalRelationship = (venueRentalRelationship) => ({
  type: SET_VENUE_RENTAL_RELATIONSHIP,
  venueRentalRelationship,
});

export const setShouldAddVenueDocuments = (boolean) => ({
  type: SET_SHOULD_ADD_VENUE_DOCUMENTS,
  boolean,
});
export const setShouldAddVenueNote = (boolean) => ({
  type: SET_SHOULD_ADD_VENUE_NOTE,
  boolean,
});
export const setShouldChangeDeliveryAddressSourceTypeToVenue = (boolean) => ({
  type: SET_SHOULD_CHANGE_DELIVERY_ADDRESS_SOURCE_TYPE_TO_VENUE,
  boolean,
});

export const handleVenueChange = (venue, isChecked) => (dispatch, getState) => {
  const { venueRentalRelationship } = getState().rentalForm.venue;
  const newVenue = { ...venue, _destroy: '0' };
  const newVRR = {
    ...venueRentalRelationship,
    venue_id: venue.id,
    _destroy: '0',
  };

  dispatch(setVenue(newVenue));
  dispatch(setVenueRentalRelationship(newVRR));
  dispatch(setVenueSearchListDrawerState(!isChecked));
  dispatch(setCanLeave(false));

  // @ToDo these dispatch calls should be handled by dispatch functions in NotesAction, DocumentsAction, and DeliveryActions when rentalForm.notes, rentalForm.documents, and
  // rentalForm.delivery are implemented, currently we are passing props to ReusableForm, that will call the component methods.
  dispatch(setShouldAddVenueDocuments(true));
  dispatch(setShouldAddVenueNote(true));
  if (isChecked)
    dispatch(setShouldChangeDeliveryAddressSourceTypeToVenue(true));
};

export const deleteFormVenue = () => (dispatch, getState) => {
  const { venueRentalRelationship, venue } = getState().rentalForm.venue;
  const newVenue = { ...venue, _destroy: '1' };
  const newVRR = { ...venueRentalRelationship, _destroy: '1' };

  dispatch(setVenue(newVenue));
  dispatch(setVenueRentalRelationship(newVRR));
  dispatch(setCanLeave(false));
};

export const resetVenueForm = () => ({ type: RESET_VENUE_FORM });

export const setVenueForm = (
  rental,
  temporarilyNeededComponentState,
  forReorderOrTemplate = false
) => (dispatch, getState) => {
  if (rental.venueRentalRelationship) {
    dispatch(setVenue(rental.venueRentalRelationship.venue));
    if (!forReorderOrTemplate)
      dispatch(setVenueRentalRelationship(rental.venueRentalRelationship));
  }

  const { customers } = temporarilyNeededComponentState;
  const company = rental.companyRentalRelationship?.company;

  dispatch(updateAssociatedContactVenues(customers, company, false));
};

import {
  SHOW_ITEM_DISCOUNTS_ALERT,
  HIDE_ITEM_DISCOUNTS_ALERT,
  SHOW_NONDISCOUNTABLES_ALERT,
  HIDE_NONDISCOUNTABLES_ALERT,
} from 'Constants/redux';

function ItemDicountsAlertReducer(
  state = {
    itemDiscountsOpen: false,
    nondiscountablesOpen: false,
    nondiscountables: null,
  },
  action
) {
  switch (action.type) {
    case SHOW_ITEM_DISCOUNTS_ALERT:
      return {
        ...state,
        itemDiscountsOpen: true,
        nondiscountables: action.nondiscountables,
      };
    case HIDE_ITEM_DISCOUNTS_ALERT:
      return {
        ...state,
        itemDiscountsOpen: false,
        nondiscountables: null,
      };
    case SHOW_NONDISCOUNTABLES_ALERT:
      return {
        ...state,
        nondiscountablesOpen: true,
        nondiscountables: action.nondiscountables,
      };
    case HIDE_NONDISCOUNTABLES_ALERT:
      return {
        ...state,
        nondiscountablesOpen: false,
        nondiscountables: null,
      };
    default:
      return state;
  }
}

export default ItemDicountsAlertReducer;

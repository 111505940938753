import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import {
  TitleText,
  MidContainer,
  TopContainer,
  RootContainer,
  BottomContainer,
} from './ParentOrderSummaryInvoice.styles';
import { setErrors } from '../../../actions';
import NotesInvoiceSection from './NotesInvoiceSection';
import LoadingSpinner from '../../utils/LoadingSpinner';
import { mapStateToProps } from './redux/mapStateToProps';
import {
  Rental,
  useGetParentRentalForInvoiceLazyQuery,
} from '../../../types/graphql';
import PaymentTermsInvoiceSection from './PaymentTermsInvoiceSection';
import { ParentOrderSummaryInvoiceProps } from './ParentOrderSummaryInvoice.types';
import SecondaryOrdersInvoiceTotalsTable from './SecondaryOrdersInvoiceTotalsTable';
import BusinessInvoiceSection from './BusinessInvoiceSection/BusinessInvoiceSection';
import ContactInfoSection from './ContactInfoInvoiceSection/ContactInfoInvoiceSection';
import OrderInfoInvoiceSection from './OrderInfoInvoiceSection/OrderInfoInvoiceSection';
import ClientSignatureInvoiceSection from './ClientSignatureInvoiceSection/ClientSignatureInvoiceSection';

const ParentOrderSummaryInvoice = ({
  match,
  business,
  setErrors,
}: ParentOrderSummaryInvoiceProps) => {
  const token = match.params?.token!;

  const [isLoading, setIsLoading] = useState(true);
  const [getParentRental] = useGetParentRentalForInvoiceLazyQuery();
  const [parentOrder, setParentOrder] = useState<Partial<Rental>>({});

  useEffect(() => {
    if (token.length) {
      setIsLoading(true);
      getParentRental({
        variables: { ID: token },
        onError: (error) => {
          setErrors('Error fetching parent rental');
          setIsLoading(false);
        },
        onCompleted: (data) => {
          setParentOrder(data.getRentalById as Rental);
          setIsLoading(false);
        },
        fetchPolicy: 'no-cache',
      });
    }
  }, [token]);

  const eventNameLabel = parentOrder.event
    ? `${parentOrder.event?.name} /`
    : ``;
  const titleText = `${eventNameLabel} ${parentOrder.name}`;

  if (isLoading) {
    return <LoadingSpinner loading={isLoading} />;
  }

  return (
    <RootContainer>
      <TitleText>{titleText}</TitleText>
      <TopContainer>
        <BusinessInvoiceSection business={business} />
        <OrderInfoInvoiceSection parentOrder={parentOrder} />
      </TopContainer>
      <MidContainer>
        <ContactInfoSection parentOrder={parentOrder} />
        <SecondaryOrdersInvoiceTotalsTable
          secondaryOrders={parentOrder?.secondaryOrders!}
        />
      </MidContainer>
      <BottomContainer>
        <PaymentTermsInvoiceSection paymentTerm={parentOrder.paymentTerm!} />
        <NotesInvoiceSection
          notes={parentOrder.notes!}
          timeZone={parentOrder.schedule?.timeZone!}
        />
        <ClientSignatureInvoiceSection parentOrder={parentOrder} />
      </BottomContainer>
    </RootContainer>
  );
};

export default connect(mapStateToProps, { setErrors })(
  ParentOrderSummaryInvoice
);

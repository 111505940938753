import React from 'react';
import classNames from 'classnames';
import SerializedRow from 'Utils/SerializedRow';

class SerializedInventoryTable extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      usageDir: true,
    };
  }

  changeColumnDirection = (name) => {
    this.setState((prevState) => ({
      [name]: !prevState[name],
    }));
  };

  render() {
    const {
      updateSelected,
      checkSelected,
      unitsAvailable,
      isCheckIn,
      serializedProducts,
      serializedPickListItems,
      sortBySelected,
    } = this.props;
    const { usageDir } = this.state;
    const showUsage = serializedProducts[0]?.usageWithUnitDetails?.length > 0;

    const filteredSerializedPickListItems = serializedPickListItems?.filter(
      (item) => item.status === 'in_use'
    );
    const filteredSerials = filteredSerializedPickListItems?.map(
      (product) => product.serializedProduct.token
    );
    const filteredSerializedProducts = !!isCheckIn
      ? serializedProducts?.filter((product) =>
          filteredSerials?.includes(product.token)
        )
      : serializedProducts;
    return (
      <table
        className={classNames({
          grid: true,
          checkInUsage: isCheckIn,
        })}
      >
        <thead>
          <tr>
            <th>
              <span
                className={classNames('padding-bottom-10', {
                  sortable: !!sortBySelected,
                  asc: true,
                })}
                onClick={sortBySelected}
              />
            </th>
            <th>Serial No</th>
            {showUsage && (
              <th>
                <span
                  className={classNames({
                    sortable: true,
                    asc: usageDir,
                    desc: !usageDir,
                  })}
                  onClick={() => {
                    this.changeColumnDirection('usageDir');
                  }}
                >
                  Usage
                </span>
              </th>
            )}
            {showUsage && <th>Usage Out</th>}
            {!!isCheckIn && !!unitsAvailable && <th>Date</th>}
            {!!isCheckIn && !!unitsAvailable && <th>Units In</th>}
          </tr>
        </thead>
        <tbody>
          {!!isCheckIn
            ? filteredSerializedProducts.map((serializedProduct) =>
                Array.isArray(serializedProduct.checkIn) &&
                serializedProduct.checkIn.length > 0 ? (
                  serializedProduct.checkIn.map((checkIn, index) => (
                    <SerializedRow
                      serializedProduct={serializedProduct}
                      index={index}
                      checkObj={checkIn}
                      updateSelected={updateSelected}
                      checkSelected={checkSelected}
                    />
                  ))
                ) : (
                  <SerializedRow
                    serializedProduct={serializedProduct}
                    index={0}
                    updateSelected={updateSelected}
                    checkSelected={checkSelected}
                  />
                )
              )
            : filteredSerializedProducts.map((serializedProduct) =>
                Array.isArray(serializedProduct.checkOut) &&
                serializedProduct.checkOut.length > 0 ? (
                  serializedProduct.checkOut.map((checkOut, index) => (
                    <SerializedRow
                      serializedProduct={serializedProduct}
                      index={index}
                      checkObj={checkOut}
                      updateSelected={updateSelected}
                      checkSelected={checkSelected}
                    />
                  ))
                ) : (
                  <SerializedRow
                    serializedProduct={serializedProduct}
                    index={0}
                    updateSelected={updateSelected}
                    checkSelected={checkSelected}
                  />
                )
              )}
        </tbody>
      </table>
    );
  }
}

export default SerializedInventoryTable;

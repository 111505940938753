import { combineEpics } from 'redux-observable';
import SearchCompanyNamesEpic from './SearchCompanyNamesEpic';
import SearchVenueNamesEpic from './SearchVenueNamesEpic';
import FetchCustomerNamesEpic from './FetchCustomerNamesEpic';
import FetchStaffMemberNamesEpic from './FetchStaffMemberNamesEpic';
import AddStaffMemberNamesEpic from './AddStaffMemberNamesEpic';
import FetchStaffRoleNamesEpic from './FetchStaffRoleNamesEpic';
import FetchRecurrencesEpic from './FetchRecurrencesEpic';
import AddStaffRoleNamesEpic from './AddStaffRoleNamesEpic';
import AddCustomerNamesEpic from './AddCustomerNamesEpic';
import AddVenueNamesEpic from './AddVenueNamesEpic';
import AddCompanyNamesEpic from './AddCompanyNamesEpic';
import FetchMessageableNamesEpic from './FetchMessageableNamesEpic';
import FetchVenueNamesEpic from './FetchVenueNamesEpic';
import FetchCompanyNamesEpic from './FetchCompanyNamesEpic';
import FetchProductListEpic from './FetchProductListEpic';
import FetchAccessoryListEpic from './FetchAccessoryListEpic';
import FetchBundleListEpic from './FetchBundleListEpic';
import FetchAddOnListEpic from './FetchAddOnListEpic';
import ReloadInventoryEpic from './ReloadInventoryEpic';
import FetchRentalListEpic from './FetchRentalListEpic';
import PaginateRentalListEpic from './PaginateRentalListEpic';
import FetchRentalInventoryListEpic from './FetchRentalInventoryListEpic';
import PaginateRentalInventoryListEpic from './PaginateRentalInventoryListEpic';
import FetchInfiniteInventoryEpic from './FetchInfiniteInventoryEpic';
import ConnectDefaultsEpic from './ConnectDefaultsEpic';
import MarketplaceSettingsEpic from './MarketplaceSettingsEpic';
import DebouncedUpdateProductEpic from './DebouncedUpdateProductEpic';
import FetchCombinedInventoryEpic from './FetchCombinedInventoryEpic';
import FetchInventoryAvailabilityEpic from './FetchInventoryAvailabilityEpic';

const rootEpic = combineEpics(
  SearchCompanyNamesEpic,
  SearchVenueNamesEpic,
  FetchCustomerNamesEpic,
  AddCustomerNamesEpic,
  AddVenueNamesEpic,
  AddCompanyNamesEpic,
  FetchStaffMemberNamesEpic,
  AddStaffMemberNamesEpic,
  FetchStaffRoleNamesEpic,
  FetchRecurrencesEpic,
  AddStaffRoleNamesEpic,
  FetchMessageableNamesEpic,
  FetchProductListEpic,
  FetchAddOnListEpic,
  FetchAccessoryListEpic,
  FetchBundleListEpic,
  FetchVenueNamesEpic,
  FetchCompanyNamesEpic,
  ReloadInventoryEpic,
  FetchRentalListEpic,
  PaginateRentalListEpic,
  FetchRentalInventoryListEpic,
  PaginateRentalInventoryListEpic,
  FetchInfiniteInventoryEpic,
  ConnectDefaultsEpic,
  MarketplaceSettingsEpic,
  DebouncedUpdateProductEpic,
  FetchCombinedInventoryEpic,
  FetchInventoryAvailabilityEpic
);

export default rootEpic;

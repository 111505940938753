import AppState from '../../../../types/AppState';

export const mapStateToProps = (state: AppState) => {
  const { business: rootBusiness } = state;
  const { business } = rootBusiness;

  return {
    business,
  };
};

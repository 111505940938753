import { ROOT_URL } from "Api";
import service from "Api/service";

const routing = {
  fetchDrivers: () => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN + "/api/location/drivers/names"
    );
  }
};

export default routing;

import React from "react";
import Modal from "Utils/Modal";
import * as actions from "Actions";
import { connect } from "react-redux";
import { saveError } from "Api";
import { RemoveCircle } from "Utils/SvgIcons";
import Endpoints from "Api/endpoints";
import ScrollArea from "Utils/react-scrollbar";

class SerializedMultiplePickModal extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      selected: [],
      serialNumber: "",
      quantity: "",
      isSerializedMode: true,
      pickedQuantity: ""
    };
  }

  handleChange = event => {
    const { name, value } = event.target;
    this.setState({
      [name]: value
    });
  };

  handleSerialNumberReset = () => {
    this.setState({
      serialNumber: "",
      quantity: ""
    });
  };

  performSearch = event => {
    event.preventDefault();
    const { product, setErrors } = this.props;
    const { serialNumber, quantity } = this.state;

    if (quantity === "" || +quantity <= 0) {
      setErrors("Invalid quantity");
      return;
    }

    const component = this;
    Endpoints.serializedProducts
      .showBySerialNumber(product, serialNumber)
      .then(response => {
        const { selected, quantity } = component.state;
        const { serializedProduct } = response.data;
        if (
          +quantity >
          product.quantity - product.maintenanceQuantity
        ) {
          setErrors("Cannot checkout item that is in maintenance.");
        } else {
          const foundIndex = selected.findIndex(
            sp => sp.serialNumber === serializedProduct.serialNumber
          );
          if (foundIndex === -1) {
            component.setState({
              selected: [
                ...selected,
                { ...serializedProduct, maintenanceQuantity: quantity }
              ],
              serialNumber: ""
            });
          } else {
            setErrors("Serial number already added.");
            component.setState({
              serialNumber: ""
            });
          }
        }
      })
      .catch(error => {
        setErrors("Unknown/invalid token provided.");
        console.log(error);
        saveError(error);
      });
  };

  handleSubmit = () => {
    const { isSerializedMode } = this.state;
    if (isSerializedMode) {
      this.handleSerializedSubmit();
    } else {
      this.handleStandardSubmit();
    }
  };

  handleSerializedSubmit = () => {
    const { selected, quantity } = this.state;
    const {
      rental,
      item,
      serializedMultiplePickRentalItem,
      toggle
    } = this.props;

    // Validation
    if (selected.length === 0) {
      this.props.setErrorMessage({
        field: "selected",
        text: "You need to select at least one serialized item."
      });
    } else if (quantity > item.quantity) {
      this.props.setErrorMessage({
        field: "selected",
        text: "Cannot checkout more items than rented."
      });
    } else {
      const selectedSp = selected[0];
      const pickedQuantity = selectedSp.maintenanceQuantity;
      const pickedId = selectedSp.id;
      serializedMultiplePickRentalItem(
        rental.id,
        item.id,
        pickedQuantity,
        pickedId
      );
      toggle();
    }
  };

  handleStandardSubmit = () => {
    const { pickedQuantity } = this.state;

    // Validation
    if (pickedQuantity < 0 || pickedQuantity > this.props.item.quantity) {
      this.props.setErrorMessage({
        field: "pickedQuantity",
        text: "Cannot checkout more than reserved"
      });
    } else {
      const { rental, item, pickRentalItem, toggle } = this.props;
      pickRentalItem(rental.id, item.id, pickedQuantity);
      toggle();
    }
  };

  removeItem = serialNumber => {
    const newSelected = this.state.selected.filter(
      s => s.serialNumber !== serialNumber
    );
    this.setState({
      selected: newSelected
    });
  };

  toggleIsSerializedMode = () => {
    this.setState({
      isSerializedMode: !this.state.isSerializedMode
    });
  };

  render() {
    const { product, open, toggle } = this.props;
    const {
      serialNumber,
      quantity,
      selected,
      isSerializedMode,
      pickedQuantity
    } = this.state;

    return (
      <Modal
        open={open}
        toggle={toggle}
        large={true}
        className="serializedModal"
        title="Check Out Serialized Inventory"
        actions={[
          <a className="btn" onClick={this.handleSubmit}>
            Submit And Update Rental Inventory
          </a>,
          <a className="btn cancel" onClick={toggle}>
            Cancel
          </a>
        ]}
      >
        <div className="form">
          <p>{product.name}</p>
          {isSerializedMode ? (
            <div>
              <form className="fullForm" onSubmit={this.performSearch}>
                <input
                  type="text"
                  name="serialNumber"
                  className="barcode"
                  onChange={this.handleChange}
                  value={serialNumber}
                />
                <input
                  type="text"
                  name="quantity"
                  onChange={this.handleChange}
                  placeholder="Quantity"
                  value={quantity}
                />
                <input
                  type="submit"
                  value="Add Item to Check-Out List"
                  className="btn"
                />
                <button
                  className="btn cancel"
                  onClick={this.handleSerialNumberReset}
                >
                  Cancel
                </button>
              </form>
              <section>
                <h4>Total</h4>
                <strong>
                  {selected[0] ? selected[0].maintenanceQuantity : 0}
                </strong>
                <ScrollArea speed={0.8} horizontal={false}>
                  <table className="grid">
                    <thead>
                      <tr>
                        <th>Serial No</th>
                        <th className="actionSm" />
                      </tr>
                    </thead>
                    <tbody>
                      {selected.map((s, index) => (
                        <tr key={index}>
                          <td>{s.serialNumber}</td>
                          <td>
                            <a
                              className="btnLink remove"
                              onClick={() => this.removeItem(s.serialNumber)}
                            >
                              <RemoveCircle />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </ScrollArea>
              </section>
            </div>
          ) : (
            <div className="fields">
              <p>How many items need to be Checked-Out?</p>
              <input
                type="text"
                name="pickedQuantity"
                className="sm"
                onChange={this.handleChange}
                value={pickedQuantity}
              />
            </div>
          )}
          <a onClick={this.toggleIsSerializedMode}>
            {isSerializedMode
              ? "Check Out Serialized Items without using Serial Numbers"
              : "Check Out Serialized Items using Serial Numbers"}
          </a>
        </div>
      </Modal>
    );
  }
}

const mapStateToProps = state => {
  const { rental, loading } = state.rental;
  const { errors } = state.dashboard;
  return { rental, loading, errors };
};

export default connect(mapStateToProps, actions)(SerializedMultiplePickModal);

import { ROOT_URL, saveError } from "Api";
import service from "Api/service";
import { adminService } from "Api/service";
import {
  FETCH_TERMINOLOGIES_REQUEST,
  FETCH_TERMINOLOGIES_SUCCESS,
  FETCH_TERMINOLOGIES_FAILURE,
  FETCH_ORIGINAL_TERMINOLOGIES_REQUEST,
  FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS,
  FETCH_ORIGINAL_TERMINOLOGIES_FAILURE
} from "Constants/redux";
import converter from 'json-style-converter/es5';

export function fetchTerminologies(admin = false, locationId) {
  const serviceToUse = admin ? adminService : service;
  return (dispatch, getState) => {
    dispatch({ type: FETCH_TERMINOLOGIES_REQUEST });
    serviceToUse
      .get(`${ROOT_URL}/terminologies/`, { location_id: locationId })
      .then(response => {
        return dispatch({
          response,
          type: FETCH_TERMINOLOGIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_TERMINOLOGIES_FAILURE
        });
      });
  };
}

export function fetchOriginalTerminologies(admin = false) {
  const serviceToUse = admin ? adminService : service;
  return (dispatch, getState) => {
    dispatch({ type: FETCH_ORIGINAL_TERMINOLOGIES_REQUEST });
    serviceToUse
      .get(`${ROOT_URL}/original_terminology/`)
      .then(response => {
        console.log(response)
        return dispatch({
          response,
          type: FETCH_ORIGINAL_TERMINOLOGIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_ORIGINAL_TERMINOLOGIES_FAILURE
        });
      });
  };
}



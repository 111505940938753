import React from "react";
import { Link } from "react-router-dom";

const NotAuthorized = () => {
  return (
    <div className="errorLanding">
      <p>You are not authorized to access this page.</p>
      <h1>Oops!</h1>
      <Link to="/" className="btn">
        Take me home
      </Link>
    </div>
  );
};
export default NotAuthorized;

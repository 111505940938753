import React from 'react';
import { getSortClass } from 'HelperFunctions/rentals';

const StandardRentalTableHead = (props) => {
  const { sortField, sortDirection, onSort, multilocationAllLocations } = props;
  return (
    <tr>
      {multilocationAllLocations && <th className='exSm'>Location</th>}
      <th className='lg'>
        <span
          className={getSortClass('name', sortField, sortDirection)}
          onClick={() => onSort('name')}
        >
          Item
        </span>
      </th>
      <th className='date med'>
        <span
          className={getSortClass('start_date', sortField, sortDirection)}
          onClick={() => onSort('start_date')}
        >
          Date
        </span>
      </th>
      <th className='med'>
        <span>Supplier</span>
      </th>
      <th className='med'>
        <span>Contact</span>
      </th>
      <th className='med'>Contact Info</th>
      <th className='med'>Expenses</th>
      <th className='actionSm' />
    </tr>
  );
};
export default StandardRentalTableHead;

import {
  UPDATE_RENTAL_FORM_V2_REQUEST,
  UPDATE_RENTAL_FORM_V2_SUCCESS,
} from '../../constants/redux';
import service from '../../api/service';
import { ROOT_URL } from '../../api';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';
import { RentalFormActionTypes } from './types';
import RentalVersionData from '../../types/RentalVersionData';
import _ from 'lodash';
import axios from 'axios';

function deepSnakeCaseKeys(obj) {
  if (_.isArray(obj)) {
    return obj.map(deepSnakeCaseKeys);
  } else if (_.isPlainObject(obj)) {
    return _.mapValues(
      _.mapKeys(obj, (value, key) => (key[0] === '_' ? key : _.snakeCase(key))),
      deepSnakeCaseKeys
    );
  }
  return obj;
}

export const updateRentalVersion = (
  id: number,
  payload: Partial<RentalVersionData>,
  cancelToken = axios.CancelToken.source().token
): ThunkAction<
  Promise<void>,
  AppState,
  unknown,
  RentalFormActionTypes
> => async (dispatch) => {
  dispatch({ type: UPDATE_RENTAL_FORM_V2_REQUEST });
  
  try {
    const response = await service.put(
      `${ROOT_URL}/rental_versions/${id}`,
      {
        rental_version: deepSnakeCaseKeys(payload),
      },
      { cancelToken }
    );

    dispatch({
      type: UPDATE_RENTAL_FORM_V2_SUCCESS,
      payload: response.data,
    });
  } catch (error) {
    return Promise.reject(error);
  }
};

import React from 'react';
import { connect } from 'react-redux';
import * as actions from 'Actions';
import PrintMapRoute from './PrintMapRoute';
import PrintMapRouteInfo from './PrintMapRouteInfo';
import moment from "moment";
import { parseQuery } from "HelperFunctions/QueryString";
import update from 'immutability-helper';
import PrintDispatchSummary from './PrintDispatchSummary';
import PrintFullInventorySheet from './PrintFullInventorySheet';
import PrintDeliveryManifest from './PrintDeliveryManifest';

// Description: The route print page
class PrintMapRoutePrintables extends React.Component {
  state = {
    stops: [],
    routeInfo: null,
    directions: null
  };

  UNSAFE_componentWillMount() {
    const { truckRoute } = this.props;
    this.setState({
      startTime: truckRoute.startTime,
      stops: truckRoute.stops,
    });
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (this.props.match.params.id !== nextProps.match.params.id) {
      const { fetchTruckRoute } = this.props;
      fetchTruckRoute(nextProps.match.params.id);
    }
  }

  componentDidMount() {
    const { fetchTruckRoute } = this.props;
    fetchTruckRoute(this.props.match.params.id);
  }
  
  handleStartTimeChange = (event) => {
    const { truckRoute, updateTruckRoute } = this.props;
    this.setState({
      startTime: event.target.value,
    });
    const data = {
      truck_route: {
        start_time: event.target.value,
      },
    };
    updateTruckRoute(truckRoute.id, data);
  };

  moveStop = (oldIndex, newIndex) => {
    const { stops } = this.state;
    const dragItem = stops[oldIndex];
    const newState = update(this.state, {
      stops: {
        $splice: [
          [oldIndex, 1],
          [newIndex, 0, dragItem],
        ],
      },
    });
    this.handlePositionChange(newState.stops);
    this.setState(newState);
  };

  handlePositionChange = (stops) => {
    const { truckRoute, updateTruckRoute } = this.props;
    const newStops = stops.map((stop, index) => {
      return {
        id: stop.id,
        position: index,
      };
    });

    const data = {
      truck_route: {
        stops_attributes: newStops,
      },
    };
    updateTruckRoute(truckRoute.id, data);
  };

  handleDirectionChange = (response) => {
    this.setState({ routeInfo: response.routes[0] });
  };

  render() {
    const {
      truckRoute,
      loading 
    } = this.props;
    const { withMap } = parseQuery(window.location.search);
    const { name, startTime, stops, routeStart, routeEnd } = truckRoute;
    const { directions, routeInfo } = this.state;
    let legs = [];
    let totalDuration = "";
    if (directions) {
      legs = directions.legs;
      let arrivalTime = moment(startTime);
      legs = legs.map((leg, index) => {
        const duration = leg.duration.value;
        arrivalTime = moment(arrivalTime).second(
          arrivalTime.second() + duration
        );
        const result = {
          duration: moment.duration(duration, "seconds").humanize(),
          arrivalTime: arrivalTime.toDate()
        };
        if (index < legs.index - 1) {
          arrivalTime = moment(arrivalTime).minute(
            arrivalTime.minute() + stops[index].setupTime
          );
        }
        return result;
      });
      totalDuration = moment
        .duration(arrivalTime.diff(moment(startTime)))
        .humanize();
    }

    let stopNumberCounter = 0;
    const stopNumbers = stops.map((stop) => {
      if (stop.stopType === 'custom' && !stop.physicalAddress) {
        return '';
      } else {
        stopNumberCounter++;
        return stopNumberCounter;
      }
    });
    
    let includeLocationsWithMap = window.location.href.includes('locationsWithMap')
    let includeTurnByTurnWithMap = window.location.href.includes('turnByTurnWithMap')
    let includeLocationsOnly = window.location.href.includes('locationsOnly')
    let includeTurnByTurnOnly = window.location.href.includes('turnByTurnOnly')
    let includeDispatchSummary = window.location.href.includes('dispatchSummary')
    let includeFullInventorySheet = window.location.href.includes('fullInventorySheet')
    let includeDeliveryManifest = window.location.href.includes('deliveryManifest')

    return (
      <div>
        {includeLocationsWithMap &&
          <PrintMapRoute
            truckRoute={truckRoute}
            withMap={withMap}
            loading={loading}
            name={name}
            startTime={startTime}
            directions={directions}
            totalDuration={totalDuration}
            stops={stops}
            routeInfo={routeInfo}
            routeStart={routeStart}
            stopNumbers={stopNumbers}
            legs={legs}
            handleDirectionChange={this.handleDirectionChange}
            moveStop={this.moveStop}
            match={this.props.match}
          />
        }
        {includeTurnByTurnWithMap &&
          <PrintMapRouteInfo
            truckRoute={truckRoute}
            withMap={withMap}
            loading={loading}
            name={name}
            startTime={startTime}
            directions={directions}
            totalDuration={totalDuration}
            stops={stops}
          />
        }
        {includeLocationsOnly &&
          <PrintMapRoute
            truckRoute={truckRoute}
            withMap={undefined}
            loading={loading}
            name={name}
            startTime={startTime}
            directions={directions}
            totalDuration={totalDuration}
            stops={stops}
            routeInfo={routeInfo}
            routeStart={routeStart}
            stopNumbers={stopNumbers}
            legs={legs}
            handleDirectionChange={this.handleDirectionChange}
            moveStop={this.moveStop}
            match={this.props.match}
          />
        }
        {includeTurnByTurnOnly &&
          <PrintMapRouteInfo
            truckRoute={truckRoute}
            withMap={undefined}
            loading={loading}
            name={name}
            startTime={startTime}
            directions={directions}
            totalDuration={totalDuration}
            stops={stops}
          />
        }
        { includeDispatchSummary &&
          <PrintDispatchSummary
            truckRoute={truckRoute}
            match={this.props.match}
          />
        }
        { includeFullInventorySheet &&
          <PrintFullInventorySheet
            match={this.props.match}
          />
        }
        { includeDeliveryManifest &&
          <PrintDeliveryManifest
            match={this.props.match}
          />
        }
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { truckRoute, loading } = state.truckRoute;
  return { truckRoute, loading };
};

export default connect(mapStateToProps, actions)(PrintMapRoutePrintables);

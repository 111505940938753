import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as actions from "Actions";
import AdminNav from "./AdminNav";
import SignedInNav from "./SignedInNav";
import SignedOutNav from "./SignedOutNav";

class MainMenu extends Component {
  static propTypes = {
    authenticated: PropTypes.bool.isRequired
  };

  renderMenu() {
    const { authenticated, business, location } = this.props;
    const tgAdmin = location.pathname.toLowerCase().startsWith("/tg-admin");

    if (tgAdmin) {
      return <AdminNav />;
    }

    if (authenticated) {
      return <SignedInNav />;
    }

    return <SignedOutNav />;
  }

  render() {
    const { authenticated } = this.props;
    return (
      <div>
        {this.renderMenu()}
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const { authenticated } = state.auth;
  const { business } = state.business;
  return { authenticated, business };
};

export default connect(mapStateToProps, actions)(MainMenu);

import { SET_PICKLIST_FILTER } from 'Constants/redux';

const INITIAL_STATE = {
  filter: {
    query: '',
    sectionType: 'inventory',
    sortUp: true
  },
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_PICKLIST_FILTER:
      return {
        ...state,
        filter: action.payload.filter,
      };
    default:
      return state;
  }
};

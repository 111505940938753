import React from "react";
import { PrintCircle } from "Utils/SvgIcons";
import classnames from "classnames";

const PrintLink = props => (
  <a
    className={classnames({
      [props.className]: true,
      noMobile: true
    })}
    onClick={() => {
      window.print();
      return false;
    }}
  >
    {props.children}
    <PrintCircle />
  </a>
);
PrintLink.defaultProps = {
  className: "btnIcon"
};

export default PrintLink;

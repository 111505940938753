import {
  FETCH_MAINTENANCE_RECORD_REQUEST,
  UPDATE_MAINTENANCE_RECORD_REQUEST,
  FETCH_MAINTENANCE_RECORD_FAILURE,
  UPDATE_MAINTENANCE_RECORD_FAILURE,
  FETCH_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS,
  UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE,
  SET_MAINTENANCE_RECORD_PRIORITY,
  SET_MAINTENANCE_RECORD_NOTES,
  UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS,
  GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE,
  CREATE_VENDOR_REQUEST,
  CREATE_VENDOR_REQUEST_SUCCESS,
  CREATE_VENDOR_REQUEST_FAILURE,
  SHOULD_ADD_VENDOR,
  SHOULD_ADD_VENDOR_COMPLETE,
  SHOULD_CREATE_VENDOR,
  SHOULD_CREATE_VENDOR_COMPLETE,
  SELECTED_VENDOR_FOR_WORK_ORDER,
  SHOULD_SHOW_UPDATING_VIEW,
  SHOULD_SHOW_UPDATING_VIEW_COMPLETE,
  HOVER_DOCUMENT,
  UNHOVER_DOCUMENT,
  RETURN_TO_INVENTORY_BULK_REQUEST,
  RETURN_TO_INVENTORY_BULK_SUCCESS,
  RETURN_TO_INVENTORY_BULK_FAILURE,
  CREATE_WORK_ORDER_REQUEST,
  CREATE_WORK_ORDER_SUCCESS,
  CREATE_WORK_ORDER_FAILURE,
  UPDATE_WORK_ORDER_REQUEST,
  UPDATE_WORK_ORDER_SUCCESS,
  UPDATE_WORK_ORDER_FAILURE,
  REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST,
  REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS,
  REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE,
} from 'Constants/redux';
import { getIndex } from 'HelperFunctions/formHelpers';
import { RESET_SELECTED_VENDOR } from '../constants/redux';

const INITIAL_STATE = {
  selectedMaintenanceRecord: {
    workOrders: [],
    quantity: undefined,
    priority: undefined,
    maintainable: {
      name: undefined,
      pictures: [],
    },
    rentalId: undefined,
    documents: [],
  },
  loading: false,
  loadingAddDocument: false,
  loadingVendorList: false,
  loadingCreateVendor: false,
  availableVendorList: [],
  shouldCreateVendor: false,
  shouldAddVendor: false,
  shouldShowUpdatingView: false,
  loadingReturnInventorySerialized: false,
  errors: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_MAINTENANCE_RECORD_REQUEST:
    case UPDATE_MAINTENANCE_RECORD_REQUEST:
    case REMOVE_MAINTENANCE_RECORD_ITEMS_REQUEST:
    case CREATE_WORK_ORDER_REQUEST:
    case UPDATE_WORK_ORDER_REQUEST:
      return { ...state, loading: true };
    case FETCH_MAINTENANCE_RECORD_FAILURE:
    case UPDATE_MAINTENANCE_RECORD_FAILURE:
    case REMOVE_MAINTENANCE_RECORD_ITEMS_FAILURE:
    case CREATE_WORK_ORDER_FAILURE:
    case UPDATE_WORK_ORDER_FAILURE:
      return { ...state, loading: false };
    case FETCH_MAINTENANCE_RECORD_SUCCESS:
    case UPDATE_MAINTENANCE_RECORD_QUANTITY_SUCCESS:
    case UPDATE_MAINTENANCE_RECORD_SUCCESS:
    case REMOVE_MAINTENANCE_RECORD_ITEMS_SUCCESS:
      return {
        ...state,
        selectedMaintenanceRecord: action.response
          ? {
              ...state.selectedMaintenanceRecord,
              ...action.response.maintenanceRecord,
            }
          : {},
        loading: false,
      };
    case CREATE_WORK_ORDER_SUCCESS:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          workOrders: [
            ...state.selectedMaintenanceRecord.workOrders,
            action.response.workOrder,
          ],
        },
      };
    case UPDATE_WORK_ORDER_SUCCESS:
      const newWorkOrders = [...state.selectedMaintenanceRecord.workOrders];
      const index = getIndex(action.response.workOrder.id, newWorkOrders, 'id');
      newWorkOrders[index] = action.response.workOrder;
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          workOrders: newWorkOrders,
        },
      };
    case UPDATE_MAINTENANCE_RECORD_DOCUMENT_REQUEST:
      return {
        ...state,
        loadingAddDocument: true,
      };
    case UPDATE_MAINTENANCE_RECORD_DOCUMENT_SUCCESS:
      return {
        ...state,
        selectedMaintenanceRecord: action.response
          ? {
              ...state.selectedMaintenanceRecord,
              ...action.response.maintenanceRecord,
            }
          : {},
        loadingAddDocument: false,
      };
    case UPDATE_MAINTENANCE_RECORD_DOCUMENT_FAILURE:
      return {
        ...state,
        loadingAddDocument: false,
      };
    case SET_MAINTENANCE_RECORD_PRIORITY:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          priority: action.priority,
        },
      };
    case SET_MAINTENANCE_RECORD_NOTES:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          notes: action.notes,
        },
      };
    case GET_VENDORS_LIST_FOR_SLIDE_DRAWER:
      return {
        ...state,
        loadingVendorList: true,
      };
    case GET_VENDORS_LIST_FOR_SLIDE_DRAWER_SUCCESS:
      return {
        ...state,
        // availableVendorList: [...state.availableVendorList, ...action.response.names],
        availableVendorList: [...action.response.names],
        loadingVendorList: false,
      };
    case GET_VENDORS_LIST_FOR_SLIDE_DRAWER_FAILURE:
      return {
        ...state,
        loadingVendorList: false,
      };
    case CREATE_VENDOR_REQUEST:
      return {
        ...state,
        loadingCreateVendor: true,
      };
    case CREATE_VENDOR_REQUEST_SUCCESS:
      return {
        ...state,
        availableVendorList: [...state.availableVendorList, action.response],
        loadingCreateVendor: false,
      };
    case CREATE_VENDOR_REQUEST_FAILURE:
      return {
        ...state,
        loadingCreateVendor: false,
      };
    case SHOULD_ADD_VENDOR:
      return {
        ...state,
        shouldAddVendor: true,
        shouldCreateVendor: false,
      };
    case SHOULD_ADD_VENDOR_COMPLETE:
      return {
        ...state,
        shouldAddVendor: false,
      };
    case SHOULD_CREATE_VENDOR:
      return {
        ...state,
        shouldCreateVendor: true,
      };
    case SHOULD_CREATE_VENDOR_COMPLETE:
      return {
        ...state,
        shouldCreateVendor: false,
      };
    case SELECTED_VENDOR_FOR_WORK_ORDER:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          vendor: action.response,
        },
      };
    case SHOULD_SHOW_UPDATING_VIEW:
      return {
        ...state,
        shouldShowUpdatingView: true,
      };
    case SHOULD_SHOW_UPDATING_VIEW_COMPLETE:
      return {
        ...state,
        shouldShowUpdatingView: false,
      };
    case HOVER_DOCUMENT:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          documents: setHoverForDocument(
            state.selectedMaintenanceRecord.documents,
            action.index,
            true
          ),
        },
      };
    case UNHOVER_DOCUMENT:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          documents: setHoverForDocument(
            state.selectedMaintenanceRecord.documents,
            action.index,
            false
          ),
        },
      };
    case RETURN_TO_INVENTORY_BULK_REQUEST:
      return {
        ...state,
        loadingReturnInventorySerialized: true,
      };
    case RETURN_TO_INVENTORY_BULK_SUCCESS:
      return {
        ...state,
        loadingReturnInventorySerialized: false,
        selectedMaintenanceRecord: action.response
          ? {
              ...state.selectedMaintenanceRecord,
              ...action.response.maintenanceRecord,
            }
          : {},
      };
    case RETURN_TO_INVENTORY_BULK_FAILURE:
      return {
        ...state,
        loadingReturnInventorySerialized: false,
      };
    case RESET_SELECTED_VENDOR:
      return {
        ...state,
        selectedMaintenanceRecord: {
          ...state.selectedMaintenanceRecord,
          vendor: undefined,
        },
      };
    default:
      return state;
  }
};

const setHoverForDocument = (documents, index, hoverState) => {
  const newDocuments = [...documents];
  newDocuments[index].hover = hoverState;
  return newDocuments;
};

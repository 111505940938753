import service from 'Api/service';
import { ROOT_URL, saveError } from 'Api';
import {
  FETCH_NOTIFICATION_ALL_COUNT_REQUEST,
  FETCH_NOTIFICATION_ALL_COUNT_SUCCESS,
  FETCH_NOTIFICATION_ALL_COUNT_FAILURE,
} from 'Constants/redux';

export function fetchNotificationAllCount(multilocationAllLocations = false) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_NOTIFICATION_ALL_COUNT_REQUEST });
    service
      .get(`${ROOT_URL}/notifications/all_count`, {
        ...(multilocationAllLocations && { multilocation_all_locations: true }),
      })
      .then((response) => {
        return dispatch({
          response: response.data,
          type: FETCH_NOTIFICATION_ALL_COUNT_SUCCESS,
        });
      })
      .catch((error) => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_NOTIFICATION_ALL_COUNT_FAILURE,
        });
      });
  };
}

import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  SEARCH_CATEGORIES_REQUEST,
  SEARCH_CATEGORIES_SUCCESS,
  SEARCH_CATEGORIES_FAILURE,
  FETCH_INVENTORY_CATEGORIES_REQUEST,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES_FAILURE
} from "Constants/redux";

const INITIAL_STATE = {
  categories: [],
  itemsCategories: [],
  addOnsCategories: [],
  accessoriesCategories: [],
  loading: false
};

function getCategoriesState(categories) {
  const newCategories = categories.map((category, index) => {
    return {
      ...getCategoryState(category),
      lastSubcategoryKey: category.subCategories.length
    };
  });

  return newCategories;
}

function getCategoryState(category) {
  const newSubCategories = category.subCategories.map((subCategory, index) => {
    return {
      key: index + 1,
      ...subCategory
    };
  });

  return {
    ...category,
    subCategories: newSubCategories
  };
}

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_CATEGORIES_REQUEST:
    case SEARCH_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_CATEGORIES_FAILURE:
    case SEARCH_CATEGORIES_FAILURE:
      return { ...state, loading: false };
    case FETCH_CATEGORIES_SUCCESS:
    case SEARCH_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: getCategoriesState(action.response.data.categories),
        loading: false
      };
    case FETCH_INVENTORY_CATEGORIES_REQUEST:
      return { ...state, loading: true };

    case FETCH_INVENTORY_CATEGORIES_FAILURE:
      return { ...state, loading: false };
    case FETCH_INVENTORY_CATEGORIES_SUCCESS:
      return {
        ...state,
        [`${action.productType}Categories`]: action.response.data,
        loading: false
      };
    default:
      return state;
  }
};

import * as actionTypes from 'Constants/redux';

function RolesReducer(
  state = {
    roles: [],
    roleActionDrawerOpen: false,
    selectedRole: undefined,
    loading: false,
    isCreatingRole: false,
    isEditingRole: false,
    deleteDialogOpen: false,
    errors: [],
  },
  action
) {
  switch (action.type) {
    case actionTypes.FETCH_ROLES:
    case actionTypes.CREATE_ROLE:
    case actionTypes.UPDATE_ROLE:
    case actionTypes.DELETE_ROLE:
      return {
        ...state,
        loading: true,
      };
    case actionTypes.FETCH_ROLES_SUCCESS:
      return {
        ...state,
        roles: action.payload,
        loading: false,
      };
    case actionTypes.TOGGLE_DRAWER:
      return {
        ...state,
        roleActionDrawerOpen: !state.roleActionDrawerOpen,
        isCreatingRole: !state.roleActionDrawerOpen
          ? state.isCreatingRole
          : false,
        isEditingRole: !state.roleActionDrawerOpen
          ? state.isEditingRole
          : false,
        errors: [],
      };
    case actionTypes.SET_CREATING_ROLE_STATUS:
      return {
        ...state,
        isCreatingRole: action.status,
      };
    case actionTypes.SET_EDITING_ROLE_STATUS:
      return {
        ...state,
        isEditingRole: action.status,
      };
    case actionTypes.CREATE_ROLE_SUCCESS:
      return {
        ...state,
        loading: false,
        roles: [...state.roles, action.role],
      };
    case actionTypes.CREATE_ROLE_FAIL:
    case actionTypes.UPDATE_ROLE_FAIL:
    case actionTypes.DELETE_ROLE_FAIL:
      return {
        ...state,
        loading: false,
        errors: action.errors,
      };
    case actionTypes.SELECT_ROLE:
      return {
        ...state,
        selectedRole: action.role,
      };
    case actionTypes.UPDATE_ROLE_SUCCESS:
      let newRoles = state.roles.map((role) =>
        role.id === action.role.id ? action.role : role
      );
      return {
        ...state,
        roles: newRoles,
      };
    case actionTypes.TOGGLE_DELETE_DIALOG:
      return {
        ...state,
        deleteDialogOpen: !state.deleteDialogOpen,
        selectedRole: !state.deleteDialogOpen ? state.selectedRole : undefined,
      };
    case actionTypes.DELETE_ROLE_SUCCESS:
      return {
        ...state,
        roles: state.roles.filter((role) => role.id != action.deletedRoleId),
        selectedRole: undefined,
        loading: false,
      };
    default:
      return state;
  }
}

export default RolesReducer;

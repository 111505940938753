import { saveError } from "Api";
import service from "Api/service";
import { arraysEqual } from "HelperFunctions/general";
import {
  REQUEST_ALERTS,
  RECEIVE_ALERTS,
  BULK_READ_ALERTS
} from "Constants/redux";

const ROOT_URL = process.env.REACT_APP_API_DOMAIN + "/api";

const receiveAlerts = (dispatch, alerts) => {
  dispatch({
    type: RECEIVE_ALERTS,
    payload: alerts
  });
};

export const requestAlerts = () => {
  return (dispatch, getState) => {
    dispatch({ type: REQUEST_ALERTS });
    service
      .get(`${ROOT_URL}/alerts/quote`)
      .then(response => {
        receiveAlerts(dispatch, response.data.alerts);
      })
      .catch(error => {
        saveError(error, getState());
      });
  };
};

export const bulkReadAlerts = alertIds => {
  return (dispatch, getState) => {
    if (!arraysEqual(getState().alerts.alertIds, alertIds)) {
      dispatch({ type: BULK_READ_ALERTS, alertIds: alertIds });
      service
        .post(`${ROOT_URL}/alerts/bulk_read`, {
          ids_to_be_read: alertIds
        })
        .then(response => {
          receiveAlerts(dispatch, response.data.alerts);
        })
        .catch(error => {
          saveError(error, getState());
        });
    }
  };
};

function shouldFetchAlerts(state) {
  const alerts = state.alerts;
  if (!alerts) {
    return true;
  } else if (alerts.isFetching) {
    return false;
  } else {
    return alerts.shouldRefresh;
  }
}

export function fetchAlertsIfNeeded() {
  return (dispatch, getState) => {
    if (shouldFetchAlerts(getState())) {
      return dispatch(requestAlerts());
    }
  };
}

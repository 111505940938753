import React from "react";
import { connect } from "react-redux";
import * as actions from "Actions";
import { Settings } from "Utils/SvgIcons";
import { FormattedTime } from "react-intl";

class RouteTerminal extends React.Component {
  state = {
    routeTerminal: {
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      locale: "",
      postalCode: "",
      country: ""
    }
  };

  UNSAFE_componentWillMount() {
    const { routeTerminal } = this.props;
    this.setState({
      routeTerminal: routeTerminal
    });
  }


  render() {
    const { routeTerminal } = this.state;
    const { leg } = this.props;
    return (
      <div className="accordian">
        <a className="btnLink config" onClick={this.toggleEditing}>
          <Settings />
        </a>
        <div className="header">
          <div>
            <div className="details">
              <p className="routeTerminal">
                <strong>
                  {routeTerminal?.streetAddress1}{" "}
                  {routeTerminal?.streetAddress2 &&
                  `# ${routeTerminal?.streetAddress2}`}{" "}
                  {routeTerminal?.city}
                  {", "}
                  {routeTerminal?.locale} {routeTerminal?.postal_code}
                  {", "}
                  {routeTerminal?.country}
                </strong>
                <span className="routeTerminalType">({leg ? 'End' : 'Start'}: Warehouse)</span>
              </p>
              {leg && (
                <label className="routeTimeArea">
                  <span className="highlight">
                    (Drive Time: {leg.duration})
                  </span>
                  Approx. Arrival: <FormattedTime value={leg.arrivalTime} />
                </label>
              )}

            </div>
          </div>
        </div>

      </div>
    );
  }
}

export default connect(null, actions)(RouteTerminal);

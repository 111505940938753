import React from "react";
import { Link } from "react-router-dom";
import { AddCircle } from "Utils/SvgIcons";
import ConversationCard from "./MobileConversationCard";

const MobileConversations = props => {
  const { conversations, newConversationLink, forRental } = props;
  return (
    <div>
      <Link className="btn" to={newConversationLink}>
        New Message
      </Link>
      <h1>Messages</h1>
      <div className="filter">
        <input type="search" value="" />
      </div>
      <section>
        <table className="grid convItemList">
          <thead>
            <tr>
              <th>Contact</th>
              <th className="medSm">Time</th>
            </tr>
          </thead>
          <tbody>
            {conversations.map((conversation, index) => {
              return (
                <ConversationCard
                  conversation={conversation}
                  key={index}
                  forRental={forRental}
                />
              );
            })}
          </tbody>
        </table>
      </section>
    </div>
  );
};

export default MobileConversations;

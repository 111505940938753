import { ROOT_URL, saveError } from 'Api';
import service from 'Api/service';
import {
  FETCH_RENTAL_STAFFS_REQUEST,
  FETCH_RENTAL_STAFFS_SUCCESS,
  FETCH_RENTAL_STAFFS_FAILURE,
  UPDATE_RENTAL_STAFF_FILTERS,
  CLEAR_RENTAL_STAFF_FILTERS,
  REFRESH_RENTAL_STAFF,
  CREATE_RENTAL_STAFF_ROUTES_FAILURE,
  CREATE_RENTAL_STAFF_ROUTES_REQUEST,
  CREATE_RENTAL_STAFF_ROUTES_SUCCESS,
} from 'Constants/redux';

import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

import converter from 'json-style-converter/es5';
import { objectToQueryString } from 'HelperFunctions/formHelpers';
import pickBy from 'lodash/pickBy';

import {
  setErrors,
  openSuccessSnackBar,
} from 'Actions/dashboardActionCreators';

export const fetchRentalStaffs = (data) => {
  return (dispatch, getState) => {
    dispatch(openLoadingSpinner('Fetching services...'));
    dispatch({ type: FETCH_RENTAL_STAFFS_REQUEST });
    const filter_service_tickets = objectToQueryString(
      converter.camelToSnakeCase(pickBy(getState().rentalStaff.filters)),
      'filter_service_tickets'
    );

    const route = !!filter_service_tickets
      ? `${ROOT_URL}/rental_staffs?rental_id=${data.rental_id}&${filter_service_tickets}`
      : `${ROOT_URL}/rental_staffs?rental_id=${data.rental_id}`;
    service
      .get(route)
      .then((response) => {
        dispatch({
          response: response.data,
          type: FETCH_RENTAL_STAFFS_SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_STAFFS_FAILURE,
        });
      })
      .finally(() => {
        dispatch(closeLoadingSpinner());
      });
  };
};

export const clearFilters = () => {
  return (dispatch) => {
    dispatch({
      type: CLEAR_RENTAL_STAFF_FILTERS,
    });
  };
};

export const searchRentalStaff = (name, value) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_RENTAL_STAFF_FILTERS,
      payload: { [name]: value },
    });
  };
};

export const fetchRentalStaffsPaginated = (data) => {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_RENTAL_STAFFS_REQUEST });
    const route = `${ROOT_URL}/rental_staffs/paginated?rental_id=${data.rental_id}`;
    service
      .post(route, {
        query: data.query || '',
      })
      .then((response) => {
        dispatch({
          response: response.data,
          type: FETCH_RENTAL_STAFFS_SUCCESS,
        });
      })
      .catch((error) => {
        console.error(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_RENTAL_STAFFS_FAILURE,
        });
      });
  };
};

export const createRentalStaffRoutes = (id) => {
  return (dispatch, getState) => {
    dispatch({ type: CREATE_RENTAL_STAFF_ROUTES_REQUEST });

    const route = `${ROOT_URL}/rental_staffs/${id}/create_routes`;

    dispatch(openLoadingSpinner('Setting service routes...'));

    return service
      .post(route)
      .then((response) => {
        dispatch({
          response: response.data,
          type: CREATE_RENTAL_STAFF_ROUTES_SUCCESS,
        });

        dispatch(closeLoadingSpinner());

        dispatch(
          openSuccessSnackBar('Service routes were created successfully.', 5)
        );
      })
      .catch((error) => {
        saveError(error, getState());

        dispatch(setErrors(error.response.data));

        dispatch(closeLoadingSpinner());

        dispatch({
          error,
          type: CREATE_RENTAL_STAFF_ROUTES_FAILURE,
        });
      });
  };
};

// Refreshes specific rental staff with provided data.
// ! No API request is made.
// @param [Object] updatedRentalStaff data to be updated on the rentalStaff store.
export const refreshRentalStaff = (updatedRentalStaff) => (
  dispatch,
  getState
) => {
  dispatch({ type: REFRESH_RENTAL_STAFF, payload: updatedRentalStaff });
};

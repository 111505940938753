import { combineReducers } from 'redux';
import AvailabilityAlertReducer from './AvailabilityAlertReducer';
import VenueReducer from './VenueReducer';
import FormReducer from './RentalFormReducer';
import ItemDiscountsAlertReducer from './ItemDiscountsAlertReducer';
import DeliveryTypeReducer from './DeliveryTypeReducer';

const RentalFormReducer = combineReducers({
  availabilityAlert: AvailabilityAlertReducer,
  venue: VenueReducer,
  rentalForm: FormReducer,
  itemDiscountsAlert: ItemDiscountsAlertReducer,
  deliveryType: DeliveryTypeReducer,
});

export default RentalFormReducer;

import { ROOT_URL, saveError } from 'Api';
import {
  FETCH_INVENTORY_AVAILABILITY_REQUEST,
  FETCH_INVENTORY_AVAILABILITY_SUCCESS,
} from 'Constants/redux';
import service from 'Api/service';
import { catchError, map, switchMap } from 'rxjs/operators';
import { from } from 'rxjs';
import {
  fetchInventoryAvailabilitySuccess,
  fetchInventoryAvailabilityFailure,
} from 'Actions/AvailabilityActions';
import converter from 'json-style-converter/es5';
import { ofType } from 'redux-observable';
import Rx from 'rxjs/Rx';

// TODO: Catch error
const fetchInventoryAvailabilityEpic = (action$) =>
  action$.ofType(FETCH_INVENTORY_AVAILABILITY_REQUEST).mergeMap((action) => {
    const { startDate, endDate } = action.requestPayload;

    const payload = converter.camelToSnakeCase(action.requestPayload);

    if (!startDate || !endDate) {
      return Rx.Observable.of(
        fetchInventoryAvailabilityFailure('No date interval provided')
      );
    }

    return Rx.Observable.fromPromise(
      service.post(`${ROOT_URL}/inventories/available_in_period`, {
        ...payload,
        ledger: true,
      })
    )
      .map((res) => fetchInventoryAvailabilitySuccess(res.data.availability))
      .catch((error) =>
        Rx.Observable.of(fetchInventoryAvailabilityFailure(error))
      );
  });

export default fetchInventoryAvailabilityEpic;

import React, { Component } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import PageView from './PageView';
import BreakView from './BreakView';
import { FirstPage, PreviousPage, NextPage, LastPage } from './SvgIcons';

export default class PaginationBoxView extends Component {
  static propTypes = {
    pageCount: PropTypes.number.isRequired,
    pageRangeDisplayed: PropTypes.number.isRequired,
    marginPagesDisplayed: PropTypes.number.isRequired,
    previousLabel: PropTypes.node,
    nextLabel: PropTypes.node,
    firstLabel: PropTypes.node,
    lastLabel: PropTypes.node,
    breakLabel: PropTypes.node,
    onPageChange: PropTypes.func,
    initialPage: PropTypes.number,
    forcePage: PropTypes.number,
    disableInitialCallback: PropTypes.bool,
    className: PropTypes.string,
    containerClassName: PropTypes.string,
    pageClassName: PropTypes.string,
    pageLinkClassName: PropTypes.string,
    activeClassName: PropTypes.string,
    previousLinkClassName: PropTypes.string,
    nextLinkClassName: PropTypes.string,
    disabledClassName: PropTypes.string,
    breakClassName: PropTypes.string,
    editable: PropTypes.bool,
    navigateToEnds: PropTypes.bool
  };

  static defaultProps = {
    pageRangeDisplayed: 2,
    marginPagesDisplayed: 3,
    activeClassName: 'selected',
    previousLinkClassName: 'previous',
    nextLinkClassName: 'next',
    firstLinkClassName: 'first',
    lastLinkClassName: 'last',
    previousLabel: 'Previous',
    nextLabel: 'Next',
    firstLabel: 'First',
    lastLabel: 'Last',
    breakLabel: '...',
    disabledClassName: 'disabled',
    disableInitialCallback: false,
    editable: true,
    navigateToEnds: true,
  };

  constructor(props) {
    super(props);

    this.state = {
      pageCount: props.pageCount ? props.pageCount : 10,
      selected: props.initialPage
        ? props.initialPage
        : props.forcePage
        ? props.forcePage
        : 0,
    };
  }

  componentDidMount() {
    // Call the callback with the initialPage item:
    if (
      typeof this.props.initialPage !== 'undefined' &&
      !this.props.disableInitialCallback
    ) {
      this.callCallback(this.props.initialPage);
    }
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    const newState = {}
    if (typeof nextProps.forcePage !== 'undefined') {
      newState.selected = nextProps.forcePage;
    }
    if (typeof nextProps.pageCount !== 'undefined') {
      newState.pageCount = nextProps.pageCount;
    }
    if (
      typeof nextProps.forcePage !== 'undefined' ||
      typeof nextProps.pageCount !== 'undefined'
    ) {
      this.setState(newState);
    }
  }

  handlePreviousPage = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (this.state.selected > 0) {
      this.handlePageSelected(this.state.selected - 1, evt, 'previous');
    }
  };
  handleFirstPage = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    this.handlePageSelected(0, evt, 'first');
  };

  handleNextPage = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (this.state.selected < this.state.pageCount - 1) {
      this.handlePageSelected(this.state.selected + 1, evt, 'next');
    }
  };
  handleLastPage = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    this.handlePageSelected(this.state.pageCount - 1, evt, 'last');
  };

  handlePageChange = (evt) => {
    const { value } = evt.target;
    if (value <= this.state.pageCount) {
      const selected = value > 0 ? value - 1 : value;
      this.setState({ selected });
    }
  };

  handlePageChangeSubmit = (evt) => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (evt.keyCode === 13) {
      this.callCallback(this.state.selected, 'manual');
    }
  };

  handlePageSelected = (selected, evt, navigation = 'manual') => {
    evt.preventDefault ? evt.preventDefault() : (evt.returnValue = false);
    if (this.state.selected === selected) return;
    this.setState({ selected: selected });

    // Call the callback with the new selected item:
    this.callCallback(selected, navigation);
  };

  callCallback = (selectedItem, navigation) => {
    if (
      typeof this.props.onPageChange !== 'undefined' &&
      typeof this.props.onPageChange === 'function'
    ) {
      if (selectedItem !== '') {
        this.props.onPageChange({
          selected: selectedItem,
          navigation: navigation,
        });
      }
    }
  };

  simplePagination = () => {
    const { selected, pageCount } = this.state;
    return (
      <div>
        {this.props.editable && (
          <input
            type="text"
            value={selected === '' ? selected : selected + 1}
            onChange={this.handlePageChange}
            onKeyUp={this.handlePageChangeSubmit}
          />
        )}
        {!this.props.editable && (
          <span>{selected === '' ? selected : selected + 1}</span>
        )}
        {' of '}
        <span>{pageCount}</span>
      </div>
    );
  };

  pagination = () => {
    let items = {};

    if (this.state.pageCount <= this.props.pageRangeDisplayed) {
      for (let index = 0; index < this.state.pageCount; index++) {
        items['key' + index] = (
          <PageView
            onClick={(event) =>
              this.handlePageSelected(index, event, 'pagination')
            }
            selected={this.state.selected === index}
            pageClassName={this.props.pageClassName}
            pageLinkClassName={this.props.pageLinkClassName}
            activeClassName={this.props.activeClassName}
            page={index + 1}
          />
        );
      }
    } else {
      let leftSide = this.props.pageRangeDisplayed / 2;
      let rightSide = this.props.pageRangeDisplayed - leftSide;

      if (
        this.state.selected >
        this.state.pageCount - this.props.pageRangeDisplayed / 2
      ) {
        rightSide = this.state.pageCount - this.state.selected;
        leftSide = this.props.pageRangeDisplayed - rightSide;
      } else if (this.state.selected < this.props.pageRangeDisplayed / 2) {
        leftSide = this.state.selected;
        rightSide = this.props.pageRangeDisplayed - leftSide;
      }

      let index;
      let page;
      let breakView;

      for (index = 0; index < this.state.pageCount; index++) {
        page = index + 1;

        let pageView = (
          <PageView
            onClick={this.handlePageSelected.bind(null, index)}
            selected={this.state.selected === index}
            pageClassName={this.props.pageClassName}
            pageLinkClassName={this.props.pageLinkClassName}
            activeClassName={this.props.activeClassName}
            page={index + 1}
          />
        );

        if (page <= this.props.marginPagesDisplayed) {
          items['key' + index] = pageView;
          continue;
        }

        if (page > this.state.pageCount - this.props.marginPagesDisplayed) {
          items['key' + index] = pageView;
          continue;
        }

        if (
          index >= this.state.selected - leftSide &&
          index <= this.state.selected + rightSide
        ) {
          items['key' + index] = pageView;
          continue;
        }

        let keys = Object.keys(items);
        let breakLabelKey = keys[keys.length - 1];
        let breakLabelValue = items[breakLabelKey];

        if (this.props.breakLabel && breakLabelValue !== breakView) {
          breakView = (
            <BreakView
              breakLabel={this.props.breakLabel}
              breakClassName={this.props.breakClassName}
            />
          );

          items['key' + index] = breakView;
        }
      }
    }

    return items;
  };

  render() {
    let disabled = this.props.disabledClassName;

    const firstClasses = classNames(this.props.firstClassName, {
      [disabled]: this.state.selected === 0,
    });
    const previousClasses = classNames(this.props.previousClassName, {
      [disabled]: this.state.selected === 0,
    });
    const lastClasses = classNames(this.props.lastClassName, {
      [disabled]: this.state.selected === this.state.pageCount - 1,
    });
    const nextClasses = classNames(this.props.nextClassName, {
      [disabled]: this.state.selected === this.state.pageCount - 1,
    });

    if (!this.props.pageCount) {
      return null;
    }

    return (
      <div className={this.props.className}>
        {this.state.pageCount > 1 && (
          <ul className={this.props.containerClassName}>
            {this.props.navigateToEnds && (
              <li className={firstClasses}>
                <a
                  onClick={this.handleFirstPage}
                  className={this.props.firstLinkClassName}
                  tabIndex="0"
                  onKeyPress={this.handleFirstPage}
                >
                  <FirstPage />
                </a>
              </li>
            )}
            <li className={previousClasses}>
              <a
                onClick={this.handlePreviousPage}
                className={this.props.previousLinkClassName}
                tabIndex="0"
                onKeyPress={this.handlePreviousPage}
              >
                <PreviousPage />
              </a>
            </li>
            {this.simplePagination()}
            <li className={nextClasses}>
              <a
                onClick={this.handleNextPage}
                className={this.props.nextLinkClassName}
                tabIndex='0'
                onKeyPress={this.handleNextPage}
              >
                <NextPage />
              </a>
            </li>
            {this.props.navigateToEnds && (
              <li className={lastClasses}>
                <a
                  onClick={this.handleLastPage}
                  className={this.props.lastLinkClassName}
                  tabIndex='0'
                  onKeyPress={this.handleLastPage}
                >
                  <LastPage />
                </a>
              </li>
            )}
          </ul>
        )}
      </div>
    );
  }
}

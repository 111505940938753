import { TOGGLE_ITEMS_MODAL } from 'Constants/redux';
import { RentalFormActionTypes } from './types';
import AppState from '../../types/AppState';
import { ThunkAction } from 'redux-thunk';

export const toggleItemsModal = (
  status: boolean
): ThunkAction<void, AppState, unknown, RentalFormActionTypes> => (
  dispatch
) => {
  dispatch({
    type: TOGGLE_ITEMS_MODAL,
    status: status,
  });
};

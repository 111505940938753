import React from 'react';
import converter from 'json-style-converter/es5';
import * as actions from 'Actions';
import { connect } from 'react-redux';
import classNames from 'classnames';
import Checkbox from 'Components/utils/Checkbox';
import SettingsProgress from 'Components/signup/SettingsProgress';
import { parseQuery } from 'HelperFunctions/QueryString';
import PhoneInput from 'Utils/PhoneInput';
import validations from 'HelperFunctions/validations';
import { pricingTierGetter } from '../../helper_functions/pricingTierGetter';

class SignUp extends React.Component {
  state = {
    employee: {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: {
        office: '',
        cell: '',
        fax: '',
      },
      physicalAddress: {
        streetAddress1: '',
        streetAddress2: '',
        city: '',
        locale: '',
        postalCode: '',
        country: '',
      },
      role: 'owner',
      password: '',
      passwordConfirmation: '',
      softwareTier: 'all',
    },
    file: null,
  };

  handleChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const newEmployee = Object.assign(this.state.employee, { [name]: value });
    this.setState({
      employee: newEmployee,
    });
  };

  componentDidMount() {
    pricingTierGetter(
      this.props.getPricingTierStandard,
      this.props.getPricingTierSelect,
      this.props.getPricingTierPremier,
      this.props.getPricingTierEssentials,
    );
    const { type } = parseQuery(window.location.search);
    if (type === 'services') {
      this.setState({
        employee: {
          ...this.state.employee,
          softwareTier: 'services',
        },
      });
    } else if (type === 'rentals') {
      this.setState({
        employee: {
          ...this.state.employee,
          softwareTier: 'all',
        },
      });
    }
  }

  handleAddressChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const newAddress = Object.assign(this.state.employee.physicalAddress, {
      [name]: value,
    });
    const newEmployee = Object.assign(this.state.employee, {
      physicalAddress: newAddress,
    });
    this.setState({
      employee: newEmployee,
    });
  };

  handlePhoneChange = (event) => {
    const target = event.target;
    const name = target.name;
    const value = target.value;
    const newPhone = Object.assign(this.state.employee.phoneNumber, {
      [name]: value,
    });
    const newEmployee = Object.assign(this.state.employee, {
      phoneNumber: newPhone,
    });
    this.setState({
      employee: newEmployee,
    });
  };

  buildRailsObject = () => {
    const employee = this.state.employee;

    let newEmployee = {
      firstName: employee.firstName,
      lastName: employee.lastName,
      email: employee.email,
      phoneNumberAttributes: employee.phoneNumber,
      role: 'owner',
      softwareTier: employee.softwareTier,
      physicalAddressAttributes: {
        street_address_1: employee.physicalAddress.streetAddress1,
        street_address_2: employee.physicalAddress.streetAddress2,
        city: employee.physicalAddress.city,
        locale: employee.physicalAddress.locale,
        postal_code: employee.physicalAddress.postalCode,
        country: employee.physicalAddress.country,
      },
    };
    newEmployee = Object.assign(
      newEmployee,
      employee.password && { password: employee.password },
      employee.passwordConfirmation && {
        password_confirmation: employee.passwordConfirmation,
      }
    );

    return newEmployee;
  };

  validate = () => {
    let errors = {};
    const { employee } = this.state;
    if (validations.required(employee.firstName, true)) {
      errors.first_name = 'First name cannot be blank';
    }
    if (validations.required(employee.lastName, true)) {
      errors.last_name = 'Last name cannot be blank';
    }
    if (validations.required(employee.phoneNumber.office, true)) {
      errors['phone_number.office'] = 'Phone number cannot be blank';
    }
    if (validations.required(employee.email, true)) {
      errors.email = 'Email cannot be blank';
    }
    if (validations.required(employee.password, true)) {
      errors.password = 'Password cannot be blank';
    }
    if (validations.required(employee.passwordConfirmation, true)) {
      errors.password_confirmation = 'Password confirmation cannot be blank';
    }
    return errors;
  };

  handleSubmit = (event) => {
    event.preventDefault();
    const errors = this.validate();
    console.log(errors);
    if (Object.keys(errors).length > 0) {
      this.props.setErrors(errors);
    } else {
      const { reviewNewUser } = this.props;
      const formattedData = converter.camelToSnakeCase(this.buildRailsObject());
      reviewNewUser(formattedData);
      // createUser(formattedData);
    }
  };

  render() {
    const { employee } = this.state;
    const { errors } = this.props;
    return (
      <div className='signUpWrapper'>
        <header>
          <div>
            <h1>
              Get Started
              <br />
              <strong>with TapGoods</strong>
            </h1>
            <div className='optOut'>
              Not quite ready?
              {'  '}
              <a href='https://www.tapgoods.com/pro/demo/' target='_blank'>
                See a demo
              </a>
              .
            </div>
          </div>

          <SettingsProgress step='start' />

          <div className='quote'>
            <p>
              We now get twice as much done! TapGoods has helped our business
              move to the next level.
            </p>
            <label>
              Sonia Dominguez
              <br />
              DFW Lounge Rentals, Dallas, TX
            </label>
          </div>
        </header>
        <section className='signUp'>
          <form onSubmit={this.handleSubmit} className='form'>
            <div className='fields'>
              <input
                name='firstName'
                type='text'
                placeholder='First Name'
                className={classNames({
                  med: true,
                  error: errors.first_name,
                })}
                value={employee.firstName}
                onChange={this.handleChange}
              />
              <input
                name='lastName'
                type='text'
                placeholder='Last Name'
                className={classNames({
                  med: true,
                  error: errors.last_name,
                })}
                value={employee.lastName}
                onChange={this.handleChange}
              />
              <PhoneInput
                name='office'
                type='text'
                placeholder='Phone Number'
                className={classNames({
                  error: errors['phone_number.office'],
                })}
                value={employee.phoneNumber.office}
                onChange={this.handlePhoneChange}
              />
            </div>
            <div className='fields'>
              <input
                name='email'
                type='text'
                className={classNames({ error: errors.email })}
                placeholder='User Email (used to sign in)'
                value={employee.email}
                onChange={this.handleChange}
              />
              <input
                name='password'
                type='password'
                placeholder='Set Password'
                className={classNames({
                  error: errors.password,
                })}
                value={employee.password}
                onChange={this.handleChange}
              />
              <input
                name='passwordConfirmation'
                type='password'
                placeholder='Confirm Password'
                className={classNames({
                  error: errors.password_confirmation,
                })}
                value={employee.passwordConfirmation}
                onChange={this.handleChange}
              />
            </div>
            <div className='actions'>
              <input
                id='create_my_account'
                className='btn full'
                type='submit'
                value='Create My Account'
              />
            </div>
          </form>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const { errors } = state.dashboard;
  return { errors };
};

export default connect(mapStateToProps, actions)(SignUp);

import {
  SET_TUTORIAL_PRODUCT_PROPS,
  SET_TUTORIAL_RENTAL_PROPS,
  SET_TUTORIAL_CUSTOMER_PROPS
} from "Constants/redux";

const INITIAL_STATE = {
  product: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    hourlyPrice: "",
    halfDayPrice: "",
    dailyPrice: "",
    weeklyPrice: "",
    monthlyPrice: "",
    subCategories: [],
    colors: [],
    suppliers: [],
    businessInfo: {},
    pictures: [],
    files: []
  },
  rental: {
    name: "",
    status: "",
    schedule_id: "",
    checkout: null,
    checkoutWindowEnd: null,
    checkout_time: null,
    checkin: null,
    checkinWindowStart: null,
    checkin_time: null,
    eventStart: null,
    eventStartTime: null,
    eventEnd: null,
    eventEndTime: null,
    taxExempt: false,
    depositRequired: false,
    rentalAgreementId: "",
    signatureRequired: false,
    rentalAgreementOptions: [],
    npsRequired: false,
    hasAgreement: true,
    manualDamageWaiver: false,
    depositType: "optional",
    expireType: "no_expire",
    customExpireDate: null,
    delivery_cost: "",
    deliveryType: "",
    delivery_address_location_name: "",
    delivery_address_street_address_1: "",
    delivery_address_street_address_2: "",
    delivery_address_city: "",
    delivery_address_locale: "",
    delivery_address_postal_code: "",
    delivery_address_country: "",
    rentalSetting: {
      hideEventDates: false,
      hideEventTimes: false,
      hideDeliveryInfo: false,
      hideDeliveryTimes: false,
      multipleDeliveryTickets: false
    },
    shouldRecalcDelivery: true,
    fees: [],
    last_fee_id: 0,
    expenses: [],
    salesTaxes: [],
    last_expense_id: 0,
    notes: [],
    last_note_id: 0,

    shelfEnabled: false,
    offShelfAt: null,
    onShelfAt: null,

    hasFlip: false,
    flipDateTime: null,
    flipNotes: "",

    deliveryTaxEnabled: true,

    customers: [],
    last_customer_id: 0,

    discounts: [],
    last_discount_id: 0,

    items: [],
    last_item_id: 0,

    rentalAccessories: [],
    last_rental_accessory_id: 0,

    rentalAddOns: [],
    last_rental_add_on_id: 0,

    rentalBundles: [],
    last_rental_bundle_id: 0,

    rentalStaffs: [],
    last_rental_staff_id: 0,

    documents: [],
    last_document_id: 0,
    employee_rental_relationship: null,
    damage_waiver_fee_total: "",

    allProductsForSubmit: {
      items: {},
      accessories: {},
      addons: {},
      bundles: {}
    }
  },
  customer: {
    firstName: "",
    lastName: "",
    title: "",
    notes: "",
    current_name: true,
    street_address_1: "",
    street_address_2: "",
    city: "",
    locale: "select_locale",
    company: "",
    postal_code: "",
    country: "",
    main_contact_address: true,
    email: "",
    primary_contact_email: true,
    office: "",
    extension: "",
    cell: "",
    fax: "",
    main_contact_number: true,
    rentals: [],
    last_rental_id: 0
  },
  loading: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case SET_TUTORIAL_PRODUCT_PROPS:
      const newProduct = {
        ...state.product,
        ...action.payload
      };
      return { ...state, product: newProduct };
    case SET_TUTORIAL_RENTAL_PROPS:
      const newRental = {
        ...state.rental,
        ...action.payload
      };
      return { ...state, rental: newRental };
    case SET_TUTORIAL_CUSTOMER_PROPS:
      const newCustomer = {
        ...state.customer,
        ...action.payload
      };
      return { ...state, customer: newCustomer };
    default:
      return state;
  }
};

import React from "react";
import { Link } from "react-router-dom";
import service from "Api/service";
import { RemoveCircle } from "Utils/SvgIcons";
import classnames from "classnames";
import ActionCable from "actioncable";
import {actioncableCheck} from "Actions"
import { getAuthToken, getBusinessId, getLocationId } from "Api";
import converter from "json-style-converter/es5";
import { connect } from "react-redux";

class TopBadge extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      signedUp: false,
      banking: false,
      inventory: false,
      firstRental: false,
      rentalSettings: false,
      getPaid: false,
      open: false
    };
  }

  componentDidMount() {
    this.reload();
  }

  UNSAFE_componentWillReceiveProps(nextProps) {
    if (nextProps.locationId != this.props.locationId) {
      this.reload();
    }
  }

  reload = () => {
    const authToken = getAuthToken();
    this.cable = ActionCable.createConsumer(
      `${process.env.REACT_APP_ACTION_CABLE}/cable?access-token=${
        authToken["access-token"]
      }&client=${authToken["client"]}&uid=${
        authToken["uid"]
      }&resource_type=employee&bid=${getBusinessId()}&lid=${getLocationId()}`
    );
    this.channel = this.cable.subscriptions.create(
      { channel: "BadgeCompleteChannel" },
      {
        connected: () => {},
        received: this.completeBadge
      }
    );
    actioncableCheck(this.cable)
    this.loadBadges();
  };

  completeBadge = badgeName => {
    const formattedBadgeName = converter.snakeToCamelCase(badgeName);
    this.setState({
      [formattedBadgeName]: true
    });
  };

  loadBadges = () => {
    const { updateHeader } = this.props;
    service
      .get(process.env.REACT_APP_API_DOMAIN + "/api/business/badges")
      .then(response => {
        const { badges } = response.data;
        const badgeNames = badges.map(badge => badge.name);
        this.setState({
          loading: false,
          banking: badgeNames.includes("banking"),
          inventory: badgeNames.includes("inventory"),
          rentalSettings: badgeNames.includes("rental_settings"),
          getPaid: badgeNames.includes("get_paid"),
          firstRental: badgeNames.includes("first_rental")
        });
        if (
          !(
            badgeNames.includes("banking") &&
            badgeNames.includes("inventory") &&
            badgeNames.includes("rental_settings") &&
            badgeNames.includes("get_paid") &&
            badgeNames.includes("first_rental")
          )
        ) {
          updateHeader("badges", true);
        } else {
          updateHeader("badges", false);
        }
      });
  };

  render() {
    const {
      banking,
      inventory,
      rentalSettings,
      getPaid,
      firstRental,
      loading
    } = this.state;
    const url = "/start/get-started";
    if (loading) {
      return null;
    }
    if (!(banking && inventory && rentalSettings && getPaid && firstRental)) {
      return (
        <div className="topBadges">
          <div>
            <h1>You're almost ready to start doing business!</h1>
            <Link
              to={url}
              target="_blank"
              className="signup complete"
              id="signup_badge"
            >
              Sign Up
            </Link>
            <Link
              to={url}
              target="_blank"
              className={classnames({
                inventory: true,
                complete: this.state.inventory
              })}
              id="inventory_badge"
            >
              Upload Inventory
            </Link>
            <Link
              to={url}
              target="_blank"
              className={classnames({
                settings: true,
                complete: this.state.rentalSettings
              })}
              id="rental_settings_badge"
            >
              Set Up Your Settings
            </Link>
            <Link
              to={url}
              target="_blank"
              className={classnames({
                merchant: true,
                complete: this.state.banking
              })}
              id="banking_badge"
            >
              Set Up a Merchant Account
            </Link>
            <Link
              to={url}
              target="_blank"
              className={classnames({
                renting: true,
                complete: this.state.firstRental
              })}
              id="first_rental_badge"
            >
              Start Renting
            </Link>
            <Link
              to={url}
              target="_blank"
              className={classnames({
                payments: true,
                complete: this.state.getPaid
              })}
              id="get_paid_badge"
            >
              Collect Some $$$!
            </Link>
            <a
              id="close_badges"
              className="btnLink delete"
              onClick={this.props.closeBadges}
            >
              Close
              <RemoveCircle />
            </a>
          </div>
        </div>
      );
    } else {
      return null;
    }
  }
}
const mapStateToProps = state => {
  const { location } = state.location;
  return { locationId: location.id };
};

export default connect(mapStateToProps)(TopBadge);

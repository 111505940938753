const getCellErrors = (errors, columnName) => {
  return errors.find((error) => error[columnName])?.[columnName] || '';
};

const accessoryErrors = {
  Name: 'Name',
  Country: 'Country',
  City: 'City',
  Street: 'Street Address',
  Locale: 'State',
  Postal: 'Postal Code',
  Email: 'Email Address',
  Last: 'Last Name',
  ID: 'Accessory ID',
  First: 'First Name',
  'Color(s)': 'Color(s)',
  Turnover: 'Turnover Unit',
  Height: 'Height (in)',
  'Height ft': 'Height (ft)',
  Length: 'Length (in)',
  'Length ft': 'Length (ft)',
  Width: 'Width (in)',
  'Width ft': 'Width (ft)',
  Weight: 'Weight',
  Buffer: 'Buffer',
  Quantity: 'Quantity',
  Material: 'Material',
  Warehouse: 'Warehouse location',
  Product: 'Products',
  Purchase: 'Wholesale Cost',
};

const buildErrorName = (splittedError) => {
  let columnError = splittedError[0];
  if (
    (columnError == 'Width' ||
      columnError == 'Length' ||
      columnError == 'Height') &&
    splittedError[1] == 'ft'
  ) {
    return `${columnError} ft`;
  }
  return columnError;
};

const accessoryCompoundErrorName = {
  Email: 2,
  Street: 3,
  Postal: 2,
  Last: 2,
  First: 2,
  Turnover: 2,
  'Width ft': 2,
  'Length ft': 2,
  'Height ft': 2,
  Purchase: 2,
};

const isColumnMatched = (columnName, requiredColumns) => {
  return requiredColumns.includes(columnName);
};

const parseErrors = (data = []) => {
  return data.map((error) => {
    let splittedError = error.split(' ');
    let columnError = buildErrorName(splittedError);
    let formattedError = {};
    formattedError[accessoryErrors[columnError]] = error
      .split(' ')
      .slice(accessoryCompoundErrorName[columnError] || 1)
      .join(' ');
    return formattedError;
  });
};

const countUnmatched = (columns = [], requiredColumns = []) => {
  return columns.reduce((count, curr) => {
    if (!requiredColumns.includes(curr)) {
      count++;
    }
    return count;
  }, 0);
};

const buildAccessoryFromJson = (rowData = {}) => {
  const rowDataFormatted = JSON.parse(rowData.json_data);
  return {
    ...rowDataFormatted,
    errors: parseErrors(rowDataFormatted.errors),
    id: rowData.id,
  };
};

export default {
  errors: accessoryErrors,
  getCellErrors,
  compoundErrorName: accessoryCompoundErrorName,
  parseErrors,
  buildFromJson: buildAccessoryFromJson,
  isColumnMatched,
  countUnmatched,
};

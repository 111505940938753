import { uniqBy } from 'lodash';

export const sortSerializedProducts = (direction, serializedProducts) => {
  return serializedProducts.sort(
    ({ checked: checkedA }, { checked: checkedB }) =>
      direction
        ? checkedA === checkedB
          ? 0
          : checkedA
          ? -1
          : 1
        : checkedA === checkedB
        ? 0
        : checkedA
        ? 1
        : -1
  );
};

export const searchSerializedProducts = (
  serialNumber,
  selected,
  serializedProducts
) => {
  if (!selected.find((serial) => serial.serialNumber === serialNumber)) {
    const newSerializedProducts = serializedProducts.map(
      (serializedProduct) => {
        if (serializedProduct.serialNumber == serialNumber) {
          serializedProduct.checked = true;
        }
        return serializedProduct;
      }
    );

    return newSerializedProducts;
  } else {
    return serializedProducts;
  }
};

export const handleResponse = (serializedProducts, item, checkObj, state) => {
  const rentalItemIds = item.rentalItems?.map((rentalItem) => rentalItem.id);

  serializedProducts = serializedProducts.map((serializedProduct) => {
    const serializedRentalItem = serializedProduct.serializedRentalItems.find(
      (serializedRentalItem) =>
        rentalItemIds?.includes(serializedRentalItem.rental_item_id)
    );
    serializedProduct.serializedRentalItem = serializedRentalItem;

    return serializedProduct;
  });

  serializedProducts = serializedProducts.map((serializedProduct) => {
    serializedProduct = {
      ...serializedProduct,
      notes: state.textValues['notes'],
      checked: !!rentalItemIds.includes(
        serializedProduct.serializedRentalItem?.rental_item_id
      ),
    };

    serializedProduct[checkObj] = serializedProduct?.usageWithUnitDetails?.map(
      (unit) => ({
        id: unit.unitId,
        label: unit.unitName,
        value: unit.usage,
        type: unit.unitType,
      })
    );

    return serializedProduct;
  });

  const selected = serializedProducts.filter((serializedProduct) =>
    rentalItemIds.includes(
      serializedProduct.serializedRentalItem?.rental_item_id
    )
  );

  const newSerializedProducts = uniqBy(
    [...state.serializedProducts, ...serializedProducts],
    (serializedProduct) => serializedProduct.serialNumber
  );
  let newSelected = uniqBy(
    [...state.selected, ...selected],
    (selected) => selected.serialNumber
  );

  return {
    newSerializedProducts: newSerializedProducts,
    newSelected: newSelected,
  };
};

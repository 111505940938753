import { SET_CAN_LEAVE } from 'Constants/redux';

function AvailabilityAlertReducer(
  state = {
    canLeave: true,
  },
  action
) {
  switch (action.type) {
    case SET_CAN_LEAVE:
      return {
        ...state,
        canLeave: action.canLeave,
      };
    default:
      return state;
  }
}

export default AvailabilityAlertReducer;

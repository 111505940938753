import React from 'react';
import { Items } from 'Utils/SvgIcons';
import PrintRentalItem from './PrintRentalItem';
import classnames from 'classnames';
import Imgix from 'react-imgix';
import CurrencyLabel from '../utils/CurrencyLabel';

class PrintRentalBundle extends React.Component {
  render() {
    const {
      item,
      index,
      tileView,
      descriptionsAllowed,
      dimensionsAllowed,
    } = this.props;

    return (
      <tbody key={index}>
        <tr className='top'>
          <td />
          {tileView && (
            <td className='image'>
              <figure>
                {item.bundle && item.bundle.pictures[0] ? (
                  <Imgix
                    src={item.bundle.pictures[0].imgixUrl}
                    alt={item.name}
                    width={124}
                    height={70}
                  />
                ) : (
                  <Items />
                )}
              </figure>
            </td>
          )}
          <td className='center'>{item.quantity}</td>
          <td>
            {item.name}
            {item.notes && item.notes !== '' && <p>{item.notes}</p>}
          </td>
          <td className='center'>
            <CurrencyLabel value={item.selectedPrice || 0} />
          </td>
        </tr>
        {item.rentalItems && item.rentalItems.length > 0 && (
          <tr className='noBorder'>
            <td colSpan={tileView ? 4 : 3} className='indent'>
              <table
                className={classnames({
                  grid: true,
                  subGrid: true,
                  thumbnail: tileView,
                })}
              >
                {item.rentalItems.map((rentalItem, subindex) => (
                  <PrintRentalItem
                    key={subindex}
                    index={subindex}
                    item={rentalItem}
                    tileView={tileView}
                    descriptionsAllowed={descriptionsAllowed}
                    dimensionsAllowed={dimensionsAllowed}
                  />
                ))}
              </table>
            </td>
          </tr>
        )}
        {item.rentalAccessories && item.rentalAccessories.length > 0 && (
          <tr className='noBorder'>
            <td colSpan={tileView ? 4 : 3} className='indent'>
              <table
                className={classnames({
                  grid: true,
                  subGrid: true,
                  thumbnail: tileView,
                })}
              >
                {item.rentalAccessories.map((rentalAccessory, subindex) => (
                  <PrintRentalItem
                    key={subindex}
                    index={subindex}
                    item={rentalAccessory}
                    type='accessory'
                    tileView={tileView}
                    descriptionsAllowed={descriptionsAllowed}
                    dimensionsAllowed={dimensionsAllowed}
                  />
                ))}
              </table>
            </td>
          </tr>
        )}
        {item.rentalAddOns && item.rentalAddOns.length > 0 && (
          <tr className='noBorder'>
            <td colSpan={tileView ? 4 : 3} className='indent'>
              <table
                className={classnames({
                  grid: true,
                  subGrid: true,
                  thumbnail: tileView,
                })}
              >
                {item.rentalAddOns.map((rentalAddOn, subindex) => (
                  <PrintRentalItem
                    key={subindex}
                    index={subindex}
                    item={rentalAddOn}
                    type='addOn'
                    tileView={tileView}
                    descriptionsAllowed={descriptionsAllowed}
                    dimensionsAllowed={dimensionsAllowed}
                  />
                ))}
              </table>
            </td>
          </tr>
        )}
      </tbody>
    );
  }
}

export default PrintRentalBundle;

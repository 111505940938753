import React from "react";
import { connect } from "react-redux";
import { FormattedDate } from "react-intl";
import { Link } from "react-router-dom";
import { PickUp } from "Utils/SvgIcons";
import classnames from "classnames";
import { customersString, renderAlert } from "HelperFunctions/rentals";
import RentalName from "./RentalName"

const StandardRentalTableRow = props => {
  const { rental, alert, ready, link, location } = props;

  return (
    <tbody>
      <Link
        className={classnames({ alert: alert, ready: ready, rTableRow: true })}
        to={link}
      >
        <td colSpan="3" className="bold">
          <RentalName rental={rental} />
        </td>
        <td rowSpan="2" className="alert">
          {renderAlert(rental, location)}
        </td>
      </Link>
      <Link
        className={classnames({ alert: alert, ready: ready, rTableRow: true })}
        to={link}
      >
        <td
          className={classnames({
            pickUp: rental.deliveryType === "customer_pick_up",
            textBreak: true
          })}
        >
          {customersString(rental)}
          {props.business.softwareTier !== "services" &&
            rental.deliveryType === "customer_pick_up" && <PickUp />}
        </td>
        <td>
          {rental.schedule.startDate && (
            <FormattedDate
              value={rental.schedule.startDate}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          )}
        </td>
        <td>
          {rental.schedule.endDate && (
            <FormattedDate
              value={rental.schedule.endDate}
              day="2-digit"
              month="2-digit"
              year="numeric"
            />
          )}
        </td>
      </Link>
    </tbody>
  );
};
export default connect(state => ({
  business: state.business.business,
  location: state.location.location
}))(StandardRentalTableRow);

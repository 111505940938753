import React from "react";
import classNames from "classnames";
import { Alert } from "Utils/SvgIcons";

const Step = props => {
  return (
    <div
      className={classNames({
        step: true,
        complete: props.complete,
        active: props.active,
        alert: props.isAlert
      })}
    >
      <div className="icon">{props.icon}</div>
      <label>
        {props.label}
        {props.active && props.hasShortage && <Alert />}
      </label>
    </div>
  );
};

export default Step;

import AppState from '../../types/AppState';
import { store } from '../../components/Root';

interface startAndEndDates {
  startDate: Date | undefined;
  endDate: Date | undefined;
}

// Returns proper dates for inventory request
const selectEventDatesForInventoryRequest = (
  source: string
): startAndEndDates => {
  if (source === 'newRental') {
    const { newRental }: AppState = store.getState();

    const {
      scheduleAttributes: {
        eventStartDateTime,
        eventEndDateTime,
        offShelfAt,
        onShelfAt,
        shelfEnabled,
        sameAsEventDate,
        startDateTime,
        endDateTime,
      } = {},
    } = newRental;

    return {
      startDate: shelfEnabled
        ? offShelfAt
        : !sameAsEventDate
        ? startDateTime
        : eventStartDateTime,
      endDate: shelfEnabled
        ? onShelfAt
        : !sameAsEventDate
        ? endDateTime
        : eventEndDateTime,
    };
  } else {
    const { rentalFormV2 }: AppState = store.getState();

    // TODO: Add types for data. Jordan is working on setting the base types for it.
    const {
      data: {
        scheduleAttributes: {
          eventStartDateTime,
          eventEndDateTime,
          offShelfAt,
          onShelfAt,
          shelfEnabled,
          sameAsEventDate,
          startDateTime,
          endDateTime,
        } = {},
      } = {},
    } = rentalFormV2;

    return {
      startDate: shelfEnabled
        ? offShelfAt
        : !sameAsEventDate
        ? startDateTime
        : eventStartDateTime,
      endDate: shelfEnabled
        ? onShelfAt
        : !sameAsEventDate
        ? endDateTime
        : eventEndDateTime,
    };
  }
};

export default selectEventDatesForInventoryRequest;

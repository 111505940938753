import React from 'react';
import { connect } from 'react-redux';
import TopWarning from 'Utils/global/TopWarning';
import TopWarningTrial from 'Utils/global/TopWarningTrial';
import TopBadge from 'Utils/global/TopBadge';
import MainMenu from 'Components/main_menu/MainMenu';
import SimpleNav from 'Components/main_menu/SimpleNav';
import GlobalProgressBar from 'Utils/GlobalProgressBar';
import ProgressTracker from 'Utils/ProgressTracker';
import { Route, Switch, withRouter, matchPath } from 'react-router-dom';

const routesWithTopBadge = [
  '/dashboard',
  '/todos',
  '/payments',
  '/conversations',
  '/profile',
  '/operations',
  '/services',
  '/calendars',
  '/contacts',
  '/inventory',
  '/orders',
  '/admin',
  '/at-a-glance',
  'importWizard',
  '/upgrade',
];

const routesWithNav = [
  '/feedbacks',
  '/test',
  '/forgotPassword',
  '/passwordReset',
  '/support',
  '/contact',
  '/dashboard',
  '/todos',
  '/payments',
  '/conversations',
  '/profile',
  '/operations',
  '/services',
  '/calendars',
  '/contacts',
  '/inventory',
  '/orders',
  '/admin',
  '/notFound',
  '/applicationError',
  '/at-a-glance',
  '/importWizard',
  '/ImportWizard/upload/:dataType',
  '/importWizard/adjust/:dataType',
  '/upgrade',
];

class TopHeader extends React.Component {
  state = {
    badges: false,
    badges_visible: true,
  };
  updateHeader = (name, on) => {
    this.setState({
      [name]: on,
    });
  };

  closeBadges = () => {
    this.setState({
      badges_visible: !this.state.badges_visible,
    });
  };
  render() {
    const { badges, badges_visible } = this.state;
    const {
      progress,
      globalProgress,
      warning,
      location,
      business,
    } = this.props;

    const onRouteWithNav = routesWithNav.some((route) => {
      if (
        matchPath(location.pathname, {
          path: route,
        })
      ) {
        return true;
      } else {
        return false;
      }
    });
    const onRouteWithBadge = routesWithTopBadge.some((route) => {
      if (
        matchPath(location.pathname, {
          path: route,
        })
      ) {
        return true;
      } else {
        return false;
      }
    });
    const isLive = business.reportsIncluded;
    let padding = 40;
    if (progress && onRouteWithNav) {
      padding += 20;
    }
    if (globalProgress && onRouteWithNav) {
      padding += 20;
    }
    if (isLive && warning && onRouteWithBadge) {
      padding += 60;
    }
    if (isLive && badges_visible && badges && onRouteWithBadge) {
      padding += 120;
    }

    return (
      <div className='headerWrapper' style={{ paddingTop: `${padding}px` }}>
        <div>
          {routesWithNav.concat('/tg-admin').map((path, index) => (
            <Route key={index} path={path} component={GlobalProgressBar} />
          ))}

          {routesWithNav.concat('/tg-admin').map((path, index) => (
            <Route key={index} path={path} component={ProgressTracker} />
          ))}
          {/*Remove "You're almost ready to start doing business" banner 
            https://app.asana.com/0/412799086927453/1200102296193124
          */}
          {/* {isLive && badges_visible && onRouteWithBadge && (
            <Route
              render={props => {
                return (
                  <TopBadge
                    {...props}
                    updateHeader={this.updateHeader}
                    closeBadges={this.closeBadges}
                  />
                );
              }}
            />
          )} */}

          {isLive && onRouteWithBadge && <Route component={TopWarningTrial} />}
          {isLive && onRouteWithBadge && <Route component={TopWarning} />}

          {onRouteWithNav && <Route component={MainMenu} />}
          <Switch>
            <Route exact path='/tg-admin' component={SimpleNav} />
            <Route path='/tg-admin/' component={MainMenu} />
            <Route path='/login' component={SimpleNav} />
            <Route path='/signUp' component={SimpleNav} />
            <Route path='/start/get-started' component={MainMenu} />
            <Route path='/start/success' component={MainMenu} />
            <Route path='/start' component={SimpleNav} />
            <Route path='/reactivate' component={SimpleNav} />
            <Route path='/selectBusiness' component={SimpleNav} />
            <Route
              exact
              path='/billingInfo/:businessId/:paymentToken'
              component={SimpleNav}
            />
            <Route
              path='/paymentStatus/:businessId/:clientSecret'
              component={SimpleNav}
            />
          </Switch>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  const globalProgress = state.progressBar.visible;
  const progress = state.progressTracker.visible;
  const { alerts, business } = state.business;
  const { authenticated } = state.auth;
  const warning = authenticated && alerts.length !== 0;
  return {
    progress,
    globalProgress,
    warning,
    business,
  };
};

export default withRouter(connect(mapStateToProps)(TopHeader));

import React from 'react';
import { Route, Switch } from 'react-router-dom';
import PrintPickList from 'Components/rentals/PrintPickList';
import PrintSummary from 'Components/rentals/PrintSummary';
import PrintCheckingOut from 'Components/rentals/PrintCheckingOut';
import PrintCheckingIn from 'Components/rentals/PrintCheckingIn';
import PrintMaintenanceDetails from 'Components/products/maintenance_record/PrintMaintenanceDetails';

import PrintMapRoute from '../routing/MapRentals/routes/PrintMapRoute';
import PrintMapRouteInfo from '../routing/MapRentals/routes/PrintMapRouteInfo';
import PrintDispatchSummary from '..//routing/MapRentals/routes/PrintDispatchSummary';
import PrintFullInventorySheet from '../routing/MapRentals/routes/PrintFullInventorySheet';
import PrintDeliveryManifest from '../routing/MapRentals/routes/PrintDeliveryManifest';
import PrintMapRoutePrintables from '../routing/MapRentals/routes/PrintMapRoutePrintables';
import ParentOrderSummaryInvoice from '../events/ParentOrdersInvoice/ParentOrderSummaryInvoice';
import ProtectedRoute from '../utils/ProtectedRoute';
import { connect } from 'react-redux';

const PrintMiddle = (props) => (
  <Switch>
    <Route
      path={`${props.match.path}/rentals/:id/picklist`}
      component={PrintPickList}
    />
    <Route
      path={`${props.match.path}/rentals/:id/summary`}
      component={PrintSummary}
    />
    <Route
      path={`${props.match.path}/checkingOut`}
      component={PrintCheckingOut}
    />
    <Route
      path={`${props.match.path}/checkingIn`}
      component={PrintCheckingIn}
    />
    <ProtectedRoute
      path={`${props.match.path}/parent-orders/:token/summary`}
      component={ParentOrderSummaryInvoice}
      authenticated={props.business.permissions.secondaryOrdersEnabled}
    />
    <Route
      exact
      path={`${props.match.path}/routes/:id`}
      component={PrintMapRoute}
    />
    <Route
      path={`${props.match.path}/routes/:id/info`}
      component={PrintMapRouteInfo}
    />
    <Route
      path={`${props.match.path}/routes/:id/dispatch_summary`}
      component={PrintDispatchSummary}
    />
    <Route
      path={`${props.match.path}/routes/:id/full_inventory_sheet`}
      component={PrintFullInventorySheet}
    />
    <Route
      path={`${props.match.path}/routes/:id/delivery_manifest`}
      component={PrintDeliveryManifest}
    />
    <Route
      path={`${props.match.path}/inventory/maintenance/:id`}
      component={PrintMaintenanceDetails}
    />
    <Route
      path={`${props.match.path}/routes/:id/test`}
      component={PrintMapRoutePrintables}
    />
  </Switch>
);

const mapStateToProps = (state) => ({
  business: state.business.business,
});

export default connect(mapStateToProps, () => {})(PrintMiddle);

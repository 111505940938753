import {
  FETCH_ROUTING_SCHEDULE_REQUEST,
  FETCH_ROUTING_SCHEDULE_SUCCESS,
  SET_ROUTING_SCHEDULE_FILTER,
  RESET_ROUTING_FILTERS
} from "Constants/redux";

const FILTER_INITIAL_STATE = {
  year: new Date().getFullYear(),
  month: new Date().getMonth(),
  transportType: "drop_off,pick_up",
  truckIds: [],
  employeeIds: [],
  rentalStatus: "",
  showServiceTickets: false
};

const INITIAL_STATE = {
  dataSet: [],
  loading: false,
  filter: FILTER_INITIAL_STATE
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case FETCH_ROUTING_SCHEDULE_REQUEST:
      return { ...state, loading: true };
    case FETCH_ROUTING_SCHEDULE_SUCCESS:
      return {
        ...state,
        loading: false,
        ...action.payload
      };
    case SET_ROUTING_SCHEDULE_FILTER:
      return {
        ...state,
        filter: action.payload.filter
      };
    case RESET_ROUTING_FILTERS:
      return {
        ...state,
        filter: FILTER_INITIAL_STATE
      };
    default:
      return state;
  }
};

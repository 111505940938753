import React from "react";
import service from "Api/service";
import { connect } from "react-redux";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "Actions/LoadingSpinnerActions";
import {
  AddCircle,
  WebsiteCircle,
  PhoneCircle,
  CellCircle,
  FaxCircle,
  EmailCircle,
  LocationCircle,
  Company
} from "Utils/SvgIcons";
import { formatPhoneNumber } from "HelperFunctions/general";
import withLoadingSpinner from "Components/../hocs/withLoadingSpinner";
import Imgix from "react-imgix";
import { getCountry } from "HelperFunctions/general";
import { selectLocation, setNewUser } from "Actions/AuthActions";
import { styledTheme } from '../Root';

class Accounts extends React.Component {
  state = {
    user: {
      firstName: "",
      lastName: "",
      email: "",
      phoneNumber: {
        office: "",
        cell: "",
        fax: ""
      },
      physicalAddress: {
        streetAddress1: "",
        streetAddress2: "",
        city: "",
        locale: "",
        postalCode: "",
        country: ""
      },
      role: "",
      locations: []
    },
    business: {}
  };

  receiveBusiness = response => {
    const { business } = response.data;
    const { phoneNumber, physicalAddress, ...other } = business;
    const newBusiness = Object.assign(
      this.state.business,
      other,
      phoneNumber && { phoneNumber },
      physicalAddress && {
        physicalAddress
      }
    );
    this.setState({
      business: newBusiness
    });
  };

  receiveUser = response => {
    const user = response.data.employee;
    const newPhone = Object.assign(
      this.state.user.phoneNumber,
      user.phoneNumber && {
        office: user.phoneNumber.office || "",
        fax: user.phoneNumber.fax || "",
        cell: user.phoneNumber.cell || "",
        main_contact_number: true
      }
    );
    const newAddress = {
      ...this.state.user.physicalAddress,
      ...user.physicalAddress,
      country: getCountry(
        user.physicalAddress && user.physicalAddress.country,
        ""
      )
    };
    const newUser = Object.assign(this.state.user, response.data.employee, {
      phoneNumber: newPhone,
      physicalAddress: newAddress,
      locations: user.locations
    });
    this.setState({
      user: newUser
    });
  };

  componentDidMount() {
    const {openLoadingSpinner, closeLoadingSpinner} = this.props;

    openLoadingSpinner("Loading...");
    service
      .get(process.env.REACT_APP_API_DOMAIN + "/api/business/")
      .then(response => {
        this.receiveBusiness(response);
      })
      .catch(error => {
        console.log(error);
        closeLoadingSpinner();
      });
    service
      .get(process.env.REACT_APP_API_DOMAIN + "/api/employees/profile")
      .then(response => {
        this.receiveUser(response);
        closeLoadingSpinner();
      })
      .catch(error => {
        console.log(error);
        closeLoadingSpinner();
      });
  }

  addNewBusiness = () => {
    const { setNewUser, history } = this.props;
    setNewUser({ ...this.state.user, software_tier: "all" });
    history.push("/start/newLocation");
  };

  render() {
    const { location } = this.props;
    let { user, business } = this.state;
    business = Object(business);

    return (
      <div className="accounts">
        <h1 style={{ marginBottom: 0 }}>Business</h1>
        <section>
          <div className="accountSection">
            <figure className="businessPicture">
              {!business.picture ? (
                <Company />
              ) : (
                <Imgix
                  className="accountCompanyImg"
                  src={business.picture.imgixUrl}
                  alt={business.name}
                  width={510}
                  height={288}
                />
              )}
            </figure>
          </div>
          <div className="accountSection">
            <h6>Business Token: {business.token}</h6>
            <h2>{business.name}</h2>
            <p>{business.email}</p>
          </div>
          <div className="iconView accountSection">
            <h4>Contact Info</h4>
            <div className="fields">
              {business.website && <WebsiteCircle />}
              {business.website && (
                <a
                  href={"http://" + business.website}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {business.website}
                </a>
              )}
              {business.email && <EmailCircle />}
              {business.email && (
                <div>
                  <a href={"mailto:" + business.email}>{business.email}</a>
                  <a
                    className="info"
                    data-tip
                    data-for="info"
                    data-event="click"
                    data-event-off="mouseleave"
                  />
                </div>
              )}
              <PhoneCircle />
              <a
                href={"tel:" + (business.phoneNumber || {})}
                target="_blank"
                rel="noopener noreferrer"
              >
                {formatPhoneNumber((business.phoneNumber || {}).office)}
              </a>
              {(business.phoneNumber || {}).cell && <CellCircle />}
              {(business.phoneNumber || {}).cell && (
                <a
                  href={"tel:" + (business.phoneNumber || {}).cell}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatPhoneNumber((business.phoneNumber || {}).cell)}
                </a>
              )}
              {(business.phoneNumber || {}).fax && <FaxCircle />}
              {(business.phoneNumber || {}).fax && (
                <a
                  href={`tel:${(business.phoneNumber || {}).fax}`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  {formatPhoneNumber((business.phoneNumber || {}).fax)}
                </a>
              )}
              {business.physicalAddress && <LocationCircle />}
              {business.physicalAddress && (
                <a
                  href={
                    "http://maps.google.com/maps?q=" +
                    business.physicalAddress.streetAddress1 +
                    " " +
                    business.physicalAddress.city +
                    "," +
                    business.physicalAddress.locale +
                    " " +
                    business.physicalAddress.postalCode +
                    "," +
                    business.physicalAddress.country
                  }
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <div>{business.physicalAddress.streetAddress1}</div>
                  {business.physicalAddress.streetAddress2 && (
                    <div># {business.physicalAddress.streetAddress2}</div>
                  )}
                  <div>
                    {business.physicalAddress.city},{" "}
                    {business.physicalAddress.locale}{" "}
                    {business.physicalAddress.postalCode},{" "}
                    {business.physicalAddress.country}
                  </div>
                </a>
              )}
            </div>
          </div>
        </section>
        <h1 style={{ marginBottom: 0 }}>Locations</h1>
        {user.role === "owner" && (
          <div className="right">
            <a className="btn btnIcon add" onClick={this.addNewBusiness}>
              <AddCircle />
              Add Location
            </a>
            <a
              className="info"
              data-tip
              data-for="addnew"
              data-event="click"
              data-event-off="mouseleave"
            />
          </div>
        )}
        <div className="smallLocations">
          {user.locations &&
            user.locations.map(l => (
              <section key={l.id}>
                <div>
                  <figure className="locationFigure">
                    {l.picture === null || l.picture === undefined ? (
                      <Company />
                    ) : (
                      <Imgix
                        src={l.picture.imgixUrl}
                        alt={l.name}
                        width={510}
                        height={288}
                      />
                    )}
                  </figure>
                </div>
                <div>
                  <h2>{l.name}</h2>
                  <p>{l.email}</p>
                </div>
                {l.locationCode && (
                  <span
                    className='badge xl'
                    style={{ backgroundColor: l.locationColor || styledTheme.darkGray}}
                  >
                    {l.locationCode}
                  </span>
                )}
                {location.id === l.id ? (
                  <button className="btn" disabled>
                    Working In This Location
                  </button>
                ) : (
                  <button
                    className="btn"
                    onClick={() =>
                      this.props.selectLocation(l.id, l.businessId)
                    }
                  >
                    Select Location
                  </button>
                )}
              </section>
            ))}
        </div>
      </div>
    );
  }
}

const mapStateToProps = state => {
  const { user } = state.user;
  const { location } = state.location;

  return { location, user };
};

export default connect(mapStateToProps, { selectLocation, setNewUser, openLoadingSpinner, closeLoadingSpinner })(
  Accounts
);

import React from "react";
import Modal from "Utils/Modal";
import service from "Api/service";
import ScrollArea from "Utils/react-scrollbar";

class SystemUpdatePopUp extends React.Component {
  render() {
    const {
      open,
      systemUpdate,
      toggle,
      refreshSystem,
      hideCloseButton
    } = this.props;
    let actions = [
      <a className={`btn ${hideCloseButton ? 'full' : ''}`} onClick={refreshSystem}>
        Refresh
      </a>
    ];
    if (!hideCloseButton) {
      actions.push(
        <a className="btn cancel" onClick={toggle}>
          Cancel
        </a>
      );
    }
    return (
      <Modal
        className="large"
        open={open}
        toggle={toggle}
        title={systemUpdate.title}
        actions={actions}
        hideCloseButton={hideCloseButton}
      >
        <section>
          <ScrollArea speed={0.8} horizontal={false}>
            <div
              className="editedText"
              dangerouslySetInnerHTML={{
                __html: systemUpdate.htmlContent || ""
              }}
            />
          </ScrollArea>
        </section>
      </Modal>
    );
  }
}
export default SystemUpdatePopUp;

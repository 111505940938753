import React from 'react';
import PrintDispatchSummary from './PrintDispatchSummary';

const PrintFullInventorySheet = ({ match }) => {
  const paramsId = match.params.id;

  return <PrintDispatchSummary paramsId={paramsId} fullInventorySheet />;
};

export default PrintFullInventorySheet;

import {
  FETCH_RENTAL_TRANSPORT_LIST_REQUEST,
  FETCH_RENTAL_TRANSPORT_LIST_SUCCESS,
  SET_RENTAL_TRANSPORT_FILTER,
  SHOW_OVERLAPPED_RENTAL_ON_MAP,
  CLOSE_OVERLAPPED_RENTAL_ON_MAP,
  RESET_TRANSPORT_LIST_FILTERS,
} from 'Constants/redux';
import service from 'Api/service';
import moment from 'moment-timezone';
import {
  openLoadingSpinner,
  closeLoadingSpinner,
} from './LoadingSpinnerActions';

export function setRentalTransportFilter(filter) {
  return {
    type: SET_RENTAL_TRANSPORT_FILTER,
    payload: { filter },
  };
}

export const loadRentalTransports = (prioritize_sticky = false) => {
  return (dispatch, getState) => {
    const {
      startDate,
      endDate,
      truckIds,
      employeeIds,
      transportType,
      rentalStatus,
      rentalSource,
    } = getState().operation.operationsFilter;
    dispatch({
      type: FETCH_RENTAL_TRANSPORT_LIST_REQUEST,
    });
    const timeZone = moment.tz.guess()
    dispatch(openLoadingSpinner());
    service
      .get(process.env.REACT_APP_API_DOMAIN + '/api/rental_transports/search', {
        start_date_time_gte: startDate,
        start_date_time_lte: endDate,
        truck_ids: truckIds,
        employee_ids: employeeIds,
        transport_type: transportType,
        rental_status: rentalStatus,
        rental_source: rentalSource,
        time_zone: timeZone,
        ...(prioritize_sticky && { prioritize_sticky: true }),
      })
      .then((response) => {
        const newState = {
          rentalTransports: response.data.rentalTransports,
        };
        dispatch({
          type: FETCH_RENTAL_TRANSPORT_LIST_SUCCESS,
          payload: newState,
        });

        dispatch(closeLoadingSpinner());
      })
      .finally(() => dispatch(closeLoadingSpinner()));
  };
};

export function showOverlappedRentalOnMap(rentalTransportId) {
  return (dispatch, getState) => {
    dispatch({
      type: SHOW_OVERLAPPED_RENTAL_ON_MAP,
      payload: { rentalTransportId },
    });
  };
}

export function closeOverlappedRentalOnMap() {
  return (dispatch, getState) => {
    dispatch({
      type: CLOSE_OVERLAPPED_RENTAL_ON_MAP,
    });
  };
}

export function resetTransportListFilters() {
  return (dispatch, getState) => {
    dispatch({
      type: RESET_TRANSPORT_LIST_FILTERS,
    });
  };
}

import { store } from '../components/Root';
import AppState from '../types/AppState';
import { OriginalTerminology } from '../types/OriginalTerminology';

const originalTerminologies = () => {
  const state: AppState = store.getState();
  const terminologies: OriginalTerminology[] =
    state.originalTerminology.originalTerminologies;

  const findTerminology = (key: string) =>
    terminologies.find((t: OriginalTerminology) => t.originalWord === key)!;

  const venueTerminology = findTerminology('venue');
  const parentOrderTerminology = findTerminology('parent_order');
  const purchaseTerminology = findTerminology('purchase');
  const secondaryOrderTerminology = findTerminology('secondary_order');
  const customIdTerminology = findTerminology('custom_id');
  const checkInTerminology = findTerminology('check_in');
  const checkOutTerminology = findTerminology('check_out');
  const checkedInTerminology = findTerminology('checked_in');
  const checkedOutTerminology = findTerminology('checked_out');
  const checkingInTerminology = findTerminology('checking_in');
  const checkingOutTerminology = findTerminology('checking_out');

  return {
    venueSingular: venueTerminology.singularWord,
    venuePlural: venueTerminology.pluralWord,
    parentOrderSingular: parentOrderTerminology.singularWord,
    parentOrderPlural: parentOrderTerminology.pluralWord,
    purchaseSingular: purchaseTerminology.singularWord,
    purchasePlural: purchaseTerminology.pluralWord,
    purchaseOriginal: purchaseTerminology.originalWord,
    secondaryOrderSingular: secondaryOrderTerminology.singularWord,
    secondaryOrderPlural: secondaryOrderTerminology.pluralWord,
    customIdSingular: customIdTerminology.singularWord,
    customIdPlural: customIdTerminology.pluralWord,
    checkInSingular: checkInTerminology.singularWord,
    checkInPlural: checkInTerminology.pluralWord,
    checkOutSingular: checkOutTerminology.singularWord,
    checkOutPlural: checkOutTerminology.pluralWord,
    checkedInSingular: checkedInTerminology.singularWord,
    checkedOutSingular: checkedOutTerminology.singularWord,
    checkingInSingular: checkingInTerminology.singularWord,
    checkingOutSingular: checkingOutTerminology.singularWord,
  };
};

export default originalTerminologies;

import classnames from "classnames";
import React from "react";

const renderField = props => {
  const {
    input,
    className,
    type,
    meta: { touched, error }
  } = props;
  return (
    <input
      {...input}
      value={input.value || ""}
      autoComplete="off"
      className={classnames({
        [className]: className,
        error: touched && error
      })}
      type={type}
    />
  );
};

export default renderField;

import service from "Api/service";
import { reset } from "redux-form";
import { fetchRental } from "./RentalActions";
import { history } from "Components/Routes";
import { setErrors } from "./dashboardActionCreators";

import {
  FETCH_CONVERSATION_LIST_REQUEST,
  FETCH_CONVERSATION_LIST_SUCCESS,
  FETCH_CONVERSATION_LIST_FAILURE,
  CREATE_CONVERSATION_REQUEST,
  CREATE_CONVERSATION_FAILURE,
  READ_CONVERSATION_REQUEST,
  READ_CONVERSATION_FAILURE,
  FETCH_CONVERSATION_REQUEST,
  FETCH_CONVERSATION_SUCCESS,
  FETCH_CONVERSATION_FAILURE,
  CREATE_MESSAGE_REQUEST,
  CREATE_MESSAGE_SUCCESS,
  CREATE_MESSAGE_FAILURE,
  ADD_MESSAGE,
  ADD_UNREAD_MESSAGE
} from "Constants/redux";
import {
  openLoadingSpinner,
  closeLoadingSpinner
} from "./LoadingSpinnerActions";

export const fetchConversationList = () => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CONVERSATION_LIST_REQUEST
    });
    service
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/conversations/`)
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_LIST_SUCCESS,
          payload: {
            conversations: response.data.conversations,
            unreadMessages: response.data.conversations.reduce(
              (sum, conversation) =>
                (sum += parseInt(conversation.unreadMessages, 10)),
              0
            )
          }
        });
      })
      .catch(error => {
        dispatch({ type: FETCH_CONVERSATION_LIST_FAILURE });
      });
  };
};

export const addUnreadMessage = payload => {
  return {
    type: ADD_UNREAD_MESSAGE,
    payload
  };
};

export const createConversation = (
  customer_id,
  rental_id,
  body,
  forRental = false
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_CONVERSATION_REQUEST
    });
    const { user } = getState().auth;
    const { location } = getState().location;

    service
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/conversations`, {
        conversation: {
          location_conversation_relationships_attributes: {
            "0": {
              location_id: location.id,
              role: "supplier"
            }
          },
          rental_conversation_relationships_attributes: {
            "0": {
              rental_id: rental_id,
              role: "supplier"
            }
          },
          conversation_relationships_attributes: {
            "0": {
              customer_id: customer_id
            }
          },
          messages_attributes: {
            "0": {
              body: body,
              sender_type: "Employee",
              sender_id: user.id
            }
          }
        }
      })
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        if (forRental) {
          dispatch(fetchRental(rental_id));
          history.push(
            `/orders/rentals/${rental_id}/conversations/${response.data.conversation.id}`
          );
        } else {
          dispatch(fetchConversationList());
          history.push(`/conversations/${response.data.conversation.id}`);
        }
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_CONVERSATION_FAILURE });
        dispatch(setErrors(error.response.data));
      });
  };
};

export const createConversationWithTenant = (
  location_id,
  rental_id,
  tenant_rental_id,
  body
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_CONVERSATION_REQUEST
    });
    const { user } = getState().auth;
    const { location } = getState().location;

    service
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/conversations`, {
        conversation: {
          conversation_type: "b2b",
          location_conversation_relationships_attributes: {
            "0": {
              location_id: location.id,
              role: "supplier"
            },
            "1": {
              location_id: location_id,
              role: "tenant"
            }
          },
          rental_conversation_relationships_attributes: {
            "0": {
              rental_id: rental_id,
              role: "supplier"
            },
            "1": {
              rental_id: tenant_rental_id,
              role: "tenant"
            }
          },
          messages_attributes: {
            "0": {
              body: body,
              sender_type: "Employee",
              sender_id: user.id
            }
          }
        }
      })
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(fetchRental(rental_id));
        history.push(
          `/orders/rentals/${rental_id}/conversations/${response.data.conversation.id}`
        );
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_CONVERSATION_FAILURE });
      });
  };
};

export const createConversationWithSupplier = (
  location_id,
  rental_id,
  supplier_rental_id,
  body
) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_CONVERSATION_REQUEST
    });
    const { user } = getState().auth;
    const { location } = getState().location;
    service
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/conversations`, {
        conversation: {
          conversation_type: "b2b",
          location_conversation_relationships_attributes: {
            "0": {
              location_id: location_id,
              role: "supplier"
            },
            "1": {
              location_id: location.id,
              role: "tenant"
            }
          },
          rental_conversation_relationships_attributes: {
            "0": {
              rental_id: supplier_rental_id,
              role: "supplier"
            },
            "1": {
              rental_id: rental_id,
              role: "tenant"
            }
          },
          messages_attributes: {
            "0": {
              body: body,
              sender_type: "Employee",
              sender_id: user.id
            }
          }
        }
      })
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(fetchRental(rental_id));
        history.push(
          `/orders/rentals/${rental_id}/conversations/${response.data.conversation.id}`
        );
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_CONVERSATION_FAILURE });
      });
  };
};

export const readConversation = id => {
  return (dispatch, getState) => {
    dispatch({
      type: READ_CONVERSATION_REQUEST
    });
    service
      .post(`${process.env.REACT_APP_API_DOMAIN}/api/conversations/${id}/read`)
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_LIST_SUCCESS,
          payload: {
            conversations: response.data.conversations,
            unreadMessages: response.data.conversations.reduce(
              (sum, conversation) =>
                (sum += parseInt(conversation.unreadMessages, 10)),
              0
            )
          }
        });
        dispatch(closeLoadingSpinner());
        const { rental } = getState().rental;
        if (rental.id !== "") {
          dispatch(fetchRental(rental.id, true));
        }
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: READ_CONVERSATION_FAILURE });
      });
  };
};

// Called from the Conversation container
export const fetchConversation = id => {
  return (dispatch, getState) => {
    dispatch({
      type: FETCH_CONVERSATION_REQUEST
    });
    dispatch(openLoadingSpinner("Loading..."));
    service
      .get(`${process.env.REACT_APP_API_DOMAIN}/api/conversations/${id}`)
      .then(response => {
        dispatch({
          type: FETCH_CONVERSATION_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(readConversation(id));
      })
      .catch(error => {
        console.log(error);
        dispatch(closeLoadingSpinner());
        dispatch({ type: FETCH_CONVERSATION_FAILURE });
      });
  };
};

export const createMessage = (conversationId, body) => {
  return (dispatch, getState) => {
    dispatch({
      type: CREATE_MESSAGE_REQUEST
    });

    service
      .post(
        `${process.env.REACT_APP_API_DOMAIN}/api/conversations/${conversationId}/messages`,
        {
          message: {
            body: body,
            conversation_id: conversationId
          }
        }
      )
      .then(response => {
        dispatch({
          type: CREATE_MESSAGE_SUCCESS,
          payload: {
            conversation: response.data.conversation
          }
        });
        dispatch(reset("NewMessage"));
      })
      .catch(error => {
        console.log(error);
        dispatch({ type: CREATE_MESSAGE_FAILURE });
        dispatch(setErrors(error.response.data));
      });
  };
};

export const addMessage = data => {
  return {
    type: ADD_MESSAGE,
    payload: data.message
  };
};

import { ROOT_URL, saveError } from "Api";
import service from "Api/service";
import { adminService } from "Api/service";
import { apiConnections } from "Constants/displayConstants";
import {
  FETCH_CATEGORIES_REQUEST,
  FETCH_CATEGORIES_SUCCESS,
  FETCH_CATEGORIES_FAILURE,
  SEARCH_CATEGORIES_REQUEST,
  SEARCH_CATEGORIES_SUCCESS,
  SEARCH_CATEGORIES_FAILURE,
  FETCH_INVENTORY_CATEGORIES_REQUEST,
  FETCH_INVENTORY_CATEGORIES_SUCCESS,
  FETCH_INVENTORY_CATEGORIES_FAILURE
} from "Constants/redux";

export function fetchCategories(admin = false) {
  const serviceToUse = admin ? adminService : service;
  return (dispatch, getState) => {
    dispatch({ type: FETCH_CATEGORIES_REQUEST });
    serviceToUse
      .get(`${ROOT_URL}/categories`)
      .then(response => {
        return dispatch({
          response,
          type: FETCH_CATEGORIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: FETCH_CATEGORIES_FAILURE
        });
      });
  };
}

export function searchCategories(query = "") {
  return (dispatch, getState) => {
    dispatch({ type: SEARCH_CATEGORIES_REQUEST });
    service
      .get(`${ROOT_URL}/categories/search?query=${query}`)
      .then(response => {
        return dispatch({
          response,
          type: SEARCH_CATEGORIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          error,
          type: SEARCH_CATEGORIES_FAILURE
        });
      });
  };
}

export function fetchInventoryCategories(productType) {
  return (dispatch, getState) => {
    if (productType !== "maintenanceRecords") {
      dispatch({ type: FETCH_INVENTORY_CATEGORIES_REQUEST });
      service
        .get(`${ROOT_URL}/${apiConnections[productType]}/categories`)
        .then(response => {
          return dispatch({
            productType,
            response,
            type: FETCH_INVENTORY_CATEGORIES_SUCCESS
          });
        })
        .catch(error => {
          console.log(error);
          saveError(error, getState());
          dispatch({
            productType,
            error,
            type: FETCH_INVENTORY_CATEGORIES_FAILURE
          });
        });
    }
  };
}

export function fetchConnectCategories(productType) {
  return (dispatch, getState) => {
    dispatch({ type: FETCH_INVENTORY_CATEGORIES_REQUEST });
    service
      .get(`${ROOT_URL}/connect/inventories/categories`)
      .then(response => {
        return dispatch({
          productType,
          response,
          type: FETCH_INVENTORY_CATEGORIES_SUCCESS
        });
      })
      .catch(error => {
        console.log(error);
        saveError(error, getState());
        dispatch({
          productType,
          error,
          type: FETCH_INVENTORY_CATEGORIES_FAILURE
        });
      });
  };
}

import { connect } from 'react-redux';
import { ErrorsType } from '../../types/ErrorsType';
import React, { useEffect, useRef, useState } from 'react';
import NotificationSystem from 'react-notification-system';
import { ValidationPopUpProps } from './ValidationPopUp.types';
import * as actions from '../../actions/dashboardActionCreators';

const ValidationPopUp = ({ errors, errorTime }: ValidationPopUpProps) => {
  const VALIDATION_POPUP_TIMEOUT = 20;

  const [_parsedErrors, setParsedErrors] = useState<string[]>([]);
  const notificationSystemRef = useRef<NotificationSystem.System>(null);

  const parseErrors = (errors: ErrorsType): string[] => {
    if (Array.isArray(errors)) return errors.map((error) => `${error} `);

    if (typeof errors === 'object') {
      return Object.values(errors).flatMap((error) => parseErrors(error));
    }

    return errors ? [errors] : [];
  };

  useEffect(() => {
    const parsed = parseErrors(errors ?? []);
    setParsedErrors(parsed);

    if (parsed.length > 0 && notificationSystemRef.current) {
      notificationSystemRef.current.clearNotifications();
      notificationSystemRef.current.addNotification({
        level: 'error',
        position: 'bc',
        title: 'Oops! We noticed some errors:',
        message: (
          <ul>
            {parsed.map((error, index) => (
              <li key={error}>{error}</li>
            ))}
          </ul>
        ),
        autoDismiss: VALIDATION_POPUP_TIMEOUT,
      });
    }
  }, [errors, errorTime]);

  return <NotificationSystem ref={notificationSystemRef} style={false} />;
};

const mapStateToProps = (state: any) => {
  const { errors, errorTime } = state.dashboard;
  return { errors, errorTime };
};

export default connect(mapStateToProps, actions)(ValidationPopUp);

import service from "Api/service";
import { apiToSingularRoot, apiConnections } from "Constants/displayConstants";

// InventoryGroups
const inventoryGroups = {
  names: (productType, data) => {
    return service.get(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiToSingularRoot[productType]}_groups/names`,
      data
    );
  },
  moveInventory: (productType, data) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiToSingularRoot[productType]}_groups/move_inventory`,
      data
    );
  },
  moveSerializedInventory: (productType, data) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiToSingularRoot[productType]}_groups/move_serialized_inventory`,
      data
    );
  }
};

// Products
const products = {
  enableSharing: (product, productType) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiConnections[productType]}/${product.id}/enable_sharing`
    );
  },
  connectToGroup: (product, productType, data) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiConnections[productType]}/${product.id}/connect_to_group`,
      data
    );
  },
  disconnectToGroup: (product, productType) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiConnections[productType]}/${product.id}/disconnect_to_group`
    );
  },
  generateSharedItem: (product, productType) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiConnections[productType]}/${product.id}/generate_shared_item`
    );
  },
  disableSharing: (product, productType) => {
    return service.post(
      process.env.REACT_APP_API_DOMAIN +
        `/api/multilocation/${apiConnections[productType]}/${product.id}/disable_sharing`
    );
  }
};
export default {
  inventoryGroups: inventoryGroups,
  products: products
};

import {
  FETCH_INVENTORY_REQUEST,
  FETCH_INVENTORY_SUCCESS,
  FETCH_INVENTORY_FAILURE,
  FETCH_PRODUCTS_REQUEST,
  FETCH_PRODUCTS_SUCCESS,
  FETCH_PRODUCTS_FAILURE,
  FETCH_PRODUCT_REQUEST,
  FETCH_PRODUCT_SUCCESS,
  FETCH_PRODUCT_FAILURE,
  ADD_MAINTENANCE_REQUEST,
  ADD_MAINTENANCE_SUCCESS,
  ADD_MAINTENANCE_FAILURE,
  DELETE_MAINTENANCE_REQUEST,
  DELETE_MAINTENANCE_SUCCESS,
  DELETE_MAINTENANCE_FAILURE,
  FILTER_PRODUCTS_REQUEST,
  FILTER_PRODUCTS_SUCCESS,
  FILTER_PRODUCTS_FAILURE,
  SHOW_ALL_PRODUCTS_REQUEST,
  SHOW_ALL_PRODUCTS_SUCCESS,
  SHOW_ALL_PRODUCTS_FAILURE,
  FETCH_ALL_PRODUCTS_REQUEST,
  FETCH_ALL_PRODUCTS_SUCCESS,
  FETCH_ALL_PRODUCTS_FAILURE,
  FETCH_PRODUCT_LIST_REQUEST,
  FETCH_PRODUCT_LIST_SUCCESS,
  FETCH_ACCESSORY_LIST_REQUEST,
  FETCH_ACCESSORY_LIST_SUCCESS,
  FETCH_ADD_ON_LIST_REQUEST,
  FETCH_ADD_ON_LIST_SUCCESS,
  FETCH_BUNDLE_LIST_REQUEST,
  FETCH_BUNDLE_LIST_SUCCESS,
  PAGINATE_REQUEST,
  PAGINATE_SUCCESS,
  PAGINATE_FAILURE,
  RELOAD_INVENTORY_REQUEST,
  RELOAD_INVENTORY_SUCCESS,
  RELOAD_INVENTORY_FAILURE,
  LOAD_INVENTORY_REQUEST,
  LOAD_INVENTORY_SUCCESS,
  LOAD_INVENTORY_FAILURE,
  INFINITE_REQUEST,
  INFINITE_FAILURE,
  INFINITE_SUCCESS,
  SET_INVENTORY_TYPE,
  SET_FILTER,
  SET_INVENTORY_FILTER,
  SET_FOR_CONNECT,
  SET_SORT,
  SET_QUERY,
  SET_NUMBER_PER,
  RESET_INVENTORY,
  RESET_INVENTORY_TO_DEFAULT,
  SET_SHOW_ALL,
  SHOW_TILES,
  HIDE_TILES,
  TOGGLE_DETAILS,
  SHOW_ITEM_NOTES,
  HIDE_ITEM_NOTES,
  TOGGLE_BAR_CODES,
} from "Constants/redux";
import { apiReceiving } from "Constants/displayConstants";

const INITIAL_STATE = {
  categories: [],
  inventory: [],
  accessories: [],
  allAccessories: [],
  addOns: [],
  items: [],
  bundles: [],
  product: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    subCategories: [],
    departmentInventoryProductRelationships: [],
    customTaxProductRelationships: [],
    inventoryCategorizations: [],
    changeHistoryEntries: [],
    businessInfo: {},
    flatPrices: [],
    multilocationShared: false,
    missingSerialNumbersCount: 0,
    activeSerialNumbersCount: 0,
    activeSerialNumbers: [],
    archivedSerialNumbersCount: 0
  },
  accessory: {
    id: "",
    name: "",
    material: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    subCategories: [],
    departmentInventoryProductRelationships: [],
    inventoryCategorizations: [],
    changeHistoryEntries: [],
    businessInfo: {},
    multilocationShared: false
  },
  addOn: {
    id: "",
    name: "",
    material: "",
    description: "",
    purchasePrice: "",
    pricing: "",
    quantity: "",
    buffer: "",
    weight: "",
    depth: "",
    width: "",
    height: "",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    suppliers: [],
    subCategories: [],
    departmentInventoryProductRelationships: [],
    inventoryCategorizations: [],
    changeHistoryEntries: [],
    businessInfo: {},
    multilocationShared: false
  },
  bundle: {
    id: "",
    name: "",
    description: "",
    turnoverTime: "",
    turnoverUnit: "hours",
    category: {
      id: "",
      name: ""
    },
    colors: [],
    subCategories: [],
    departmentInventoryProductRelationships: [],
    inventoryCategorizations: [],
    flatPrices: [],
    businessInfo: {}
  },
  inventoryFilter: {
    page: 0,
    allowed: false,
    selectedSerialized: "",
    selectedCategories: "",
    selectedSubCategories: "",
    selectedSfCategories: "",
    selectedSfSubCategories: "",
    sort: "asc",
    showAll: false,
    query: "",
    query_type: "all",
    status: "open"
  },
  numberPer: 10,
  page: 0,
  filter: {},
  sort: "asc",
  showAll: false,
  query: "",
  inventoryType: "items",
  tileView: false,
  detailView: true,
  itemNotesView: true,
  barcodesView: true,
  pagination: {},
  loading: false,
  isInfiniteLoading: false,
  pagesRemaining: true,
  infinite: false,
  forConnect: false,
  deletingMaintenance: false
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RESET_INVENTORY:
      return {
        ...state,
        inventory: [],
        inventoryFilter: {
          numberPer: 10,
          page: 0,
          allowed: false,
          selectedSerialized: "",
          selectedCategories: "",
          selectedSubCategories: "",
          sort: "asc",
          showAll: false,
          query: ""
        },
        filter: {},
        isInfiniteLoading: false,
        pagesRemaining: true,
        infinite: true,
        pagination: {}
      };
    case RESET_INVENTORY_TO_DEFAULT:
      return {
        ...INITIAL_STATE,
        tileView: state.tileView
      };
    case DELETE_MAINTENANCE_REQUEST:
      return { ...state, deletingMaintenance: true };

    case RELOAD_INVENTORY_REQUEST:
      return Object.assign(
        { ...state },
        !action.disableLoading && { loading: true, isInfiniteLoading: true }
      );

    case FETCH_INVENTORY_REQUEST:
    case FETCH_PRODUCTS_REQUEST:
    case FETCH_PRODUCT_REQUEST:
    case ADD_MAINTENANCE_REQUEST:
    case FILTER_PRODUCTS_REQUEST:
    case SHOW_ALL_PRODUCTS_REQUEST:
    case FETCH_ALL_PRODUCTS_REQUEST:
    case FETCH_PRODUCT_LIST_REQUEST:
    case FETCH_ACCESSORY_LIST_REQUEST:
    case FETCH_ADD_ON_LIST_REQUEST:
    case FETCH_BUNDLE_LIST_REQUEST:
    case LOAD_INVENTORY_REQUEST:
    case INFINITE_REQUEST:
      return { ...state, isInfiniteLoading: true };
    case PAGINATE_REQUEST:
      return { ...state, loading: true, isInfiniteLoading: true };
    case FETCH_INVENTORY_FAILURE:
    case FETCH_PRODUCT_FAILURE:
    case ADD_MAINTENANCE_FAILURE:
    case FETCH_PRODUCTS_FAILURE:
    case FILTER_PRODUCTS_FAILURE:
    case SHOW_ALL_PRODUCTS_FAILURE:
    case FETCH_ALL_PRODUCTS_FAILURE:
    case RELOAD_INVENTORY_FAILURE:
    case LOAD_INVENTORY_FAILURE:
    case PAGINATE_FAILURE:
    case INFINITE_FAILURE:
      return { ...state, loading: false, isInfiniteLoading: false };
    case DELETE_MAINTENANCE_FAILURE:
      return { ...state, deletingMaintenance: false };
    case FETCH_PRODUCT_SUCCESS:
    case ADD_MAINTENANCE_SUCCESS:
      return {
        ...state,
        [action.productType]: action.response.data[action.productType],
        loading: false
      };
    case DELETE_MAINTENANCE_SUCCESS:
      return {
        ...state,
        inventory: state.inventory.filter(
          elem => elem.id !== action.inventoryId
        ),
        deletingMaintenance: false
      };
    case FETCH_PRODUCTS_SUCCESS:
      return {
        ...state,
        [action.productType]: action.response.data[action.productType],
        loading: false
      };
    case FETCH_INVENTORY_SUCCESS:
    case FILTER_PRODUCTS_SUCCESS:
      const newFilterState = Object.assign(
        {
          inventory: action.response.data[apiReceiving[action.productType]],
          inventoryType: action.productType,
          loading: false,
          page: 0
        },
        action.response.data.meta && {
          pagination: action.response.data.meta.pagination
        }
      );
      return {
        ...state,
        ...newFilterState
      };
    case RELOAD_INVENTORY_SUCCESS:
      const newState = Object.assign(
        {
          inventory: action.response.data[apiReceiving[action.inventoryType]],
          inventoryType: action.inventoryType,
          loading: false,
          page: 0
        },
        action.response.data.meta && {
          pagination: action.response.data.meta.pagination
        }
      );
      return {
        ...state,
        ...newState
      };

    case LOAD_INVENTORY_SUCCESS:
      return {
        ...state,
        inventory: action.response.data[apiReceiving[action.productType]],
        inventoryType: action.productType,
        pagination: action.response.data.meta.pagination,
        sort: "asc",
        filter: {
          subcategory_id: [],
          category_id: []
        },
        loading: false,
        infinite: false
      };

    case SHOW_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        inventory: action.response.data[apiReceiving[action.productType]],
        inventoryType: action.productType,
        loading: false
      };
    case FETCH_ALL_PRODUCTS_SUCCESS:
      return {
        ...state,
        [action.productType]:
          action.response.data[apiReceiving[action.productType]],
        loading: false
      };
    case PAGINATE_SUCCESS:
      return {
        ...state,
        inventory: action.response.data[apiReceiving[action.productType]],
        inventoryType: action.productType,
        pagination: action.response.data.meta.pagination,
        loading: false,
        page: action.page
      };
    case INFINITE_SUCCESS:
      const newProducts =
        action.response.data[apiReceiving[action.productType]];
      let totalProducts = newProducts;
      if (!action.reset) {
        totalProducts = state.inventory.concat(totalProducts);
      }
      const pagesRemaining =
        totalProducts.length <
        action.response.data.meta.pagination.totalObjects;
      return {
        ...state,
        inventory: totalProducts,
        inventoryType: action.productType,
        pagination: action.response.data.meta.pagination,
        pagesRemaining: pagesRemaining,
        isInfiniteLoading: false,
        loading: false
      };
    case SHOW_TILES:
      return {
        ...state,
        tileView: true
      };
    case HIDE_TILES:
      return {
        ...state,
        tileView: false
      };
    case TOGGLE_DETAILS:
      return {
        ...state,
        detailView: !state.detailView
      };
    case SHOW_ITEM_NOTES:
      return {
        ...state,
        itemNotesView: true
      };
    case HIDE_ITEM_NOTES:
      return {
        ...state,
        itemNotesView: false
      };
    case TOGGLE_BAR_CODES:
      return {
        ...state,
        barcodesView: !state.barcodesView
      };
    case SET_INVENTORY_TYPE:
      return {
        ...state,
        inventoryType: action.payload
      };
    case SET_FILTER:
      return {
        ...state,
        filter: action.payload
      };
    case SET_INVENTORY_FILTER:
      return {
        ...state,
        inventoryFilter: {
          ...state.inventoryFilter,
          ...action.payload
        }
      };
    case SET_NUMBER_PER:
      return {
        ...state,
        numberPer: action.payload
      };
    case SET_FOR_CONNECT:
      return {
        ...state,
        forConnect: action.payload
      };
    case SET_QUERY:
      return {
        ...state,
        query: action.payload
      };
    case SET_SORT:
      return {
        ...state,
        sort: action.payload
      };
    case SET_SHOW_ALL:
      return {
        ...state,
        showAll: action.payload,
        infinite: action.payload
      };
    case FETCH_PRODUCT_LIST_SUCCESS:
      return {
        ...state,
        items: action.inventory
      };
    case FETCH_ADD_ON_LIST_SUCCESS:
      return {
        ...state,
        addOns: action.inventory
      };
    case FETCH_ACCESSORY_LIST_SUCCESS:
      return {
        ...state,
        accessories: action.inventory
      };
    case FETCH_BUNDLE_LIST_SUCCESS:
      return {
        ...state,
        bundles: action.inventory
      };
    default:
      return state;
  }
};

import { REQUEST_COMPANY_NAMES, EPIC_ERROR } from 'Constants/redux';
import * as actions from 'Actions';
import { saveAuthToken } from 'Api';
import service from 'Api/service';
import Rx from 'rxjs/Rx';
const { Observable } = Rx;

const fetchCompanyNamesEpic = (action$) =>
  action$
    .ofType(REQUEST_COMPANY_NAMES)
    .debounceTime(500)
    .switchMap((action) => {
      const { numberPer: per, query, page, categories } = action.filter;
      return Rx.Observable.fromPromise(
        service.get(process.env.REACT_APP_API_DOMAIN + `/api/companies/names`, {
          per,
          query,
          page,
          categories,
        })
      )
        .map((response) => {
          saveAuthToken(response.headers);
          return actions.receiveCompanyNames(response);
        })
        .catch((error) =>
          Observable.of({
            type: EPIC_ERROR,
          })
        );
    });

export default fetchCompanyNamesEpic;
